import React from "react";
import { Modal } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { Button, Card } from "react-bootstrap";
import axios from "axios";

import toast from "react-hot-toast";

//Context import
import AuthContext from "../../components/shared/Context";

const ErrorLogChild = () => {
  const { checkLoggedInStatus, loggedInUserDetails } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [markAsResolved, setMarkAsResolved] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/elog/`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data.users));
          const oldData = response.data.error_logs;
          const updatedData = oldData.map((data) => {
            return {
              ...data,
              id: data?._id,
              user: data?.user_id?.first_name + " " + data?.user_id?.last_name,
              access_level: data?.user_id?.user_type,
              state: data?.user_id?.tc_state,
            };
          });
          //console.log(updatedData);
          setData(updatedData);
        })
        .catch(function (err) {
          console.log(err.message);
          //console.log("error api call ", err.response.data.message);
          if (err?.response?.data?.message) {
            if (
              err?.response?.data?.message.toLowerCase().includes("invalid")
            ) {
              navigate("/login");
            }
          }
        });
    };
    getDataFromServer();
  }, [markAsResolved]);

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            <Tooltip title="View Error">
              <a onClick={() => viewRecord(params.row)}>
                {/* <button className='patientListEdit'>Edit</button> */}
                <i
                  className="mdi mdi-eye-circle-outline"
                  style={{
                    fontSize: "15px",
                    color: "black",
                    cursor: "pointer",
                  }}
                ></i>
              </a>
            </Tooltip>
            <Tooltip title="Edit Error">
              <div onClick={() => {}}>
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                />
              </div>
            </Tooltip>
            <Tooltip title="Resolution Status">
              <div>
                {!params.row.resolution_status ? (
                  <i
                    className="mdi mdi-close-octagon"
                    style={{
                      fontSize: "15px",
                      color: "red",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <i
                    className="mdi mdi-checkbox-marked-circle"
                    style={{
                      fontSize: "15px",
                      color: "green",
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 180,
    },
    {
      field: "page",
      headerName: "Component",
      width: 180,
    },
    {
      field: "page_route",
      headerName: "Route",
      width: 160,
    },
    {
      field: "user",
      headerName: "User",
      width: 160,
    },
    {
      field: "access_level",
      headerName: "Access Level",
      width: 160,
    },
    {
      field: "state",
      headerName: "State",
      width: 160,
    },
  ];

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
    setMarkAsResolved(false);
  };

  return (
    <div>
      <div>
        <h2>Error Logs</h2>
      </div>{" "}
      <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        displayTitle="Blood Products"
        // checkboxSelection
        style={{ height: "60vh" }}
      />
      {/* Modal Popup */}
      <Modal show={openModal} size="lg" centered>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent
            currentRecord={currentRecord}
            markAsResolved={markAsResolved}
            setMarkAsResolved={setMarkAsResolved}
          />
        </Modal.Body>
      </Modal>
      {/* {JSON.stringify(data)} */}
    </div>
  );
};

const ModalContent = ({ currentRecord, markAsResolved, setMarkAsResolved }) => {
  const [disableButton, setDisableButton] = useState(false);

  const handleMarkAsResolved = () => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/elog/update/`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { er_id: currentRecord._id },
    };
    axios(config)
      .then(function (response) {
        setMarkAsResolved(true);
        toast.success("Error Resolved");
        setDisableButton(true);
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(JSON.stringify(error.response.data));
        //  console.log(error);
      });
  };

  return (
    <div className="container-fluid legend">
      <Card>
        <Card.Header>
          <i className="fas fa-align-justify" />
          <strong>Error Details</strong>{" "}
        </Card.Header>
        <Card.Body>
          <CardTableRow rowName="Component" rowValue={currentRecord?.page} />
          <CardTableRow rowName="Route" rowValue={currentRecord?.page_route} />
          <CardTableRow
            rowName="Date Time"
            rowValue={currentRecord?.createdAt}
          />
          <div className="row">
            <div className="col-md-12 col-xs-12 divTableCell">
              <b> Error: </b>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12 col-xs-12 divTableCell"
              style={{
                flex: 1,
                flexWrap: "wrap",
                flexShrink: 1,
                wordBreak: "break-word",
              }}
            >
              {currentRecord?.error_log}
            </div>
          </div>
          {!currentRecord?.resolution_status && (
            <div className="patientUpdateButton">
              <Button
                size="small"
                onClick={handleMarkAsResolved}
                disabled={disableButton}
              >
                {" "}
                Mark as Resolved
              </Button>
            </div>
          )}
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <i className="fas fa-align-justify" />
          <strong>User Details</strong>
        </Card.Header>
        <Card.Body>
          <CardTableRow rowName="User" rowValue={currentRecord?.user} />
          <CardTableRow rowName="Role" rowValue={currentRecord?.access_level} />

          <CardTableRow
            rowName="Mobile"
            rowValue={currentRecord?.user_id?.mobile}
          />
          <CardTableRow
            rowName="Email"
            rowValue={currentRecord?.user_id?.email}
          />
          <CardTableRow rowName="State" rowValue={currentRecord?.state} />
          <CardTableRow
            rowName="District"
            rowValue={currentRecord?.user_id?.district}
          />
          <CardTableRow rowName="TC" rowValue={currentRecord?.user_id?.tc_id} />
          {/* {JSON.stringify(currentRecord?.user_id)} */}
        </Card.Body>
      </Card>
    </div>
  );
};

const CardTableRow = ({ rowName, rowValue }) => {
  return (
    <div className="row">
      <div
        className="col-md-6 col-xs-6 divTableCell"
        style={{ wordBreak: "break-word" }}
      >
        <b> {rowName}: </b>
      </div>
      <div
        className="col-md-6 col-xs-6 divTableCell"
        style={{ wordBreak: "break-word" }}
      >
        {rowValue}
      </div>
    </div>
  );
};
export default ErrorLogChild;

import React from "react";
import Select from "react-select";
const FfdMuscleScoreTab = ({ formData, dropDownChange }) => {
  const dropDown01 = [
    {
      id: 1,
      name: "<15%",
      value: 0,
    },
    {
      id: 2,
      name: "≥ 15%",
      value: 1,
    },
  ];

  const dropDown02 = [
    {
      id: 1,
      name: "Absent",
      value: 0,
    },
    {
      id: 2,
      name: "Present (>1cm)",
      value: 1,
    },
  ];

  const FfdAndMuscles = {
    ffd_left: ["ankle_left_ffd", "elbow_left_ffd", "knee_left_ffd"],
    ffd_right: ["ankle_right_ffd", "elbow_right_ffd", "knee_right_ffd"],
    muscles_atrophy_left: [
      "ankle_left_muscle_atrophy",
      "elbow_left_muscle_atrophy",
      "knee_left_muscle_atrophy",
    ],
    muscles_atrophy_right: [
      "ankle_right_muscle_atrophy",
      "elbow_right_muscle_atrophy",
      "knee_right_muscle_atrophy",
    ],
  };

  return (
    <div className="table-responsive w-100" style={{ marginRight: "40px" }}>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col" className="text-center">
              Ankle
            </th>
            <th scope="col" className="text-center">
              Elbow
            </th>
            <th scope="col" className="text-center">
              Knee
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colSpan={4}>FFD</th>
          </tr>
          <tr>
            <td className="table-row-label">Left</td>
            {FfdAndMuscles.ffd_left.map((left_ffd, index) => (
              <td className="table-row-options">
                <DropDownMenu
                  fieldName={left_ffd}
                  dropDownChange={dropDownChange}
                  defvalue={formData[FfdAndMuscles.ffd_left[index]]}
                  dropDownValues={dropDown01}
                />
              </td>
            ))}
          </tr>
          <tr>
            <td className="table-row-label">Right</td>
            {FfdAndMuscles.ffd_right.map((right_ffd, index) => (
              <td className="table-row-options">
                <DropDownMenu
                  fieldName={right_ffd}
                  dropDownChange={dropDownChange}
                  defvalue={formData[FfdAndMuscles.ffd_right[index]]}
                  dropDownValues={dropDown01}
                />
              </td>
            ))}
          </tr>
          <tr>
            <th colSpan="4">MUSCLE ATROPHY</th>
          </tr>
          <tr>
            <td className="table-row-label">Left</td>
            {FfdAndMuscles.muscles_atrophy_left.map((ma_left, index) => (
              <td className="table-row-options">
                <DropDownMenu
                  fieldName={ma_left}
                  dropDownChange={dropDownChange}
                  defvalue={formData[FfdAndMuscles.muscles_atrophy_left[index]]}
                  dropDownValues={dropDown02}
                />
              </td>
            ))}
          </tr>
          <tr>
            <td className="table-row-label">Right</td>
            {FfdAndMuscles.muscles_atrophy_right.map((ma_right, index) => (
              <td className="table-row-options">
                <DropDownMenu
                  fieldName={ma_right}
                  dropDownChange={dropDownChange}
                  defvalue={
                    formData[FfdAndMuscles.muscles_atrophy_right[index]]
                  }
                  dropDownValues={dropDown02}
                />
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FfdMuscleScoreTab;

const DropDownMenu = ({
  fieldName,
  dropDownChange,
  dropDownValues,
  defvalue,
}) => {
  const dropDownOptions = dropDownValues.map((ddval) => ({
    value: ddval.value,
    label: ddval.name,
  }));

  return (
    <div className="newPatientItem">
      <Select
        name={fieldName}
        onChange={dropDownChange}
        value={dropDownOptions.find((v) => v.value === parseInt(defvalue))}
        className="newPatientSelect"
        options={dropDownOptions}
      />
    </div>
  );
};

import "./ProductsList.css";

import ProductsListChild from "./ProductsListChild";
import { useParams } from "react-router-dom";
import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

const ProductsList = () => {
  const { patientRecordId } = useParams();
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="ListProduct" route="/products">
        <ProductsListChild patientRecordId={patientRecordId} />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default ProductsList;

import React from "react";
import { useLocation } from "react-router-dom";

import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import UpdateSupplierChild from "./UpdateSupplierChild";

const UpdateSupplier = () => {
  const { state } = useLocation();
  let supplierRecord = state;
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="UpdateSupplier" route="/updatesupplier">
        <UpdateSupplierChild supplierRecord={supplierRecord} />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default UpdateSupplier;

import { useEffect, useState, useContext } from "react";
import ScatterChartWithCells from "../../../../components/Chart/ScatterChartWithCells";

const BleedWiseReportChild = () => {
  const data = [
    { x: 100, y: 200, z: 200 },
    { x: 120, y: 100, z: 260 },
    { x: 190, y: 300, z: 400 },
    { x: 140, y: 250, z: 280 },
    { x: 150, y: 400, z: 500 },
    { x: 110, y: 280, z: 200 },
  ];

  return (
    <div className="patient-update w-50">
      <ScatterChartWithCells data={data} />
    </div>
  );
};

export default BleedWiseReportChild;

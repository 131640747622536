import "./PatientList.css";
import PatientListChild from "./PatientListChild";
import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

const PatientList = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="PatientListChild" route="/patients">
        <PatientListChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default PatientList;

import React from "react";
import { useLocation } from "react-router-dom";
import MainContentCard from "../../Home/MainContentCard";
import PatientReportChild from "./PatientReportChild";

import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

const PatientReport = () => {
  const { state } = useLocation();

  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="PatientReportChild" route="/report">
        <PatientReportChild patientRecord={state} />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default PatientReport;

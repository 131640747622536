import "./PatientUpdate.css";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-ui/core";
import validator from "aadhaar-validator";
import { confirmAlert } from "react-confirm-alert";
import {
  bloodGroupData,
  idCardType,
  patientFamilyAnnualIncome,
  employmentType,
  educationQualification,
  diagnosisData,
  diagnosisTypeData,
  precipitatingFactorData,
} from "../../../components/Data/ChartData";

import indianCities from "../../../components/Data/IndianCities";
import axios from "axios";
import toast from "react-hot-toast";
import { Tabs, Tab } from "react-bootstrap";
import GeneralTab from "./GeneralTab";
import ClinicalTab from "./ClinicalTab";
import ContactTab from "./ContactTab";

import VaccinationTab from "./VaccinationTab";
import SchedulingTab from "./SchedulingTab";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

//Context import
import AuthContext from "../../../components/shared/Context";

import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

import "bootstrap/dist/css/bootstrap.min.css";
import BackButton from "../../../components/Buttons/BackButton";

const PatientUpdateChild = ({ patient_id }) => {
  const {
    checkLoggedInStatus,
    loggedInUserDetails,
    selectedDiagnosis,
    setSelectedDiagnosis,
  } = useContext(AuthContext);
  const [patientProfile, setPatientProfile] = useState({});
  const [{ customEncrypt }] = useAtom(gbState);

  useEffect(() => {
    const loadPatientProfile = () => {
      const userToken = localStorage.getItem("token");

      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/patient/profile/${customEncrypt(
          patient_id
        )}`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
        .then((response) => {
          let patientProfile1 = response.data.patient;
          setPatientProfile(response.data.patient);

          setSelectedDiagnosis({
            id: patientProfile1.diagnoses.diagnosis_code,
            label: patientProfile1.diagnoses.diagnosis_name,
          });
          // console.log(JSON.stringify(patientProfile1.diagnoses));
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });
    };

    loadPatientProfile();
  }, [patient_id]);

  return (
    <div className="patient-update">
      {/* {JSON.stringify(selectedDiagnosis)} */}
      <span className="text-bold">{patientProfile?.patient_name}</span> &nbsp;
      <span>({selectedDiagnosis?.label})</span>
      <FormElement
        patientProfile={patientProfile}
        patient_id={patient_id}
        setSelectedDiagnosis={setSelectedDiagnosis}
      />
    </div>
  );
};

const FormElement = ({ patientProfile, patient_id, setSelectedDiagnosis }) => {
  let navigate = useNavigate();
  const [{ encryptData, decryptData }] = useAtom(gbState);
  const { loggedInUserDetails, selectedDiagnosis } = useContext(AuthContext);
  const [patientId, setPatientId] = useState(patient_id);
  const [selectedCard, setSelectedCard] = useState("ID Card");
  const [stateValue, setStateValue] = useState(loggedInUserDetails.state);
  const [city, setCity] = useState();
  const [formData, setFormData] = useState({});
  const [localRegField, setLocalRegField] = useState(true);
  //Created a set for indian cities
  const statesSet = new Set(
    indianCities.map((city, index) => {
      return city.state;
    })
  );

  // useEffect(() => {
  //   setSelectedDiagnosis({
  //     id: formData.diagnosis_code,
  //     label: formData.diagnosis_name,
  //   });
  // }, [formData]);

  //Converted Set to Array of States
  const states = [...statesSet];

  const handleRecover = (e) => {
    confirmAlert({
      title: "",
      message: "Are you sure to Recover this patient ?",
      buttons: [
        {
          label: "Yes",
          onClick: (e) => recoverRecord(e),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const recoverRecord = (e) => {
    //alert(JSON.stringify(patientProfile.id_card.card_number));
    //return;

    let userToken = localStorage.getItem("token");
    if (loggedInUserDetails.role !== "FULL_ACCESS") {
      toast.error(
        "To recover Record you need Administrator/ State Admin access"
      );
      return;
    }
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/patient/recover/${formData._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: {
        ...formData,
        card_number: encryptData(
          formData?.card_number.replace(/-/g, "").toString()
        ),
      },
    };
    axios(config)
      .then(function (response) {
        toast.success("Patient Recovered Successfully.");
        navigate("/patients");
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data.message));
        console.log(error);
      });
  };

  const handlePatientRegistration = (e) => {
    e.preventDefault();
    confirmAlert({
      title: "",
      message: "Are you sure to Update this patient ?",
      buttons: [
        {
          label: "Yes",
          onClick: (e) => confirmhandlePatientRegistration(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  function removeDashes(str) {
    // Check if the string contains a dash
    if (str && str.includes("-")) {
      return str.replace(/-/g, "");
    }
    return str;
  }

  const confirmhandlePatientRegistration = () => {
    //e.preventDefault();
    // alert(JSON.stringify({ ...formData }));
    let userToken = localStorage.getItem("token");

    if (
      loggedInUserDetails.role === "ST_ADM_ACCESS" ||
      loggedInUserDetails.role === "FULL_ACCESS"
    ) {
      toast.error(
        "State Admin cannot create the Patient record, Kindly login as Doctor/Nurse/Deo"
      );
      return;
    }

    if (!formData.treatment_center) {
      toast.error("Please add Treatment Center for Patient");
      return;
    }

    if (!validator.isValidNumber(removeDashes(formData?.card_number))) {
      toast.error("Please add Valid Adhaar Card Number for Patient");
      console.log("Adhaar Card: ", formData?.card_number);
      return;
    }

    //console.log(formData?.card_number);
    // return;

    let postdata = null;
    if (formData?.card_number) {
      postdata = {
        ...formData,
        card_number: encryptData(removeDashes(formData?.card_number)),
      };
    }

    if (!formData.belongs_to) {
      toast.error("Please Set Adhaar Belongs to ... for Patient");
      return;
    }

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/patient/edit`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: {
        ...postdata,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("Patient Updated Successfully.");
        navigate("/patients");
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data.message));
        console.log(error);
      });
  };

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
      // patientId,
      // treatment_center: "District Hospital Ludhiana",
    });
  };

  const dropDownChange = (selected, dropdown) => {
    // console.log("this is selected", JSON.stringify(dropdown.name));
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
      //[dropdown.name]: JSON.stringify(selected),
      // patientId,
    });
  };

  const adhaarValidator = ({ currentTarget: input }) => {
    if (!validator.isValidNumber(input.value)) {
      toast.error("Please input correct aadhar number");
    }
  };

  useEffect(() => {
    if (JSON.stringify(Object.keys(patientProfile).length)) {
      setFormData({
        ...patientProfile.clinical_details,
        ...patientProfile.diagnoses,
        ...patientProfile.contact,
        ...patientProfile.residential_address,
        ...patientProfile.id_card,
        ...patientProfile.vaccinations,
        ...patientProfile.bleeding_history,
        ...patientProfile.scheduling,
        ...patientProfile.economic_status,
        _id: patientProfile._id,
        card_number:
          patientProfile?.id_card?.card_number &&
          (patientProfile?.id_card?.card_number.length > 12
            ? decryptData(patientProfile?.id_card?.card_number)
            : patientProfile?.id_card?.card_number),
        patientId: patientProfile.patientId,
        local_registration_id: patientProfile.local_registration_id,
        patient_name: patientProfile.patient_name,
        gender: patientProfile.gender,
        date_of_birth: patientProfile.date_of_birth,
        minor_patient: patientProfile.minor_patient,
        weight: patientProfile.weight,
        height: patientProfile.height,
        bloodgroup: patientProfile.bloodgroup,
        mothers_name: patientProfile.mothers_name,
        fathers_name: patientProfile.fathers_name,
        marital_status: patientProfile.marital_status,
        spouse_name: patientProfile.spouse_name,
        consanguinity: patientProfile.consanguinity,
        hla_status: patientProfile.hla_status,
        languages_known: patientProfile.languages_known,
        education: patientProfile.education,
        treatment_center: patientProfile.treatment_center,
        tc_state: patientProfile.tc_state,
        delete_status: patientProfile.delete_status,
        flag_patient: patientProfile.flag_patient,
      });
    }
  }, [patientProfile]);

  return (
    <form onSubmit={(e) => handlePatientRegistration(e)}>
      <div className="submitButton">
        {/* <h1 className="newPatientTitle">New Patient</h1> */}
        <div className="patientUpdateButton">
          <Button
            variant="contained"
            color="primary"
            size="medium"
            type="submit"
            disabled={formData.delete_status ? true : false}
          >
            Update Patient
          </Button>
          {formData.delete_status &&
          loggedInUserDetails.role == "FULL_ACCESS" ? (
            <Button
              variant="contained"
              color="secondary"
              size="medium"
              onClick={handleRecover}
            >
              Recover
            </Button>
          ) : null}
          &nbsp; &nbsp;
          <BackButton />
        </div>
      </div>
      {/* {JSON.stringify(formData)} */}
      {/* {JSON.stringify(patientProfile.length)} */}
      {/* {JSON.stringify(Object.keys(patientProfile).length)} */}
      {/* {JSON.stringify(selectedDiagnosis)} */}
      <Tabs
        defaultActiveKey="general"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="general" title="General">
          <GeneralTab
            patientId={patientId}
            handleChange={handleChange}
            dropDownChange={dropDownChange}
            bloodGroupData={bloodGroupData}
            setSelectedCard={setSelectedCard}
            idCardType={idCardType}
            selectedCard={selectedCard}
            patientFamilyAnnualIncome={patientFamilyAnnualIncome}
            employmentType={employmentType}
            educationQualification={educationQualification}
            diagnosisData={diagnosisData}
            diagnosisTypeData={diagnosisTypeData}
            tcid={loggedInUserDetails.tc_id}
            formData={formData}
            setFormData={setFormData}
            selectedDiagnosis={selectedDiagnosis}
            adhaarValidator={adhaarValidator}
            localRegField={localRegField}
            setLocalRegField={setLocalRegField}
          />
        </Tab>
        <Tab eventKey="clinical" title="Clinical Primary">
          <ErrorBoundaryRoutes page="ClinicalTab" route="/patient-update">
            <ClinicalTab
              handleChange={handleChange}
              dropDownChange={dropDownChange}
              precipitatingFactorData={precipitatingFactorData}
              selectedDiagnosis={selectedDiagnosis}
              formData={formData}
              setFormData={setFormData}
            />
          </ErrorBoundaryRoutes>
        </Tab>
        <Tab eventKey="vaccination" title="Vaccinations">
          <VaccinationTab
            handleChange={handleChange}
            dropDownChange={dropDownChange}
            setFormData={setFormData}
            formData={formData}
          />
        </Tab>
        <Tab eventKey="contact" title="Contact">
          <ContactTab
            handleChange={handleChange}
            dropDownChange={dropDownChange}
            setStateValue={setStateValue}
            states={states}
            city={city}
            stateValue={stateValue}
            setCity={setCity}
            indianCities={indianCities}
            setFormData={setFormData}
            formData={formData}
            tc_state={loggedInUserDetails.state}
          />
        </Tab>
        {/* <Tab eventKey="schedule" title="Scheduling">
          <SchedulingTab
            handleChange={handleChange}
            setFormData={setFormData}
            formData={formData}
          />
        </Tab> */}
      </Tabs>
      <hr />
      <div className="helper-text">
        <b>Consanguinity</b> : Is the marriage between parents Consanguineous?
        Change/Modify only if you are sure.
        <br />
      </div>
      {formData.diagnosis_name &&
        formData.diagnosis_name.toLowerCase().includes("thalassemia") && (
          <div className="helper-text">
            <br /> <b>Transfusion Regimen</b> : Hypo transfusion is usually
            associated with only 1 bag transfusion. Hyper transfusion and Super
            transfusion target a post-transfusion Hb of 13-14gm/dl. The system
            uses 13.5 gm/dl for calculations of blood requirement.
            <br />
            <br /> <b>Number of Transfusions</b> : No. of tranfusions recieved
            prior to registration in the center.
          </div>
        )}
    </form>
  );
};

export default PatientUpdateChild;

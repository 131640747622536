import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
//Context import
import AuthContext from "../../../components/shared/Context";

const CreateRequestChild = () => {
  const { checkLoggedInStatus, loggedInUserDetails, selectedDiagnosis } =
    useContext(AuthContext);
  let navigate = useNavigate();
  const [products, setProducts] = useState([]);
  let defaultDate = new Date();
  const [date, setDate] = useState(defaultDate);

  const [formData, setFormData] = useState({
    product_id: null,
    requested_quantity: null,
    requester_comments: "Request you to please furnish the given request asap.",
    requested_date: date.toLocaleDateString("en-CA"),
    request_status: 0,
    state: loggedInUserDetails.state,
    tc_id: loggedInUserDetails.tc_id ? loggedInUserDetails.tc_id : null,
    requester_id: loggedInUserDetails._id,
  });

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/product/${loggedInUserDetails.state}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data.product));
        const oldData = response.data.product;
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data._id,
            product_name: data.products.product_name,
            measuring_unit: data.products.measuring_unit,
            company_name: data.products.company_name,
            company_email: data.products.company_email,
          };
        });
        // console.log(updatedData);
        setProducts(updatedData);
      })
      .catch(function (error) {
        console.log(error.message);
      });
  };

  useEffect(() => {
    getDataFromServer();
  }, []);

  const productsOptions = products.map((prod) => ({
    value: prod._id,
    label: prod.products.product_name,
  }));

  // console.log(productsOptions);

  const addPurchaseRequest = (e) => {
    e.preventDefault();
    //changeTab(1);

    if (!formData.product_id) {
      toast.error("Please select a Product to request & quantity!");
      return;
    }

    let userToken = localStorage.getItem("token");
    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/purchaseRequest/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, purchaserequests: true },
    };
    axios(config)
      .then(function (response) {
        toast.success("Request Created Successfully.");
        navigate("/purchase-requests");
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data.message));
        //  console.log(JSON.stringify(error.response.data));
        if (error.response.data.message.toLowerCase().includes("invalid")) {
          navigate("/login");
        }
      });
  };

  const handleChangeValue = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const dropDownChange = (selected, dropdown) => {
    console.log("this is selected", JSON.stringify(dropdown.name));
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "70vh" }}
    >
      <div className="col-md-6 mx-auto d-block d-flex signup-form">
        <form
          className="mt-5 border p-4 shadow"
          onSubmit={(e) => addPurchaseRequest(e)}
        >
          <h5 className="text-secondary">CREATE REQUEST</h5>
          <hr />
          <br />
          <div className="row">
            <div className="mb-3 col-md-6">
              <label>
                Enter Product<span className="text-danger">*</span>
              </label>
              <Select
                name="product_id"
                onChange={dropDownChange}
                value={productsOptions.find(
                  (prod) => prod.value === formData.product_id
                )}
                className="newPatientSelect"
                isSearchable
                options={productsOptions}
              />
            </div>
            <div className="mb-3 col-md-6">
              <label>
                Enter Quantity<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="requested_quantity"
                className="form-control"
                placeholder="Enter Units required"
                onChange={handleChangeValue}
                value={formData.requested_quantity}
                required
              />
            </div>
            <div className="mb-3">
              <label>Comments</label>
              <textarea
                type="textarea"
                name="requester_comments"
                className="form-control"
                placeholder="Product Description"
                rows="5"
                onChange={handleChangeValue}
                value={formData.requester_comments}
              />
            </div>

            <div className="col-md-12">
              <button className="btn-lg btn-danger float-end">
                Create Request
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateRequestChild;

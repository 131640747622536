import React, { useState, useEffect, useContext, useRef } from "react";
import { Button } from "@material-ui/core";
import styles from "./UpdateUserProfile.module.css";

import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";

//Context import
import AuthContext from "../../../components/shared/Context";

const UpdateUserProfileChild = () => {
  //const { user_id } = useParams();
  const { checkLoggedInStatus, states, loggedInUserDetails } =
    useContext(AuthContext);

  let user_id = loggedInUserDetails._id;
  let navigate = useNavigate();

  //To check if the user is logged in
  useEffect(() => {
    checkLoggedInStatus();
  }, []);

  const [tcStates, setTcStates] = useState([]);

  const [confirmPassword, setConfirmPassword] = useState(null);
  const [rolesValue, setRolesValue] = useState([]);
  const [disablePassword, setDisablePassword] = useState(true);
  const currentPasswordRef = useRef();
  const newPasswordRef = useRef();
  const confirmPasswordRef = useRef();
  const [formData, setFormData] = useState({
    first_name: null,
    last_name: null,
    mobile: null,
    email: null,
    old_password: null,
    password: null,
    role: "TC_USER_ACCESS",
    user_type: "Doctor",
    tc_state: loggedInUserDetails.state,
    tc_district: loggedInUserDetails.district,
    tc_id: loggedInUserDetails.tc_id,
    account_status: true,
    createdBy: loggedInUserDetails._id,
  });

  useEffect(() => {
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/tc`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios(config)
      .then(function (response) {
        const fetchedTcData = response.data.treatment_center;
        const tcData = fetchedTcData.map((tc) => {
          return {
            id: tc._id,
            tc_name: tc.tcenters.name_tc,
            state: tc.tcenters.state,
            district: tc.tcenters.district,
          };
        });
        //   console.log(JSON.stringify(tcData));
        setTcStates([...tcData]);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, []);

  //Get User Profile
  useEffect(() => {
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/user/${user_id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios(config)
      .then(function (response) {
        const userData = response.data.users;
        setFormData({ ...formData, ...userData, password: null });
        // console.log(JSON.stringify(userData));
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  //Load Roles on page load
  useEffect(() => {
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/roles`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios(config)
      .then(function (response) {
        const fetchedRolesData = response.data.roles;
        setRolesValue(fetchedRolesData);
        //console.log(JSON.stringify(fetchedRolesData));
      })
      .catch(function (error) {
        //console.log(error);
      });
  }, []);

  const handleUpdateUserRegistration = (e) => {
    e.preventDefault();

    if (!disablePassword) {
      if (formData.password !== confirmPassword) {
        toast.error("Password not matching!");
        return;
      }
    }

    ///profile/update/
    // const url_link = `${process.env.REACT_APP_API_URL}/user/update/${user_id}`;
    const url_link = `${process.env.REACT_APP_API_URL}/user/profile/update/`;

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: url_link,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        toast.success("User Updated Successfully.");
        setFormData({ ...formData, old_password: null, password: null });
        setConfirmPassword(null);
        confirmPasswordRef.current.value = null;
        newPasswordRef.current.value = null;
        currentPasswordRef.current.value = null;
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(error?.response?.data?.message);
        // console.log(error);
      });
  };

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  return (
    <div>
      <div>
        <h2>Update User</h2>
      </div>
      {/* {JSON.stringify({ ...formData })} */}
      {/* {JSON.stringify(loggedInUserDetails)} */}
      <form
        onSubmit={(e) => handleUpdateUserRegistration(e)}
        autoComplete="off"
      >
        <div className="patientUpdateButton">
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
          >
            Update
          </Button>
        </div>
        <div className={styles.newFormUsersOnly}>
          <div className="newPatientItem w-25">
            <label htmlFor="first_name">First Name</label>
            <input
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="last_name">Last Name</label>
            <input
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="mobile">Mobile</label>
            <input
              type="text"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              autoComplete="new-password"
              disabled
            />
          </div>

          <div className="newPatientItem w-25">
            <label htmlFor="password">Current Password</label>
            <input
              type="password"
              name="old_password"
              onChange={handleChange}
              autoComplete="new-password"
              ref={currentPasswordRef}
            />
          </div>

          <div className="newPatientItem w-25">
            <label htmlFor="password">
              New Password &nbsp;&nbsp;
              <input
                type="checkbox"
                onChange={() => {
                  setDisablePassword(!disablePassword);
                }}
              />
            </label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              autoComplete="new-password"
              disabled={disablePassword}
              ref={newPasswordRef}
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="password">Confirm Password</label>
            <input
              type="password"
              name="confim_password"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              autoComplete="new-password"
              disabled={disablePassword}
              ref={confirmPasswordRef}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateUserProfileChild;

import "./PatientProfileVisit.css";
import { useContext } from "react";
// import { Button } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PatientInfoBlock from "../../../components/PatientProfile/PatientInfoBlock";

import PatientEMRView from "./PatientEMRView";
import VisitInfoBlock from "./VisitInfoBlock";
import BackButton from "../../../components/Buttons/BackButton";

import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
//Context import
import AuthContext from "../../../components/shared/Context";

const PatientVisitChild = ({
  patientRecordId,
  patientInfo,
  patientVisit,
  patientEmr,
}) => {
  let navigate = useNavigate();
  const patientDiagnosis = patientInfo.diagnoses.diagnosis_name;
  const { loggedInUserDetails } = useContext(AuthContext);
  return (
    <div className="patient-update">
      {/* {JSON.stringify(patientVisit?.treatment_center_id?._id)} */}
      {/* {JSON.stringify(loggedInUserDetails?.tc_id)} */}
      <div className="patientUpdateButton">
        <Button
          variant="warning"
          size="md"
          type="submit"
          onClick={() => {
            navigate(`/patient/emr/`, {
              state: { patientData: patientInfo, emr_id: patientEmr._id },
            });
          }}
          disabled={
            patientVisit?.treatment_center_id?._id ===
            loggedInUserDetails?.tc_id
              ? false
              : true
          }
        >
          Edit
        </Button>
        &nbsp;
        <BackButton /> &nbsp;&nbsp;
        <Button variant="secondary" size="md" onClick={() => navigate(-1)}>
          Back
        </Button>
      </div>
      <ErrorBoundaryRoutes
        page="PatientVisitChild->PatientInfoBlock"
        route="/visit"
      >
        <PatientInfoBlock selectedPatientProfile={patientInfo} />
      </ErrorBoundaryRoutes>
      <ErrorBoundaryRoutes
        page="PatientVisitChild->VisitInfoBlock"
        route="/visit"
      >
        <VisitInfoBlock
          selectedPatientVisit={patientVisit}
          PatientDiagnosis={patientDiagnosis}
        />
      </ErrorBoundaryRoutes>
      <ErrorBoundaryRoutes
        page="PatientVisitChild->PatientEMRView"
        route="/visit"
      >
        <PatientEMRView
          selectedPatientEmr={patientEmr}
          PatientDiagnosis={patientDiagnosis}
        />
      </ErrorBoundaryRoutes>
    </div>
  );
};

export default PatientVisitChild;

import React, { useEffect } from "react";
import * as XLSX from "xlsx";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

const ExcelDownloader = ({
  data,
  filename = "download.xlsx",
  excludedFields = [],
}) => {
  let navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;

  const addDownloadLog = () => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/alog/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: {
        log_detail: JSON.stringify(data),
        activity: "Download",
        page: "Excel Download",
        page_route: currentUrl,
      },
    };
    axios(config)
      .then(function (response) {
        // toast.success("Request Created Successfully.");
        // navigate("/list-purchases");
      })
      .catch(function (error) {
        // toast.error(JSON.stringify(error.response.data.message));
        //console.log(JSON.stringify(error.response));
        if (error.response.data.message.toLowerCase().includes("invalid")) {
          navigate("/login", { replace: true });
        }
      });
  };

  const handleDownload = () => {
    addDownloadLog();
    // Filter out the excluded fields from the data
    const filteredData = data.map((item) => {
      const newItem = { ...item };
      excludedFields.forEach((field) => delete newItem[field]);
      return newItem;
    });

    // Create a new workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filteredData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate an XLSX file
    XLSX.writeFile(wb, filename);
  };

  return (
    <Button onClick={handleDownload} variant="success">
      Download Excel
    </Button>
  );
};

export default ExcelDownloader;

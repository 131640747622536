import { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button } from "react-bootstrap";
import { DatePicker } from "antd";
import toast from "react-hot-toast";
import AlertDialog from "../../../components/InfoDialog/AlertDialog";
import InputGroup from "react-bootstrap/InputGroup";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import BackButton from "../../../components/Buttons/BackButton";

//Context import
import AuthContext from "../../../components/shared/Context";
import Form from "react-bootstrap/Form";

const AddKnowledgebaseChild = () => {
  return (
    <div className="patient-create">
      <div className="patientUpdateButton">
        <Button variant="primary" size="large" onClick={() => {}}>
          Create
        </Button>{" "}
      </div>
      <div>
        <h1 className="allPatients">Add Knowledgebase</h1>
      </div>
      <FormElement />
    </div>
  );
};

const FormElement = () => {
  return (
    <form>
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">Publication title</InputGroup.Text>
        <Form.Control
          aria-label="Publication title"
          aria-describedby="basic-addon1"
          name="publication_title"
          onChange={(e) => {}}
        />
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon2">Publication Name</InputGroup.Text>
        <Form.Control
          aria-label="Publication Name"
          aria-describedby="basic-addon1"
          name="publication_name"
          onChange={(e) => {}}
        />
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon3">Author Name</InputGroup.Text>
        <Form.Control
          aria-label="Author Name"
          aria-describedby="basic-addon1"
          name="author_name"
          onChange={(e) => {}}
        />
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon4">Keywords</InputGroup.Text>
        <Form.Control
          aria-label="Keywords"
          aria-describedby="basic-addon4"
          name="keywords"
          onChange={(e) => {}}
        />
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon5">Publication Date</InputGroup.Text>
        <Form.Control
          aria-label="Keywords"
          aria-describedby="basic-addon4"
          name="date"
          type="date"
          onChange={(e) => {}}
        />
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon5">Publication URL</InputGroup.Text>
        <Form.Control
          aria-label="Keywords"
          aria-describedby="basic-addon4"
          name="publication_url"
          onChange={(e) => {}}
        />
      </InputGroup>
      <InputGroup className="mb-3">
        <Form.Control
          aria-label="Keywords"
          aria-describedby="basic-addon4"
          name="file"
          type="file"
          onChange={(e) => {}}
        />
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon5">Publication Summary</InputGroup.Text>
        <Form.Control
          aria-label="Keywords"
          aria-describedby="basic-addon4"
          name="publication_summary"
          as="textarea"
          row="4"
          onChange={(e) => {}}
        />
      </InputGroup>
    </form>
  );
};

export default AddKnowledgebaseChild;

import React from "react";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import axios from "axios";

import AddDeleteTableRows from "../../../../components/Medicine/AddDeleteTableRows";

const TreatmentAdviceTab = ({
  emrRecordId,
  emrRecord,
  emrRecordLocal,
  setEmrRecordLocal,
}) => {
  const [formData, setFormData] = useState(
    emrRecord.selectedPatientEmr.treatment_advice
  );

  // console.log("This is log");
  const [treatmentAdvice, setTreatmentAdvice] = useState(formData);

  useEffect(() => {
    try {
      // console.log("Show when data is there ", emrRecordLocal.treatment_advice);
      //console.log("Is this being called on every re-render?");
      if (emrRecord.selectedPatientEmr.treatment_advice.t_advice) {
        setFormData({
          ...formData,
          t_advice: emrRecord.selectedPatientEmr.treatment_advice.t_advice,
          treatment_given: [
            ...emrRecord.selectedPatientEmr.treatment_advice.treatment_given,
          ],
        });
      }
    } catch (err) {}
  }, [emrRecord]);

  useEffect(() => {
    if (treatmentAdvice) {
      setFormData({ ...formData, ...treatmentAdvice });
    }
  }, [treatmentAdvice]);

  const updateEmr = (e) => {
    e.preventDefault();

    let userToken = localStorage.getItem("token");
    const config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/emr/update/${emrRecordId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, treatment_advice: true },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));

        //localStorage.setItem(
        //  `${emrRecordId}-tadvice`,
        //  JSON.stringify(formData)
        //);

        toast.success("Treatment advice saved Successfully.");
        setEmrRecordLocal(response.data.emr);
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error.response.data));
      });
  };

  const handleChangeValue = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  return (
    <form className="d-flex flex-sm-wrap" onSubmit={(e) => updateEmr(e)}>
      {/* {JSON.stringify(formData)} */}
      <div className="table-responsive w-100" style={{ marginRight: "40px" }}>
        <div className="ms-4 me-4">
          {/* <div className="patientUpdateButton">
            <Button variant="primary" size="large" type="submit">
              Save
            </Button>
          </div> */}
          {/* {emrRecord &&
            JSON.stringify(emrRecord.selectedPatientEmr.treatment_advice)} */}
          {/* {emrRecordLocal && JSON.stringify(emrRecordLocal.treatment_advice)} */}
          {/* <br />
          <br />
          {JSON.stringify(emrRecordLocal.treatment_advice)} */}
          {/* <br />
          <br /> */}
          {/* {JSON.stringify(formData)} */}
          <div className="newPatientItem w-100">
            {/* {formData &&
              (formData?.treatment_given?.length === 0 ||
                formData?.treatment_given?.length > 0) && (
                <AddDeleteTableRows
                  formData={formData}
                  setFormData={setFormData}
                  editable={true}
                />
              )} */}
            {/* {emrRecordLocal && JSON.stringify(formData)} */}

            {emrRecordLocal &&
            emrRecordLocal.treatment_advice.treatment_given.length > 0
              ? formData &&
                (formData?.treatment_given?.length === 0 ||
                  formData?.treatment_given?.length > 0) && (
                  <AddDeleteTableRows
                    formData={formData}
                    setFormData={setFormData}
                    editable={false}
                  />
                )
              : // <AddDeleteTableRows
                //   formData={formData}
                //   setFormData={setFormData}
                //   editable={false}
                // />
                null}
          </div>
          <div className="newPatientItem w-100">
            <label htmlFor="weight">Treatment Advice</label>
            <textarea
              className="form-control"
              name="t_advice"
              placeholder="Details..."
              rows={4}
              onChange={handleChangeValue}
              value={formData && formData.t_advice}
              disabled
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default TreatmentAdviceTab;

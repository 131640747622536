import React from "react";

import Topbar from "../../../../components/Topbar/Topbar";
import SideBar from "../../../../components/SideBar/SideBar";

import "./AgeWiseReport.css";
import AgeWiseReportChild from "./AgeWiseReportChild";

const AgeWiseReport = ({ patientData }) => {
  return (
    <div className="parent-container">
      <AgeWiseReportChild patientData={patientData} />
    </div>
  );
};

export default AgeWiseReport;

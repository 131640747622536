import React, { useState, useEffect } from "react";
import { Card, Modal } from "react-bootstrap";
import ReactPlayer from "react-player";

import "./VideoTile.css";

const VideoTile = ({ videoUrl, title }) => {
  const [showModal, setShowModal] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const getThumbnail = async () => {
      try {
        const videoId = extractVideoId(videoUrl);
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=AIzaSyA9FCoLFsJJfzzvOnbmVmNyevs1coAL6Es`
        );
        const data = await response.json();
        const thumbnailUrl = data.items[0].snippet.thumbnails.medium.url;
        setThumbnailUrl(thumbnailUrl);
      } catch (error) {
        console.error("Error fetching video thumbnail:", error);
      }
    };

    getThumbnail();
  }, [videoUrl]);

  const extractVideoId = (url) => {
    const match = url.match(/[?&]v=([^&#]+)/);
    return match ? match[1] : null;
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleHoverEnter = () => {
    setIsHovered(true);
  };

  const handleHoverLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <Card
        style={{
          width: "18rem",
          margin: "5px",
          cursor: "pointer",
          paddingTop: "15px",
          backgroundColor: isHovered ? "dark" : "inherit",
        }}
        onClick={handleOpenModal}
        onMouseEnter={handleHoverEnter}
        onMouseLeave={handleHoverLeave}
      >
        {thumbnailUrl && <Card.Img variant="top" src={thumbnailUrl} />}
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          {/* <Card.Text>{videoUrl}</Card.Text> */}
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Video Player</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReactPlayer url={videoUrl} controls width="100%" height="500px" />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VideoTile;

import React from "react";
import Select from "react-select";

const OsteoperosisXray = ({ titleText, formData, setFormData }) => {
  return (
    <div className="table-responsive w-100" style={{ marginRight: "40px" }}>
      <table className="table table-striped">
        {/* {JSON.stringify(formData.ankle_left_osteoporosis)} */}
        {/* {JSON.stringify(formData.ankle_right_osteoporosis)} */}
        <thead>
          <tr>
            <th scope="col">{titleText}</th>
            <th scope="col" className="text-center">
              Left
            </th>
            <th scope="col" className="text-center">
              Right
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="table-row-label">Ankle</td>
            <td>
              <div className="newPatientItem">
                <XrayDropDown
                  fieldName="ankle_left_osteoporosis"
                  formData={formData}
                  setFormData={setFormData}
                />
              </div>
            </td>
            <td>
              <div className="newPatientItem">
                <XrayDropDown
                  fieldName="ankle_right_osteoporosis"
                  formData={formData}
                  setFormData={setFormData}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td className="table-row-label">Knee</td>
            <td>
              <div className="newPatientItem">
                <XrayDropDown
                  fieldName="knee_left_osteoporosis"
                  formData={formData}
                  setFormData={setFormData}
                />
              </div>
            </td>
            <td>
              <div className="newPatientItem">
                <XrayDropDown
                  fieldName="knee_right_osteoporosis"
                  formData={formData}
                  setFormData={setFormData}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td className="table-row-label">Elbow</td>
            <td>
              <div className="newPatientItem">
                <XrayDropDown
                  fieldName="elbow_left_osteoporosis"
                  formData={formData}
                  setFormData={setFormData}
                />
              </div>
            </td>
            <td>
              <div className="newPatientItem">
                <XrayDropDown
                  fieldName="elbow_right_osteoporosis"
                  formData={formData}
                  setFormData={setFormData}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default OsteoperosisXray;

const XrayDropDown = ({ fieldName, formData, setFormData }) => {
  const dropDownOptions = [
    { value: 1, label: "Absent" },
    { value: 2, label: "Present" },
  ];

  return (
    <Select
      name={fieldName}
      onChange={(selected, dropdown) => {
        setFormData({
          ...formData,
          [fieldName]: selected.value,
        });
      }}
      value={
        formData[fieldName] &&
        dropDownOptions.find(
          (opt) => opt.value === parseInt(formData[fieldName])
        )
      }
      className="newPatientSelect"
      isDisabled={true}
    />
  );
};

import React from "react";
import Card from "react-bootstrap/Card";
import { Form, Row, Col } from "react-bootstrap";

//Global State import
import { useAtom } from "jotai";
import { gbState } from "../../../../components/shared/GlobalState";

const ViewReAllocationContentModal = ({ currentRecord }) => {
  return (
    <div className="container-fluid legend">
      {/* {JSON.stringify(currentRecord)} */}
      <AllocatedProductDetails allocatedDetails={currentRecord} />
    </div>
  );
};

export default ViewReAllocationContentModal;

const AllocatedProductDetails = ({ allocatedDetails }) => {
  //Error Logging Service
  const [
    { errorLogApi, dateFormatter, _calculateDaysLeft, _checkCalculateDaysLeft },
  ] = useAtom(gbState);

  const expirydateFormatter = (date) => {
    const date_conv = new Date(date);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date_conv.getMonth()];
    const year = date_conv.getFullYear();
    return `${month} ${year}`;
  };
  return (
    <Card>
      <Card.Header>
        <i className="fas fa-align-justify" />
        <strong>Allocation Details</strong>
      </Card.Header>
      <Card.Body>
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            <b> Product Name</b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {allocatedDetails?.product_name}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            <b> Product Expiry</b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {expirydateFormatter(allocatedDetails?.product_expiry)}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            <b> Allocated Quantity</b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            <b>{allocatedDetails?.quantity}</b>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            <b> Allocated To</b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {allocatedDetails?.allocated_to}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            <b> Allocation Date</b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {dateFormatter(allocatedDetails?.date_of_allocation)}
          </div>
        </div>
        {/* local_allocation_id */}
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            <b> Local Allocation ID</b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {allocatedDetails?.local_allocation_id}
          </div>
        </div>
        {/* Authrize dby  */}
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            <b> Authorized By</b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {allocatedDetails?.authorized_by}
          </div>
        </div>
        {/* Comments */}
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            <b> Comments</b>
          </div>
        </div>
        <Form>
          <Row>
            <Col xs={12}>
              <Form.Group controlId="commentsForm.ControlTextarea1">
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={allocatedDetails?.comments}
                  disabled
                  className="w-100"
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

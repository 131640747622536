import React, { useState } from "react";
import { useEffect, useContext, useRef } from "react";
import Select from "react-select";
import { DatePicker } from "antd";
import moment from "moment";
import "moment/locale/zh-cn";

import { BsQuestionCircleFill } from "react-icons/bs";
import AlertDialog from "../../../components/InfoDialog/AlertDialog";

//Context import
import AuthContext from "../../../components/shared/Context";

const GeneralTab = ({
  patientId,
  handleChange,
  dropDownChange,
  bloodGroupData,
  patientFamilyAnnualIncome,
  employmentType,
  educationQualification,
  diagnosisData,
  diagnosisTypeData,
  tcid,
  formData,
  setFormData,
  adhaarValidator,
  localRegField,
  setLocalRegField,
}) => {
  const { selectedDiagnosis, setSelectedDiagnosis, setFieldValueFromArray } =
    useContext(AuthContext);

  // useEffect(() => {
  //   setSelectedDiagnosis({
  //     id: formData.diagnosis_code,
  //     label: formData.diagnosis_name,
  //   });
  // }, [formData]);

  const childRef = useRef();
  const [diagnosisTypeOptions, setDiagnosisTypeOptions] = useState(null);
  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Others", label: "Others" },
  ];

  const minorOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const bloodgroupOptions = bloodGroupData.map((data) => ({
    value: data.bloodGroupType,
    label: data.bloodGroupType,
  }));

  const patientAnnualIncomeOptions = patientFamilyAnnualIncome.map((data) => ({
    value: data.incomeType,
    label: data.incomeType,
  }));

  const emplymentTypeOptions = employmentType.map((data) => ({
    value: data.eType,
    label: data.eType,
  }));

  const educationQualOptions = educationQualification.map((data) => ({
    value: data.eqType,
    label: data.eqType,
  }));

  const sourceFundOptions = [
    { value: "ESI", label: "ESI" },
    { value: "CGHS", label: "CGHS" },
    { value: "RAIL", label: "Railways" },
    { value: "DEFN", label: "Defence" },
    { value: "STGOV", label: "State Govt." },
    { value: "OOP", label: "Out of Pocket" },
    { value: "OTHERS", label: "Others" },
  ];

  // const diagnosisOptions = diagnosisData.map((data) => ({
  //   value: data.disease,
  //   label: data.disease,
  // }));

  const maritalOptions = [
    { value: "SINGLE", label: "Single" },
    { value: "MARRIED", label: "Married" },
    { value: "DIVORCED", label: "Divorced" },
    { value: "WIDOWED", label: "Widowed" },
    { value: "UNKOWN", label: "Unknown" },
    { value: "OTHER", label: "Other" },
  ];

  const consanguinityOptions = [
    { value: "100", label: "Unknown" },
    { value: "101", label: "Yes" },
    { value: "102", label: "No" },
  ];

  const hlaStatusOptions = [
    { value: 1, label: "Pending" },
    { value: 2, label: "Done" },
    { value: 3, label: "Unknown" },
  ];

  const languagesKnownOptions = [
    { value: 1, label: "English" },
    { value: 2, label: "Hindi" },
    { value: 3, label: "Punjabi" },
    { value: 4, label: "Bengali" },
    { value: 5, label: "Maithili" },
    { value: 6, label: "Sanskrit" },
    { value: 7, label: "Tamil" },
    { value: 8, label: "Urdu" },
    { value: 9, label: "Assamese" },
    { value: 10, label: "Dogri" },
    { value: 11, label: "Kannada" },
    { value: 12, label: "Gujarati" },
    { value: 13, label: "Bodo" },
    { value: 14, label: "Manipur" },
    { value: 15, label: "Oriya" },
    { value: 16, label: "Marathi" },
    { value: 17, label: "Santali" },
    { value: 18, label: "Telugu" },
    { value: 19, label: "Sindhi" },
    { value: 20, label: "Malayalam" },
    { value: 21, label: "Kashmiri" },
  ];

  const aadharOwnerOptions = [
    { value: 1, label: "Patient" },
    { value: 2, label: "Father" },
    { value: 3, label: "Mother" },
  ];

  function removeDashes(str) {
    // Check if the string contains a dash
    if (str && str.includes("-")) {
      return str.replace(/-/g, "");
    }
    return str;
  }

  useEffect(() => {
    if (formData.diagnosis_name) {
      if (formData.diagnosis_name.toLowerCase().includes("hemophilia")) {
        setDiagnosisTypeOptions(
          diagnosisTypeData.hemophilia.map((data) => ({
            value: data.id,
            label: data.severity_of_illness,
          }))
        );
      } else if (formData.diagnosis_name.toLowerCase().includes("vwd")) {
        setDiagnosisTypeOptions(
          diagnosisTypeData.vwd.map((data) => ({
            value: data.id,
            label: data.severity_of_illness,
          }))
        );
      } else {
        setDiagnosisTypeOptions({});
      }
    }
  }, [formData]);

  function _calculateAge(birthday) {
    const today = new Date();
    const birthDate = new Date(birthday);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  let dob;
  useEffect(() => {
    dob = moment(formData.date_of_birth, "YYYY-MM-DD");
    if (parseInt(_calculateAge(new Date(formData.date_of_birth)) <= 18)) {
      setFormData({ ...formData, minor_patient: true });
    } else {
      setFormData({ ...formData, minor_patient: false });
    }
  }, [formData.date_of_birth]);

  const consanguinityHelp =
    "Consanguinity : Is the marriage between parents Consanguineous? Change/Modify only if you are sure.";

  return (
    <div className="newPatientFormOnly">
      {/* {JSON.stringify(patientForUpdate)} */}
      <AlertDialog ref={childRef} title="Special Tranfusion note" />
      <div className="newPatientItem">
        <label htmlFor="patientId">Registration ID</label>
        <input
          type="text"
          name="patientId"
          value={formData.patientId}
          disabled
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="patient_name">
          Local Registration ID &nbsp;&nbsp;
          <input
            type="checkbox"
            onChange={() => {
              setLocalRegField(!localRegField);
            }}
          />
        </label>
        <input
          type="text"
          name="local_registration_id"
          onChange={handleChange}
          disabled={localRegField}
          value={formData.local_registration_id}
        />
      </div>
      {/* Patient Name */}
      <div className="newPatientItem">
        <label htmlFor="patient_name">
          Patient Name <span className="text-danger">*</span>
        </label>
        <input
          type="text"
          name="patient_name"
          placeholder="Patient Name"
          value={formData.patient_name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="date_of_birth">Date of Birth</label>
        <DatePicker
          className="newPatientSelect datePicker"
          style={{ paddingLeft: "10px" }}
          name="date_of_birth"
          onChange={(date, dateString) => {
            setFormData({
              ...formData,
              date_of_birth: dateString,
            });
          }}
          disabledDate={(current) => {
            let customDate = moment().format("YYYY-MM-DD");
            return current && current > moment(customDate, "YYYY-MM-DD");
          }}
          value={
            formData.date_of_birth &&
            moment(formData.date_of_birth, "YYYY-MM-DD")
          }
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="age_current">Current Age</label>
        <input
          type="text"
          name="age_current"
          value={
            formData.date_of_birth &&
            _calculateAge(new Date(formData.date_of_birth)) + " yrs"
          }
          disabled
        />
      </div>
      <div className="newPatientItem">
        <label>Minor</label>
        <Select
          name="minor_patient"
          onChange={dropDownChange}
          className="newPatientSelect"
          value={
            formData.date_of_birth &&
            minorOptions.find(
              (minor) =>
                minor.value ===
                  parseInt(_calculateAge(new Date(formData.date_of_birth))) &&
                parseInt(_calculateAge(new Date(formData.date_of_birth))) < 18
            )
          }
          options={minorOptions}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="weight">Weight (in Kgs)</label>
        <input
          type="number"
          name="weight"
          placeholder="Weight in Kgs"
          value={formData.weight}
          onChange={handleChange}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="height">Height (in cm)</label>
        <input
          type="number"
          name="height"
          placeholder="in Centimeters"
          value={formData.height}
          onChange={handleChange}
        />
      </div>
      <div className="newPatientItem">
        <label>Gender</label>
        <Select
          name="gender"
          onChange={
            //dropDownChange
            (selected, dropdown) => {
              //console.log(selected);
              setFormData({
                ...formData,
                gender: JSON.stringify(selected),
              });
            }
          }
          value={formData.gender && JSON.parse(formData.gender)}
          className="newPatientSelect"
          options={genderOptions}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
        />
      </div>
      <div className="newPatientItem">
        <label>Marital Status</label>
        <Select
          name="marital_status"
          onChange={
            //dropDownChange
            (selected, dropdown) => {
              //console.log(selected);
              setFormData({
                ...formData,
                marital_status: JSON.stringify(selected),
              });
            }
          }
          value={formData.marital_status && JSON.parse(formData.marital_status)}
          className="newPatientSelect"
          options={maritalOptions}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="patient_name">Spouse Name</label>
        <input
          type="text"
          name="spouse_name"
          placeholder="Spouse Name"
          value={formData.spouse_name}
          onChange={handleChange}
        />
      </div>
      <div className="newPatientItem">
        <label>Blood Group</label>
        <Select
          name="bloodgroup"
          onChange={
            //dropDownChange
            (selected, dropdown) => {
              //console.log(selected);
              setFormData({
                ...formData,
                bloodgroup: JSON.stringify(selected),
              });
            }
          }
          value={formData.bloodgroup && JSON.parse(formData.bloodgroup)}
          className="newPatientSelect"
          options={bloodgroupOptions}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="mothers_name">Mother's Name</label>
        <input
          type="text"
          name="mothers_name"
          placeholder="Mother's Name"
          onChange={handleChange}
          value={formData.mothers_name}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="fathers_name">Father's Name</label>
        <input
          type="text"
          name="fathers_name"
          placeholder="Father's Name"
          onChange={handleChange}
          value={formData.fathers_name}
        />
      </div>
      <div className="newPatientItem">
        <label>
          Consanguinity &nbsp;&nbsp;
          <BsQuestionCircleFill
            color="green"
            className="helper-icon"
            onClick={() => {
              childRef.current.handleClickOpenInfo(
                "Consanguinity",
                consanguinityHelp
              );
            }}
          />
        </label>
        <Select
          name="consanguinity"
          onChange={dropDownChange}
          className="newPatientSelect"
          value={
            consanguinityOptions.find(
              (val) => val.value === formData.consanguinity
            ) || { value: 100, label: "Unknown" }
          }
          options={consanguinityOptions}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
        />
      </div>
      <div className="newPatientItem">
        {/* <label htmlFor="card_number">{selectedCard} Number</label> */}
        <label htmlFor="card_number">
          Aadhaar Card Number <span className="text-danger">*</span>
        </label>
        <input
          type="text"
          name="card_number"
          placeholder="XXXXXXXXXXXX"
          onChange={handleChange}
          onBlur={adhaarValidator}
          value={removeDashes(formData?.card_number)}
          required
        />
      </div>
      {/* New field added 16/01/23 */}
      <div className="newPatientItem">
        <label>
          Aadhaar Belongs to <span className="text-danger">*</span>
        </label>
        <Select
          name="belongs_to"
          onChange={
            //dropDownChange
            (selected, dropdown) => {
              //console.log(selected);
              setFormData({
                ...formData,
                belongs_to: JSON.stringify(selected),
              });
            }
          }
          className="newPatientSelect"
          options={aadharOwnerOptions}
          value={formData.belongs_to && JSON.parse(formData.belongs_to)}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
          // ref={belongsToInput}
        />
      </div>
      <div className="newPatientItem">
        <label>Patient/Family Ann. Income</label>

        <Select
          name="patient_annual_income"
          onChange={
            //dropDownChange
            (selected, dropdown) => {
              //console.log(selected);
              setFormData({
                ...formData,
                patient_annual_income: JSON.stringify(selected),
              });
            }
          }
          value={
            formData.patient_annual_income &&
            JSON.parse(formData.patient_annual_income)
          }
          className="newPatientSelect"
          options={patientAnnualIncomeOptions}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
        />
      </div>
      <div className="newPatientItem">
        <label>Employment Type</label>

        <Select
          name="employment_type"
          onChange={
            //dropDownChange
            (selected, dropdown) => {
              //console.log(selected);
              setFormData({
                ...formData,
                employment_type: JSON.stringify(selected),
              });
            }
          }
          value={
            formData.employment_type && JSON.parse(formData.employment_type)
          }
          className="newPatientSelect"
          options={emplymentTypeOptions}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
        />
      </div>
      <div className="newPatientItem">
        <label>Educational Qualification</label>
        <Select
          name="education"
          onChange={
            //dropDownChange
            (selected, dropdown) => {
              //console.log(selected);
              setFormData({
                ...formData,
                education: JSON.stringify(selected),
              });
            }
          }
          value={formData.education && JSON.parse(formData.education)}
          className="newPatientSelect"
          options={educationQualOptions}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
        />
      </div>
      <div className="newPatientItem">
        <label>Source of Funds</label>
        <Select
          name="source_of_funds"
          onChange={
            //dropDownChange
            (selected, dropdown) => {
              //console.log(selected);
              setFormData({
                ...formData,
                source_of_funds: JSON.stringify(selected),
              });
            }
          }
          value={
            formData.source_of_funds && JSON.parse(formData.source_of_funds)
          }
          className="newPatientSelect"
          options={sourceFundOptions}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
        />
      </div>
      <div className="newPatientItem">
        <label>Diagnosis</label>
        <input
          type="text"
          name="diagnosis_name"
          value={formData.diagnosis_name}
          disabled
        />
      </div>
      {formData.diagnosis_name &&
        (formData.diagnosis_name.toLowerCase().includes("hemophilia") ||
          formData.diagnosis_name.toLowerCase().includes("vwd")) && (
          <div className="newPatientItem">
            <label>Severity of illness</label>
            <Select
              name="severity_of_illness"
              onChange={
                //dropDownChange
                (selected, dropdown) => {
                  //console.log(selected);
                  setFormData({
                    ...formData,
                    severity_of_illness: JSON.stringify(selected),
                  });
                }
              }
              value={
                formData.severity_of_illness &&
                JSON.parse(formData.severity_of_illness)
              }
              className="newPatientSelect"
              options={diagnosisTypeOptions}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
            />
          </div>
        )}
      <div className="newPatientItem">
        <label htmlFor="diagnosis_others">Other Diagnosis</label>
        <input
          type="text"
          name="diagnosis_others"
          placeholder="Other than selection"
          onChange={handleChange}
        />
      </div>
      <div className="newPatientItem">
        <label>Languages Known</label>
        <Select
          name="languages_known"
          isMulti
          onChange={(selected, dropdown) => {
            // console.log(selected);
            if (selected.length) {
              setFormData({
                ...formData,
                languages_known: JSON.stringify(selected),
              });
            }
          }}
          value={
            formData.languages_known && JSON.parse(formData.languages_known)
          }
          className="basic-multi-select"
          options={languagesKnownOptions}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
        />
      </div>
      {formData.diagnosis_name &&
        formData.diagnosis_name.toLowerCase().includes("thalassemia") && (
          <div className="newPatientItem">
            <label>HLA Status</label>
            <Select
              name="hla_status"
              onChange={(selected, dropdown) => {
                //console.log(selected);
                setFormData({
                  ...formData,
                  hla_status: JSON.stringify(selected),
                });
              }}
              value={
                formData.languages_known && JSON.parse(formData.hla_status)
              }
              className="newPatientSelect"
              options={hlaStatusOptions}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
            />
          </div>
        )}
    </div>
  );
};

export default GeneralTab;

import React from "react";
import MainContentCard from "../Home/MainContentCard";
import { useLocation } from "react-router-dom";

import ErrorBoundaryRoutes from "../../components/shared/ErrorBoundaryRoutes";
import UpdateDiagnosisNameChild from "./UpdateDiagnosisNameChild";

const UpdateDiagnosisName = () => {
  const { state } = useLocation();
  let diagnosis = state;
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="UpdateDiagnosisName" route="/diagnosis/update">
        <UpdateDiagnosisNameChild diagnosis={diagnosis} />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default UpdateDiagnosisName;

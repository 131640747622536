import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import axios from "axios";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const FibroscanTab = ({ emrRecordId, emrRecord, setEmrRecord }) => {
  const [formData, setFormData] = useState({
    capscore: null,
    e_kpa: null,
  });

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  useEffect(() => {
    setFormData({
      ...emrRecord,
    });
  }, [emrRecord]);

  const updateEmr = () => {
    // e.preventDefault();
    //changeTab(1);

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/emr/update/${emrRecordId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, fibroscan: true },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        localStorage.setItem(
          `${emrRecordId}-fibroscan`,
          JSON.stringify(formData)
        );
        toast.success("Fibroscan sheet updated Successfully.");
        setEmrRecord(response.data.emr);
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error.response.data));
      });
  };

  return (
    <div className="newPatientFormOnly">
      <div className="w-100 mt-2 text-end pe-5">
        <Button
          variant="primary"
          size="large"
          onClick={updateEmr}
          className="btn btn-primary float-right"
        >
          Save
        </Button>
      </div>
      <div className="newPatientItem">
        <label htmlFor="capscore">Cap Score</label>
        <InputGroup className="mb-3">
          <Form.Control
            aria-label="capscore"
            type="number"
            name="capscore"
            onChange={handleChange}
            value={formData.capscore}
          />
          <InputGroup.Text id="basic-addon2">dB/m</InputGroup.Text>
        </InputGroup>
      </div>
      <div className="newPatientItem">
        <label htmlFor="capscore">E KPA</label>
        <InputGroup className="mb-3">
          <Form.Control
            aria-label="e_kpa"
            type="number"
            name="e_kpa"
            onChange={handleChange}
            value={formData.e_kpa}
          />
          <InputGroup.Text id="basic-addon2">kPa</InputGroup.Text>
        </InputGroup>
      </div>
    </div>
  );
};

export default FibroscanTab;

import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

//Context import
import AuthContext from "../../components/shared/Context";

const AddDiagnosisCategoryChild = () => {
  const {
    checkLoggedInStatus,
    loggedInUserDetails,
    selectedDiagnosis,
    states,
  } = useContext(AuthContext);
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    category: null,
    state: loggedInUserDetails?.state ? loggedInUserDetails?.state : null,
  });

  const addDiagnosisCategory = () => {
    if (!formData.category) {
      toast.error("Please Add Category!");
      return;
    }

    if (loggedInUserDetails?.role === "FULL_ACCESS" && formData?.state === null)
      return toast.error("Please Select State!");

    let userToken = localStorage.getItem("token");
    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/dcat/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        toast.success("Category Created Successfully.");
        navigate("/diagnosis-category");
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error?.response?.data?.message));
        if (error.response.data.message.toLowerCase().includes("invalid")) {
          navigate("/login");
        }
      });
  };

  const handleChangeValue = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const stateOptions = states.map((state, index) => ({
    value: index,
    label: state,
  }));

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "70vh" }}
    >
      <div className="col-md-6 mx-auto d-block d-flex signup-form">
        <form className="mt-1 border p-4 shadow w-100">
          <div className="row">
            <div className="">
              <h5 className="text-secondary">Create Diagnosis Category</h5>
              <hr />
              <br />
              <label>
                Enter Category<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="category"
                className="form-control"
                placeholder="Diagnosis Category"
                onChange={handleChangeValue}
                value={formData?.category}
                required
              />
            </div>
          </div>
          {loggedInUserDetails?.role === "FULL_ACCESS" && (
            <div className="row">
              <div className="">
                <label>State</label>
                <Select
                  name="state"
                  onChange={
                    //dropDownChange
                    (selected, dropdown) => {
                      console.log(selected);
                      setFormData({
                        ...formData,
                        state: selected.label,
                      });
                    }
                  }
                  className="newPatientSelect"
                  options={stateOptions}
                />
              </div>
            </div>
          )}
          <div className="flex flex-row mt-4">
            <div className="col-md-12">
              <Button
                variant="primary"
                size="medium"
                className="float-end"
                onClick={addDiagnosisCategory}
              >
                Save
              </Button>
            </div>

            <div className="col-md-12">
              <Button
                variant="primary"
                size="secondary"
                className="float-start"
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
            </div>
          </div>
        </form>
        {/* {JSON.stringify(formData)} */}
      </div>
    </div>
  );
};

export default AddDiagnosisCategoryChild;

import React from "react";
import Card from "react-bootstrap/Card";

const ContactDetailsCard = ({ currentRecord }) => {
  return (
    <Card>
      <Card.Header>
        <i className="fas fa-align-justify" />
        <strong>Patient Contact</strong>
      </Card.Header>
      <Card.Body>
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            <b>{"primary_mobile".replaceAll("_", " ").toUpperCase()}</b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {currentRecord?.contact?.primary_mobile}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            <b>{"whatsapp_number".replaceAll("_", " ").toUpperCase()}</b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {currentRecord?.contact?.whatsapp_number}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            <b>{"secondary_mobile".replaceAll("_", " ").toUpperCase()}</b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {currentRecord?.contact?.secondary_mobile}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            <b>{"landline".replaceAll("_", " ").toUpperCase()}</b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {currentRecord?.contact?.landline}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            <b>{"email".replaceAll("_", " ").toUpperCase()}</b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {currentRecord?.contact?.email}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ContactDetailsCard;

import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import FishScores from "./FishScores";
import HjhsScore from "./HjhsScore";
import PetterssonScore from "./PetterssonScore";
import WFHScores from "./WFHScores";
import DenverScores from "./DenverScores";
import toast from "react-hot-toast";
import axios from "axios";

const JointScores = ({ emrRecordId }) => {
  const [key, setKey] = useState("hjhs");
  const [jsRecord, setJsRecord] = useState(null);

  useEffect(() => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/jointsscore/record/${emrRecordId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setJsRecord(response.data.jscore);
        // toast.success("Retrived  Successfully.");
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error.response.data));
      });
  }, []);

  return (
    <div style={{ marginRight: "40px" }}>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>HJHS Scores</Accordion.Header>
          <Accordion.Body>
            {/* {JSON.stringify(jsRecord)} */}
            <HjhsScore
              emrRecordId={emrRecordId}
              jsRecord={jsRecord && jsRecord.hjhsscores}
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>FISH Scores</Accordion.Header>
          <Accordion.Body>
            <FishScores
              emrRecordId={emrRecordId}
              jsRecord={jsRecord && jsRecord.fishscores}
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>WFH Scores</Accordion.Header>
          <Accordion.Body>
            <WFHScores
              emrRecordId={emrRecordId}
              jsRecord={jsRecord && jsRecord.wfhscores}
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Pettersson Scores</Accordion.Header>
          <Accordion.Body>
            <PetterssonScore
              emrRecordId={emrRecordId}
              jsRecord={jsRecord && jsRecord.petterssonscore}
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>Denver Score</Accordion.Header>
          <Accordion.Body>
            <DenverScores
              emrRecordId={emrRecordId}
              jsRecord={jsRecord && jsRecord.denverscores}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default JointScores;

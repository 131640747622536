import React from "react";
import MainContentCard from "../../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../../components/shared/ErrorBoundaryRoutes";
import ListReAllocationHistoryChild from "./ListReAllocationHistoryChild";
import { Tabs, Tab } from "react-bootstrap";
import ListAllocationReceivingHistoryChild from "./ListAllocationReceivingHistoryChild";

const ListReAllocationHistory = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="ListPurchaseTC" route="/reallocation/list">
        <span className="">
          <h3>TC ReAllocations Register</h3>
        </span>
        <HistoryTabs />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default ListReAllocationHistory;

const HistoryTabs = ({}) => {
  return (
    <div>
      <Tabs
        defaultActiveKey="allocation"
        id="history-tabs"
        className="history-tabs"
      >
        <Tab eventKey="allocation" title="Allocation History">
          <div className="border border-top-0">
            {/* Allocation History content goes here */}
            <ListReAllocationHistoryChild />
          </div>
        </Tab>
        <Tab eventKey="receiving" title="Receiving History">
          <div className="border border-top-0">
            {/* Receiving History content goes here */}
            <ListAllocationReceivingHistoryChild />
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

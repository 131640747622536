import React from "react";

import Topbar from "../../../../components/Topbar/Topbar";
import SideBar from "../../../../components/SideBar/SideBar";

import "./JointsWiseReport.css";
import JointsWiseReportChild from "./JointsWiseReportChild";

const JointsWiseReport = () => {
  return (
    <div>
      <Topbar />
      <div className="parent-container">
        <SideBar />
        <JointsWiseReportChild />
      </div>
    </div>
  );
};

export default JointsWiseReport;

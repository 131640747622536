import { useEffect, useState } from "react";

const CheckboxComponent = ({ setData, data, unfilteredData }) => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (e) => {
    setSelectedOption(e?.target?.value);
  };

  useEffect(() => {
    if (selectedOption === "All") {
      setData(unfilteredData);
    } else if (selectedOption === "Pending") {
      setData(unfilteredData.filter((item) => item?.request_status === 0));
    } else if (selectedOption === "Processing") {
      setData(unfilteredData.filter((item) => item?.request_status === 1));
    } else if (selectedOption === "Rejected") {
      setData(unfilteredData.filter((item) => item?.request_status === 2));
    } else if (selectedOption === "Approved") {
      setData(unfilteredData.filter((item) => item?.request_status === 3));
    }
  }, [selectedOption, unfilteredData]);

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <label>
        <input
          type="checkbox"
          value="All"
          checked={selectedOption === "All"}
          onChange={handleOptionChange}
        />
        &nbsp;&nbsp;All
      </label>
      <label>
        <input
          type="checkbox"
          value="Pending"
          checked={selectedOption === "Pending"}
          onChange={handleOptionChange}
        />
        &nbsp;&nbsp;Pending
      </label>
      <label>
        <input
          type="checkbox"
          value="Processing"
          checked={selectedOption === "Processing"}
          onChange={handleOptionChange}
        />
        &nbsp;&nbsp;Processing
      </label>
      <label>
        <input
          type="checkbox"
          value="Rejected"
          checked={selectedOption === "Rejected"}
          onChange={handleOptionChange}
        />
        &nbsp;&nbsp;Rejected
      </label>
      <label>
        <input
          type="checkbox"
          value="Approved"
          checked={selectedOption === "Approved"}
          onChange={handleOptionChange}
        />
        &nbsp;&nbsp;Approved
      </label>
    </div>
  );
};

export default CheckboxComponent;

import React from "react";
import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import TCWiseInventoryDownloadChild from "./TCWiseInventoryDownloadChild";

const TCWiseInventoryDownload = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes
        page="ListTCWiseInventoryDownload"
        route="/tcwise/inventory/download"
      >
        <TCWiseInventoryDownloadChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default TCWiseInventoryDownload;

import React from "react";
import MainContentCard from "../../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../../components/shared/ErrorBoundaryRoutes";
import ListTCInventoryChild from "./ListTCInventoryChild";

const ListTCInventory = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="ListTCInventory" route="/tc-inventory">
        <ListTCInventoryChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default ListTCInventory;

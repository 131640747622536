import React from "react";
import MainContentCard from "../../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../../components/shared/ErrorBoundaryRoutes";
import ViewDoseTableChild from "./ViewDoseTableChild";

const ViewDoseTable = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="DosesList" route="/dose/list">
        <ViewDoseTableChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default ViewDoseTable;

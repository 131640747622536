//import "./CreateUser.css";
import React from "react";
import MainContentCard from "../../Home/MainContentCard";
import CreateUserChild from "./CreateUserChild";

import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

const CreateUser = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="CreateUserChild" route="/users/add">
        <CreateUserChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default CreateUser;

import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import axios from "axios";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const HbElectrophoresis = ({ emrRecordId, emrRecord }) => {
  const [formData, setFormData] = useState({
    hba1: null,
    hba2: null,
    hbc: null,
    hbd: null,
    hbe: null,
    hbf: null,
    other_hb: null,
    hbs: null,
  });
  const hbElectrophoresisKeys = Object.keys(formData);

  useEffect(() => {
    if (emrRecord) {
      localStorage.setItem(
        `${emrRecordId}-hbelectrophoresis`,
        JSON.stringify(emrRecord)
      );
    }
    let hbelectro = localStorage.getItem(`${emrRecordId}-hbelectrophoresis`);
    setFormData({ ...formData, ...JSON.parse(hbelectro) });
  }, [emrRecord]);

  const updateEmr = (e) => {
    e.preventDefault();
    //changeTab(1);

    let userToken = localStorage.getItem("token");
    const config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/emr/update/${emrRecordId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, hb_electrophoresis: true },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        localStorage.setItem(
          `${emrRecordId}-hbelectrophoresis`,
          JSON.stringify(formData)
        );
        toast.success("Biochemistry sheet updated Successfully.");
      })
      .catch(function (error) {
        //handleFailure(JSON.stringify(error.response.data.patient));
        toast.error(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error.response.data));
      });
  };

  const handleChange = ({ currentTarget: input }) => {
    //alert([input.name]);
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const hbElectroLabels = [
    "HbA1",
    "HbA2",
    "HbC",
    "HbD",
    "HbE",
    "HbF",
    "Other Hb",
    "HbS",
  ];
  //mmol/mol, %,

  return (
    <div className="newPatientFormOnly">
      {/* {JSON.stringify(biochemKeys)} */}
      {/* {JSON.stringify(formData)} */}
      {hbElectrophoresisKeys.map((key, index) => (
        <InputField
          handleChange={handleChange}
          fieldname={key}
          formData={formData}
          hbElectroLabels={hbElectroLabels}
          index={index}
        />
      ))}
    </div>
  );
};

export default HbElectrophoresis;

export const InputField = ({
  handleChange,
  fieldname,
  formData,
  hbElectroLabels,
  index,
}) => {
  function capitalize(s) {
    return s && s[0].toUpperCase() + s.slice(1);
  }

  const measuringUnit = [];

  return (
    <div className="newPatientItem">
      <label htmlFor={fieldname}>
        {/* {capitalize(fieldname.replaceAll("_", " ")).toUpperCase()} */}
        {hbElectroLabels[index]}
      </label>

      <InputGroup className="mb-3">
        <Form.Control
          aria-label={fieldname}
          type="number"
          name={fieldname}
          onChange={handleChange}
          value={formData[fieldname]}
          disabled
        />
        {measuringUnit[index] && (
          <InputGroup.Text id="basic-addon2">
            {measuringUnit[index]}
          </InputGroup.Text>
        )}
      </InputGroup>
    </div>
  );
};

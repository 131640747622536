import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import axios from "axios";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const EndocrineProfileTab = ({ emrRecordId, emrRecord }) => {
  const [formData, setFormData] = useState({
    serum_triodothyronine_t3: null,
    serum_thyroxine_t4: null,
    thyroid_stimulating_hormone_tsh: null,
    free_t3: null,
    free_t4: null,
    lh: null,
    fsh: null,
    estrodiol: null,
    testosterone: null,
  });

  const endocrineProfileKeys = Object.keys(formData);

  useEffect(() => {
    if (emrRecord) {
      localStorage.setItem(
        `${emrRecordId}-endocrineprofile`,
        JSON.stringify(emrRecord)
      );
    }
    let endocrine = localStorage.getItem(`${emrRecordId}-endocrineprofile`);
    setFormData({ ...formData, ...JSON.parse(endocrine) });
  }, [emrRecord]);

  const updateEmr = (e) => {
    e.preventDefault();
    //changeTab(1);

    let userToken = localStorage.getItem("token");
    const config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/emr/update/${emrRecordId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, endocrine_profile: true },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        localStorage.setItem(
          `${emrRecordId}-endocrineprofile`,
          JSON.stringify(formData)
        );
        toast.success("Biochemistry sheet updated Successfully.");
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error.response.data));
      });
  };

  const handleChange = ({ currentTarget: input }) => {
    //alert([input.name]);
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const endocrineLabels = [
    "Serum triidothyronine",
    "Serum thyroxine T4",
    "Thyroid stl hormone(TSH)",
    "Free T3",
    "Free T4",
    "LH",
    "FSH",
    "Estrodiol",
    "Testosterone",
  ];
  const measuringUnit = [
    "ng/dl",
    "ug/dL",
    "uIU/mL",
    "ng/mL",
    "µ/dL",
    "IU/L",
    "IU",
    "pg/ml",
    "ng/dl",
  ];

  return (
    <div className="newPatientFormOnly">
      {/* {JSON.stringify(biochemKeys)} */}
      {/* {JSON.stringify(formData)} */}
      {endocrineProfileKeys.map((key, index) => (
        <InputField
          handleChange={handleChange}
          fieldname={key}
          formData={formData}
          index={index}
          measuringUnit={measuringUnit}
          endocrineLabels={endocrineLabels}
        />
      ))}
    </div>
  );
};

export default EndocrineProfileTab;

export const InputField = ({
  handleChange,
  fieldname,
  formData,
  index,
  measuringUnit,
  endocrineLabels,
}) => {
  function capitalize(s) {
    return s && s[0].toUpperCase() + s.slice(1);
  }

  return (
    <div className="newPatientItem">
      <label htmlFor={fieldname}>
        {/* {capitalize(fieldname.replaceAll("_", " ")).toUpperCase()} */}
        {endocrineLabels[index]}
      </label>
      {/* <input
        type="number"
        name={fieldname}
        onChange={handleChange}
        value={formData[fieldname]}
        placeholder={measuringUnit[index]}
      /> */}
      <InputGroup className="mb-3">
        <Form.Control
          aria-label={fieldname}
          type="number"
          name={fieldname}
          onChange={handleChange}
          value={formData[fieldname]}
          disabled
        />
        {measuringUnit[index] && (
          <InputGroup.Text id="basic-addon2">
            {measuringUnit[index]}
          </InputGroup.Text>
        )}
      </InputGroup>
    </div>
  );
};

import { useRef } from "react";
import Select from "react-select";

const ContactTab = ({
  handleChange,
  dropDownChange,
  setStateValue,
  states,
  setCity,
  stateValue,
  city,
  indianCities,
  formData,
  setFormData,
}) => {
  const districtRef = useRef();
  const stateOptions = states.map((state) => ({
    value: state,
    label: state,
  }));

  const districtOptions = indianCities
    .filter((state) => {
      return state.state === stateValue;
    })
    .map((state) => ({ value: state.city, label: state.city }));

  return (
    <div className="newPatientFormOnly">
      <div className="newPatientItem">
        <label htmlFor="patientPhone">Residential Address</label>
        <input
          type="text"
          name="address"
          placeholder="Apt #, Street name, City"
          onChange={handleChange}
          value={formData.address}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="landmark">Landmark</label>
        <input
          type="text"
          name="landmark"
          placeholder="e.g Any popular place nearby"
          onChange={handleChange}
          value={formData.landmark}
        />
      </div>
      <div className="newPatientItem">
        <label>State</label>
        <Select
          name="state"
          onChange={(selected, dropdown) => {
            setStateValue(selected.value);
            setCity(null);
            dropDownChange(selected, dropdown);
          }}
          className="newPatientSelect"
          value={{ value: stateValue, label: stateValue }}
          options={stateOptions}
          isDisabled={false}
        />
      </div>

      <div className="newPatientItem">
        <label>District/City</label>
        <Select
          name="district"
          onChange={
            //dropDownChange
            (selected, dropdown) => {
              //console.log(selected);
              setFormData({
                ...formData,
                district: JSON.stringify(selected),
              });
            }
          }
          ref={districtRef}
          className="newPatientSelect"
          options={districtOptions}
          value={formData.district && JSON.parse(formData.district)}
          isClearable={true}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="pincode">Pin Code</label>
        <input
          type="text"
          name="pincode"
          placeholder="XXXXXX"
          onChange={handleChange}
          value={formData.pincode}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="landline">Phone</label>
        <input
          type="text"
          name="landline"
          placeholder="+91 1234567890"
          onChange={handleChange}
          value={formData.landline}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="whatsapp_number">WhatsApp</label>
        <input
          type="text"
          name="whatsapp_number"
          placeholder="+91 1234567890"
          onChange={handleChange}
          value={formData.whatsapp_number}
        />
      </div>

      <div className="newPatientItem">
        <label htmlFor="primary_mobile">Mobile Primary</label>
        <input
          type="text"
          name="primary_mobile"
          placeholder="+91 1234567890"
          onChange={handleChange}
          value={formData.primary_mobile}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="secondary_mobile">Mobile Secondary</label>
        <input
          type="text"
          name="secondary_mobile"
          placeholder="+91 1234567890"
          onChange={handleChange}
          value={formData.secondary_mobile}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          name="email"
          placeholder="patient@email.com"
          onChange={handleChange}
          value={formData.email}
        />
      </div>
    </div>
  );
};

export default ContactTab;

import React from "react";
import MainContentCard from "../../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../../components/shared/ErrorBoundaryRoutes";
import TCSettlementHistoryChild from "./TCSettlementHistoryChild";

const TCSettlementHistory = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes
        page="ListTCSettlementHistory"
        route="/tc-settlement-history"
      >
        <TCSettlementHistoryChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default TCSettlementHistory;

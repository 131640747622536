import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

const UpdatePurchaseRequestChild = ({ requestedProduct }) => {
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    ...requestedProduct,
    approved_quantity: requestedProduct?.approved_quantity
      ? requestedProduct?.approved_quantity
      : null,
    request_status: requestedProduct?.request_status
      ? requestedProduct?.request_status
      : null,
    approver_comments: requestedProduct?.approver_comments
      ? requestedProduct?.approver_comments
      : null,
  });

  const selectedProduct = {
    value: formData?.product_id?.products?._id,
    label: formData?.product_id?.products?.product_name,
  };

  const requestOptions = [
    { value: 1, label: "Processing" },
    { value: 2, label: "Rejected" },
    { value: 3, label: "Completed" },
  ];

  const dropDownChange = (selected, dropdown) => {
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const handleChangeValue = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  useEffect(() => {
    if (parseInt(formData?.request_status) === 2) {
      console.log("Called when requewst changed");
      setFormData({
        ...formData,
        approved_quantity: 0,
      });
    }
    if (parseInt(formData?.request_status) === 1) {
      console.log("Called when requewst changed");
      setFormData({
        ...formData,
        approved_quantity: requestedProduct?.approved_quantity
          ? requestedProduct?.approved_quantity
          : null,
      });
    }
  }, [formData.request_status]);

  const updatePurchaseRequest = () => {
    if (formData?.request_status !== 2 && !formData?.approved_quantity) {
      return toast.error("Please Enter a valid quantity");
    }

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/purchaseRequest/update/${requestedProduct?.id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, purchaserequests: true },
    };
    axios(config)
      .then(function (response) {
        toast.success("Request Updated Successfully.");
        navigate("/purchase-requests");
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message);
        //  console.log(JSON.stringify(error.response.data));
        if (error.response.data.message.toLowerCase().includes("invalid")) {
          navigate("/login");
        }
      });
  };

  return (
    <div>
      <div className="patientUpdateButton">
        <button className="btn btn-primary" onClick={updatePurchaseRequest}>
          {" "}
          Update
        </button>{" "}
        &nbsp;&nbsp;
        <button className="btn btn-secondary" onClick={() => navigate(-1)}>
          Back
        </button>
      </div>
      <div className="col-md-6 mx-auto d-block d-flex signup-form">
        <form className="mt-5 border p-4 shadow">
          <div className="d-flex flex-row justify-content-between">
            <h5 className="text-secondary">Update Purchase Request</h5>
          </div>

          <hr />
          <br />
          <div className="row">
            <div className="mb-3 ">
              <label>
                TC ID<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="TCID"
                className="form-control"
                value={requestedProduct?.tc_id?.tcenters?.treatment_center_id}
                disabled
              />
            </div>
            <div className="mb-3 ">
              <label>
                TC Name<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="tc_name"
                className="form-control"
                value={requestedProduct?.tc_id?.tcenters?.name_tc}
                disabled
              />
            </div>
            <div className="mb-3 ">
              <label>
                Requested Product<span className="text-danger">*</span>
              </label>
              {/* <Select
                name="product_id"
                value={selectedProduct}
                className="newPatientSelect"
                isDisabled
              /> */}
              <input
                type="text"
                name="product_name"
                className="form-control"
                placeholder="Enter Units required"
                value={requestedProduct?.product_name}
                disabled
              />
              {/* {JSON.stringify(selectedProduct)} */}
            </div>
            <div className="mb-3 ">
              <label>
                Requested Quantity<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="requested_quantity"
                className="form-control"
                placeholder="Enter Units required"
                value={formData?.requested_quantity}
                disabled
              />
            </div>

            <div className="mb-3 ">
              <label>Comments</label>
              <textarea
                type="textarea"
                name="requester_comments"
                className="form-control"
                placeholder="Product Description"
                rows="2"
                disabled
                value={formData.requester_comments}
              />
            </div>
            <div className="mb-3 col-md-6">
              <label>
                Approved Quantity<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="approved_quantity"
                className="form-control"
                onChange={handleChangeValue}
                placeholder="Enter Units required"
                value={formData?.approved_quantity}
              />
            </div>
            <div className="mb-3 col-md-6">
              <label>
                Requested Status<span className="text-danger">*</span>
              </label>
              <Select
                name="request_status"
                options={requestOptions}
                onChange={dropDownChange}
                className="newPatientSelect"
                value={
                  formData?.request_status &&
                  requestOptions.find(
                    (req) => req?.value === formData?.request_status
                  )
                }
              />
            </div>
            <div className="mb-3 ">
              <label>Approver Comments</label>
              <textarea
                type="textarea"
                name="approver_comments"
                className="form-control"
                onChange={handleChangeValue}
                placeholder="Update Message"
                rows="5"
                value={formData?.approver_comments}
              />
            </div>
          </div>
        </form>
      </div>
      {/* {JSON.stringify(formData)} */}
    </div>
  );
};

export default UpdatePurchaseRequestChild;

import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const PatientDataProcessor = ({ jsonData }) => {
  const [processedData, setProcessedData] = useState([]);

  useEffect(() => {
    const groupByTreatmentCenterAndDiagnosis = jsonData.reduce(
      (acc, patient) => {
        const treatmentCenterCode = patient.treatment_center;
        const diagnosisCode = patient.diagnoses?.diagnosis_code;
        const treatmentCenterName = patient.tc_detail?.tcenters?.name_tc;

        if (!acc[treatmentCenterCode]) {
          acc[treatmentCenterCode] = {
            treatmentCenterName: treatmentCenterName,
            diagnosisGroups: {},
          };
        }

        if (!acc[treatmentCenterCode].diagnosisGroups[diagnosisCode]) {
          acc[treatmentCenterCode].diagnosisGroups[diagnosisCode] = {
            totalPatients: 0,
            diagnosis: patient.diagnosis,
            patientsWithIdCard: 0,
            patientsWithoutIdCard: 0,
          };
        }

        acc[treatmentCenterCode].diagnosisGroups[
          diagnosisCode
        ].totalPatients += 1;

        if (patient.id_card) {
          acc[treatmentCenterCode].diagnosisGroups[
            diagnosisCode
          ].patientsWithIdCard += 1;
        } else {
          acc[treatmentCenterCode].diagnosisGroups[
            diagnosisCode
          ].patientsWithoutIdCard += 1;
        }

        return acc;
      },
      {}
    );

    setProcessedData(groupByTreatmentCenterAndDiagnosis);
  }, [jsonData]);

  const generatePdfContent = () => {
    const currentDate = new Date().toLocaleDateString();
    const content = [];

    Object.entries(processedData).forEach(
      ([treatmentCenterCode, centerData]) => {
        content.push(
          {
            text: `Treatment Center: ${centerData.treatmentCenterName}`,
            style: "title",
          },
          {
            table: {
              headerRows: 1,
              widths: [100, 100, 100, 100],
              body: [
                [
                  "Diagnosis Code",
                  "Total Patients",
                  "Patients with ID Card",
                  "Patients without ID Card",
                ],
                ...Object.entries(centerData.diagnosisGroups).map(
                  ([diagnosisCode, data]) => [
                    data.diagnosis,
                    data.totalPatients,
                    data.patientsWithIdCard,
                    data.patientsWithoutIdCard,
                  ]
                ),
              ],
            },
            style: "table",
          }
        );
      }
    );

    const docDefinition = {
      content,
      styles: {
        title: {
          fontSize: 16,
          marginBottom: 10,
        },
        table: {
          margin: [0, 10, 0, 10],
        },
      },
    };

    return docDefinition;
  };

  const downloadPdfDocument = () => {
    const docDefinition = generatePdfContent();
    const pdfDoc = pdfMake.createPdf(docDefinition);
    // Generate a timestamp for the filename
    const timestamp = new Date().toISOString().replace(/[\-\:\.]/g, "_");
    const filename = `patient_data_${timestamp}.pdf`;

    pdfDoc.download(filename);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>Patient Data</h2>
        <Button
          variant="warning"
          onClick={downloadPdfDocument}
          style={{ alignSelf: "flex-end" }}
        >
          Download as PDF
        </Button>
      </div>
      <div id="patient-data-table">
        {Object.entries(processedData).map(
          ([treatmentCenterCode, centerData]) => (
            <div key={treatmentCenterCode}>
              <h3>{`Treatment Center: ${centerData.treatmentCenterName}`}</h3>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Diagnosis Code</th>
                    <th>Total Patients</th>
                    <th>Patients with ID Card</th>
                    <th>Patients without ID Card</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(centerData.diagnosisGroups).map(
                    ([diagnosisCode, data], index) => (
                      <tr key={index}>
                        <td>{data.diagnosis}</td>
                        <td>{data.totalPatients}</td>
                        <td>{data.patientsWithIdCard}</td>
                        <td>{data.patientsWithoutIdCard}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default PatientDataProcessor;

import React from "react";
import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import TestimonialListChild from "./TestimonialListChild";

const TestimonialList = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="Testimonials" route="/testimonials">
        <TestimonialListChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default TestimonialList;

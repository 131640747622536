import { useEffect, useState, useContext } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import { Tabs, Tab, Alert } from "react-bootstrap";
//Context import
import AuthContext from "../../../../components/shared/Context";
import AgeWiseReport from "../AgeWise/AgeWiseReport";
import DiagnosisWiseReport from "../DiagnosisWise/DiagnosisWiseReport";
import HeightWiseReport from "../HeightWise/HeightWiseReport";
import WeightWiseReport from "../WeightWise/WeightWiseReport";

const GenericReportsChild = () => {
  const { checkLoggedInStatus, loggedInUserDetails, selectedDiagnosis } =
    useContext(AuthContext);

  const [patientData, setPatientData] = useState([]);
  const [patientDataGenderWise, setPatientDataGenderWise] = useState([]);
  const [patientDataWeightWise, setPatientDataWeightWise] = useState([]);
  const [patientDataHeightWise, setPatientDataHeightWise] = useState([]);
  const [patientDataAgeWise, setPatientDataAgeWise] = useState([]);
  const [patientDataMaritalStatusWise, setPatientDataMaritalStatusWise] =
    useState([]);
  const [patientDataBloodGroupWise, setPatientDataBloodGroupWise] = useState(
    []
  );
  const [patientAdultOrMinorWise, setPatientAdultOrMinorWise] = useState([]);
  const [patientDataCityWise, setPatientDataCityWise] = useState([]);
  const [
    patientDataEconomicStatusAnnualIncomeWise,
    setPatientDataEconomicStatusAnnualIncomeWise,
  ] = useState([]);

  const [
    patientDataEconomicStatusEmploymentTypeWise,
    setPatientDataEconomicStatusEmploymentTypeWise,
  ] = useState([]);

  const [patientDataESSourceFunds, setPatientDataESSourceFunds] = useState([]);

  const [patientDataEducationWise, setPatientDataEducationWise] = useState([]);
  const [patientDataDiagnosisWise, setPatientDataDiagnosisWise] = useState([]);
  const [patientDataHemophiliaSeverity, setPatientDataHemophiliaSeverity] =
    useState([]);

  function _calculateAge(birthday) {
    // birthday is a date
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  useEffect(() => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/patient/${loggedInUserDetails.tc_id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {},
    };
    axios(config)
      .then(function (response) {
        setPatientData(response.data.patients);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setPatientDataGenderWise(
      patientData.map((patient, index) => ({
        name: patient.patient_name,
        gender: patient.gender,
      }))
    );
    setPatientDataAgeWise(
      patientData.map((patient, index) => ({
        name: patient.patient_name,
        age: _calculateAge(new Date(patient.date_of_birth)),
      }))
    );
    setPatientAdultOrMinorWise(
      patientData.map((patient, index) => ({
        name: patient.patient_name,
        age:
          _calculateAge(new Date(patient.date_of_birth)) > 18
            ? "Adult"
            : "Minor",
      }))
    );
    setPatientDataHeightWise(
      patientData.map((patient, index) => ({
        name: patient.patient_name,
        height: parseInt(patient.height),
      }))
    );
    setPatientDataWeightWise(
      patientData.map((patient, index) => ({
        name: patient.patient_name,
        weight: parseInt(patient.weight),
      }))
    );
    setPatientDataBloodGroupWise(
      patientData.map((patient, index) => ({
        name: patient.patient_name,
        bloodgroup: patient.bloodgroup,
      }))
    );
    setPatientDataMaritalStatusWise(
      patientData.map((patient, index) => ({
        name: patient.patient_name,
        bloodgroup: patient.marital_status,
      }))
    );
    setPatientDataCityWise(
      patientData.map((patient, index) => ({
        name: patient.patient_name,
        city: patient.residential_address.district,
      }))
    );
    setPatientDataEconomicStatusAnnualIncomeWise(
      patientData.map((patient, index) => ({
        name: patient.patient_name,
        annual_income: patient.economic_status.patient_annual_income,
      }))
    );
    setPatientDataEconomicStatusEmploymentTypeWise(
      patientData.map((patient, index) => ({
        name: patient.patient_name,
        employment_type: patient.economic_status.employment_type,
      }))
    );
    setPatientDataESSourceFunds(
      patientData.map((patient, index) => ({
        name: patient.patient_name,
        source_funds: patient.economic_status.source_of_funds,
      }))
    );
    setPatientDataEducationWise(
      patientData.map((patient, index) => ({
        name: patient.patient_name,
        education: patient.education,
      }))
    );
    setPatientDataDiagnosisWise(
      patientData.map((patient, index) => ({
        name: patient.patient_name,
        diagnosis: patient.diagnoses.diagnosis_name,
      }))
    );
    setPatientDataHemophiliaSeverity(
      patientData.map((patient, index) => {
        if (
          patient.diagnoses.diagnosis_name.toLowerCase().includes("hemophilia")
        ) {
          return {
            name: patient.patient_name,
            diagnosis: patient.diagnoses.severity_of_illness,
          };
        }
      })
    );
  }, [patientData]);

  return (
    <div className="patient-update">
      {/* {JSON.stringify(patientData)} */}
      <div className="mt-3">
        <Tabs
          defaultActiveKey="diagnosiswise"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="diagnosiswise" title="Diagnosis wise">
            <DiagnosisWiseReport patientData={patientData} />
          </Tab>
          <Tab eventKey="severitywise" title="Severity wise"></Tab>
          <Tab eventKey="genderwise" title="Gender wise"></Tab>
          <Tab eventKey="agewise" title="Age Wise">
            <AgeWiseReport patientData={patientDataAgeWise} />
          </Tab>
          <Tab eventKey="heightwise" title="Height Wise">
            <HeightWiseReport patientData={patientDataHeightWise} />
          </Tab>
          <Tab eventKey="weightwise" title="Weight wise">
            <WeightWiseReport patientData={patientDataWeightWise} />
          </Tab>
          <Tab eventKey="minorwise" title="Minor/Adult"></Tab>

          <Tab eventKey="bloodgroup" title="Blood Group"></Tab>

          <Tab eventKey="maritalstatus" title="Marital Status"></Tab>
          <Tab eventKey="citywise" title="City wise"></Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default GenericReportsChild;

import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import Badge from "react-bootstrap/Badge";
import { Card } from "react-bootstrap";
import "antd/dist/antd.min.css";
import { Modal } from "react-bootstrap";
import { DataGrid } from "@material-ui/data-grid";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import { makeStyles } from "@material-ui/core/styles";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

//Context import
import AuthContext from "../../../components/shared/Context";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import CheckboxComponent from "./CheckboxComponent";

const PurchaseRequestsChild = () => {
  let navigate = useNavigate();
  const { loggedInUserDetails } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const searchRef = useRef();
  //Error Logging Service
  const [{ errorLogApi, dateFormatter, _checkIfLatestRecord }] =
    useAtom(gbState);

  const getDataFromServer = () => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };

    //console.log(config);

    const bodyParameters = {
      key: "value",
    };

    let urlLink = `${process.env.REACT_APP_API_URL}/purchaseRequest/tc/${loggedInUserDetails.tc_id}`;

    if (loggedInUserDetails.role === "ST_ADM_ACCESS") {
      urlLink = `${process.env.REACT_APP_API_URL}/purchaseRequest/${loggedInUserDetails.state}`;
    }

    if (loggedInUserDetails.role === "FULL_ACCESS") {
      urlLink = `${process.env.REACT_APP_API_URL}/purchaseRequest/`;
    }

    axios
      .get(urlLink, config)
      .then((res) => {
        const oldData = res?.data?.purchase_request;
        //console.log(oldData);
        const updatedData = oldData.map((data) => {
          //   console.log(data.purchase_inventory.product_id.products); purchaserequests
          return {
            ...data?.purchaserequests,
            request_code: data?.purchaserequests?.request_code, //purchaserequests
            id: data?._id,
            product_name:
              data?.purchaserequests?.product_id?.products?.product_name,
            product_id: data?.purchaserequests?.product_id?.products?.productId,
            storage_units:
              data?.purchaserequests?.product_id?.products?.storage_unit,
            tc_name: data?.purchaserequests?.tc_id?.tcenters?.name_tc,
            requester_name: data?.purchaserequests?.requester_id?.last_name
              ? data?.purchaserequests?.requester_id?.first_name +
                " " +
                data?.purchaserequests?.requester_id?.last_name
              : data?.purchaserequests?.requester_id?.first_name,
            request_status: parseInt(data?.purchaserequests?.request_status),
            created: data?.createdAt?.split("T")[0],
            updated: data?.updatedAt,
            recent: data?.createdAt,
          };
        });
        //  console.log(updatedData);
        setData(updatedData);
        setUnfilteredData(updatedData);
      })
      .catch((err) => console.log("error", err));
  };

  useEffect(() => {
    getDataFromServer();
  }, []);

  const handleDelete = (id) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this record ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (id) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/purchaseRequest/delete/${id}`,
        config
      )
      .then((response) => {
        // alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const badgeStatus = {
    0: { badge: "warning", label: "PENDING" },
    1: { badge: "primary", label: "PROCESSING" },
    2: { badge: "danger", label: "REJECTED" },
    3: { badge: "success", label: "COMPLETED" },
  };

  const useStyles = makeStyles(() => ({
    multiLineCell: {
      display: "flex",
      wordBreak: "keep-all",
      wordWrap: "break-word",
      overflowWrap: "break-word",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      lineHeight: "1.2rem",
      height: "100%",
    },
    customRow: {
      height: "75px", // Increase the row height as needed
    },
  }));

  const ProductLineCellRenderer = (params) => {
    const classes = useStyles();
    return (
      <div className={classes.multiLineCell}>
        <span id="content1" className="flex">
          {/* {params.row.recent && (
            <RecentComponent record_date={params.row.recent} />
          )}{" "} */}
          &nbsp;
          <b>{params?.row?.tc_name?.toUpperCase()}</b>
        </span>
        <span id="content2">
          <small className="small-font-size">
            <b>Requested:</b> {params.row.product_name}
          </small>
        </span>
      </div>
    );
  };
  const columns = [
    {
      field: "Action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            <Tooltip title="View">
              <a onClick={() => viewRecord(params.row)}>
                <i
                  className="mdi mdi-eye-circle-outline"
                  style={{
                    fontSize: "15px",
                    color: "black",
                    cursor: "pointer",
                  }}
                ></i>
              </a>
            </Tooltip>
            {loggedInUserDetails.role === "ST_ADM_ACCESS" && (
              <Tooltip title="Update">
                <a
                  onClick={() =>
                    navigate("/purchase-request-update", { state: params.row })
                  }
                >
                  <i
                    className="mdi mdi-square-edit-outline"
                    style={{
                      fontSize: "15px",
                      color: "grey",
                      cursor: "pointer",
                    }}
                  />
                </a>
              </Tooltip>
            )}
            <Tooltip title="Delete">
              <div onClick={() => handleDelete(params.row.id)}>
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "created",
      headerName: "Date",
      filterable: false,
      sortable: true,
      disableColumnMenu: true,
      width: 100,
      //   renderCell: (params) => (
      //     <>
      //       {dateFormatter(params?.row?.created)}
      //       &nbsp;
      //       <RecentComponent record_date={params.row.recent} />
      //     </>
      //   ),
    },
    {
      field: "request_code",
      headerName: "Request ID",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 110,
    },
    {
      field: "product_name",
      headerName: "Product Request",
      width: 250,
      renderCell: (params) => (
        <>
          <RecentComponent record_date={params.row.recent} /> &nbsp;&nbsp;&nbsp;
          {ProductLineCellRenderer(params)}
        </>
      ),
    },
    {
      field: "requested_quantity",
      headerName: "Req. Qty",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 120,
      align: "left",
      renderCell: (params) =>
        params.row.requested_quantity + " " + params.row.storage_units,
    },
    {
      field: "approved_quantity",
      headerName: "Approved",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 120,
      align: "left",
      renderCell: (params) =>
        params.row.approved_quantity + " " + params.row.storage_units,
    },
    {
      field: "request_status",
      headerName: "Status",
      width: 130,
      align: "center",
      renderCell: (params) => {
        return (
          <Badge
            bg={badgeStatus[params.row.request_status].badge}
            style={{ width: "100%", display: "block" }}
          >
            {badgeStatus[params.row.request_status].label}
          </Badge>
        );
      },
    },
    {
      field: "requester_name",
      headerName: "Request By",
      width: 160,
    },
    {
      field: "tc_name",
      headerName: "Center",
      width: 160,
    },
    {
      field: "state",
      headerName: "State",
      width: 110,
    },
  ];

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };

  const handleSearch = (e) => {
    //console.log("Search Value...", e);

    if (e) {
      setData(
        unfilteredData.filter((item) => {
          return (
            item?.product_name?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.tc_name?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.state?.toLowerCase().includes(e?.toLowerCase())
          );
        })
      );
    } else {
      getDataFromServer();
      setData(unfilteredData);
    }
  };

  const clearSearch = () => {
    searchRef.current.value = null;
    getDataFromServer();
  };

  return (
    <div>
      <span className="card-title">
        <h3>Purchase Requests</h3>
      </span>
      {/* {JSON.stringify(data)} */}
      <CheckboxComponent
        setData={setData}
        data={data}
        unfilteredData={unfilteredData}
      />
      <div className="patientListSearch">
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">Filter</InputGroup.Text>
          <Form.Control
            placeholder="Product / State / Code / Company ..."
            aria-label="Search"
            aria-describedby="basic-addon1"
            name="searchPatients"
            ref={searchRef}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <InputGroup.Text>
            <i
              className="mdi mdi-delete-outline"
              onClick={clearSearch}
              style={{ cursor: "pointer" }}
            ></i>
          </InputGroup.Text>
        </InputGroup>
      </div>
      <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        displayTitle="Purchase Requests"
        checkboxSelection
        style={{ height: "60vh" }}
      />

      {/* Modal Popup */}
      <Modal show={openModal} centered>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Request Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent
            currentRecord={currentRecord}
            badgeStatus={badgeStatus}
          />
        </Modal.Body>
      </Modal>
      {/* {JSON.stringify(data)} */}
    </div>
  );
};

const ModalContent = ({ currentRecord, badgeStatus }) => {
  //Error Logging Service
  const [{ errorLogApi, dateFormatter }] = useAtom(gbState);
  return (
    <div className="container-fluid">
      <Card>
        <Card.Header>
          <i className="fas fa-align-justify" />
          <strong>Request ID: {currentRecord?.request_code}</strong>
        </Card.Header>
        <Card.Body>
          <table class="table table-striped">
            <tbody>
              <tr>
                <th scope="row">Requested Date</th>
                <td>{dateFormatter(currentRecord.created)}</td>
              </tr>
              <tr>
                <th scope="row">Product Name</th>
                <td>{currentRecord.product_name}</td>
              </tr>
              <tr>
                <th scope="row">
                  <i
                    className="mdi mdi-check-circle"
                    style={{ color: "blue" }}
                  ></i>{" "}
                  Requested Quantity
                </th>
                <td>{currentRecord.requested_quantity}</td>
              </tr>
              <tr>
                <th scope="row">Request Status</th>
                <td>
                  <Badge bg={badgeStatus[currentRecord.request_status].badge}>
                    {badgeStatus[currentRecord.request_status].label}
                  </Badge>
                </td>
              </tr>
              <tr>
                <th scope="row" colSpan={2}>
                  Comments
                </th>
              </tr>
              <tr>
                <td colSpan={2}>{currentRecord.requester_comments}</td>
              </tr>
            </tbody>
          </table>
          {/* {JSON.stringify(currentRecord)} */}
        </Card.Body>
      </Card>
      {parseInt(currentRecord.request_status) !== 0 && (
        <Card>
          <Card.Header>
            <i className="fas fa-align-justify" />
            <strong>Status Details</strong>
          </Card.Header>
          <Card.Body>
            <table class="table table-striped">
              <tbody>
                <tr>
                  <th scope="row">
                    {" "}
                    {currentRecord.request_status !== 2 ? (
                      <i
                        className="mdi mdi-check-circle"
                        style={{ color: "green" }}
                      ></i>
                    ) : (
                      <i
                        className="mdi mdi-close-circle"
                        style={{ color: "red" }}
                      ></i>
                    )}{" "}
                    Approved Quantity
                  </th>
                  <td>{currentRecord.approved_quantity} </td>
                </tr>
                <tr>
                  <th scope="row">Updated Date</th>
                  <td>{dateFormatter(currentRecord.updated)}</td>
                </tr>
                <tr>
                  <th scope="row" colSpan={2}>
                    Comments
                  </th>
                </tr>
                <tr>
                  <td colSpan={2}>{currentRecord.approver_comments}</td>
                </tr>
              </tbody>
            </table>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

const RecentComponent = ({ record_date }) => {
  const [{ _checkIfLatestRecord }] = useAtom(gbState);
  const isRecent = _checkIfLatestRecord(record_date);

  return (
    <span>
      {!isRecent ? (
        <div
          style={{ display: "flex", flexDirection: "column", height: "auto" }}
        >
          <div
            style={{
              width: "7px",
              height: "7px",
              backgroundColor: "green",
              borderRadius: "50%",
            }}
          ></div>
        </div>
      ) : null}
    </span>
  );
};

export default PurchaseRequestsChild;

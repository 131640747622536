import React from "react";

import Topbar from "../../../../components/Topbar/Topbar";
import SideBar from "../../../../components/SideBar/SideBar";

import "./HeightWiseReport.css";
import HeightWiseReportChild from "./HeightWiseReportChild";

const HeightWiseReport = ({ patientData }) => {
  return (
    <div className="parent-container">
      <HeightWiseReportChild patientData={patientData} />
    </div>
  );
};

export default HeightWiseReport;

import React from "react";
import { useLocation } from "react-router-dom";
import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import PatientDeathRecordChild from "./PatientDeathRecordChild";

const PatientDeathRecord = () => {
  const { state } = useLocation();
  let pRecord = state;
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="PatientDeathRecord" route="/patient/drecord">
        <PatientDeathRecordChild pRecord={pRecord} />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default PatientDeathRecord;

import "./UsersList.css";
import React from "react";
import MainContentCard from "../../Home/MainContentCard";
// import { Outlet } from "react-router-dom";

import UsersListChild from "./UsersListChild";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

const UsersList = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="UsersListChild" route="/users">
        <UsersListChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default UsersList;

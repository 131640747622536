import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Card from "react-bootstrap/Card";

const ResponsiveTable = ({ data }) => {
  const patientfields = [
    "temp_patient_id",
    "patient_name",
    "gender",
    "mothers_name",
    "primary_mobile",
    "whatsapp_number",
    "email",
  ];

  // Utility function to check if a value is an object
  const isObject = (key) => {
    if (key === "gender") {
      return JSON.parse(data[key])?.label;
    } else {
      return data[key];
    }
  };

  return (
    <div className="container mt-4">
      <Card>
        <Card.Header>
          <i className="fas fa-align-justify" />
          <strong>Patient General Details</strong>
        </Card.Header>
        <Card.Body>
          {patientfields.map((pfields) => {
            return (
              <div key={pfields} className="row">
                <div className="col-md-6 col-xs-6 divTableCell ">
                  <b>{pfields.replaceAll("_", " ").toUpperCase()}</b>
                </div>
                <div className="col-md-6 col-xs-6 divTableCell ">
                  {isObject(pfields)}
                </div>
              </div>
            );
          })}
        </Card.Body>
      </Card>
    </div>
  );
};

export default ResponsiveTable;

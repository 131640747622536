import Select from "react-select";
import { DatePicker } from "antd";
import { useEffect } from "react";

const VaccinationTab = ({
  handleChange,
  dropDownChange,
  formData,
  setFormData,
}) => {
  const hbvOptions = [
    { value: 1, label: "Done" },
    { value: 2, label: "Not Done" },
    { value: 3, label: "Undergoing" },
    { value: 4, label: "Unkown" },
  ];

  const vaccinationOptions = [
    { value: 1, label: "Done" },
    { value: 2, label: "Not Done" },
  ];

  const setHbvOptionValue = () => {
    if (
      formData.hbv_date_of_vaccination_1st &&
      formData.hbv_date_of_vaccination_2nd &&
      formData.hbv_date_of_vaccination_3rd
    ) {
      return { value: 1, label: "Done" };
    }
    if (
      formData.hbv_date_of_vaccination_1st ||
      formData.hbv_date_of_vaccination_2nd ||
      formData.hbv_date_of_vaccination_3rd
    ) {
      return { value: 3, label: "Undergoing" };
    }
  };

  useEffect(() => {
    setHbvOptionValue();
    setFormData({
      ...formData,
      hepatitis_b_vaccination: JSON.stringify(setHbvOptionValue()),
    });
  }, [
    formData.hbv_date_of_vaccination_1st,
    formData.hbv_date_of_vaccination_2nd,
    formData.hbv_date_of_vaccination_3rd,
  ]);

  const setMeningococcalDropDown = () => {
    if (formData.date_of_meningococcal_vaccination) {
      return {
        value: 1,
        label: "Done",
      };
    }
  };

  useEffect(() => {
    setFormData({
      ...formData,
      meningococcal_vaccination: JSON.stringify(setMeningococcalDropDown()),
    });
  }, [formData.date_of_meningococcal_vaccination]);

  const setPneumococcalDropDown = () => {
    if (formData.date_of_pneumococcal_vaccination) {
      return {
        value: 1,
        label: "Done",
      };
    }
  };

  useEffect(() => {
    setFormData({
      ...formData,
      pneumococcal_vaccination: JSON.stringify(setPneumococcalDropDown()),
    });
  }, [formData.date_of_pneumococcal_vaccination]);

  const setTyphoidDropDown = () => {
    if (formData.date_of_typhoid_vaccination) {
      return {
        value: 1,
        label: "Done",
      };
    }
  };

  useEffect(() => {
    setFormData({
      ...formData,
      typhoid_vaccination: JSON.stringify(setTyphoidDropDown()),
    });
  }, [formData.date_of_typhoid_vaccination]);

  const setInfluenzaDropDown = () => {
    if (formData.date_of_h_influenza_vaccination) {
      return {
        value: 1,
        label: "Done",
      };
    }
  };

  useEffect(() => {
    setFormData({
      ...formData,
      h_influenza_vaccination: JSON.stringify(setInfluenzaDropDown()),
    });
  }, [formData.date_of_h_influenza_vaccination]);

  return (
    <div className="newPatientFormOnly">
      <fieldset className="border p-2 w-100">
        <legend className="float-none w-auto p-2 fs-5">
          Hepatitis B Virus
        </legend>
        <div className="newPatientFormOnly vaccination">
          <div className="newPatientItem ">
            <label htmlFor="hepatitis_b_vaccination">Hepatitis B</label>
            <Select
              name="hepatitis_b_vaccination"
              onChange={
                //dropDownChange
                (selected, dropdown) => {
                  //console.log(selected);
                  setFormData({
                    ...formData,
                    hepatitis_b_vaccination: JSON.stringify(selected),
                  });
                }
              }
              value={
                formData.hepatitis_b_vaccination &&
                JSON.parse(formData.hepatitis_b_vaccination)
              }
              className="newPatientSelect"
              options={hbvOptions}
            />
          </div>
          <div className="newPatientItem">
            <label htmlFor="hbv_date_of_vaccination_1st">
              DATE OF 1ST VACCINATION
            </label>
            <DatePicker
              className="newPatientSelect datePicker"
              style={{ paddingLeft: "10px" }}
              name="hbv_date_of_vaccination_1st"
              onChange={(date, dateString) => {
                setFormData({
                  ...formData,
                  hbv_date_of_vaccination_1st: dateString,
                  hepatitis_b_vaccination: JSON.stringify({
                    value: 3,
                    label: "Undergoing",
                  }),
                });
              }}
            />
          </div>
          <div className="newPatientItem">
            <label htmlFor="hbv_date_of_vaccination_2nd">
              DATE OF 2nd VACCINATION
            </label>

            <DatePicker
              className="newPatientSelect datePicker"
              style={{ paddingLeft: "10px" }}
              name="hbv_date_of_vaccination_2nd"
              onChange={(date, dateString) => {
                setFormData({
                  ...formData,
                  hbv_date_of_vaccination_2nd: dateString,
                  hepatitis_b_vaccination: JSON.stringify(setHbvOptionValue()),
                });
              }}
            />
          </div>
          <div className="newPatientItem">
            <label htmlFor="hbv_date_of_vaccination_3rd">
              DATE OF 3rd VACCINATION
            </label>

            <DatePicker
              className="newPatientSelect datePicker"
              style={{ paddingLeft: "10px" }}
              name="hbv_date_of_vaccination_3rd"
              onChange={(date, dateString) => {
                setFormData({
                  ...formData,
                  hbv_date_of_vaccination_3rd: dateString,
                  hepatitis_b_vaccination: JSON.stringify(setHbvOptionValue()),
                });
              }}
            />
          </div>
        </div>
      </fieldset>
      <div className="vaccination w-100">
        <fieldset className="border p-2 " style={{ width: "48%" }}>
          <legend className="float-none w-auto p-2 fs-5">Meningococcal</legend>
          <div className="newPatientFormOnly">
            <div className="newPatientItem ">
              <label htmlFor="meningococcal_vaccination">
                Meningococcal Vaccine
              </label>
              <Select
                name="meningococcal_vaccination"
                onChange={
                  //dropDownChange
                  (selected, dropdown) => {
                    //console.log(selected);
                    setFormData({
                      ...formData,
                      meningococcal_vaccination: JSON.stringify(selected),
                    });
                  }
                }
                value={
                  formData.meningococcal_vaccination &&
                  JSON.parse(formData.meningococcal_vaccination)
                }
                className="newPatientSelect"
                options={vaccinationOptions}
              />
            </div>
            <div className="newPatientItem">
              <label htmlFor="date_of_meningococcal_vaccination">
                DATE OF VACCINATION
              </label>

              <DatePicker
                className="newPatientSelect datePicker"
                style={{ paddingLeft: "10px" }}
                name="date_of_meningococcal_vaccination"
                onChange={(date, dateString) => {
                  setFormData({
                    ...formData,
                    date_of_meningococcal_vaccination: dateString,
                    meningococcal_vaccination: JSON.stringify({
                      value: 1,
                      label: "Done",
                    }),
                  });
                }}
              />
            </div>
          </div>
        </fieldset>
        <fieldset className="border p-2" style={{ width: "48%" }}>
          <legend className="float-none w-auto p-2 fs-5">Pneumococcal</legend>
          <div className="newPatientFormOnly ">
            <div className="newPatientItem">
              <label htmlFor="pneumococcal_vaccination">
                Pneumococcal Vaccine
              </label>
              <Select
                name="pneumococcal_vaccination"
                onChange={
                  //dropDownChange
                  (selected, dropdown) => {
                    //console.log(selected);
                    setFormData({
                      ...formData,
                      pneumococcal_vaccination: JSON.stringify(selected),
                    });
                  }
                }
                value={
                  formData.pneumococcal_vaccination &&
                  JSON.parse(formData.pneumococcal_vaccination)
                }
                className="newPatientSelect"
                options={vaccinationOptions}
              />
            </div>
            <div className="newPatientItem">
              <label htmlFor="date_of_pneumococcal_vaccination">
                DATE OF VACCINATION
              </label>

              <DatePicker
                className="newPatientSelect datePicker"
                style={{ paddingLeft: "10px" }}
                name="date_of_pneumococcal_vaccination"
                onChange={(date, dateString) => {
                  setFormData({
                    ...formData,
                    date_of_pneumococcal_vaccination: dateString,
                    pneumococcal_vaccination: JSON.stringify({
                      value: 1,
                      label: "Done",
                    }),
                  });
                }}
              />
            </div>
          </div>
        </fieldset>
      </div>
      <div className="vaccination w-100">
        <fieldset className="border p-2" style={{ width: "48%" }}>
          <legend className="float-none w-auto p-2 fs-5">Typhoid</legend>
          <div className="newPatientFormOnly ">
            <div className="newPatientItem">
              <label htmlFor="typhoid_vaccination">Typhoid Vaccine</label>
              <Select
                name="typhoid_vaccination"
                onChange={
                  //dropDownChange
                  (selected, dropdown) => {
                    //console.log(selected);
                    setFormData({
                      ...formData,
                      typhoid_vaccination: JSON.stringify(selected),
                    });
                  }
                }
                value={
                  formData.typhoid_vaccination &&
                  JSON.parse(formData.typhoid_vaccination)
                }
                className="newPatientSelect"
                options={vaccinationOptions}
              />
            </div>
            <div className="newPatientItem">
              <label htmlFor="date_of_typhoid_vaccination">
                DATE OF VACCINATION
              </label>

              <DatePicker
                className="newPatientSelect datePicker"
                style={{ paddingLeft: "10px" }}
                name="date_of_typhoid_vaccination"
                onChange={(date, dateString) => {
                  setFormData({
                    ...formData,
                    date_of_typhoid_vaccination: dateString,
                    typhoid_vaccination: JSON.stringify({
                      value: 1,
                      label: "Done",
                    }),
                  });
                }}
              />
            </div>
          </div>
        </fieldset>
        <fieldset className="border p-2 " style={{ width: "48%" }}>
          <legend className="float-none w-auto p-2 fs-5">H Influenza</legend>
          <div className="newPatientFormOnly">
            <div className="newPatientItem">
              <label htmlFor="h_influenza_vaccination">
                H Influenza Vaccine
              </label>
              <Select
                name="h_influenza_vaccination"
                onChange={
                  //dropDownChange
                  (selected, dropdown) => {
                    //console.log(selected);
                    setFormData({
                      ...formData,
                      h_influenza_vaccination: JSON.stringify(selected),
                    });
                  }
                }
                value={
                  formData.h_influenza_vaccination &&
                  JSON.parse(formData.h_influenza_vaccination)
                }
                className="newPatientSelect"
                options={vaccinationOptions}
              />
            </div>
            <div className="newPatientItem">
              <label htmlFor="date_of_h_influenza_vaccination">
                DATE OF VACCINATION
              </label>

              <DatePicker
                className="newPatientSelect datePicker"
                style={{ paddingLeft: "10px" }}
                name="date_of_h_influenza_vaccination"
                onChange={(date, dateString) => {
                  setFormData({
                    ...formData,
                    date_of_h_influenza_vaccination: dateString,
                    h_influenza_vaccination: JSON.stringify({
                      value: 1,
                      label: "Done",
                    }),
                  });
                }}
              />
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  );
};

export default VaccinationTab;

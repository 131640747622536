import React from "react";
import Card from "react-bootstrap/Card";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const GeneralDetailsCard = ({ currentRecord, setFieldLabelFromArray }) => {
  const [{ decryptData }] = useAtom(gbState);
  const patient_details = [
    "patientId",
    "local_registration_id",
    "patient_name",
    "gender",
    "date_of_birth",
    "age",
    "weight",
    "height",
    "bloodgroup",
    "mothers_name",
    "fathers_name",
    "marital_status",
    "spouse_name",
    "education",

    "consanguinity",
  ];

  function _calculateAge(birthday) {
    // birthday is a date
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  const consanguinityOptions = [
    { value: "100", label: "Unknown" },
    { value: "101", label: "Yes" },
    { value: "102", label: "No" },
  ];

  const consanguinityArr = { 100: "Unknown", 101: "Yes", 102: "No" };

  return (
    <Card>
      <Card.Header>
        <i className="fas fa-align-justify" />
        <strong>Patient General Details</strong>
      </Card.Header>
      <Card.Body>
        {patient_details?.map(
          (p_detail) =>
            p_detail && (
              <div key={p_detail} className="row ">
                <div className="col-md-6 col-xs-6 divTableCell ">
                  <b>{p_detail.replaceAll("_", " ").toUpperCase()}</b>
                </div>
                <div className="col-md-6 col-xs-6 divTableCell ">
                  {(p_detail === "gender" &&
                    setFieldLabelFromArray(currentRecord[p_detail])) ||
                    (p_detail === "bloodgroup" &&
                      setFieldLabelFromArray(currentRecord[p_detail])) ||
                    (p_detail === "marital_status" &&
                      setFieldLabelFromArray(currentRecord[p_detail])) ||
                    (p_detail === "education" &&
                      setFieldLabelFromArray(currentRecord[p_detail])) ||
                    (p_detail === "age" &&
                      currentRecord?.date_of_birth &&
                      _calculateAge(new Date(currentRecord?.date_of_birth)) +
                        " yrs") ||
                    (p_detail === "consanguinity" &&
                      currentRecord?.consanguinity &&
                      consanguinityArr[currentRecord?.consanguinity]) ||
                    currentRecord[p_detail]}
                </div>
              </div>
            )
        )}
        {currentRecord?.languages_known && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              <b> {"languages_known".replaceAll("_", " ").toUpperCase()}</b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {JSON.parse(currentRecord?.languages_known)
                .map((lang) => lang.label)
                .toString()}
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-12 col-xs-12 divTableCellRows text-center h6 mt-2">
            ID Card
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            {" "}
            <b> {"card_type".replaceAll("_", " ").toUpperCase()}</b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {currentRecord?.id_card?.card_type}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            {" "}
            <b> {"card_number".replaceAll("_", " ").toUpperCase()}</b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {currentRecord?.id_card?.card_number &&
              decryptData(currentRecord?.id_card?.card_number)}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            {" "}
            <b> {"card_owner".replaceAll("_", " ").toUpperCase()}</b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {currentRecord?.id_card?.belongs_to &&
              JSON.parse(currentRecord?.id_card?.belongs_to)?.label}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default GeneralDetailsCard;

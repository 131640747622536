import { useNavigate } from "react-router-dom";
import React, { useContext } from "react";
import ProductList from "./ProductList";

//Context import
import AuthContext from "../../../components/shared/Context";

const ProductsListChild = ({ patientRecordId }) => {
  let navigate = useNavigate();
  const { loggedInUserDetails } = useContext(AuthContext);

  const authRoles = ["ST_ADM_ACCESS", "TC_ADM_ACCESS"];

  return (
    <div className="patient-update1">
      <div className="patientTitleContainer1"></div>
      <div className="patientContainer1">
        <div className="patientVisitListContainer1">
          <div>
            <h2>Products</h2>
          </div>
          {authRoles.includes(loggedInUserDetails.role) ? (
            <div className="patientUpdateButton">
              <button
                className="btn btn-primary"
                onClick={() => navigate("/addproduct")}
              >
                Add Products
              </button>
            </div>
          ) : null}
          <ProductList />
        </div>
      </div>
    </div>
  );
};

export default ProductsListChild;

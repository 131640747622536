import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Unauthorised = ({ mesg }) => {
  let navigate = useNavigate();
  const handleClick = () => {
    if (localStorage.getItem("token")) {
      navigate(-1);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <div className="patient-list page-content403">
      {mesg ? (
        <>
          <p>{mesg}</p>
          <p>
            <Button variant="primary" size="medium" onClick={handleClick}>
              Click here
            </Button>
          </p>
          <p>
            <Button variant="danger" size="medium" onClick={handleLogout}>
              Logout
            </Button>
          </p>
        </>
      ) : (
        <>
          <div>
            <h2>404</h2>
          </div>
          <div>
            <h9>&nbsp;User Authorization required !</h9>
          </div>
          <div>Please contact Support / Admin.</div>
        </>
      )}
    </div>
  );
};

export default Unauthorised;

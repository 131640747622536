import Chart from "../../../../components/Chart/Chart";
import ShowPieChart from "../../../../components/Chart/ShowPieChart";

const DiagnosisWiseReportChild = ({ patientData }) => {
  const hemAPatients = patientData.filter(
    (p) => parseInt(p.diagnoses.diagnosis_code) === 1008
  );
  const hemBPatients = patientData.filter(
    (p) => parseInt(p.diagnoses.diagnosis_code) === 1009
  );
  const hemAInhibitorsPatients = patientData.filter(
    (p) => parseInt(p.diagnoses.diagnosis_code) === 1010
  );
  const hemBInhibitorsPatients = patientData.filter(
    (p) => parseInt(p.diagnoses.diagnosis_code) === 1014
  );

  const acquiredHemPatients = patientData.filter(
    (p) => parseInt(p.diagnoses.diagnosis_code) === 1013
  );

  const vWdPatients = patientData.filter(
    (p) => parseInt(p.diagnoses.diagnosis_code) === 1011
  );

  const data01 = [
    {
      name: "Hemophilia A",
      value: hemAPatients.length,
    },
    { name: "Hemophilia B", value: hemBPatients.length },
    {
      name: "Hemophilia A with Inhibitors",
      value: hemAInhibitorsPatients.length,
    },
    {
      name: "Hemophilia B with Inhibitors",
      value: hemBInhibitorsPatients.length,
    },
    { name: "Acquired Hemophilia", value: acquiredHemPatients.length },
    { name: "vWd", value: vWdPatients.length },
    ,
  ];

  return (
    <div className="patient-update w-50">
      {JSON.stringify(hemAPatients.length)}
      <ShowPieChart data={data01} />
    </div>
  );
};

export default DiagnosisWiseReportChild;

import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";
import Chart from "react-apexcharts";
import jwt_decode from "jwt-decode";
//Context import
import AuthContext from "../../components/shared/Context";
import DashboardReportComponent from "./DashboardReportComponent";
import TCDiagnosisCount from "./TCDiagnosisCount";
import NotificationComponent from "../Notifications/NotificationComponent";

const DashboardTCChild = () => {
  let navigate = useNavigate();
  const [{ errorLogApi, customEncrypt }] = useAtom(gbState);
  const { loggedInUserDetails, setPatientDiagnosis, setFieldLabelFromArray } =
    useContext(AuthContext);
  const [data, setData] = useState([]);
  const [vdata, setVdata] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [upcomingAppointments, setUpcomingAppointments] = useState([]);

  const [notifications, setNotifications] = useState();

  useEffect(() => {
    let userToken = localStorage.getItem("token");

    let notification_url = "";

    if (loggedInUserDetails?.tc_id) {
      //TC Admins Notifications
      notification_url = `${process.env.REACT_APP_API_URL}/notifications/${loggedInUserDetails?.tc_id}/5`;
    } else {
      //State Admin Notifications
      notification_url = `${process.env.REACT_APP_API_URL}/notifications/${loggedInUserDetails?._id}/5`;
    }

    const config = {
      method: "GET",
      url: notification_url,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then((response) => {
        // console.log(response.data.notifications);
        setNotifications(response.data.notifications);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    let user = jwt_decode(localStorage.getItem("token"));

    const getDataFromServer = () => {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };

      const bodyParameters = {
        key: "value",
      };

      let urlLink = `${process.env.REACT_APP_API_URL}/patient/${customEncrypt(
        user.tc_id
      )}`;
      if (loggedInUserDetails.role === "FULL_ACCESS") {
        urlLink = `${process.env.REACT_APP_API_URL}/patient/`;
      }

      if (loggedInUserDetails.role === "ST_ADM_ACCESS") {
        urlLink = `${process.env.REACT_APP_API_URL}/patient/state/${
          user && user.state
        }`;
      }

      axios
        .get(urlLink, config)
        .then(
          (res) => {
            const oldData = res.data.patients;
            const updatedData = oldData.map((data) => {
              return {
                ...data,
                id: data._id,
                diagnosis: data.diagnoses.diagnosis_name,
                state: data.tc_state,
              };
            });
            //console.log(updatedData);
            setData(updatedData);
          },
          (error) => {
            if (error.response.data.message) {
              if (
                error.response.data.message.toLowerCase().includes("invalid")
              ) {
                navigate("/login");
              }
            }
            //toast.error("DATA FETCHING ERROR ! Contact Admin");
            errorLogApi(error, "PatientListChild", urlLink);
          }
        )
        .catch((err) => {
          //console.log("error api call ", err.response.data.message);
          if (err) {
            errorLogApi(err, "PatientListChild", urlLink);
          }

          if (err.response.data.message) {
            if (err.response.data.message.toLowerCase().includes("invalid")) {
              navigate("/login");
            }
          }
        });
    };

    const getVisitDataFromServer = () => {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };
      let urlLink = `${process.env.REACT_APP_API_URL}/visits/tc/${user.tc_id}`;
      axios
        .get(urlLink, config)
        .then(
          (res) => {
            const oldData = res.data.visits;
            const updatedData = oldData.map((data) => {
              return {
                ...data,
                id: data._id,
              };
            });
            //console.log(updatedData);
            setVdata(updatedData);
          },
          (error) => {
            if (error.response.data.message) {
              if (
                error.response.data.message.toLowerCase().includes("invalid")
              ) {
                navigate("/login");
              }
            }
            //toast.error("DATA FETCHING ERROR ! Contact Admin");
            errorLogApi(error, "DashboardTC", urlLink);
          }
        )
        .catch((err) => {
          //console.log("error api call ", err.response.data.message);
          if (err) {
            errorLogApi(err, "DashboardTC", urlLink);
          }

          if (err.response.data.message) {
            if (err.response.data.message.toLowerCase().includes("invalid")) {
              navigate("/login");
            }
          }
        });
    };

    getDataFromServer();
    getVisitDataFromServer();
  }, []);

  useEffect(() => {
    let user = jwt_decode(localStorage.getItem("token"));
    const getAppointmentsFromServer = () => {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };
      const bodyParameters = {
        key: "value",
      };
      let urlLink = `${process.env.REACT_APP_API_URL}/appointment/tc/${user?.tc_id}`;
      axios
        .get(urlLink, config)
        .then((res) => {
          const oldData = res.data.appointments;
          const upcoming = res.data.upcoming_appointments;
          setAppointments(oldData);
          setUpcomingAppointments(upcoming);
        })
        .catch((err) => {
          errorLogApi(err, "AppointmentListChild", urlLink);
        });
    };
    getAppointmentsFromServer();
  }, []);

  const [chartOptions, setChartOptions] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
      },
    },
    series: [
      {
        name: "series-1",
        data: [30, 40, 45, 50, 49, 60, 70, 91],
      },
      {
        name: "series-2",
        data: [40, 30, 15, 20, 69, 60, 9, 130],
      },
      {
        name: "series-3",
        data: [60, 40, 35, 90, 45, 3, 39, 10],
      },
    ],
    series2: [
      {
        name: "series-1",
        data: [30, 40, 45, 50, 49, 60, 70, 91],
      },
      {
        name: "series-2",
        data: [40, 30, 15, 20, 69, 60, 9, 130],
      },
    ],
    series3: [
      {
        data: [
          {
            x: new Date("2018-02-12").getTime(),
            y: 76,
          },
          {
            x: new Date("2018-02-12").getTime(),
            y: 76,
          },
        ],
      },
    ],
    xaxis: {
      type: "datetime",
    },
  });

  const reloadNotifications = () => {
    setNotifications(null);
    let userToken = localStorage.getItem("token");
    let notification_url = "";

    if (loggedInUserDetails?.tc_id) {
      //TC Admins Notifications
      notification_url = `${process.env.REACT_APP_API_URL}/notifications/${loggedInUserDetails?.tc_id}/5`;
    } else {
      //State Admin Notifications
      notification_url = `${process.env.REACT_APP_API_URL}/notifications/${loggedInUserDetails?._id}/5`;
    }

    const config = {
      method: "GET",
      url: notification_url,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then((response) => {
        console.log(response.data.notifications);
        setNotifications(response.data.notifications);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="container-fluid">
      {/* start page title */}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <button
                className="btn btn-primary ms-2"
                onClick={reloadNotifications}
              >
                <i className="mdi mdi-autorenew" />
              </button>
            </div>
            <h4 className="page-title">Analytics</h4>
          </div>
        </div>
      </div>
      {/* end page title */}
      <div className="row">
        <div className="col-xl-3 col-lg-4">
          <div className="card tilebox-one">
            <div className="card-body">
              <i className="uil uil-users-alt float-end" />
              <h6 className="text-uppercase mt-0">Enrolled Patients</h6>
              <h2 className="my-2" id="active-users-count">
                <span className="badge bg-success">{data?.length}</span>
              </h2>
              <p className="mb-0 text-muted"></p>
            </div>{" "}
            {/* end card-body*/}
          </div>
          {/*end card*/}
          <div className="card tilebox-one">
            <div className="card-body">
              <i className="uil uil-window-restore float-end" />
              <h6 className="text-uppercase mt-0">Total Visits</h6>
              <h2 className="my-2" id="active-views-count">
                <span className="badge bg-success"> {vdata?.length} </span>
              </h2>
              <p className="mb-0 text-muted">
                <span className="text-nowrap">Since last login</span>
              </p>
            </div>{" "}
            {/* end card-body*/}
          </div>
          {/*end card*/}
          <div className="card cta-box overflow-hidden">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div>
                  <h3 className="m-0 fw-normal cta-box-title">
                    <b>APPOINTMENTS</b>
                  </h3>
                  <h6>
                    TOTAL <br />
                    <i className="mdi mdi-arrow-right" /> {appointments?.length}
                  </h6>
                </div>
                <img
                  className="ms-1"
                  src="/assets/images/email-campaign.svg"
                  width={72}
                  alt="Generic placeholder image"
                />
              </div>
              <div>
                <h6>
                  UPCOMING <br />
                  <i className="mdi mdi-arrow-right" />{" "}
                  {upcomingAppointments?.length}
                </h6>
                <h6>
                  <span
                    className="badge bg-warning float-start text-black"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/upcoming/appointments")}
                  >
                    CLICK HERE
                  </span>
                </h6>
              </div>
            </div>
            {/* end card-body */}
          </div>
        </div>{" "}
        {/* end col */}
        <div className="col-xl-9 col-lg-8">
          <div className="card card-h-100 p-3">
            <h5>Notifications </h5>
            {notifications ? (
              <NotificationComponent
                notificationsData={notifications}
                loggedInUserDetails={loggedInUserDetails}
              />
            ) : (
              "loading ..."
            )}

            {/* end card-body*/}
          </div>{" "}
          {/* end card*/}
        </div>
      </div>
      {/* Realtime Data Visualization below */}
      <DashboardReportComponent tcData={data} />
    </div>
  );
};

export default DashboardTCChild;

import React, { useRef } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
} from "recharts";
import html2canvas from "html2canvas";

const PatientVisitFrequencyChart = ({ data }) => {
  const chartRef = useRef(null);

  // Aggregate data to count visits per patient
  const visitFrequency = data.reduce((acc, item) => {
    const patientName = item.patient_name;
    if (!acc[patientName]) {
      acc[patientName] = 0;
    }
    acc[patientName]++;
    return acc;
  }, {});

  // Transform the data for the chart
  const chartData = Object.entries(visitFrequency).map(
    ([patientName, visits]) => ({
      patient_name: patientName,
      visits: visits,
    })
  );

  // Sort the data by visit frequency in descending order
  chartData.sort((a, b) => b.visits - a.visits);

  const handleDownload = () => {
    if (chartRef.current) {
      html2canvas(chartRef.current).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = imgData;
        link.download = "patient_visit_frequency_chart.png";
        link.click();
      });
    }
  };

  return (
    <div>
      <h2>Patient Visit Frequency</h2>
      <div ref={chartRef}>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="patient_name"
              angle={-45}
              textAnchor="end"
              height={70}
            />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="visits" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
        <div style={{ marginTop: "20px" }}>
          <h3>Visit Frequency Details:</h3>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={tableHeaderStyle}>Patient Name</th>
                <th style={tableHeaderStyle}>Number of Visits</th>
              </tr>
            </thead>
            <tbody>
              {chartData.map(({ patient_name, visits }) => (
                <tr key={patient_name}>
                  <td style={tableCellStyle}>{patient_name}</td>
                  <td style={tableCellStyle}>{visits}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button
          onClick={handleDownload}
          style={{
            padding: "10px 20px",
            backgroundColor: "#4CAF50",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Download Chart
        </button>
      </div>
    </div>
  );
};

const tableHeaderStyle = {
  backgroundColor: "#f2f2f2",
  padding: "10px",
  borderBottom: "1px solid #ddd",
  textAlign: "left",
};

const tableCellStyle = {
  padding: "10px",
  borderBottom: "1px solid #ddd",
};

export default PatientVisitFrequencyChart;

import React from "react";
import MainContentCard from "../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../components/shared/ErrorBoundaryRoutes";
import NotificationsChild from "./NotificationsChild";

const Notifications = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="Notifications" route="/notifications">
        <NotificationsChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default Notifications;

import React, { useState } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import MainContent from "../Home/MainContent";
import QuickStart from "./QuickStart";
import VideoHelp from "./VideoHelp";
import FaqHelp from "./FaqHelp";
import IntroVideo from "./IntroVideo";

const HelpDashboard = () => {
  const [tabState, setTabState] = useState(true);
  const [value, setValue] = useState(1);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ span: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <MainContent>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{ maxWidth: { xs: 420, sm: 780 }, bgcolor: "background.paper" }}
        >
          <Tabs
            style={{ backgroundColor: "#f5f5f2" }}
            value={value}
            onChange={handleChangeTabs}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Video Tutorials" value={1} />
            <Tab label="FAQs" value={2} />
            {/* Remove frmo here 3,4 options in final build for NHM */}
            <Tab label="Demo Slides" value={3} />
            {/* <Tab label="iCare Intro Video" value={4} /> */}
          </Tabs>
        </Box>

        <TabPanel value={value} index={1}>
          <VideoHelp />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <FaqHelp />
        </TabPanel>
        {/* Remove frmo here 3,4 options in final build for NHM */}
        <TabPanel value={value} index={3}>
          <QuickStart />
        </TabPanel>
        {/* <TabPanel value={value} index={4}>
          <IntroVideo />
        </TabPanel> */}
      </Box>
    </MainContent>
  );
};

export default HelpDashboard;

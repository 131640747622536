import React from "react";
import { useEffect, useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import { BsQuestionCircleFill } from "react-icons/bs";

import GeneralDetailsCard from "../../pages/Patients/List/GeneralDetailsCard";
import ClinicalDetailsCard from "../../pages/Patients/List/ClinicalDetailsCard";
import BleedingHistoryCard from "../../pages/Patients/List/BleedingHistoryCard";
import VaccinationDetailsCard from "../../pages/Patients/List/VaccinationDetailsCard";
import AddressDetailsCard from "../../pages/Patients/List/AddressDetailsCard";
import ContactDetailsCard from "../../pages/Patients/List/ContactDetailsCard";
import EconomicStatusCard from "../../pages/Patients/List/EconomicStatusCard";
import SchedulingDetailsCard from "../../pages/Patients/List/SchedulingDetailsCard";

//Context import
import AuthContext from "../../components/shared/Context";

const PatientInfoBlock = ({ selectedPatientProfile }) => {
  const { loggedInUserDetails, setPatientDiagnosis, setFieldLabelFromArray } =
    useContext(AuthContext);
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };

  function calculateAge(birthDateString) {
    // Check if the birth date string is not provided or is invalid
    if (!birthDateString || isNaN(Date.parse(birthDateString))) {
      return null;
    }

    // Convert the input string to a Date object
    const birthDate = new Date(birthDateString);

    // Get today's date
    const today = new Date();

    // Calculate the age
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust the age if the current month is before the birth month
    // Or if it's the birth month but the day is yet to come
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return ` (${age} yrs)`;
  }

  return (
    <div>
      <fieldset className="border p-2 w-100">
        <legend className="float-none w-auto p-2 fs-5">
          Patient Information
        </legend>
        <div className="newPatientFormOnly">
          <div
            style={{ width: "100%", paddingLeft: "20px", paddingRight: "20px" }}
          >
            <div className="table-responsive overflow-y:hidden">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th scope="row">Name</th>
                    <td>
                      {" "}
                      {selectedPatientProfile?.patient_name} &nbsp;{" "}
                      <BsQuestionCircleFill
                        color="green"
                        className="helper-icon"
                        onClick={() => viewRecord(selectedPatientProfile)}
                      />
                    </td>
                    <th scope="row">Disease</th>
                    <td>
                      {selectedPatientProfile?.diagnoses &&
                        selectedPatientProfile?.diagnoses?.diagnosis_name}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Patient ID</th>
                    <td>{selectedPatientProfile?.patientId}</td>
                    <th scope="row">Date of Birth</th>
                    <td>
                      {selectedPatientProfile?.date_of_birth}
                      <span className="text-bold">
                        &nbsp;&nbsp;
                        {calculateAge(selectedPatientProfile?.date_of_birth)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Mobile</th>
                    <td>{selectedPatientProfile?.contact?.primary_mobile}</td>
                    <th scope="row">Weight</th>
                    <td>{selectedPatientProfile?.weight}</td>
                  </tr>
                  <tr>
                    <th scope="row">Email</th>
                    <td>{selectedPatientProfile?.contact?.email}</td>
                    <th scope="row">Blood Group</th>
                    <td>
                      {selectedPatientProfile?.bloodgroup &&
                        JSON.parse(selectedPatientProfile?.bloodgroup)?.label}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </fieldset>
      {/* Modal Popup */}
      <Modal show={openModal} size="lg">
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent
            currentRecord={currentRecord}
            setFieldLabelFromArray={setFieldLabelFromArray}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

const ModalContent = ({ currentRecord, setFieldLabelFromArray }) => {
  return (
    <div className="container-fluid legend">
      <GeneralDetailsCard
        currentRecord={currentRecord}
        setFieldLabelFromArray={setFieldLabelFromArray}
      />
      <ClinicalDetailsCard
        currentRecord={currentRecord}
        setFieldLabelFromArray={setFieldLabelFromArray}
      />
      <BleedingHistoryCard
        currentRecord={currentRecord}
        setFieldLabelFromArray={setFieldLabelFromArray}
      />
      <VaccinationDetailsCard
        currentRecord={currentRecord}
        setFieldLabelFromArray={setFieldLabelFromArray}
      />
      <AddressDetailsCard
        currentRecord={currentRecord}
        setFieldLabelFromArray={setFieldLabelFromArray}
      />
      <ContactDetailsCard
        currentRecord={currentRecord}
        setFieldLabelFromArray={setFieldLabelFromArray}
      />
      <EconomicStatusCard
        currentRecord={currentRecord}
        setFieldLabelFromArray={setFieldLabelFromArray}
      />
      <SchedulingDetailsCard currentRecord={currentRecord} />
    </div>
  );
};

export default PatientInfoBlock;

import React, { useEffect, useState, useContext, useRef } from "react";

import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Card from "react-bootstrap/Card";
import MainContent from "../../Home/MainContent";
import "antd/dist/antd.min.css";
import { Modal } from "react-bootstrap";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@mui/material/Tooltip";
//Global State import
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

//Context import
import AuthContext from "../../../components/shared/Context";

const InventoryStatus = () => {
  let navigate = useNavigate();
  const { loggedInUserDetails } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const searchRef = useRef();

  //Error Logging Service
  const { dateFormatter, errorLogApi } = useAtom(gbState);

  const getDataFromServer = () => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };

    //console.log(config);

    const bodyParameters = {
      key: "value",
    };

    let urlLink = `${process.env.REACT_APP_API_URL}/state/pwstock/${loggedInUserDetails.state}`;

    // if (loggedInUserDetails.role === "FULL_ACCESS") {
    //   urlLink = `${process.env.REACT_APP_API_URL}/state/stock/`;
    // }

    // if (loggedInUserDetails.role === "ST_ADM_ACCESS") {
    //   urlLink = `${process.env.REACT_APP_API_URL}/suppliers/state/${loggedInUserDetails.state}`;
    // }

    axios
      .get(urlLink, config)
      .then((res) => {
        const oldData = res.data.product_stock;
        console.log(oldData);
        const updatedData = oldData.map((data) => {
          //   console.log(data.purchase_inventory.product_id.products);
          return {
            ...data,
            id: data._id,
            product_name: data.product_stock.product_id.products.product_name,
            item_code: data.product_stock.item_code,
            measuring_unit:
              data.product_stock.product_id.products.measuring_unit,
            company_name: data.product_stock.product_id.products.company_name,
            product_expiry: data.product_stock.expiry_date,
            state: data.product_stock.state,
            quantity:
              data.product_stock.quantity +
              " " +
              data.product_stock.product_id.products.storage_unit,
            updatedAt: data.updatedAt,
          };
        });
        //console.log(updatedData);
        setData(updatedData);
      })
      .catch((err) => console.log("error", err));
  };

  useEffect(() => {
    getDataFromServer();
    // getProducts();
  }, []);

  const useStyles = makeStyles(() => ({
    multiLineCell: {
      display: "flex",
      wordBreak: "keep-all",
      wordWrap: "break-word",
      overflowWrap: "break-word",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      lineHeight: "1.2rem",
      height: "auto",
    },
  }));

  const calculateDaysLeft = (date) => {
    // Convert the given date string to a Date object
    const givenDate = new Date(date);

    // Calculate the difference between the current date and the given date in milliseconds
    const timeDiff = givenDate.getTime() - new Date().getTime();

    // Convert milliseconds to days and round down to the nearest integer
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    // Update the state with the number of days left
    //setDaysLeft(days);
    return days;
  };

  const MultiLineCellRenderer = (params) => {
    const classes = useStyles();
    const daysLeft = calculateDaysLeft(params.row.product_expiry);
    return (
      <div className={classes.multiLineCell}>
        <span id="content1">{params.row.product_name}</span>
        <span id="content2">
          <small className="small-font-size">
            Expiry: {daysLeft !== null ? `${daysLeft} days` : <p>Loading...</p>}
          </small>
        </span>
      </div>
    );
  };

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            <Tooltip title="View Product">
              <a onClick={() => viewRecord(params.row)}>
                <i
                  className="mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </a>
            </Tooltip>
            <Tooltip title="View Product">
              <a onClick={() => viewRecord(params.row)}>
                <i
                  className="mdi mdi-medical-bag"
                  style={{ fontSize: "17px", color: "black" }}
                ></i>
              </a>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "product_name",
      headerName: "Product",
      width: 200,
      renderCell: MultiLineCellRenderer,
    },
    {
      field: "item_code",
      headerName: "Product Code",
      width: 120,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 150,
    },
    {
      field: "state",
      headerName: "State",
      width: 110,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };

  const unfilterData = data;
  const handleSearch = (e) => {
    console.log("Search Value...", e);

    if (e) {
      setData(
        unfilterData.filter((item) => {
          return (
            item?.product_code?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.product_name?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.company_name?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.state?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.quantity?.toLowerCase().includes(e?.toLowerCase())
          );
        })
      );
    } else {
      getDataFromServer();
    }
  };

  const clearSearch = () => {
    searchRef.current.value = null;
    getDataFromServer();
  };

  return (
    <MainContent>
      <Card style={{ width: "100%", marginTop: "10px", padding: "30px" }}>
        <Card.Header>
          <span className="card-title">
            <h3>ProductWise Stocks (Current Status)</h3>
          </span>
        </Card.Header>
        <Card.Body>
          <div className="patientListSearch">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">Filter</InputGroup.Text>
              <Form.Control
                placeholder="Product / State / Code / Company ..."
                aria-label="Search"
                aria-describedby="basic-addon1"
                name="searchPatients"
                ref={searchRef}
                onChange={(e) => handleSearch(e.target.value)}
              />
              <InputGroup.Text>
                <i
                  className="mdi mdi-delete-outline"
                  onClick={clearSearch}
                  style={{ cursor: "pointer" }}
                ></i>
              </InputGroup.Text>
            </InputGroup>
          </div>
          <DataGrid
            rows={data}
            disableSelectionOnClick
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            displayTitle="Current Stocks"
            checkboxSelection
            style={{ height: "60vh" }}
          />
        </Card.Body>
      </Card>

      {/* Modal Popup */}
      <Modal show={openModal} centered>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Stocks Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent currentRecord={currentRecord} />
        </Modal.Body>
      </Modal>

      {/* {JSON.stringify(currentRecord)} */}
    </MainContent>
  );
};

const ModalContent = ({ currentRecord }) => {
  const dateFormatter = (date) => {
    const date_conv = new Date(date);
    const fullyear = date_conv.getFullYear();
    const month = date_conv.getMonth() + 1;
    const day = date_conv.getDate();
    return `${day}/${month}/${fullyear}`;
  };

  return (
    <div className="container-fluid">
      <table class="table table-striped">
        <tbody>
          <tr>
            <th scope="row">Product Name</th>
            <td>{currentRecord.product_name}</td>
          </tr>
          <tr>
            <th scope="row">Measuring Units</th>
            <td>{currentRecord.measuring_unit}</td>
          </tr>
          <tr>
            <th scope="row">Quantity</th>
            <td>{currentRecord.quantity}</td>
          </tr>
          <tr>
            <th scope="row">Expiry</th>
            <td>{dateFormatter(currentRecord.product_expiry)}</td>
          </tr>
          <tr>
            <th scope="row">Company Name</th>
            <td>{currentRecord.company_name}</td>
          </tr>
          <tr>
            <th scope="row">State</th>
            <td>{currentRecord.state}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default InventoryStatus;

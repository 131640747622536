import React, { useEffect, useState } from "react";
import { RadioGroup, Radio } from "react-radio-group";
import { Button } from "react-bootstrap";
import axios from "axios";
import toast from "react-hot-toast";
import "./FishScores.css";

const FishScores = ({ emrRecordId, jsRecord }) => {
  const [totalScore, setTotalScore] = useState(0);

  const [formData, setFormData] = useState({
    selfcare_eating_and_grooming: null,
    selfcare_bathing: null,
    selfcare_dressing: null,
    transfers_chair: null,
    transfers_squatting: null,
    locomotion_walking: null,
    locomotion_stairs: null,
    locomotion_running: null,
  });

  // useEffect(() => {
  //   let fishscore = localStorage.getItem(`${emrRecordId}-fishscores`);
  //   setFormData({ ...formData, ...JSON.parse(fishscore) });
  // }, []);

  useEffect(() => {
    if (jsRecord) {
      localStorage.setItem(
        `${emrRecordId}-fishscores`,
        JSON.stringify(jsRecord)
      );
    }
    let fishscore = localStorage.getItem(`${emrRecordId}-fishscores`);
    setFormData({ ...formData, ...JSON.parse(fishscore) });
  }, [jsRecord]);

  useEffect(() => {
    setTotalScore(
      Object.values({ ...formData })
        .filter((value) => value != null)
        .reduce((prev, value) => (prev = prev + parseInt(value)), 0)
    );
  }, [formData]);

  const updateFishScore = (e) => {
    e.preventDefault();

    //changeTab(1);

    //  alert("Click to submit");

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/jointsscore/update/${emrRecordId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, fishscores: true },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        localStorage.setItem(
          `${emrRecordId}-fishscores`,
          JSON.stringify(formData)
        );
        toast.success("FishScore saved Successfully.");
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error.response.data));
      });
  };

  const handleChangeValue = ({ target: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  return (
    <form className="" onSubmit={(e) => updateFishScore(e)}>
      <table class="table table-striped table-responsive">
        {/* <progress id="file" value="32" max="100">
        32%
      </progress> */}
        <thead>
          <tr>
            <td colSpan={5} style={{ borderBottom: "none" }}></td>
          </tr>
          <tr>
            <th colSpan={2}>Total Score: {totalScore}</th>
            <th colSpan={3} style={{ textAlign: "right" }}>
              <Button variant="primary" size="medium" type="submit">
                Save
              </Button>
            </th>
          </tr>
          {/* <tr>
            <td colSpan={5}>{JSON.stringify(formData)}</td>
          </tr> */}
          <tr>
            <th style={{ width: "30%" }}>&nbsp;</th>
            <th style={{ textAlign: "center" }}>1</th>
            <th style={{ textAlign: "center" }}>2</th>
            <th style={{ textAlign: "center" }}>3</th>
            <th style={{ textAlign: "center" }}>4</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <th colSpan={5}>A. Selfcare</th>
          </tr>
          <FishScoreRow
            handleChange={handleChangeValue}
            rowOptionTitle="Eating & Grooming"
            radioButtonName="selfcare_eating_and_grooming"
            selectedValue={formData.selfcare_eating_and_grooming}
          />
          <FishScoreRow
            handleChange={handleChangeValue}
            rowOptionTitle="Bathing"
            radioButtonName="selfcare_bathing"
            selectedValue={formData.selfcare_bathing}
          />
          <FishScoreRow
            handleChange={handleChangeValue}
            rowOptionTitle="Dressing"
            radioButtonName="selfcare_dressing"
            selectedValue={formData.selfcare_dressing}
          />
          <tr>
            <th colSpan={5}>B. Transfers</th>
          </tr>
          <FishScoreRow
            handleChange={handleChangeValue}
            rowOptionTitle="Chair"
            radioButtonName="transfers_chair"
            selectedValue={formData.transfers_chair}
          />
          <FishScoreRow
            handleChange={handleChangeValue}
            rowOptionTitle="Squatting"
            radioButtonName="transfers_squatting"
            selectedValue={formData.transfers_squatting}
          />
          <tr>
            <th colSpan={5}>C. Locomotion</th>
          </tr>
          <FishScoreRow
            handleChange={handleChangeValue}
            rowOptionTitle="Walking"
            radioButtonName="locomotion_walking"
            selectedValue={formData.locomotion_walking}
          />
          <FishScoreRow
            handleChange={handleChangeValue}
            rowOptionTitle="Stairs"
            radioButtonName="locomotion_stairs"
            selectedValue={formData.locomotion_stairs}
          />
          <FishScoreRow
            handleChange={handleChangeValue}
            rowOptionTitle="Running"
            radioButtonName="locomotion_running"
            selectedValue={formData.locomotion_running}
          />
          <tr>
            <td colSpan={5} style={{ textAlign: "right" }}>
              1 - No Difficulty <br />4 - Maximum Difficulty
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default FishScores;

const FishScoreRow = ({
  radioButtonName,
  handleChange,
  rowOptionTitle,
  selectedValue,
}) => {
  return (
    <tr>
      <td>{rowOptionTitle}</td>
      <td>
        <RadioGroup
          name={radioButtonName}
          // selectedValue={this.state.selectedValue}
        >
          <Radio
            value="1"
            onClick={handleChange}
            className="radioButton"
            checked={1 === parseInt(selectedValue) ? true : false}
          />
        </RadioGroup>
      </td>
      <td>
        <RadioGroup
          name={radioButtonName}
          // selectedValue={this.state.selectedValue}
        >
          <Radio
            value="2"
            onClick={handleChange}
            className="radioButton"
            checked={2 === parseInt(selectedValue) ? true : false}
          />
        </RadioGroup>
      </td>
      <td>
        <RadioGroup
          name={radioButtonName}
          // selectedValue={this.state.selectedValue}
        >
          <Radio
            value="3"
            onClick={handleChange}
            className="radioButton"
            checked={3 === parseInt(selectedValue) ? true : false}
          />
        </RadioGroup>
      </td>
      <td>
        <RadioGroup
          name={radioButtonName}
          // selectedValue={this.state.selectedValue}
        >
          <Radio
            value="4"
            onClick={handleChange}
            className="radioButton"
            checked={4 === parseInt(selectedValue) ? true : false}
          />
        </RadioGroup>
      </td>
    </tr>
  );
};

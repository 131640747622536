import React from "react";
import { useLocation } from "react-router-dom";

import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import AllocateTCChild from "./AllocateTCChild";

const AllocateTC = () => {
  const { state } = useLocation();
  let allocateProduct = state;
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="AllocateTC" route="/tcallocation">
        <AllocateTCChild allocateProduct={allocateProduct} />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default AllocateTC;

import React from "react";
import Chart from "react-apexcharts";

const BarChartComponent = ({ data }) => {
  const series = [
    {
      name: "Count",
      data: Object.values(data),
    },
  ];

  // Generate random colors for each bar
  const colors = Object.keys(data).map(
    () => "#" + Math.floor(Math.random() * 16777215).toString(16)
  );

  const options = {
    chart: {
      type: "bar",
      id: "basic-bar",
      toolbar: {
        show: true,
      },
    },
    colors: colors, // Apply the random colors
    xaxis: {
      categories: Object.keys(data),
      title: {
        text: "Diseases",
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: true, // Distribute colors to each bar
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val) {
          return val + " patients";
        },
      },
    },
    title: {
      text: "",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
        },
      },
    ],
  };

  return (
    <div>
      <Chart options={options} series={series} type="bar" />
    </div>
  );
};

export default BarChartComponent;

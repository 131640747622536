import React from "react";
import Card from "react-bootstrap/Card";

const BleedingHistoryCard = ({ currentRecord }) => {
  return (
    <Card>
      <Card.Header>
        <i className="fas fa-align-justify" />
        <strong>Patient Bleeding History</strong>
      </Card.Header>
      <Card.Body>
        {currentRecord?.bleeding_history?.family_history_bleed_disorder && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b>
                {" "}
                {"family_history_bleed_disorder"
                  .replaceAll("_", " ")
                  .toUpperCase()}
              </b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {
                JSON.parse(
                  currentRecord?.bleeding_history?.family_history_bleed_disorder
                )?.label
              }
            </div>
          </div>
        )}
        {currentRecord?.bleeding_history?.family_history_bleed_description && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b>
                {" "}
                {"family_history_bleed_description"
                  .replaceAll("_", " ")
                  .toUpperCase()}
              </b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {
                currentRecord?.bleeding_history
                  ?.family_history_bleed_description
              }
            </div>
          </div>
        )}
        {currentRecord?.bleeding_history?.annual_bleed_frequency && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b>
                {" "}
                {"annual_bleed_frequency".replaceAll("_", " ").toUpperCase()}
              </b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {currentRecord?.bleeding_history?.annual_bleed_frequency}
            </div>
          </div>
        )}
        {currentRecord?.bleeding_history?.first_time_bleed && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b> {"first_time_bleed".replaceAll("_", " ").toUpperCase()}</b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {
                new Date(currentRecord?.bleeding_history?.first_time_bleed)
                  .toISOString()
                  .split("T")[0]
              }
            </div>
          </div>
        )}
        {currentRecord?.bleeding_history?.age_at_first_diagnosis && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b>
                {" "}
                {"age_at_first_diagnosis".replaceAll("_", " ").toUpperCase()}
              </b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {currentRecord?.bleeding_history?.age_at_first_diagnosis}
            </div>
          </div>
        )}
        {currentRecord?.bleeding_history?.date_first_factor_inclusion && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b>
                {" "}
                {"date_first_factor_inclusion"
                  .replaceAll("_", " ")
                  .toUpperCase()}
              </b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {
                new Date(
                  currentRecord?.bleeding_history?.date_first_factor_inclusion
                )
                  .toISOString()
                  .split("T")[0]
              }
            </div>
          </div>
        )}
        {currentRecord?.bleeding_history?.first_factor_exposure && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b>
                {" "}
                {"first_factor_exposure".replaceAll("_", " ").toUpperCase()}
              </b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {
                new Date(currentRecord?.bleeding_history?.first_factor_exposure)
                  .toISOString()
                  .split("T")[0]
              }
            </div>
          </div>
        )}
        {currentRecord?.bleeding_history?.type_of_first_time_bleed && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b>
                {" "}
                {"type_of_first_time_bleed".replaceAll("_", " ").toUpperCase()}
              </b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {
                JSON.parse(
                  currentRecord?.bleeding_history?.type_of_first_time_bleed
                )?.label
              }
            </div>
          </div>
        )}
        {currentRecord?.bleeding_history?.factor_level_viii_percent && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b>
                {" "}
                {"factor_level_viii_percent".replaceAll("_", " ").toUpperCase()}
              </b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {currentRecord?.bleeding_history?.factor_level_viii_percent}
            </div>
          </div>
        )}
        {currentRecord?.bleeding_history?.factor_level_ix_percent && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b>
                {" "}
                {"factor_level_ix_percent".replaceAll("_", " ").toUpperCase()}
              </b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {currentRecord?.bleeding_history?.factor_level_ix_percent}
            </div>
          </div>
        )}
        {currentRecord?.bleeding_history?.precipitating_factor && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b>
                {" "}
                {"precipitating_factor".replaceAll("_", " ").toUpperCase()}
              </b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {
                JSON.parse(
                  currentRecord?.bleeding_history?.precipitating_factor
                ).label
              }
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default BleedingHistoryCard;

import "./PatientCreate.css";

import { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import {
  bloodGroupData,
  idCardType,
  patientFamilyAnnualIncome,
  employmentType,
  educationQualification,
  diagnosisData,
  diagnosisTypeData,
  precipitatingFactorData,
} from "../../../components/Data/ChartData";

import indianCities from "../../../components/Data/IndianCities";
import axios from "axios";
import toast from "react-hot-toast";
import { Tabs, Tab } from "react-bootstrap";
import GeneralTab from "./GeneralTab";
import ClinicalTab from "./ClinicalTab";
// import ContactTab from "./ContactTab";
import validator from "aadhaar-validator";

// import { useNavigate } from "react-router-dom";

//Global State import
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

//Context import
import AuthContext from "../../../components/shared/Context";

import "bootstrap/dist/css/bootstrap.min.css";
import VaccinationTab from "./VaccinationTab";
// import SchedulingTab from "./SchedulingTab";

const PatientCreateChild = ({ preRegistrationRecord }) => {
  // let navigate = useNavigate();
  //  const { checkLoggedInStatus, loggedInUserDetails, selectedDiagnosis } =

  const { checkLoggedInStatus } = useContext(AuthContext);

  //To check if the user is logged in
  // useEffect(() => {
  checkLoggedInStatus();
  // }, []);

  return (
    <div className="patient-create">
      <FormElement preRegistrationRecord={preRegistrationRecord} />
    </div>
  );
};

const FormElement = ({ preRegistrationRecord }) => {
  const patientNameInput = useRef();
  const aadhaarNumberInput = useRef();
  const genderInput = useRef();
  const bloodGroupInput = useRef();
  const dobInput = useRef();
  const weightInput = useRef();
  const motherNameInput = useRef();
  const primaryMobileInput = useRef();
  const belongsToInput = useRef();
  const localRegistrationId = useRef();

  let navigate = useNavigate();
  const { loggedInUserDetails, selectedDiagnosis } = useContext(AuthContext);

  //Error Logging Service
  const [{ errorLogApi, sanitizeInput, encryptData, user }] = useAtom(gbState);

  const [patientId, setPatientId] = useState(null);
  const [selectedCard, setSelectedCard] = useState("ID Card");
  const [stateValue, setStateValue] = useState(loggedInUserDetails.state);
  const [city, setCity] = useState();
  const [localRegField, setLocalRegField] = useState(true);
  const tabSequence = [
    "general",
    "clinical",
    "vaccination",
    // "contact",
    // "schedule",
  ];
  const [tabState, setTabState] = useState(0);

  const [formData, setFormData] = useState({
    temp_patient_id: preRegistrationRecord?.temp_patient_id
      ? preRegistrationRecord?.temp_patient_id
      : null,
    patientId: null,
    local_registration_id: null,
    patient_name: preRegistrationRecord?.patient_name
      ? preRegistrationRecord?.patient_name
      : null,
    gender: preRegistrationRecord?.gender
      ? preRegistrationRecord?.gender
      : null,
    date_of_birth: preRegistrationRecord?.date_of_birth
      ? preRegistrationRecord?.date_of_birth
      : null,
    weight: null,
    height: null,
    bloodgroup: null,
    mothers_name: preRegistrationRecord?.mothers_name
      ? preRegistrationRecord?.mothers_name
      : null,
    fathers_name: null,
    marital_status: null,
    spouse_name: null,
    consanguinity: 100,
    hla_status: null,
    languages_known: "",
    card_type: "AADHARCARD",
    card_number: null,
    belongs_to: null,
    issuing_authority: "CENTRAL-GOVT",
    address: null,
    landmark: null,
    state: loggedInUserDetails.state,
    district: null,
    pincode: null,
    primary_mobile: preRegistrationRecord?.primary_mobile
      ? preRegistrationRecord?.primary_mobile
      : null,
    whatsapp_number: preRegistrationRecord
      ? preRegistrationRecord?.whatsapp_number
      : null,
    secondary_mobile: preRegistrationRecord?.secondary_mobile
      ? preRegistrationRecord?.secondary_mobile
      : null,
    landline: preRegistrationRecord?.landline
      ? preRegistrationRecord?.landline
      : null,
    email: preRegistrationRecord?.email ? preRegistrationRecord?.email : null,
    patient_annual_income: null,
    family_annual_income: null,
    employment_type: null,
    source_of_funds: null,
    education: null,
    diagnosis_name: selectedDiagnosis?.label,
    diagnosis_code: selectedDiagnosis?.id,
    category_id: selectedDiagnosis?.category_id, //Added on 31 jan2024
    diagnosis_id: selectedDiagnosis?.diagnosis_id, //Added on 31 jan2024
    diagnosis_type: null,
    diagnosis_others: null,
    genetic_counselling: false,
    genetic_counselling_date: null,
    carrier_status_sister: false,
    carrier_status_mother: false,
    molecular_diagnosis: null,
    clot_solubility_test: null,
    aptt: null,
    pt: null,
    platelet_count: null,
    hemoglobin_gm_dl: null,
    counseled_prenatal_diagnosis: false,
    counseled_prenatal_diagnosis_date: null,
    tlc: null,
    mcv: null,
    parental_hplc_father: null,
    parental_hplc_mother: null,
    donor_search_status: false,
    donor_search_date: null,
    donor_search_result: null,
    counseled_for_bmt_status: false,
    counseled_for_bmt_date: null,
    transfusion_regimen: null,
    special_transfusion_needs: null,
    special_transfusion_note: null,
    on_chelation: null,
    splenectomy: null,
    atypical_antibodies: null,
    date_of_diagnosis: null,
    date_of_first_transfusion: null,
    number_of_transfusions: null,
    approx_blood_vol_transfused_ml: null,
    tanner_scale_genital_breasts: null,
    tanner_scale_pubic_hair: null,
    tanner_scale_last_assesment_date: null,
    genetic_mutations: null,
    hepatitis_b_vaccination: null,
    hbv_date_of_vaccination_1st: null,
    hbv_date_of_vaccination_2nd: null,
    hbv_date_of_vaccination_3rd: null,
    meningococcal_vaccination: null,
    date_of_meningococcal_vaccination: null,
    pneumococcal_vaccination: null,
    date_of_pneumococcal_vaccination: null,
    typhoid_vaccination: null,
    date_of_typhoid_vaccination: null,
    h_influenza_vaccination: null,
    date_of_h_influenza_vaccination: null,
    patient_revisit_days: null,
    next_scheduled_appointment_date: null,
    annual_bleed_frequency: null,
    first_time_bleed: null,
    age_at_first_diagnosis: null,
    first_factor_exposure: null,
    type_of_first_time_bleed: null,
    treatment_center: loggedInUserDetails?.tc_id,
    tc_state: loggedInUserDetails.state,
    date_first_factor_inclusion: null,
    family_history_bleed_disorder: null,
    family_history_bleed_description: null,
    factor_level_viii_percent: null,
    factor_level_ix_percent: null,
    precipitating_factor: null,
    createdBy: loggedInUserDetails?._id,
  });

  //Created a set for indian cities
  const statesSet = new Set(
    indianCities.map((city, index) => {
      return city?.state;
    })
  );

  //Converted Set to Array of States
  const states = [...statesSet];

  function makeid(length) {
    var result = "";
    //var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const generateRandomID = () => {
    const id_4_digit = Math.floor(1000 + Math.random() * 9000);
    const pre_id = `${loggedInUserDetails.state.substring(0, 2).toUpperCase()}`;

    const getSubIdPrefix = (category) => {
      const lowerCaseCategory = category?.toLowerCase();
      if (
        lowerCaseCategory.includes("hemophilia") ||
        lowerCaseCategory.includes("haemophilia")
      ) {
        return "HID";
      } else if (lowerCaseCategory.includes("thalassemia")) {
        return "TID";
      } else if (lowerCaseCategory.includes("sickle")) {
        return "SKL";
      }
      return "GEN"; // Generic prefix for other categories
    };

    const sub_id = `${pre_id}-${getSubIdPrefix(selectedDiagnosis?.category)}-`;
    const id_main = `${sub_id}${id_4_digit}${makeid(2)}`;
    return id_main;
  };

  useEffect(() => {
    const generateRandomID = () => {
      const id_4_digit = Math.floor(1000 + Math.random() * 9000);
      const pre_id = `${loggedInUserDetails.state
        .substring(0, 2)
        .toUpperCase()}`;

      const getSubIdPrefix = (category) => {
        const lowerCaseCategory = category?.toLowerCase();
        if (
          lowerCaseCategory.includes("hemophilia") ||
          lowerCaseCategory.includes("haemophilia")
        ) {
          return "HID";
        } else if (lowerCaseCategory.includes("thalassemia")) {
          return "TID";
        } else if (lowerCaseCategory.includes("sickle")) {
          return "SKL";
        }
        return "GEN"; // Generic prefix for other categories
      };

      const sub_id = `${pre_id}-${getSubIdPrefix(
        selectedDiagnosis?.category
      )}-`;
      const id_main = `${sub_id}${id_4_digit}${makeid(2)}`;
      return id_main;
    };

    const patient_id = generateRandomID();
    setPatientId(patient_id);
  }, [selectedDiagnosis, loggedInUserDetails]);

  const adminRolesCheck = ["FULL_ACCESS", "ST_ADM_ACCESS"];

  const handlePatientRegistration = (save_all) => {
    //e.preventDefault();
    // alert(JSON.stringify({ ...formData }));
    let userToken = localStorage.getItem("token");

    if (adminRolesCheck.includes(loggedInUserDetails.role)) {
      toast.error(
        "State Admin cannot create the Patient record, Kindly login as Doctor/Nurse/Deo"
      );
      return;
    }

    if (!formData.treatment_center) {
      toast.error("Please add Treatment Center for Patient");
      return;
    }

    if (!formData.patient_name) {
      setTabState(0);
      patientNameInput.current.focus();
      toast.error("Please add Name Patient");
      return;
    }

    if (!formData.date_of_birth) {
      setTabState(0);
      dobInput.current.focus();
      toast.error("Please add Date of Birth");
      return;
    }

    if (!formData.gender) {
      setTabState(0);
      genderInput.current.focus();
      toast.error("Please Select Gender");
      return;
    }

    // if (!formData.bloodgroup) {
    //   setTabState(0);
    //   bloodGroupInput.current.focus();
    //   toast.error("Please Select Blood Group");
    //   return;
    // }

    // if (!formData.mothers_name) {
    //   setTabState(0);
    //   motherNameInput.current.focus();
    //   toast.error("Please Enter Mother's Name");
    //   return;
    // }

    if (!formData.card_number) {
      setTabState(0);
      aadhaarNumberInput.current.focus();
      toast.error("Please add Valid Adhaar Card Number for Patient");
      return;
    }

    if (!formData.belongs_to) {
      setTabState(0);
      aadhaarNumberInput.current.focus();
      toast.error("Please Set Adhaar Belongs to ... for Patient");
      return;
    }

    if (!validator.isValidNumber(formData.card_number)) {
      toast.error("Please add Valid Adhaar Card Number for Patient");
      return;
    }

    if (!save_all) {
      if (tabState !== tabSequence.length) {
        setTabState((prev) => prev + 1);
        return;
      } else {
        setTabState(0);
      }
    }

    // if (!formData.primary_mobile) {
    //   setTabState(0);
    //   primaryMobileInput.current.focus();
    //   toast.error("Please add Valid Primary Mobile for Patient");
    //   return;
    // }

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/patient/v2/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, card_number: encryptData(formData?.card_number) },
    };
    axios(config)
      .then(function (response) {
        // if (parseInt(formData.diagnosis_code) !== 1013) {
        //   setPatientId(generateRandomID());
        // }
        //console.log(JSON.stringify(response.data));
        toast.success("Patient Registered Successfully.");
        navigate("/patients");
      })
      .catch(function (error) {
        setPatientId(generateRandomID());
        //handleFailure(JSON.stringify(error.response.data.patient));
        console.log(error);
        //console.log(error);
        errorLogApi(error, "PatientCreateChild", "/patient-add");
        toast.error(error.response.data.message);
        if (error?.response?.data?.message?.toLowerCase().includes("invalid")) {
          navigate("/login", { replace: true });
        }
        //console.log(error);
      });
  };

  const handleChange = ({ currentTarget: input }) => {
    // setFormData({
    //   ...formData,
    //   [input.name]: input.value,
    //   patientId,
    // });
    if (sanitizeInput(input.value, input.name)) {
      setFormData({
        ...formData,
        [input.name]: input.value,
        patientId,
      });
    }
  };

  const dropDownChange = (selected, dropdown) => {
    // console.log("this is selected", JSON.stringify(dropdown.name));
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
      //[dropdown.name]: JSON.stringify(selected),
      patientId,
    });
  };

  const adhaarValidator = ({ currentTarget: input }) => {
    if (!validator.isValidNumber(input.value)) {
      toast.error("Please input correct aadhar number");
    }
  };

  const handleSelect = (key) => {
    if (key === "general") {
      setTabState(0);
    }
    if (key === "clinical") {
      setTabState(1);
    }
    if (key === "vaccination") {
      setTabState(2);
    }
    if (key === "schedule") {
      setTabState(3);
    }
    // if (key === "contact") {
    //   setTabState(4);
    // }
  };

  return (
    <form>
      <h3>{selectedDiagnosis?.label}</h3>
      <div className="submitButton">
        {/* <h1 className="newPatientTitle">New Patient</h1> */}
        <div className="patientUpdateButton">
          <Button
            variant="success"
            size="medium"
            onClick={() => handlePatientRegistration(true)}
          >
            Save All
          </Button>
          &nbsp;
          <Button
            variant="primary"
            size="medium"
            onClick={() => handlePatientRegistration(false)}
          >
            Add Next Tab
          </Button>
        </div>
      </div>
      {/* {JSON.stringify(formData)} */}
      {/* {JSON.stringify(languageMultiSelect)} */}
      <Tabs
        defaultActiveKey={tabSequence[tabState]}
        activeKey={tabSequence[tabState]}
        id="uncontrolled-tab-example"
        className="mb-3"
        onSelect={handleSelect}
      >
        <Tab eventKey="general" title="General">
          <GeneralTab
            patientNameInput={patientNameInput}
            aadhaarNumberInput={aadhaarNumberInput}
            genderInput={genderInput}
            bloodGroupInput={bloodGroupInput}
            dobInput={dobInput}
            weightInput={weightInput}
            motherNameInput={motherNameInput}
            belongsToInput={belongsToInput}
            setLocalRegField={setLocalRegField}
            localRegField={localRegField}
            patientId={patientId}
            handleChange={handleChange}
            dropDownChange={dropDownChange}
            bloodGroupData={bloodGroupData}
            setSelectedCard={setSelectedCard}
            idCardType={idCardType}
            selectedCard={selectedCard}
            patientFamilyAnnualIncome={patientFamilyAnnualIncome}
            employmentType={employmentType}
            educationQualification={educationQualification}
            diagnosisData={diagnosisData}
            diagnosisTypeData={diagnosisTypeData}
            tcid={loggedInUserDetails?.tc_id}
            formData={formData}
            setFormData={setFormData}
            selectedDiagnosis={selectedDiagnosis}
            adhaarValidator={adhaarValidator}
            primaryMobileInput={primaryMobileInput}
            setStateValue={setStateValue}
            states={states}
            city={city}
            stateValue={stateValue}
            setCity={setCity}
            indianCities={indianCities}
            tc_state={user?.state}
          />
        </Tab>
        <Tab eventKey="clinical" title="Clinical Primary">
          <ClinicalTab
            handleChange={handleChange}
            dropDownChange={dropDownChange}
            precipitatingFactorData={precipitatingFactorData}
            selectedDiagnosis={selectedDiagnosis}
            formData={formData}
            setFormData={setFormData}
          />
        </Tab>
        <Tab eventKey="vaccination" title="Vaccinations">
          <VaccinationTab
            handleChange={handleChange}
            dropDownChange={dropDownChange}
            setFormData={setFormData}
            formData={formData}
          />
        </Tab>
        {/* <Tab eventKey="contact" title="Contact">
          <ContactTab
            primaryMobileInput={primaryMobileInput}
            handleChange={handleChange}
            dropDownChange={dropDownChange}
            setStateValue={setStateValue}
            states={states}
            city={city}
            stateValue={stateValue}
            setCity={setCity}
            indianCities={indianCities}
            setFormData={setFormData}
            formData={formData}
            tc_state={loggedInUserDetails.state}
          />
        </Tab> */}
        {/* <Tab eventKey="schedule" title="Scheduling">
          <SchedulingTab
            handleChange={handleChange}
            setFormData={setFormData}
            formData={formData}
          />
        </Tab> */}
      </Tabs>
    </form>
  );
};

export default PatientCreateChild;

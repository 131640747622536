import React from "react";

import MainContentCard from "../../Home/MainContentCard";
import SuppliersListChild from "./SuppliersListChild";

import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

const Suppliers = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="SuppliersListChild" route="/suppliers">
        <SuppliersListChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default Suppliers;

export const faqs = [
  {
    id: 1,
    question: "What is hemophilia?",
    answer:
      "Hemophilia is a rare bleeding disorder in which the blood does not clot properly.",
  },
  {
    id: 2,
    question: "What is thalassemia?",
    answer:
      "Thalassemia is a genetic blood disorder that causes the body to produce abnormal hemoglobin.",
  },
  {
    id: 4,
    question: "How can I register a patient with hemophilia or thalassemia?",
    answer:
      "To register a patient, click on the left menu on Patients>Add Patients.",
  },
  {
    id: 5,
    question: "Will the registered patient get a unique ID?",
    answer: "Yes, the registered patient will receive a unique ID.",
  },
  {
    id: 6,
    question:
      "What information is required to register a patient for the first time?",
    answer:
      "To register a patient for the first time, you need their name, age, Aadhar ID, phone number, and address.",
  },
  {
    id: 7,
    question:
      "Can I update the patient details after the initial registration?",
    answer:
      "Yes, you can update the patient details as per the information availability.",
  },
  {
    id: 8,
    question: "How can I view the details of a patient?",
    answer:
      "To view the patient details, click on the left menu on Patients>List Patients, search for the patient from the list, and click on the eye icon on the left of the record.",
  },
  {
    id: 9,
    question: "How can I create a visit for a patient?",
    answer:
      "To create a visit for a patient, first, create an appointment by going to Patients>List Patients, then from the list, click on the second icon from the left for the intended patient.",
  },
  {
    id: 10,
    question: "How can I create a visit for a patient?",
    answer:
      "To create a visit for a patient, go to Patients>List Patients, then from the list, click on the plus icon to create a visit.",
  },
];

import React from "react";
import Select from "react-select";

const SubchondralCystFormationXray = ({ titleText, formData, setFormData }) => {
  return (
    <table class="table table-striped table-responsive">
      {/* {JSON.stringify(formData.ankle_left_osteoporosis)} */}
      {/* {JSON.stringify(formData.ankle_right_osteoporosis)} */}
      <thead>
        <tr>
          <th scope="col">{titleText}</th>
          <th scope="col" className="text-center">
            Left
          </th>
          <th scope="col" className="text-center">
            Right
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="table-row-label">Ankle</td>
          <td>
            <div className="newPatientItem">
              <XrayDropDown
                fieldName="ankle_left_subchondral_cyst_formation"
                formData={formData}
                setFormData={setFormData}
              />
            </div>
          </td>
          <td>
            <div className="newPatientItem">
              <XrayDropDown
                fieldName="ankle_right_subchondral_cyst_formation"
                formData={formData}
                setFormData={setFormData}
              />
            </div>
          </td>
        </tr>
        <tr>
          <td className="table-row-label">Knee</td>
          <td>
            <div className="newPatientItem">
              <XrayDropDown
                fieldName="knee_left_subchondral_cyst_formation"
                formData={formData}
                setFormData={setFormData}
              />
            </div>
          </td>
          <td>
            <div className="newPatientItem">
              <XrayDropDown
                fieldName="knee_right_subchondral_cyst_formation"
                formData={formData}
                setFormData={setFormData}
              />
            </div>
          </td>
        </tr>
        <tr>
          <td className="table-row-label">Elbow</td>
          <td>
            <div className="newPatientItem">
              <XrayDropDown
                fieldName="elbow_left_subchondral_cyst_formation"
                formData={formData}
                setFormData={setFormData}
              />
            </div>
          </td>
          <td>
            <div className="newPatientItem">
              <XrayDropDown
                fieldName="elbow_right_subchondral_cyst_formation"
                formData={formData}
                setFormData={setFormData}
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default SubchondralCystFormationXray;

const XrayDropDown = ({ fieldName, formData, setFormData }) => {
  const dropDownOptions = [
    { value: 1, label: "Absent" },
    { value: 2, label: "1 Cyst" },
    { value: 3, label: ">1 Cyst" },
  ];

  return (
    <Select
      name={fieldName}
      onChange={(selected, dropdown) => {
        setFormData({
          ...formData,
          [fieldName]: selected.value,
        });
      }}
      value={
        formData[fieldName] &&
        dropDownOptions.find(
          (opt) => opt.value === parseInt(formData[fieldName])
        )
      }
      className="newPatientSelect"
      options={dropDownOptions}
      isDisabled={true}
    />
  );
};

import "./RolesList.css";
import React from "react";
import MainContentCard from "../../Home/MainContentCard";
import RolesListChild from "./RolesListChild";

import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

const RolesList = () => {
  return (
    <MainContentCard>
      {/* <SideBar /> */}
      <ErrorBoundaryRoutes route="/roles" page="RolesListChild">
        <RolesListChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default RolesList;

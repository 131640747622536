import React from "react";
import MainContentCard from "../Home/MainContentCard";
import EntryLogsChild from "./EntryLogsChild";

const EntryLogs = () => {
  return (
    <MainContentCard>
      <EntryLogsChild />
    </MainContentCard>
  );
};

export default EntryLogs;

import React, { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import styles from "./CreateUser.module.css";

import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";

//Context import
import AuthContext from "../../../components/shared/Context";

const CreateDoctorChild = () => {
  const { checkLoggedInStatus, states, loggedInUserDetails } =
    useContext(AuthContext);

  let navigate = useNavigate();
  //To check if the user is logged in
  useEffect(() => {
    checkLoggedInStatus();
  }, []);

  const [tcStates, setTcStates] = useState([]);

  const [rolesValue, setRolesValue] = useState([]);
  const [confirmPassword, setConfirmPassword] = useState();
  const [formData, setFormData] = useState({
    first_name: null,
    last_name: null,
    mobile: null,
    email: null,
    password: null,
    role: "TC_USER_ACCESS",
    user_type: "Doctor",
    tc_state: loggedInUserDetails.state,
    tc_district: loggedInUserDetails.district,
    tc_id: loggedInUserDetails.tc_id,
    account_status: true,
    createdBy: loggedInUserDetails._id,
  });

  useEffect(() => {
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/tc`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios(config)
      .then(function (response) {
        const fetchedTcData = response.data.treatment_center;
        const tcData = fetchedTcData.map((tc) => {
          return {
            id: tc._id,
            tc_name: tc.tcenters.name_tc,
            state: tc.tcenters.state,
            district: tc.tcenters.district,
          };
        });
        //console.log(JSON.stringify(tcData));
        setTcStates([...tcData]);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  //Load Roles on page load
  useEffect(() => {
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/roles`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios(config)
      .then(function (response) {
        const fetchedRolesData = response.data.roles;
        setRolesValue(fetchedRolesData);
        //console.log(JSON.stringify(fetchedRolesData));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  function generateRandomString() {
    // Define character sets
    const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercase = "abcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";
    const specials = "$@";

    // Helper function to get random character from a string
    const getRandomChar = (chars) => {
      return chars[Math.floor(Math.random() * chars.length)];
    };

    // Gather two characters from each required set
    let result = "";
    for (let i = 0; i < 2; i++) {
      result += getRandomChar(uppercase);
      result += getRandomChar(lowercase);
      result += getRandomChar(numbers);
      result += getRandomChar(specials);
    }

    // Shuffle the result to mix the characters
    result = result
      .split("")
      .sort(() => 0.5 - Math.random())
      .join("");

    return result;
  }

  const handleUserRegistration = (e) => {
    e.preventDefault();

    // if (formData.password !== confirmPassword) {
    //   toast.error("Password not matching!");
    //   return;
    // }

    const random_pass = generateRandomString();

    confirmAlert({
      title: "",
      message: "Are you sure to Add Doctor ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => addDoctor(),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });

    const addDoctor = () => {
      let userToken = localStorage.getItem("token");
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/user/register`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        data: { ...formData, password: random_pass },
      };
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          toast.success("User Registered Successfully.");
          navigate("/doctors");
        })
        .catch(function (error) {
          //  toast.error(JSON.stringify(error.response.data.message));
          toast.error(JSON.stringify(error.response.data));
          console.log(error);
        });
    };
  };

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  return (
    <div>
      <div>
        <h2>Add Doctor</h2>
      </div>
      {/* {JSON.stringify({ ...formData })} */}
      {/* {JSON.stringify(loggedInUserDetails)} */}
      <form onSubmit={(e) => handleUserRegistration(e)} autoComplete="off">
        <div className="patientUpdateButton">
          <Button variant="primary" size="medium" type="submit">
            Save Doctor
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="secondary"
            size="medium"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
        <div className={styles.newFormUsersOnly}>
          <div className="newPatientItem w-25">
            <label htmlFor="first_name">First Name</label>
            <input
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="last_name">Last Name</label>
            <input
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="mobile">Mobile</label>
            <input
              type="text"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              autoComplete="new-password"
              required
            />
          </div>

          {/* <div className="newPatientItem w-25">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              autoComplete="new-password"
              required
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="password">Confirm Password</label>
            <input
              type="password"
              name="confirm_password"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              autoComplete="new-password"
              required
            />
          </div> */}
        </div>
      </form>
    </div>
  );
};

export default CreateDoctorChild;

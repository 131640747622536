export const doseData = [
  {
    name: "Jan",
    dosages: 4000,
  },
  {
    name: "Feb",
    dosages: 3000,
  },
  {
    name: "Mar",
    dosages: 2000,
  },
  {
    name: "Apr",
    dosages: 2780,
  },
  {
    name: "May",
    dosages: 1890,
  },
  {
    name: "Jun",
    dosages: 2390,
  },
  {
    name: "Jul",
    dosages: 3490,
  },
];

export const imgLink =
  "https://images.pexels.com/photos/2613260/pexels-photo-2613260.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";

export const bloodGroupData = [
  {
    id: 1,
    bloodGroupType: "A +ve",
  },
  {
    id: 2,
    bloodGroupType: "A -ve",
  },
  {
    id: 3,
    bloodGroupType: "B +ve",
  },
  {
    id: 4,
    bloodGroupType: "B -ve",
  },
  {
    id: 5,
    bloodGroupType: "O +ve",
  },
  {
    id: 6,
    bloodGroupType: "O -ve",
  },
  {
    id: 7,
    bloodGroupType: "AB +ve",
  },
  {
    id: 8,
    bloodGroupType: "AB -ve",
  },
];

export const idCardType = [
  {
    id: 1,
    cardType: "Aadhar Card",
  },
  {
    id: 2,
    cardType: "Driving License",
  },
  {
    id: 3,
    cardType: "Guardian's ID",
  },
  {
    id: 4,
    cardType: "Hemophilia Card",
  },
  {
    id: 5,
    cardType: "PAN Card",
  },
  {
    id: 6,
    cardType: "Passport",
  },
  {
    id: 7,
    cardType: "Ration Card",
  },
  {
    id: 8,
    cardType: "Voter Card",
  },
];

export const patientFamilyAnnualIncome = [
  {
    id: 1,
    incomeType: "Priority Household (PHH)",
  },
  {
    id: 2,
    incomeType: "Antyodaya Anna Yojana (AAY)",
  },
  {
    id: 3,
    incomeType: "APL (Above Poverty Line)",
  },
  {
    id: 4,
    incomeType: "BPL (Below Poverty Line)",
  },
  {
    id: 4,
    incomeType: "Annapoorna Yojana (AY)",
  },
];

export const employmentType = [
  {
    id: 1,
    eType: "Unemployed",
  },
  {
    id: 2,
    eType: "Government",
  },
  {
    id: 3,
    eType: "Private",
  },
];

export const educationQualification = [
  {
    id: 1,
    eqType: "illiterate",
  },
  {
    id: 2,
    eqType: "Upto Primary",
  },
  {
    id: 3,
    eqType: "Middle School",
  },
  {
    id: 4,
    eqType: "Sr. Secondary",
  },
  {
    id: 5,
    eqType: "University Graduate",
  },
  {
    id: 6,
    eqType: "Post Graduate",
  },
  {
    id: 7,
    eqType: "Post Doctoral",
  },
  {
    id: 8,
    eqType: "Professional",
  },
  {
    id: 9,
    eqType: "Others",
  },
];

export const diagnosisData = [
  {
    id: 1,
    disease: "Hemophilia A",
  },
  {
    id: 2,
    disease: "Hemophilia B",
  },
  {
    id: 3,
    disease: "Inhibitors",
  },
  {
    id: 4,
    disease: "vWF",
  },
];

export const diagnosisTypeData = {
  hemophilia: [
    {
      id: 1,
      severity_of_illness: "Mild (5-40%)",
    },
    {
      id: 2,
      severity_of_illness: "Moderate (1-5%)",
    },
    {
      id: 3,
      severity_of_illness: "Severe (less than 1%)",
    },
  ],
  vwd: [
    {
      id: 4,
      severity_of_illness: "Type I",
    },
    {
      id: 5,
      severity_of_illness: "Type II",
    },
    {
      id: 6,
      severity_of_illness: "Type III",
    },
  ],
};

export const precipitatingFactorData = [
  {
    id: 1,
    pfactor: "Spontaneous",
  },
  {
    id: 2,
    pfactor: "Minor Trauma",
  },
  {
    id: 3,
    pfactor: "Major Trauma",
  },
  {
    id: 4,
    pfactor: "Surgical Operation",
  },
  {
    id: 5,
    pfactor: "Intervention",
  },
  {
    id: 6,
    pfactor: "Dental Procedures",
  },
  {
    id: 7,
    pfactor: "Others",
  },
];

export const vReasons = {
  hemophilia: [
    {
      id: 1,
      reason: "Bleeding Episode",
      subreasons: [],
    },
    {
      id: 2,
      reason: "Consultation",
      subreasons: [],
    },
    {
      id: 3,
      reason: "Treatment",
      subreasons: [
        {
          value: 1,
          label: "Prophylaxis",
        },
        {
          value: 2,
          label: "On-Demand",
        },
        {
          value: 3,
          label: "Trauma - Hospitalization",
        },
        {
          value: 4,
          label: "Surgery - Hospitalization",
        },
      ],
    },
    {
      id: 4,
      reason: "Physiotherapy",
      subreasons: [],
    },
    {
      id: 5,
      reason: "Counselling",
      subreasons: [],
    },
    {
      id: 6,
      reason: "Diagnostics",
      subreasons: [],
    },
  ],
  thalassemia: [
    {
      id: 2,
      reason: "Consultation",
      subreasons: [],
    },
    {
      id: 3,
      reason: "Treatment",
      subreasons: [
        {
          value: 1,
          label: "Chelation",
        },
        {
          value: 2,
          label: "Blood Transfusion",
        },
        {
          value: 3,
          label: "Diagnostics",
        },
      ],
    },
    {
      id: 4,
      reason: "Physiotherapy",
      subreasons: [],
    },
    {
      id: 5,
      reason: "Counselling",
      subreasons: [],
    },
    {
      id: 6,
      reason: "Diagnostics",
      subreasons: [],
    },
  ],
};

export const visitReasons = [
  {
    id: 1,
    reason: "Bleeding Episode",
  },
  {
    id: 2,
    reason: "Consultation",
  },
  {
    id: 3,
    reason: "Treatment",
  },
  {
    id: 4,
    reason: "Physiotherapy",
  },
  {
    id: 5,
    reason: "Counselling",
  },
  {
    id: 6,
    reason: "Diagnostics",
  },
];

export const visitReasonsThalassemia = [
  {
    id: 2,
    reason: "Consultation",
  },
  {
    id: 3,
    reason: "Treatment",
  },
  {
    id: 4,
    reason: "Physiotherapy",
  },
  {
    id: 5,
    reason: "Counselling",
  },
  {
    id: 6,
    reason: "Diagnostics",
  },
];

export const visitTypes = [
  {
    value: 1,
    label: "Prophylaxis",
  },
  {
    value: 2,
    label: "On-Demand",
  },
  {
    value: 3,
    label: "Trauma - Hospitalization",
  },
  {
    value: 4,
    label: "Surgery - Hospitalization",
  },
];

export const visitTypesHemophilia = [
  {
    value: 1,
    label: "Prophylaxis",
  },
  {
    value: 2,
    label: "On-Demand",
  },
  {
    value: 3,
    label: "Trauma - Hospitalization",
  },
  {
    value: 4,
    label: "Surgery - Hospitalization",
  },
];

export const visitTypesThalassemia = [
  {
    value: 1,
    label: "Chelation",
  },
  {
    value: 2,
    label: "Blood Transfusion",
  },
  {
    value: 3,
    label: "Diagnostics",
  },
];

export const doctors = [
  {
    id: 1,
    name: "DR VARUN",
    doctor_id: "7cde63d62ce17c18d09b099a",
  },
  {
    id: 2,
    name: "DR TARUN",
    doctor_id: "3d627cde6ce17c18d09b099a",
  },
];

export const death_causes = {
  hemophilia: [
    {
      value: 1,
      label:
        "Bleeding episodes (spontaneous or trauma-induced affecting joints, muscles, or vital organs)",
    },
    { value: 2, label: "Intracranial hemorrhage" },
    { value: 3, label: "Hemorrhage from a surgical procedure" },
    {
      value: 4,
      label:
        "Infection (e.g., HIV, hepatitis from contaminated blood products)",
    },
    {
      value: 5,
      label: "Development of inhibitors against clotting factor therapies",
    },
  ],
  thalassemia: [
    {
      value: 1,
      label:
        "Cardiac complications (e.g., heart failure, arrhythmias due to iron overload)",
    },
    {
      value: 2,
      label: "Liver disease (from iron overload or infections like hepatitis)",
    },
    { value: 3, label: "Infections (due to spleen removal or dysfunction)" },
    {
      value: 4,
      label:
        "Endocrine complications (e.g., diabetes mellitus, hypothyroidism due to iron overload)",
    },
    {
      value: 5,
      label:
        "Complications from bone marrow transplantation (e.g., graft-versus-host disease)",
    },
  ],
};

export const hemophilic = [
  "Hemophilia A",
  "Hemophilia B",
  "Hemophilia A with Inhibitors",
  "Hemophilia B with Inhibitors",
  "Von Willebrand Disease (vWd)",
  "Factor VII Difficiency",
  "Hemophilia C",
  "Acquired Hemophilia",
];

export const thalassemic = [
  "Alpha Thalassemia",
  "E-Beta Thalassemia",
  "HbE Thalassemia",
  "HbD Thalassemia",
  "Sickle Beta Thalassemia",
  "Thalassemia Intermedia",
  "Thalassemia Major",
  "Thalassemia Minor (Carrier)",
];

export const badgeAliveStatus = {
  0: { badge: "danger", label: "DECEASED" },
  1: { badge: "success", label: "ALIVE" },
};

export const permissionOptions = [
  { value: "FULL_ACCESS", label: "Full Access" },
  { value: "ST_ADM_ACCESS", label: "State Level Access" },
  { value: "TC_ADM_ACCESS", label: "TC Admin Level Access " },
  { value: "TC_USER_ACCESS", label: "TC User Level Access" },
  { value: "REPORT_ACCESS", label: "Report Level Access" },
];

// export const visitReasons = [
//   {
//     id: 1,
//     reason: "Acute Bleed",
//   },
//   {
//     id: 2,
//     reason: "Infection",
//   },
//   {
//     id: 3,
//     reason: "Surgery",
//   },
//   {
//     id: 4,
//     reason: "Sequelae related to Hemophilia",
//   },
//   {
//     id: 5,
//     reason: "Regular Follow Up",
//   },
//   {
//     id: 6,
//     reason: "Counselling",
//   },
//   {
//     id: 7,
//     reason: "Registration",
//   },
//   {
//     id: 8,
//     reason: "Any Other",
//   },
// ];

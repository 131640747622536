import React from "react";

import MainContentCard from "../../Home/MainContentCard";
import AddSupplierChild from "./AddSupplierChild";

import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

const AddSupplier = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="AddSupplier" route="/supplier-add">
        <AddSupplierChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default AddSupplier;

import React from "react";
import Card from "react-bootstrap/Card";

const AddressDetailsCard = ({ currentRecord }) => {
  return (
    <Card>
      <Card.Header>
        <i className="fas fa-align-justify" />
        <strong>Patient Address Details</strong>
      </Card.Header>
      <Card.Body>
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            {" "}
            <b> {"residential_address".replaceAll("_", " ").toUpperCase()}</b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {currentRecord?.residential_address?.address}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            {" "}
            <b> {"landmark".replaceAll("_", " ").toUpperCase()}</b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {currentRecord?.residential_address?.landmark}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            {" "}
            <b> {"state".replaceAll("_", " ").toUpperCase()}</b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {currentRecord?.residential_address?.state}
          </div>
        </div>
        {currentRecord?.residential_address?.district && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b> {"district".replaceAll("_", " ").toUpperCase()}</b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {JSON.parse(currentRecord?.residential_address?.district)?.label}
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            {" "}
            <b> {"pincode".replaceAll("_", " ").toUpperCase()}</b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {currentRecord?.residential_address?.pincode}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default AddressDetailsCard;

import React from "react";
import { Modal } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { Card } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import axios from "axios";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

//Context import
import AuthContext from "../../components/shared/Context";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";

const ActivityLogChild = () => {
  const { checkLoggedInStatus, loggedInUserDetails } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  let navigate = useNavigate();

  const getDataFromServer = () => {
    setLoading(true);
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/alog/`;

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.audit_logs));
        const oldData = response.data.audit_logs;
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data?._id,
            user: data?.user_id?.first_name + " " + data?.user_id?.last_name,
            access_level: data?.user_id?.user_type,
            activity: data?.activity,
          };
        });
        // console.log(updatedData);
        setData(updatedData);
      })
      .catch(function (err) {
        // console.log(error.message);
        console.log("error api call ", err.response.data.message);
        if (err.response.data.message) {
          if (err.response.data.message.toLowerCase().includes("invalid")) {
            navigate("/login");
          }
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 3000); // 3 seconds delay
      });
  };

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/alog`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //  console.log(JSON.stringify(response.data.audit_logs));
          const oldData = response.data.audit_logs;
          const updatedData = oldData.map((data) => {
            return {
              ...data,
              id: data?._id,
              user: data?.user_id?.first_name + " " + data?.user_id?.last_name,
              access_level: data?.user_id?.user_type,
              activity: data?.activity,
            };
          });
          //  console.log(updatedData);
          setData(updatedData);
        })
        .catch(function (err) {
          console.log(err);
          //console.log("error api call ", err.response.data.message);
          if (err?.response?.data?.message) {
            if (
              err?.response?.data?.message.toLowerCase().includes("invalid")
            ) {
              navigate("/login");
            }
          }
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 3000); // 3 seconds delay
        });
    };
    getDataFromServer();
  }, []);

  const badgeColorClass = {
    login: "success",
    delete: "danger",
    update: "warning",
    create: "primary",
    failed: "danger",
  };

  const columns = [
    {
      field: "Action",
      headerName: "Activity",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 120,
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            <Tooltip title="View Error">
              <a onClick={() => viewRecord(params.row)}>
                {/* <button className='patientListEdit'>Edit</button> */}
                <i
                  className="mdi mdi-eye-circle-outline"
                  style={{
                    fontSize: "15px",
                    color: "black",
                    cursor: "pointer",
                  }}
                ></i>
              </a>
            </Tooltip>
            &nbsp;&nbsp;
            <div>
              <Badge
                bg={
                  params?.row?.activity
                    ? badgeColorClass[`${params?.row?.activity?.toLowerCase()}`]
                    : "success"
                }
              >
                {params?.row?.activity?.toUpperCase()}
              </Badge>
            </div>
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 180,
    },
    {
      field: "page",
      headerName: "Component",
      width: 180,
    },
    {
      field: "page_route",
      headerName: "Route",
      width: 160,
    },
    {
      field: "user",
      headerName: "User",
      width: 160,
    },
    {
      field: "access_level",
      headerName: "Access Level",
      width: 160,
    },
  ];

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
    // setMarkAsResolved(false);
  };

  const unfilterData = data;
  const handleSearch = (e) => {
    // console.log("Search Value...", e);

    if (e) {
      setData(
        unfilterData.filter((item) => {
          return (
            item.activity?.toLowerCase().includes(e.toLowerCase()) ||
            item.user?.toLowerCase().includes(e.toLowerCase()) ||
            item.access_level?.toLowerCase().includes(e.toLowerCase())
          );
        })
      );
    } else {
      getDataFromServer();
    }
  };

  // if (loading) {
  //   return <LoadingComponent />;
  // }

  return (
    <div>
      <div>
        <h2>Audit Logs</h2>
        <div className="patientListSearch">
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">
              Search Activity / User
            </InputGroup.Text>
            <Form.Control
              placeholder=""
              aria-label="Search"
              aria-describedby="basic-addon1"
              name="searchPatients"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </InputGroup>
        </div>
      </div>{" "}
      {loading ? (
        <LoadingComponent />
      ) : (
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Blood Products"
          // checkboxSelection
          style={{ height: "60vh" }}
        />
      )}
      {/* Modal Popup */}
      <Modal show={openModal} size="lg" centered>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent
            currentRecord={currentRecord}
            badgeColorClass={badgeColorClass}
          />
        </Modal.Body>
      </Modal>
      {/* {JSON.stringify(data)} */}
    </div>
  );
};

const ModalContent = ({ currentRecord, badgeColorClass }) => {
  return (
    <div className="container-fluid legend">
      <Card>
        <Card.Header>
          <i className="fas fa-align-justify" />
          <strong>Log Details</strong>{" "}
        </Card.Header>
        <Card.Body>
          <CardTableRow rowName="Component" rowValue={currentRecord?.page} />
          <CardTableRow rowName="Route" rowValue={currentRecord?.page_route} />
          <CardTableRow
            rowName="Date Time"
            rowValue={currentRecord?.createdAt}
          />
          <CardTableRow
            rowName="IP Info."
            rowValue={currentRecord?.ip_information}
          />
          <CardTableRow
            rowName="Activity"
            rowValue={
              <Badge
                bg={
                  currentRecord?.activity
                    ? badgeColorClass[
                        `${currentRecord?.activity?.toLowerCase()}`
                      ]
                    : "success"
                }
              >
                {currentRecord?.activity?.toUpperCase()}
              </Badge>
            }
          />
          <div className="row">
            <div className="col-md-12 col-xs-12 divTableCell">
              <b> Log Details: </b>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12 col-xs-12 divTableCell"
              style={{
                flex: 1,
                flexWrap: "wrap",
                flexShrink: 1,
                wordBreak: "break-word",
              }}
            >
              {currentRecord?.log_detail
                ? currentRecord?.log_detail
                : currentRecord?.data}
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <i className="fas fa-align-justify" />
          <strong>User Details</strong>
          {/* {JSON.stringify(currentRecord?.user_id)} */}
        </Card.Header>
        <Card.Body>
          <CardTableRow rowName="User" rowValue={currentRecord?.user} />
          <CardTableRow rowName="Role" rowValue={currentRecord?.access_level} />

          <CardTableRow
            rowName="Mobile"
            rowValue={currentRecord?.user_id?.mobile}
          />
          <CardTableRow
            rowName="Email"
            rowValue={currentRecord?.user_id?.email}
          />
          <CardTableRow
            rowName="State"
            rowValue={currentRecord?.user_id?.tc_state}
          />
          <CardTableRow
            rowName="District"
            rowValue={currentRecord?.user_id?.tc_district}
          />
          <CardTableRow rowName="TC" rowValue={currentRecord?.user_id?.tc_id} />
          {/* {JSON.stringify(currentRecord?.user_id)} */}
        </Card.Body>
      </Card>
    </div>
  );
};

const CardTableRow = ({ rowName, rowValue }) => {
  return (
    <div className="row">
      <div
        className="col-md-6 col-xs-6 divTableCell"
        style={{ wordBreak: "break-word" }}
      >
        <b> {rowName}: </b>
      </div>
      <div
        className="col-md-6 col-xs-6 divTableCell"
        style={{ wordBreak: "break-word" }}
      >
        {rowValue}
      </div>
    </div>
  );
};

export default ActivityLogChild;

import React from "react";
import { Button } from "react-bootstrap";
import { CSVLink } from "react-csv";

const CsvButton = ({csvData,fileName,csvDownloadRef,isIcon}) => {
  return (
    <div>

        <CSVLink 
            data={csvData}
            style={{ fontSize: "15px", color: "white", cursor: "pointer" }}
            filename={fileName}
            ref={csvDownloadRef}
            target="_blank"
            >
                {(!isIcon) 
                    ? <Button
                        variant="primary"
                        size="medium"
                    >
                        <i className="mdi mdi-file-excel"></i>Export
                    </Button>
                    : ''
                }             
        </CSVLink>
    </div>
  );
};

export default CsvButton;

import React from "react";
import axios from "axios";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    //console.log(error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //console.log(error, errorInfo);
    if (localStorage.getItem("token")) {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/elog/add`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {
          error_log: JSON.stringify(errorInfo),
          page: this.props.page,
          route: this.props.route,
        },
      };

      axios(config)
        .then(function (response) {
          console.log("This is executed");
        })
        .catch(function (error) {});
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return;
      <>
        <h1>Something went Wrong</h1>
        this.props.fallback
      </>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

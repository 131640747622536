import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid, useDataGridComponent } from "@material-ui/data-grid";
import { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";

//Jotai
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

//Context import
import AuthContext from "../../../components/shared/Context";

const ProductList = () => {
  const { loggedInUserDetails } = useContext(AuthContext);
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  const searchRef = useRef();
  //Error Logging Service & Santize Input
  const [{ errorLogApi, sanitizeInput }] = useAtom(gbState);

  const handleDelete = (id) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this Product ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (id) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(`${process.env.REACT_APP_API_URL}/product/delete/${id}`, config)
      .then((response) => {
        // alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };
  //const pre_id = `${loggedInUserDetails.state.substring(0, 2).toUpperCase()}`;
  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let product_url = `${process.env.REACT_APP_API_URL}/product/${loggedInUserDetails.state}`;

    if (loggedInUserDetails.role === "FULL_ACCESS") {
      product_url = `${process.env.REACT_APP_API_URL}/product/`;
    }

    const config = {
      method: "GET",
      url: product_url,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data.product));
        const oldData = response.data.product;
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data?._id,
            category_id: data?.products?.category_id?._id,
            category: data?.products?.category_id?.category,
            sub_category: data?.products?.sub_category,
            s_category: displayLabels(data?.products?.sub_category),
            product_code: data?.products?.item_code,
            product_name: data?.products?.product_name,
            measuring_unit: data?.products?.measuring_unit,
            storage_unit: data?.products?.storage_unit,
            minimum_alert_qty: data?.products?.minimum_alert_qty,
            company_name: data?.products?.company_name,
            state: data?.products?.state,
          };
        });
        //console.log(updatedData);
        setData(updatedData);
        setUnfilteredData(updatedData);
      })
      .catch(function (error) {
        errorLogApi(error, "ProductList", "/products");

        console.log(error);
      });
  };

  useEffect(() => {
    getDataFromServer();
  }, []);

  const userRolesForMAQ = ["FULL_ACCESS", "ST_ADM_ACCESS"];

  const displayLabels = (jsonData) => {
    if (jsonData) {
      let jsonDataparsed = JSON.parse(jsonData);

      // Map over the jsonData to extract only the label of each item
      const labels = jsonDataparsed?.map((item) => item?.label);

      // Join all labels with a comma and a space
      return labels.join(", ");
    }
  };

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            <Tooltip title="View Product">
              <a onClick={() => viewRecord(params.row)}>
                <i
                  className="mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </a>
            </Tooltip>
            <Tooltip title="Edit Product">
              <div
                onClick={() => {
                  if (loggedInUserDetails.role === "ST_ADM_ACCESS")
                    navigate("/updateproduct", { state: params.row });
                  else toast.error("You are not Authorized");
                }}
              >
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete Product">
              <div onClick={() => handleDelete(params.row._id)}>
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "product_code",
      headerName: "Product Code",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 120,
    },
    {
      field: "product_name",
      headerName: "Product Name",
      width: 260,
      renderCell: (params) => {
        const charsPerLine = 50; // adjust this according to your requirement
        let width = params?.row?.product_name.length * 8 + "px";

        if (width > "400px") {
          width = "400px"; // set a maximum width
        }

        return (
          <div
            className="text-bold text-break"
            style={{
              whiteSpace: "normal",
              wordBreak: "break-all",
              lineHeight: "1.2em", // Adjust this value as needed
            }}
          >
            {params?.row?.product_name}
          </div>
        );
      },
    },
    {
      field: "category",
      headerName: "Category",
      width: 140,
    },
    {
      field: "s_category",
      headerName: "Sub Category",
      width: 140,
      renderCell: (params) => {
        return params?.row?.s_category;
      },
    },
    {
      field: "measuring_unit",
      headerName: "Dosing Units",
      width: 130,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
    },
    {
      field: "storage_unit",
      headerName: "Storage Units",
      width: 130,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
    },
    {
      field: "minimum_alert_qty",
      headerName: "MAQ",
      width: 100,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
    },
    {
      field: "company_name",
      headerName: "Company Name",
      width: 180,
      align: "center",
    },
    {
      field: "state",
      headerName: "State",
      width: 100,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };

  const handleSearch = (e) => {
    console.log("Search Value...", e);

    if (e) {
      setData(
        unfilteredData.filter((item) => {
          return (
            item?.product_code?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.product_name?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.company_name?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.state?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.measuring_unit?.toLowerCase().includes(e?.toLowerCase())
          );
        })
      );
    } else {
      getDataFromServer();
    }
  };

  const clearSearch = () => {
    searchRef.current.value = null;
    getDataFromServer();
  };

  return (
    <>
      <div className="patientListSearch">
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">Filter</InputGroup.Text>
          <Form.Control
            placeholder="Product / State / Code / Company ..."
            aria-label="Search"
            aria-describedby="basic-addon1"
            name="searchPatients"
            ref={searchRef}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <InputGroup.Text>
            <i
              className="mdi mdi-delete-outline"
              onClick={clearSearch}
              style={{ cursor: "pointer" }}
            ></i>
          </InputGroup.Text>
        </InputGroup>
      </div>
      <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        displayTitle="Products"
        checkboxSelection
        style={{ height: "60vh" }}
      />

      {/* Modal Popup */}
      <Modal show={openModal} centered>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Product Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent currentRecord={currentRecord} />
        </Modal.Body>
      </Modal>
    </>
  );
};

const ModalContent = ({ currentRecord }) => {
  const displayLabels = (jsonData) => {
    let jsonDataparsed = JSON?.parse(jsonData);
    // Map over the jsonData to extract only the label of each item
    const labels = jsonDataparsed?.map((item) => item?.label);

    // Join all labels with a comma and a space
    return labels.join(", ");
  };
  return (
    <div className="container-fluid">
      <table class="table table-striped">
        <tbody>
          <tr>
            <th scope="row">Product Name</th>
            <td>{currentRecord?.product_name}</td>
          </tr>
          <tr>
            <th scope="row">Product Category</th>
            <td>{currentRecord?.category}</td>
          </tr>
          <tr>
            <th scope="row">Product sub Category</th>
            <td>
              {currentRecord?.sub_category &&
                displayLabels(currentRecord?.sub_category)}
            </td>
          </tr>
          <tr>
            <th scope="row">Measuring Unit</th>
            <td>{currentRecord?.measuring_unit}</td>
          </tr>
          <tr>
            <th scope="row">Storage Unit</th>
            <td>{currentRecord?.storage_unit}</td>
          </tr>
          <tr>
            <th scope="row">Minimum Qty (Inventory Alert)</th>
            <td>{currentRecord?.minimum_alert_qty}</td>
          </tr>
          <tr>
            <th scope="row">Company Name</th>
            <td>{currentRecord?.company_name}</td>
          </tr>
          <tr>
            <th scope="row">Contact Number</th>
            <td>{currentRecord?.contact_number}</td>
          </tr>
          <tr>
            <th scope="row">Email</th>
            <td>{currentRecord?.company_email}</td>
          </tr>
          <tr>
            <th scope="row">Comments</th>
            <td>{currentRecord?.comments}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ProductList;

import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import "./LoadingComponent.css"; // Import custom styles

const LoadingComponent = () => {
  const [loading, setLoading] = useState(true);
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      setCompleted(true);
    }, 5000); // Simulate a 5-second processing time

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="container-fluid">
      {loading && (
        <div className="spinner-container">
          <Spinner animation="border" variant="primary" />
          <div className="loading-text">Processing Data...</div>
        </div>
      )}
      {completed && (
        <div className="completed-container">
          {/* <div className="checkmark-circle">
            <div className="checkmark"></div>
          </div> */}
          <i
            className="mdi mdi-check-circle"
            style={{
              fontSize: "2.9rem",
              color: "green",
            }}
          ></i>

          <div className="completed-text">Completed</div>
        </div>
      )}
    </div>
  );
};

export default LoadingComponent;

import React, { useEffect, useState, useContext, useRef } from "react";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Card from "react-bootstrap/Card";
import { confirmAlert } from "react-confirm-alert";
import MainContent from "../../Home/MainContent";
import "antd/dist/antd.min.css";
import { Modal } from "react-bootstrap";
import { DataGrid } from "@material-ui/data-grid";
import toast from "react-hot-toast";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker, Space } from "antd";
import { Button } from "react-bootstrap";
//Context import
import AuthContext from "../../../components/shared/Context";
import ExcelDownloader from "./ExcelDownloader";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const { RangePicker } = DatePicker;
const ListPurchases = () => {
  let navigate = useNavigate();
  const { loggedInUserDetails } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const searchRef = useRef();

  //Date Range Search
  const [durationResult, setDurationResult] = useState([]);
  //date range selector code
  const [dates, setDates] = useState(null);
  const [dateValue, setDateValue] = useState(null);
  const [dateInterval, setDateInterval] = useState({});
  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], "days") > 365;
    const tooEarly = dates[1] && dates[1].diff(current, "days") > 365;
    return !!tooEarly || !!tooLate;
  };
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const searchRecordsBetweenDates = (field) => {
    if (dateInterval) {
      //alert("started");
      const data_res = data.filter((d) => {
        var time = new Date(d[field]).getTime();
        return dateInterval.sd <= time && time <= dateInterval.ed;
      });
      setDurationResult(data_res);
      setData(data_res);
    }
  };

  useEffect(() => {
    if (dateValue) {
      setDateInterval({
        sd: new Date(dateValue[0]).getTime(),
        ed: new Date(dateValue[1]).getTime(),
      });
    }
  }, [dateValue]);

  const getDataFromServer = () => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    const bodyParameters = {
      key: "value",
    };
    let urlLink = `${process.env.REACT_APP_API_URL}/state/purchases/${loggedInUserDetails.state}`;
    if (loggedInUserDetails.role === "FULL_ACCESS") {
      urlLink = `${process.env.REACT_APP_API_URL}/state/purchases/`;
    }
    // if (loggedInUserDetails.role === "ST_ADM_ACCESS") {
    //   urlLink = `${process.env.REACT_APP_API_URL}/suppliers/state/${loggedInUserDetails.state}`;
    // }
    axios
      .get(urlLink, config)
      .then((res) => {
        const oldData = res.data.purchases;
        // console.log(oldData);
        const updatedData = oldData.map((data) => {
          //   console.log(data.purchase_inventory.product_id.products);
          return {
            ...data,
            id: data._id,
            product_code: data?.p_register?.product_id?.products?.item_code,
            product_name: data?.p_register?.product_id?.products?.product_name,
            measuring_unit:
              data?.p_register?.product_id?.products?.measuring_unit,
            company_name: data?.p_register?.product_id?.products?.company_name,
            batch_id: data?.p_register?.product_id?.products?.batch_id,
            shipment_id: data?.p_register?.shipment_id,
            unit_price: data?.p_register?.unit_price,
            po_number: data?.p_register?.po_number,
            state: data?.p_register?.state,
            quantity: data?.p_register?.quantity,
            product_sku: data?.p_register?.product_sku,
            supplier_name: data?.p_register?.supplier_id?.supplier_name,
            supplier_phone: data?.p_register?.supplier_id?.phone,
            supplier_email: data?.p_register?.supplier_id?.email,
            po_date: data?.p_register?.po_date.slice(0, 10),
            product_expiry: data?.p_register?.expiry_date.slice(0, 10),
            purchaser_name:
              data?.p_register?.purchaser_id?.first_name +
              " " +
              data?.p_register?.purchaser_id?.last_name,
            comments: data?.p_register?.comments,
          };
        });
        //console.log(updatedData);
        setData(updatedData);
        setUnfilteredData(updatedData);
      })
      .catch((err) => console.log("error", err));
  };

  useEffect(() => {
    getDataFromServer();
  }, []);

  const handleDelete = (id) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this Product ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (id) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/state/purchases/delete/${id}`,
        config
      )
      .then((response) => {
        getDataFromServer();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const useStyles = makeStyles(() => ({
    multiLineCell: {
      display: "flex",
      wordBreak: "keep-all",
      wordWrap: "break-word",
      overflowWrap: "break-word",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      lineHeight: "1.2rem",
      height: "auto",
    },
  }));

  const calculateDaysLeft = (date) => {
    // Convert the given date string to a Date object
    const givenDate = new Date(date);

    // Calculate the difference between the current date and the given date in milliseconds
    const timeDiff = givenDate.getTime() - new Date().getTime();

    // Convert milliseconds to days and round down to the nearest integer
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    // Update the state with the number of days left
    //setDaysLeft(days);
    return days;
  };

  const MultiLineCellRenderer = (params) => {
    const classes = useStyles();
    const daysLeft = calculateDaysLeft(params.row.product_expiry);
    return (
      <div className={classes.multiLineCell}>
        <span id="content1" className="text-bold">
          {params.row.product_name}
        </span>
        <span id="content2">
          <small className="small-font-size">
            Expiry: {daysLeft !== null ? `${daysLeft} days` : <p>Loading...</p>}
          </small>
        </span>
      </div>
    );
  };

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            <Tooltip title="View PO">
              <a onClick={() => viewRecord(params.row)}>
                <i
                  className="mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </a>
            </Tooltip>
            <Tooltip title="Edit PO">
              <div onClick={() => navigate("/updatepo", { state: params.row })}>
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete PO">
              <div onClick={() => handleDelete(params.row._id)}>
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "product_code",
      headerName: "Product ID",
      filterable: false,
      disableColumnMenu: true,
      width: 130,
      align: "center",
    },
    {
      field: "po_date",
      headerName: "PO Date",
      filterable: false,
      disableColumnMenu: true,
      width: 110,
      align: "center",
    },
    {
      field: "product_name",
      headerName: "Product",
      width: 240,
      renderCell: MultiLineCellRenderer,
    },
    {
      field: "quantity",
      headerName: "P.Qty",
      filterable: false,
      disableColumnMenu: true,
      width: 100,
      align: "center",
    },
    {
      field: "product_expiry",
      headerName: "Expiry",
      width: 150,
    },
    {
      field: "company_name",
      headerName: "Company",
      width: 140,
    },
    {
      field: "po_number",
      headerName: "PO Number",
      width: 160,
    },

    {
      field: "state",
      headerName: "State",
      width: 110,
    },
  ];

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };
  //   console.log(data);

  const handleSearch = (e) => {
    if (e) {
      setData(
        unfilteredData.filter((item) => {
          return (
            item?.product_code?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.product_name?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.company_name?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.po_number?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.state?.toLowerCase().includes(e?.toLowerCase())
          );
        })
      );
    } else {
      setData(unfilteredData);
      getDataFromServer();
    }
  };

  const clearSearch = () => {
    searchRef.current.value = null;
    getDataFromServer();
  };

  return (
    <MainContent>
      <Card style={{ width: "100%", marginTop: "10px", padding: "30px" }}>
        <Card.Header>
          <span className="">
            <h3>Purchase Orders</h3>
          </span>
        </Card.Header>

        <Card.Body>
          <div className="mb-10 mt-2">
            <div className="text-end">
              <ExcelDownloader
                data={data}
                excludedFields={[
                  "id",
                  "_id",
                  "p_register",
                  "__v",
                  "shipment_id",
                ]}
                filename={`Purchases_stock_${new Date()
                  .toISOString()
                  .replace(/[-:.]/g, "")}.xlsx`}
              />
            </div>
            <Space direction="vertical" size={12}>
              <RangePicker
                value={dates || dateValue}
                style={{ height: "36px" }}
                disabledDate={disabledDate}
                onCalendarChange={(val) => setDates(val)}
                onChange={(val) => setDateValue(val)}
                onOpenChange={onOpenChange}
                format="YYYY-MM-DD"
              />
            </Space>{" "}
            &nbsp;&nbsp;
            <Button
              variant="info"
              onClick={() => {
                //  setColLabel("Patients Created");
                searchRecordsBetweenDates("po_date");
              }}
              disabled={dateInterval.sd ? false : true}
            >
              Search
            </Button>
            &nbsp;&nbsp;
            <Button
              onClick={() => {
                // setResult([]);
                setDurationResult([]);
                setDateValue([]);
                //getDataFromServer();
                // setColLabel("Total Patients");
                setData(unfilteredData);
              }}
            >
              Reset
            </Button>
          </div>
          <div className="patientListSearch">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">Filter</InputGroup.Text>
              <Form.Control
                placeholder="Product / State / Code / Company ..."
                aria-label="Search"
                aria-describedby="basic-addon1"
                name="searchPatients"
                ref={searchRef}
                onChange={(e) => handleSearch(e.target.value)}
              />
              <InputGroup.Text>
                <i
                  className="mdi mdi-delete-outline"
                  onClick={clearSearch}
                  style={{ cursor: "pointer" }}
                ></i>
              </InputGroup.Text>
            </InputGroup>
          </div>
          <DataGrid
            rows={data}
            disableSelectionOnClick
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            displayTitle="Purchases"
            checkboxSelection
            style={{ height: "60vh" }}
          />
        </Card.Body>
      </Card>

      {/* Modal Popup */}
      <Modal show={openModal} centered>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Purchase Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent currentRecord={currentRecord} />
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={() => setOpenModal(false)}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      {/* {JSON.stringify(currentRecord)} */}
    </MainContent>
  );
};

const ModalContent = ({ currentRecord }) => {
  return (
    <div className="container-fluid">
      <Card style={{ width: "100%" }}>
        <Card.Header>
          <span className="card-title">
            <b>Product</b>
          </span>
        </Card.Header>
        <Card.Body>
          <table class="table table-striped">
            <tbody>
              <tr>
                <th scope="row">Product Name</th>
                <td>{currentRecord.product_name}</td>
              </tr>
              <tr>
                <th scope="row">Measuring Units</th>
                <td>{currentRecord.measuring_unit}</td>
              </tr>
              <tr>
                <th scope="row">Quantity</th>
                <td>{currentRecord.quantity}</td>
              </tr>
              <tr>
                <th scope="row">Unit Price</th>
                <td>{currentRecord.unit_price}</td>
              </tr>
              <tr>
                <th scope="row">Total Price</th>
                <td>
                  <b>{currentRecord.unit_price * currentRecord.quantity}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </Card.Body>
      </Card>
      <Card style={{ width: "100%" }}>
        <Card.Header>
          <span className="card-title">
            <b>Purchase Information</b>
          </span>
        </Card.Header>
        <Card.Body>
          <table class="table table-striped">
            <tbody>
              <tr>
                <th scope="row">PO Date</th>
                <td>{currentRecord.po_date}</td>
              </tr>
              <tr>
                <th scope="row">PO Number</th>
                <td>{currentRecord.po_number}</td>
              </tr>
              <tr>
                <th scope="row">Product Expiry</th>
                <td>{currentRecord.product_expiry}</td>
              </tr>
              {currentRecord.product_sku && (
                <tr>
                  <th scope="row">Produck SKU</th>
                  <td>{currentRecord.product_sku}</td>
                </tr>
              )}
              {currentRecord.batch_id && (
                <tr>
                  <th scope="row">Batch ID</th>
                  <td>{currentRecord.batch_id}</td>
                </tr>
              )}
              {currentRecord.shipment_id && (
                <tr>
                  <th scope="row">Shipment ID</th>
                  <td>{currentRecord.shipment_id}</td>
                </tr>
              )}
              <tr>
                <th scope="row">Company Name</th>
                <td>{currentRecord.company_name}</td>
              </tr>
              <tr>
                <th scope="row">Supplier Name</th>
                <td>{currentRecord.supplier_name}</td>
              </tr>
              <tr>
                <th scope="row">Phone</th>
                <td>{currentRecord.supplier_phone}</td>
              </tr>
              <tr>
                <th scope="row">Purchaser</th>
                <td>{currentRecord.purchaser_name}</td>
              </tr>
              <tr>
                <th scope="row">State</th>
                <td>{currentRecord.state}</td>
              </tr>
              {currentRecord.comments && (
                <tr>
                  <th scope="row" colSpan={2}>
                    Comments
                  </th>
                </tr>
              )}
              {currentRecord.comments && (
                <tr>
                  <td colSpan={2}>{currentRecord.comments}</td>
                </tr>
              )}
            </tbody>
          </table>
        </Card.Body>
      </Card>
    </div>
  );
};
export default ListPurchases;

import React, { useEffect, useState, useContext, useRef } from "react";

import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import axios from "axios";
import "antd/dist/antd.min.css";
import { Button, Row, Col } from "react-bootstrap";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap CSS is imported

import toast from "react-hot-toast";
//Context import
import AuthContext from "../../../components/shared/Context";

import useFetch from "../../../components/shared/useFetch";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const TCWiseInventoryDownloadChild = () => {
  let navigate = useNavigate();
  const { loggedInUserDetails } = useContext(AuthContext);

  const [tcenters, setTcenters] = useState();
  // State to track selected centers
  const [selectedCenters, setSelectedCenters] = useState([]);
  const [sortedSelectedCenters, setSortedSelectedCenters] = useState();
  const [tcInventory, setTCInventory] = useState();
  const [selectAll, setSelectAll] = useState(false);

  const { data: tcs } = useFetch(
    `${process.env.REACT_APP_API_URL}/tc/state/${loggedInUserDetails.state}`
  );

  useEffect(() => {
    if (!tcs) return;

    const filteredTCs = tcs?.treatment_center?.filter(
      (tc) => tc?.tcenters?.tc_type === "government"
    );

    setTcenters(
      filteredTCs?.map((tc) => ({
        tc_id: tc?._id,
        tc_name: tc?.tcenters?.name_tc,
      }))
    );
  }, [tcs]);

  const getInventoryStatus = () => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/tcstock/status`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: {
        selected_tcs: selectedCenters,
      },
    };
    axios(config)
      .then(function (response) {
        toast.success("Inventory status query executed successfully.");
        setTCInventory(response?.data?.records);
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      });
  };

  // Handle change when a checkbox is toggled
  const handleChange = (center) => {
    const isSelected = selectedCenters.find((sc) => sc.tc_id === center.tc_id);
    if (isSelected) {
      setSelectedCenters(
        selectedCenters.filter((sc) => sc.tc_id !== center.tc_id)
      );
    } else {
      setSelectedCenters([...selectedCenters, center]);
    }
  };

  const handleResetSelected = () => {
    setSelectedCenters([]);
    setTCInventory(null);
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedCenters([]);
    } else {
      setSelectedCenters(tcenters);
    }
    setSelectAll(!selectAll);
  };

  // Determine the number of columns needed (6 rows per column)
  const columns = Math.ceil(tcenters?.length / 6);

  const generatePdf = (data) => {
    // Get current date and time
    const now = new Date();
    // Format the timestamp for the filename
    const timestamp = now
      .toISOString()
      .replace(/:\s*/g, "-")
      .split(".")[0]
      .replace("T", "_");
    // Format a more readable date for display in the PDF
    const readableDate = now.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    const documentDefinition = {
      pageOrientation: "landscape",
      content: [
        // Add the current date at the top of the PDF
        { text: `Date: ${readableDate}`, style: "subheader" },
        // Add some space after the date
        { text: "\n\n" },

        ...data.map((tcGroup) => {
          return [
            {
              text: tcGroup.stockRecords[0]?.tc_info[0]?.tcenters?.name_tc,
              style: "header",
            },
            {
              style: "tableExample",
              table: {
                widths: ["*", "*", "*", "*", "*", "*"],
                body: [
                  [
                    "Product Name",
                    "Item Code",
                    "Opening Stock",
                    "Issue Qty",
                    "Consumed Qty",
                    "Closing Stock",
                  ],
                  ...tcGroup.stockRecords.map((record) => [
                    record.product_info[0]?.products?.product_name,
                    record.current_stock?.item_code,
                    record.current_stock?.opening_stock.toString(),
                    record.current_stock?.purchase_qty.toString(),
                    record.current_stock?.sale_qty.toString(),
                    record.current_stock?.closing_stock.toString(),
                  ]),
                ],
              },
              layout: "lightHorizontalLines",
            },
          ];
        }),
      ],
      styles: {
        header: {
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 12,
          bold: false,
          margin: [0, 0, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
      },
    };

    // Use the timestamp in the filename
    pdfMake
      .createPdf(documentDefinition)
      .download(`stock-tables_${timestamp}.pdf`);
  };

  return (
    <div className="">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div>
          <h3>Treatment Centers Inventory Status</h3>
        </div>
        <div className="d-flex flex-row">
          <div>
            <Button variant="success" onClick={handleSelectAllChange}>
              Select All
            </Button>
          </div>
          &nbsp;&nbsp;
          <div>
            <Button
              variant="primary"
              onClick={getInventoryStatus}
              disabled={!selectedCenters?.length}
            >
              Generate
            </Button>
          </div>
          &nbsp;&nbsp;
          <div>
            <Button
              variant="info"
              onClick={handleResetSelected}
              disabled={!selectedCenters?.length}
            >
              <i className="mdi mdi-refresh text-white"></i>
            </Button>
          </div>
        </div>
      </div>

      <div className="">
        <fieldset className="border p-2 w-100">
          <legend className="float-none w-auto p-2 fs-5">
            <span
              class="btn btn-warning btn-sm not-clickable"
              style={{ pointerEvents: "none" }}
            >
              Treatment Centers
            </span>
          </legend>
          <Grid container spacing={2}>
            {Array.from({ length: columns }).map((_, colIndex) => (
              <Grid item md={12 / columns} key={colIndex}>
                {tcenters
                  .slice(colIndex * 6, (colIndex + 1) * 6)
                  .map((center) => (
                    <Grid container key={center.tc_id}>
                      <Grid item xs={12}>
                        <label
                          style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "10px 0",
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={selectedCenters.some(
                              (sc) => sc.tc_id === center.tc_id
                            )}
                            onChange={() => handleChange(center)}
                            style={{ marginRight: "10px" }}
                          />
                          {center.tc_name}
                        </label>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            ))}
          </Grid>
        </fieldset>
      </div>

      {/* {JSON.stringify(
        selectedCenters.sort((a, b) =>
          a?.tc_name.split(" ")[1].localeCompare(b?.tc_name.split(" ")[1])
        )
      )} */}
      {/* {JSON.stringify(tcInventory)} */}

      {tcInventory?.length ? (
        <div className="mt-3">
          <fieldset className="border p-2 w-100">
            <legend className="float-none w-auto p-2 fs-5">
              <span
                class="btn btn-success btn-sm not-clickable"
                style={{ pointerEvents: "none" }}
              >
                Inventory Status per Center
              </span>
            </legend>
            <div className="d-flex flex-row justify-content-end align-items-center">
              <Button
                variant="primary"
                onClick={() => generatePdf(tcInventory)}
                className="justify-content-end"
              >
                Save PDF
              </Button>
            </div>
            <StockTables data={tcInventory} />
          </fieldset>
        </div>
      ) : (
        "Nothing to load..."
      )}
    </div>
  );
};

export default TCWiseInventoryDownloadChild;

const StockTables = ({ data }) => {
  return (
    <div>
      {data.map((tcGroup) => (
        <div key={tcGroup._id} className="mb-4">
          <h2>{tcGroup.stockRecords[0]?.tc_info[0]?.tcenters?.name_tc}</h2>
          <Table striped bordered hover style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Item Code</th>
                <th>Opening Stock</th>
                <th>Issue Qty</th>
                <th>Consumed Qty</th>
                <th>Closing Stock</th>
              </tr>
            </thead>
            <tbody>
              {tcGroup.stockRecords.map((record, index) => (
                <tr
                  key={record._id}
                  style={{
                    backgroundColor: index % 2 === 0 ? "white" : "silver",
                  }}
                >
                  <td>{record.product_info[0]?.products?.product_name}</td>
                  <td>{record.current_stock?.item_code}</td>
                  <td>{record.current_stock?.opening_stock}</td>
                  <td>{record.current_stock?.purchase_qty}</td>
                  <td>{record.current_stock?.sale_qty}</td>
                  <td>{record.current_stock?.closing_stock}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ))}
    </div>
  );
};

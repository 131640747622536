import React, { useEffect, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import BackButton from "../../../components/Buttons/BackButton";
import PatientInfoBlock from "../../../components/PatientProfile/PatientInfoBlock";
import VisitsTable from "./VisitsTable";
import ClinicalDetails from "./ClinicalDetails";
import axios from "axios";
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";
import CsvButton from "../../../components/DownloadCsv/CsvButton";
import VisitTab from "../GraphTabs/VisitTab";
import ReasonTab from "../GraphTabs/ReasonTab";
import GeneralTab from "../GraphTabs/GeneralTab";
import FinancialTab from "../GraphTabs/FinancialTab";

const PatientReportChild = ({ patientRecord }) => {
  const [data, setData] = useState([]);
  const [jscore, setJscore] = useState([]);
  const [dataCsv, setCsvData] = useState([]);
  const csvDownloadRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  //Error Logging Service
  const [{ errorLogApi }] = useAtom(gbState);
  //Sample Call: errorLogApi(err, "UsersListChild", "/users");

  useEffect(() => {
    const getDataFromServer = () => {
      const config = {
        "Content-Type": "application/json; charset=utf-8",
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/emr/patient/${patientRecord._id}`,
          config
        )
        .then(
          (res) => {
            console.log(res);
            setData(res.data.emrs);
            setJscore(res.data.jscore);
          },
          (error) => {
            errorLogApi(
              error,
              "PatientReportChild",
              `/emr/patient/${patientRecord._id}`
            );
          }
        )
        .catch((err) => {
          errorLogApi(
            err,
            "PatientReportChild",
            `/emr/patient/${patientRecord._id}`
          );
          console.log("error", err);
        });
    };
    getDataFromServer();
  }, []);

  // Download to excel code start----
  useEffect(() => {
    const getDataFromServer = () => {
      // console.log(patientRecordId);
      const config = {
        "Content-Type": "application/json; charset=utf-8",
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/visits/${patientRecord._id}`,
          config
        )
        .then(
          (res) => {
            const oldData = res.data.visits;
            const updatedData = oldData.map((data) => {
              if (
                patientRecord?.diagnosis?.toLowerCase().includes("thalassemia")
              ) {
                return {
                  patient_id: patientRecord.patientId,
                  patient: patientRecord.patient_name,
                  patient: patientRecord.patient_name,
                  disease: patientRecord.diagnosis,
                  date_of_birth: patientRecord.date_of_birth,
                  mobile: patientRecord.contact.primary_mobile,
                  email: patientRecord.contact.email,
                  weight: patientRecord.weight,
                  blood_group: patientRecord.bloodgroup
                    ? JSON.parse(patientRecord.bloodgroup).label
                    : "",
                  date_of_visit: dateFormatter(data.date_of_visit),
                  consultation: data.consultation
                    ? "CONSULTATION"
                    : "HOSPITALIZATION",
                  discharge: data.consultation
                    ? "NOT APPLICABLE"
                    : "CREATE DISCHARGE",
                  reason: JSON.parse(data.visit_reason).label,
                  center: data.treatment_center_id.tcenters.name_tc,
                  travel_distance: data.travel_distance,
                  travel_mode: JSON.parse(data.travel_mode).label,
                  payment_status: JSON.parse(data.payment_status).label,
                  on_visit_weight: data.weight,
                  height: data.height,
                  pre_transfusion_hb: data.pre_transfusion_hb,
                  liver: data.liver,
                  spleen: data.spleen,
                  post_transfusion_hb: data.post_transfusion_hb,
                  stay_cost: data.stay_cost,
                  food_cost: data.food_cost,
                  wages_lost_by_parents: data.wages_lost_by_parents,
                  school_absentism: data.school_absentism,
                  travel_cost: data.travel_cost,
                  other_direct_indirect_cost: data.other_direct_indirect_cost,
                  hcp_indirect_cost: data.hcp_indirect_cost,
                  reason_others: data.reason_others,
                };
              } else {
                return {
                  patient_id: patientRecord.patientId,
                  patient: patientRecord.patient_name,
                  patient: patientRecord.patient_name,
                  disease: patientRecord.diagnosis,
                  date_of_birth: patientRecord.date_of_birth,
                  mobile: patientRecord.contact.primary_mobile,
                  email: patientRecord.contact.email,
                  weight: patientRecord.weight,
                  blood_group: patientRecord.bloodgroup
                    ? JSON.parse(patientRecord.bloodgroup).label
                    : "",
                  date_of_visit: dateFormatter(data.date_of_visit),
                  consultation: data.consultation
                    ? "CONSULTATION"
                    : "HOSPITALIZATION",
                  discharge: data.consultation
                    ? "NOT APPLICABLE"
                    : "CREATE DISCHARGE",
                  reason: JSON.parse(data.visit_reason).label,
                  center: data.treatment_center_id.tcenters.name_tc,
                  travel_distance: data.travel_distance,
                  travel_mode: JSON.parse(data.travel_mode).label,
                  payment_status: JSON.parse(data.payment_status).label,
                  on_visit_weight: data.weight,
                  height: data.height,
                  stay_cost: data.stay_cost,
                  food_cost: data.food_cost,
                  wages_lost_by_parents: data.wages_lost_by_parents,
                  school_absentism: data.school_absentism,
                  travel_cost: data.travel_cost,
                  other_direct_indirect_cost: data.other_direct_indirect_cost,
                  hcp_indirect_cost: data.hcp_indirect_cost,
                  reason_others: data.reason_others,
                };
              }
            });
            setCsvData(updatedData);
          },
          (error) => {
            errorLogApi(
              error,
              "PatientReportChild",
              `/visits/${patientRecord._id}`
            );
          }
        )
        .catch((err) => console.log("error", err));
    };
    getDataFromServer();
  }, []);
  // Download to excel code end----
  const showGraphView = () => {
    setOpenModal(true);
  };

  function dateFormatter(date) {
    const date_conv = new Date(date);
    const fullyear = date_conv.getFullYear();
    const month = date_conv.getMonth() + 1;
    const day = date_conv.getDate();
    return `${day}/${month}/${fullyear}`;
  }

  return (
    <div>
      <div className="submitButton">
        {/* <h1 className="newPatientTitle">Create Visit</h1> */}
        <div className="patientUpdateButton">
          <BackButton />
          &nbsp;&nbsp;
          <CsvButton
            csvData={dataCsv}
            fileName="patient-visit-detail.csv"
            csvDownloadRef={csvDownloadRef}
            isIcon={false}
          />
          &nbsp;&nbsp;
          <Button
            variant="primary"
            size="medium"
            onClick={() => showGraphView()}
          >
            Graphical View
          </Button>
        </div>
        {/* Modal Popup */}
        <Modal show={openModal} size="lg">
          <Modal.Header closeButton onClick={() => setOpenModal(false)}>
            <Modal.Title>Graphical View</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ModalContent currentRecord={dataCsv} />
          </Modal.Body>
        </Modal>
      </div>
      <PatientInfoBlock selectedPatientProfile={patientRecord} />
      <div className="newPatientFormOnly mt-4">
        {/* <fieldset className="border p-2 w-100">
          <legend className="float-none w-auto p-2 fs-5">Visits</legend>
          <div className="newPatientFormOnly visit">
            <VisitsTable patientRecordId={patientRecord._id} />
          </div>
        </fieldset> */}
        <VisitsTable patientRecordId={patientRecord?._id} />
      </div>
      <ClinicalDetails title="Medical Details" pRecord={data} />
      <ClinicalDetails title="Joint Scores" pRecord={jscore} />
    </div>
  );
};

const ModalContent = ({ currentRecord }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  return (
    <div className="container-fluid legend">
      <Box>
        <Box>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Visit Graph" style={{ padding: "10px" }} />
            <Tab label="Reason Graph" style={{ padding: "10px" }} />
            <Tab label="General Graph" style={{ padding: "10px" }} />
            <Tab label="Financial Graph" style={{ padding: "10px" }} />
          </Tabs>
        </Box>
        <Box sx={{ padding: 2 }}>
          {tabIndex === 0 && (
            <Box>
              <Typography>
                <VisitTab currentRecord={currentRecord} />
              </Typography>
            </Box>
          )}
          {tabIndex === 1 && (
            <Box>
              <Typography>
                <ReasonTab currentRecord={currentRecord} />
              </Typography>
            </Box>
          )}
          {tabIndex === 2 && (
            <Box>
              <Typography>
                <GeneralTab currentRecord={currentRecord} />
              </Typography>
            </Box>
          )}
          {tabIndex === 3 && (
            <Box>
              <Typography>
                <FinancialTab currentRecord={currentRecord} />
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};
export default PatientReportChild;

import React from "react";
import { useLocation } from "react-router-dom";
import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import ViewPatientDeathRecordChild from "./ViewPatientDeathRecordChild";

const ViewPatientDeathRecord = () => {
  const { state } = useLocation();
  let pRecord = state;
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes
        page="PatientDeathRecord"
        route="/patient/drecord/view"
      >
        <ViewPatientDeathRecordChild pRecord={pRecord} />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default ViewPatientDeathRecord;

import React from "react";
import Card from "react-bootstrap/Card";

const VaccinationDetailsCard = ({ currentRecord }) => {
  return (
    <Card>
      <Card.Header>
        <i className="fas fa-align-justify" />
        <strong>Patient Vaccinations</strong>
      </Card.Header>
      <Card.Body>
        {currentRecord?.vaccinations?.hepatitis_b_vaccination && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b>
                {" "}
                {"hepatitis_b_vaccination".replaceAll("_", " ").toUpperCase()}
              </b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {
                JSON.parse(currentRecord?.vaccinations?.hepatitis_b_vaccination)
                  ?.label
              }
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            {" "}
            <b>
              {" "}
              {"hbv_date_of_vaccination_1st".replaceAll("_", " ").toUpperCase()}
            </b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {currentRecord?.vaccinations?.hbv_date_of_vaccination_1st}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            {" "}
            <b>
              {" "}
              {"hbv_date_of_vaccination_2nd".replaceAll("_", " ").toUpperCase()}
            </b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {currentRecord?.vaccinations?.hbv_date_of_vaccination_2nd}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            {" "}
            <b>
              {" "}
              {"hbv_date_of_vaccination_3rd".replaceAll("_", " ").toUpperCase()}
            </b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {currentRecord?.vaccinations?.hbv_date_of_vaccination_3rd}
          </div>
        </div>
        {currentRecord?.vaccinations?.meningococcal_vaccination && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b>
                {" "}
                {"meningococcal_vaccination".replaceAll("_", " ").toUpperCase()}
              </b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {
                JSON.parse(
                  currentRecord?.vaccinations?.meningococcal_vaccination
                )?.label
              }
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            {" "}
            <b>
              {" "}
              {"date_of_meningococcal_vaccination"
                .replaceAll("_", " ")
                .toUpperCase()}
            </b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {currentRecord?.vaccinations?.date_of_meningococcal_vaccination}
          </div>
        </div>
        {currentRecord?.vaccinations?.pneumococcal_vaccination && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b>
                {" "}
                {"pneumococcal_vaccination".replaceAll("_", " ").toUpperCase()}
              </b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {
                JSON.parse(
                  currentRecord?.vaccinations?.pneumococcal_vaccination
                ).label
              }
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            {" "}
            <b>
              {" "}
              {"date_of_pneumococcal_vaccination"
                .replaceAll("_", " ")
                .toUpperCase()}
            </b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {currentRecord?.vaccinations?.date_of_pneumococcal_vaccination}
          </div>
        </div>
        {currentRecord?.vaccinations?.typhoid_vaccination && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b> {"typhoid_vaccination".replaceAll("_", " ").toUpperCase()}</b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {
                JSON.parse(currentRecord?.vaccinations?.typhoid_vaccination)
                  .label
              }
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            {" "}
            <b>
              {" "}
              {"date_of_typhoid_vaccination".replaceAll("_", " ").toUpperCase()}
            </b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {currentRecord?.vaccinations?.date_of_typhoid_vaccination}
          </div>
        </div>
        {currentRecord?.vaccinations?.h_influenza_vaccination && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b>
                {" "}
                {"h_influenza_vaccination".replaceAll("_", " ").toUpperCase()}
              </b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {
                JSON.parse(currentRecord?.vaccinations?.h_influenza_vaccination)
                  .label
              }
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            {" "}
            <b>
              {" "}
              {"date_of_h_influenza_vaccination"
                .replaceAll("_", " ")
                .toUpperCase()}
            </b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {currentRecord?.vaccinations?.date_of_h_influenza_vaccination}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default VaccinationDetailsCard;

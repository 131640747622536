import { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import Tooltip from "@mui/material/Tooltip";

import axios from "axios";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import AlertDialog from "../../../components/InfoDialog/AlertDialog";

import PatientInfoBlock from "../../../components/PatientProfile/PatientInfoBlock";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import BackButton from "../../../components/Buttons/BackButton";

const ListHealthRecordChild = ({ patientRecordId, patientProfileData }) => {
  let navigate = useNavigate();
  const [patientProfile, setPatientProfile] = useState(patientProfileData);
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  const childRef = useRef();
  //Error Logging Service
  const [{ errorLogApi, dateFormatter }] = useAtom(gbState);

  const getDataFromServer = () => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };

    const bodyParameters = {
      key: "value",
    };

    let urlLink = `${process.env.REACT_APP_API_URL}/hrecord/patient/${patientRecordId}`;

    axios
      .get(urlLink, config)
      .then((res) => {
        const oldData = res.data.health_records;
        //console.log(res.data);
        const updatedData = oldData.map((data) => {
          return {
            ...data?.quality_of_life,
            id: data._id,
            diagnosis: data.diagnoses.diagnosis_name,
          };
        });
        //console.log(updatedData);
        setData(updatedData);
      })
      .catch((err) => {
        errorLogApi(err, "HealthListChild", urlLink);
      });
  };

  useEffect(() => {
    function dateFormatter(date) {
      const date_conv = new Date(date);
      const fullyear = date_conv.getFullYear();
      const month = date_conv.getMonth() + 1;
      const day = date_conv.getDate();
      return `${day}/${month}/${fullyear}`;
    }
    const getDataFromServer = () => {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };

      const bodyParameters = {
        key: "value",
      };

      let urlLink = `${process.env.REACT_APP_API_URL}/hrecord/patient/${patientRecordId}`;

      axios
        .get(urlLink, config)
        .then((res) => {
          const oldData = res.data.health_records;

          //const adl = Object.values({})
          const updatedData = oldData.map((data) => {
            const hrecord = { ...data?.quality_of_life };
            return {
              ...data?.quality_of_life,
              id: data?._id,
              date: dateFormatter(data?.createdAt),
              adl:
                hrecord?.bathing +
                hrecord?.dressing +
                hrecord?.eating +
                hrecord?.using_the_toilet +
                hrecord?.walking +
                hrecord?.getting_in_and_out_of_bed_or_chair +
                hrecord?.using_stairs,
              mobility:
                hrecord?.ability_to_walk_without_assistance +
                hrecord?.ability_to_walk_on_uneven_surfaces +
                hrecord?.ability_to_climb_stairs +
                hrecord?.ability_to_run_or_jog +
                hrecord?.ability_to_participate_in_physical_activities +
                hrecord?.location_and_severity_of_joint_pain_hemophilia +
                hrecord?.location_and_severity_of_bone_pain_thalassemia +
                hrecord?.frequency_of_pain +
                hrecord?.duration_of_pain +
                hrecord?.effect_of_pain_on_daily_activities,
              emotional:
                hrecord?.anxiety +
                hrecord?.depression +
                hrecord?.stress +
                hrecord?.coping_skills +
                hrecord?.social_support,
              cognitive:
                hrecord?.memory +
                hrecord?.attention +
                hrecord?.executive_functioning +
                hrecord?.processing_speed +
                hrecord?.language_skills,
              sexual:
                hrecord?.libido +
                hrecord?.erectile_function +
                hrecord?.ejaculation_function +
                hrecord?.orgasmic_function +
                hrecord?.sexual_satisfaction,
              quality_ol:
                hrecord?.physical_wellbeing +
                hrecord?.emotional_wellbeing +
                hrecord?.social_wellbeing +
                hrecord?.financial_wellbeing +
                hrecord?.spiritual_wellbeing,
              patient_satifaction:
                hrecord?.effectiveness +
                hrecord?.side_effects +
                hrecord?.convenience +
                hrecord?.cost +
                hrecord?.overall_satisfaction,
            };
          });
          setData(updatedData);
        })
        .catch((err) => {
          errorLogApi(err, "PatientListChild", urlLink);
        });
    };
    getDataFromServer();
  }, []);

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      width: 100,
      sortable: false,
      hideable: false,
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            <Tooltip title="View Health Record">
              <a onClick={() => {}}>
                <i
                  className="mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </a>
            </Tooltip>

            <Tooltip title="Delete Health Record">
              <div onClick={() => {}}>
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit Health Record">
              <div onClick={() => {}}>
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 110,
    },
    {
      field: "adl",
      headerName: "Activity of Daily living",
      width: 200,
    },
    {
      field: "mobility",
      headerName: "Mobility",
      width: 140,
    },
    {
      field: "emotional",
      headerName: "Emotional Functioning",
      width: 160,
    },
    {
      field: "cognitive",
      headerName: "Cognitive Functioning",
      width: 160,
    },
    {
      field: "sexual",
      headerName: "Sexual Functioning",
      width: 160,
    },
    {
      field: "quality_ol",
      headerName: "Overall QOL",
      width: 160,
    },
    {
      field: "patient_satifaction",
      headerName: "Patient Satisfaction",
      width: 180,
    },
  ];

  return (
    <div>
      <div className="patientUpdateButton">
        <Button
          variant="primary"
          size="large"
          onClick={() =>
            navigate("/addhealthrecord/", { state: patientProfile })
          }
        >
          Create Health Record
        </Button>{" "}
        &nbsp;&nbsp;
        <BackButton />
      </div>
      <AlertDialog ref={childRef} title="" />
      <div className="mb-2">
        <PatientInfoBlock selectedPatientProfile={patientProfile} />
      </div>
      <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        displayTitle="Health Record"
        checkboxSelection
        style={{ height: "40vh" }}
      />
    </div>
  );
};

export default ListHealthRecordChild;

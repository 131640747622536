import React from "react";
import { Route } from "react-router-dom";

import PrivateRoutes from "../../components/Auth/PrivateRoutes";

//Import All Components
import * as Pages from "./Pages";

export const StateAdminUserRoutes = (
  <Route element={<PrivateRoutes roleRequired={["ST_ADM_ACCESS"]} />}>
    <Route path="/errorlogs" name="ErrorLogs" element={<Pages.ErrorLog />} />
    <Route path="/patients" name="Patients" element={<Pages.PatientList />} />
    <Route path="/patient/" name="Patients" element={<Pages.PatientUpdate />} />
    <Route
      path="/dashboard-state"
      name="Dashboard State"
      element={<Pages.DashboardState />}
    />
    <Route
      path="/inventory-status"
      name="Inventory Status"
      element={<Pages.InventoryStatus />}
    />
    <Route
      path="/settlement-history"
      name="Settlement History"
      element={<Pages.SettlementHistory />}
    />
    <Route
      path="/pwinventory"
      name="Product Wise Inventory"
      element={<Pages.ProductWiseInventory />}
    />
    <Route
      path="/state-inventory"
      name="Product Wise Inventory"
      element={<Pages.ListStateInventory />}
    />
    <Route
      path="/tc-allotment-status"
      name="TC Allotment Status"
      element={<Pages.TCAllotmentStatus />}
    />
    <Route
      path="/tcallocation"
      name="Allocate to TC"
      element={<Pages.AllocateTC />}
    />
    <Route
      path="/tcwise/inventory/status"
      name="TC Wise Inventory Status"
      element={<Pages.TCWiseInventoryStatus />}
    />
    <Route
      path="/tcwise/inventory/download"
      name="TC Wise Inventory Download"
      element={<Pages.TCWiseInventoryDownload />}
    />
    <Route
      path="/transaction-history-state"
      name="Transaction History"
      element={<Pages.StateTransactionHistory />}
    />
    <Route
      path="/add-purchase"
      name="Inventory Add"
      element={<Pages.AddPurchase />}
    />
    <Route
      path="/list-purchases"
      name="List Purchases"
      element={<Pages.ListPurchases />}
    />
    <Route path="/list-sales" name="List Sales" element={<Pages.ListSales />} />
    <Route
      path="/inventory-status-tc"
      name="Inventory Status"
      element={<Pages.TCInventoryStatus />}
    />
    <Route
      path="/purchase-request-update"
      name="Purhcase Request Update"
      element={<Pages.UpdatePurchaseRequest />}
    />
    <Route
      path="/products/"
      name="List Products"
      element={<Pages.ProductsList />}
    />
    <Route
      path="/addproduct"
      name="Add Products"
      element={<Pages.AddProduct />}
    />
    <Route
      path="/updateproduct"
      name="Update Products"
      element={<Pages.UpdateProduct />}
    />
    <Route
      path="/diagnosis"
      name="Diagnosis Management"
      element={<Pages.Diagnosis />}
    />

    <Route
      path="/supplier-add"
      name="Add Supplier"
      element={<Pages.AddSupplier />}
    />
    <Route
      path="/suppliers"
      name="List Suppliers"
      element={<Pages.Suppliers />}
    />
    <Route
      path="/updatesupplier"
      name="Update Supplier"
      element={<Pages.UpdateSupplier />}
    />
    <Route
      path="/purchase-requests"
      name="List Request"
      element={<Pages.PurchaseRequests />}
    />

    {/* Drug Recommendations */}
    <Route
      path="/listkb"
      name="List Knowledgebase"
      element={<Pages.ListKnowledgebase />}
    />
    <Route
      path="/recommndeddrugs"
      name="List Recommended Drugs"
      element={<Pages.ListRecommendedDrugs />}
    />
    <Route
      path="/addkb"
      name="Add Knowledgebase"
      element={<Pages.AddKnowledgebase />}
    />
    {/* Users Management */}
    <Route path="/users" name="Users" element={<Pages.UsersList />} />
    <Route path="/users/add" name="Add User" element={<Pages.CreateUser />} />
    <Route
      path="/users/add-doctor"
      name="Add Doctor"
      element={<Pages.CreateDoctor />}
    />
    <Route
      path="/users/update-user/"
      name="Update User"
      element={<Pages.UpdateUser />}
    />
    {/* Roles Management */}
    <Route path="/roles" name="Roles" element={<Pages.RolesList />} />
    <Route
      path="/tcenters"
      name="Treatment Centers"
      element={<Pages.TCentersList />}
    />
    <Route
      path="/tcenters/add"
      name="Add Treatment Centers"
      element={<Pages.CreateTC />}
    />
    <Route
      path="/tcenters/update/"
      name="Update Treatment Center"
      element={<Pages.UpdateTC />}
    />
    {/* Diagnosis Management */}
    <Route
      path="/diagnosis/add"
      name="Add Diagnosis"
      element={<Pages.AddDiagnosisName />}
    />
    <Route
      path="/diagnosis/update"
      name="Update Diagnosis"
      element={<Pages.UpdateDiagnosisName />}
    />
    <Route
      path="/diagnosis-category"
      name="Diagnosis Category"
      element={<Pages.DiagnosisCategory />}
    />
    <Route path="/entrylogs" name="EntryLogs" element={<Pages.EntryLogs />} />
    <Route
      path="/activitylogs"
      name="ActivityLogs"
      element={<Pages.ActivityLog />}
    />
    <Route
      path="/inventory-status"
      name="Inventory Status"
      element={<Pages.InventoryStatus />}
    />
    <Route
      path="/settlement-history"
      name="Settlement History"
      element={<Pages.SettlementHistory />}
    />
    <Route
      path="/pwinventory"
      name="Product Wise Inventory"
      element={<Pages.ProductWiseInventory />}
    />
    <Route
      path="/state-inventory"
      name="Product Wise Inventory"
      element={<Pages.ListStateInventory />}
    />
    <Route
      path="/tc-allotment-status"
      name="TC Allotment Status"
      element={<Pages.TCAllotmentStatus />}
    />
    <Route
      path="/tcallocation"
      name="Allocate to TC"
      element={<Pages.AllocateTC />}
    />
    <Route
      path="/tcwise/inventory/status"
      name="TC Wise Inventory Status"
      element={<Pages.TCWiseInventoryStatus />}
    />
    <Route
      path="/transaction-history-state"
      name="Transaction History"
      element={<Pages.StateTransactionHistory />}
    />
    <Route
      path="/add-purchase"
      name="Inventory Add"
      element={<Pages.AddPurchase />}
    />
    <Route
      path="/list-purchases"
      name="List Purchases"
      element={<Pages.ListPurchases />}
    />
    <Route path="/list-sales" name="List Sales" element={<Pages.ListSales />} />
    <Route
      path="/inventory-status-tc"
      name="Inventory Status"
      element={<Pages.TCInventoryStatus />}
    />
    <Route
      path="/purchase-request-update"
      name="Purhcase Request Update"
      element={<Pages.UpdatePurchaseRequest />}
    />
    {/* Notification Route */}
    <Route
      path="/notifications"
      name="Notifications"
      element={<Pages.Notifications />}
    />
    {/* List Patient Dosing History */}
    <Route
      path="/dosing-history"
      name="Patient Dosing History"
      element={<Pages.ListPatientDosingHistory />}
    />
    <Route
      path="/view-dose"
      name="View Dose for Patient"
      element={<Pages.ViewDoseForPatient />}
    />
    {/* Select Export to Excel */}
    <Route
      path="/export/toexcel"
      name="Patient Search"
      element={<Pages.SelectExportToExcel />}
    />
    {/* Patients visit */}
    <Route
      path="/view-discharge/"
      name="View Discharge"
      element={<Pages.ViewDischarge />}
    />
    <Route
      path="/visits/"
      name="Visits"
      element={<Pages.PatientVisitsList />}
    />
    <Route path="/visit/" name="View Visit" element={<Pages.PatientVisit />} />
    <Route path="/patient/emr/" name="EMR" element={<Pages.UpdateEmr />} />
    {/* List Doses */}
    <Route
      path="/dose/list"
      name="List Add Dose for Patient"
      element={<Pages.ViewDoseTable />}
    />
  </Route>
);

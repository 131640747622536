import React, { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import styles from "./CreateUser.module.css";

import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import Badge from "react-bootstrap/Badge";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

//Context import
import AuthContext from "../../../components/shared/Context";

const CreateUserChild = () => {
  let navigate = useNavigate();
  const { checkLoggedInStatus, states, loggedInUserDetails } =
    useContext(AuthContext);

  //Error Logging Service & Santize Input
  const [{ errorLogApi, sanitizeInput }] = useAtom(gbState);

  //To check if the user is logged in
  useEffect(() => {
    checkLoggedInStatus();
  }, []);

  const [tcStates, setTcStates] = useState([]);
  const [tcType, setTCType] = useState(null);
  const [rolesValue, setRolesValue] = useState([]);
  const [formData, setFormData] = useState({
    first_name: null,
    last_name: null,
    mobile: null,
    email: null,
    password: null,
    role: null,
    user_type: null,
    tc_state: null,
    tc_district: null,
    tc_id: null,
    account_status: true,
    createdBy: loggedInUserDetails._id,
  });

  useEffect(() => {
    let urlLink = `${process.env.REACT_APP_API_URL}/tc/state/${loggedInUserDetails.state}`;

    if (loggedInUserDetails.role === "FULL_ACCESS") {
      urlLink = `${process.env.REACT_APP_API_URL}/tc/`;
    }

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios(config)
      .then(function (response) {
        const fetchedTcData = response.data.treatment_center;
        const tcData = fetchedTcData.map((tc) => {
          return {
            id: tc._id,
            tc_name: tc.tcenters.name_tc,
            state: tc.tcenters.state,
            district: tc.tcenters.district,
            tc_type: tc.tcenters.tc_type,
          };
        });
        //console.log(JSON.stringify(tcData));
        setTcStates([...tcData]);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  //Load Roles on page load
  useEffect(() => {
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/roles`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios(config)
      .then(function (response) {
        const fetchedRolesData = response.data.roles;
        setRolesValue(fetchedRolesData);
        //console.log(JSON.stringify(fetchedRolesData));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handleUserRegistration = (e) => {
    e.preventDefault();
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/user/register`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        //  console.log(JSON.stringify(response.data));
        navigate("/users");
        toast.success("User Registered Successfully.");
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "CreateUserChild", "/users/add");
        //  console.log(error);
      });
  };

  const handleChange = ({ currentTarget: input }) => {
    if (sanitizeInput(input.value, input.name)) {
      setFormData({
        ...formData,
        [input.name]: input.value,
      });
    }
  };

  const rolesFullOptions = rolesValue.map((role) => ({
    value: role._id,
    label: role.role_name,
    access: role.access_level,
  }));
  let rolesOptions = null;
  if (loggedInUserDetails.role !== "FULL_ACCESS") {
    rolesOptions = rolesFullOptions.filter(
      (role) => role.label !== "Administrator"
    );
  } else {
    rolesOptions = rolesFullOptions;
  }

  const tcOptions = tcStates.map((tc) => ({
    value: tc.id,
    label: <TCNameSelectComponent tc_name={tc.tc_name} tc_type={tc.tc_type} />,
    state: tc.state,
    district: tc.district,
    tc_type: tc.tc_type,
  }));

  const stateOptions = states.map((state, index) => ({
    value: index,
    label: state,
  }));

  //console.log("TC here : ", tcOptions);

  return (
    <div className="patient-list1">
      <div>
        <h2>Add User</h2>
        {tcType === "private" ? (
          <Badge bg="primary">{tcType && tcType.toUpperCase()}</Badge>
        ) : (
          <Badge bg="success">{tcType && tcType.toUpperCase()}</Badge>
        )}
        {/* {tcType && JSON.stringify(tcType)} */}
      </div>
      {/* {JSON.stringify({ ...formData })} */}
      <form onSubmit={(e) => handleUserRegistration(e)} autocomplete="off">
        <div className="patientUpdateButton">
          <Button variant="primary" size="medium" type="submit">
            Save User
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="secondary"
            size="medium"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
        <div className={styles.newFormUsersOnly}>
          <div className="newPatientItem w-25">
            <label htmlFor="first_name">First Name</label>
            <input
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              onClick={handleChange}
              required
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="last_name">Last Name</label>
            <input
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              onClick={handleChange}
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="mobile">Mobile</label>
            <input
              type="text"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              onClick={handleChange}
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              value={formData ? formData.email : null}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  email: e.target.value,
                });
              }}
              autoComplete="new-password"
              required
            />
          </div>

          <div className="newPatientItem w-25">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  password: e.target.value,
                });
              }}
              autoComplete="new-password"
              required
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="role">Role</label>
            <Select
              name="role"
              onChange={
                //dropDownChange
                (selected, dropdown) => {
                  //console.log(selected);
                  setFormData({
                    ...formData,
                    role: selected.access,
                    user_type: selected.label,
                  });
                }
              }
              className="newPatientSelect"
              options={rolesOptions}
            />
          </div>

          {formData.role === "ST_ADM_ACCESS" && (
            <div className="newPatientItem w-25">
              <label>State</label>
              <Select
                name="tc_state"
                onChange={
                  //dropDownChange
                  (selected, dropdown) => {
                    //  console.log(selected.id);
                    setFormData({
                      ...formData,
                      tc_state: selected.label,
                    });
                  }
                }
                className="newPatientSelect"
                options={stateOptions}
              />
            </div>
          )}

          {!(
            formData.role === "FULL_ACCESS" || formData.role === "ST_ADM_ACCESS"
          ) && (
            <div className="newPatientItem w-25">
              <label>Treatment Center</label>
              <Select
                name="tc_state"
                onChange={
                  //dropDownChange
                  (selected, dropdown) => {
                    // console.log(selected);
                    setFormData({
                      ...formData,
                      tc_id: selected.value,
                      tc_state: selected.state,
                      tc_district: selected.district,
                    });
                    setTCType(selected.tc_type);
                  }
                }
                className="newPatientSelect"
                options={tcOptions}
              />
            </div>
          )}
          {!(
            formData.role === "FULL_ACCESS" || formData.role === "ST_ADM_ACCESS"
          ) && (
            <div className="newPatientItem w-25">
              <label htmlFor="tc_state">State</label>
              <input
                type="text"
                name="tc_state"
                value={formData.tc_state}
                onChange={handleChange}
                disabled
              />
            </div>
          )}
          {!(
            formData.role === "FULL_ACCESS" || formData.role === "ST_ADM_ACCESS"
          ) && (
            <div className="newPatientItem w-25">
              <label htmlFor="tc_district">District</label>
              <input
                type="text"
                name="tc_district"
                value={formData.tc_district}
                onChange={handleChange}
                disabled
              />
            </div>
          )}
          {/* {JSON.stringify(rolesValue)} */}
        </div>
      </form>
    </div>
  );
};

const TCNameSelectComponent = ({ tc_name, tc_type }) => {
  const [{ errorLogApi, expiryFormatter }] = useAtom(gbState);
  return (
    <div>
      <span className="text-xs">{tc_name}</span>
      <p className="text-black truncate text-light font-12" small>
        {tc_type === "private" ? (
          <Badge bg="primary">{tc_type && tc_type.toUpperCase()}</Badge>
        ) : (
          <Badge bg="success">{tc_type && tc_type.toUpperCase()}</Badge>
        )}
      </p>
    </div>
  );
};

export default CreateUserChild;

import React, { useEffect, useState, useContext, useRef } from "react";
import axios from "axios";
import Select from "react-select";
import { Row, Col } from "react-bootstrap";
import "./customStyles.css"; // Make sure to import your custom CSS

//Context import
import AuthContext from "../../../../components/shared/Context";
import toast from "react-hot-toast";
import LoadingComponent from "./LoadingComponent";

const AllocateTCInventoryLocalModal = ({ currentRecord, refreshStock }) => {
  const { loggedInUserDetails } = useContext(AuthContext);
  const [currentStep, setCurrentStep] = useState(1);
  const [tcenters, setTcenters] = useState();
  const [selectedTC, setSelectedTC] = useState();
  const searchRef = useRef();
  const recieverTCRef = useRef();
  const authorizationRef = useRef();
  const allocatedQuantityRef = useRef();
  const commentsRef = useRef();
  const [formData, setFormData] = useState({
    tcpwstock_record_id: currentRecord?._id,
    tc_purchase_id: currentRecord?.product_stock?.tc_purchase_id,
    state_sale_id: null,
    state_purchase_id: currentRecord?.product_stock?.state_purchase_id?._id,
    sale_id: null,
    purchase_id: null,
    product_id: currentRecord?.product_stock?.product_id?._id,
    item_code: currentRecord?.product_stock?.product_id?.products?.item_code,
    allocated_quantity: null,
    authorized_by: null,
    from_tc_id: loggedInUserDetails?.tc_id,
    to_tc_id: selectedTC ? selectedTC : null,
    expiry_date:
      currentRecord?.product_stock?.state_purchase_id?.p_register?.expiry_date,
    state: loggedInUserDetails?.state,
  });

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const renderStepIndicator = (stepNumber) => {
    return currentStep === stepNumber
      ? "bg-blue-500 text-white"
      : "bg-gray-300 text-black";
  };

  //Get All TCs
  useEffect(() => {
    const getTCDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/tc/`;

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data.treatment_center));
          const oldData = response.data.treatment_center;
          const filteredTC = oldData?.filter(
            (tc) => tc?.tcenters?.tc_type === "government"
          );
          const updatedData = filteredTC.map((data) => {
            return {
              ...data,
              id: data?._id,
              value: data?._id,
              name_tc: data?.tcenters?.name_tc,
              hdcc_no: data?.tcenters?.hdcc_no,
              state: data?.tcenters?.state,
              district: data?.tcenters?.district,
              tcid: data?.tcenters?.treatment_center_id,
              label: (
                <TCDetails
                  tc_id={data?.tcenters?.treatment_center_id}
                  tc_name={data.tcenters.name_tc}
                />
              ),
              dataLabel: data?.tcenters?.name_tc,
            };
          });
          // console.log(updatedData);
          setTcenters(updatedData);
        })
        .catch(function (error) {
          //console.log(error.message);
        });
    };

    getTCDataFromServer();
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmitAllocation = () => {
    if (currentStep === 1) {
      if (!formData?.to_tc_id) {
        recieverTCRef.current.focus();
        return toast.error("Please select Treatment Center");
      }

      if (
        parseInt(formData?.allocated_quantity) >
          parseInt(currentRecord?.quantity) ||
        parseInt(formData?.allocated_quantity) < 0 ||
        !formData?.allocated_quantity
      ) {
        allocatedQuantityRef.current.focus();
        return toast.error(
          `The Quantity should be less than ${currentRecord?.quantity} and greater than 0`
        );
      }

      if (!formData?.authorized_by) {
        //focus to useref authorizationRef
        authorizationRef.current.focus();
        return toast.error("Name of Authorised person");
      }
      if (!formData?.comments) {
        //focus to useref authorizationRef
        commentsRef.current.focus();
        return toast.error("Update Comments");
      }
    }

    if (currentStep === 2) {
      let userToken = localStorage.getItem("token");
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/reallocate/add/`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        data: {
          ...formData,
        },
      };
      axios(config)
        .then(function (response) {
          refreshStock();
          toast.success("Allocated Successfully.");
        })
        .catch(function (error) {
          toast.error(JSON.stringify(error.response.data.message));
          console.log(error);
        });
    }

    nextStep();
  };

  return (
    <div className="container px-4 mx-auto">
      <Row className="align-items-center mb-3">
        <Col xs="auto">
          <div
            className={`${
              currentStep == 1 ? "step-circle-active" : "step-circle"
            }`}
          >
            1
          </div>
        </Col>
        <Col className="flex-grow-1">
          <div
            className="border-top border-primary"
            style={{ transition: "width 0.5s ease-in-out" }}
          ></div>
        </Col>
        <Col xs="auto">
          <div
            className={`${
              currentStep == 2 ? "step-circle-active" : "step-circle"
            }`}
          >
            2
          </div>
        </Col>
        <Col className="flex-grow-1">
          <div
            className="border-top border-primary"
            style={{ transition: "width 0.5s ease-in-out" }}
          ></div>
        </Col>
        <Col xs="auto">
          <div
            className={`${
              currentStep == 3 ? "step-circle-active" : "step-circle"
            }`}
          >
            3
          </div>
        </Col>
      </Row>
      {currentStep === 1 && (
        <ProductAllocationForm
          currentRecord={currentRecord}
          tcenters={tcenters}
          setSelectedTC={setSelectedTC}
          setFormData={setFormData}
          formData={formData}
          handleChange={handleChange}
          handleSubmitAllocation={handleSubmitAllocation}
          authorizationRef={authorizationRef}
          recieverTCRef={recieverTCRef}
          allocatedQuantityRef={allocatedQuantityRef}
          commentsRef={commentsRef}
        />
      )}
      {currentStep === 2 && (
        <VerifyData
          formData={formData}
          currentRecord={currentRecord}
          selectedTC={selectedTC}
          handleSubmitAllocation={handleSubmitAllocation}
          prevStep={prevStep}
        />
      )}

      {currentStep === 3 && <LoadingComponent />}
    </div>
  );
};

const TCDetails = ({ tc_name, tc_id }) => {
  return (
    <div>
      <span className="text-bold">{tc_name}</span>
      <p className="text-black truncate text-light font-12" small>
        TC ID: {tc_id}
      </p>
    </div>
  );
};

export default AllocateTCInventoryLocalModal;

const VerifyData = ({
  formData,
  currentRecord,
  selectedTC,
  handleSubmitAllocation,
  prevStep,
}) => {
  return (
    <div className="container-fluid">
      {/* {JSON.stringify(formData)} */}

      <table class="table table-striped">
        <tbody>
          <tr>
            <th scope="row">Product Name</th>
            <td>{currentRecord?.product_name}</td>
          </tr>
          <tr>
            <th scope="row">Selected Treatment Center</th>
            <td>{selectedTC}</td>
          </tr>
          <tr>
            <th scope="row">Allocated Quantity</th>
            <td>
              {formData?.allocated_quantity}{" "}
              {currentRecord?.product_stock?.product_id?.products
                ?.storage_unit &&
                currentRecord?.product_stock?.product_id?.products
                  ?.storage_unit}
            </td>
          </tr>
          <tr>
            <th scope="row">Authorised By</th>
            <td>{formData?.authorized_by}</td>
          </tr>
          <tr>
            <th scope="row">Comments</th>
            <td>{formData?.comments}</td>
          </tr>
        </tbody>
      </table>
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary" onClick={prevStep}>
          Update
        </button>
        &nbsp;
        <button className="btn btn-primary" onClick={handleSubmitAllocation}>
          Submit
        </button>
      </div>
    </div>
  );
};

const ProductAllocationForm = ({
  currentRecord,
  tcenters,
  setSelectedTC,
  setFormData,
  formData,
  handleChange,
  handleSubmitAllocation,
  authorizationRef,
  recieverTCRef,
  allocatedQuantityRef,
  commentsRef,
}) => {
  const dateFormatter = (date) => {
    const date_conv = new Date(date);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date_conv.getMonth()];
    const year = date_conv.getFullYear();
    return `${month} ${year}`;
  };

  return (
    <div className="container-fluid">
      <table class="table table-striped">
        <tbody>
          <tr>
            <th scope="row">Product Name</th>
            <td>{currentRecord?.product_name}</td>
          </tr>
          <tr>
            <th scope="row">Measuring Units</th>
            <td>{currentRecord?.measuring_unit}</td>
          </tr>
          <tr>
            <th scope="row">Category</th>
            <td>{currentRecord?.category}</td>
          </tr>
          <tr>
            <th scope="row">Expiry</th>
            <td>{dateFormatter(currentRecord?.product_expiry)}</td>
          </tr>
          <tr>
            <th scope="row">Company Name</th>
            <td>{currentRecord?.company_name}</td>
          </tr>
          <tr>
            <th scope="row">State</th>
            <td>{currentRecord?.state}</td>
          </tr>
          <tr>
            <th scope="row">Requesting TC</th>
            <td>
              {" "}
              <Select
                name="to_tc_id"
                onChange={(selected, dropdown) => {
                  setSelectedTC(
                    `${selected?.name_tc} / ${selected?.tcenters?.treatment_center_id}`
                  );

                  setFormData({ ...formData, to_tc_id: selected?._id });
                }}
                placeholder="Select TC..."
                options={tcenters}
                filterOption={(option, input) => {
                  return option.data.dataLabel
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
                value={
                  formData?.to_tc_id &&
                  tcenters.find((option) => option.value === formData.to_tc_id)
                }
                ref={recieverTCRef}
              />
            </td>
          </tr>
          <tr>
            <th scope="row">Available Quantity</th>
            <td>{currentRecord?.quantity}</td>
          </tr>
          <tr>
            <th scope="row">Allocated Quantity</th>
            <td>
              <input
                type="number"
                name="allocated_quantity"
                placeholder=""
                ref={allocatedQuantityRef}
                onChange={handleChange}
                value={formData?.allocated_quantity}
              />{" "}
              {currentRecord?.product_stock?.product_id?.products
                ?.storage_unit &&
                currentRecord?.product_stock?.product_id?.products
                  ?.storage_unit}
            </td>
          </tr>
          <tr>
            <th scope="row">Authorized by</th>
            <td>
              <input
                type="text"
                name="authorized_by"
                placeholder=""
                onChange={handleChange}
                value={formData?.authorized_by}
                ref={authorizationRef}
              />
            </td>
          </tr>
          <tr>
            <th scope="row" colSpan={2}>
              Comments
            </th>
          </tr>
          <tr>
            <td colSpan={2} style={{ height: "170px" }}>
              <textarea
                type="text"
                name="comments"
                className="newPatientSelect w-100 h-100"
                row={3}
                placeholder="Update note/comments for your reference"
                onChange={handleChange}
                value={formData?.comments}
                ref={commentsRef}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="d-flex justify-content-end">
        <button className="btn btn-primary" onClick={handleSubmitAllocation}>
          Next
        </button>
      </div>
    </div>
  );
};

import React from "react";
import MainContentCard from "../Home/MainContentCard";
import ActivityLogChild from "./ActivityLogChild";

const ActivityLog = () => {
  return (
    <MainContentCard>
      <ActivityLogChild />
    </MainContentCard>
  );
};

export default ActivityLog;

import React from "react";
import { RadioGroup, Radio } from "react-radio-group";

const globalGaitScores = [
  "All Skills are within normal limits",
  "One skill is not within normal limits",
  "Two skills are not within normal limits",
  "Three skills are not within normal limits",
  "No Skills are not within normal limits",
];

const GaitScoreTab = ({ handleChange, formData }) => {
  return (
    <table class="table table-striped table-responsive">
      <tbody>
        {globalGaitScores.map((skill, index) => {
          return (
            <RadioButtonRow
              radioButtonName="global_gait_score"
              handleChange={handleChange}
              option_text={skill}
              optionValue={index}
              defaultSelectedValue={formData.global_gait_score}
            />
          );
        })}
      </tbody>
    </table>
  );
};

export default GaitScoreTab;

const RadioButtonRow = ({
  radioButtonName,
  handleChange,
  option_text,
  optionValue,
  defaultSelectedValue,
}) => {
  return (
    <tr className="align-content-end">
      <RadioGroup
        name={radioButtonName}
        // selectedValue={this.state.selectedValue}
      >
        <td className="text-center w-25">&nbsp;</td>
        <td className="w-100">{option_text}</td>
        <td className="w-25">
          <Radio
            value={optionValue}
            className="radioButton"
            onChange={handleChange}
            checked={
              optionValue === parseInt(defaultSelectedValue) ? true : false
            }
          />
        </td>
      </RadioGroup>
    </tr>
  );
};

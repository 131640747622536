import React from "react";
import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

import DoctorsListChild from "./DoctorsListChild";

const DoctorsList = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="DoctorsListChild" route="/doctors">
        <DoctorsListChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

// Add doctor /users/add-doctor

export default DoctorsList;

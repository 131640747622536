import React, { useEffect, useState, useContext } from "react";

import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Card from "react-bootstrap/Card";
import MainContent from "../../Home/MainContent";
import "antd/dist/antd.min.css";
import { Modal } from "react-bootstrap";
import { DataGrid } from "@material-ui/data-grid";
import Badge from "react-bootstrap/Badge";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

//Context import
import AuthContext from "../../../components/shared/Context";

const StateTransactionHistory = () => {
  let navigate = useNavigate();
  const { loggedInUserDetails } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [{ errorLogApi }] = useAtom(gbState);
  const getDataFromServer = () => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };

    // console.log(config);

    const bodyParameters = {
      key: "value",
    };

    let urlLink = `${process.env.REACT_APP_API_URL}/state/transhistory/${loggedInUserDetails.state}`;

    if (loggedInUserDetails.role === "FULL_ACCESS") {
      urlLink = `${process.env.REACT_APP_API_URL}/state/transhistory/`;
    }

    // if (loggedInUserDetails.role === "ST_ADM_ACCESS") {
    //   urlLink = `${process.env.REACT_APP_API_URL}/suppliers/state/${loggedInUserDetails.state}`;
    // }

    axios
      .get(urlLink, config)
      .then((res) => {
        const oldData = res.data.trans_history;
        //console.log(oldData);
        const updatedData = oldData.map((data) => {
          //   console.log(data.purchase_inventory.product_id.products);
          return {
            ...data,
            id: data._id,
            dot: data.transaction.transaction_date,
            transaction_type: data.transaction.transaction_type,
            transaction_log: data.transaction.transaction_log,
          };
        });
        // console.log(updatedData);
        setData(updatedData);
      })
      .catch((err) => {
        errorLogApi(err, "StateTransactionHistory", urlLink);
      });
  };

  useEffect(() => {
    getDataFromServer();
  }, []);
  const columns = [
    {
      field: "Action",
      headerName: "Action",
      width: 120,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            <a onClick={() => viewRecord(params.row)}>
              <i
                className="mdi mdi-information"
                style={{ fontSize: "20px", color: "orange" }}
              ></i>
            </a>{" "}
            {/* &nbsp;
                <Link to={"/users/update-user/" + params.row.id}>
                  <i className="mdi mdi-square-edit-outline"></i>
                </Link>
                &nbsp;
                <a onClick={() => handleDelete(params.row.id)}>
                  <i className="mdi mdi-delete-outline"></i>
                </a> */}
          </div>
        );
      },
    },
    {
      field: "dot",
      headerName: "Trans Date",
      width: 160,
      renderCell: (params) => params.row.dot.slice(0, 10),
    },
    {
      field: "transaction_type",
      headerName: "Trans Type",
      width: 160,
      renderCell: (params) => {
        return (
          <div style={{ textAlign: "center" }}>
            {params.row.transaction_type === "CR" ? (
              <Badge bg="success">CREDIT</Badge>
            ) : (
              <Badge bg="danger">DEBIT</Badge>
            )}
          </div>
        );
      },
    },
    {
      field: "transaction_log",
      headerName: "Log",
      width: 450,
    },
  ];

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };

  return (
    <MainContent>
      <Card style={{ width: "100%", marginTop: "10px", padding: "30px" }}>
        <Card.Header>
          <span className="card-title">
            <h3>Transactions</h3>
          </span>
        </Card.Header>
        <Card.Body>
          <DataGrid
            rows={data}
            disableSelectionOnClick
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            displayTitle="Transactions"
            checkboxSelection
            style={{ height: "60vh" }}
          />
        </Card.Body>
      </Card>

      {/* Modal Popup */}
      <Modal show={openModal} centered>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Purchase Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent currentRecord={currentRecord} />
        </Modal.Body>
      </Modal>
    </MainContent>
  );
};

const ModalContent = ({ currentRecord }) => {
  return (
    <div className="container-fluid">
      <table class="table table-striped">
        <tbody>
          <tr>
            <th scope="row">Date of Transaction</th>
            <td>{currentRecord.dot}</td>
          </tr>
          <tr>
            <th scope="row">Transaction Type</th>
            <td>
              {currentRecord.transaction_type === "CR" ? (
                <Badge bg="success">CREDIT</Badge>
              ) : (
                <Badge bg="danger">DEBIT</Badge>
              )}
            </td>
          </tr>

          <tr>
            <th scope="row">Log</th>
            <td style={{ wordBreak: "break-word" }}>
              {currentRecord.transaction_log}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default StateTransactionHistory;

import React, { useState } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import axios from "axios";

const ADRCaptureTab = ({
  emrRecordId,
  emrRecord,
  emrRecordLocal,
  setEmrRecordLocal,
}) => {
  const [formData, setFormData] = useState({
    ...emrRecord,
    adr_details: emrRecord?.adr_details,
    adr_comments: emrRecord?.adr_comments,
  });

  const handleChangeValue = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const updateEmr = () => {
    // e.preventDefault();

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/emr/update/${emrRecordId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        toast.success("ADR Details saved Successfully.");
        setEmrRecordLocal(response.data.emr);
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error.response.data));
      });
  };

  return (
    <div>
      <div className="patientUpdateButton">
        <Button variant="primary" size="large" onClick={updateEmr}>
          Save
        </Button>
      </div>
      <div className="newPatientItem w-100">
        <label htmlFor="weight">ADR Details</label>
        <textarea
          className="form-control"
          name="adr_details"
          placeholder="Details..."
          rows={4}
          onChange={handleChangeValue}
          value={formData?.adr_details}
        />
      </div>
      <div className="newPatientItem w-100">
        <label htmlFor="weight">ADR Comments</label>
        <textarea
          className="form-control"
          name="adr_comments"
          placeholder="Details..."
          rows={4}
          onChange={handleChangeValue}
          value={formData?.adr_comments}
        />
      </div>
      {/* {JSON.stringify(formData)} */}
    </div>
  );
};

export default ADRCaptureTab;

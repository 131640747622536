import React from "react";
import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import PreRegistrationAddChild from "./PreRegistrationAddChild";

const PreRegistrationAdd = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="PreRegistrationsAdd" route="/pre/register/add">
        <PreRegistrationAddChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default PreRegistrationAdd;

import "./PatientVisit.css";

import PatientVisitChild from "./PatientVisitChild";
import { useLocation } from "react-router-dom";
import MainContentCard from "../../Home/MainContentCard";

import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

const PatientVisit = () => {
  const { state } = useLocation();
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="PatientVisitListChild" route="/visit">
        <PatientVisitChild
          patientRecordId={state.visit.id}
          patientInfo={state.patient}
          patientVisit={state.visit}
          patientEmr={state.emr}
        />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default PatientVisit;

import React from "react";
import MainContentCard from "../../Home/MainContentCard";
import ListKnowledgebaseChild from "./ListKnowledgebaseChild";

const ListKnowledgebase = () => {
  return (
    <MainContentCard>
      <ListKnowledgebaseChild />
    </MainContentCard>
  );
};

export default ListKnowledgebase;

import "./UpdateEmr.css";

import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";

import UpdateEmrChild from "./UpdateEmrChild";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

import MainContentCard from "../../Home/MainContentCard";
//import "bootstrap/dist/css/bootstrap.min.css";

const UpdateEmr = () => {
  const { state } = useLocation();
  // console.log("State data in EMR: ", state);
  const emrRecordId = state.emr_id;
  const [patientRecordId, setpatientRecordId] = useState("");
  const [emrRecord, setEmrRecord] = useState();
  const [visitRecord, setvisitRecord] = useState([]);
  const [visitRecords, setvisitRecords] = useState([]);

  //loadPatientProfile();
  //Error Logging Service
  const [{ errorLogApi }] = useAtom(gbState);

  useEffect(() => {
    if (emrRecordId) {
      let userToken = localStorage.getItem("token");
      // console.log(`${process.env.REACT_APP_API_URL}/emr/record/${emrRecordId}`);
      const config = {
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/emr/record/${emrRecordId}`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data.emrs));

          setvisitRecord(response?.data?.emrs?.visit_id);
          getVisitRecords(response?.data?.emrs?.visit_id);
          setpatientRecordId(response?.data?.emrs?.patientId);
          setEmrRecord(response?.data?.emrs);
          //console.log(patientRecordId);
          // console.log(response?.data?.emrs);
        })
        .catch(function (error) {
          //console.log(error);
          errorLogApi(error, "UpdateEmr", "/patient/emr");
        });
      const getVisitRecords = (vid) => {
        let userToken = localStorage.getItem("token");
        const config = {
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/visits/visit/${vid}`,
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        };

        axios(config)
          .then(function (response) {
            // console.log("Visit Records ", JSON.stringify(response.data));
            setvisitRecords(response.data);
          })
          .catch(function (error) {
            errorLogApi(error, "VisitRecords", "/patient/emr");
            //console.log(error);
          });
      };
    }
  }, []);

  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="UpdateEMR" route="/patient/emr">
        {/* hi this is test
        {JSON.stringify(emrRecord)}
        <br />
        <br />
        {JSON.stringify(state.emr_id)}
        <br />
        <br />
        {JSON.stringify(state.patientData)} */}
        {emrRecord ? (
          <UpdateEmrChild
            emrRecord={emrRecord}
            emrRecordId={state?.emr_id}
            pRecord={state?.patientData}
          />
        ) : (
          "Loading ..."
        )}
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default UpdateEmr;

import React from "react";
import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import UpcomingListChild from "./UpcomingListChild";

const UpcomingList = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="UpcomingList" route="/upcoming/appointments">
        <UpcomingListChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default UpcomingList;

import React from "react";
import MainContentCard from "../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../components/shared/ErrorBoundaryRoutes";
import AddDiagnosisCategoryChild from "./AddDiagnosisCategoryChild";

const AddDiagnosisCategory = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes
        page="AddDiagnosisCategory"
        route="/diagnosis-category/add"
      >
        <AddDiagnosisCategoryChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default AddDiagnosisCategory;

import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { tcRoles } from "../Data/UserRolesData";

//Context import
import AuthContext from "../shared/Context";

const PurchaseRequestMenu = (
  {
    // setExpandedMenu,
    // expandedMenu,
  }
) => {
  const { loggedInUserDetails, expandedMenu, setExpandedMenu } =
    useContext(AuthContext);
  // console.log(loggedInUserDetails);

  return (
    <li className="side-nav-item">
      <div
        onClick={() =>
          setExpandedMenu({
            ...expandedMenu,
            purchase_requests: !expandedMenu.purchase_requests,
          })
        }
        style={{ cursor: "pointer" }}
        className="side-nav-link"
      >
        <i className="mdi mdi-file-export" />
        <span> Purchase Requests</span>
        {!expandedMenu.purchase_requests && <span className="menu-arrow" />}
        {expandedMenu.purchase_requests && (
          <span className="menu-arrow" style={{ transform: "rotate(90deg)" }} />
        )}
      </div>

      <div
        className={`${
          expandedMenu.purchase_requests ? "collapse show" : "collapse"
        }`}
        id="sidebarPURCHASEPages"
      >
        <ul className="side-nav-second-level">
          {tcRoles?.includes(loggedInUserDetails?.role) && (
            <li>
              <NavLink to="/purchase-request-add">Create Request</NavLink>
            </li>
          )}
          <li>
            <NavLink to="/purchase-requests">List Requests</NavLink>
          </li>
        </ul>
      </div>
    </li>
  );
};

export default PurchaseRequestMenu;

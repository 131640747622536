import React from "react";
import Select from "react-select";

const MuscleCrepitusTab = ({ formData, dropDownChange }) => {
  const dropDown03 = [
    {
      id: 1,
      name: "None",
      value: 0,
    },
    {
      id: 2,
      name: "Mild",
      value: 1,
    },
    {
      id: 3,
      name: "Severe",
      value: 2,
    },
  ];

  const muscleCrepitus = {
    muscle_atrophy_left: [
      "muscle_atrophy_left_elbow",
      "muscle_atrophy_left_knee",
      "muscle_atrophy_left_ankle",
    ],
    muscle_atrophy_right: [
      "muscle_atrophy_right_elbow",
      "muscle_atrophy_right_knee",
      "muscle_atrophy_right_ankle",
    ],
    crepitus_on_motion_left: [
      "crepitus_on_motion_left_elbow",
      "crepitus_on_motion_left_knee",
      "crepitus_on_motion_left_ankle",
    ],
    crepitus_on_motion_right: [
      "crepitus_on_motion_right_elbow",
      "crepitus_on_motion_right_knee",
      "crepitus_on_motion_right_ankle",
    ],
  };

  return (
    <div className="table-responsive w-100" style={{ marginRight: "40px" }}>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col" className="text-center">
              Elbow
            </th>
            <th scope="col" className="text-center">
              Knee
            </th>
            <th scope="col" className="text-center">
              Ankle
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {/* Left Row */}
            <td className="table-row-label">Left</td>
            {muscleCrepitus.muscle_atrophy_left.map(
              (muscle_atrophy_left, index) => (
                <td className="table-row-options">
                  <DropDownMenu
                    fieldName={muscle_atrophy_left}
                    dropDownChange={dropDownChange}
                    dropDownValues={dropDown03}
                    defvalue={
                      formData[muscleCrepitus.muscle_atrophy_left[index]]
                    }
                    key={muscle_atrophy_left + index}
                    disabled
                  />
                </td>
              )
            )}
          </tr>
          <tr>
            {/* Right Row */}
            <td className="table-row-label">Right</td>
            {muscleCrepitus.muscle_atrophy_right.map(
              (muscle_atrophy_right, index) => (
                <td className="table-row-options">
                  <DropDownMenu
                    fieldName={muscle_atrophy_right}
                    dropDownChange={dropDownChange}
                    dropDownValues={dropDown03}
                    defvalue={
                      formData[muscleCrepitus.muscle_atrophy_right[index]]
                    }
                    key={muscle_atrophy_right + index}
                    disabled
                  />
                </td>
              )
            )}
          </tr>
          <tr>
            <td colSpan="4" className="text-center">
              Crepitus on motion
            </td>
          </tr>
          <tr>
            <td className="table-row-label">Left</td>
            {/* Left Row */}
            {muscleCrepitus.crepitus_on_motion_left.map(
              (crepitus_on_motion_left, index) => (
                <td className="table-row-options">
                  <DropDownMenu
                    fieldName={crepitus_on_motion_left}
                    dropDownValues={dropDown03}
                    defvalue={
                      formData[muscleCrepitus.crepitus_on_motion_left[index]]
                    }
                    key={crepitus_on_motion_left + index}
                    disabled
                  />
                </td>
              )
            )}
          </tr>
          <tr>
            {/* Right Row */}
            <td className="table-row-label">Right</td>
            {muscleCrepitus.crepitus_on_motion_right.map(
              (crepitus_on_motion_right, index) => (
                <td className="table-row-options">
                  <DropDownMenu
                    fieldName={crepitus_on_motion_right}
                    dropDownChange={dropDownChange}
                    dropDownValues={dropDown03}
                    defvalue={
                      formData[muscleCrepitus.crepitus_on_motion_right[index]]
                    }
                    key={crepitus_on_motion_right + index}
                    disabled
                  />
                </td>
              )
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default MuscleCrepitusTab;

const DropDownMenu = ({
  fieldName,
  dropDownChange,
  dropDownValues,
  defvalue,
  key,
}) => {
  const dropDownOptions = dropDownValues.map((ddval) => ({
    value: ddval.value,
    label: ddval.name,
  }));

  return (
    <div className="newPatientItem ">
      <Select
        name={fieldName}
        onChange={dropDownChange}
        value={dropDownOptions.find((v) => v.value === parseInt(defvalue))}
        className="newPatientSelect"
        options={dropDownOptions}
        isDisabled={true}
      />
    </div>
  );
};

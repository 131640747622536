import { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import AlertDialog from "../../../components/InfoDialog/AlertDialog";
import PatientInfoBlock from "../../../components/PatientProfile/PatientInfoBlock";
import moment from "moment";
import { DatePicker, TimePicker } from "antd";
import { visitReasons, visitTypes } from "../../../components/Data/ChartData";
//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import BackButton from "../../../components/Buttons/BackButton";

const AddAppointmentChild = ({ patientRecordId, patientProfileData }) => {
  let navigate = useNavigate();
  moment.tz.setDefault("Asia/Kolkata");
  const [patientProfile, setPatientProfile] = useState(patientProfileData);

  const [formData, setFormData] = useState({
    patient_id: patientRecordId,
    appointment_date: null,
    appointment_time: null,
    appointment_type: null,
    visit_type: null,
    appointment_notes: null,
  });
  const childRef = useRef();

  //Error Logging Service
  const [{ errorLogApi, dateFormatter }] = useAtom(gbState);

  const saveAppointment = (e) => {
    e.preventDefault();

    if (
      !formData.appointment_date ||
      !formData.appointment_time ||
      !formData.appointment_type
    ) {
      return toast.error("Please fill the details before Creating appointment");
    }

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/appointment/add/`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        toast.success("Appointment Saved Successfully.");
        navigate("/appointmentlist", { state: patientProfileData });
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "AddAppointment", "/appointment/add/");
      });
  };

  return (
    <form onSubmit={saveAppointment}>
      <div className="patientUpdateButton">
        <Button variant="primary" size="large" type="submit">
          Save Appointment
        </Button>{" "}
        &nbsp;&nbsp;
        <BackButton />
      </div>
      <AlertDialog ref={childRef} title="" />
      <div className="mb-2">
        <PatientInfoBlock selectedPatientProfile={patientProfile} />
      </div>
      <fieldset className="border p-2 w-100">
        <legend className="float-none w-auto p-2 fs-5">
          Create Appointment
        </legend>
        <div className="newPatientFormOnly">
          <FormElement formData={formData} setFormData={setFormData} />
        </div>
      </fieldset>
    </form>
  );
};

export default AddAppointmentChild;

const FormElement = ({ formData, setFormData }) => {
  //Error Logging Service
  const [{ errorLogApi }] = useAtom(gbState);
  const [vTypeOptions, setVTypeOptions] = useState(true);
  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };
  const visitTypeInput1 = useRef();

  const appointTypeOptions = [
    { value: 1, label: "Consultation" },
    { value: 2, label: "Drug Infusion" },
  ];

  function handleDatePickerChange(time, timeString) {
    setFormData({
      ...formData,
      appointment_time: timeString,
    });
  }

  const visitOptions = visitReasons.map((visit) => ({
    value: visit.id,
    label: visit.reason,
  }));

  useEffect(() => {
    let vObject = JSON.parse(formData?.appointment_type);
    if (vObject?.value === 2) {
      setVTypeOptions(false);
    } else {
      setFormData({
        ...formData,
        visit_type: null,
      });
      setVTypeOptions(true);
    }
  }, [formData?.appointment_type]);

  return (
    <div className="newPatientFormOnly m-5">
      {/* {JSON.stringify(formData)} */}
      <div className="newPatientItem">
        <label htmlFor="appointment_date">Appointment Date & Time</label>
        <DatePicker
          className="newPatientSelect datePicker"
          style={{ paddingLeft: "10px" }}
          name="appointment_date"
          onChange={(date, dateString) => {
            setFormData({
              ...formData,
              appointment_date: dateString,
            });
          }}
          disabledDate={(current) => {
            let customDate = moment().format("YYYY-MM-DD");
            return current && current < moment(customDate, "YYYY-MM-DD");
          }}
          showToday={true}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="appointment_time">Appointment Time</label>
        <TimePicker
          type={`time`}
          format="h:mm A"
          use12Hours={true}
          showNow={false}
          name="appointment_time"
          className="newPatientSelect datePicker"
          onChange={handleDatePickerChange}
          //   value={
          //     formData?.appointment_time &&
          //     moment(formData?.appointment_time, "h:mm A")
          //   }
          disabled={formData?.appointment_date ? false : true}
        />
      </div>
      <div className="newPatientItem">
        <label>Appointment Type</label>
        <Select
          name="appointment_type"
          onChange={(selected, dropdown) => {
            setFormData({
              ...formData,
              appointment_type: JSON.stringify(selected),
            });
          }}
          className="newPatientSelect"
          options={visitOptions}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
        />
      </div>
      <div className="newPatientItem">
        <label>Visit Type</label>
        <Select
          name="visit_type"
          onChange={(selected, dropdown) => {
            setFormData({
              ...formData,
              visit_type: JSON.stringify(selected),
            });
          }}
          className="newPatientSelect"
          options={visitTypes}
          ref={visitTypeInput1}
          isDisabled={vTypeOptions}
          value={formData?.visit_type && JSON.parse(formData?.visit_type)}
        />
      </div>
      <div className="newPatientItem" style={{ width: "100%" }}>
        <label htmlFor="appointment_notes">Notes/Description</label>
        <textarea
          type="text"
          name="appointment_notes"
          style={{ borderColor: "lightgray", width: "100%" }}
          placeholder="Other than selection"
          rows={3}
          onChange={handleChange}
        />
      </div>
      {/* {JSON.stringify(formData)} */}
    </div>
  );
};

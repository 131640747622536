import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const SwellingTab = ({ formData, dropDownChange }) => {
  const dropDown01 = [
    {
      id: 1,
      name: "No Swelling",
      value: 0,
    },
    {
      id: 2,
      name: "Mild",
      value: 1,
    },
    {
      id: 3,
      name: "Moderate",
      value: 2,
    },
    {
      id: 4,
      name: "Severe",
      value: 3,
    },
  ];

  const dropDown01_n = [
    {
      value: 0,
      label: "No Swelling",
    },
    {
      value: 1,
      label: "Mild",
    },
    {
      value: 2,
      label: "Moderate",
    },
    {
      value: 3,
      label: "Severe",
    },
  ];

  const dropDown02 = [
    {
      id: 1,
      name: "< 6 months",
      value: 0,
    },
    {
      id: 2,
      name: "≥ 6 months",
      value: 1,
    },
  ];
  const dropDown03 = [
    {
      id: 1,
      name: "No Swelling",
      value: 0,
    },
    {
      id: 2,
      name: "Mild",
      value: 1,
    },
    {
      id: 3,
      name: "Severe",
      value: 2,
    },
  ];

  const swellings = {
    swelling_left: [
      "swelling_left_elbow",
      "swelling_left_knee",
      "swelling_left_ankle",
    ],
    swelling_right: [
      "swelling_right_elbow",
      "swelling_right_knee",
      "swelling_right_ankle",
    ],
    swelling_durations_left: [
      "swelling_duration_left_elbow",
      "swelling_duration_left_knee",
      "swelling_duration_left_ankle",
    ],
    swelling_durations_right: [
      "swelling_duration_right_elbow",
      "swelling_duration_right_knee",
      "swelling_duration_right_ankle",
    ],
  };

  return (
    <div className="table-responsive w-100" style={{ marginRight: "40px" }}>
      <table className="table table-striped table-responsive">
        <thead>
          {/* <tr><td colSpan={4}>{JSON.stringify(formData)}</td></tr> */}
          <tr>
            <th scope="col">#</th>
            <th scope="col" className="text-center">
              Elbow
            </th>
            <th scope="col" className="text-center">
              Knee
            </th>
            <th scope="col" className="text-center">
              Ankle
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colSpan="4" className="text-center">
              Swelling
            </th>
          </tr>
          <tr>
            {/* left Row */}
            <td className="table-row-label">Left</td>
            {swellings.swelling_left.map((swelling_left, index) => {
              const frmData = `formData.${swelling_left}`;
              return (
                <td className="table-row-options">
                  <DropDownMenu
                    fieldName={swelling_left}
                    dropDownChange={dropDownChange}
                    dropDownValues={dropDown01}
                    defvalue={formData[swellings.swelling_left[index]]}
                    key={`swelling_left${index}`}
                  />
                </td>
              );
            })}
          </tr>
          <tr>
            {/* Right Row */}
            <td className="table-row-label">Right</td>
            {swellings.swelling_right.map((swelling_right, index) => (
              <td className="table-row-options">
                <DropDownMenu
                  fieldName={swelling_right}
                  dropDownValues={dropDown01}
                  defvalue={formData[swellings.swelling_right[index]]}
                  key={`swelling_right${index}`}
                />
              </td>
            ))}
          </tr>
          <tr>
            <th colSpan="4" className="text-center">
              Swelling Duration
            </th>
          </tr>
          <tr>
            {/* Left Row */}
            <td className="table-row-label">Left</td>
            {swellings.swelling_durations_left.map(
              (swelling_durations_left, index) => (
                <td className="table-row-options">
                  <DropDownMenu
                    fieldName={swelling_durations_left}
                    dropDownValues={dropDown01}
                    defvalue={
                      formData[swellings.swelling_durations_left[index]]
                    }
                    key={`swelling_durations_left${index}`}
                  />
                </td>
              )
            )}
          </tr>
          <tr>
            {/* Right Row */}
            <td className="table-row-label">Right</td>
            {swellings.swelling_durations_right.map(
              (swelling_durations_right, index) => (
                <td className="table-row-options">
                  <DropDownMenu
                    fieldName={swelling_durations_right}
                    dropDownChange={dropDownChange}
                    dropDownValues={dropDown01}
                    defvalue={
                      formData[swellings.swelling_durations_right[index]]
                    }
                    key={`swelling_durations_right${index}`}
                  />
                </td>
              )
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SwellingTab;

const DropDownMenu = ({
  fieldName,
  dropDownChange,
  dropDownValues,
  defvalue,
  key,
}) => {
  const dropDownOptions = dropDownValues.map((ddval) => ({
    value: ddval.value,
    label: ddval.name,
  }));

  return (
    <div className="newPatientItem">
      <Select
        name={fieldName}
        onChange={dropDownChange}
        value={dropDownOptions.find((v) => v.value === parseInt(defvalue))}
        className="newPatientSelect"
        options={dropDownOptions}
        isDisabled={true}
      />
    </div>
  );
};

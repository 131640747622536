import React, { useRef, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import InputGroup from "react-bootstrap/InputGroup";

//import useFetch from "../../components/shared/useFetch";

//Global State import
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

const GlobalSearchChild = () => {
  const [searchTerm, setSearchTerm] = useState(null);
  let navigate = useNavigate();
  const searchRef = useRef();
  //Error Logging Service
  const [{ encryptData, user }] = useAtom(gbState);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  function removeDashes(str) {
    // Check if the string contains a dash
    if (str && str.includes("-")) {
      return str.replace(/-/g, "");
    }
    return str;
  }

  const handleSearchPatients = () => {
    if (searchRef.current.value) {
      //find the patientID of the patient if it exists

      //doesnt not exist throw error

      //Exists  - redirect to /patient/:id with id as parameter
      navigate("/search-patient", {
        state: encryptData(removeDashes(searchRef.current.value.trim())),
      });
    } else toast.error("Please enter a search ID");
  };

  const handleClear = (name) => {
    setSearchTerm("");
    searchRef.current.value = "";
    searchRef.current.focus();
  };

  return (
    <Container fluid>
      <Row className="align-items-center justify-content-between my-3">
        <Col>
          <h2 className="allPatients">Search Patients</h2>
        </Col>
      </Row>
      <Row className="g-2">
        {/* Adjust the size of the input field to be larger on larger screens */}
        <Col xs={12} lg={12}>
          <div className="patientListSearch">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <i className="mdi mdi-account-search"></i>
              </InputGroup.Text>
              <Form.Control
                placeholder="Government ID (Aadhar XXXX-XXXX-XXXX)"
                aria-label="Search"
                aria-describedby="basic-addon1"
                name="searchPatients"
                ref={searchRef}
              />
              <InputGroup.Text>
                <i
                  className="mdi mdi-delete-outline"
                  onClick={handleClear}
                  style={{ cursor: "pointer" }}
                ></i>
              </InputGroup.Text>
            </InputGroup>
          </div>
          <div className="w-full flex align-items-end">
            <Button
              variant="primary"
              className="w-lg-auto"
              onClick={handleSearchPatients}
            >
              <i className="mdi mdi-magnify"></i> Search
            </Button>
          </div>
        </Col>
        {/* Adjust the size of the button to be smaller on larger screens */}
        <Col xs={12} lg={3} className="d-flex justify-content-end"></Col>
      </Row>
      <br />
      <br />
      <br />
      <br />
    </Container>
  );
};

export default GlobalSearchChild;

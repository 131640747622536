import React from "react";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import toast from "react-hot-toast";
import { RadioGroup, Radio } from "react-radio-group";

const UltraSoundScores = ({ emrRecordId, emrRecord }) => {
  const [anklesTotalScore, setAnklesTotalScore] = useState(0);
  const [kneesTotalScore, setKneesTotalScore] = useState(0);

  const [formData, setFormData] = useState({ ...emrRecord });

  useEffect(() => {
    if (emrRecord) {
      localStorage.setItem(`${emrRecordId}-utsound`, JSON.stringify(emrRecord));
    }
    let ultrasound = localStorage.getItem(`${emrRecordId}-utsound`);
    setFormData({ ...formData, ...JSON.parse(ultrasound) });
  }, [emrRecord]);

  useEffect(() => {
    const ankleScore = [
      formData.effusion_haemarthrosis_ankles,
      formData.synovial_hypertrophy_ankles,
      formData.haemosiderin_ankles,
      formData.surface_erosions_ankles,
      formData.subchondral_cysts_ankles,
      formData.cartilage_damage_ankles,
    ];

    //setAnklesTotalScore

    setAnklesTotalScore(
      ankleScore.reduce(
        (prev, value) => (prev = prev + parseInt(value ? value : 0)),
        0
      )
    );

    const kneeScore = [
      formData.effusion_haemarthrosis_knees,
      formData.synovial_hypertrophy_knees,
      formData.hemosiderin_knees,
      formData.surface_erosions_knees,
      formData.subchondral_cysts_knees,
      formData.cartilage_damage_knees,
    ];

    //setKneesTotalScore

    setKneesTotalScore(
      kneeScore.reduce(
        (prev, value) => (prev = prev + parseInt(value ? value : 0)),
        0
      )
    );
  }, [formData]);

  const updateEmr = (e) => {
    e.preventDefault();
    //changeTab(1);

    //  alert("Click to submit");

    let userToken = localStorage.getItem("token");
    const config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/emr/update/${emrRecordId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, ultrasound: true },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        localStorage.setItem(
          `${emrRecordId}-utsound`,
          JSON.stringify(formData)
        );
        toast.success("Ultrasound Score saved Successfully.");
      })
      .catch(function (error) {
        console.log(JSON.stringify(error.response.data));
      });
  };

  const handleChange = ({ currentTarget: input }) => {
    //alert([input.name]);
    setFormData({
      ...formData,
      [input.name]: input.checked,
    });
  };
  const handleChangeValue = ({ target: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  return (
    <form className="d-flex flex-sm-wrap" onSubmit={(e) => updateEmr(e)}>
      {/* {JSON.stringify(formData)} */}
      <div className="table-responsive w-100" style={{ marginRight: "40px" }}>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col" colspan="2">
                Ultrasound Score
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Ankles Total Score</th>
              <th>{anklesTotalScore}</th>
              <th style={{ textAlign: "right" }}>Knees Total Score</th>
              <th>{kneesTotalScore}</th>
            </tr>
            <tr>
              <th colspan="4">Ankles A. Soft-tissue Changes</th>
            </tr>
            <tr>
              <td>Effusion / Hemarthrosis</td>
              <td colspan="3" style={{ verticalAlign: "middle" }}>
                <RadioGroup
                  name="effusion_haemarthrosis_ankles"
                  selectedChecked={formData.effusion_haemarthrosis_ankles}
                  onClick={handleChangeValue}
                  disabled
                >
                  <Radio
                    value="1"
                    className="radioButton"
                    checked={
                      1 === parseInt(formData.effusion_haemarthrosis_ankles)
                    }
                    disabled
                  />
                  Small (1)
                  <Radio
                    value="2"
                    className="radioButton"
                    checked={
                      2 === parseInt(formData.effusion_haemarthrosis_ankles)
                    }
                    disabled
                    defaultChecked={true}
                  />
                  Moderate (2)
                  <Radio
                    value="3"
                    className="radioButton"
                    checked={
                      3 === parseInt(formData.effusion_haemarthrosis_ankles)
                    }
                    disabled
                  />
                  Large (3)
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td>Synovial Hypertophy</td>
              <td colspan="3" style={{ verticalAlign: "middle" }}>
                <RadioGroup
                  name="synovial_hypertrophy_ankles"
                  selectedChecked={formData.synovial_hypertrophy_ankles}
                  onClick={handleChangeValue}
                  disabled
                >
                  <Radio
                    value="1"
                    className="radioButton"
                    checked={
                      1 === parseInt(formData.synovial_hypertrophy_ankles)
                    }
                    disabled
                  />
                  Small (1)
                  <Radio
                    value="2"
                    className="radioButton"
                    checked={
                      2 === parseInt(formData.synovial_hypertrophy_ankles)
                    }
                    disabled
                  />
                  Moderate (2)
                  <Radio
                    value="3"
                    className="radioButton"
                    checked={
                      3 === parseInt(formData.synovial_hypertrophy_ankles)
                    }
                    disabled
                  />
                  Large (3)
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td>Haemosiderin</td>
              <td colspan="3" style={{ verticalAlign: "middle" }}>
                <RadioGroup
                  name="haemosiderin_ankles"
                  selectedChecked={formData.haemosiderin_ankles}
                  onClick={handleChangeValue}
                  disabled
                >
                  <Radio
                    value="1"
                    className="radioButton"
                    checked={1 === parseInt(formData.haemosiderin_ankles)}
                    disabled
                  />
                  Small (1)
                  <Radio
                    value="2"
                    className="radioButton"
                    checked={2 === parseInt(formData.haemosiderin_ankles)}
                    disabled
                  />
                  Moderate (2)
                  <Radio
                    value="3"
                    className="radioButton"
                    checked={3 === parseInt(formData.haemosiderin_ankles)}
                    disabled
                  />
                  Large (3)
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <th colspan="4">Ankles B. Osteochondral Domain</th>
            </tr>
            <tr>
              <td>Surface Erosion</td>
              <td colspan="3" style={{ verticalAlign: "middle" }}>
                <RadioGroup
                  name="surface_erosions_ankles"
                  selectedChecked={formData.surface_erosions_ankles}
                  onClick={handleChangeValue}
                  disabled
                >
                  <Radio
                    value="1"
                    className="radioButton"
                    checked={1 === parseInt(formData.surface_erosions_ankles)}
                    disabled
                  />
                  Small (1)
                  <Radio
                    value="2"
                    className="radioButton"
                    checked={2 === parseInt(formData.surface_erosions_ankles)}
                    disabled
                  />
                  Moderate (2)
                  <Radio
                    value="3"
                    className="radioButton"
                    checked={3 === parseInt(formData.surface_erosions_ankles)}
                    disabled
                  />
                  Large (3)
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td>Subchondral Cysts</td>
              <td colspan="3" style={{ verticalAlign: "middle" }}>
                <RadioGroup
                  name="subchondral_cysts_ankles"
                  selectedChecked={formData.subchondral_cysts_ankles}
                  onClick={handleChangeValue}
                  disabled
                >
                  <Radio
                    value="1"
                    className="radioButton"
                    checked={1 === parseInt(formData.subchondral_cysts_ankles)}
                    disabled
                  />
                  None
                  <Radio
                    value="2"
                    className="radioButton"
                    checked={2 === parseInt(formData.subchondral_cysts_ankles)}
                    disabled
                  />
                  One Bone
                  <Radio
                    value="3"
                    className="radioButton"
                    checked={3 === parseInt(formData.subchondral_cysts_ankles)}
                    disabled
                  />
                  More than one bone
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td>Cartilage Damage</td>
              <td colspan="3" style={{ verticalAlign: "middle" }}>
                <RadioGroup
                  name="cartilage_damage_ankles"
                  selectedChecked={formData.cartilage_damage_ankles}
                  onClick={handleChangeValue}
                  disabled
                >
                  <Radio
                    value="1"
                    className="radioButton"
                    checked={1 === parseInt(formData.cartilage_damage_ankles)}
                    disabled
                  />
                  None
                  <Radio
                    value="2"
                    className="radioButton"
                    checked={2 === parseInt(formData.cartilage_damage_ankles)}
                    disabled
                  />
                  Partial Loss
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <th colspan="4">Knees A. Soft-tissue Changes</th>
            </tr>
            <tr>
              <td>Effusion / Hemarthrosis</td>
              <td colspan="3" style={{ verticalAlign: "middle" }}>
                <RadioGroup
                  name="effusion_haemarthrosis_knees"
                  selectedChecked={formData.effusion_haemarthrosis_knees}
                  onClick={handleChangeValue}
                  disabled
                >
                  <Radio
                    value="1"
                    className="radioButton"
                    checked={
                      1 === parseInt(formData.effusion_haemarthrosis_knees)
                    }
                    disabled
                  />
                  Small (1)
                  <Radio
                    value="2"
                    className="radioButton"
                    checked={
                      2 === parseInt(formData.effusion_haemarthrosis_knees)
                    }
                    disabled
                  />
                  Moderate (2)
                  <Radio
                    value="3"
                    className="radioButton"
                    checked={
                      3 === parseInt(formData.effusion_haemarthrosis_knees)
                    }
                    disabled
                  />
                  Large (3)
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td>Synovial Hypertophy</td>
              <td colspan="3" style={{ verticalAlign: "middle" }}>
                <RadioGroup
                  name="synovial_hypertrophy_knees"
                  selectedChecked={formData.synovial_hypertrophy_knees}
                  onClick={handleChangeValue}
                  disabled
                >
                  <Radio
                    value="1"
                    className="radioButton"
                    checked={
                      1 === parseInt(formData.synovial_hypertrophy_knees)
                    }
                    disabled
                  />
                  Small (1)
                  <Radio
                    value="2"
                    className="radioButton"
                    checked={
                      2 === parseInt(formData.synovial_hypertrophy_knees)
                    }
                    disabled
                  />
                  Moderate (2)
                  <Radio
                    value="3"
                    className="radioButton"
                    checked={
                      3 === parseInt(formData.synovial_hypertrophy_knees)
                    }
                    disabled
                  />
                  Large (3)
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td>Haemosiderin</td>
              <td colspan="3" style={{ verticalAlign: "middle" }}>
                <RadioGroup
                  name="hemosiderin_knees"
                  selectedChecked={formData.hemosiderin_knees}
                  onClick={handleChangeValue}
                  disabled
                >
                  <Radio
                    value="1"
                    className="radioButton"
                    checked={1 === parseInt(formData.hemosiderin_knees)}
                    disabled
                  />
                  Small (1)
                  <Radio
                    value="2"
                    className="radioButton"
                    checked={2 === parseInt(formData.hemosiderin_knees)}
                    disabled
                  />
                  Moderate (2)
                  <Radio
                    value="3"
                    className="radioButton"
                    checked={3 === parseInt(formData.hemosiderin_knees)}
                    disabled
                  />
                  Large (3)
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <th colspan="4">Knees B. Osteochondral Domain</th>
            </tr>
            <tr>
              <td>Surface Erosion</td>
              <td colspan="3" style={{ verticalAlign: "middle" }}>
                <RadioGroup
                  name="surface_erosions_knees"
                  selectedChecked={formData.surface_erosions_knees}
                  onClick={handleChangeValue}
                  disabled
                >
                  <Radio
                    value="1"
                    className="radioButton"
                    checked={1 === parseInt(formData.surface_erosions_knees)}
                    disabled
                  />
                  Small (1)
                  <Radio
                    value="2"
                    className="radioButton"
                    checked={2 === parseInt(formData.surface_erosions_knees)}
                    disabled
                  />
                  Moderate (2)
                  <Radio
                    value="3"
                    className="radioButton"
                    checked={3 === parseInt(formData.surface_erosions_knees)}
                    disabled
                  />
                  Large (3)
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td>Subchondral Cysts</td>
              <td colspan="3" style={{ verticalAlign: "middle" }}>
                <RadioGroup
                  name="subchondral_cysts_knees"
                  selectedChecked={formData.subchondral_cysts_knees}
                  onClick={handleChangeValue}
                  disabled
                >
                  <Radio
                    value="1"
                    className="radioButton"
                    checked={1 === parseInt(formData.subchondral_cysts_knees)}
                    disabled
                  />
                  None
                  <Radio
                    value="2"
                    className="radioButton"
                    checked={2 === parseInt(formData.subchondral_cysts_knees)}
                    disabled
                  />
                  One Bone
                  <Radio
                    value="3"
                    className="radioButton"
                    checked={3 === parseInt(formData.subchondral_cysts_knees)}
                    disabled
                  />
                  More than one bone
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td>Cartilage Damage</td>
              <td colspan="3" style={{ verticalAlign: "middle" }}>
                <RadioGroup
                  name="cartilage_damage_knees"
                  selectedChecked={formData.cartilage_damage_knees}
                  onClick={handleChangeValue}
                  disabled
                >
                  <Radio
                    value="1"
                    className="radioButton"
                    checked={1 === parseInt(formData.cartilage_damage_knees)}
                    disabled
                  />
                  None
                  <Radio
                    value="2"
                    className="radioButton"
                    checked={2 === parseInt(formData.cartilage_damage_knees)}
                    disabled
                  />
                  Partial Loss
                </RadioGroup>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </form>
  );
};

export default UltraSoundScores;

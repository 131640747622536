import "./CreateRole.css";
import React from "react";
import MainContentCard from "../../Home/MainContentCard";

import CreateRoleChild from "./CreateRoleChild";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

const CreateRole = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes route="/tcenters/add" page="CreateRoleChild">
        <CreateRoleChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default CreateRole;

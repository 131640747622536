import React from "react";
import MainContentCard from "../../Home/MainContentCard";

import UpdateTCChild from "./UpdateTCChild";

const UpdateTC = () => {
  return (
    <MainContentCard>
      <UpdateTCChild />
    </MainContentCard>
  );
};

export default UpdateTC;

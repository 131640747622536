import React, { useState, useEffect } from "react";
import { faqs } from "./faq.js";
// import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Card,
  Accordion,
  InputGroup,
  FormControl,
} from "react-bootstrap";

const FaqHelpChild = () => {
  const [data, setData] = useState(faqs);
  const [searchTerm, setSearchTerm] = useState("");

  // Load the FAQ data from the JSON file
  useEffect(() => {
    setData(faqs);
  }, []);

  return (
    <Container>
      <h1>FAQs</h1>
      <InputGroup className="mb-3">
        <FormControl
          placeholder="Search for FAQs"
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
        />
      </InputGroup>
      <Accordion defaultActiveKey="0">
        {data
          .filter((val) => {
            if (searchTerm === "") {
              return val;
            } else if (
              val.question.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              return val;
            }
            return false;
          })
          .map((faq, index) => (
            <Accordion.Item key={faq.id} eventKey={faq.id.toString()}>
              <Accordion.Header>{faq.question}</Accordion.Header>
              <Accordion.Body className="bg-light">
                Ans: <i>{faq.answer}</i>
              </Accordion.Body>
            </Accordion.Item>
          ))}
      </Accordion>
    </Container>
  );
};

export default FaqHelpChild;

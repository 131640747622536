import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoutes from "../../components/Auth/PrivateRoutes";

import Unauthorised from "../../pages/404/Unauthorised";

import {
  privateRoles,
  tcRoles,
  adminRoles,
  stateAdminRole,
} from "../Data/UserRolesData";

//Import All Components
import * as Pages from "./Pages";

//Context import
import AuthContext from "../../components/shared/Context";
import { PrivateUserRoutes } from "./PrivateUserRoutes";
import { TCUserRoutes } from "./TCUserRoutes";
import { StateAdminUserRoutes } from "./StateAdminUserRoutes";
import { AdminUserRoutes } from "./AdminUserRoutes";

const OptimizedRoutes = () => {
  const { loggedInUserDetails } = useContext(AuthContext);

  return (
    <Routes>
      <Route
        path="*"
        name="Page not found"
        element={
          <Unauthorised mesg="404 error, Path not resolved / page not found." />
        }
      />
      <Route path="/" name="Login" element={<Pages.Login />} />
      <Route path="/login" name="Login" element={<Pages.Login />} />
      <Route
        path="/error404"
        name="Error: Page not found"
        element={<Pages.Unauthorised />}
      />
      <Route element={<PrivateRoutes />}>
        {/* For All LoggedIn Users */}
        <Route
          path="/error404"
          name="Error: Page not found"
          element={<Pages.Page404 />}
        />
        <Route
          path="/error403"
          name="Error: Permission denied"
          element={Pages.Page403}
        />
        <Route path="/home" name="Home" element={<Pages.DashboardTC />} />
        <Route
          path="/help-dash"
          name="Application Help"
          element={<Pages.HelpDashboard />}
        />
        {/* Generate Routes for Private Users */}
        {privateRoles.includes(loggedInUserDetails?.role) && PrivateUserRoutes}
        {/*  Generate Routes for TC Users */}
        {tcRoles.includes(loggedInUserDetails?.role) && TCUserRoutes}
        {/* Generate routes for State Admin */}
        {stateAdminRole.includes(loggedInUserDetails?.role) &&
          StateAdminUserRoutes}
        {/* Generate Routes for Admin USers */}
        {adminRoles.includes(loggedInUserDetails?.role) && AdminUserRoutes}
      </Route>
    </Routes>
  );
};

export default OptimizedRoutes;

import React, { useContext, useRef } from "react";

import { Button } from "react-bootstrap";

import Tooltip from "@mui/material/Tooltip";
import { BsFileEarmarkPlus } from "react-icons/bs";
import { DataGrid } from "@material-ui/data-grid";
import BackButton from "../../../../components/Buttons/BackButton";
import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import toast from "react-hot-toast";
import { makeStyles } from "@material-ui/core/styles";
import { Box, withStyles } from "@material-ui/core";
import { Modal } from "react-bootstrap";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../../../components/shared/GlobalState";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
//Context import
import AuthContext from "../../../../components/shared/Context";
import PatientInfoBlock from "../../../../components/PatientProfile/PatientInfoBlock";
import VisitInfoBlock from "../../../Visits/View/VisitInfoBlock";
import CreateDoseForPatientModal from "../Create/CreateDoseForPatientModal";
import UpdateVisitModal from "../../../Visits/Update/UpdateVisitModal";

const ViewDoseTableChild = () => {
  let navigate = useNavigate();
  const { state } = useLocation();
  let patientRecordId = state.visit;
  const selectedRecord = state;
  const patientDiagnosis = state?.patient?.diagnoses?.diagnosis_name;
  const [visit, setVisit] = useState(state.visit);

  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openDoseModal, setOpenDoseModal] = useState(false);
  const [openVisitModal, setOpenVisitModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [deliveryInfo, setDeliveryInfo] = useState(false);
  const searchRef = useRef();
  //Error Logging Service
  const [{ errorLogApi, dateFormatter, expiryFormatter }] = useAtom(gbState);
  const { loggedInUserDetails, setPatientDiagnosis, setFieldLabelFromArray } =
    useContext(AuthContext);

  const handleDelete = (id) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete visit (y/n) ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (id) => {
    //  setData(data.filter((item) => item.id !== id));
    //alert(id);
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(`${process.env.REACT_APP_API_URL}/visits/delete/${id}`, config)
      .then((response) => {
        //alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((err) => alert(err));
  };

  const getDataFromServer = () => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    const bodyParameters = {
      key: "value",
    };
    let urlLink = `${process.env.REACT_APP_API_URL}/tcstocksale/history/${patientRecordId?.patientId}/${patientRecordId?._id}`;

    axios
      .get(urlLink, config)
      .then((res) => {
        const oldData = res.data.dose_history;
        // console.log(oldData);
        const updatedData = oldData.map((data) => {
          //   console.log(data.purchase_inventory.product_id.products);
          return {
            ...data,
            id: data?._id,
            dose_id: data?.s_register?.tc_sale_id,
            date: data?.s_register?.date_time_of_infusion,
            patient_name: data?.s_register?.patient_id?.patient_name,
            patient_id: data?.s_register?.patient_id?.patientId,
            patient_record: data?.s_register?.patient_id,
            visit_date: data?.s_register?.visit_id?.date_of_visit,
            visit_record: {
              ...data?.s_register?.visit_id,
              id: data?.s_register?.visit_id?._id,
            },
            quantity:
              data?.s_register?.quantity +
              " " +
              data?.s_register?.product_id?.products?.storage_unit,
            product_name: data?.s_register?.product_id?.products?.product_name,
            product_code: data?.s_register?.product_id?.products?.item_code,
            tc_name: data?.s_register?.tc_id?.tcenters?.name_tc,
            hcp_name: data?.s_register?.given_by_nurse_or_doctor,
            state: data?.s_register?.tc_id?.tcenters?.state,
          };
        });
        //console.log(updatedData);
        setData(updatedData);
        setUnfilteredData(updatedData);
      })
      .catch((err) => console.log("error", err));
  };

  useEffect(() => {
    getDataFromServer();
  }, []);

  const useStyles = makeStyles(() => ({
    multiLineCell: {
      display: "flex",
      wordBreak: "keep-all",
      wordWrap: "break-word",
      overflowWrap: "break-word",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      lineHeight: "1.2rem",
      height: "100%",
    },
    customRow: {
      height: "75px", // Increase the row height as needed
    },
  }));

  const MultiLineCellRenderer = (params) => {
    const classes = useStyles();
    return (
      <div className={classes.multiLineCell}>
        <span id="content1">{params?.row?.product_name}</span>
        <span id="content2">
          <small className="small-font-size">{params?.row?.product_code}</small>
        </span>
      </div>
    );
  };

  const PatientLineCellRenderer = (params) => {
    const classes = useStyles();
    return (
      <div className={classes.multiLineCell}>
        <span id="content1">
          <b>{params?.row?.patient_name?.toUpperCase()}</b>
        </span>
        <span id="content2">
          <small className="small-font-size">
            <b>Patient ID:</b> {params?.row?.patient_id}
          </small>
        </span>
        <span id="content2">
          <small className="small-font-size">
            <b>Dose ID&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</b> {params?.row?.dose_id}
          </small>
        </span>
      </div>
    );
  };

  //Delete the dose
  const deleteDose = (id) => {
    confirmAlert({
      title: "",
      message: "Please select 'Yes' to confirm or 'No' to abort!",
      buttons: [
        {
          label: "Yes",
          onClick: () => delDose(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });

    const delDose = (id) => {
      const url_link = `${process.env.REACT_APP_API_URL}/tcstocksale/delete/${id}`;

      let userToken = localStorage.getItem("token");
      const config = {
        method: "delete",
        url: url_link,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      axios(config)
        .then(function (response) {
          toast.success("Dose Deleted Successfully.");
          getDataFromServer();
        })
        .catch(function (error) {
          toast.error(error?.response?.data?.message);
        });
    };
  };

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            <Tooltip title="Dose Information">
              <div
                onClick={() =>
                  navigate("/view-dose", {
                    state: {
                      dose_record: params.row,
                    },
                  })
                }
              >
                <i
                  className="mdi mdi-information"
                  style={{
                    fontSize: "15px",
                    color: `${
                      params.row.allotment_recieved_status ? "blue" : "green"
                    }`,
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete Dose">
              <div onClick={() => deleteDose(params.row._id)}>
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "visit_date",
      headerName: "Dose Date",
      filterable: false,
      disableColumnMenu: true,
      width: 140,
      align: "left",
      renderCell: (params) => dateFormatter(params.row.visit_date),
    },
    {
      field: "dose_id",
      headerName: "Dose ID",
      width: 220,
      renderCell: (params) => <div>{params?.row?.dose_id}</div>,
    },

    {
      field: "product_name",
      headerName: "Product",
      width: 200,
      renderCell: MultiLineCellRenderer,
    },

    {
      field: "quantity",
      headerName: "Dose Qty",
      filterable: false,
      disableColumnMenu: true,
      width: 140,
      align: "center",
      renderCell: (params) => <div>{params?.row?.quantity}</div>,
    },
    {
      field: "hcp_name",
      headerName: "HCP",
      width: 130,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => <div>{params?.row?.hcp_name}</div>,
    },
    {
      field: "tc_name",
      headerName: "TC Name",
      width: 160,
      align: "center",
      renderCell: (params) => <div>{params?.row?.tc_name}</div>,
    },
    {
      field: "state",
      headerName: "State",
      filterable: false,
      disableColumnMenu: true,
      width: 110,
      renderCell: (params) => <div>{params?.row?.state}</div>,
    },
  ];

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };
  //   console.log(data);

  let unfilterData = data;
  const handleSearch = (e) => {
    //console.log("Search Value...", e);

    if (e) {
      setData(
        unfilteredData.filter((item) => {
          return (
            item?.dose_id?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.patient_name?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.product_code?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.product_name?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.patient_id?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.sale_code?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.state?.toLowerCase().includes(e?.toLowerCase())
          );
        })
      );
    } else {
      getDataFromServer();
      setData(unfilteredData);
    }
  };

  const clearSearch = () => {
    searchRef.current.value = null;
    getDataFromServer();
  };

  const StyledDataGrid = withStyles({
    root: {
      "& .MuiDataGrid-renderingZone": {
        maxHeight: "none !important",
      },
      "& .MuiDataGrid-cell": {
        lineHeight: "unset !important",
        maxHeight: "none !important",
        whiteSpace: "normal",
      },
      "& .MuiDataGrid-row": {
        maxHeight: "none !important",
      },
    },
  })(DataGrid);

  return (
    <div>
      <span className="">
        <h3>Dose Lists</h3>
      </span>

      <div className="text-end">
        <Button variant="secondary" size="md" onClick={() => navigate(-1)}>
          Back
        </Button>
        {/* &nbsp;&nbsp;
        <Button
          variant="warning"
          size="md"
          onClick={() =>
            navigate("/create-dose", {
              state: { ...selectedRecord },
            })
          }
        >
          Create Dose
        </Button>{" "} */}
        &nbsp;&nbsp;
        <Button
          variant="warning"
          size="md"
          onClick={() => {
            setOpenDoseModal(true);
          }}
        >
          Create Dose
        </Button>
      </div>

      <div className="mb-2">
        <PatientInfoBlock selectedPatientProfile={selectedRecord?.patient} />
        {/* {JSON.stringify(selectedRecord?.patient)} */}
      </div>

      <div className="patientListSearch">
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">Filter</InputGroup.Text>
          <Form.Control
            placeholder="Product / Dose id  ..."
            aria-label="Search"
            aria-describedby="basic-addon1"
            name="searchPatients"
            ref={searchRef}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <InputGroup.Text>
            <i
              className="mdi mdi-delete-outline"
              onClick={clearSearch}
              style={{ cursor: "pointer" }}
            ></i>
          </InputGroup.Text>
        </InputGroup>
      </div>
      <StyledDataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        displayTitle="Purchases"
        // checkboxSelection
        style={{ height: "57vh" }}
      />

      <div className="mt-2">
        <i
          className="mdi mdi-square-edit-outline"
          style={{ fontSize: "15px", color: "blue", cursor: "pointer" }}
          onClick={() => setOpenVisitModal(true)}
        >
          &nbsp;Edit Visit
        </i>{" "}
        <VisitInfoBlock
          selectedPatientVisit={visit}
          PatientDiagnosis={patientDiagnosis}
        />
      </div>

      {/* Modal Patient Popup */}
      <Modal show={openModal} centered>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent currentRecord={currentRecord} />
        </Modal.Body>
      </Modal>

      {/* Modal Dose Popup */}
      <Modal show={openDoseModal} size="lg" centered>
        <Modal.Header closeButton onClick={() => setOpenDoseModal(false)}>
          <Modal.Title>Dose</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateDoseForPatientModal
            selectedRecord={selectedRecord}
            reloadDoses={getDataFromServer}
            setOpenDoseModal={setOpenDoseModal}
          />
        </Modal.Body>
      </Modal>

      {/* Modal Edit Visit */}
      <Modal show={openVisitModal} size="lg" centered>
        <Modal.Header closeButton onClick={() => setOpenVisitModal(false)}>
          <Modal.Title>Edit Visits</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdateVisitModal
            visit={visit}
            patient={state.patient}
            setVisit={setVisit}
            setOpenVisitModal={setOpenVisitModal}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

const ModalContent = ({ currentRecord }) => {
  return <div className="">{JSON.stringify(currentRecord)}</div>;
};

export default ViewDoseTableChild;

import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

//Context import
import AuthContext from "../../components/shared/Context";

const UpdateDiagnosisNameChild = ({ diagnosis }) => {
  const {
    checkLoggedInStatus,
    loggedInUserDetails,
    selectedDiagnosis,
    states,
  } = useContext(AuthContext);
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: diagnosis?.id,
    category_id: diagnosis?.category_id?._id,
    state: diagnosis?.state ? diagnosis?.state : null,
    label: diagnosis?.label,
  });

  const [diagnosisCatOptions, setDiagnosisCatOptions] = useState();

  const stateOptions = states.map((state, index) => ({
    value: index,
    label: state,
  }));

  //Get Diagnosis Category Based on State
  useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };

    let urlLink = `${process.env.REACT_APP_API_URL}/dcat/state/${formData?.state}`;

    axios
      .get(urlLink, config)
      .then((res) => {
        const responseData = res?.data?.categories;
        // console.log(responseData);
        const diagnosesCatData = responseData.map((c) => ({
          value: c._id,
          label: c.category,
        }));
        //console.log(diagnosesCatData);
        setDiagnosisCatOptions(diagnosesCatData);
      })
      .catch((err) => console.log("error", err));
  }, [formData?.state]);

  const updateDiagnosis = () => {
    if (!formData.category_id) {
      toast.error("Please Add Category!");
      return;
    }

    if (loggedInUserDetails?.role === "FULL_ACCESS" && formData?.state === null)
      return toast.error("Please Select State!");

    let userToken = localStorage.getItem("token");
    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/diagnosis/update/${diagnosis?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        toast.success("Diagonsis Updated Successfully.");
        //console.log(response?.message);
        navigate("/diagnosis");
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error?.response?.data?.message));
        if (error?.response?.data?.message?.toLowerCase().includes("invalid")) {
          navigate("/login");
        }
      });
  };

  const handleChangeValue = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "70vh" }}
    >
      <div className="col-md-6 mx-auto d-block d-flex signup-form">
        <form className="mt-1 border p-4 shadow w-100">
          <div className="row">
            <div className="">
              <h5 className="text-secondary">Create Diagnosis Category</h5>
              <hr />
              <br />
            </div>
          </div>
          {loggedInUserDetails?.role === "FULL_ACCESS" && (
            <div className="row">
              <div className="">
                <label>State</label>
                <Select
                  name="state"
                  onChange={
                    //dropDownChange
                    (selected, dropdown) => {
                      console.log(selected);
                      setFormData({
                        ...formData,
                        state: selected.label,
                        category_id: null,
                      });
                    }
                  }
                  className="newPatientSelect"
                  options={stateOptions}
                  value={
                    formData?.state &&
                    stateOptions.find((st) => st?.label === formData?.state)
                  }
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="">
              <label>Diagnosis Category</label>
              <Select
                name="category_id"
                onChange={
                  //dropDownChange
                  (selected, dropdown) => {
                    console.log(selected);
                    setFormData({
                      ...formData,
                      category_id: selected.value,
                    });
                  }
                }
                className="newPatientSelect"
                options={diagnosisCatOptions}
                value={
                  formData?.category_id
                    ? diagnosisCatOptions?.find(
                        (cat) => cat.value === formData?.category_id
                      )
                    : null
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="">
              <label>
                Enter ID<span className="text-danger">*</span>
              </label>
              <input
                type="number"
                name="id"
                className="form-control"
                placeholder="Diagnosis ID"
                onChange={handleChangeValue}
                value={formData?.id}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="">
              <label>
                Enter Diagnosis Name<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="label"
                className="form-control"
                placeholder="Diagnosis Name"
                onChange={handleChangeValue}
                value={formData?.label}
                required
              />
            </div>
          </div>
          <div className="flex flex-row mt-4">
            <div className="col-md-12">
              <Button
                variant="primary"
                size="medium"
                className="float-end"
                onClick={updateDiagnosis}
              >
                Save
              </Button>
            </div>

            <div className="col-md-12">
              <Button
                variant="primary"
                size="secondary"
                className="float-start"
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
            </div>
          </div>
        </form>
      </div>
      {/* {JSON.stringify(formData)} */}
    </div>
  );
};

export default UpdateDiagnosisNameChild;

import React, { useState } from "react";
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

import MainContent from "../Home/MainContent";
import DashboardTCChild from "./DashboardTCChild";
import HelpPopup from "../ApplicationHelp/HelpPopup";

const DashboardTC = () => {
  // const [userStatus] = useAtom(gbState);
  const [showHelpPopup, setShowHelpPopup] = useState(true);

  const handleCloseHelpPopup = () => {
    setShowHelpPopup(false);
  };
  return (
    <MainContent>
      <HelpPopup showPopup={showHelpPopup} onClose={handleCloseHelpPopup} />
      <DashboardTCChild />
    </MainContent>
  );
};

export default DashboardTC;

import React from "react";

import Topbar from "../../../../components/Topbar/Topbar";
import SideBar from "../../../../components/SideBar/SideBar";

import "./DiagnosisWiseReport.css";
import DiagnosisWiseReportChild from "./DiagnosisWiseReportChild";

import MainContent from "../../../Home/MainContent";

const DiagnosisWiseReport = ({ patientData }) => {
  return (
    <div className="parent-container">
      <DiagnosisWiseReportChild patientData={patientData} />
    </div>
  );
};

export default DiagnosisWiseReport;

export const videoList = [
  {
    id: 1,
    title: "Add Patient Hemophilia",
    url: "https://www.youtube.com/watch?v=JpBqRdT2EwE",
    keywords: [""],
  },
  {
    id: 2,
    title: "Create Treatment Center",
    url: "https://www.youtube.com/watch?v=ufY--j3yzzc",
    keywords: [""],
  },
  {
    id: 3,
    title: "Create State Admin",
    url: "https://www.youtube.com/watch?v=UBnBmblLB9U",
    keywords: [""],
  },
  {
    id: 4,
    title: "Show Roles",
    url: "https://www.youtube.com/watch?v=RBaBMf482Ao",
    keywords: [""],
  },
  {
    id: 5,
    title: "Add EMR Hemophilia",
    url: "https://www.youtube.com/watch?v=T6Z4lstBzV8",
    keywords: [""],
  },
  {
    id: 6,
    title: "Create Health Record",
    url: "https://www.youtube.com/watch?v=ssHAXirDADQ",
    keywords: [""],
  },
  {
    id: 7,
    title: "Patient State Reporting",
    url: "https://www.youtube.com/watch?v=0juVnOFAdoc",
    keywords: [""],
  },
  {
    id: 8,
    title: "Create a Doctor",
    url: "https://www.youtube.com/watch?v=i62DbT97bRQ",
    keywords: [""],
  },
  {
    id: 9,
    title: "Create A Product",
    url: "https://www.youtube.com/watch?v=zMjwpVNo1M4",
    keywords: [""],
  },
  {
    id: 10,
    title: "Create A Supplier",
    url: "https://www.youtube.com/watch?v=Oo4UTFbtt2w",
    keywords: [""],
  },
  {
    id: 11,
    title: "Add Product To Inventory",
    url: "https://www.youtube.com/watch?v=6JQIBWcEV-w",
    keywords: [""],
  },
  {
    id: 12,
    title: "Add Product To Inventory",
    url: "https://www.youtube.com/watch?v=6JQIBWcEV-w",
    keywords: [""],
  },
  {
    id: 13,
    title: "Allocate To TC",
    url: "https://www.youtube.com/watch?v=IMzOGwVyVak",
    keywords: [""],
  },
  {
    id: 14,
    title: "Add Patient Thalassemia",
    url: "https://www.youtube.com/watch?v=Zk1GI9SgTLU",
    keywords: [""],
  },
  {
    id: 15,
    title: "List Patients",
    url: "https://www.youtube.com/watch?v=vGz4qcKG-Xo",
    keywords: [""],
  },
  {
    id: 16,
    title: "Create Appointments",
    url: "https://www.youtube.com/watch?v=gF4GCmP8Cpw",
    keywords: [""],
  },
  {
    id: 17,
    title: "Create A Visit",
    url: "https://www.youtube.com/watch?v=HZAM4-yRd8Y",
    keywords: [""],
  },
  {
    id: 18,
    title: "Add EMR Hemophilia",
    url: "https://www.youtube.com/watch?v=T6Z4lstBzV8",
    keywords: [""],
  },
  {
    id: 19,
    title: "Add EMR Thalassemia",
    url: "https://www.youtube.com/watch?v=DPbJN5lkVBA",
    keywords: [""],
  },
  {
    id: 20,
    title: "Create Dose for Patient",
    url: "https://www.youtube.com/watch?v=QRJqwwMLXJ4",
    keywords: [""],
  },
  {
    id: 21,
    title: "Create Purchase Request",
    url: "https://www.youtube.com/watch?v=hfywVKDQfmE",
    keywords: [""],
  },
  {
    id: 22,
    title: "TC Add Product Inventory",
    url: "https://www.youtube.com/watch?v=2RqTOvgz8HQ",
    keywords: [""],
  },
  {
    id: 23,
    title: "TC Dosing History",
    url: "https://www.youtube.com/watch?v=xFalVjmxKyo",
    keywords: [""],
  },
];

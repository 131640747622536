import axios from "axios";
import toast from "react-hot-toast";
import moment from "moment";
import Badge from "react-bootstrap/Badge";
import CryptoJS from "crypto-js";
import { decode as base64_decode, encode as base64_encode } from "base-64";

//Sanitization function
//Use case: sanitizeFunction = (input.value, input.name)
//make sure onClick and onChange both handlers are set to handleChange on input
export const sanitizeFunction = (value, input_name) => {
  // Updated regex to include '+'
  const conditionRegex = /([^a-zA-Z0-9:+\-\s.&,])/g;

  const field_values = [
    "password",
    "email",
    "primary_email",
    "secondary_email",
    // "weight",
    // "height",
  ];

  if (!field_values.includes(input_name)) {
    if (conditionRegex.test(value)) {
      toast.error("Please Use A-Z, a-z, 0-9, + only!");
      return false;
    } else {
      return true;
    }
  }
};

/*
export const sanitizeFunction = (value, input_name) => {
  const conditionRegex = /([^a-zA-Z0-9:\-\s])/g;
  const field_values = [
    "password",
    "email",
    "primary_email",
    "secondary_email",
  ];
  if (!field_values.includes(input_name)) {
    if (conditionRegex.test(value)) {
      toast.error("Please Use A-Z, a-z, 0-9 only!");
      return false;
    } else {
      return true;
    }
  }
};
*/

//Error Logging function
// Use case : errorLogFunction = (error, page, route);
export const errorLogFunction = (errorInfo, page, route) => {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/elog/add`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: {
      error_log: JSON.stringify(errorInfo),
      page: page,
      route: route,
    },
  };
  axios(config)
    .then(function (response) {})
    .catch(function (error) {});
};

//Encrypt Data
export const encryptData = (plain_text) => {
  //console.log(plain_text);
  //console.log(process.env.REACT_APP_SECRET_PASS);
  // const encryptedData = CryptoJS.AES.encrypt(
  //   plain_text,
  //   process.env.REACT_APP_SECRET_PASS
  // ).toString();
  const encryptedData = base64_encode(plain_text);
  return encryptedData;
};

//Decrypt Data
export const decryptData = (enc_text) => {
  // const bytes = CryptoJS.AES.decrypt(
  //   enc_text,
  //   process.env.REACT_APP_SECRET_PASS
  // );
  // const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const decryptedData = base64_decode(enc_text)?.replace(/(.{4})(?=.)/g, "$1-");
  return decryptedData;
};

export const maskAadhaarString = (str) => {
  return str.replace(/^(\d{4})-(\d{4})-(\d{4})$/, "XXXX-XXXX-$3");
};

// export const dateFormatter = (date) => {
//   const date_conv = new Date(date);
//   const fullyear = date_conv.getFullYear();
//   const month = date_conv.getMonth() + 1;
//   const day = date_conv.getDate();
//   return `${day}/${month}/${fullyear}`;
// };

export const dateFormatter = (date) => {
  const formattedDate = moment(date).format("DD/MM/YYYY");
  // const formattedDate = moment(date, "DD/MM/YYYY")
  //   .utc()
  //   .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  return formattedDate;
};

export const expiryFormatter = (date) => {
  const date_conv = new Date(date);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date_conv.getMonth()];
  const year = date_conv.getFullYear();
  return `${month} ${year}`;
};

export const customEncrypt = (password) => {
  const encrypted_pass = window.btoa(
    `${password}-${process.env.REACT_APP_SECRET_PASS}`
  );
  return encrypted_pass;
};

export const _calculateAge = (birthday) => {
  const today = new Date();
  const birthDate = new Date(birthday);

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};

export const _checkIfLatestRecord = (record) => {
  const currentTime = new Date();
  const recordTimestamp = new Date(record);
  //console.log(currentTime, recordTimestamp);
  const timeDifference = Math.abs(currentTime - recordTimestamp);
  //console.log(timeDifference);
  const timeDifferenceInHours = Math.floor(timeDifference / (1000 * 60 * 60));
  // console.log(timeDifferenceInHours);
  const isRecent = timeDifferenceInHours < 1;
  //console.log(isRecent);
  return timeDifferenceInHours;
};

export const _calculateDaysLeft = (date) => {
  // Convert the given date string to a Date object
  const givenDate = new Date(date);

  // Calculate the difference between the current date and the given date in milliseconds
  const timeDiff = givenDate.getTime() - new Date().getTime();

  // Convert milliseconds to days and round down to the nearest integer
  const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

  // Update the state with the number of days left
  //setDaysLeft(days);
  if (days > 0) return <Badge bg="success">( {days} ) days left</Badge>;
  if (days < 0) return <Badge bg="danger">Expired</Badge>;
};

export const _checkCalculateDaysLeft = (date) => {
  // Convert the given date string to a Date object
  const givenDate = new Date(date);

  // Calculate the difference between the current date and the given date in milliseconds
  const timeDiff = givenDate.getTime() - new Date().getTime();

  // Convert milliseconds to days and round down to the nearest integer
  const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

  // Update the state with the number of days left
  //setDaysLeft(days);
  if (days > 0) return days;
  if (days < 0) return "Expired";
};

export const convertDate = (isoDateString) => {
  // const isoDateString = "2023-02-07T05:19:05";
  const date = new Date(isoDateString);

  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
};

import React from "react";
import { NavLink } from "react-router-dom";

const SupplierMenu = ({ setExpandedMenu, expandedMenu }) => {
  return (
    <li className="side-nav-item">
      <div
        onClick={() =>
          setExpandedMenu({
            ...expandedMenu,
            suppliers: !expandedMenu.suppliers,
          })
        }
        style={{ cursor: "pointer" }}
        className="side-nav-link"
      >
        <i className="mdi mdi-briefcase-account-outline" />
        <span> Suppliers </span>
        {!expandedMenu.suppliers && <span className="menu-arrow" />}
        {expandedMenu.suppliers && (
          <span className="menu-arrow" style={{ transform: "rotate(90deg)" }} />
        )}
      </div>
      <div
        className={`${expandedMenu.suppliers ? "collapse show" : "collapse"}`}
        id="sidebarSupPages"
      >
        <ul className="side-nav-second-level">
          <li>
            <NavLink to="/suppliers">Supplier List</NavLink>
          </li>
          <li>
            <NavLink to="/supplier-add">Add Supplier</NavLink>
          </li>
        </ul>
      </div>
    </li>
  );
};

export default SupplierMenu;

import { useEffect, useState, useRef } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import Button from "react-bootstrap/Button";
import { BsFileEarmarkPlus } from "react-icons/bs";
import toast from "react-hot-toast";
import axios from "axios";
// import AddDeleteTableRows from "../../../components/Medicine/AddDeleteTableRows";

const ViewDischargeChild = ({
  visitData,
  formData,
  setFormData,
  handleChange,
}) => {
  const [editable, setEditable] = useState(false);

  const handleSubmit = (e) => {
    //alert(JSON.stringify(formData));

    if (!formData.discharged_date) {
      toast.error("Please select the discharge date");
      return;
    }

    if (!formData.procedure_or_surgery_performed) {
      toast.error("Please detail procedure/surgery.");
      return;
    }

    if (!formData.treatment_advice_on_discharge) {
      toast.error("Please detail treatment advice");
      return;
    }

    if (!formData.followup_instruction) {
      toast.error("Please detail followup instructions");
      return;
    }

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/visits/create-discharge/${formData._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        toast.success("Discharge updated sucessfully");
        setEditable(false);
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data.message));
        console.log(error);
      });
  };

  return (
    <div className="newPatientFormOnly ">
      <fieldset className="border p-2 w-100">
        <legend className="float-none w-auto p-2 fs-5">
          <i
            className="mdi mdi-square-edit-outline"
            style={{ fontSize: "18px", color: "grey", cursor: "pointer" }}
            onClick={() => setEditable(!editable)}
          ></i>{" "}
          Discharge Details
        </legend>
        <div className="ms-4 me-4">
          {editable && (
            <div className="patientUpdateButton">
              <Button variant="success" size="medium" onClick={handleSubmit}>
                <BsFileEarmarkPlus /> Save Discharge
              </Button>
            </div>
          )}
          <div className="newPatientFormOnly ">
            <div className="newPatientItem">
              <label htmlFor="date_of_admission">Date of Admission</label>
              <DatePicker
                value={moment(formData.date_of_visit)}
                className="newPatientSelect datePicker"
                name="discharged_date"
                disabled
              />
            </div>
            <div className="newPatientItem">
              <label htmlFor="date_of_visit">Date & Time of Discharge</label>
              <DatePicker
                className="newPatientSelect datePicker"
                name="discharged_date"
                showTime
                onChange={(date, dateString) => {
                  setFormData({
                    ...formData,
                    discharged_date: date,
                  });
                }}
                disabledDate={(current) => {
                  return (
                    moment(formData.date_of_visit).add(1, "days") >= current
                  );
                }}
                value={moment(formData.discharged_date)}
                disabled={!editable}
              />
            </div>
          </div>
          <div className="newPatientItem w-100">
            <label htmlFor="weight">History of past illness</label>
            <textarea
              className="form-control"
              name="history_of_past_illness"
              placeholder="Details..."
              rows={2}
              value={formData?.history_of_past_illness}
              disabled={!editable}
              onChange={handleChange}
            />
          </div>
          <div className="newPatientItem w-100">
            <label htmlFor="weight">Procedure or Surgery performed</label>
            <textarea
              className="form-control"
              name="procedure_or_surgery_performed"
              placeholder="Details..."
              rows={2}
              value={formData?.procedure_or_surgery_performed}
              disabled={!editable}
              onChange={handleChange}
            />
          </div>
          <div className="newPatientItem w-100">
            <label htmlFor="weight">Significant findings</label>
            <textarea
              className="form-control"
              name="significant_findings"
              placeholder="Details..."
              rows={2}
              value={formData?.significant_findings}
              disabled={!editable}
              onChange={handleChange}
            />
          </div>
          <div className="newPatientItem w-100">
            <label htmlFor="weight">Treatment advice on discharge</label>
            <textarea
              className="form-control"
              name="treatment_advice_on_discharge"
              placeholder="Details..."
              rows={2}
              value={formData?.treatment_advice_on_discharge}
              disabled={!editable}
              onChange={handleChange}
            />
          </div>
          <div className="newPatientItem w-100">
            <label htmlFor="weight">Followup Instructions</label>
            <textarea
              className="form-control"
              name="followup_instruction"
              placeholder="Details..."
              rows={3}
              value={formData?.followup_instruction}
              disabled={!editable}
              onChange={handleChange}
            />
          </div>
        </div>
        <br />
      </fieldset>
      {/* <fieldset className="border p-2 w-100">
        <legend className="float-none w-auto p-2 fs-5">Treatment Given</legend>
        <div className="ms-4 me-4">
          <div className="newPatientItem w-100">
            <AddDeleteTableRows
              formData={visitData}
              setFormData={setFormData}
              editable={editable}
            />
          </div>
        </div>
      </fieldset> */}
    </div>
  );
};

export default ViewDischargeChild;

import React from "react";
import Select from "react-select";

const WFHBleedPainScoreTab = ({
  handleChangeValue,
  dropDownChange,
  formData,
}) => {
  const dropDown01 = [
    {
      id: 1,
      name: "0",
      value: 0,
    },
    {
      id: 2,
      name: "1",
      value: 1,
    },
    {
      id: 3,
      name: "2",
      value: 2,
    },
    {
      id: 1,
      name: "3",
      value: 3,
    },
  ];
  const dropDown03 = [
    {
      id: 1,
      name: "1",
      value: 1,
    },
    {
      id: 2,
      name: "2",
      value: 2,
    },
    {
      id: 3,
      name: "3",
      value: 3,
    },
  ];

  const BleedAndPain = {
    bleedscore_left: [
      "ankle_left_bleed_score",
      "elbow_left_bleed_score",
      "knee_left_bleed_score",
    ],
    bleedscore_right: [
      "ankle_right_bleed_score",
      "elbow_right_bleed_score",
      "knee_right_bleed_score",
    ],
    painscore_left: ["ankle_left_pain", "elbow_left_pain", "knee_left_pain"],
    painscore_right: [
      "ankle_right_pain",
      "elbow_right_pain",
      "knee_right_pain",
    ],
  };

  return (
    <div className="table-responsive w-100" style={{ marginRight: "40px" }}>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col" className="text-center">
              Ankle
            </th>
            <th scope="col" className="text-center">
              Elbow
            </th>
            <th scope="col" className="text-center">
              Knee
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colSpan={4}>BLEED SCORE</th>
          </tr>
          <tr>
            <td className="table-row-label">Left</td>

            {BleedAndPain.bleedscore_left.map((bs_left, index) => (
              <td className="table-row-options">
                <DropDownMenu
                  fieldName={bs_left}
                  handleChangeValue={handleChangeValue}
                  dropDownChange={dropDownChange}
                  defvalue={formData[BleedAndPain.bleedscore_left[index]]}
                  dropDownValues={dropDown01}
                />
              </td>
            ))}
          </tr>
          <tr>
            <td className="table-row-label">Right</td>
            {BleedAndPain.bleedscore_right.map((bs_right, index) => (
              <td className="table-row-options">
                <DropDownMenu
                  fieldName={bs_right}
                  handleChangeValue={handleChangeValue}
                  dropDownChange={dropDownChange}
                  defvalue={formData[BleedAndPain.bleedscore_right[index]]}
                  dropDownValues={dropDown01}
                />
              </td>
            ))}
          </tr>
          <tr>
            <td colSpan={4}>
              0: 0 Year <br />
              1: No major bleeds, 1-3 minor
              <br />
              2: 1-2 major/yr or 4-6 minor
              <br />
              3: ≥3 major/yr or ≥7 minor
            </td>
          </tr>
          <tr>
            <th colSpan="4">PAIN</th>
          </tr>
          <tr>
            <td className="table-row-label">Left</td>

            {BleedAndPain.painscore_left.map((ps_left, index) => (
              <td className="table-row-options">
                <DropDownMenu
                  fieldName={ps_left}
                  handleChangeValue={handleChangeValue}
                  dropDownChange={dropDownChange}
                  defvalue={formData[BleedAndPain.painscore_left[index]]}
                  dropDownValues={dropDown03}
                />
              </td>
            ))}
          </tr>
          <tr>
            <td className="table-row-label">Right</td>

            {BleedAndPain.painscore_right.map((ps_right, index) => (
              <td className="table-row-options">
                <DropDownMenu
                  fieldName={ps_right}
                  handleChangeValue={handleChangeValue}
                  dropDownChange={dropDownChange}
                  defvalue={formData[BleedAndPain.painscore_right[index]]}
                  dropDownValues={dropDown03}
                />
              </td>
            ))}
          </tr>
          <tr>
            <td colSpan="4">
              1: slight pain on activity, no problem with daily activity or
              daily living
              <br />
              2: mod pain on activity,(uses nonnarcotic analgesics occasionally)
              <br />
              3: Pain limiting normal activity(narcotic analgesics needed
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default WFHBleedPainScoreTab;

const DropDownMenu = ({
  fieldName,
  dropDownChange,
  dropDownValues,
  defvalue,
  key,
}) => {
  const dropDownOptions = dropDownValues.map((ddval) => ({
    value: ddval.value,
    label: ddval.name,
  }));

  return (
    <div className="newPatientItem">
      <Select
        name={fieldName}
        onChange={dropDownChange}
        value={dropDownOptions.find((v) => v.value === parseInt(defvalue))}
        className="newPatientSelect"
        options={dropDownOptions}
        isDisabled={true}
      />
    </div>
  );
};

import { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import AlertDialog from "../../../components/InfoDialog/AlertDialog";
import PatientInfoBlock from "../../../components/PatientProfile/PatientInfoBlock";
import moment from "moment";
import { DatePicker, TimePicker } from "antd";
import { visitReasons, visitTypes } from "../../../components/Data/ChartData";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import BackButton from "../../../components/Buttons/BackButton";

const UpdateAppointmentChild = ({
  patientRecordId,
  patientProfileData,
  appointment,
}) => {
  dayjs.extend(customParseFormat);
  let navigate = useNavigate();
  const [patientProfile, setPatientProfile] = useState(patientProfileData);
  const [formData, setFormData] = useState({
    appointment_date: appointment.appointment_date,
    appointment_time: appointment.appointment_time,
    appointment_type: appointment.appointment_type,
    visit_type: appointment.visit_type,
    appointment_status: appointment.appointment_status,
    appointment_notes: appointment.appointment_notes,
  });
  const childRef = useRef();

  //Error Logging Service
  const [{ errorLogApi, dateFormatter }] = useAtom(gbState);

  const updateAppointment = (e) => {
    e.preventDefault();

    if (
      !formData.appointment_date ||
      !formData.appointment_time ||
      !formData.appointment_type
    ) {
      return toast.error("Please fill the details before Creating appointment");
    }

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/appointment/update/${appointment._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        toast.success("Appointment Updated Successfully.");
        navigate("/appointmentlist", { state: patientProfileData });
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message);
        errorLogApi(error, "UpdateAppointment", "/appointment/update/");
      });
  };

  return (
    <form onSubmit={updateAppointment}>
      <div className="patientUpdateButton">
        <Button variant="primary" size="large" type="submit">
          Update Appointment
        </Button>{" "}
        &nbsp;&nbsp;
        <BackButton />
      </div>
      <AlertDialog ref={childRef} title="" />
      <div className="mb-2">
        <PatientInfoBlock selectedPatientProfile={patientProfile} />
      </div>
      {/* {JSON.stringify(formData)} */}
      <fieldset className="border p-2 w-100">
        <legend className="float-none w-auto p-2 fs-5">
          Create Appointment
        </legend>
        <div className="newPatientFormOnly">
          <FormElement formData={formData} setFormData={setFormData} />
        </div>
      </fieldset>
    </form>
  );
};

export default UpdateAppointmentChild;

const FormElement = ({ formData, setFormData }) => {
  //Error Logging Service
  const [{ errorLogApi }] = useAtom(gbState);
  const [vTypeOptions, setVTypeOptions] = useState(true);
  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };
  const visitTypeInput1 = useRef();
  const appointTypeOptions = [
    { value: 1, label: "Consultation" },
    { value: 2, label: "Drug Infusion" },
    { value: 3, label: "Physiotherapy" },
  ];

  const visitOptions = visitReasons.map((visit) => ({
    value: visit.id,
    label: visit.reason,
  }));

  const appointmentStatusOptions = [
    { value: 1, label: "Pending" },
    { value: 2, label: "Cancelled" },
    { value: 3, label: "Re Schedule" },
  ];

  useEffect(() => {
    let vObject = JSON.parse(formData?.appointment_type);
    if (vObject?.value === 2) {
      setVTypeOptions(false);
    } else {
      setFormData({
        ...formData,
        visit_type: null,
      });
      setVTypeOptions(true);
    }
  }, [formData?.appointment_type]);

  function handleDatePickerChange(time, timeString) {
    setFormData({
      ...formData,
      appointment_time: timeString,
    });
  }

  return (
    <div className="newPatientFormOnly m-5">
      {/* {JSON.stringify(formData)} */}
      <div className="newPatientItem">
        <label htmlFor="appointment_date">Appointment Date & Time</label>
        <DatePicker
          className="newPatientSelect datePicker"
          style={{ paddingLeft: "10px" }}
          name="appointment_date"
          onChange={(date, dateString) => {
            setFormData({
              ...formData,
              appointment_date: dateString,
            });
          }}
          disabled
          value={moment(formData.appointment_date, "YYYY-MM-DD")}
          showToday={true}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="appointment_time">Appointment Time</label>
        <TimePicker
          type="time"
          format="h:mm A"
          use12Hours={true}
          showNow={false}
          name="appointment_time"
          className="newPatientSelect datePicker"
          onChange={handleDatePickerChange}
          defaultValue={dayjs(formData?.appointment_time, "h:mm A")}
          disabled={formData?.appointment_date ? false : true}
        />
      </div>
      <div className="newPatientItem">
        <label>Appointment Type </label>
        <Select
          name="appointment_type"
          onChange={(selected, dropdown) => {
            setFormData({
              ...formData,
              appointment_type: JSON.stringify(selected),
            });
          }}
          className="newPatientSelect"
          options={visitOptions}
          value={
            formData?.appointment_type && JSON.parse(formData?.appointment_type)
          }
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
        />
      </div>
      <div className="newPatientItem">
        <label>Visit Type</label>
        <Select
          name="visit_type"
          onChange={(selected, dropdown) => {
            setFormData({
              ...formData,
              visit_type: JSON.stringify(selected),
            });
          }}
          className="newPatientSelect"
          options={visitTypes}
          ref={visitTypeInput1}
          isDisabled={vTypeOptions}
          value={formData?.visit_type && JSON.parse(formData?.visit_type)}
        />
      </div>

      <div className="newPatientItem">
        <label>Appointment Status </label>
        <Select
          name="appointment_status"
          onChange={(selected, dropdown) => {
            setFormData({
              ...formData,
              appointment_status: JSON.stringify(selected),
            });
          }}
          className="newPatientSelect"
          options={appointmentStatusOptions}
          value={
            formData?.appointment_status &&
            JSON.parse(formData?.appointment_status)
          }
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
        />
      </div>
      <div className="newPatientItem" style={{ width: "100%" }}>
        <label htmlFor="appointment_notes">Notes/Description</label>
        <textarea
          type="text"
          name="appointment_notes"
          style={{ borderColor: "lightgray", width: "100%" }}
          placeholder="Other than selection"
          rows={3}
          value={formData?.appointment_notes && formData?.appointment_notes}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

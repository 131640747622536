import React, { useContext, useState, useEffect } from "react";
import { Button, Form, Container, Row, Col, Card } from "react-bootstrap";
import Select from "react-select";
//import "./ProductsList.css";
import axios from "axios";
import indianCities from "../../../components/Data/IndianCities";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

//Context import
import AuthContext from "../../../components/shared/Context";

const AddProductChild = () => {
  const { loggedInUserDetails } = useContext(AuthContext);
  let navigate = useNavigate();
  const [productCategory, setProductCategory] = useState();
  const [selectedProductCategory, setSelectedProductCategory] = useState();
  const [productSubCategoryList, setProductSubCategoryList] = useState();
  const [prodSubCatListOptions, setProdSubCatListOptions] = useState();

  const [formData, setFormData] = useState({
    product_name: null,
    category_id: null,
    sub_category: null,
    measuring_unit: null,
    storage_unit: null,
    minimum_alert_qty: 100,
    company_name: "Generic",
    contact_number: null,
    company_email: null,
    comments: null,
    state: null,
  });

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  useEffect(() => {
    const filteredList = productSubCategoryList?.filter(
      (item) => item?.category_id?._id === selectedProductCategory?.value
    );
    setProdSubCatListOptions(
      filteredList?.map((item) => ({ value: item._id, label: item.label }))
    );
  }, [selectedProductCategory]);

  useEffect(() => {
    const getDataFromServer = () => {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };

      let urlLink = `${process.env.REACT_APP_API_URL}/dcat/`;

      axios
        .get(urlLink, config)
        .then((res) => {
          const responseData = res?.data?.categories;
          //  console.log(responseData);
          const diagnosesData = responseData.map((c) => ({
            ...c,
            value: c._id,
            label: c.category,
          }));
          setProductCategory(diagnosesData);
        })
        .catch((err) => console.log("error", err));
    };

    getDataFromServer();
  }, []);

  // Getting Diagnosis from server
  useEffect(() => {
    const getDataFromServer = () => {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };

      let urlLink = `${process.env.REACT_APP_API_URL}/diagnosis/`;

      axios
        .get(urlLink, config)
        .then((res) => {
          const olDdiagnosesData = res.data.diagnoses;
          // console.log(olDdiagnosesData);
          const res_diagnosesData = olDdiagnosesData.map((d) => ({
            ...d,
            cat_name: d?.category_id?.category,
          }));
          setProductSubCategoryList(res_diagnosesData);
        })
        .catch((err) => console.log("error", err));
    };

    getDataFromServer();
  }, []);

  const checkValidation = ["storage_unit"];

  const addProduct = (e) => {
    e.preventDefault();

    if (!formData?.category_id) {
      toast.error("Please select a Category Unit!");
      return;
    }

    if (!formData.measuring_unit) {
      toast.error("Please select a Measuring Unit!");
      return;
    }

    if (!formData?.storage_unit) {
      toast.error("Please select a Storage Unit!");
      return;
    }

    if (loggedInUserDetails.role === "FULL_ACCESS" && !formData.state) {
      toast.error("Please select a State");
      return;
    }

    let userToken = localStorage.getItem("token");
    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/product/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        //localStorage.setItem("sob", JSON.stringify(formData));

        // setTabState("products");
        toast.success("Product Saved Successfully.");

        navigate("/products");
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message);
        console.log(error);
      });
  };

  const statesSet = new Set(
    indianCities.map((city, index) => {
      return city.state;
    })
  );

  const states = [...statesSet];

  const stateOptions = states
    .sort()
    .map((state) => ({ value: state, label: state }));

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col md={8} lg={6}>
          <Card className="mt-4 mb-4">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h2>Add Products</h2>
              <div>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => navigate(-1)}
                  className="me-2"
                >
                  Back
                </Button>
                <Button
                  variant="primary"
                  size="sm"
                  type="submit"
                  form="addProductForm"
                >
                  Save
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <Form id="addProductForm" onSubmit={(e) => addProduct(e)}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Product Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="product_name"
                    onChange={handleChange}
                    value={formData.product_name}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Product Category <span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    name="category_id"
                    onChange={(selected) => {
                      setFormData({
                        ...formData,
                        category_id: selected.value,
                      });
                      setSelectedProductCategory(selected);
                    }}
                    options={productCategory}
                    value={
                      formData?.category_id
                        ? productCategory?.find(
                            (cat) => cat._id === formData?.category_id
                          )
                        : null
                    }
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Product Sub Category <span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    name="sub_category"
                    isMulti
                    onChange={(selectedOptions) => {
                      setFormData({
                        ...formData,
                        sub_category: JSON.stringify(selectedOptions),
                      });
                    }}
                    options={prodSubCatListOptions}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Measuring Unit <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="measuring_unit"
                    onChange={handleChange}
                    value={formData.measuring_unit}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Storage Unit <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="storage_unit"
                    onChange={handleChange}
                    value={formData.storage_unit}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Minimum Qty (Inventory Alert){" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="minimum_alert_qty"
                    onChange={handleChange}
                    value={formData.minimum_alert_qty}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="company_name"
                    onChange={handleChange}
                    value={formData.company_name}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Company Contact</Form.Label>
                  <Form.Control
                    type="text"
                    name="contact_number"
                    onChange={handleChange}
                    value={formData.contact_number}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Company Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="company_email"
                    onChange={handleChange}
                    value={formData.company_email}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Comments</Form.Label>
                  <Form.Control
                    type="text"
                    name="comments"
                    onChange={handleChange}
                    value={formData.comments}
                  />
                </Form.Group>

                {loggedInUserDetails.role === "FULL_ACCESS" && (
                  <Form.Group className="mb-3">
                    <Form.Label>State</Form.Label>
                    <Select
                      name="state"
                      onChange={(selected) => {
                        setFormData({
                          ...formData,
                          state: selected.label,
                        });
                      }}
                      options={stateOptions}
                      isDisabled={false}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </Form.Group>
                )}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AddProductChild;

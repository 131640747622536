import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import SiteOfBleedTab from "./SiteOfBleedTab";
//import "bootstrap/dist/css/bootstrap.min.css";

import JointScores from "./JointScores/JointScores";
import THistory from "./THistory";
import ChronicComplicationTab from "./ChronicComplicationTab";
import InvestigationTab from "./InvestigationTab";
import TreatmentAdviceTab from "./TreatmentAdviceTab";
import MriScoreTab from "./MriScoreTab";
import XrayScores from "./XrayScores";
import UltraSoundScores from "./UltraSoundScores";
import UploadReports from "./UploadReports";
import CbcProfileTab from "./Thalassemia/CbcProfileTab";
import EchoProfileTab from "./Thalassemia/EchoProfileTab";
import BiochemTab from "./Thalassemia/BiochemTab";
import HbElectrophoresis from "./Thalassemia/HbElectrophoresis";
import EndocrineProfileTab from "./Thalassemia/EndocrineProfileTab";
import ViralMarkersTab from "./Thalassemia/ViralMarkersTab";
import FibroscanTab from "./FibroscanTab";
import MRIT2StarTab from "./Thalassemia/MRIT2StarTab";
import DexaTab from "./Thalassemia/DexaTab";
import ChronicComplicationThalassemiaTab from "./Thalassemia/ChronicComplicationThalassemiaTab";

const styles = {
  tab: {
    marginRight: "20px", // Adjust the right margin to increase space
  },
};

const PrimaryEMRMenu = ({
  emrRecordId,
  handleChangeTabs,
  value,
  TabPanel,
  setValue,
  tabState,
  emrRecord,
  setEmrRecord,
}) => {
  return (
    <Box sx={{ width: "100%", marginLeft: "20px" }}>
      <Box
        sx={{
          maxWidth: { xs: 300, sm: "90%" },
          bgcolor: "background.paper",
        }}
      >
        {/* {JSON.stringify(tabState)} */}
        <Tabs
          value={value}
          onChange={handleChangeTabs}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {!tabState && (
            <Tab label="Bio Chemistry" value={10} style={styles.tab} />
          )}
          {!tabState && (
            <Tab label="CBC Profile" value={11} style={styles.tab} />
          )}
          {!tabState && (
            <Tab label="ECHO Profile" value={12} style={styles.tab} />
          )}
          {!tabState && <Tab label="HPLC" value={13} style={styles.tab} />}
          {!tabState && (
            <Tab label="Chronic Complication " value={19} style={styles.tab} />
          )}
          {!tabState && (
            <Tab label="Endocrine Profile" value={14} style={styles.tab} />
          )}
          {!tabState && (
            <Tab label="Viral Markers" value={15} style={styles.tab} />
          )}
          {!tabState && <Tab label="MRI T2* " value={17} style={styles.tab} />}
          {!tabState && <Tab label="Dexa " value={18} style={styles.tab} />}

          {tabState && (
            <Tab label="Site of Bleeds" value={0} style={styles.tab} />
          )}
          {tabState && (
            <Tab label="Joint Bleed Scores" value={1} style={styles.tab} />
          )}

          {tabState && (
            <Tab label="Treatment History" value={2} style={styles.tab} />
          )}
          {tabState && <Tab label="Fibroscan" value={16} style={styles.tab} />}
          {tabState && (
            <Tab label="Chronic Complications" value={3} style={styles.tab} />
          )}
          {tabState && (
            <Tab label="Investigations" value={4} style={styles.tab} />
          )}

          {tabState && <Tab label="MRI Scores" value={6} style={styles.tab} />}
          {tabState && <Tab label="Xray Scores" value={7} style={styles.tab} />}
          {tabState && (
            <Tab label="Ultrasound Scores" value={8} style={styles.tab} />
          )}
          <Tab label="Upload Files" value={9} />
        </Tabs>
      </Box>

      <div className="m-20 p-20">
        <TabPanel value={value} index={0}>
          <SiteOfBleedTab
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.site_of_bleeds}
            setEmrRecord={setEmrRecord}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <JointScores emrRecordId={emrRecordId} />
        </TabPanel>
        <TabPanel
          value={value}
          index={2}
          // onClick={() => alert("this will reload")}
        >
          <THistory
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.treatment_history}
            setEmrRecord={setEmrRecord}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ChronicComplicationTab
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.chronic_complications}
            setEmrRecord={setEmrRecord}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <InvestigationTab
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.investigation_sheet}
            setEmrRecord={setEmrRecord}
          />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <MriScoreTab
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.mriscan_score}
            setEmrRecord={setEmrRecord}
          />
        </TabPanel>
        <TabPanel value={value} index={7}>
          <XrayScores
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.xray_score}
            setEmrRecord={setEmrRecord}
          />
        </TabPanel>
        <TabPanel value={value} index={8}>
          <UltraSoundScores
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.ultrasound}
            setEmrRecord={setEmrRecord}
          />
        </TabPanel>
        <TabPanel value={value} index={9}>
          <UploadReports
            emrRecordId={emrRecordId}
            deleteStatus={emrRecord?.delete_status}
            setEmrRecord={setEmrRecord}
          />
        </TabPanel>
        <TabPanel value={value} index={10}>
          <BiochemTab
            emrRecordId={emrRecordId}
            changeTab={setValue}
            emrRecord={emrRecord && emrRecord.biochemistry}
            setEmrRecord={setEmrRecord}
          />
        </TabPanel>
        <TabPanel value={value} index={11}>
          <CbcProfileTab
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.cbc_profile}
            setEmrRecord={setEmrRecord}
          />
        </TabPanel>
        <TabPanel value={value} index={12}>
          <EchoProfileTab
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.echo_profile}
            setEmrRecord={setEmrRecord}
          />
        </TabPanel>
        <TabPanel value={value} index={13}>
          <HbElectrophoresis
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.hb_electrophoresis}
            setEmrRecord={setEmrRecord}
          />
        </TabPanel>
        <TabPanel value={value} index={14}>
          <EndocrineProfileTab
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.endocrine_profile}
            setEmrRecord={setEmrRecord}
          />
        </TabPanel>
        <TabPanel value={value} index={15}>
          <ViralMarkersTab
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.viral_markers}
            setEmrRecord={setEmrRecord}
          />
        </TabPanel>
        <TabPanel value={value} index={16}>
          <FibroscanTab
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.fibroscan}
            setEmrRecord={setEmrRecord}
          />
        </TabPanel>
        <TabPanel value={value} index={17}>
          <MRIT2StarTab
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.mri_t2_star}
            setEmrRecord={setEmrRecord}
          />
        </TabPanel>
        <TabPanel value={value} index={18}>
          <DexaTab
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.dexa}
            setEmrRecord={setEmrRecord}
          />
        </TabPanel>
        <TabPanel value={value} index={19}>
          <ChronicComplicationThalassemiaTab
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.chronic_complication_thalassemia}
            setEmrRecord={setEmrRecord}
          />
        </TabPanel>
      </div>
    </Box>
  );
};

export default PrimaryEMRMenu;

import "./CreateTC.css";
import React from "react";
import MainContentCard from "../../Home/MainContentCard";

import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

import CreateTCChild from "./CreateTCChild";

const CreateTC = () => {
  return (
    <MainContentCard>
      {/* <SideBar /> */}
      <ErrorBoundaryRoutes route="/tcenters/add" page="CreateTCChild">
        <CreateTCChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default CreateTC;

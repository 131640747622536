import React from "react";

import MainContentCard from "../../Home/MainContentCard";
import CreateDoctorChild from "./CreateDoctorChild";

import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

const CreateDoctor = () => {
  return (
    <MainContentCard>
      {/* <Topbar />
      <div className="parent-container">
        <SideBar /> */}
      <ErrorBoundaryRoutes page="CreateDoctorChild" route="/patients">
        <CreateDoctorChild />
      </ErrorBoundaryRoutes>
      {/* </div> */}
    </MainContentCard>
  );
};

export default CreateDoctor;

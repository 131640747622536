import React from "react";
import { useLocation } from "react-router-dom";
import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

import AddAppointmentChild from "./AddAppointmentChild";

const AddAppointment = () => {
  const { state } = useLocation();
  let patientRecordId = state.id;
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="CreateAppointment" route="/addappointment">
        <AddAppointmentChild
          patientRecordId={patientRecordId}
          patientProfileData={state}
        />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default AddAppointment;

import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const MRIT2StarTab = ({ emrRecordId, emrRecord, setEmrRecord }) => {
  const [formData, setFormData] = useState({
    liver_t2_star: null,
    liver_r2: null,
    liver_lic: null,
    cardiac_t2_star: null,
    cardiac_r2: null,
    cardiac_mic: null,
  });

  useEffect(() => {
    setFormData({ ...formData, ...emrRecord });
  }, [emrRecord]);

  const mrit2starKeys = Object.keys(formData);

  const handleChange = ({ currentTarget: input }) => {
    //alert([input.name]);
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const mrit2starLabels = [
    "T2* (Liver)",
    "R2 (Liver)",
    "LIC (Liver)",
    "T2* (Cardiac)",
    "R2 (Cardiac)",
    "MIC (Cardiac)",
  ];
  const measuringUnit = ["secs", "sec-1", "mg/gm", "secs", "sec-1", "mr/gm"];

  const updateEmr = () => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/emr/update/${emrRecordId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, mri_t2_star: true },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        localStorage.setItem(
          `${emrRecordId}-mrit2star`,
          JSON.stringify(formData)
        );
        toast.success("Endocrine sheet updated Successfully.");
        setEmrRecord(response.data.emr);
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error.response.data));
      });
  };
  return (
    <div className="newPatientFormOnly">
      {/* {JSON.stringify(biochemKeys)} */}
      {/* {JSON.stringify(formData)} */}
      <div className="w-100 mt-2 text-end pe-5">
        <Button
          variant="primary"
          size="large"
          onClick={updateEmr}
          className="btn btn-primary float-right"
        >
          Save
        </Button>
      </div>
      {mrit2starKeys.map((key, index) => (
        <InputField
          handleChange={handleChange}
          fieldname={key}
          formData={formData}
          index={index}
          measuringUnit={measuringUnit}
          endocrineLabels={mrit2starLabels}
        />
      ))}
    </div>
  );
};

export default MRIT2StarTab;

export const InputField = ({
  handleChange,
  fieldname,
  formData,
  index,
  measuringUnit,
  endocrineLabels,
}) => {
  function capitalize(s) {
    return s && s[0].toUpperCase() + s.slice(1);
  }

  return (
    <div className="newPatientItem">
      <label htmlFor={fieldname}>
        {/* {capitalize(fieldname.replaceAll("_", " ")).toUpperCase()} */}
        {endocrineLabels[index]}
      </label>
      {/* <input
          type="number"
          name={fieldname}
          onChange={handleChange}
          value={formData[fieldname]}
          placeholder={measuringUnit[index]}
        /> */}
      <InputGroup className="mb-3">
        <Form.Control
          aria-label={fieldname}
          type="number"
          name={fieldname}
          onChange={handleChange}
          value={formData[fieldname]}
        />
        {measuringUnit[index] && (
          <InputGroup.Text id="basic-addon2">
            {measuringUnit[index]}
          </InputGroup.Text>
        )}
      </InputGroup>
    </div>
  );
};

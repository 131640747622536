import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import indianCities from "../../components/Data/IndianCities";
import Badge from "react-bootstrap/Badge";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loggedInUserDetails, setloggedInUserDetails] = useState({});
  const [selectedDiagnosis, setSelectedDiagnosis] = useState({});
  const [selectedPatientProfile, setSelectedPatientProfile] = useState({});
  const [patientDiagnosis, setPatientDiagnosis] = useState("");
  const [showFooterLogo, setShowFooterLogo] = useState(true);
  const [globalSearchText, setGlobalSearchText] = useState(null);
  const [expandedMenu, setExpandedMenu] = useState({
    patients: false,
    reports: false,
    suppliers: false,
    purchase_requests: false,
    inventory: false,
    stateinventory: false,
    dashboard: false,
  });

  let userToken = localStorage.getItem("token");
  let navigate = useNavigate();

  // if (userToken) {
  //   setloggedInUserDetails(jwt_decode(localStorage.getItem("token")));
  // }

  //Created a set for indian cities
  const statesSet = new Set(
    indianCities.map((city, index) => {
      return city.state;
    })
  );

  //Converted Set to Array of States
  const states = [...statesSet];

  const handleLogout = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("user-details");
    setExpandedMenu({
      patients: false,
      reports: false,
      suppliers: false,
      purchase_requests: false,
      inventory: false,
      stateinventory: false,
      dashboard: false,
    });
    setIsLoggedIn(false);
    navigate("/login", { replace: true });
  };

  const handleRegisterNewPatient = () => {
    navigate("/register-patients", { replace: true });
  };

  const dateFormatter = (date) => {
    const date_conv = new Date(date);
    const fullyear = date_conv.getFullYear();
    const month = date_conv.getMonth() + 1;
    const day = date_conv.getDate();
    return `${day}/${month}/${fullyear}`;
  };

  const checkLoggedInStatus = () => {
    if (isLoggedIn) {
      const config = {
        headers: { Authorization: `Bearer ${userToken}` },
      };

      const bodyParameters = {
        key: "value",
      };

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/user/tokenverify`,
          bodyParameters,
          config
        )
        // .then((res) => console.log(res))
        .catch(() => {
          localStorage.removeItem("token");
          setIsLoggedIn(false);
          navigate("/login", { replace: true });
        });
    }
  };

  const textBadgeValue = {
    PENDING: "warning",
    APPROVED: "success",
    ACCEPTED: "success",
    CREDIT: "success",
    DEBIT: "danger",
    RECEIVED: "success",
    REJECTED: "danger",
    DELIVERED: "success",
    RETURNED: "danger",
  };

  const setFieldLabelFromArray = (field_object) => {
    if (field_object != null) {
      return JSON.parse(field_object).label;
    }

    return field_object;
  };

  const setFieldValueFromArray = (field_object) => {
    return JSON.parse(field_object).value;
  };

  return (
    <AuthContext.Provider
      value={{
        userToken,
        handleLogout,
        handleRegisterNewPatient,
        setIsLoggedIn,
        dateFormatter,
        isLoggedIn,
        checkLoggedInStatus,
        states,
        loggedInUserDetails,
        setloggedInUserDetails,
        setSelectedDiagnosis,
        selectedDiagnosis,
        setSelectedPatientProfile,
        selectedPatientProfile,
        setPatientDiagnosis,
        patientDiagnosis,
        textBadgeValue,
        showFooterLogo,
        setShowFooterLogo,
        setFieldLabelFromArray,
        setFieldValueFromArray,
        globalSearchText,
        setGlobalSearchText,
        setExpandedMenu,
        expandedMenu,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

import React from "react";
import { useLocation } from "react-router-dom";
import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import UpdateProductChild from "./UpdateProductChild";

const UpdateProduct = () => {
  const { state } = useLocation();
  let updateProduct = state;
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="AddProduct" route="/updateproduct">
        <UpdateProductChild updateProduct={updateProduct} />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default UpdateProduct;

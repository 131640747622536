import React, { useEffect, useState, useContext } from "react";

import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Card from "react-bootstrap/Card";
import MainContent from "../../Home/MainContent";
import "antd/dist/antd.min.css";
import { Modal } from "react-bootstrap";
import { DataGrid } from "@material-ui/data-grid";
import Badge from "react-bootstrap/Badge";

//Context import
import AuthContext from "../../../components/shared/Context";
import toast from "react-hot-toast";

const TCAllotmentStatus = () => {
  let navigate = useNavigate();
  const { loggedInUserDetails, textBadgeValue } = useContext(AuthContext);

  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  const getDataFromServer = () => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };

    //console.log(config);

    const bodyParameters = {
      key: "value",
    };

    let urlLink = `${process.env.REACT_APP_API_URL}/state/sales/${loggedInUserDetails.state}`;

    if (loggedInUserDetails.role === "FULL_ACCESS") {
      urlLink = `${process.env.REACT_APP_API_URL}/state/sales/`;
    }

    // if (loggedInUserDetails.role === "ST_ADM_ACCESS") {
    //   urlLink = `${process.env.REACT_APP_API_URL}/suppliers/state/${loggedInUserDetails.state}`;
    // }

    axios
      .get(urlLink, config)
      .then((res) => {
        const oldData = res.data.sales;
        //console.log(oldData);
        const updatedData = oldData.map((data) => {
          //   console.log(data.purchase_inventory.product_id.products);
          return {
            ...data,
            id: data._id,
            product_name: data.s_register.product_id.products.product_name,
            tc_name: data.s_register.tc_id.tcenters.name_tc,
            alloted_quantity: data.s_register.quantity,
            recieved_status: data.s_register.recieved_status,
            sale_date: data.s_register.sale_date,
            state: data.s_register.state,
          };
        });
        //console.log(updatedData);
        setData(updatedData);
      })
      .catch((err) => console.log("error", err));
  };

  useEffect(() => {
    getDataFromServer();
    // getProducts();
  }, []);

  const handleDelete = (id) => {
    //  setData(data.filter((item) => item.id !== id));
    //alert(id);
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/state/sales/delete/${id}`,
        config
      )
      .then((response) => {
        //alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((err) => {
        // alert(err);
        // console.log(err);
        toast.error(err.response.data.message);
      });
  };

  //console.log(textBadgeValue["ACCEPTED"]);
  const columns = [
    {
      field: "Action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            <a onClick={() => viewRecord(params.row)}>
              <i className="uil-eye"></i>
            </a>{" "}
            {/* &nbsp;
            <Link to={"/users/update-user/" + params.row.id}>
              <i className="mdi mdi-square-edit-outline"></i>
            </Link> */}
            &nbsp;
            <a onClick={() => handleDelete(params.row.id)}>
              <i className="mdi mdi-delete-outline"></i>
            </a>
          </div>
        );
      },
    },
    {
      field: "tc_name",
      headerName: "TC Name",
      width: 220,
    },
    {
      field: "product_name",
      headerName: "Product",
      width: 240,
    },
    {
      field: "alloted_quantity",
      headerName: "Quantity",
      width: 120,
    },
    {
      field: "sale_date",
      headerName: "Date",
      width: 160,
      headerAlign: "center",
      renderCell: (params) => {
        return params.row.sale_date && params.row.sale_date.slice(0, 10);
      },
    },
    {
      field: "recieved_status",
      headerName: "Recieved Status",
      width: 160,

      renderCell: (params) => {
        //textToBadge(params.row.recieved_status);
        let status = params.row.recieved_status.toUpperCase();
        // console.log(status);

        //console.log(textBadgeValue[status]);
        return (
          <Badge bg={textBadgeValue[status]}>
            {params.row.recieved_status}
          </Badge>
        );
      },
    },
    {
      field: "state",
      headerName: "State",
      width: 110,
    },
  ];
  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };
  return (
    <MainContent>
      <Card style={{ width: "100%", marginTop: "10px", padding: "30px" }}>
        <Card.Header>
          <span className="card-title">
            <h3>Drug Allotment</h3>
          </span>
        </Card.Header>
        <Card.Body>
          <DataGrid
            rows={data}
            disableSelectionOnClick
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            displayTitle="Drug Allotment"
            checkboxSelection
            style={{ height: "60vh" }}
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "primary.light",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
          />
        </Card.Body>
      </Card>

      {/* Modal Popup */}
      <Modal show={openModal}>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent currentRecord={currentRecord} />
        </Modal.Body>
      </Modal>
    </MainContent>
  );
};

const ModalContent = ({ currentRecord }) => {
  return (
    <div className="container-fluid">
      <table class="table table-striped">
        <tbody>
          <tr>
            <th scope="row">Treatment Center</th>
            <td>{currentRecord.tc_name}</td>
          </tr>
          <tr>
            <th scope="row">Product Name</th>
            <td>{currentRecord.product_name}</td>
          </tr>

          <tr>
            <th scope="row">Allotted Quantity</th>
            <td>{currentRecord.alloted_quantity}</td>
          </tr>
          {currentRecord.sale_date && (
            <tr>
              <th scope="row">Date of Allotment</th>
              <td>{currentRecord.sale_date.slice(0, 10)}</td>
            </tr>
          )}
          <tr>
            <th scope="row">Recieved Status</th>
            <td>{currentRecord.recieved_status}</td>
          </tr>
          <tr>
            <th scope="row">State</th>
            <td>{currentRecord.state}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TCAllotmentStatus;

import React from "react";
import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import AddTestimonialChild from "./AddTestimonialChild";

const AddTestimonial = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="Add Testimonials" route="/testimonial/add">
        <AddTestimonialChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default AddTestimonial;

import React from "react";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
//import "./ProductsList.css";
import axios from "axios";
import toast from "react-hot-toast";
import { RadioGroup, Radio } from "react-radio-group";

import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";

const MriScoreTab = ({ emrRecordId, setEmrRecord, emrRecord }) => {
  const [value, setValue] = React.useState([1, 3]);
  const [totalScore, setTotalScore] = useState(0);
  const [softTissueScore, setSoftTissueScore] = useState(0);
  const [osteochondralScore, setOsteochondralScore] = useState(0);

  const [formData, setFormData] = useState({
    effusion_haemarthrosis: 0,
    synovial_hypertrophy: 0,
    haemosiderin: 0,
    joint_margins: 0,
    subchondral_cysts: 0,
    cartilage_degradation: 0,
  });

  useEffect(() => {
    if (emrRecord) {
      localStorage.setItem(
        `${emrRecordId}-mriscore`,
        JSON.stringify(emrRecord)
      );
    }
    let mriscores = localStorage.getItem(`${emrRecordId}-mriscore`);
    setFormData({ ...formData, ...JSON.parse(mriscores) });
  }, [emrRecord]);

  useEffect(() => {
    setTotalScore(
      Object.values({ ...formData }).reduce(
        (prev, value) => (prev = prev + parseInt(value ? value : 0)),
        0
      )
    );

    const oScore = [
      formData.joint_margins,
      formData.subchondral_cysts,
      formData.cartilage_degradation,
    ];

    setOsteochondralScore(
      oScore.reduce(
        (prev, value) => (prev = prev + parseInt(value ? value : 0)),
        0
      )
    );

    const softTissue = [
      formData.effusion_haemarthrosis,
      formData.synovial_hypertrophy,
      formData.haemosiderin,
    ];
    setSoftTissueScore(
      softTissue.reduce(
        (prev, value) => (prev = prev + parseInt(value ? value : 0)),
        0
      )
    );
  }, [formData]);

  const updateEmr = (e) => {
    e.preventDefault();
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/emr/update/${emrRecordId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, mriscan_score: true },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        localStorage.setItem(
          `${emrRecordId}-mriscore`,
          JSON.stringify(formData)
        );
        toast.success("MRI Score saved Successfully.");
        setEmrRecord(response.data.emr);
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error.response.data));
      });
  };

  const handleChange = ({ currentTarget: input }) => {
    //alert([input.name]);
    setFormData({
      ...formData,
      [input.name]: input.checked,
    });
  };
  const handleChangeValue = ({ target: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  return (
    <form className="d-flex flex-sm-wrap" onSubmit={(e) => updateEmr(e)}>
      {/* {JSON.stringify(formData)} */}
      <div className="table-responsive w-100" style={{ marginRight: "40px" }}>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col" colspan="2">
                MRI Score
              </th>
              <th scope="col" colspan="2" style={{ textAlign: "right" }}>
                <Button variant="primary" size="large" type="submit">
                  Save
                </Button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Soft Tissue: {softTissueScore} </th>
              <th style={{ textAlign: "right" }}>
                Osteochondral: {osteochondralScore}
              </th>
              <th style={{ textAlign: "right" }}>Total Score</th>
              <th>{totalScore}</th>
            </tr>
            <tr>
              <th colspan="4">A. Soft-tissue Changes</th>
            </tr>
            <tr>
              <td>Effusion / Hemarthrosis</td>
              <td colspan="3" style={{ verticalAlign: "middle" }}>
                <RadioGroup
                  name="effusion_haemarthrosis"
                  selectedChecked={formData.effusion_haemarthrosis}
                  onClick={handleChangeValue}
                  onChange={() => {}}
                >
                  <Radio
                    value="1"
                    className="radioButton"
                    checked={1 === parseInt(formData.effusion_haemarthrosis)}
                  />
                  Small (1)
                  <Radio
                    value="2"
                    className="radioButton"
                    checked={2 === parseInt(formData.effusion_haemarthrosis)}
                  />
                  Moderate (2)
                  <Radio
                    value="3"
                    className="radioButton"
                    checked={3 === parseInt(formData.effusion_haemarthrosis)}
                  />
                  Large (3)
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td>Synovial Hypertophy</td>
              <td colspan="3" style={{ verticalAlign: "middle" }}>
                <RadioGroup
                  name="synovial_hypertrophy"
                  // selectedValue={this.state.selectedValue}
                  selectedChecked={formData.synovial_hypertrophy}
                  onClick={handleChangeValue}
                  onChange={() => {}}
                >
                  <Radio
                    value="1"
                    className="radioButton"
                    checked={1 === parseInt(formData.synovial_hypertrophy)}
                  />
                  Small (1)
                  <Radio
                    value="2"
                    className="radioButton"
                    checked={2 === parseInt(formData.synovial_hypertrophy)}
                  />
                  Moderate (2)
                  <Radio
                    value="3"
                    className="radioButton"
                    checked={3 === parseInt(formData.synovial_hypertrophy)}
                  />
                  Large (3)
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td>Haemosiderin</td>
              <td colspan="3" style={{ verticalAlign: "middle" }}>
                <RadioGroup
                  name="haemosiderin"
                  // selectedValue={this.state.selectedValue}
                  selectedChecked={formData.haemosiderin}
                  onClick={handleChangeValue}
                  onChange={() => {}}
                >
                  <Radio
                    value="1"
                    className="radioButton"
                    checked={1 === parseInt(formData.haemosiderin)}
                  />
                  Small (1)
                  <Radio
                    value="2"
                    className="radioButton"
                    checked={2 === parseInt(formData.haemosiderin)}
                  />
                  Moderate (2)
                  <Radio
                    value="3"
                    className="radioButton"
                    checked={3 === parseInt(formData.haemosiderin)}
                  />
                  Large (3)
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <th colspan="4">B. Osteochondral changes</th>
            </tr>
            <tr>
              <td colspan="4">
                <div style={{ paddingBottom: "10px" }}>
                  Surface erosions involving subchondral cortex or Joint
                  margins. ?
                </div>
                <RadioGroup
                  name="joint_margins"
                  // selectedValue={this.state.selectedValue}
                  selectedChecked={formData.joint_margins}
                  onClick={handleChangeValue}
                  onChange={() => {}}
                >
                  <Radio
                    value="1"
                    className="radioButtonOsteo"
                    checked={1 === parseInt(formData.joint_margins)}
                  />
                  Any Surface Erosion
                  <Radio
                    value="2"
                    className="radioButtonOsteo"
                    checked={2 === parseInt(formData.joint_margins)}
                  />
                  Half/more of the articular surface eroded in atleast one bone
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td>Subchondral Cysts</td>
              <td colspan="4">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <RadioGroup
                          name="subchondral_cysts"
                          // selectedValue={this.state.selectedValue}
                          selectedChecked={formData.subchondral_cysts}
                          onClick={handleChangeValue}
                          onChange={() => {}}
                        >
                          <Radio
                            value="1"
                            className="radioButtonOsteo"
                            onChange={handleChangeValue}
                            checked={1 === parseInt(formData.subchondral_cysts)}
                          />
                          Atleast one subchorndral cysts
                        </RadioGroup>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <RadioGroup
                          name="subchondral_cysts"
                          // selectedValue={this.state.selectedValue}
                          selectedChecked={formData.subchondral_cysts}
                          onClick={handleChangeValue}
                          onChange={() => {}}
                        >
                          <div style={{ textAlign: "left" }}>
                            <Radio
                              value="2"
                              className="radioButtonOsteo"
                              onChange={handleChangeValue}
                              checked={
                                2 === parseInt(formData.subchondral_cysts)
                              }
                            />
                            Subchondral cysts in atleast two bones or cystic
                            changes involving a third or more of the articular
                            surface in atleast.
                          </div>
                        </RadioGroup>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>Cartilage Degradation</td>
              <td colspan="4">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <RadioGroup
                          name="cartilage_degradation"
                          // selectedValue={this.state.selectedValue}
                          selectedChecked={formData.cartilage_degradation}
                          onClick={handleChangeValue}
                          onChange={() => {}}
                        >
                          <Radio
                            value="1"
                            className="radioButtonOsteo"
                            checked={
                              1 === parseInt(formData.cartilage_degradation)
                            }
                          />
                          Any loss of joint cartilage height
                        </RadioGroup>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <RadioGroup
                          name="cartilage_degradation"
                          // selectedValue={this.state.selectedValue}

                          selectedChecked={formData.cartilage_degradation}
                          onClick={handleChangeValue}
                          onChange={() => {}}
                        >
                          <Radio
                            value="2"
                            className="radioButtonOsteo"
                            checked={
                              2 === parseInt(formData.cartilage_degradation)
                            }
                          />
                          Loss of half or more of the total volume of joint
                          cartilage in atleast one bone.
                        </RadioGroup>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <RadioGroup
                          name="cartilage_degradation"
                          // selectedValue={this.state.selectedValue}

                          selectedChecked={formData.cartilage_degradation}
                          onClick={handleChangeValue}
                          onChange={() => {}}
                        >
                          <Radio
                            value="3"
                            className="radioButtonOsteo"
                            checked={
                              3 === parseInt(formData.cartilage_degradation)
                            }
                          />
                          Full thickness loss of joint cartilage in atleast some
                          area in atleast one bone
                        </RadioGroup>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <RadioGroup
                          name="cartilage_degradation"
                          // selectedValue={this.state.selectedValue}

                          selectedChecked={formData.cartilage_degradation}
                          onClick={handleChangeValue}
                          onChange={() => {}}
                        >
                          <Radio
                            value="4"
                            className="radioButtonOsteo"
                            checked={
                              4 === parseInt(formData.cartilage_degradation)
                            }
                          />
                          Full thickness loss of joint cartilage including
                          atleast one half of the joint surface in atleast one
                          maximum
                        </RadioGroup>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </form>
  );
};

export default MriScoreTab;

import React from "react";
import Chart from "react-apexcharts";

const ShowApexChart = ({ chartOptions ,type}) => {
  return (
            <>
                <Chart
                options={chartOptions.options}
                series={chartOptions.series}
                type={type}
                width="100%"
                height="380"
                />
            </>
  );
};

export default ShowApexChart;

import React from "react";
import { Button } from "react-bootstrap";
import axios from "axios";

class ErrorBoundaryRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    //console.log(error);
    return { hasError: true };
  }

  componentDidCatch(er, errorInfo) {
    // You can also log the error to an error reporting service
    //console.log(er);
    // console.log(errorInfo);

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/elog/add`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        error_log: JSON.stringify(errorInfo),
        page: this.props.page,
        route: this.props.route,
      },
    };
    axios(config)
      .then(function (response) {})
      .catch(function (error) {});
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <CustomErrorPage mesg="Something went Wrong" route={this.props.route} />
      );
    }

    return this.props.children;
  }
}

const CustomErrorPage = ({ mesg, route }) => {
  //  let navigate = useNavigate();
  // const handleClick = () => {
  //   if (localStorage.getItem("token")) {
  //     navigate(route, { replace: true });
  //   }
  // };
  return (
    <>
      <p>{mesg}</p>
      <p>
        <Button variant="primary" size="medium" href={route}>
          Click here
        </Button>
      </p>
    </>
  );
};

export default ErrorBoundaryRoutes;

import React from "react";
import { useLocation } from "react-router-dom";
import MainContentCard from "../../Home/MainContentCard";
import MainContent from "../../Home/MainContent";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import PatientImportsChild from "./PatientImportsChild";

const PatientImports = () => {
  return (
    <MainContent>
      <ErrorBoundaryRoutes page="PatientImports" route="/import-patients">
        <PatientImportsChild />
      </ErrorBoundaryRoutes>
    </MainContent>
  );
};

export default PatientImports;

import React from "react";
import CardContent from "./CardContent";
import FaqHelpChild from "./FaqHelpChild";

const FaqHelp = () => {
  return (
    <CardContent>
      <FaqHelpChild />
    </CardContent>
  );
};

export default FaqHelp;

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Pagination } from "react-bootstrap";

import AuthContext from "../../components/shared/Context";
import NotificationComponent from "./NotificationComponent";

const NotificationsChild = () => {
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentNotifications, setCurrentNotifications] = useState([]);
  const notificationsPerPage = 5;
  const { loggedInUserDetails } = useContext(AuthContext);

  // Fetch notifications
  useEffect(() => {
    const fetchNotifications = async () => {
      const userToken = localStorage.getItem("token");
      const notificationUrl = `${process.env.REACT_APP_API_URL}/notifications/${
        loggedInUserDetails?.tc_id || loggedInUserDetails?._id
      }/10`;

      try {
        const response = await axios.get(notificationUrl, {
          headers: { Authorization: `Bearer ${userToken}` },
        });
        setNotifications(response.data.notifications);
        console.log("Notifications fetched:", response.data.notifications);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, [loggedInUserDetails]);

  // Update current notifications based on page change
  useEffect(() => {
    const indexOfLastNotification = currentPage * notificationsPerPage;
    const indexOfFirstNotification =
      indexOfLastNotification - notificationsPerPage;
    const newCurrentNotifications = notifications?.slice(
      indexOfFirstNotification,
      indexOfLastNotification
    );
    setCurrentNotifications(newCurrentNotifications);
    console.log(
      `Current notifications for page ${currentPage}:`,
      newCurrentNotifications
    );
  }, [currentPage, notifications]);

  // Handle page change
  const paginate = (pageNumber) => {
    console.log("Page clicked:", pageNumber);
    setCurrentPage(pageNumber);
  };

  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil(notifications?.length / notificationsPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  const reloadNotifications = () => {
    setNotifications(null);
    let userToken = localStorage.getItem("token");
    let notification_url = "";

    if (loggedInUserDetails?.tc_id) {
      //TC Admins Notifications
      notification_url = `${process.env.REACT_APP_API_URL}/notifications/${loggedInUserDetails?.tc_id}/20`;
    } else {
      //State Admin Notifications
      notification_url = `${process.env.REACT_APP_API_URL}/notifications/${loggedInUserDetails?._id}/20`;
    }

    const config = {
      method: "GET",
      url: notification_url,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then((response) => {
        console.log(response.data.notifications);
        setNotifications(response.data.notifications);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="w-100 h-screen">
      <div>
        <h2>Notifications</h2>
        <span className="float-end">
          <button
            className="btn btn-primary ms-2"
            onClick={reloadNotifications}
          >
            <i className="mdi mdi-autorenew" />
          </button>
        </span>
      </div>
      <br />
      <div
        className="d-flex justify-content-center w-[100px]"
        style={{
          wordBreak: "break-word",
          maxWidth: "100%",
          overflow: "hidden",
        }}
      >
        {currentNotifications?.length ? (
          <div className="w-[100px]">
            <NotificationComponent
              notificationsData={currentNotifications}
              loggedInUserDetails={loggedInUserDetails}
            />
            <div className="mt-2 justify-content-end">
              {notifications?.length > 5 && (
                <Pagination>
                  {pageNumbers?.map((number) => (
                    <Pagination.Item
                      key={number}
                      active={number === currentPage}
                      onClick={() => paginate(number)}
                    >
                      {number}
                    </Pagination.Item>
                  ))}
                </Pagination>
              )}
            </div>
          </div>
        ) : (
          "No notifications to display or loading..."
        )}
      </div>
    </div>
  );
};

export default NotificationsChild;

import React from "react";
import { BarChart, Bar, CartesianGrid, XAxis, YAxis , ResponsiveContainer , Tooltip } from 'recharts';
import "./Chart.css";
//import "./ShowBarChart.css";

const ShowBarChart = ({ data }) => {
  return (
    <div className="chart">
        <ResponsiveContainer width="100%" aspect={2}>
            <BarChart width={600} height={600} data={data}>
                <Bar dataKey="value" fill="green" />
                <CartesianGrid stroke="#ccc" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
            </BarChart>
        </ResponsiveContainer>  
    </div>
  );
};

export default ShowBarChart;

import React from "react";
import { useLocation } from "react-router-dom";
import MainContentCard from "../../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../../components/shared/ErrorBoundaryRoutes";
import AddPurchaseTCChild from "./AddPurchaseTCChild";

const AddPurchaseTC = () => {
  const { state } = useLocation();
  let recieveProduct = state;
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="AddPurchaseTC" route="/add-purchase-tc">
        <AddPurchaseTCChild recieveProduct={recieveProduct} />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default AddPurchaseTC;

import React from "react";
import MainContentCard from "../../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../../components/shared/ErrorBoundaryRoutes";
import ListTCDosingHistoryChild from "./ListTCDosingHistoryChild";

const ListTCDosingHistory = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="ListTCDosingHistory" route="/dose-history-tc">
        <ListTCDosingHistoryChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default ListTCDosingHistory;

import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const HelpPopup = ({ showPopup, onClose }) => {
  let navigate = useNavigate();
  return (
    <Modal show={showPopup} onHide={onClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Help</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ textAlign: "justify", fontSize: "15px" }}>
          Introducing the iCare Application, a groundbreaking patient registry
          designed to revolutionize healthcare.
          <br />
          <br />
          With our user-friendly interface, navigating through the application
          is a breeze. To ensure you make the most of its features, our
          comprehensive help menus are readily available. <br />
          <br />
          Whether you're a beginner or an advanced user, we're here to guide you
          every step of the way and help you unlock the application's full
          potential.
        </p>
        <p style={{ textAlign: "justify", fontSize: "15px" }}>
          Click the button below to visit the help page:
        </p>
        <Button variant="primary" onClick={() => navigate("/help-dash")}>
          Go to Help
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default HelpPopup;

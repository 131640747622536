import React from "react";

import MainContentCard from "../../Home/MainContentCard";
import AddPurchaseChild from "./AddPurchaseChild";

const AddPurchase = () => {
  return (
    <MainContentCard>
      <AddPurchaseChild />
    </MainContentCard>
  );
};

export default AddPurchase;

import React from "react";
import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

import ListPatientDosingHistoryChild from "./ListPatientDosingHistoryChild";

const ListPatientDosingHistory = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes
        page="ListPatientDosingHistoryChild"
        route="/dosing-history"
      >
        <ListPatientDosingHistoryChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default ListPatientDosingHistory;

import { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import Tooltip from "@mui/material/Tooltip";
import { Modal } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import { confirmAlert } from "react-confirm-alert";
import Card from "react-bootstrap/Card";

import axios from "axios";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import AlertDialog from "../../../components/InfoDialog/AlertDialog";
import moment from "moment";
//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import PatientInfoBlock from "../../../components/PatientProfile/PatientInfoBlock";

import BackButton from "../../../components/Buttons/BackButton";

const AppointmentListChild = ({ patientRecordId, patientProfileData }) => {
  let navigate = useNavigate();
  moment.tz.setDefault("Asia/Kolkata");
  const [patientProfile, setPatientProfile] = useState(patientProfileData);
  const [data, setData] = useState([]);
  const [visits, setVisits] = useState();
  //Modal Variables
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  const childRef = useRef();
  //Error Logging Service
  const [{ errorLogApi, dateFormatter }] = useAtom(gbState);

  const getDataFromServer = () => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    const bodyParameters = {
      key: "value",
    };
    let urlLink = `${process.env.REACT_APP_API_URL}/appointment/patient/${patientRecordId}`;
    axios
      .get(urlLink, config)
      .then((res) => {
        const oldData = res.data.appointments;
        //console.log(res.data);
        const visitData = res.data.visits;
        const visitArray = [];
        visitData.map((date) => visitArray.push(date.date_of_visit));
        setVisits(visitArray);
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data._id,
            appointment_date: dateFormatter(data?.appointment_date),
            ap_date: data?.appointment_date,
            appointment_time: data?.appointment_time,
            appointment_type: data?.appointment_type,
            visit_type: data?.visit_type,
            appointment_status: data?.appointment_status,
          };
        });
        //console.log(updatedData);
        setData(updatedData);
      })
      .catch((err) => {
        errorLogApi(err, "AppointmentListChild", urlLink);
      });
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this patient ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (id) => {
    //  setData(data.filter((item) => item.id !== id));
    //alert(id);
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/appointment/delete/${id}`,
        config
      )
      .then((response) => {
        getDataFromServer();
      })
      .catch((err) => alert(err));
  };

  useEffect(() => {
    const getDataFromServer = () => {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };
      const bodyParameters = {
        key: "value",
      };
      let urlLink = `${process.env.REACT_APP_API_URL}/appointment/patient/${patientRecordId}`;
      axios
        .get(urlLink, config)
        .then((res) => {
          const oldData = res.data.appointments;
          //console.log(res.data);
          const visitData = res.data.visits;
          const visitArray = [];
          visitData.map((date) => visitArray.push(date.date_of_visit));
          setVisits(visitArray);
          const updatedData = oldData.map((data) => {
            return {
              ...data,
              id: data._id,
              appointment_date: dateFormatter(data?.appointment_date),
              ap_date: data?.appointment_date,
              appointment_time: data?.appointment_time,
              appointment_type: data?.appointment_type,
              visit_type: data?.visit_type,
              appointment_status: data?.appointment_status,
            };
          });
          //console.log(updatedData);
          setData(updatedData);
        })
        .catch((err) => {
          errorLogApi(err, "AppointmentListChild", urlLink);
        });
    };
    getDataFromServer();
  }, []);

  const badgeColor = {
    pending: "warning",
    completed: "success",
    cancelled: "danger",
    reschedule: "primary",
  };

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      width: 100,
      sortable: false,
      hideable: false,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            <Tooltip title="View Appointment">
              <a onClick={() => viewRecord(params.row)}>
                <i
                  className="mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </a>
            </Tooltip>
            <Tooltip title="Delete Appointment">
              <div
                onClick={() => {
                  if (visits?.includes(params.row.ap_date)) {
                    toast.error("The Appointment cannot be Deleted");
                  } else {
                    handleDelete(params.row._id);
                  }
                }}
              >
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{
                    fontSize: "15px",
                    color: "red",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit Appointment">
              <div
                onClick={() => {
                  if (visits?.includes(params.row.ap_date)) {
                    toast.error("The Appointment cannot be Edited");
                  } else {
                    navigate("/editappointment", {
                      state: {
                        appointment: params.row,
                        patient_data: patientProfileData,
                      },
                    });
                  }
                }}
              >
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{
                    fontSize: "15px",
                    color: "grey",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Create a Visit">
              <div
                onClick={() => {
                  if (visits?.includes(params.row.ap_date)) {
                    toast.error("The Visit already exist");
                  } else {
                    if (!params?.row?.appointment_status) {
                      navigate(`/create-visit/`, {
                        state: {
                          ...patientProfileData,
                          appointment_date: params.row.ap_date,
                          visit_reason: params.row.appointment_type,
                          visit_type: params.row.visit_type,
                        },
                      });
                    } else if (
                      params?.row?.appointment_status &&
                      JSON.parse(params?.row?.appointment_status).label ===
                        "Pending"
                    ) {
                      navigate(`/create-visit/`, {
                        state: {
                          ...patientProfileData,
                          appointment_date: params.row.ap_date,
                        },
                      });
                    } else {
                      toast.error("The Visit cannot be created");
                    }
                  }
                }}
              >
                <i
                  className="mdi mdi-plus-box"
                  style={{
                    fontSize: "15px",
                    color: "green",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "ap_date",
      headerName: "Date",
      width: 110,
      renderCell: (params) => dateFormatter(params.row.ap_date),
    },
    {
      field: "appointment_time",
      headerName: "Time",
      width: 90,
      sortable: false,
      hideable: false,
      align: "center",
    },
    {
      field: "appointment_type",
      headerName: "Appointment",
      width: 150,
      sortable: false,
      hideable: false,
      align: "center",
      renderCell: (params) => JSON.parse(params.row.appointment_type).label,
    },
    {
      field: "visit_type",
      headerName: "Purpose",
      width: 150,
      sortable: false,
      hideable: false,
      align: "center",
      renderCell: (params) =>
        params?.row?.visit_type
          ? JSON.parse(params?.row?.visit_type)?.label
          : "N/A",
    },
    {
      field: "appointment_status",
      headerName: "Ap. Status",
      width: 130,
      sortable: false,
      hideable: false,
      align: "center",
      renderCell: (params) => {
        return (
          <CheckVisitStatus
            visit_date={params.row.ap_date}
            appointment_status={params.row.appointment_status}
            patientRecordId={patientRecordId}
          />
        );
      },
    },
  ];

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };

  return (
    <div>
      <h2>Appointments</h2>
      <div className="patientUpdateButton">
        <Button
          variant="primary"
          size="large"
          onClick={() =>
            navigate("/addappointment/", { state: patientProfile })
          }
        >
          Create Appointment
        </Button>{" "}
        &nbsp;&nbsp;
        <BackButton />
      </div>
      <AlertDialog ref={childRef} title="" />
      <div className="mb-2">
        {patientProfile && (
          <PatientInfoBlock selectedPatientProfile={patientProfile} />
        )}
      </div>
      <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        displayTitle="Health Record"
        checkboxSelection
        style={{ height: "40vh" }}
      />

      {/* {JSON.stringify(visits)} */}
      {/* Modal Popup */}
      <Modal show={openModal} size="md" centered>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent currentRecord={currentRecord} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

const CheckVisitStatus = ({
  visit_date,
  appointment_status,
  patientRecordId,
}) => {
  const [visits, setVisits] = useState();
  //Error Logging Service
  const [{ errorLogApi }] = useAtom(gbState);
  const badgeColor = {
    pending: "warning",
    completed: "success",
    cancelled: "danger",
    reschedule: "primary",
  };

  //console.log(visit_date);
  useEffect(() => {
    const getDataFromServer = () => {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };
      const bodyParameters = {
        key: "value",
      };
      let urlLink = `${process.env.REACT_APP_API_URL}/appointment/patient/visits/${patientRecordId}`;
      axios
        .get(urlLink, config)
        .then((res) => {
          //console.log(res.data);
          const visitData = res.data.visits;
          //console.log("Visits: ", visitData);
          const visitArray = [];
          visitData.map((date) => visitArray.push(date.date_of_visit));
          //console.log("Visits: ", visitArray);
          setVisits(visitArray);
        })
        .catch((err) => {
          errorLogApi(err, "AppointmentListChild", urlLink);
        });
    };
    getDataFromServer();
  }, []);
  //console.log("Visits from State: ", visits);
  return visits?.includes(visit_date) ? (
    <Badge bg={badgeColor[`completed`]}>
      &nbsp;&nbsp;COMPLETED&nbsp;&nbsp;
    </Badge>
  ) : appointment_status ? (
    <Badge
      bg={badgeColor[`${JSON.parse(appointment_status).label.toLowerCase()}`]}
    >
      {JSON.parse(appointment_status).label.toUpperCase()}
    </Badge>
  ) : (
    <Badge bg="warning">&nbsp;&nbsp;PENDING&nbsp;&nbsp;</Badge>
  );
};

const ModalContent = ({ currentRecord }) => {
  return (
    <div className="container-fluid legend">
      <Card>
        <Card.Header>
          <i className="fas fa-align-justify" />
          <strong>Appointment Details</strong>
        </Card.Header>
        <Card.Body>
          <div key={currentRecord._id} className="row ">
            <div className="col-md-6 col-xs-6 divTableCell">Date</div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {currentRecord.appointment_date}
            </div>
          </div>
          <div key={currentRecord.appointment_time} className="row ">
            <div className="col-md-6 col-xs-6 divTableCell">Time</div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {currentRecord.appointment_time}
            </div>
          </div>
          <div key={currentRecord.appointment_type} className="row ">
            <div className="col-md-6 col-xs-6 divTableCell">
              Appointment Type
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {JSON.parse(currentRecord.appointment_type).label}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-xs-12 divTableCell text-center h6 mt-2">
              Description
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-xs-12 divTableCell">
              {currentRecord.appointment_notes}
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AppointmentListChild;

import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import { confirmAlert } from "react-confirm-alert";
//import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import Badge from "react-bootstrap/Badge";
import toast from "react-hot-toast";
import { makeStyles } from "@material-ui/core/styles";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Card from "react-bootstrap/Card";
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

//Context import
import AuthContext from "../../../components/shared/Context";
import PatientInfoBlock from "../../../components/PatientProfile/PatientInfoBlock";

const ListRecommendedDrugsChild = () => {
  let navigate = useNavigate();
  //Error Logging Service
  const [{ errorLogApi }] = useAtom(gbState);

  const { loggedInUserDetails, setPatientDiagnosis, setFieldLabelFromArray } =
    useContext(AuthContext);

  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/drugrec/tc/${loggedInUserDetails.tc_id}`;

      if (loggedInUserDetails.role === "ST_ADM_ACCESS") {
        urlLink = `${process.env.REACT_APP_API_URL}/drugrec/${loggedInUserDetails.state}`;
      }

      if (loggedInUserDetails.role === "FULL_ACCESS") {
        urlLink = `${process.env.REACT_APP_API_URL}/drugrec/`;
      }

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data.roles));
          const oldData = response.data.drugs;
          const updatedData = oldData.map((data) => {
            return {
              ...data,
              id: data._id,
              patientid: data.patient_id.patientId,
              patient_name: data.patient_id.patient_name,
              diagnosis_name: data.patient_id.diagnoses.diagnosis_name,
              recommended_product_name:
                data.recommended_product_id.products.product_name,
              current_product_name:
                data.current_product_id.products.product_name,
              approval_status: data.approval_status,
              tc_name: data.tc_id.tcenters.name_tc,
              state: data.tc_id.tcenters.state,
              recommendation_id: data.recommendation_id,
            };
          });
          //   console.log(updatedData);
          setData(updatedData);
        })
        .catch(function (error) {
          //console.log(error.message);
        });
    };

    getDataFromServer();
  }, []);

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/drugrec/tc/${loggedInUserDetails.tc_id}`;

    if (loggedInUserDetails.role === "ST_ADM_ACCESS") {
      urlLink = `${process.env.REACT_APP_API_URL}/drugrec/${loggedInUserDetails.state}`;
    }

    if (loggedInUserDetails.role === "FULL_ACCESS") {
      urlLink = `${process.env.REACT_APP_API_URL}/drugrec/`;
    }

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data.roles));
        const oldData = response.data.drugs;
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data._id,
            patientid: data.patient_id.patientId,
            patient_name: data.patient_id.patient_name,
            diagnosis_name: data.patient_id.diagnoses.diagnosis_name,
            recommended_product_name:
              data.recommended_product_id.products.product_name,
            current_product_name: data.current_product_id.products.product_name,
            approval_status: data.approval_status,
            tc_name: data.tc_id.tcenters.name_tc,
            state: data.tc_id.tcenters.state,
            recommendation_id: data.recommendation_id,
          };
        });
        //   console.log(updatedData);
        setData(updatedData);
      })
      .catch(function (error) {
        //console.log(error.message);
      });
  };

  const badgeStatus = {
    false: { badge: "warning", label: "PENDING" },
    true: { badge: "success", label: "COMPLETED" },
  };

  const handleDelete = (row) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this Record ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(row),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (row) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/drugrec/delete/${row.id}`,
        config
      )
      .then((response) => {
        //alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
        errorLogApi(err, "ListRecommendedDrugsChild", "/recommndeddrugs");
      });
  };

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };

  const useStyles = makeStyles(() => ({
    multiLineCell: {
      display: "flex",
      wordBreak: "keep-all",
      wordWrap: "break-word",
      overflowWrap: "break-word",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      lineHeight: "1.2rem",
      height: "auto",
    },
  }));

  const MultiLineCellRenderer = (params) => {
    const classes = useStyles();
    return (
      <div className={classes.multiLineCell}>
        <span id="content1">
          <b>{params.row.patient_name.toUpperCase()}</b>
        </span>
        <span id="content2">
          <small className="small-font-size">{params.row.patientid}</small>
        </span>
      </div>
    );
  };

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      width: 90,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            <Tooltip title="View">
              <a onClick={() => viewRecord(params.row)}>
                <i
                  className="mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </a>
            </Tooltip>
            <Tooltip title="Edit">
              <div
                onClick={() =>
                  navigate("/update-recommendation", { state: params.row })
                }
              >
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            {loggedInUserDetails.role === "ST_ADM_ACCESS" && (
              <Tooltip title="Approve/Decline">
                <div
                  onClick={() =>
                    navigate("/approve-recommendation", { state: params.row })
                  }
                >
                  <i
                    className="mdi mdi-checkbox-marked-outline"
                    style={{
                      fontSize: "15px",
                      color: "green",
                      cursor: "pointer",
                    }}
                  ></i>
                </div>
              </Tooltip>
            )}
            <Tooltip title="Delete">
              <div onClick={() => handleDelete(params.row)}>
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "recommendation_id",
      headerName: "Recommendation ID",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      width: 180,
    },
    {
      field: "patientid",
      headerName: "Patient ID",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 180,
      renderCell: MultiLineCellRenderer,
    },
    // {
    //   field: "patient_name",
    //   headerName: "Patient Name",
    //   width: 160,
    //   filterable: false,
    //   sortable: false,
    //   disableColumnMenu: false,
    //   // align: "center",
    // },
    {
      field: "diagnosis_name",
      headerName: "Diagnosis",
      filterable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "center",
      width: 120,
    },
    {
      field: "recommended_product_name",
      headerName: "Recommendation",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 240,
    },
    {
      field: "approval_status",
      headerName: "Approval Status",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      width: 160,
      renderCell: (params) => {
        return (
          <Badge bg={badgeStatus[params.row.approval_status].badge}>
            {badgeStatus[params.row.approval_status].label}
          </Badge>
        );
      },
    },
  ];

  return (
    <div>
      <div className="patientUpdateButton">
        <Button
          variant="primary"
          size="large"
          onClick={() => navigate("/adddrug/")}
        >
          Recommend New Drug
        </Button>{" "}
      </div>
      <h1 className="allPatients">Recommended Drugs Browser </h1>
      <div className="patientListSearch">
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">Search Title</InputGroup.Text>
          <Form.Control
            placeholder="title..."
            aria-label="Search"
            aria-describedby="basic-addon1"
            name="searchPatients"
            onChange={(e) => {}}
          />
        </InputGroup>
      </div>
      <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        displayTitle="Patient List"
        checkboxSelection
        style={{ height: "60vh" }}
      />

      {/* Modal Popup */}
      <Modal show={openModal} size="lg" centered>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>
            Recommended Drug Detail{" "}
            {/* {currentRecord ? (
              <Badge bg={badgeStatus[currentRecord?.approval_status].badge}>
                REQUEST {badgeStatus[currentRecord?.approval_status].label}
              </Badge>
            ) : null} */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent currentRecord={currentRecord} />
        </Modal.Body>
      </Modal>
      {/* {JSON.stringify(data)} */}
    </div>
  );
};

const ModalContent = ({ currentRecord }) => {
  const badgeStatus = {
    false: { badge: "warning", label: "PENDING" },
    true: { badge: "success", label: "COMPLETED" },
  };

  return (
    <div style={{ wordBreak: "break-word" }}>
      <PatientInfoBlock selectedPatientProfile={currentRecord.patient_id} />
      <br />
      <Card>
        <Card.Header>
          <i className="fas fa-align-justify" />
          <strong>Recommendation</strong>
        </Card.Header>
        <Card.Body>
          {/* {JSON.stringify(currentRecord)} */}
          <div className="table-responsive overflow-y:hidden">
            <table className="table table-striped">
              <tbody>
                <tr>
                  <th scope="row">Treatment Center</th>
                  <th>
                    <div className="flex flex-column">
                      {" "}
                      <span id="content1">
                        {currentRecord?.tc_id?.tcenters?.name_tc}
                      </span>
                      <span id="content2">
                        <small className="small-font-size">
                          {currentRecord?.tc_id?.tcenters?.treatment_center_id}
                        </small>
                      </span>
                    </div>
                  </th>
                </tr>
                <tr>
                  <td> Current Product</td>
                  <td>
                    <div className="flex flex-column">
                      <span id="content1">
                        {
                          currentRecord?.recommended_product_id?.products
                            ?.product_name
                        }
                      </span>
                      <span id="content2">
                        <small className="small-font-size">
                          {
                            currentRecord?.recommended_product_id?.products
                              ?.item_code
                          }
                        </small>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td> Recommended Product</td>
                  <td>
                    <div className="flex flex-column">
                      <span id="content1">
                        {
                          currentRecord?.current_product_id?.products
                            ?.product_name
                        }
                      </span>
                      <span id="content2">
                        <small className="small-font-size">
                          {
                            currentRecord?.current_product_id?.products
                              ?.item_code
                          }
                        </small>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div className="flex flex-column">
                      <span id="content1">
                        <b>Recommended Use</b>
                      </span>
                      <span id="content2">
                        {currentRecord?.recommended_use}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan={2}>
                    <div className="flex flex-column">
                      <span id="content1">
                        <b>Potential Benefits</b>
                      </span>
                      <span id="content2">
                        {currentRecord?.potential_benefits}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan={2}>
                    <div className="flex flex-column">
                      <span id="content1">
                        <b> Potential Risks</b>
                      </span>
                      <span id="content2">
                        {currentRecord?.potential_risks}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan={2}>
                    <div className="flex flex-column">
                      <span id="content1">
                        <b>Product Description</b>
                      </span>
                      <span id="content2">
                        {currentRecord?.product_description}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan={2}>
                    <div className="flex flex-column">
                      <span id="content1">
                        <b>Evidence Support</b>
                      </span>
                      <span id="content2">
                        {currentRecord?.evidence_supporting_the_recommendation}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Approval Status</td>
                  <td>
                    {" "}
                    <Badge
                      bg={badgeStatus[currentRecord?.approval_status].badge}
                    >
                      {badgeStatus[currentRecord?.approval_status].label}
                    </Badge>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* {JSON.stringify(currentRecord)} */}
        </Card.Body>
      </Card>
    </div>
  );
};

export default ListRecommendedDrugsChild;

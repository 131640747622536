import React from "react";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import toast from "react-hot-toast";

import Accordion from "react-bootstrap/Accordion";
import OsteoperosisXray from "./OsteoperosisXray";
import EnlargedEpiphysisXray from "./EnlargedEpiphysisXray";
import IrregularSubchondralXray from "./IrregularSubchondralXray";
import NarrowingOfJointSpaceXray from "./NarrowingOfJointSpaceXray";
import SubchondralCystFormationXray from "./SubchondralCystFormationXray";
import ErosionOfJointMarginXray from "./ErosionOfJointMarginXray";
import GrossIncongruanceXray from "./GrossIncongruanceXray";
import JointDeformityXray from "./JointDeformityXray";

const XrayScores = ({ emrRecordId, emrRecord, setEmrRecord }) => {
  const [formData, setFormData] = useState({
    ankle_left_osteoporosis: null,
    ankle_right_osteoporosis: null,
    knee_left_osteoporosis: null,
    knee_right_osteoporosis: null,
    elbow_left_osteoporosis: null,
    elbow_right_osteoporosis: null,
    knee_left_enlarged_epiphysis: null,
    knee_right_enlarged_epiphysis: null,
    ankle_left_enlarged_epiphysis: null,
    ankle_right_enlarged_epiphysis: null,
    elbow_left_enlarged_epiphysis: null,
    elbow_right_enlarged_epiphysis: null,
    knee_left_irregular_subchondral_surface: null,
    knee_right_irregular_subchondral_surface: null,
    ankle_left_irregular_subchondral_surface: null,
    ankle_right_irregular_subchondral_surface: null,
    elbow_left_irregular_subchondral_surface: null,
    elbow_right_irregular_subchondral_surface: null,
    knee_left_narrowing_of_joint_space: null,
    knee_right_narrowing_of_joint_space: null,
    ankle_left_narrowing_of_joint_space: null,
    ankle_right_narrowing_of_joint_space: null,
    elbow_left_narrowing_of_joint_space: null,
    elbow_right_narrowing_of_joint_space: null,
    knee_left_subchondral_cyst_formation: null,
    knee_right_subchondral_cyst_formation: null,
    ankle_left_subchondral_cyst_formation: null,
    ankle_right_subchondral_cyst_formation: null,
    elbow_left_subchondral_cyst_formation: null,
    elbow_right_subchondral_cyst_formation: null,
    knee_left_erosion_of_joint_margins: null,
    knee_right_erosion_of_joint_margins: null,
    ankle_left_erosion_of_joint_margins: null,
    ankle_right_erosion_of_joint_margins: null,
    elbow_left_erosion_of_joint_margins: null,
    elbow_right_erosion_of_joint_margins: null,
    knee_left_articular_bone_ends: null,
    knee_right_articular_bone_ends: null,
    ankle_left_articular_bone_ends: null,
    ankle_right_articular_bone_ends: null,
    elbow_left_articular_bone_ends: null,
    elbow_right_articular_bone_ends: null,
    knee_left_joint_deformity: null,
    knee_right_joint_deformity: null,
    ankle_left_joint_deformity: null,
    ankle_right_joint_deformity: null,
    elbow_left_joint_deformity: null,
    elbow_right_joint_deformity: null,
  });

  useEffect(() => {
    if (emrRecord) {
      localStorage.setItem(
        `${emrRecordId}-xrayscores`,
        JSON.stringify(emrRecord)
      );
    }
    let xray = localStorage.getItem(`${emrRecordId}-xrayscores`);
    setFormData({ ...formData, ...JSON.parse(xray) });
  }, [emrRecord]);

  const updateEmrXray = (e) => {
    e.preventDefault();
    //changeTab(1);

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/emr/update/${emrRecordId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, xray_score: true },
    };
    axios(config)
      .then(function (response) {
        localStorage.setItem(
          `${emrRecordId}-xrayscores`,
          JSON.stringify(formData)
        );
        toast.success("Xray Score saved Successfully.");
        setEmrRecord(response.data.emr);
      })
      .catch(function (error) {
        // handleFailure(JSON.stringify(error.response.data.message));
        toast.error(JSON.stringify(error.response.data.message));
      });
  };

  return (
    <form
      style={{ marginRight: "40px" }}
      onSubmit={(e) => {
        updateEmrXray(e);
      }}
    >
      {/* {JSON.stringify(formData)} */}
      <div className="table-responsive w-100">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col" colSpan="3">
                Total Score
              </th>
              <th scope="col" colSpan="3" style={{ textAlign: "right" }}>
                <Button variant="primary" size="large" type="submit">
                  Save
                </Button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="col">Knee Left:</td>
              <td scope="col">0</td>
              <td scope="col">Knee Right:</td>
              <td scope="col">0</td>
              <td scope="col">Ankle Left:</td>
              <td scope="col">0</td>
            </tr>

            <tr>
              <td scope="col">Ankle Right:</td>
              <td scope="col">0</td>
              <td scope="col">Elbow Left:</td>
              <td scope="col">0</td>
              <td scope="col">Elbow Right:</td>
              <td scope="col">0</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>OSTEPOROSIS</Accordion.Header>
          <Accordion.Body>
            <OsteoperosisXray
              formData={formData}
              setFormData={setFormData}
              titleText="OSTEPOROSIS"
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>ENLARGED EPIPHYSIS</Accordion.Header>
          <Accordion.Body>
            <EnlargedEpiphysisXray
              formData={formData}
              setFormData={setFormData}
              titleText="ENLARGED EPIPHYSIS"
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>IRREGULAR SUBCHONDRAL SURFACE</Accordion.Header>
          <Accordion.Body>
            <IrregularSubchondralXray
              formData={formData}
              setFormData={setFormData}
              titleText="IRREGULAR SUBCHONDRAL SURFACE"
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>NARROWING OF JOINT SPACE</Accordion.Header>
          <Accordion.Body>
            <NarrowingOfJointSpaceXray
              formData={formData}
              setFormData={setFormData}
              titleText="NARROWING OF JOINT SPACE"
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>SUBCHONDRAL CYST FORMATION</Accordion.Header>
          <Accordion.Body>
            <SubchondralCystFormationXray
              formData={formData}
              setFormData={setFormData}
              titleText="SUBCHONDRAL CYST FORMATION"
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>EROSION OF JOINT MARGINS</Accordion.Header>
          <Accordion.Body>
            <ErosionOfJointMarginXray
              formData={formData}
              setFormData={setFormData}
              titleText="EROSION OF JOINT MARGINS"
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>
            GROSS INCONGRUANCE OF ARTICULAR BONE ENDS
          </Accordion.Header>
          <Accordion.Body>
            <GrossIncongruanceXray
              formData={formData}
              setFormData={setFormData}
              titleText="GROSS INCONGRUANCE ..."
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
          <Accordion.Header>
            JOINT DEFORMITY (angulation and/or displacement between articulating
            bones)
          </Accordion.Header>
          <Accordion.Body>
            <JointDeformityXray
              formData={formData}
              setFormData={setFormData}
              titleText="JOINT DEFORMITY"
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </form>
  );
};

export default XrayScores;

import "./PatientVisitsList.css";
import PatientVisitsListChild from "./PatientVisitsListChild";
import MainContentCard from "../../Home/MainContentCard";

import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

const PatientVisitsList = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="PatientVisitsListChild" route="/visits">
        <PatientVisitsListChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default PatientVisitsList;

import React from "react";
import { useEffect, useState } from "react";
import { Tabs, Tab, Button } from "react-bootstrap";
import axios from "axios";
import toast from "react-hot-toast";

import SwellingTab from "./SwellingTab";
import MuscleCrepitusTab from "./MuscleCrepitusTab";
import FlexionExtensionTab from "./FlexionExtensionTab";
import JointPainTab from "./JointPainTab";
import StrengthTab from "./StrengthTab";
import GaitScoreTab from "./GaitScoreTab";

const HjhsScore = ({ emrRecordId, jsRecord }) => {
  const [totalJointScore, setTotalJointScore] = useState(0);
  const [totalHjhsScore, setTotalHjhsScore] = useState(0);

  const [formData, setFormData] = useState({
    swelling_left_elbow: null,
    swelling_right_elbow: null,
    swelling_left_knee: null,
    swelling_right_knee: null,
    swelling_left_ankle: null,
    swelling_right_ankle: null,
    swelling_duration_left_elbow: null,
    swelling_duration_right_elbow: null,
    swelling_duration_left_knee: null,
    swelling_duration_right_knee: null,
    swelling_duration_left_ankle: null,
    swelling_duration_right_ankle: null,
    muscle_atrophy_left_elbow: null,
    muscle_atrophy_right_elbow: null,
    muscle_atrophy_left_knee: null,
    muscle_atrophy_right_knee: null,
    muscle_atrophy_left_ankle: null,
    muscle_atrophy_right_ankle: null,
    crepitus_on_motion_left_elbow: null,
    crepitus_on_motion_right_elbow: null,
    crepitus_on_motion_left_knee: null,
    crepitus_on_motion_right_knee: null,
    crepitus_on_motion_left_ankle: null,
    crepitus_on_motion_right_ankle: null,
    flexion_loss_left_elbow: null,
    flexion_loss_right_elbow: null,
    flexion_loss_left_knee: null,
    flexion_loss_right_knee: null,
    flexion_loss_left_ankle: null,
    flexion_loss_right_ankle: null,
    extension_loss_left_elbow: null,
    extension_loss_right_elbow: null,
    extension_loss_left_knee: null,
    extension_loss_right_knee: null,
    extension_loss_left_ankle: null,
    extension_loss_right_ankle: null,
    joint_pain_left_elbow: null,
    joint_pain_right_elbow: null,
    joint_pain_left_knee: null,
    joint_pain_right_knee: null,
    joint_pain_left_ankle: null,
    joint_pain_right_ankle: null,
    strength_left_elbow: null,
    strength_right_elbow: null,
    strength_left_knee: null,
    strength_right_knee: null,
    strength_left_ankle: null,
    strength_right_ankle: null,
    global_gait_score: null,
  });

  useEffect(() => {
    setTotalJointScore(
      Object.values({ ...formData, global_gait_score: "0" })
        .filter((value) => value != null)
        .reduce((prev, value) => (prev = prev + parseInt(value)), 0)
    );

    setTotalHjhsScore(
      Object.values(formData)
        .filter((value) => value != null)
        .reduce((prev, value) => (prev = prev + parseInt(value)), 0)
    );
  }, [formData]);

  // useEffect(() => {
  //   let hjhs = localStorage.getItem(`${emrRecordId}-hjhsscores`);
  //   setFormData({ ...formData, ...JSON.parse(hjhs) });
  // }, []);

  useEffect(() => {
    if (jsRecord) {
      localStorage.setItem(
        `${emrRecordId}-hjhsscores`,
        JSON.stringify(jsRecord)
      );
    }
    let hjhs = localStorage.getItem(`${emrRecordId}-hjhsscores`);
    setFormData({ ...formData, ...JSON.parse(hjhs) });
  }, [jsRecord]);

  const updateJointScores = (e) => {
    e.preventDefault();

    let userToken = localStorage.getItem("token");
    const config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/jointsscore/update/${emrRecordId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, hjhsscores: true },
    };
    axios(config)
      .then(function (response) {
        localStorage.setItem(
          `${emrRecordId}-hjhsscores`,
          JSON.stringify(formData)
        );
        toast.success("HJHSscores saved Successfully.");
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error.response.data));
      });
  };

  const handleChange = ({ currentTarget: input }) => {
    //alert([input.name]);
    setFormData({
      ...formData,
      [input.name]: input.checked,
    });
  };
  const handleChangeValue = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const dropDownChange = (selected, dropdown) => {
    //console.log("this is selected", JSON.stringify(dropdown.name));
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  return (
    <form className="" onSubmit={(e) => updateJointScores(e)}>
      <table class="table table-striped table-responsive">
        <thead>
          <tr>
            <th scope="col">Scores</th>
            {/* <th scope="col" colSpan="3" style={{ textAlign: "right" }}>
              <Button variant="primary" size="medium" type="submit">
                Save
              </Button>
            </th> */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="col">Sum of Total Joints</td>
            <td scope="col" className="text-left">
              {totalJointScore}
            </td>
            <td scope="col">HJHS Total Score</td>
            <td scope="col" className="text-left">
              {totalHjhsScore}
            </td>
          </tr>
        </tbody>
      </table>

      <Tabs
        defaultActiveKey="swelling"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="swelling" title="Swelling">
          <SwellingTab formData={formData} dropDownChange={dropDownChange} />
        </Tab>
        <Tab eventKey="muscleCrepitus" title="Muscle/Crepitus">
          <MuscleCrepitusTab
            formData={formData}
            dropDownChange={dropDownChange}
          />
        </Tab>
        <Tab eventKey="flexionExtension" title="Flexion/Extension">
          <FlexionExtensionTab
            dropDownChange={dropDownChange}
            formData={formData}
          />
        </Tab>
        <Tab eventKey="jointPain" title="Joint Pain">
          <JointPainTab dropDownChange={dropDownChange} formData={formData} />
        </Tab>
        <Tab eventKey="strength" title="Strength">
          <StrengthTab
            handleChangeValue={handleChangeValue}
            dropDownChange={dropDownChange}
            formData={formData}
          />
        </Tab>
        <Tab eventKey="gaitScore" title="Gait">
          <GaitScoreTab handleChange={handleChangeValue} formData={formData} />
        </Tab>
      </Tabs>
    </form>
  );
};

export default HjhsScore;

import React, { useEffect, useState, useRef } from "react";

import axios from "axios";
import { DataGrid } from "@material-ui/data-grid";
import Tooltip from "@mui/material/Tooltip";
import Badge from "react-bootstrap/Badge";
import { useNavigate, useLocation } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";
import CsvButton from "../../../components/DownloadCsv/CsvButton";

import "./VisitsTable.css";

const VisitsTable = ({ patientRecordId }) => {
  const [data, setData] = useState([]);
  let navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [dataCsv, setCsvData] = useState([]);
  const csvDownloadRef= useRef(null);
  //Error Logging Service
  const [{ errorLogApi }] = useAtom(gbState);
  //Sample Call: errorLogApi(err, "UsersListChild", "/users");

  useEffect(() => {
    const getDataFromServer = () => {
      // console.log(patientRecordId);
      const config = {
        "Content-Type": "application/json; charset=utf-8",
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/visits/${patientRecordId}`,
          config
        )
        .then(
          (res) => {
            const oldData = res.data.visits;
            const updatedData = oldData.map((data) => {
              return {
                ...data,
                id: data._id,
                tc_name: data.treatment_center_id.tcenters.name_tc,
              };
            });
            // console.log(updatedData);
            setData(updatedData);
          },
          (error) => {
            errorLogApi(
              error,
              "PatientReportChild",
              `/visits/${patientRecordId}`
            );
          }
        )
        .catch((err) => console.log("error", err));
    };
    getDataFromServer();
  }, []);

  const exportVisit = (v_id) => {
    const csvData = data.filter((item) => item._id === v_id).map((val,index)=>{
      return { 
            patientId:val.patientId,
            date:dateFormatter(val.date_of_visit),
            visit:(val.consultation) ? 'CONSULTATION' : 'HOSPITALIZATION',
            discharge:(val.consultation) ? 'NOT APPLICABLE' : 'CREATE DISCHARGE',
            reason:JSON.parse(val.visit_reason).label,
            center:val.tc_name
        }
    });
    setCsvData(csvData);
    const timer = setTimeout(() => {
      csvDownloadRef.current.link.click()
    }, 500);
    return () => clearTimeout(timer);
  };
  
  function dateFormatter(date) {
    const date_conv = new Date(date);
    const fullyear = date_conv.getFullYear();
    const month = date_conv.getMonth() + 1;
    const day = date_conv.getDate();
    return `${day}/${month}/${fullyear}`;
  }

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      width: 100,
      sortable: false,
      hideable: false,
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            <Tooltip title="View EMR/Visit details">
              <a onClick={() => {}}>
                <i
                  className="mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </a>
            </Tooltip>
            <Tooltip title="Export to excel">
              <div onClick={() => exportVisit(params.row._id)} >
                <i
                  className="mdi mdi-file-excel"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            {/* <Tooltip title="Delete EMR/Visit">
              <div onClick={() => {}}>
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit Visits">
              <div onClick={() => {}}>
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip> */}
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 110,
      renderCell: (params) => {
        return dateFormatter(params.row.date_of_visit);
      },
    },
    {
      field: "hospitalization",
      headerName: "Visit",
      width: 140,
      renderCell: (params) => {
        return (
          <div style={{ textAlign: "center" }}>
            {params.row.hospitalization ? (
              <Badge bg="danger">HOSPITALIZATION</Badge>
            ) : (
              <Badge bg="success">&nbsp;&nbsp; CONSULTATION &nbsp;&nbsp;</Badge>
            )}
          </div>
        );
      },
    },
    {
      field: "discharged",
      headerName: "Discharge",
      width: 170,
      renderCell: (params) => {
        return (
          <div style={{ textAlign: "center" }}>
            {!params.row.hospitalization ? (
              <Badge bg="secondary">NOT APPLICABLE</Badge>
            ) : !params.row.discharged ? (
              <Badge
                bg="primary"
                onClick={() => {}}
                style={{ cursor: "pointer" }}
              >
                + CREATE DISCHARGE
              </Badge>
            ) : (
              <Badge
                bg="success"
                onClick={() => {}}
                style={{ cursor: "pointer" }}
              >
                VIEW DISCHARGE
              </Badge>
            )}
          </div>
        );
      },
    },
    {
      field: "visit_reason",
      headerName: "Reason",
      width: 150,
      renderCell: (params) => {
        return JSON.parse(params.row.visit_reason).label;
      },
    },
    {
      field: "tc_name",
      headerName: "Center",
      width: 150,
    },
  ];

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };
  return (
    <>
      <CsvButton csvData={dataCsv} fileName="patient-visit-detail.csv" csvDownloadRef={csvDownloadRef} isIcon={true} />
      <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        displayTitle="Visits"
        checkboxSelection={false}
        style={{ height: "40vh" }}
      />
      {/* {JSON.stringify(data)} */}
    </>
  );
};

export default VisitsTable;

import React from "react";
import { Route } from "react-router-dom";

import PrivateRoutes from "../../components/Auth/PrivateRoutes";

//Import All Components
import * as Pages from "./Pages";

export const PrivateUserRoutes = (
  <Route element={<PrivateRoutes roleRequired={["PVT_USER_ACCESS"]} />}>
    <Route path="/dashboard-tc" name="Home" element={<Pages.DashboardTC />} />
    <Route path="/patients" name="Patients" element={<Pages.PatientList />} />
    <Route path="/patient/" name="Patients" element={<Pages.PatientUpdate />} />
    <Route
      path="/user/profile"
      name="My Profile"
      element={<Pages.UpdateUserProfile />}
    />
    {/* Diagnosis */}
    <Route
      path="/add-diagnosis"
      name="Add-Diagnosis"
      element={<Pages.AddDiagnosis />}
    />
    <Route
      path="/patient-add"
      name="Patient Create"
      element={<Pages.PatientCreate />}
    />
    <Route
      path="/import-patients"
      name="Import Patients"
      element={<Pages.PatientImports />}
    />
    <Route
      path="/search-patient"
      name="Patient Search"
      element={<Pages.SearchPatientList />}
    />
    {/* Death Record Management */}
    <Route
      path="/patient/drecord"
      name="Add Death Record"
      element={<Pages.PatientDeathRecord />}
    />
    <Route
      path="/patient/drecord/view"
      name="View Patient Death Record"
      element={<Pages.ViewPatientDeathRecord />}
    />
    <Route
      path="/upcoming/appointments"
      name="Up Coming Appointments"
      element={<Pages.UpcomingList />}
    />
    <Route
      path="/appointmentlist"
      name="Appointment List"
      element={<Pages.AppointmentList />}
    />
    <Route
      path="/addappointment"
      name="Add New Appointment"
      element={<Pages.AddAppointment />}
    />
    <Route
      path="/editappointment"
      name="Edit Appointment"
      element={<Pages.UpdateAppointment />}
    />
    <Route
      path="/report"
      name="View Report"
      element={<Pages.PatientReport />}
    />
    <Route
      path="/listhrecord"
      name="List Health Record"
      element={<Pages.ListHealthRecord />}
    />
    {/* Pre Registration Patient */}
    <Route
      path="/pre/register/list"
      name="Pre Registration Patient"
      element={<Pages.PreRegistrationsList />}
    />
    <Route
      path="/pre/register/add"
      name="Pre Registration Add"
      element={<Pages.PreRegistrationAdd />}
    />
    <Route
      path="/pre/register/update"
      name="Pre Registration Update"
      element={<Pages.PreRegistrationUpdate />}
    />
    <Route
      path="/create-visit/"
      name="Create Visit"
      element={<Pages.CreateVisit />}
    />
    <Route path="/visit/" name="View Visit" element={<Pages.PatientVisit />} />

    <Route
      path="/create-discharge/"
      name="Create Discharge"
      element={<Pages.CreateDischarge />}
    />
    <Route
      path="/view-discharge/"
      name="View Discharge"
      element={<Pages.ViewDischarge />}
    />
    <Route
      path="/visits/"
      name="Visits"
      element={<Pages.PatientVisitsList />}
    />
    <Route path="/patient/emr/" name="EMR" element={<Pages.UpdateEmr />} />
    <Route
      path="/tclevelreport"
      name="TC Level Report"
      element={<Pages.PatientTCLevelReport />}
    />
    <Route
      path="/reports/generic/"
      name="Generic Report"
      element={<Pages.GenericReports />}
    />
    <Route
      path="/reports/agewise/"
      name="Age-wise Report"
      element={<Pages.AgeWiseReport />}
    />
    <Route
      path="/reports/bleedwise/"
      name="Bleed-wise Report"
      element={<Pages.BleedWiseReport />}
    />
    <Route
      path="/reports/jointswise/"
      name="Joints-wise Report"
      element={<Pages.JointsWiseReport />}
    />
    <Route
      path="/reports/severitywise/"
      name="Severity-wise Report"
      element={<Pages.SeverityWiseReport />}
    />
    <Route
      path="/dose-history-tc"
      name="Dosing History"
      element={<Pages.ListTCDosingHistory />}
    />
    <Route
      path="/dashboard-nc/"
      name="Dashboard Nodal Center"
      element={<Pages.DashboardNC />}
    />
    <Route
      path="/testimonials"
      name="Testimonials"
      element={<Pages.TestimonialList />}
    />
    <Route
      path="/testimonial/add"
      name="Add Testimonials"
      element={<Pages.AddTestimonial />}
    />

    <Route
      path="/products/"
      name="List Products"
      element={<Pages.ProductsList />}
    />
    <Route
      path="/create-dose"
      name="Create Dose for Patient"
      element={<Pages.CreateDoseForPatient />}
    />
    <Route
      path="/view-dose"
      name="View Dose for Patient"
      element={<Pages.ViewDoseForPatient />}
    />
    <Route
      path="/dose/list"
      name="List Add Dose for Patient"
      element={<Pages.ViewDoseTable />}
    />
    {/* Global Patient Search */}
    <Route
      path="/global/search"
      name="Patient Search"
      element={<Pages.GlobalSearch />}
    />
    {/* Select Export to Excel */}
    <Route
      path="/export/toexcel"
      name="Patient Search"
      element={<Pages.SelectExportToExcel />}
    />
    {/* Notification Route */}
    <Route
      path="/notifications"
      name="Notifications"
      element={<Pages.Notifications />}
    />
    {/* Doctors of TC */}
    <Route path="/doctors" name="Doctors" element={<Pages.DoctorsList />} />
    {/* Add Doctor */}
    <Route
      path="/users/add-doctor"
      name="Add Doctor"
      element={<Pages.CreateDoctor />}
    />
    {/* Update Doctor */}
    <Route
      path="/doctor/update"
      name="Update Doctor"
      element={<Pages.UpdateDoctor />}
    />
  </Route>
);

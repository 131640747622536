import React from "react";
import { useEffect, useState } from "react";
import { Tabs, Tab, Button } from "react-bootstrap";
import axios from "axios";
import toast from "react-hot-toast";

import WFHBleedPainScoreTab from "./WFHBleedPainScoreTab";
import WFHSwellingRomTab from "./WFHSwellingRomTab";
import FfdMuscleScoreTab from "./FfdMuscleScoreTab";
import CrepitusInstabilityTab from "./CrepitusInstabilityTab";
import WFHAxialDeformityTab from "./WFHAxialDeformityTab";

const WFHScores = ({ emrRecordId, jsRecord }) => {
  const [formData, setFormData] = useState({
    knee_left_bleed_score: null,
    knee_right_bleed_score: null,
    ankle_left_bleed_score: null,
    ankle_right_bleed_score: null,
    elbow_left_bleed_score: null,
    elbow_right_bleed_score: null,
    knee_left_pain: null,
    knee_right_pain: null,
    ankle_left_pain: null,
    ankle_right_pain: null,
    elbow_left_pain: null,
    elbow_right_pain: null,
    knee_left_swelling: null,
    knee_right_swelling: null,
    ankle_left_swelling: null,
    ankle_right_swelling: null,
    elbow_left_swelling: null,
    elbow_right_swelling: null,
    knee_left_rom: null,
    knee_right_rom: null,
    ankle_left_rom: null,
    ankle_right_rom: null,
    elbow_left_rom: null,
    elbow_right_rom: null,
    knee_left_ffd: null,
    knee_right_ffd: null,
    ankle_left_ffd: null,
    ankle_right_ffd: null,
    elbow_left_ffd: null,
    elbow_right_ffd: null,
    knee_left_muscle_atrophy: null,
    knee_right_muscle_atrophy: null,
    ankle_left_muscle_atrophy: null,
    ankle_right_muscle_atrophy: null,
    elbow_left_muscle_atrophy: null,
    elbow_right_muscle_atrophy: null,
    knee_left_crepitus: null,
    knee_right_crepitus: null,
    ankle_left_crepitus: null,
    ankle_right_crepitus: null,
    elbow_left_crepitus: null,
    elbow_right_crepitus: null,
    knee_left_ad_knee: null,
    knee_right_ad_knee: null,
    ankle_left_ad_knee: null,
    ankle_right_ad_knee: null,
    elbow_left_ad_knee: null,
    elbow_right_ad_knee: null,
    knee_left_ad_ankle: null,
    knee_right_ad_ankle: null,
    ankle_left_ad_ankle: null,
    ankle_right_ad_ankle: null,
    elbow_left_ad_ankle: null,
    elbow_right_ad_ankle: null,
    knee_left_instability: null,
    knee_right_instability: null,
    ankle_left_instability: null,
    ankle_right_instability: null,
    elbow_left_instability: null,
    elbow_right_instability: null,
  });

  const [totalWFHScore, setTotalWFHScore] = useState(0);

  useEffect(() => {
    setTotalWFHScore(
      Object.values(formData)
        .filter((value) => value != null)
        .reduce((prev, value) => (prev = prev + parseInt(value)), 0)
    );
  }, [formData]);

  // useEffect(() => {
  //   let wfh = localStorage.getItem(`${emrRecordId}-wfhscores`);
  //   setFormData({ ...formData, ...JSON.parse(wfh) });
  //   console.log(JSON.parse(wfh));
  // }, []);

  useEffect(() => {
    if (jsRecord) {
      localStorage.setItem(
        `${emrRecordId}-wfhscores`,
        JSON.stringify(jsRecord)
      );
    }
    let wfh = localStorage.getItem(`${emrRecordId}-wfhscores`);
    setFormData({ ...formData, ...JSON.parse(wfh) });
  }, [jsRecord]);

  const updateJointScores = (e) => {
    e.preventDefault();

    //changeTab(1);

    //  alert("Click to submit");
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/jointsscore/update/${emrRecordId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, wfhscores: true },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        //handleSuccess(JSON.stringify(response.data));
        localStorage.setItem(
          `${emrRecordId}-wfhscores`,
          JSON.stringify(formData)
        );
        toast.success("Wfh Score saved Successfully.");
      })
      .catch(function (error) {
        //handleFailure(JSON.stringify(error.response.data.patient));
        toast.error(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error.response.data));
      });
  };

  const handleChange = ({ currentTarget: input }) => {
    //alert([input.name]);
    setFormData({
      ...formData,
      [input.name]: input.checked,
    });
  };
  const handleChangeValue = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const dropDownChange = (selected, dropdown) => {
    //console.log("this is selected", JSON.stringify(dropdown.name));
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  return (
    <form className="" onSubmit={updateJointScores}>
      {/* {JSON.stringify(jsRecord)} */}
      <div className="table-responsive w-100" style={{ marginRight: "40px" }}>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Scores</th>
              <th scope="col" colSpan="3" style={{ textAlign: "right" }}>
                <Button variant="primary" size="medium" type="submit">
                  Save
                </Button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="col" width="25%">
                &nbsp;
              </td>
              <td scope="col" width="45%">
                &nbsp;
              </td>
              <td scope="col" className="text-align-right">
                WFH Total Score
              </td>
              <td scope="col" className="text-left">
                {totalWFHScore}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* {JSON.stringify(formData)} */}
      <Tabs
        defaultActiveKey="Bleed/Pain"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="Bleed/Pain" title="Bleed/Pain">
          <WFHBleedPainScoreTab
            dropDownChange={dropDownChange}
            formData={formData}
          />
        </Tab>
        <Tab eventKey="Swelling/ROM" title="Swelling/ROM">
          <WFHSwellingRomTab
            dropDownChange={dropDownChange}
            formData={formData}
          />
        </Tab>
        <Tab eventKey="FFD/MUSCLE" title="FFD/MUSCLE">
          <FfdMuscleScoreTab
            dropDownChange={dropDownChange}
            formData={formData}
          />
        </Tab>
        <Tab eventKey="CREPITUS/INSTABILITY" title="CREPITUS/INSTABILITY">
          <CrepitusInstabilityTab
            dropDownChange={dropDownChange}
            formData={formData}
          />
        </Tab>
        <Tab eventKey="axialDeformity" title="AXIAL DEFORMITY">
          <WFHAxialDeformityTab
            handleChangeValue={handleChangeValue}
            dropDownChange={dropDownChange}
            formData={formData}
            fieldName="axialDeformity"
          />
        </Tab>
      </Tabs>
    </form>
  );
};

export default WFHScores;

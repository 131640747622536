import React, { useContext } from "react";

import { Button } from "react-bootstrap";

import Tooltip from "@mui/material/Tooltip";
import { BsFileEarmarkPlus } from "react-icons/bs";
import { DataGrid } from "@material-ui/data-grid";
import BackButton from "../../../components/Buttons/BackButton";
import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import toast from "react-hot-toast";
//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

//Context import
import AuthContext from "../../../components/shared/Context";
import PatientInfoBlock from "../../../components/PatientProfile/PatientInfoBlock";

const PatientVisitsListChild = () => {
  let navigate = useNavigate();
  const { state } = useLocation();
  let patientRecordId = state.id;
  const [data, setData] = useState([]);

  const { loggedInUserDetails, setPatientDiagnosis, setFieldLabelFromArray } =
    useContext(AuthContext);

  //Error Logging Service
  const [{ errorLogApi }] = useAtom(gbState);

  const handleDelete = (id) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete visit (y/n) ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (id) => {
    //  setData(data.filter((item) => item.id !== id));
    //alert(id);
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(`${process.env.REACT_APP_API_URL}/visits/delete/${id}`, config)
      .then((response) => {
        //alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((err) => alert(err));
  };

  const getDataFromServer = () => {
    //console.log(patientRecordId);
    const config = {
      "Content-Type": "application/json; charset=utf-8",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };

    let urlLink = `${process.env.REACT_APP_API_URL}/visits/${patientRecordId}`;

    if (loggedInUserDetails.role === "FULL_ACCESS") {
      urlLink = `${process.env.REACT_APP_API_URL}/visits/admin/${patientRecordId}`;
    }

    axios
      .get(urlLink, config)
      .then((res) => {
        const oldData = res.data.visits;
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data._id,
          };
        });
        // console.log(updatedData);
        setData(updatedData);
      })
      .catch((err) => {
        errorLogApi(err, "PatientVisitsListChild", "/visits/");
      });
  };

  useEffect(() => {
    const getDataFromServer = () => {
      // console.log(patientRecordId);
      const config = {
        "Content-Type": "application/json; charset=utf-8",
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };

      let urlLink = `${process.env.REACT_APP_API_URL}/visits/${patientRecordId}`;

      if (loggedInUserDetails.role === "FULL_ACCESS") {
        urlLink = `${process.env.REACT_APP_API_URL}/visits/admin/${patientRecordId}`;
      }

      axios
        .get(urlLink, config)
        .then((res) => {
          const oldData = res.data.visits;
          const updatedData = oldData.map((data) => {
            return {
              ...data,
              id: data._id,
              tc_name: data.treatment_center_id.tcenters.name_tc,
            };
          });
          // console.log(updatedData);
          setData(updatedData);
        })
        .catch((err) => {
          errorLogApi(err, "PatientVisitsListChild", "/visits/");
        });
    };

    getDataFromServer();
  }, []);

  function dateFormatter(date) {
    const date_conv = new Date(date);
    const fullyear = date_conv.getFullYear();
    const month = date_conv.getMonth() + 1;
    const day = date_conv.getDate();
    return `${day}/${month}/${fullyear}`;
  }

  const handleOpenVisit = (v_id) => {
    //alert(v_id);

    const config = {
      "Content-Type": "application/json; charset=utf-8",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}/emr/${v_id}`, config)
      .then((res) => {
        const responseData = res.data.emrs;
        //  console.log({ emr_id: responseData._id });
        navigate(
          `/patient/emr/`,
          {
            state: {
              // pRecord: state,
              // emrRecordId: responseData._id,
              patientData: state,
              emr_id: responseData._id,
            },
          },
          {
            replace: true,
          }
        );
      })
      .catch((err) => {
        errorLogApi(err, "PatientVisitsListChild", "/visits/");
        console.log("error", err);
      });
  };

  const handleCreateVisit = (pat_id) => {
    if (state?.patient_death_status)
      return toast.error("Cannot create the visit for deceased patient");

    if (state) {
      navigate(
        `/create-visit/`,
        { state: { ...state } },
        {
          replace: true,
        }
      );
    }
  };

  const viewPatientVisit = (row) => {
    //console.log(row);
    //console.log(row.id);
    const config = {
      "Content-Type": "application/json; charset=utf-8",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    axios
      .get(`${process.env.REACT_APP_API_URL}/emr/${row.id}`, config)
      .then((res) => {
        const responseData = res.data.emrs;
        // console.log(res);
        //console.log({ emr_id: responseData._id });
        //return;
        navigate(
          `/visit/`,
          { state: { visit: row, patient: state, emr: responseData } },
          {
            replace: true,
          }
        );
      })
      .catch((err) => {
        errorLogApi(err, "PatientVisitsListChild", "/visits/");
        console.log("error", err);
      });
  };

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      width: 80,
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            <Tooltip title="View EMR/Visit details">
              <a onClick={() => viewPatientVisit(params.row)}>
                <i
                  className="mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </a>
            </Tooltip>
            <Tooltip title="Edit Visits">
              <div
                onClick={() => {
                  if (
                    params?.row?.treatment_center_id?._id !==
                    loggedInUserDetails?.tc_id
                  )
                    return toast.error("You are not Allowed to Edit!");
                  handleOpenVisit(params.row._id);
                }}
              >
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete EMR/Visit">
              <div
                onClick={() => {
                  if (params.row.dose_complete_status) {
                    toast.error("Cannot be deleted!");
                  } else {
                    if (
                      params?.row?.treatment_center_id?._id !==
                      loggedInUserDetails?.tc_id
                    )
                      return toast.error("You are not Allowed to Delete!");
                    handleDelete(params.row._id);
                  }
                }}
              >
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    loggedInUserDetails.role === "FULL_ACCESS"
      ? {
          field: "delete_status",
          headerName: "Status",
          width: 130,
          renderCell: ({ row }) => {
            if (row.delete_status) {
              return <Badge bg="danger">&nbsp;&nbsp;DELETED&nbsp;&nbsp;</Badge>;
            } else {
              return <Badge bg="success">AVAILABLE</Badge>;
            }
          },
        }
      : "",
    {
      field: "createdAt",
      headerName: "Date",
      width: 110,
      renderCell: (params) => {
        return dateFormatter(params?.row?.date_of_visit);
      },
    },
    {
      field: "hospitalization",
      headerName: "Visit",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      align: "center",
      width: 130,
      renderCell: (params) => {
        return (
          <div style={{ textAlign: "center" }}>
            {params.row.hospitalization ? (
              <Badge bg="danger">HOSPITALIZATION</Badge>
            ) : (
              <Badge bg="success">&nbsp;&nbsp; CONSULTATION &nbsp;&nbsp;</Badge>
            )}
          </div>
        );
      },
    },
    {
      field: "discharged",
      headerName: "Discharge",
      width: 130,
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      align: "center",
      renderCell: (params) => {
        return (
          <div style={{ textAlign: "center" }}>
            {!params.row.hospitalization ? (
              <Badge bg="secondary">NOT APPLICABLE</Badge>
            ) : !params.row.discharged ? (
              <Badge
                bg="primary"
                onClick={() =>
                  navigate("/create-discharge/", {
                    state: { visit: params.row, patient: state },
                  })
                }
                style={{ cursor: "pointer" }}
              >
                + CREATE DISCHARGE
              </Badge>
            ) : (
              <Badge
                bg="success"
                onClick={() =>
                  navigate("/view-discharge/", {
                    state: { visit: params.row, patient: state },
                  })
                }
                style={{ cursor: "pointer" }}
              >
                VIEW DISCHARGE
              </Badge>
            )}
          </div>
        );
      },
    },
    {
      field: "visit_reason",
      headerName: "Reason",
      width: 150,
      renderCell: (params) => {
        return JSON.parse(params?.row?.visit_reason)?.label;
      },
    },

    {
      field: "Dose",
      headerName: "Dose",
      width: 140,
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      align: "center",
      renderCell: (params) => {
        if (params.row.dose_complete_status) {
          return (
            <>
              {/* <button
                className="btn btn-success btn-sm"
                onClick={() =>
                  navigate("/view-dose", {
                    state: { visit: params.row, patient: state },
                  })
                }
              >
                {" "}
                View Dose
              </button> */}
              &nbsp;
              <button
                className="btn btn-warning btn-sm"
                onClick={() =>
                  navigate("/dose/list", {
                    state: { visit: params.row, patient: state },
                  })
                }
              >
                {" "}
                <i className="mdi mdi-format-list-bulleted"></i> List Doses
              </button>
            </>
          );
        } else {
          // if (
          //   params?.row?.date_of_visit?.slice(0, 10) !==
          //   new Date().toISOString().slice(0, 10)
          // ) {
          //   return (
          //     <button
          //       className="btn btn-warning btn-sm"
          //       onClick={() =>
          //         navigate("/create-dose", {
          //           state: { visit: params.row, patient: state },
          //         })
          //       }
          //       disabled={true}
          //     >
          //       {" "}
          //       Create Dose
          //     </button>
          //   );
          // } else
          //   return (
          //     <button
          //       className="btn btn-warning btn-sm"
          //       onClick={() =>
          //         navigate("/create-dose", {
          //           state: { visit: params.row, patient: state },
          //         })
          //       }
          //       disabled={
          //         params?.row?.treatment_center_id?._id !==
          //         loggedInUserDetails?.tc_id
          //           ? true
          //           : false
          //       }
          //     >
          //       {" "}
          //       Create Dose
          //     </button>
          //   );
          return (
            <button
              className="btn btn-warning btn-sm"
              onClick={() =>
                navigate("/create-dose", {
                  state: { visit: params.row, patient: state },
                })
              }
              disabled={
                params?.row?.treatment_center_id?._id !==
                loggedInUserDetails?.tc_id
                  ? true
                  : false
              }
            >
              {" "}
              Create Dose
            </button>
          );
        }
      },
    },
    {
      field: "tc_name",
      headerName: "Center",
      width: 180,
      align: "center",
      renderCell: (params) => <div>{params?.row?.tc_name}</div>,
    },
  ];

  return (
    <div className="patientVisitListContainer1">
      {/* {JSON.stringify(data)} */}
      <span className="card-title">
        <h3>Patient Visit(s) : {data?.length && data?.length}</h3>
      </span>
      <div className="submitButton">
        {/* <h1 className="newPatientTitle">New Patient</h1> */}
        <div className="patientUpdateButton">
          <Button
            size="medium"
            onClick={() => handleCreateVisit(patientRecordId)}
            variant="success"
            className="patientListEdit"
          >
            <BsFileEarmarkPlus /> Create Visits
          </Button>{" "}
          &nbsp; &nbsp;
          <BackButton />
          <Button
            size="medium"
            onClick={() => navigate(-1)}
            variant="secondary"
            className="patientListEdit"
          >
            Back
          </Button>
        </div>
      </div>
      <div className="mb-2">
        <PatientInfoBlock selectedPatientProfile={state} />
      </div>

      <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        displayTitle="Visits"
        checkboxSelection
        style={{ height: "40vh" }}
      />
      {/* {JSON.stringify(state)} */}
    </div>
  );
};

export default PatientVisitsListChild;

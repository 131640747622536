import "./PatientUpdate.css";

import MainContent from "../../Home/MainContent";
import Card from "react-bootstrap/Card";
import PatientUpdateChild from "./PatientUpdateChild";
import { useLocation } from "react-router-dom";

import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

const PatientUpdate = () => {
  const { state } = useLocation();
  let id = state;
  return (
    <MainContent>
      <Card style={{ width: "100%", marginTop: "10px", padding: "30px" }}>
        <Card.Body>
          <ErrorBoundaryRoutes page="PatientUpdateChild" route="/patient">
            <PatientUpdateChild patient_id={id} />
          </ErrorBoundaryRoutes>
        </Card.Body>
      </Card>
    </MainContent>
  );
};

export default PatientUpdate;

import { useEffect, useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import { useNavigate } from "react-router-dom";

import { Card } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import axios from "axios";
import { Button, FormControl } from "react-bootstrap";
import { DatePicker, Space } from "antd";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import jwt_decode from "jwt-decode";

import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

//Context import
import AuthContext from "../../components/shared/Context";
import PatientStats from "./PatientStats";

import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import PatientDataProcessor from "./PatientDataProcessor";
const { RangePicker } = DatePicker;

const EntryLogsChild = () => {
  const { checkLoggedInStatus, loggedInUserDetails } = useContext(AuthContext);
  const [colLabel, setColLabel] = useState("Total Patients");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  let navigate = useNavigate();

  //Error Logging Service
  const [{ errorLogApi, customEncrypt }] = useAtom(gbState);

  const [result, setResult] = useState(null);
  const [durationResult, setDurationResult] = useState([]);
  //date range selector code
  const [dates, setDates] = useState(null);
  const [dateValue, setDateValue] = useState(null);
  const [dateInterval, setDateInterval] = useState({});
  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], "days") > 365;
    const tooEarly = dates[1] && dates[1].diff(current, "days") > 365;
    return !!tooEarly || !!tooLate;
  };
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const getDataFromServer = () => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage?.getItem("token")}` },
    };

    const bodyParameters = {
      key: "value",
    };

    let urlLink = `${process.env.REACT_APP_API_URL}/patient/${customEncrypt(
      loggedInUserDetails?.tc_id
    )}`;
    if (loggedInUserDetails?.role === "FULL_ACCESS") {
      urlLink = `${process.env.REACT_APP_API_URL}/patient/`;
    }

    if (loggedInUserDetails?.role === "ST_ADM_ACCESS") {
      urlLink = `${process.env.REACT_APP_API_URL}/patient/state/${loggedInUserDetails?.state}`;
    }

    axios
      .get(urlLink, config)
      .then((res) => {
        const oldData = res?.data?.patients;
        const updatedData = oldData?.map((data) => {
          return {
            ...data,
            id: data?._id,
            diagnosis: data?.diagnoses?.diagnosis_name,
            state: data?.tc_state,
          };
        });
        //console.log(updatedData);
        setData(updatedData);
        setUnfilteredData(updatedData);
      })
      .catch((err) => {
        errorLogApi(err, "PatientListChild", urlLink);
      });
  };

  useEffect(() => {
    let user = jwt_decode(localStorage?.getItem("token"));

    const getDataFromServer = () => {
      const config = {
        headers: { Authorization: `Bearer ${localStorage?.getItem("token")}` },
      };

      const bodyParameters = {
        key: "value",
      };

      let urlLink = `${process.env.REACT_APP_API_URL}/patient/${customEncrypt(
        user?.tc_id
      )}`;
      if (loggedInUserDetails?.role === "FULL_ACCESS") {
        urlLink = `${process.env.REACT_APP_API_URL}/patient/`;
      }

      if (loggedInUserDetails?.role === "ST_ADM_ACCESS") {
        urlLink = `${process.env.REACT_APP_API_URL}/patient/state/${
          user && user?.state
        }`;
      }

      axios
        .get(urlLink, config)
        .then(
          (res) => {
            const oldData = res?.data?.patients;
            const updatedData = oldData.map((data) => {
              return {
                ...data,
                id: data?._id,
                diagnosis: data?.diagnoses?.diagnosis_name,
                state: data?.tc_state,
              };
            });
            //console.log(updatedData);
            setData(updatedData);
            setUnfilteredData(updatedData);
          },
          (error) => {
            if (error?.response?.data?.message) {
              if (
                error?.response?.data?.message.toLowerCase().includes("invalid")
              ) {
                navigate("/login");
              }
            }
            //toast.error("DATA FETCHING ERROR ! Contact Admin");
            errorLogApi(error, "PatientListChild", urlLink);
          }
        )
        .catch((err) => {
          //console.log("error api call ", err.response.data.message);
          if (err) {
            errorLogApi(err, "PatientListChild", urlLink);
          }

          if (err?.response?.data?.message) {
            if (
              err?.response?.data?.message?.toLowerCase().includes("invalid")
            ) {
              navigate("/login");
            }
          }
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 3000); // 3 seconds delay
        });
    };

    getDataFromServer();
  }, []);

  const badgeColorClass = {
    login: "success",
    delete: "danger",
    update: "warning",
    create: "primary",
    failed: "danger",
  };

  const columns = [
    {
      field: "Action",
      headerName: "Activity",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 120,
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            <Tooltip title="View Error">
              <a onClick={() => viewRecord(params.row)}>
                {/* <button className='patientListEdit'>Edit</button> */}
                <i
                  className="mdi mdi-eye-circle-outline"
                  style={{
                    fontSize: "15px",
                    color: "black",
                    cursor: "pointer",
                  }}
                ></i>
              </a>
            </Tooltip>
            &nbsp;&nbsp;
            <div>
              <Badge
                bg={
                  params?.row?.activity
                    ? badgeColorClass[`${params?.row?.activity?.toLowerCase()}`]
                    : "success"
                }
              >
                {params?.row?.activity?.toUpperCase()}
              </Badge>
            </div>
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 180,
    },
    {
      field: "page",
      headerName: "Component",
      width: 180,
    },
    {
      field: "page_route",
      headerName: "Route",
      width: 160,
    },
    {
      field: "user",
      headerName: "User",
      width: 160,
    },
    {
      field: "access_level",
      headerName: "Access Level",
      width: 160,
    },
    {
      field: "tc_name",
      headerName: "TC Name",
      width: 160,
    },
  ];

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
    // setMarkAsResolved(false);
  };

  const unfilterData = data;
  const handleSearch = (e) => {
    // console.log("Search Value...", e);

    if (e) {
      setData(
        unfilterData.filter((item) => {
          return item.tc_detail?.tcenters?.name_tc
            ?.toLowerCase()
            .includes(e.toLowerCase());
        })
      );
    } else {
      getDataFromServer();
    }
  };

  const searchRecordsBetweenDates = (field) => {
    if (dateInterval) {
      //alert("started");
      const data_res = data.filter((d) => {
        var time = new Date(d[field]).getTime();
        return dateInterval.sd <= time && time <= dateInterval.ed;
      });
      setDurationResult(data_res);
      setData(data_res);
    }
  };

  useEffect(() => {
    if (dateValue) {
      setDateInterval({
        sd: new Date(dateValue[0]).getTime(),
        ed: new Date(dateValue[1]).getTime(),
      });
    }
  }, [dateValue]);

  // if (loading) {
  //   return <LoadingComponent />;
  // }

  return (
    <div>
      <div>
        <h3>Patient Statistics by Treatment Center</h3>
        <div className="mb-10">
          <Space direction="vertical" size={12}>
            <RangePicker
              value={dates || dateValue}
              style={{ height: "36px" }}
              disabledDate={disabledDate}
              onCalendarChange={(val) => setDates(val)}
              onChange={(val) => setDateValue(val)}
              onOpenChange={onOpenChange}
              format="YYYY-MM-DD"
            />
          </Space>{" "}
          &nbsp;&nbsp;
          <Button
            variant="info"
            onClick={() => {
              setColLabel("Patients Created");
              searchRecordsBetweenDates("createdAt");
            }}
            disabled={dateInterval.sd ? false : true}
          >
            Created
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="info"
            onClick={() => {
              setColLabel("Patients Updated");
              searchRecordsBetweenDates("updatedAt");
            }}
            disabled={dateInterval.sd ? false : true}
          >
            Updated
          </Button>
          &nbsp;&nbsp;
          <Button
            onClick={() => {
              // setResult([]);
              setDurationResult([]);
              setDateValue([]);
              //getDataFromServer();
              setColLabel("Total Patients");
              setData(unfilteredData);
            }}
          >
            Reset
          </Button>
        </div>
        <div className="patientListSearch">
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">
              Search Treatment Center
            </InputGroup.Text>
            <Form.Control
              placeholder=""
              aria-label="Search"
              aria-describedby="basic-addon1"
              name="searchPatients"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </InputGroup>
        </div>
      </div>{" "}
      {/* <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        displayTitle="Blood Products"
        // checkboxSelection
        style={{ height: "60vh" }}
      /> */}
      {loading ? (
        <LoadingComponent />
      ) : (
        // <PatientStats data={data} colLabel={colLabel} />
        <PatientDataProcessor jsonData={data} />
      )}
      {/* {loading ? (
        <LoadingComponent />
      ) : (
        <PatientStats data={data} colLabel={colLabel} />
      )} */}
      {/* Modal Popup */}
      <Modal show={openModal} size="lg" centered>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent
            currentRecord={currentRecord}
            badgeColorClass={badgeColorClass}
          />
        </Modal.Body>
      </Modal>
      {/* {JSON.stringify(data)} */}
    </div>
  );
};

const ModalContent = ({ currentRecord, badgeColorClass }) => {
  return (
    <div className="container-fluid legend">
      <Card>
        <Card.Header>
          <i className="fas fa-align-justify" />
          <strong>Log Details</strong>{" "}
        </Card.Header>
        <Card.Body>
          <CardTableRow rowName="Component" rowValue={currentRecord?.page} />
          <CardTableRow rowName="Route" rowValue={currentRecord?.page_route} />
          <CardTableRow
            rowName="Date Time"
            rowValue={currentRecord?.createdAt}
          />
          <CardTableRow
            rowName="IP Info."
            rowValue={currentRecord?.ip_information}
          />
          <CardTableRow
            rowName="Activity"
            rowValue={
              <Badge
                bg={
                  currentRecord?.activity
                    ? badgeColorClass[
                        `${currentRecord?.activity?.toLowerCase()}`
                      ]
                    : "success"
                }
              >
                {currentRecord?.activity?.toUpperCase()}
              </Badge>
            }
          />
          <div className="row">
            <div className="col-md-12 col-xs-12 divTableCell">
              <b> Log Details: </b>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12 col-xs-12 divTableCell"
              style={{
                flex: 1,
                flexWrap: "wrap",
                flexShrink: 1,
                wordBreak: "break-word",
              }}
            >
              {currentRecord?.log_detail
                ? currentRecord?.log_detail
                : currentRecord?.data}
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <i className="fas fa-align-justify" />
          <strong>User Details</strong>
          {/* {JSON.stringify(currentRecord?.user_id)} */}
        </Card.Header>
        <Card.Body>
          <CardTableRow rowName="User" rowValue={currentRecord?.user} />
          <CardTableRow rowName="Role" rowValue={currentRecord?.access_level} />

          <CardTableRow
            rowName="Mobile"
            rowValue={currentRecord?.user_id?.mobile}
          />
          <CardTableRow
            rowName="Email"
            rowValue={currentRecord?.user_id?.email}
          />
          <CardTableRow
            rowName="State"
            rowValue={currentRecord?.user_id?.tc_state}
          />
          <CardTableRow
            rowName="District"
            rowValue={currentRecord?.user_id?.tc_district}
          />
          <CardTableRow rowName="TC" rowValue={currentRecord?.user_id?.tc_id} />
          {/* {JSON.stringify(currentRecord?.user_id)} */}
        </Card.Body>
      </Card>
    </div>
  );
};

const CardTableRow = ({ rowName, rowValue }) => {
  return (
    <div className="row">
      <div
        className="col-md-6 col-xs-6 divTableCell"
        style={{ wordBreak: "break-word" }}
      >
        <b> {rowName}: </b>
      </div>
      <div
        className="col-md-6 col-xs-6 divTableCell"
        style={{ wordBreak: "break-word" }}
      >
        {rowValue}
      </div>
    </div>
  );
};

export default EntryLogsChild;

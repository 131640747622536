import "./CreateVisit.css";

import { useState, useContext, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import toast from "react-hot-toast";
import VisitTab from "./VisitTab";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import "bootstrap/dist/css/bootstrap.min.css";
import BackButton from "../../../components/Buttons/BackButton";

//Context import
import AuthContext from "../../../components/shared/Context";

const CreateVisitChild = ({ patientRecordId, patientProfileData }) => {
  return (
    <div className="patient-create">
      <FormElement
        patientRecordId={patientRecordId}
        patientProfileData={patientProfileData}
      />
    </div>
  );
};

const FormElement = ({ patientRecordId, patientProfileData }) => {
  const { loggedInUserDetails, selectedPatientProfile } =
    useContext(AuthContext);
  moment.tz.setDefault("Asia/Kolkata");
  //Error Logging Service
  const [{ errorLogApi }] = useAtom(gbState);

  // const [patientProfileStatus, setPatientProfileStatus] = useState(false);
  // const [patientProfile, setPatientProfile] = useState({});
  const [latestWHRecord, setLatestWHRecord] = useState();

  const visitTypeInput = useRef();
  const doctorInput = useRef();
  const visitDateInput = useRef();
  const visitReasonInput = useRef();
  const visitWeightInput = useRef();
  const visitHeightInput = useRef();
  const visitTypeInput1 = useRef();

  let navigate = useNavigate();

  //const [patientId, setPatientId] = useState("");
  const [doctors, setDoctors] = useState([]);
  let defaultDate = new Date();
  const [date, setDate] = useState(defaultDate);
  const [patientHomeTC, setPatientHomeTC] = useState("");
  defaultDate.setDate(defaultDate.getDate());

  const today = new Date();

  const [formData, setFormData] = useState({
    patientId: patientRecordId,
    date_of_visit: patientProfileData?.appointment_date
      ? moment.utc(patientProfileData?.appointment_date, "YYYY-MM-DD")
      : moment.utc(today, "YYYY-MM-DD").startOf("day"),
    hospitalization: null,
    consultation: null,
    ward_clinic_opd_number: "",
    doctor_id: "",
    visit_reason: patientProfileData?.visit_reason
      ? patientProfileData?.visit_reason
      : null,
    visit_type: patientProfileData?.visit_type
      ? patientProfileData?.visit_type
      : null,
    reason_others: "",
    weight: patientProfileData?.weight,
    height: patientProfileData?.height,
    liver: null,
    spleen: null,
    stay_cost: null,
    food_cost: null,
    wages_lost_by_parents: null,
    school_absentism: null,
    travel_cost: null,
    other_direct_indirect_cost: null,
    hcp_indirect_cost: null,
    hemoglobin_initial_hb: null,
    hemoglobin_final_hb: null,
    pre_transfusion_hb: null,
    post_transfusion_hb: null,
    transfusion_regimen: null,
    external_visit: false,
    travel_mode: null,
    payment_status: null,
    transportation_support: null,
    travel_distance: null,
    treatment_center_id: loggedInUserDetails.tc_id,
    tc_state: loggedInUserDetails.state,
    createdBy: loggedInUserDetails._id,
  });

  // useEffect(() => {
  //   const loadPatientProfile = () => {
  //     const userToken = localStorage.getItem("token");
  //     if (!patientRecordId) return toast.error("Invalid Profile");
  //     //console.log(patientRecordId);
  //     axios({
  //       method: "GET",
  //       url: `${process.env.REACT_APP_API_URL}/patient/profile/${patientRecordId}`,
  //       headers: {
  //         Authorization: `Bearer ${userToken}`,
  //       },
  //     })
  //       .then((response) => {
  //         setPatientHomeTC(response?.data?.patient?.treatment_center);
  //         console.log(
  //           JSON.stringify(response?.data?.patient?.treatment_center)
  //         );
  //       })
  //       .catch((error) => {
  //         errorLogApi(error, "CreateVisitChild", "/create-visit/");
  //         //console.log(JSON.stringify(error));
  //       });
  //   };

  //   loadPatientProfile();
  // }, [patientRecordId]);

  useEffect(() => {
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/user/doc/${loggedInUserDetails.tc_id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.doctors));
        const doctorsData = response.data.doctors;
        setDoctors(doctorsData);
        // console.log(doctorsData);
      })
      .catch(function (error) {
        //   console.log(error.message);
      });
  }, []);

  useEffect(() => {
    const getLatestWeightHeight = () => {
      const userToken = localStorage.getItem("token");
      // console.log(patientRecordId);

      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/visits/latest-weight/${patientRecordId}`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
        .then((response) => {
          if (response?.data?.latestRecord) {
            setLatestWHRecord(response?.data?.latestRecord);
          }
        })
        .catch((error) => {
          errorLogApi(error, "GetLatestWeight", "visits/latest-weight");
        });
    };

    getLatestWeightHeight();
  }, []);

  useEffect(() => {
    setFormData({
      ...formData,
      weight: latestWHRecord?.weight,
      height: latestWHRecord?.height,
    });
  }, [latestWHRecord]);

  const handleVisitRegistration = (e) => {
    e.preventDefault();
    //alert(JSON.stringify({ ...formData }));

    if (
      loggedInUserDetails.role === "ST_ADM_ACCESS" ||
      loggedInUserDetails.role === "FULL_ACCESS"
    ) {
      toast.error(
        "State Admin cannot create the Visit record, Kindly login as Doctor/Nurse/Deo"
      );
      return;
    }

    if (formData.date_of_visit === null) {
      visitDateInput.current.focus();
      toast.error("Please select visit Date");
      return;
    }

    if (formData.consultation === null) {
      visitTypeInput.current.focus();
      toast.error("Please select visit type");
      return;
    }

    if (formData.doctor_id === "") {
      doctorInput.current.focus();
      toast.error("Please select Doctor");
      return;
    }

    //visit_reason
    if (formData.visit_reason === "") {
      visitReasonInput.current.focus();
      toast.error("Please select Visit Reason");
      return;
    }

    //weight
    if (formData.weight === null) {
      visitWeightInput.current.focus();
      toast.error("Please Enter Weight");
      return;
    }
    //height
    if (formData.height === null) {
      visitHeightInput.current.focus();
      toast.error("Please enter Height");
      return;
    }

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/visits/add/`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        //${response.data.patientEMR}
        toast.success("Visit Created Successfully.");
        setTimeout(() => {
          navigate(
            `/patient/emr/`,
            {
              state: {
                patientData: patientProfileData,
                emr_id: response.data.patientEMR,
              },
            },
            {
              replace: true,
            }
          );
        }, 2000);
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data.message));
        errorLogApi(error, "CreateVisitChild", "/create-visit/");
        //console.log(error);
      });
  };

  const checkNonNegative = [
    "stay_cost",
    "food_cost",
    "wages_lost_by_parents",
    "school_absentism",
    "travel_cost",
    "other_direct_indirect_cost",
    "hcp_indirect_cost",
    "weight",
    "height",
    "travel_distance",
  ];

  useEffect(() => {
    // Iterate over each field in checkNonNegative
    checkNonNegative.forEach((field) => {
      // Check if the field's value is less than 0
      if (formData[field] < 0) {
        // Update the specific field to null
        setFormData((prevFormData) => ({
          ...prevFormData,
          [field]: null,
        }));
      }
    });
  }, [formData]); // Dependency array includes formData to trigger the effect when formData changes

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Check if the field is in the checkNonNegative array and the value is non-negative
    if (
      checkNonNegative.includes(name) &&
      (value === "" || parseInt(value, 10) >= 0)
    ) {
      setFormData({
        ...formData,
        [name]: value,
        patientId: patientRecordId,
      });
    } else if (!checkNonNegative.includes(name)) {
      // Update the state normally for fields not in the checkNonNegative array
      setFormData({
        ...formData,
        [name]: value,
        patientId: patientRecordId,
      });
    }
    // If the value is negative, you can choose to not update or handle it differently
  };

  const handleChange01 = ({ currentTarget: input }) => {
    //setPatientId(patientRecordId);
    //console.log("HandleChange");
    if (checkNonNegative.includes(input.name) && input.value < 0) {
      toast.error("Please enter a non negative number!");
      return;
    }

    setFormData({
      ...formData,
      [input.name]: input.value,
      patientId: patientRecordId,
    });
  };

  const dropDownChange = (selected, dropdown) => {
    // console.log("this is selected", JSON.stringify(dropdown.name));
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
      patientId: patientRecordId,
    });
  };

  return (
    <form
      className="newPatientForm1"
      onSubmit={(e) => handleVisitRegistration(e)}
    >
      <h2>Visit</h2>
      <div className="submitButton">
        <div className="patientUpdateButton">
          <Button variant="success" size="medium" type="submit">
            Create Visit
          </Button>
          &nbsp;&nbsp;
          <BackButton />
          &nbsp;&nbsp;
          <Button
            variant="secondary"
            size="medium"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
      </div>
      {/* <hr /> */}
      {patientProfileData ? (
        <VisitTab
          visitTypeInput={visitTypeInput}
          visitDateInput={visitDateInput}
          visitReasonInput={visitReasonInput}
          visitWeightInput={visitWeightInput}
          visitHeightInput={visitHeightInput}
          visitTypeInput1={visitTypeInput1}
          doctorInput={doctorInput}
          handleChange={handleChange}
          dropDownChange={dropDownChange}
          loggedInUserDetails={loggedInUserDetails}
          formData={formData}
          setFormData={setFormData}
          doctors={doctors}
          patientHomeTC={patientHomeTC}
          selectedPatientProfile={patientProfileData}
        />
      ) : (
        <div className="newPatientForm"> Loading ...</div>
      )}
    </form>
  );
};

export default CreateVisitChild;

import React from "react";
import { useLocation } from "react-router-dom";
import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

import UpdateAppointmentChild from "./UpdateAppointmentChild";

const UpdateAppointment = () => {
  const { state } = useLocation();
  let patientRecordId = state.id;
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="UpdateAppointment" route="/editappointment">
        <UpdateAppointmentChild
          patientRecordId={patientRecordId}
          patientProfileData={state.patient_data}
          appointment={state.appointment}
        />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default UpdateAppointment;

import {React,useState,useEffect} from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import ShowApexChart from "../../../components/Chart/ShowApexChart";
const GeneralTab = ({ currentRecord }) => {
    const [dataGraph, setDataGraph] = useState([]);
    const [graphDisplay, setGraphDisplay] = useState('');
    const showGraphView=(graphType)=>{
      setGraphDisplay(graphType);
    }  
    useEffect(() => {
        let categories=[];
        let heights=[];
        let weights=[];
        let pretransfusionhb=[];
        let posttransfusionhb=[];
        let liver=[];
        let spleen=[];
        let maindataPoints=[];
        for (var i = 0; i < currentRecord.length; i++) {
            categories.push(currentRecord[i].date_of_visit);
            heights.push(currentRecord[i].height);
            weights.push(currentRecord[i].on_visit_weight??0);
            if(currentRecord[i]?.disease?.toLowerCase().includes('thalassemia')){
                pretransfusionhb.push(currentRecord[i].pre_transfusion_hb??0);
                posttransfusionhb.push(currentRecord[i].post_transfusion_hb??0);
                liver.push(currentRecord[i].liver??0);
                spleen.push(currentRecord[i].spleen??0);
            }
        }
        maindataPoints.push({
            name: "Height",
            data: heights,
        },{
            name: "Weight",
            data: weights,
        })
        if(currentRecord[0]?.disease?.toLowerCase().includes('thalassemia')){
            maindataPoints.push({
                name: "Pre Transfusion Hb",
                data: pretransfusionhb,
            },{
                name: "Liver",
                data: liver,
            },{
                name: "Spleen",
                data: spleen,
            },{
                name: "Post Transfusion Hb",
                data: posttransfusionhb,
            })
        }
         setDataGraph({
            options: {
              chart: {
                type: 'bar',
                id: "basic-bar",
                toolbar: {
                    show: false,
                }
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: "smooth",
              },
              xaxis: {
                labels: {
                    rotate: -45
                  },
                categories: categories,
              },
            },
            series: maindataPoints,
          });
          setGraphDisplay('line');

     },[]);
    console.log(dataGraph);
    return (
        <>
            <DropdownButton id="dropdown-basic-button" style={{marginLeft:'600px'}} title={graphDisplay.toUpperCase()+' CHART'} size="sm" variant="secondary">
                <Dropdown.Item  onClick={()=>showGraphView('line')}>Line Graph</Dropdown.Item>
                <Dropdown.Item  onClick={()=>showGraphView('bar')}>Bar Graph</Dropdown.Item>
            </DropdownButton>
            {(graphDisplay ==='bar') &&
                (<ShowApexChart  chartOptions={dataGraph} type={graphDisplay}/>)}
            {(graphDisplay ==='line') &&
                (<ShowApexChart  chartOptions={dataGraph} type={graphDisplay}/>)}  
        </>   
  )
};

export default GeneralTab;

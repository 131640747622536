import React, { useState } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import Select from "react-select";
import PatientInfoBlock from "../../../components/PatientProfile/PatientInfoBlock";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import toast from "react-hot-toast";
import {
  death_causes,
  hemophilic,
  thalassemic,
} from "../../../components/Data/ChartData";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import BackButton from "../../../components/Buttons/BackButton";

const PatientDeathRecordChild = ({ pRecord }) => {
  let navigate = useNavigate();
  const [{ customEncrypt }] = useAtom(gbState);
  const [formData, setFormData] = useState({
    patient_id: pRecord?._id,
    date_of_death: pRecord?.patient_death_details?.date_of_death,
    place_of_death: pRecord?.patient_death_details?.place_of_death,
    death_report_date: pRecord?.patient_death_details?.death_report_date,
    death_reported_by: pRecord?.patient_death_details?.death_reported_by,
    cause_of_death: pRecord?.patient_death_details?.cause_of_death,
    other_cause_of_death: pRecord?.patient_death_details?.other_cause_of_death,
    additional_information:
      pRecord?.patient_death_details?.additional_information,
    govt_registration_no: pRecord?.patient_death_details?.govt_registration_no,
    death_certificate_copy:
      pRecord?.patient_death_details?.death_certificate_copy,
  });

  const checkDiagnosis = (diagnosis) => {
    if (hemophilic.includes(diagnosis)) return death_causes?.hemophilia;
    if (thalassemic.includes(diagnosis)) return death_causes?.thalassemia;
    return null;
  };

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleSubmit = (e) => {
    confirmAlert({
      title: "",
      message: `Are you sure to (S)ave this patient's death record. \nPost addition of this record you cannot reverse it (yes/no)?`,
      buttons: [
        {
          label: "Yes",
          onClick: (e) => handleSubmitConfirmed(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleSubmitConfirmed = () => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${
        process.env.REACT_APP_API_URL
      }/patient/death/report/${customEncrypt(pRecord?._id)}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: {
        ...formData,
      },
    };

    axios(config)
      .then(function (response) {
        toast.success("Submitted");
        //navigate("/patients");
        navigate(`/patient/drecord/view`, {
          state: {
            ...pRecord,
            patient_death_details: { ...formData },
          },
        });
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data.message));
        console.log(error);
      });
  };

  return (
    <div>
      <h1>Decease Form </h1>
      <div className="patientUpdateButton">
        <button className="btn btn-danger float-end" onClick={handleSubmit}>
          Update Record
        </button>
        &nbsp;&nbsp;
        <BackButton />
      </div>
      <PatientInfoBlock selectedPatientProfile={pRecord} />
      <fieldset className="border p-4 w-100">
        <legend className="float-none w-auto p-2 fs-5">
          Decease Information Update
        </legend>
        <div className="newPatientFormOnly">
          <div className="newPatientItem">
            <label htmlFor="date_of_death">Date of Death</label>
            <DatePicker
              value={
                formData?.date_of_death
                  ? moment(formData.date_of_death, "YYYY-MM-DD")
                  : null
              }
              format="YYYY-MM-DD"
              className="newPatientSelect datePicker"
              name="date_of_death"
              onChange={(momentObj) => {
                setFormData({
                  ...formData,
                  date_of_death: momentObj?.format("YYYY-MM-DD"),
                });
              }}
            />
          </div>
          <div className="newPatientItem">
            <label htmlFor="place_of_death">Place of Death</label>
            <input
              type="text"
              name="place_of_death"
              placeholder="Place of death.."
              onChange={handleChange}
              value={formData?.place_of_death && formData?.place_of_death}
            />
          </div>
          <div className="newPatientItem">
            <label>Cause of Death</label>
            <Select
              name="cause_of_death"
              onChange={(selected, dropdown) => {
                //console.log(selected);
                setFormData({
                  ...formData,
                  cause_of_death: JSON.stringify(selected),
                });
              }}
              className="newPatientSelect"
              options={checkDiagnosis(pRecord?.diagnosis)}
              value={
                formData.cause_of_death && JSON.parse(formData.cause_of_death)
              }
            />
          </div>
          <div className="newPatientItem">
            <label htmlFor="other_cause_of_death">Other cause of Death</label>
            <input
              type="text"
              name="other_cause_of_death"
              placeholder="Cause of death.."
              onChange={handleChange}
              value={
                formData?.other_cause_of_death && formData?.other_cause_of_death
              }
            />
          </div>
          <div className="newPatientItem">
            <label htmlFor="govt_registration_no">
              Government Registration
            </label>
            <input
              type="text"
              name="govt_registration_no"
              placeholder="Govt Registration No."
              onChange={handleChange}
              value={
                formData?.govt_registration_no && formData?.govt_registration_no
              }
            />
          </div>
          <div className="newPatientItem">
            <label htmlFor="death_report_date">Date of Reporting</label>
            <DatePicker
              value={
                formData?.death_report_date &&
                moment(formData?.death_report_date, "YYYY-MM-DD")
              }
              format="YYYY-MM-DD"
              className="newPatientSelect datePicker"
              name="death_report_date"
              onChange={(momentObj) => {
                setFormData({
                  ...formData,
                  death_report_date: momentObj?.format("YYYY-MM-DD"),
                });
              }}
              //   disabledDate={(current) => {
              //     return (
              //       // moment().add(-1, "days") >= current
              //       dayjs().add(1, "month") <= current
              //     );
              //   }}
            />
          </div>
          <div className="newPatientItem">
            <label htmlFor="death_reported_by">Reported By</label>
            <input
              type="text"
              name="death_reported_by"
              placeholder="Reporting Person"
              onChange={handleChange}
              value={formData?.death_reported_by && formData?.death_reported_by}
            />
          </div>
          <div className="newPatientItem">
            <label htmlFor="other_cause_of_death">Additional Information</label>
            <textarea
              type="text"
              name="additional_information"
              placeholder="Place of death.."
              onChange={handleChange}
              rows={4}
              value={
                formData?.additional_information &&
                formData?.additional_information
              }
            />
          </div>
        </div>

        <br />
      </fieldset>
      {/* {JSON.stringify(pRecord._id)} */}
    </div>
  );
};

export default PatientDeathRecordChild;

import "./UpdateEmr.css";
import { useState, useEffect, useContext } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import axios from "axios";
import toast from "react-hot-toast";
import { Tabs, Tab } from "react-bootstrap";
import { Button } from "@material-ui/core";
import PrimaryEMRMenu from "./PrimaryEMRMenu";
import { useLocation } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

//Context import
import AuthContext from "../../../components/shared/Context";
import PatientInfoBlock from "../../../components/PatientProfile/PatientInfoBlock";

import BackButton from "../../../components/Buttons/BackButton";

import TreatmentAdviceTab from "./TreatmentAdviceTab";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import ADRCaptureTab from "./ADRCaptureTab";

const UpdateEmrChild = ({ emrRecordId, emrRecord, pRecord }) => {
  return (
    <div className="patient-create">
      <FormElement
        emrRecord={emrRecord}
        pRecord={pRecord}
        emrRecordId={emrRecordId}
      />
    </div>
  );
};

const FormElement = ({ emrRecord, pRecord, emrRecordId }) => {
  const [emrRecordLocal, setEmrRecordLocal] = useState();
  let navigate = useNavigate();
  const { state } = useLocation();
  const { setPatientDiagnosis, loggedInUserDetails } = useContext(AuthContext);
  const selectedPatientProfile = { ...state?.patientData };
  const [tabState, setTabState] = useState(true);
  const [value, setValue] = useState(0);
  const [treatmentAdvice, setTreatmentAdvice] = useState([]);

  //Error Logging Service
  const [{ errorLogApi }] = useAtom(gbState);

  useEffect(() => {
    if (emrRecordLocal?.treatment_advice) {
      setTreatmentAdvice([...emrRecordLocal?.treatment_advice]);
    }
  }, []);

  useEffect(() => {
    if (emrRecord) {
      setEmrRecordLocal(emrRecord);
    }
  }, [emrRecord]);

  useEffect(() => {
    // console.log('EMR Child loaded && useEffect called');
    //  console.log('Selected Profile: ', selectedPatientProfile)
    try {
      // console.log(selectedPatientProfile?.diagnoses?.category_id?.category);
      setPatientDiagnosis(
        selectedPatientProfile?.diagnoses?.category_id?.category
      );
      if (
        selectedPatientProfile?.diagnoses?.category_id?.category
          .toLowerCase()
          .includes("thalassemia")
      ) {
        setTabState(false);
        setValue(10);
      } else {
        setTabState(true);
        setValue(0);
      }
    } catch (e) {
      errorLogApi(e, "UpdateEmrChild", "/patient/emr");
      // console.log(e);
    }
  }, []);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ span: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleRecover = (e) => {
    confirmAlert({
      title: "",
      message: "Are you sure to Recover this patient ?",
      buttons: [
        {
          label: "Yes",
          onClick: (e) => recoverRecord(e),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const recoverRecord = (e) => {
    let userToken = localStorage.getItem("token");
    if (loggedInUserDetails.role !== "FULL_ACCESS") {
      toast.error(
        "To recover Record you need Administrator/ State Admin access"
      );
      return;
    }
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/visits/recover/${emrRecord.visit_id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        toast.success("Visit Recovered Successfully.");
        navigate(`/visits/`, { state: pRecord });
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data.message));
        errorLogApi(error, "UpdateEmrChild", "/patient/emr/");
        //console.log(error);
      });
  };

  return (
    <>
      <div className="submitButton">
        <div className="patientUpdateButton">
          {emrRecord?.delete_status &&
          loggedInUserDetails.role == "FULL_ACCESS" ? (
            <Button
              variant="contained"
              color="secondary"
              size="medium"
              onClick={handleRecover}
            >
              Recover
            </Button>
          ) : null}
          &nbsp;&nbsp;
          <div>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => navigate("/patients")}
            >
              Home
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              variant="contained"
              color="info"
              size="medium"
              onClick={() =>
                navigate("/visits", { state: selectedPatientProfile })
              }
            >
              Visits
            </Button>
          </div>
        </div>
      </div>
      {/* {JSON.stringify(emrRecord)} */}
      {/* {JSON.stringify(selectedPatientProfile)} */}
      {/* {JSON.stringify(emrRecord.biochemistry)} */}
      {state && (
        <div className="mb-2">
          <PatientInfoBlock selectedPatientProfile={pRecord} />
        </div>
      )}
      <Tabs
        defaultActiveKey="medical"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="medical" title="Medical Details">
          <fieldset className="border p-2 w-100">
            <legend className="float-none w-auto p-2 fs-5">
              Medical Details
            </legend>
            <ErrorBoundaryRoutes
              page="UpdateEmrChild->PrimaryEMRMenu"
              route="/visit/"
            >
              <PrimaryEMRMenu
                emrRecordId={emrRecordId}
                handleChangeTabs={handleChangeTabs}
                value={value}
                TabPanel={TabPanel}
                setValue={setValue}
                tabState={tabState}
                emrRecord={emrRecordLocal}
                setEmrRecord={setEmrRecordLocal}
              />
            </ErrorBoundaryRoutes>
          </fieldset>
        </Tab>
        {/* <Tab eventKey="treatment" title="Treatment Advice">
          <fieldset className="border p-2 w-100">
            <legend className="float-none w-auto p-2 fs-5">
              Treatment Advice/Details
            </legend>
            <ErrorBoundaryRoutes
              page="UpdateEmrChild->TreatmentAdviceTab"
              route="/visit/"
            >
              <TreatmentAdviceTab
                emrRecordId={emrRecordId}
                emrRecord={emrRecord}
                emrRecordLocal={emrRecordLocal}
                setEmrRecordLocal={setEmrRecordLocal}
              />
            </ErrorBoundaryRoutes>
            <br />
          </fieldset>
        </Tab> */}
        <Tab eventKey="adr" title="ADR">
          <fieldset className="border p-2 w-100">
            <legend className="float-none w-auto p-2 fs-5">ADR Details</legend>
            <ErrorBoundaryRoutes
              page="UpdateEmrChild->TreatmentAdviceTab"
              route="/visit/"
            >
              {emrRecord ? (
                <ADRCaptureTab
                  emrRecordId={emrRecordId}
                  emrRecord={emrRecord}
                  emrRecordLocal={emrRecordLocal}
                  setEmrRecordLocal={setEmrRecordLocal}
                />
              ) : (
                "Loading..."
              )}
            </ErrorBoundaryRoutes>
            <br />
          </fieldset>
        </Tab>
      </Tabs>
      {/* {JSON.stringify(pRecord)} */}
    </>
  );
};

export default UpdateEmrChild;

import React from "react";
import MainContentCard from "../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../components/shared/ErrorBoundaryRoutes";
import DiagnosisChild from "./DiagnosisChild";

const Diagnosis = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="Diagnosis" route="/diagnosis">
        <DiagnosisChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default Diagnosis;

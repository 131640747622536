import React from "react";
import BarChartComponent from "./BarChartComponent";

const TCDiagnosisCount = ({ jsonData }) => {
  const diagnosisCounts = jsonData.reduce((acc, patient) => {
    const diagnosisName = patient.diagnoses.diagnosis_name;
    if (acc[diagnosisName]) {
      acc[diagnosisName] += 1;
    } else {
      acc[diagnosisName] = 1;
    }
    return acc;
  }, {});

  return (
    <div>
      <BarChartComponent data={diagnosisCounts} />
    </div>
  );
};

export default TCDiagnosisCount;

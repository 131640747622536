import React from "react";
import { useLocation } from "react-router-dom";
import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import ExportToExcel from "./ExportToExcel";

const SelectExportToExcel = () => {
  const { state } = useLocation();
  let selectedData = state.data;
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="ExportToExcel" route="/export/toexcel">
        {/* {JSON.stringify(selectedData)} */}
        <ExportToExcel jsonData={selectedData} />
      </ErrorBoundaryRoutes>
      {/* {JSON.stringify(selectedData)} */}
    </MainContentCard>
  );
};

export default SelectExportToExcel;

import React from "react";
import { LineChart,Legend, Line, CartesianGrid, XAxis, YAxis , ResponsiveContainer , Tooltip } from 'recharts';
import "./Chart.css";
//import "./ShowBarChart.css";

const ShowLineChart = ({ data }) => {
  return (
    <div className="chart">
        <ResponsiveContainer width="100%" aspect={2}>
            <LineChart width={600} height={600} data={data}>
                <CartesianGrid />
                <XAxis dataKey="name" 
                    interval={'preserveStartEnd'} />
                <YAxis></YAxis>
                <Legend />
                <Tooltip />
                <Line dataKey="value"
                    stroke="black" activeDot={{ r: 8 }} />
            </LineChart>
        </ResponsiveContainer>
    </div>
  );
};

export default ShowLineChart;

import {React,useState,useEffect} from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import ShowApexChart from "../../../components/Chart/ShowApexChart";
const GeneralTab = ({ currentRecord }) => {
    const [dataGraph, setDataGraph] = useState([]);
    const [graphDisplay, setGraphDisplay] = useState('');
    const showGraphView=(graphType)=>{
      setGraphDisplay(graphType);
    }  
    useEffect(() => {
        let categories=[];
        let stayCost=[];
        let foodCost=[];
        let wagesCost=[];
        let schoolAbCost=[];
        let travelCost=[];
        let drtIndrtCost=[];
        let hcpCost=[];
        let maindataPoints=[];
        for (var i = 0; i < currentRecord.length; i++) {
            categories.push(currentRecord[i].date_of_visit);
            stayCost.push(currentRecord[i].stay_cost??0);
            foodCost.push(currentRecord[i].food_cost??0);
            wagesCost.push(currentRecord[i].wages_lost_by_parents??0);
            schoolAbCost.push(currentRecord[i].school_absentism??0);
            travelCost.push(currentRecord[i].travel_cost??0);
            drtIndrtCost.push(currentRecord[i].other_direct_indirect_cost??0);
            hcpCost.push(currentRecord[i].hcp_indirect_cost??0);
        }
        maindataPoints.push({
            name: "Stay cost",
            data: stayCost,
        },{
            name: "Food cost",
            data: foodCost,
        },{
            name: "Wages lost by parents",
            data: wagesCost,
        },{
            name: "School absentism",
            data: schoolAbCost,
        },{
            name: "Travel cost",
            data: travelCost,
        },{
            name: "Other direct/indirect cost",
            data: drtIndrtCost,
        },{
            name: "HCP indirect cost",
            data: hcpCost,
        })
         setDataGraph({
            options: {
              chart: {
                type: 'bar',
                id: "basic-bar",
                toolbar: {
                    show: false,
                }
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: "smooth",
              },
              xaxis: {
                labels: {
                    rotate: -45
                  },
                categories: categories,
              },
            },
            series: maindataPoints,
          });
          setGraphDisplay('line');

     },[]);
    console.log(dataGraph);
    return (
        <>
            <DropdownButton id="dropdown-basic-button" style={{marginLeft:'600px'}} title={graphDisplay.toUpperCase()+' CHART'} size="sm" variant="secondary">
                <Dropdown.Item  onClick={()=>showGraphView('line')}>Line Graph</Dropdown.Item>
                <Dropdown.Item  onClick={()=>showGraphView('bar')}>Bar Graph</Dropdown.Item>
            </DropdownButton>
            {(graphDisplay ==='bar') &&
                (<ShowApexChart  chartOptions={dataGraph} type={graphDisplay}/>)}
            {(graphDisplay ==='line') &&
                (<ShowApexChart  chartOptions={dataGraph} type={graphDisplay}/>)}  
        </>   
  )
};

export default GeneralTab;

import React from "react";

import Topbar from "../../../../components/Topbar/Topbar";
import SideBar from "../../../../components/SideBar/SideBar";

import "./BleedWiseReport.css";
import BleedWiseReportChild from "./BleedWiseReportChild";

import MainContent from "../../../Home/MainContent";

const BleedWiseReport = () => {
  return (
    <MainContent>
      <div className="parent-container">
        <BleedWiseReportChild />
      </div>
    </MainContent>
  );
};

export default BleedWiseReport;

import { useState, useEffect } from "react";
import TableRows from "./TableRows";
function AddDeleteTableRows({ setFormData, formData, editable }) {
  const [rowsData, setRowsData] = useState([
    {
      medicine: "",
      dose_quantity: "",
      note: "",
      duration: "",
    },
  ]);

  useEffect(() => {
    // console.log("Called from inside **");
    try {
      if (formData?.treatment_given) {
        setRowsData([...formData?.treatment_given]);
      }
    } catch (err) {}
  }, []);

  const addTableRows = () => {
    const rowsInput = {
      medicine: "",
      dose_quantity: "",
      note: "",
      duration: "",
    };
    setRowsData([...rowsData, rowsInput]);
  };
  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };

  const handleChange = (index, evnt) => {
    //console.log("this is selected", JSON.stringify(index));
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  };

  const dropDownChange = (selected, dropdown, index) => {
    const rowsInput = [...rowsData];
    rowsInput[index][dropdown.name] = JSON.stringify(selected);
    setRowsData(rowsInput);
  };

  useEffect(() => {
    setFormData({ ...formData, treatment_given: [...rowsData] });
  }, [rowsData]);

  return (
    <div className="container w-100">
      {/* {JSON.stringify(formData)} */}
      {/* {JSON.stringify(rowsData)} */}
      <div className="row">
        <div className="w-100">
          <table className="table">
            <thead>
              <tr>
                <th>Medicine</th>
                <th>Dose/Quantity</th>
                <th>Duration</th>
                <th>Notes</th>
                <th style={{ verticalAlign: "bottom" }}>
                  {/* {editable && (
                    <i
                      className="mdi mdi-plus-circle-outline"
                      style={{
                        fontSize: "25px",
                        cursor: "pointer",
                        color: "blue",
                        verticalAlign: "middle",
                      }}
                      onClick={addTableRows}
                    />
                  )} */}
                </th>
              </tr>
            </thead>
            <tbody>
              <TableRows
                rowsData={rowsData}
                deleteTableRows={deleteTableRows}
                handleChange={handleChange}
                dropDownChange={dropDownChange}
                editable={editable}
                addTableRows={addTableRows}
              />
            </tbody>
          </table>
        </div>
        <div className="col-sm-4"></div>
      </div>
    </div>
  );
}
export default AddDeleteTableRows;

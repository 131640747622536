import React from "react";
import MainContentCard from "../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../components/shared/ErrorBoundaryRoutes";
import AddDiagnosisNameChild from "./AddDiagnosisNameChild";

const AddDiagnosisName = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="AddDiagnosis" route="/diagnosis/add">
        <AddDiagnosisNameChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default AddDiagnosisName;

import React from "react";

import MainContentCard from "../../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../../components/shared/ErrorBoundaryRoutes";
import ListPurchaseTCChild from "./ListPurchaseTCChild";

const ListPurchaseTC = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="ListPurchaseTC" route="/list-purchase-tc">
        <ListPurchaseTCChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default ListPurchaseTC;

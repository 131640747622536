import React from "react";
import { useLocation } from "react-router-dom";
import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import ListHealthRecordChild from "./ListHealthRecordChild";

const ListHealthRecord = () => {
  const { state } = useLocation();
  let patientRecordId = state.id;
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes
        page="CreatePatientHealthRecord"
        route="/listhealthrecord"
      >
        <ListHealthRecordChild
          patientRecordId={patientRecordId}
          patientProfileData={state}
        />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default ListHealthRecord;

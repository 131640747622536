import React, { useEffect, useState, useContext, useRef } from "react";

import { useNavigate } from "react-router-dom";
import axios from "axios";

import "antd/dist/antd.min.css";
import { Modal } from "react-bootstrap";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import { confirmAlert } from "react-confirm-alert";

//Global State import
import { useAtom } from "jotai";
import { gbState } from "../../../../components/shared/GlobalState";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import toast from "react-hot-toast";
//Context import
import AuthContext from "../../../../components/shared/Context";
import ExcelDownloader from "./ExcelDownloader"; // Import the ExcelDownloader component

const ListTCInventoryChild = () => {
  let navigate = useNavigate();
  const { loggedInUserDetails } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const searchRef = useRef();

  //Error Logging Service
  const { dateFormatter, errorLogApi } = useAtom(gbState);

  const getDataFromServer = () => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };

    let urlLink = `${process.env.REACT_APP_API_URL}/tcstock`;

    axios
      .get(urlLink, config)
      .then((res) => {
        const oldData = res?.data?.current_stock;
        //console.log(oldData);
        const updatedData = oldData?.map((data) => {
          //   console.log(data.purchase_inventory.product_id.products);
          return {
            ...data,
            id: data?._id,
            product_name:
              data?.current_stock?.product_id?.products?.product_name,
            category:
              data?.current_stock?.product_id?.products?.category_id?.category,
            item_code: data?.current_stock?.item_code,
            opening_stock:
              data?.current_stock?.opening_stock +
              " " +
              data?.current_stock?.product_id?.products?.storage_unit,
            purchase_qty:
              data?.current_stock?.purchase_qty +
              " " +
              data?.current_stock?.product_id?.products?.storage_unit,
            sale_qty:
              data?.current_stock?.sale_qty +
              " " +
              data?.current_stock?.product_id?.products?.storage_unit,
            closing_stock:
              data?.current_stock?.closing_stock +
              " " +
              data?.current_stock?.product_id?.products?.storage_unit,
            state: data?.current_stock?.tc_id?.tcenters?.state,
            tc_name: data?.current_stock?.tc_id?.tcenters?.name_tc,
            updatedAt: data?.updatedAt,
          };
        });
        //console.log(updatedData);
        setData(updatedData);
        setUnfilteredData(updatedData);
      })
      .catch((err) => console.log("error", err));
  };

  useEffect(() => {
    // if (loggedInUserDetails.tc_id) {
    getDataFromServer();
    // }
  }, []);

  const useStyles = makeStyles(() => ({
    multiLineCell: {
      display: "flex",
      wordBreak: "keep-all",
      wordWrap: "break-word",
      overflowWrap: "break-word",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      lineHeight: "1.2rem",
      height: "auto",
    },
  }));

  const calculateDaysLeft = (date) => {
    // Convert the given date string to a Date object
    const givenDate = new Date(date);

    // Calculate the difference between the current date and the given date in milliseconds
    const timeDiff = givenDate.getTime() - new Date().getTime();

    // Convert milliseconds to days and round down to the nearest integer
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    // Update the state with the number of days left
    //setDaysLeft(days);
    return days;
  };

  const MultiLineCellRenderer = (params) => {
    const classes = useStyles();
    return (
      <div className={classes.multiLineCell}>
        <span id="content1" className="text-bold">
          {params.row.product_name}
        </span>
        <span id="content2">
          <small className="small-font-size">{params.row.item_code}</small>
        </span>
      </div>
    );
  };

  const columns = [
    {
      field: "product_name",
      headerName: "Product",
      width: 240,
      renderCell: MultiLineCellRenderer,
    },
    {
      field: "category",
      headerName: "Category",
      width: 150,
    },
    {
      field: "opening_stock",
      headerName: "Opening Stock",
      width: 160,
      filterable: false,
      disableColumnMenu: true,
      align: "center",
    },
    {
      field: "purchase_qty",
      headerName: "Alloted",
      width: 110,
      filterable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "center",
    },
    {
      field: "sale_qty",
      headerName: "Consumed",
      width: 130,
      filterable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "center",
    },
    {
      field: "closing_stock",
      headerName: "Closing Stock",
      width: 160,
      filterable: false,
      disableColumnMenu: true,
      align: "center",
    },

    {
      field: "tc_name",
      headerName: "TC Name",
      width: 170,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => <div>{params?.row?.tc_name}</div>,
    },
    {
      field: "state",
      headerName: "State",
      width: 110,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };

  const handleSearch = (e) => {
    console.log("Search Value...", e);

    if (e) {
      setData(
        unfilteredData?.filter((item) => {
          return (
            item?.item_code?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.product_name?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.purchase_qty?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.sale_qty?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.closing_stock?.toLowerCase().includes(e?.toLowerCase())
          );
        })
      );
    } else {
      getDataFromServer();
      setData(unfilteredData);
    }
  };

  const clearSearch = () => {
    searchRef.current.value = null;
    getDataFromServer();
  };

  const tcinventorySettle = () => {
    // alert("inventory Settled");

    confirmAlert({
      title: "",
      message: "Are you sure to Settle Inventory ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmInventorySettle(),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });

    const confirmInventorySettle = () => {
      let urlLink = `${process.env.REACT_APP_API_URL}/tcs/stocksettle`;

      const config = {
        method: "post",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: { invdata: true },
      };

      axios(config)
        .then((res) => {
          // console.log("response", res, "data", res.data[0]);
          toast.success(res?.data?.message);
          getDataFromServer();
        })
        .catch((err) => {
          console.log();
          if (err?.response?.data?.error) toast.error("Already settled");
          errorLogApi(err, "ListTCInventoryChild", urlLink);
        });
    };
  };

  const reloadInventory = () => {
    confirmAlert({
      title: "",
      message: "Are you sure to Reload Inventory ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmReloadInventory(),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });

    const confirmReloadInventory = () => {
      let urlLink = `${process.env.REACT_APP_API_URL}/tcstock/reload`;

      const config = {
        method: "get",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      axios(config)
        .then((res) => {
          // console.log("response", res, "data", res.data[0]);
          getDataFromServer();
        })
        .catch((err) => {
          console.log();
          if (err?.response?.data?.error) toast.error("Already settled");
          errorLogApi(err, "ListTCInventoryChild", urlLink);
        });
    };
  };

  return (
    <div>
      <span className="card-title">
        <h3>Total TC Stocks (Current Status)</h3>
      </span>

      <div className="patientUpdateButton">
        <button className="btn btn-success" onClick={getDataFromServer}>
          <i className="mdi mdi-refresh"></i>
        </button>{" "}
        &nbsp;&nbsp;
        <button className="btn btn-primary" onClick={tcinventorySettle}>
          TC Settle Inventory
        </button>{" "}
        &nbsp;&nbsp;
        {!data?.length && (
          <button className="btn btn-warning" onClick={reloadInventory}>
            Reload
          </button>
        )}
        <ExcelDownloader
          data={data}
          filename={`inventory_status_${new Date()
            .toISOString()
            .replace(/[-:.]/g, "")}.xlsx`}
          excludedFields={[
            "_id",
            "id",
            "s_register",
            "__v",
            "patient_record",
            "visit_record",
            "current_stock",
          ]}
        />
      </div>
      <div className="patientListSearch">
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">Filter</InputGroup.Text>
          <Form.Control
            placeholder="Product / State / Code / Company ..."
            aria-label="Search"
            aria-describedby="basic-addon1"
            name="searchPatients"
            ref={searchRef}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <InputGroup.Text>
            <i
              className="mdi mdi-delete-outline"
              onClick={clearSearch}
              style={{ cursor: "pointer" }}
            ></i>
          </InputGroup.Text>
        </InputGroup>
      </div>
      <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        displayTitle="Current Stocks"
        checkboxSelection
        style={{ height: "60vh" }}
      />
      {/* {JSON.stringify(data)} */}
      {/* Modal Popup */}
      <Modal show={openModal} centered>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Stocks Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent currentRecord={currentRecord} />
        </Modal.Body>
      </Modal>

      {/* {JSON.stringify(currentRecord)} */}
    </div>
  );
};

const ModalContent = ({ currentRecord }) => {
  const dateFormatter = (date) => {
    const date_conv = new Date(date);
    const fullyear = date_conv.getFullYear();
    const month = date_conv.getMonth() + 1;
    const day = date_conv.getDate();
    return `${day}/${month}/${fullyear}`;
  };

  return (
    <div className="container-fluid">
      <table class="table table-striped">
        <tbody>
          <tr>
            <th scope="row">Product Name</th>
            <td>{currentRecord?.product_name}</td>
          </tr>
          <tr>
            <th scope="row">Measuring Units</th>
            <td>{currentRecord?.measuring_unit}</td>
          </tr>
          <tr>
            <th scope="row">Drug Category</th>
            <td>{currentRecord?.category}</td>
          </tr>
          <tr>
            <th scope="row">Quantity</th>
            <td>{currentRecord?.quantity}</td>
          </tr>
          <tr>
            <th scope="row">Expiry</th>
            <td>{dateFormatter(currentRecord?.product_expiry)}</td>
          </tr>
          <tr>
            <th scope="row">Company Name</th>
            <td>{currentRecord?.company_name}</td>
          </tr>
          <tr>
            <th scope="row">State</th>
            <td>{currentRecord?.state}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ListTCInventoryChild;

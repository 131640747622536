import React, { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import styles from "./CreateUser.module.css";
import { confirmAlert } from "react-confirm-alert";

import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";

//Context import
import AuthContext from "../../../components/shared/Context";

const UpdateDoctorChild = ({ doctorRecord }) => {
  const { checkLoggedInStatus, states, loggedInUserDetails } =
    useContext(AuthContext);

  let navigate = useNavigate();
  //To check if the user is logged in
  useEffect(() => {
    checkLoggedInStatus();
  }, []);

  const [tcStates, setTcStates] = useState([]);

  const [rolesValue, setRolesValue] = useState([]);
  const [confirmPassword, setConfirmPassword] = useState();

  const [formData, setFormData] = useState({
    first_name: doctorRecord?.first_name,
    last_name: doctorRecord?.last_name,
    mobile: doctorRecord?.mobile,
    email: doctorRecord?.email,
    doctor_drop_down_status: doctorRecord?.doctor_drop_down_status,
  });

  const [selectedOption, setSelectedOption] = useState(
    formData?.doctor_drop_down_status
  );

  useEffect(() => {
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/tc`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios(config)
      .then(function (response) {
        const fetchedTcData = response.data.treatment_center;
        const tcData = fetchedTcData.map((tc) => {
          return {
            id: tc._id,
            tc_name: tc.tcenters.name_tc,
            state: tc.tcenters.state,
            district: tc.tcenters.district,
          };
        });
        //console.log(JSON.stringify(tcData));
        setTcStates([...tcData]);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  //Load Roles on page load
  useEffect(() => {
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/roles`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios(config)
      .then(function (response) {
        const fetchedRolesData = response.data.roles;
        setRolesValue(fetchedRolesData);
        //console.log(JSON.stringify(fetchedRolesData));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handleUserRegistration = (e) => {
    e.preventDefault();

    confirmAlert({
      title: "",
      message: "Are you sure to Update Doctor ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => updateDoctor(),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });

    const updateDoctor = () => {
      let userToken = localStorage.getItem("token");
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/user/doc/update`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        data: { ...formData, _id: doctorRecord._id },
      };
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          toast.success("User Updated Successfully.");
          navigate("/doctors");
        })
        .catch(function (error) {
          //  toast.error(JSON.stringify(error.response.data.message));
          toast.error(JSON.stringify(error.response.data));
          console.log(error);
        });
    };
  };

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleOptionChange = () => {
    setSelectedOption(!selectedOption);
    setFormData({ ...formData, doctor_drop_down_status: selectedOption });
  };

  return (
    <div>
      <div>
        <h2>Update Doctor</h2>
      </div>
      {/* {JSON.stringify({ ...formData })} */}
      {/* {JSON.stringify(loggedInUserDetails)} */}
      <form onSubmit={(e) => handleUserRegistration(e)} autoComplete="off">
        <div className="patientUpdateButton">
          <Button variant="primary" size="medium" type="submit">
            Update Doctor
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="secondary"
            size="medium"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
        <div className={styles.newFormUsersOnly}>
          <div className="newPatientItem w-25">
            <label htmlFor="first_name">First Name</label>
            <input
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="last_name">Last Name</label>
            <input
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="mobile">Mobile</label>
            <input
              type="text"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              autoComplete="new-password"
              required
            />
          </div>
          <div className="newPatientItem w-25 mt-4 cursor-pointer">
            <label>
              Show doctor &nbsp;&nbsp;
              <input
                type="checkbox"
                name="doctor_drop_down_status"
                checked={formData?.doctor_drop_down_status ? true : false}
                onChange={handleOptionChange}
              />
            </label>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateDoctorChild;

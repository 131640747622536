import React, { useContext, useRef } from "react";
import toast from "react-hot-toast";

import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Badge from "react-bootstrap/Badge";
import { DatePicker } from "antd";
import dayjs from "dayjs";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../../../components/shared/GlobalState";

//Context import
import AuthContext from "../../../../components/shared/Context";

const PVTUSERROLE = "PVT_USER_ACCESS";

const CreateDoseForPatientModal = ({
  selectedRecord,
  reloadDoses,
  setOpenDoseModal,
}) => {
  const [visit, setVisit] = useState(selectedRecord?.visit);
  const [patientRecord, setPatientRecord] = useState(selectedRecord?.patient);
  let navigate = useNavigate();
  const { loggedInUserDetails } = useContext(AuthContext);
  const patientDiagnosis = patientRecord?.diagnoses?.diagnosis_name;
  const patientDiagCategory = patientRecord?.diagnoses?.category_id?.category;
  const [productList, setProductList] = useState();
  const [availableQty, setAvailbleQty] = useState();
  const givenDoseRef = useRef();
  const doseQuantityRef = useRef();
  const productSelectedRef = useRef();
  const [selectedProduct, setSelectedProduct] = useState(null);

  // console.log(selectedRecord);

  //Error Logging Service
  const [{ errorLogApi, expiryFormatter, dateFormatter }] = useAtom(gbState);
  const [formData, setFormData] = useState({
    // date_time_of_infusion: null,
    product_name: null,
    product_expiry: null,
    product_code: null,
    product_batch_id: null,
    available_qty: null,
    tc_sale_id: null,
    tc_purchase_id: null,
    product_id: null,
    patient_id: patientRecord?.id,
    visit_id: visit.id,
    drug_infusion_date: null,
    quantity: null,
    dose_quantity: null,
    measuring_unit: null,
    max_available_qty: null,
    dose_given: null,
    patient_id: patientRecord?.id,
    given_by_nurse_or_doctor: null,
    allergic_reactions: null,
    systolic_pressure: null,
    diastolic_pressure: null,
    heart_rate: null,
    temperature: null,
    comments: null,
    private_product_expiry: null,
    private_product_batch_id: null,
    dose_product_type:
      loggedInUserDetails?.role === PVTUSERROLE ? "private" : "government",
    state: loggedInUserDetails?.state,
  });

  useEffect(() => {
    setAvailbleQty(
      formData?.quantity
        ? parseInt(formData?.max_available_qty) - parseInt(formData?.quantity)
        : formData?.max_available_qty
    );
  }, [formData.quantity]);

  const getProductDataFromServer = () => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    const bodyParameters = {
      key: "value",
    };

    let urlLink = `${process.env.REACT_APP_API_URL}/tcpwstock/`;

    axios
      .get(urlLink, config)
      .then((res) => {
        const oldData = res.data.product_stock;
        // console.log(oldData);
        const filteredProduct = oldData?.filter(
          (p) =>
            p.product_stock?.product_id?.products?.category_id?.category ===
            patientDiagCategory
        );
        const updatedData = filteredProduct?.map((data) => {
          //   console.log(data.purchase_inventory.product_id.products);
          return {
            //   ...data,
            //   id: data._id,
            value: data._id,
            batch_id: data.product_stock.state_purchase_id.p_register.batch_id,
            product_name: data.product_stock.product_id.products.product_name,
            product_id: data.product_stock.product_id._id,
            product_code: data.product_stock.item_code,
            measuring_unit:
              data.product_stock.product_id.products.measuring_unit,
            company_name: data.product_stock.product_id.products.company_name,
            product_expiry: data.product_stock.expiry_date,
            storage_unit: data.product_stock.product_id.products.storage_unit,
            available_qty: data.product_stock.quantity,
            max_available_qty: data.product_stock.quantity,
            tc_purchase_id: data.product_stock.tc_purchase_id,
            state: data.product_stock.state,
            label: (
              <ProductName
                prod_name={data.product_stock.product_id.products.product_name}
                qty={data.product_stock.quantity}
                pexpiry={data.product_stock.expiry_date}
                loggedInUserDetails={loggedInUserDetails}
              />
            ),
            updatedAt: data.updatedAt,
          };
        });
        setProductList(updatedData);
      })
      .catch((err) => console.log("error", err));
  };

  useEffect(() => {
    getProductDataFromServer();
  }, []);

  // If the loggedin user is a private TC
  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");
      let product_url = `${process.env.REACT_APP_API_URL}/product/${loggedInUserDetails.state}`;
      const config = {
        method: "GET",
        url: product_url,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          // console.log(patientRecord?.diagnoses?.category_id?.category);
          // console.log(response.data.product);
          const oldData = response.data.product;
          const filteredProduct = oldData?.filter(
            (p) => p.products?.category_id?.category === patientDiagCategory
          );
          const updatedData = filteredProduct?.map((data) => {
            return {
              value: data?._id,
              product_id: data?._id,
              category_id: data?.products?.category_id?._id,
              category: data?.products?.category_id?.category,
              product_code: data?.products?.item_code,
              label: (
                <ProductName
                  prod_name={data?.products?.product_name}
                  qty={null}
                  pexpiry={null}
                  loggedInUserDetails={loggedInUserDetails}
                />
              ),
              measuring_unit: data?.products?.measuring_unit,
              storage_unit: data?.products?.storage_unit,
              minimum_alert_qty: data?.products?.minimum_alert_qty,
              company_name: data?.products?.company_name,
              state: data?.products?.state,
            };
          });
          //console.log(updatedData);
          setProductList(updatedData);
        })
        .catch(function (error) {
          errorLogApi(error, "ProductList", "/products");
          console.log(error);
        });
    };
    if (loggedInUserDetails.role === PVTUSERROLE) {
      getDataFromServer();
    }
  }, [loggedInUserDetails]);

  const checkInteger = ({ currentTarget: input }) => {
    const isValidInput = /^\d+$/.test(formData.quantity);
    if (!isValidInput) {
      toast.error("Choose only Integer value");
      setFormData({
        ...formData,
        quantity: null,
      });
      givenDoseRef.current.value = null;
      return;
    }
  };
  const handleChange = ({ currentTarget: input }) => {
    if (formData.quantity < 0) {
      toast.error("Choose only Positive value");
      setFormData({
        ...formData,
        quantity: null,
      });
      givenDoseRef.current.value = null;
      return;
    }

    if (parseInt(formData.quantity) > parseInt(formData.max_available_qty)) {
      toast.error(`Value should be less than ${formData.max_available_qty}`);
      setFormData({
        ...formData,
        quantity: null,
      });
      givenDoseRef.current.value = null;
      return;
    }
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleCreateDose = () => {
    if (loggedInUserDetails?.role === PVTUSERROLE)
      return toast.error("Private user cannot create dose");

    const createDose = () => {
      const url_link = `${process.env.REACT_APP_API_URL}/tcstocksale/add/`;

      let userToken = localStorage.getItem("token");
      const config = {
        method: "POST",
        url: url_link,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        data: { ...formData },
      };

      axios(config)
        .then(function (response) {
          toast.success("Dose Created Successfully.");
          reloadDoses();
          setOpenDoseModal(false);
        })
        .catch(function (error) {
          toast.error(error?.response?.data?.message);
        });
    };
    createDose();
  };

  const createMultipleDose = () => {
    if (loggedInUserDetails?.role === PVTUSERROLE)
      return toast.error("Private user cannot create dose");

    const createDose = () => {
      const url_link = `${process.env.REACT_APP_API_URL}/tcstocksale/add/`;

      let userToken = localStorage.getItem("token");
      const config = {
        method: "POST",
        url: url_link,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        data: { ...formData },
      };

      axios(config)
        .then(function (response) {
          toast.success("Dose Created Successfully.");
          reloadDoses();
          //Set formData to null
          setFormData({
            // date_time_of_infusion: null,
            product_name: null,
            product_expiry: null,
            product_code: null,
            product_batch_id: null,
            available_qty: null,
            tc_sale_id: null,
            tc_purchase_id: null,
            product_id: null,
            patient_id: patientRecord.id,
            visit_id: visit.id,
            drug_infusion_date: null,
            quantity: null,
            dose_quantity: null,
            measuring_unit: null,
            max_available_qty: null,
            dose_given: null,
            patient_id: patientRecord?.id,
            given_by_nurse_or_doctor: null,
            allergic_reactions: null,
            systolic_pressure: null,
            diastolic_pressure: null,
            heart_rate: null,
            temperature: null,
            comments: null,
            private_product_expiry: null,
            private_product_batch_id: null,
            dose_product_type:
              loggedInUserDetails?.role === PVTUSERROLE
                ? "private"
                : "government",
            state: loggedInUserDetails.state,
          });
          givenDoseRef.current.value = null;
          doseQuantityRef.current.value = null;
          getProductDataFromServer();
          setSelectedProduct(null);
          setAvailbleQty(0);
        })
        .catch(function (error) {
          toast.error(error?.response?.data?.message);
        });
    };
    createDose();
  };

  const handleCreateDosePrivate = () => {
    const createDose = () => {
      const url_link = `${process.env.REACT_APP_API_URL}/tcstocksale/private/add/`;
      let userToken = localStorage.getItem("token");
      const config = {
        method: "POST",
        url: url_link,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        data: { ...formData },
      };
      axios(config)
        .then(function (response) {
          toast.success("Dose Created Successfully.");
          reloadDoses();
          setOpenDoseModal(false);
        })
        .catch(function (error) {
          toast.error(error?.response?.data?.message);
        });
    };
    createDose();
  };

  // Function to ensure the quantity is always positive
  const handlePositiveQuantity = (e) => {
    const value = e.target.value;
    if (value < 0) {
      e.target.value = 0;
    }
  };

  return (
    <div>
      <div className="patientUpdateButton">
        {loggedInUserDetails?.role !== PVTUSERROLE ? (
          <>
            <button
              className="btn btn-primary btn-md"
              onClick={createMultipleDose}
            >
              Save Multiple Dose
            </button>
            &nbsp;&nbsp;
            <button
              className="btn btn-warning btn-md"
              onClick={handleCreateDose}
            >
              Save Dose
            </button>
          </>
        ) : (
          <button
            className="btn btn-success btn-md"
            onClick={handleCreateDosePrivate}
          >
            Save Dose
          </button>
        )}
      </div>
      <div className="mb-2">
        <fieldset className="border p-2 w-100">
          <legend className="float-none w-auto p-2 fs-5">
            {/* <button className="btn btn-warning btm-sm">Create Dose</button> */}
            <span
              className="btn btn-info btn-sm not-clickable"
              style={{ pointerEvents: "none" }}
            >
              Dose Details
            </span>
          </legend>
          <div
            className="p-20"
            style={{ width: "100%", paddingLeft: "20px", paddingRight: "20px" }}
          >
            <div className="table-responsive overflow-y:hidden">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th scope="row">Date of Visit</th>
                    <td>
                      {visit?.date_of_visit &&
                        dateFormatter(visit?.date_of_visit)}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Patient Name</th>
                    <td>
                      {" "}
                      {patientRecord?.patient_name &&
                        patientRecord?.patient_name}
                      {" / "}
                      {patientRecord?.patientId}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Diagnosis</th>
                    <td>
                      <span className="text-bold">
                        {" "}
                        {patientDiagnosis && patientDiagnosis}
                      </span>
                      {" / "}
                      {patientDiagCategory && patientDiagCategory}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" style={{ verticalAlign: "center" }}>
                      Product Name
                    </th>
                    <td style={{ width: "350px", height: "70px" }}>
                      {" "}
                      <Select
                        name="product_name"
                        onChange={(selected, dropdown) => {
                          setFormData({
                            ...formData,
                            product_name: selected.value,
                            product_id: selected.product_id,
                            product_expiry: selected.product_expiry,
                            product_code: selected.product_code,
                            available_qty: selected.available_qty,
                            max_available_qty: selected.max_available_qty,
                            storage_unit: selected.storage_unit,
                            measuring_unit: selected.measuring_unit,
                            batch_id: selected.batch_id,
                            tc_purchase_id: selected.tc_purchase_id,
                          });
                          setAvailbleQty(selected.available_qty);
                          setSelectedProduct(selected);
                        }}
                        placeholder="Select Product..."
                        className="newPatientSelect"
                        options={productList}
                        // menuPortalTarget={document.body}
                        // menuPosition={"fixed"}
                        ref={productSelectedRef}
                        value={selectedProduct}
                      />
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">Product Code</th>
                    <td> {formData?.product_code && formData.product_code} </td>
                  </tr>
                  {loggedInUserDetails?.role !== PVTUSERROLE && (
                    <>
                      <tr>
                        <th scope="row">Product Expiry</th>
                        <td>
                          {" "}
                          {formData?.product_expiry &&
                            expiryFormatter(formData.product_expiry)}{" "}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Product BatchID</th>
                        <td> {formData?.batch_id && formData?.batch_id} </td>
                      </tr>
                      <tr>
                        <th scope="row">Available Quantity</th>
                        <td>
                          {" "}
                          {availableQty && availableQty}{" "}
                          {formData?.storage_unit && formData.storage_unit}
                        </td>
                      </tr>
                    </>
                  )}
                  {loggedInUserDetails?.role === PVTUSERROLE && (
                    <tr>
                      <th scope="row">Product Expiry</th>
                      <td>
                        <DatePicker
                          className="newPatientSelect datePicker"
                          style={{ paddingLeft: "10px" }}
                          name="private_product_expiry"
                          picker="month"
                          format="YYYY-MM"
                          onChange={(date, dateString) => {
                            setFormData({
                              ...formData,
                              private_product_expiry: dateString,
                            });
                          }}
                          disabled={formData?.product_code ? false : true}
                        />
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th scope="row">Allocate Dose</th>
                    <td>
                      <input
                        type="number"
                        name="quantity"
                        placeholder=""
                        inputMode="numeric"
                        pattern="[0-9]*"
                        onChange={(e) => {
                          handleChange(e);
                          handlePositiveQuantity(e);
                        }}
                        onKeyUp={(e) => {
                          handleChange(e);
                          checkInteger(e);
                          handlePositiveQuantity(e);
                        }}
                        ref={givenDoseRef}
                        disabled={
                          loggedInUserDetails?.role === PVTUSERROLE
                            ? false
                            : formData?.available_qty
                            ? false
                            : true
                        }
                        style={{ MozAppearance: "textfield" }}
                      />
                      <style jsx>{`
                        input[type="number"] {
                          -moz-appearance: textfield;
                        }
                        input[type="number"]::-webkit-outer-spin-button,
                        input[type="number"]::-webkit-inner-spin-button {
                          -webkit-appearance: none;
                          margin: 0;
                        }
                      `}</style>{" "}
                      {formData?.storage_unit && formData.storage_unit}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Dose quantity</th>
                    <td>
                      <input
                        type="number"
                        name="dose_given"
                        placeholder=""
                        ref={doseQuantityRef}
                        disabled={
                          formData?.available_qty ||
                          loggedInUserDetails?.role === PVTUSERROLE
                            ? false
                            : true
                        }
                        onChange={handleChange}
                      />{" "}
                      {formData?.measuring_unit && formData.measuring_unit}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Attending Nurse/Doctor</th>
                    <td>
                      <input
                        type="text"
                        name="given_by_nurse_or_doctor"
                        placeholder=""
                        disabled={
                          formData?.available_qty ||
                          loggedInUserDetails?.role === PVTUSERROLE
                            ? false
                            : true
                        }
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Blood Pressure</th>
                    <td>
                      <table>
                        <tr>
                          <td>
                            <input
                              type="number"
                              name="systolic_pressure"
                              placeholder="Systolic Pressure"
                              disabled={
                                formData?.available_qty ||
                                loggedInUserDetails?.role === PVTUSERROLE
                                  ? false
                                  : true
                              }
                              onChange={handleChange}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              name="diastolic_pressure"
                              placeholder="Diastolic Pressure"
                              disabled={
                                formData?.available_qty ||
                                loggedInUserDetails?.role === PVTUSERROLE
                                  ? false
                                  : true
                              }
                              onChange={handleChange}
                            />
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Heart Rate</th>
                    <td>
                      <input
                        type="number"
                        name="heart_rate"
                        placeholder="Heart Rate (BPM)"
                        disabled={
                          formData?.available_qty ||
                          loggedInUserDetails?.role === PVTUSERROLE
                            ? false
                            : true
                        }
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Temperature</th>
                    <td>
                      <input
                        type="number"
                        name="temperature"
                        placeholder="Temperature"
                        disabled={
                          formData?.available_qty ||
                          loggedInUserDetails?.role === PVTUSERROLE
                            ? false
                            : true
                        }
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      Allergic Reaction Details (if any)
                    </th>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ height: "100px" }}>
                      <textarea
                        type="text"
                        name="allergic_reactions"
                        className="newPatientSelect w-100 h-100"
                        row={3}
                        placeholder=""
                        disabled={
                          formData?.available_qty ||
                          loggedInUserDetails?.role === PVTUSERROLE
                            ? false
                            : true
                        }
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      Comments
                    </th>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ height: "170px" }}>
                      <textarea
                        type="text"
                        name="comments"
                        className="newPatientSelect w-100 h-100"
                        row={3}
                        placeholder=""
                        disabled={
                          formData?.available_qty ||
                          loggedInUserDetails?.role === PVTUSERROLE
                            ? false
                            : true
                        }
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/* {JSON.stringify(formData)} */}
          {/* {JSON.stringify(loggedInUserDetails)} */}
        </fieldset>
      </div>
    </div>
  );
};

const ProductName = ({ prod_name, qty, pexpiry, loggedInUserDetails }) => {
  const [{ errorLogApi, expiryFormatter }] = useAtom(gbState);
  return (
    <div>
      <span className="text-xs">{prod_name}</span>
      <p className="text-black truncate text-light font-12" small>
        <Badge bg="primary">{qty && "Available Qty: " + qty}</Badge> &nbsp;
        <Badge bg="secondary">
          {pexpiry && "Expiry: " + expiryFormatter(pexpiry)}
        </Badge>
        {loggedInUserDetails?.role === PVTUSERROLE && (
          <Badge bg="primary">Local Inventory</Badge>
        )}
      </p>
    </div>
  );
};

export default CreateDoseForPatientModal;

import React, { useContext } from "react";
// import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import { Button, Alert } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import "./UploadReports.css";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { confirmAlert } from "react-confirm-alert";

//Context import
import AuthContext from "../../../components/shared/Context";

const UploadReports = ({ emrRecordId }) => {
  const { dateFormatter, loggedInUserDetails } = useContext(AuthContext);
  //const [value, setValue] = React.useState([1, 3]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [formData, setFormData] = useState({
    emr_id: emrRecordId,
    rtype: null,
    rcomments: null,
    createdBy: loggedInUserDetails._id,
  });

  const reportTypeOptions = [
    { value: "factor_assay", label: "Factor Assay" },
    { value: "xray", label: "Xray" },
    { value: "mriscan", label: "MRI Scan" },
    { value: "ultrasound", label: "Ultrasound" },
    { value: "prescription", label: "Prescription" },
    { value: "blood_test_report", label: "Blood Test Report" },
    { value: "ct_scan", label: "CT Scan" },
    {
      value: "pulmonary_function_test_report",
      label: "Pulmonary Function Test Report",
    },
    { value: "malaria_test_report", label: "Malaria Test Report" },
    { value: "hiv_test_report", label: "HIV Test Report" },
    { value: "hbv_test_report", label: "Hbv Test Report" },
    { value: "hcv_test_report", label: "Hcv Test Report" },
    { value: "dengue_reports", label: "Dengue Reports" },
    {
      value: "hospital_discharge_summary",
      label: "Hospital Discharge Summary",
    },
    { value: "thyroid_function_test", label: "Thyroid Function Test" },
    { value: "biopsy_results", label: "Biopsy results" },
    {
      value: "neuropsychology_evaluations",
      label: "Neuropsychology Evaluations",
    },
    {
      value: "Emergency_room_clinical_note",
      label: "Emergency Room clinical Notes",
    },
  ];

  useEffect(() => {
    loadPreviousReports();
  }, []);

  const loadPreviousReports = () => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/upload/${emrRecordId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        //toast.success("Report Uploaded Successfully.");
        setUploadedFiles(response.data);
      })
      .catch(function (error) {
        //toast.error(JSON.stringify(error.response.data));
        setUploadedFiles([]);
      });
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: "",
      message: "Are you sure you want to Delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteRecord = (id) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(`${process.env.REACT_APP_API_URL}/upload/delete/${id}`, config)
      .then((response) => {
        loadPreviousReports();
      })
      .catch((err) => console.log(err));
  };

  const updateEmr = (e) => {
    e.preventDefault();
    //changeTab(1);

    if (!fileData) {
      toast.error("Please select a file");
      return;
    }

    //New data being created before submission
    const data = new FormData();
    data.append("file", fileData);
    data.append("rtype", formData.rtype);
    data.append("rcomments", formData.rcomments ? formData.rcomments : "");
    data.append("createdBy", formData.createdBy);

    let userToken = localStorage.getItem("token");
    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/upload/${emrRecordId}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userToken}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        loadPreviousReports();
        toast.success("Report Uploaded Successfully.");
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error.response));
      });
  };

  const updateReportDescription = (data) => {
    //New data being created before submission

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/upload/update/${data._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...data },
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));

        toast.success("Report updated Successfully.");
        loadPreviousReports();
      })
      .catch(function (error) {
        toast.error(error?.response?.data);
        console.log(JSON.stringify(error.response));
      });
  };

  const handleChangeValue = ({ target: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
      createdBy: loggedInUserDetails._id,
    });
  };

  const dropDownChange = (selected, dropdown) => {
    //console.log("this is selected", JSON.stringify(dropdown.name));
    setFormData({
      ...formData,
      [dropdown.name]: JSON.stringify(selected),
    });
  };

  const handleFileUpload = (e) => {
    let uploadedfile = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(uploadedfile);

    reader.onload = (e) => {
      // console.log(e.target.result);
      setFileData(uploadedfile);
    };

    // console.log("File ", file);
  };

  return (
    <>
      <form className="d-flex flex-sm-wrap" onSubmit={(e) => updateEmr(e)}>
        {/* {JSON.stringify(formData)} */}
        <div className="table-responsive w-100" style={{ marginRight: "40px" }}>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col" colspan="2">
                  Upload Reports
                </th>
                <th scope="col" colspan="2" style={{ textAlign: "right" }}>
                  {/* <Button variant="primary" size="large" type="submit">
                  Upload
                </Button> */}
                  &nbsp;
                </th>
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              <tr>
                <td>&nbsp;</td>
                <td>Select File to upload</td>
                <td>
                  <input
                    type="file"
                    name="file"
                    onChange={(e) => {
                      handleFileUpload(e);
                    }}
                  />
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>Report Type</td>
                <td>
                  <Select
                    name="rtype"
                    onChange={dropDownChange}
                    className="newPatientSelect"
                    options={reportTypeOptions}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                  />
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>Comments</td>
                <td>
                  <textarea
                    name="rcomments"
                    onChange={handleChangeValue}
                    className="comments-class"
                    row={5}
                    style={{ width: "100%", height: "100px" }}
                  />
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td colSpan="4" style={{ textAlign: "right" }}>
                  <Button variant="primary" size="large" type="submit">
                    Upload
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
      {uploadedFiles.length > 0 && (
        <UploadReportsTable
          uploadedFiles={uploadedFiles}
          handleDelete={handleDelete}
          dateFormatter={dateFormatter}
          dropDownChange={dropDownChange}
          reportTypeOptions={reportTypeOptions}
          handleChangeValue={handleChangeValue}
          updateReportDescription={updateReportDescription}
        />
      )}
    </>
  );
};

export default UploadReports;

const UploadReportsTable = ({
  uploadedFiles,
  handleDelete,
  dateFormatter,
  dropDownChange,
  reportTypeOptions,
  handleChangeValue,
  updateReportDescription,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };

  return (
    <div className="table-responsive" style={{ marginRight: "40px" }}>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col" colspan="2">
              Uploaded Reports
            </th>
            <th scope="col" colspan="2">
              &nbsp;
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>

          {uploadedFiles.map((file) => {
            return (
              <tr key={file._id}>
                <td>
                  <i
                    className="mdi mdi-square-edit-outline"
                    onClick={() => viewRecord(file)}
                    style={{
                      fontSize: "15px",
                      color: "grey",
                      cursor: "pointer",
                    }}
                  />
                </td>
                <td>{dateFormatter(file.createdAt)}</td>
                <td>{JSON.parse(file.reports.rtype).label}</td>
                <td className="comment-section">
                  {file.reports.rcomments && file.reports.rcomments}
                </td>
                <td style={{ textAlign: "right" }}>
                  <a
                    href={
                      process.env.REACT_APP_REPORTS_DOWNLOAD_URL +
                      file.reports.rname
                    }
                    target="_blank"
                    title="Upload"
                  >
                    Download Report
                  </a>
                  &nbsp;
                  {/* <BsXCircle
                    className="delete-icon"
                    onClick={() => handleDelete(file._id)}
                  /> */}
                  <i
                    className="mdi mdi-minus-circle-outline"
                    onClick={() => handleDelete(file._id)}
                    style={{
                      fontSize: "18px",
                      color: "red",
                      cursor: "pointer",
                      verticalAlign: "middle",
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Modal Popup */}
      <Modal show={openModal} size="lg" centered>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>File Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent
            currentRecord={currentRecord}
            dropDownChange={dropDownChange}
            reportTypeOptions={reportTypeOptions}
            handleChangeValue={handleChangeValue}
            updateReportDescription={updateReportDescription}
            setCurrentRecord={setCurrentRecord}
            setOpenModal={setOpenModal}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

const ModalContent = ({
  currentRecord,
  dropDownChange,
  reportTypeOptions,
  handleChangeValue,
  updateReportDescription,
  setCurrentRecord,
  setOpenModal,
}) => {
  const [formData, setFormData] = useState(currentRecord);

  return (
    <div className="container-fluid legend">
      <table className="table table-striped">
        <tbody>
          <tr>
            <td>&nbsp;</td>
            <td>Report Type</td>
            <td>
              <Select
                name="rtype"
                onChange={(selected, dropdown) => {
                  setFormData({
                    ...formData,
                    reports: {
                      ...formData.reports,
                      rtype: JSON.stringify(selected),
                    },
                  });
                }}
                className="newPatientSelect"
                options={reportTypeOptions}
                // menuPortalTarget={document.body}
                menuPosition={"fixed"}
                value={formData.reports && JSON.parse(formData.reports.rtype)}
              />
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>Comments</td>
            <td>
              <textarea
                name="rcomments"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    reports: {
                      ...formData.reports,
                      rcomments: e.target.value,
                    },
                  });
                }}
                className="comments-class"
                row={5}
                style={{ width: "100%", height: "100px" }}
                value={formData.reports && formData.reports.rcomments}
              />
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td colSpan="4" style={{ textAlign: "right" }}>
              <Button
                variant="primary"
                size="large"
                type="submit"
                onClick={() => {
                  updateReportDescription(formData);
                  setOpenModal(false);
                }}
              >
                Update
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
      {/* {JSON.stringify(formData)} */}
    </div>
  );
};

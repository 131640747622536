import React, { useEffect, useState, useContext, useRef } from "react";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import "antd/dist/antd.min.css";
import { Modal } from "react-bootstrap";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@mui/material/Tooltip";
import { Row, Col } from "react-bootstrap";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

//Global State import
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import toast from "react-hot-toast";
//Context import
import AuthContext from "../../../components/shared/Context";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const TCWiseInventoryStatusChild = () => {
  let navigate = useNavigate();
  const { loggedInUserDetails } = useContext(AuthContext);
  const [tcenters, setTcenters] = useState();
  const [selectedTC, setSelectedTC] = useState();
  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const searchRef = useRef();

  //Error Logging Service
  const { dateFormatter, errorLogApi } = useAtom(gbState);

  //Get All TCs
  useEffect(() => {
    const getTCDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/tc/`;

      if (loggedInUserDetails.role === "ST_ADM_ACCESS") {
        urlLink = `${process.env.REACT_APP_API_URL}/tc/state/${loggedInUserDetails.state}`;
      }

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data.treatment_center));
          const oldData = response.data.treatment_center;
          const filteredTC = oldData?.filter(
            (tc) => tc?.tcenters?.tc_type === "government"
          );
          const updatedData = filteredTC.map((data) => {
            return {
              ...data,
              id: data?._id,
              value: data?._id,
              name_tc: data?.tcenters?.name_tc,
              hdcc_no: data?.tcenters?.hdcc_no,
              state: data?.tcenters?.state,
              district: data?.tcenters?.district,
              tcid: data?.tcenters?.treatment_center_id,
              label: (
                <TCDetails
                  tc_id={data?.tcenters?.treatment_center_id}
                  tc_name={data.tcenters.name_tc}
                />
              ),
              dataLabel: data?.tcenters?.name_tc,
            };
          });
          // console.log(updatedData);
          setTcenters(updatedData);
        })
        .catch(function (error) {
          //console.log(error.message);
        });
    };

    getTCDataFromServer();
  }, []);

  //Get Current status of inventory
  const getTCInventoryDataFromServer = () => {
    // console.log(selectedTC);
    // return;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    const bodyParameters = {
      key: "value",
    };
    let urlLink = `${process.env.REACT_APP_API_URL}/tcstock/${selectedTC?.id}`;
    axios
      .get(urlLink, config)
      .then((res) => {
        const oldData = res.data.current_stock;
        //console.log(oldData);
        const updatedData = oldData.map((data) => {
          //   console.log(data.purchase_inventory.product_id.products);
          return {
            ...data,
            id: data?._id,
            product_name:
              data?.current_stock?.product_id?.products?.product_name,
            category:
              data?.current_stock?.product_id?.products?.category_id?.category,
            item_code: data?.current_stock?.item_code,
            opening_stock:
              data?.current_stock?.opening_stock +
              " " +
              data?.current_stock?.product_id?.products?.storage_unit,
            purchase_qty:
              data?.current_stock?.purchase_qty +
              " " +
              data?.current_stock?.product_id?.products?.storage_unit,
            sale_qty:
              data?.current_stock?.sale_qty +
              " " +
              data?.current_stock?.product_id?.products?.storage_unit,
            closing_stock:
              data?.current_stock?.closing_stock +
              " " +
              data?.current_stock?.product_id?.products?.storage_unit,
            state: data?.current_stock?.tc_id?.tcenters?.state,
            tc_name: data?.current_stock?.tc_id?.tcenters?.name_tc,
            updatedAt: data?.updatedAt,
          };
        });
        //console.log(updatedData);
        setData(updatedData);
        setUnfilteredData(updatedData);
      })
      .catch((err) => console.log("error", err));
  };

  useEffect(() => {
    if (selectedTC) {
      getTCInventoryDataFromServer();
    }
  }, [selectedTC]);

  const useStyles = makeStyles(() => ({
    multiLineCell: {
      display: "flex",
      wordBreak: "keep-all",
      wordWrap: "break-word",
      overflowWrap: "break-word",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      lineHeight: "1.2rem",
      height: "auto",
    },
  }));

  const MultiLineCellRenderer = (params) => {
    const classes = useStyles();
    return (
      <div className={classes.multiLineCell}>
        <span id="content1">{params.row.product_name}</span>
        <span id="content2">
          <small className="small-font-size">{params.row.item_code}</small>
        </span>
      </div>
    );
  };

  const columns = [
    {
      field: "product_name",
      headerName: "Product",
      width: 240,
      renderCell: MultiLineCellRenderer,
    },
    {
      field: "category",
      headerName: "Category",
      width: 150,
    },
    {
      field: "opening_stock",
      headerName: "Opening Stock",
      width: 160,
      filterable: false,
      disableColumnMenu: true,
      align: "center",
    },
    {
      field: "purchase_qty",
      headerName: "Alloted",
      width: 110,
      filterable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "center",
    },
    {
      field: "sale_qty",
      headerName: "Consumed",
      width: 130,
      filterable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "center",
    },
    {
      field: "closing_stock",
      headerName: "Closing Stock",
      width: 160,
      filterable: false,
      disableColumnMenu: true,
      align: "center",
    },

    {
      field: "tc_name",
      headerName: "TC Name",
      width: 150,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "state",
      headerName: "State",
      width: 110,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };

  const handleSearch = (e) => {
    console.log("Search Value...", e);

    if (e) {
      setData(
        unfilteredData.filter((item) => {
          return (
            item?.item_code?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.product_name?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.purchase_qty?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.sale_qty?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.category?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.closing_stock?.toLowerCase().includes(e?.toLowerCase())
          );
        })
      );
    } else {
      getTCInventoryDataFromServer();
      setData(unfilteredData);
    }
  };

  const clearSearch = () => {
    searchRef.current.value = null;
    getTCInventoryDataFromServer();
  };

  const generatePdfContent = (data) => {
    // Make sure data is passed as an argument
    const currentDate = new Date().toLocaleDateString();

    const tableBody = [
      [
        "Product",
        "Category",
        "Opening Stock",
        "Allotted",
        "Consumed",
        "Closing Stock",
      ],
      ...data.map((d) => [
        // Assuming 'data' is an array of objects
        `${d.product_name} (${d.item_code})`,
        d.category,
        d.opening_stock,
        d.purchase_qty,
        d.sale_qty,
        d.closing_stock,
      ]),
    ];

    const content = [
      {
        text: `${selectedTC?.name} - ${currentDate}`, // Included currentDate
        style: "title",
      },
      {
        table: {
          headerRows: 1,
          //    widths: ["auto", "auto", "auto", "auto", "auto", "auto"], // 6 widths for 6 columns
          widths: ["*", "*", "*", "*", "*", "*"], // Distribute available width evenly across columns
          body: tableBody,
        },
        style: "table",
      },
    ];

    const docDefinition = {
      content,
      styles: {
        title: {
          fontSize: 16,
          bold: true,
          marginBottom: 10,
        },
        table: {
          margin: [0, 10, 0, 10],
        },
      },
      pageOrientation: "landscape", // Set the page orientation to landscape
    };

    return docDefinition;
  };

  const downloadPdfDocument = () => {
    if (!data?.length) return toast.error("Nothing to download");
    const docDefinition = generatePdfContent(data);
    const pdfDoc = pdfMake.createPdf(docDefinition);
    const timestamp = new Date().toISOString().replace(/[\-\:\.]/g, "_");
    const filename = `patient_data_${timestamp}.pdf`;
    pdfDoc.download(filename);
  };

  return (
    <div>
      <span className="card-title">
        <h3>Total TC Stocks (Current Status)</h3>
      </span>
      <Row className="align-items-center">
        <Col>
          <Select
            name="tc-select"
            onChange={(selected, dropdown) => {
              setSelectedTC({ id: selected?._id, name: selected?.name_tc });
              //console.log(selected?._id);
            }}
            placeholder="Select TC..."
            options={tcenters}
            filterOption={(option, input) => {
              return option.data.dataLabel
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
          />
        </Col>
        <Col>
          <button className="btn btn-primary" onClick={downloadPdfDocument}>
            Download PDF
          </button>
        </Col>
      </Row>
      <div className="patientListSearch">
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">Filter</InputGroup.Text>
          <Form.Control
            placeholder="Product / State / Code / Company ..."
            aria-label="Search"
            aria-describedby="basic-addon1"
            name="searchPatients"
            ref={searchRef}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <InputGroup.Text>
            <i
              className="mdi mdi-delete-outline"
              onClick={clearSearch}
              style={{ cursor: "pointer" }}
            ></i>
          </InputGroup.Text>
        </InputGroup>
      </div>
      <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        displayTitle="Current Stocks"
        checkboxSelection
        style={{ height: "60vh" }}
      />
      {/* {JSON.stringify(data)} */}
      {/* Modal Popup */}
      <Modal show={openModal} centered>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Stocks Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent currentRecord={currentRecord} />
        </Modal.Body>
      </Modal>

      {/* {JSON.stringify(currentRecord)} */}
    </div>
  );
};

const ModalContent = ({ currentRecord }) => {
  const dateFormatter = (date) => {
    const date_conv = new Date(date);
    const fullyear = date_conv.getFullYear();
    const month = date_conv.getMonth() + 1;
    const day = date_conv.getDate();
    return `${day}/${month}/${fullyear}`;
  };

  return (
    <div className="container-fluid">
      <table class="table table-striped">
        <tbody>
          <tr>
            <th scope="row">Product Name</th>
            <td>{currentRecord?.product_name}</td>
          </tr>
          <tr>
            <th scope="row">Measuring Units</th>
            <td>{currentRecord?.measuring_unit}</td>
          </tr>
          <tr>
            <th scope="row">Drug Category</th>
            <td>{currentRecord?.category}</td>
          </tr>
          <tr>
            <th scope="row">Quantity</th>
            <td>{currentRecord?.quantity}</td>
          </tr>
          <tr>
            <th scope="row">Expiry</th>
            <td>{dateFormatter(currentRecord?.product_expiry)}</td>
          </tr>
          <tr>
            <th scope="row">Company Name</th>
            <td>{currentRecord?.company_name}</td>
          </tr>
          <tr>
            <th scope="row">State</th>
            <td>{currentRecord?.state}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const TCDetails = ({ tc_name, tc_id }) => {
  return (
    <div>
      <span className="text-bold">{tc_name}</span>
      <p className="text-black truncate text-light font-12" small>
        TC ID: {tc_id}
      </p>
    </div>
  );
};

export default TCWiseInventoryStatusChild;

import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import { confirmAlert } from "react-confirm-alert";
//import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import Badge from "react-bootstrap/Badge";
import { Button } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

//Context import
import AuthContext from "../../../components/shared/Context";

const ListKnowledgebaseChild = () => {
  let navigate = useNavigate();
  //Error Logging Service
  const [{ errorLogApi }] = useAtom(gbState);

  const { loggedInUserDetails, setPatientDiagnosis, setFieldLabelFromArray } =
    useContext(AuthContext);

  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      width: 100,
      sortable: false,
      hideable: false,
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            <Tooltip title="View Health Record">
              <a onClick={() => {}}>
                <i
                  className="mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </a>
            </Tooltip>

            <Tooltip title="Delete Health Record">
              <div onClick={() => {}}>
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit Health Record">
              <div onClick={() => {}}>
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 110,
    },
    {
      field: "adl",
      headerName: "Activity of Daily living",
      width: 200,
    },
  ];

  const getDataFromServer = () => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };

    const bodyParameters = {
      key: "value",
    };

    let urlLink = `${process.env.REACT_APP_API_URL}/knowledge/${loggedInUserDetails.state}`;

    axios
      .get(urlLink, config)
      .then((res) => {
        const oldData = res.data.health_records;
        console.log(res.data);
        const updatedData = oldData.map((data) => {
          return {
            ...data?.quality_of_life,
            id: data._id,
            diagnosis: data.diagnoses.diagnosis_name,
          };
        });
        //console.log(updatedData);
        setData(updatedData);
      })
      .catch((err) => {
        errorLogApi(err, "HealthListChild", urlLink);
      });
  };

  return (
    <div>
      <div className="patientUpdateButton">
        <Button
          variant="primary"
          size="large"
          onClick={() => navigate("/addkb/")}
        >
          ADD Research Paper
        </Button>{" "}
      </div>
      <div>
        <h1 className="allPatients">Knowledgebase</h1>
      </div>
      <div className="patientListSearch">
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">Search Title</InputGroup.Text>
          <Form.Control
            placeholder="title..."
            aria-label="Search"
            aria-describedby="basic-addon1"
            name="searchPatients"
            onChange={(e) => {}}
          />
        </InputGroup>
      </div>
      {/* {JSON.stringify(loggedInUserDetails)} */}
      <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        displayTitle="Patient List"
        checkboxSelection
        style={{ height: "60vh" }}
      />
    </div>
  );
};

export default ListKnowledgebaseChild;

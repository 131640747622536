import Select from "react-select";
import { useRef } from "react";
import { BsQuestionCircleFill } from "react-icons/bs";
import { DatePicker } from "antd";
import AlertDialog from "../../../components/InfoDialog/AlertDialog";

const ClinicalTab = ({
  handleChange,
  precipitatingFactorData,
  dropDownChange,
  selectedDiagnosis,
  formData,
  setFormData,
}) => {
  const childRef = useRef();

  const bleedDisorderOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const precipFactorOptions = precipitatingFactorData.map((data) => ({
    value: data.id,
    label: data.pfactor,
  }));

  const transfusionRegimenOptions = [
    { value: 1, label: "Hypo-transfusion (Pre-Hb 7-8)" },
    { value: 2, label: "Hypo-transfusion (Pre-Hb 9-10)" },
    { value: 3, label: "Super-transfusion (Pre-Hb 11-12)" },
    { value: 4, label: "Others" },
  ];

  const specialTransfusionOptions = [
    { value: 1, label: "Irradiation" },
    { value: 2, label: "Triple Saline Wash" },
    { value: 3, label: "Screen for Specific Antigens" },
    { value: 4, label: "Donor Type Blood" },
    { value: 5, label: "O Type Blood" },
    { value: 6, label: "Inline filtration" },
    { value: 7, label: "Premedication needed" },
    { value: 8, label: "Transfuse slowly" },
    { value: 9, label: "Others" },
  ];

  const onChelationOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const splenectomyOptions = [
    { value: true, label: "Done" },
    { value: false, label: "Not Done" },
  ];

  const tannerScaleOptions = [
    { value: 1, label: "I" },
    { value: 2, label: "II" },
    { value: 3, label: "III" },
    { value: 4, label: "IV" },
    { value: 5, label: "V" },
  ];

  const tranfusionRegimenHelp =
    "Transfusion Regimen : Hypo transfusion is usually associated with only 1 bag transfusion. Hyper transfusion and Super transfusion target a post-transfusion Hb of 13-14gm/dl. The system uses 13.5 gm/dl for calculations of blood requirement.";
  const specialTransfusionNote =
    "Write the instruction for blood bank in case of special transfusion need.";

  const firstTimeBleedOptions = [
    { value: 1, label: "Minor" },
    { value: 2, label: "Major" },
  ];

  return (
    <div className="newPatientFormOnly">
      {/* {JSON.stringify(selectedDiagnosis)} */}
      <AlertDialog ref={childRef} title="Special Tranfusion note" />
      <div className="newPatientItem">
        <label htmlFor="date_of_diagnosis">Date of diagnosis</label>
        {/* <input type="date" name="date_of_diagnosis" onChange={handleChange} /> */}
        <DatePicker
          className="newPatientSelect datePicker"
          style={{ paddingLeft: "10px" }}
          name="date_of_diagnosis"
          format="DD-MM-YYYY"
          onChange={(date, dateString) => {
            setFormData({
              ...formData,
              date_of_diagnosis: dateString,
            });
          }}
        />
      </div>

      <div className="newPatientItem">
        <label>History of Bleeds Disorder</label>
        <Select
          name="family_history_bleed_disorder"
          onChange={(selected, dropdown) => {
            //console.log(selected);
            setFormData({
              ...formData,
              family_history_bleed_disorder: JSON.stringify(selected),
            });
          }}
          className="newPatientSelect"
          options={bleedDisorderOptions}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="annual_bleed_frequency">Freqency of Bleeds/year</label>
        <input
          type="text"
          name="annual_bleed_frequency"
          placeholder="Average yearly frequency"
          onChange={handleChange}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="first_time_bleed">First Time Bleed</label>
        {/* <input type="date" name="first_time_bleed" onChange={handleChange} /> */}
        <DatePicker
          className="newPatientSelect datePicker"
          style={{ paddingLeft: "10px" }}
          name="first_time_bleed"
          onChange={(date, dateString) => {
            setFormData({
              ...formData,
              first_time_bleed: dateString,
            });
          }}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="age_at_first_diagnosis">Age at First Diagnosis</label>
        <input
          type="text"
          name="age_at_first_diagnosis"
          placeholder="e.g 2 yrs 3 months is 2.3"
          onChange={handleChange}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="first_factor_exposure">First Treatment Exposure</label>

        <DatePicker
          className="newPatientSelect datePicker"
          style={{ paddingLeft: "10px" }}
          name="first_factor_exposure"
          onChange={(date, dateString) => {
            setFormData({
              ...formData,
              first_factor_exposure: dateString,
            });
          }}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="type_of_first_time_bleed">First time bleed Type</label>
        <Select
          name="type_of_first_time_bleed"
          onChange={(selected, dropdown) => {
            //console.log(selected);
            setFormData({
              ...formData,
              type_of_first_time_bleed: JSON.stringify(selected),
            });
          }}
          className="newPatientSelect"
          options={firstTimeBleedOptions}
        />
      </div>
      {/* updated code starts */}
      <div className="newPatientItem">
        <label htmlFor="cbc_date">CBC Date</label>
        <DatePicker
          className="newPatientSelect datePicker"
          style={{ paddingLeft: "10px" }}
          name="cbc_date"
          onChange={(date, dateString) => {
            setFormData({
              ...formData,
              cbc_date: dateString,
            });
          }}
        />
      </div>

      <div className="newPatientItem">
        <label htmlFor="hemoglobin_gm_dl">Hemoglobin (gm/dl)</label>
        <input type="Number" name="hemoglobin_gm_dl" onChange={handleChange} />
      </div>
      <div className="newPatientItem">
        <label htmlFor="platelet_count">Platelet count</label>
        <input type="Number" name="platelet_count" onChange={handleChange} />
      </div>
      {selectedDiagnosis.label.toLowerCase().includes("hemophilia") && (
        <div className="newPatientItem">
          <label htmlFor="pt">PT</label>
          <input type="Number" name="pt" onChange={handleChange} />
        </div>
      )}
      {selectedDiagnosis.label.toLowerCase().includes("hemophilia") && (
        <div className="newPatientItem">
          <label htmlFor="aptt">APTT</label>
          <input type="Number" name="aptt" onChange={handleChange} />
        </div>
      )}
      {selectedDiagnosis.label.toLowerCase().includes("hemophilia") && (
        <div className="newPatientItem">
          <label htmlFor="clot_solubility_test">Clot Soluble Test</label>
          <input
            type="Number"
            name="clot_solubility_test"
            onChange={handleChange}
          />
        </div>
      )}
      <div className="newPatientItem">
        <label htmlFor="molecular_diagnosis">Molecular Diagnosis</label>
        <input type="text" name="molecular_diagnosis" onChange={handleChange} />
      </div>

      {selectedDiagnosis.label.toLowerCase().includes("hemophilia") && (
        <div className="newPatientItem">
          <label htmlFor="carrier_status_mother_sister">Carrier Status </label>
          <div>
            <span
              style={{
                float: "left",
                fontSize: "14px",
                fontWeight: "600",
                color: "gray",
              }}
            >
              <input
                type="checkbox"
                name="carrier_status_mother"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    carrier_status_mother: !formData.carrier_status_mother,
                  });
                }}
              />
              &nbsp;&nbsp;Mother
            </span>
            <span
              style={{
                float: "right",
                fontSize: "14px",
                fontWeight: "600",
                color: "gray",
              }}
            >
              <input
                type="checkbox"
                name="carrier_status_sister"
                onChange={() => {
                  setFormData({
                    ...formData,
                    carrier_status_sister: !formData.carrier_status_sister,
                  });
                }}
              />
              &nbsp;&nbsp;Sister
            </span>
          </div>
        </div>
      )}

      {selectedDiagnosis.label.toLowerCase().includes("hemophilia") && (
        <div className="newPatientItem">
          <label htmlFor="genetic_counselling_date">
            Genetic Counselling &nbsp;&nbsp;
            <input
              type="checkbox"
              name="genetic_counselling"
              onChange={() => {
                setFormData({
                  ...formData,
                  genetic_counselling: !formData.genetic_counselling,
                });
              }}
            />
          </label>
          <DatePicker
            className="newPatientSelect datePicker"
            style={{ paddingLeft: "10px" }}
            name="genetic_counselling_date"
            onChange={(date, dateString) => {
              setFormData({
                ...formData,
                genetic_counselling_date: dateString,
              });
            }}
            disabled={!formData.genetic_counselling}
          />
        </div>
      )}
      <div className="newPatientItem">
        <label htmlFor="counseled_prenatal_diagnosis_date">
          Counseled Prenatal &nbsp;&nbsp;
          <input
            type="checkbox"
            name="counseled_prenatal_diagnosis"
            onChange={() => {
              setFormData({
                ...formData,
                counseled_prenatal_diagnosis:
                  !formData.counseled_prenatal_diagnosis,
              });
            }}
          />
        </label>
        <DatePicker
          className="newPatientSelect datePicker"
          style={{ paddingLeft: "10px" }}
          name="counseled_prenatal_diagnosis_date"
          onChange={(date, dateString) => {
            setFormData({
              ...formData,
              counseled_prenatal_diagnosis_date: dateString,
            });
          }}
          disabled={!formData.counseled_prenatal_diagnosis}
        />
      </div>
      {/* updated ends */}
      {selectedDiagnosis.label.toLowerCase().includes("hemophilia a") && (
        <div className="newPatientItem">
          <label htmlFor="factor_level_viii_percent">Factor VIII Level %</label>
          <input
            type="text"
            name="factor_level_viii_percent"
            placeholder=""
            onChange={handleChange}
          />
        </div>
      )}

      {selectedDiagnosis.label.toLowerCase().includes("hemophilia b") && (
        <div className="newPatientItem">
          <label htmlFor="factor_level_ix_percent">Factor IX Level %</label>
          <input
            type="text"
            name="factor_level_ix_percent"
            placeholder=""
            onChange={handleChange}
          />
        </div>
      )}

      <div className="newPatientItem">
        <label htmlFor="date_first_factor_inclusion">
          Date 1st factor inclusion
        </label>
        <DatePicker
          className="newPatientSelect datePicker"
          style={{ paddingLeft: "10px" }}
          name="date_first_factor_inclusion"
          onChange={(date, dateString) => {
            setFormData({
              ...formData,
              date_first_factor_inclusion: dateString,
            });
          }}
        />
      </div>

      <div className="newPatientItem">
        <label htmlFor="number_of_transfusions">Number of Exposure days</label>
        <input
          type="text"
          name="number_of_transfusions"
          placeholder="Before registration..."
          onChange={handleChange}
        />
      </div>

      <div className="newPatientItem">
        <label htmlFor="tanner_scale_last_assesment_date">
          Tanner(Date of Last assmt)
        </label>
        <DatePicker
          className="newPatientSelect datePicker"
          style={{ paddingLeft: "10px" }}
          name="tanner_scale_last_assesment_date"
          onChange={(date, dateString) => {
            setFormData({
              ...formData,
              tanner_scale_last_assesment_date: dateString,
            });
          }}
        />
      </div>
      <div className="newPatientItem">
        <label>Tanner(Genitals/Breasts)</label>
        <Select
          name="tanner_scale_genital_breasts"
          onChange={
            //dropDownChange
            (selected, dropdown) => {
              //console.log(selected);
              setFormData({
                ...formData,
                tanner_scale_genital_breasts: JSON.stringify(selected),
              });
            }
          }
          className="newPatientSelect"
          options={tannerScaleOptions}
        />
      </div>
      <div className="newPatientItem">
        <label>Tanner(Pubic Hair)</label>
        <Select
          name="tanner_scale_pubic_hair"
          onChange={
            //dropDownChange
            (selected, dropdown) => {
              //console.log(selected);
              setFormData({
                ...formData,
                tanner_scale_pubic_hair: JSON.stringify(selected),
              });
            }
          }
          className="newPatientSelect"
          options={tannerScaleOptions}
        />
      </div>
      <div className="newPatientItem">
        <label>Precipitating Factor</label>
        <Select
          name="precipitating_factor"
          onChange={
            //dropDownChange
            (selected, dropdown) => {
              //console.log(selected);
              setFormData({
                ...formData,
                precipitating_factor: JSON.stringify(selected),
              });
            }
          }
          className="newPatientSelect"
          options={precipFactorOptions}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="genetic_mutations">Genetic Mutations</label>
        <input type="text" name="genetic_mutations" onChange={handleChange} />
      </div>
      {selectedDiagnosis.label.toLowerCase().includes("thalassemia") && (
        <ThalassimiaClinicalFields
          handleChange={handleChange}
          dropDownChange={dropDownChange}
          splenectomyOptions={splenectomyOptions}
          onChelationOptions={onChelationOptions}
          childRef={childRef}
          specialTransfusionNote={specialTransfusionNote}
          specialTransfusionOptions={specialTransfusionOptions}
          transfusionRegimenOptions={transfusionRegimenOptions}
          tranfusionRegimenHelp={tranfusionRegimenHelp}
          setFormData={setFormData}
          formData={formData}
        />
      )}
    </div>
  );
};

export default ClinicalTab;

export const ThalassimiaClinicalFields = ({
  handleChange,
  dropDownChange,
  splenectomyOptions,
  onChelationOptions,
  specialTransfusionNote,
  childRef,
  tranfusionRegimenHelp,
  transfusionRegimenOptions,
  specialTransfusionOptions,
  setFormData,
  formData,
}) => {
  return (
    <>
      <div className="newPatientItem">
        <label htmlFor="approx_blood_vol_transfused_ml">
          Approx Blood Transfused
        </label>
        <input
          type="text"
          name="approx_blood_vol_transfused_ml"
          placeholder="Provide in ml"
          onChange={handleChange}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="transfusion_regimen">
          Transfusion Regimen{" "}
          <BsQuestionCircleFill
            color="green"
            className="helper-icon"
            onClick={() => {
              childRef.current.handleClickOpenInfo(
                "Transfusion Regimen",
                tranfusionRegimenHelp
              );
            }}
          />
        </label>
        <Select
          name="transfusion_regimen"
          onChange={
            //dropDownChange
            (selected, dropdown) => {
              //console.log(selected);
              setFormData({
                ...formData,
                transfusion_regimen: JSON.stringify(selected),
              });
            }
          }
          className="newPatientSelect"
          options={transfusionRegimenOptions}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="special_transfusion_needs">
          Special Transfusion needs
        </label>
        <Select
          name="special_transfusion_needs"
          onChange={
            // dropDownChange
            (selected, dropdown) => {
              //console.log(selected);
              setFormData({
                ...formData,
                special_transfusion_needs: JSON.stringify(selected),
              });
            }
          }
          className="newPatientSelect"
          options={specialTransfusionOptions}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="special_transfusion_note">
          Special Tranfusion note &nbsp;&nbsp;
          <BsQuestionCircleFill
            color="green"
            className="helper-icon"
            onClick={() => {
              childRef.current.handleClickOpenInfo(
                "Special Tranfusion note",
                specialTransfusionNote
              );
            }}
          />
        </label>
        <input
          type="text"
          name="special_transfusion_note"
          placeholder="Any instruction for blood bank"
          onChange={handleChange}
        />
      </div>
      <div className="newPatientItem">
        <label>On Chelation</label>
        <Select
          name="on_chelation"
          onChange={(selected, dropdown) => {
            setFormData({
              ...formData,
              on_chelation: JSON.stringify(selected),
            });
          }}
          className="newPatientSelect"
          options={onChelationOptions}
        />
      </div>
      <div className="newPatientItem">
        <label>Splenectomy</label>
        <Select
          name="splenectomy"
          onChange={(selected, dropdown) => {
            setFormData({
              ...formData,
              splenectomy: JSON.stringify(selected),
            });
          }}
          className="newPatientSelect"
          options={splenectomyOptions}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="atypical_antibodies">Atypical Antibodies</label>
        <input
          type="text"
          name="atypical_antibodies"
          placeholder=""
          onChange={handleChange}
        />
      </div>

      <div className="newPatientItem">
        <label htmlFor="date_of_first_transfusion">
          Date of First Transfusion
        </label>
        <DatePicker
          className="newPatientSelect datePicker"
          style={{ paddingLeft: "10px" }}
          name="date_of_first_transfusion"
          onChange={(date, dateString) => {
            setFormData({
              ...formData,
              date_of_first_transfusion: dateString,
            });
          }}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="number_of_transfusions">Number of Transfusions</label>
        <input
          type="text"
          name="number_of_transfusions"
          placeholder=""
          onChange={handleChange}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="approx_blood_vol_transfused_ml">
          Approx Blood Vol Trans.
        </label>
        <input
          type="text"
          name="approx_blood_vol_transfused_ml"
          placeholder="in ml"
          onChange={handleChange}
        />
      </div>
      {/* Update new code starts */}
      <div className="newPatientItem">
        <label htmlFor="tlc">TLC</label>
        <input
          type="Number"
          name="tlc"
          placeholder=""
          onChange={handleChange}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="mcv">MCV</label>
        <input
          type="Number"
          name="mcv"
          placeholder=""
          onChange={handleChange}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="parental_hplc_father">HPLC Father</label>
        <input
          type="Number"
          name="parental_hplc_father"
          placeholder=""
          onChange={handleChange}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="parental_hplc_mother">HPLC Mother</label>
        <input
          type="Number"
          name="parental_hplc_mother"
          placeholder=""
          onChange={handleChange}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="donor_search_status">
          Donor Search Date &nbsp;&nbsp;
          <input
            type="checkbox"
            name="donor_search_status"
            onChange={() => {
              setFormData({
                ...formData,
                donor_search_status: !formData.donor_search_status,
              });
            }}
          />
        </label>
        <DatePicker
          className="newPatientSelect datePicker"
          style={{ paddingLeft: "10px" }}
          name="donor_search_date"
          onChange={(date, dateString) => {
            setFormData({
              ...formData,
              donor_search_date: dateString,
            });
          }}
          disabled={!formData.donor_search_status}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="donor_search_result">Donor Search Result</label>
        <input
          type="text"
          name="donor_search_result"
          placeholder=""
          onChange={handleChange}
          disabled={!formData.donor_search_status}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="counseled_for_bmt_status">
          Counseled for BMT &nbsp;&nbsp;
          <input
            type="checkbox"
            name="counseled_for_bmt_status"
            onChange={() => {
              setFormData({
                ...formData,
                counseled_for_bmt_status: !formData.counseled_for_bmt_status,
              });
            }}
          />
        </label>
        <DatePicker
          className="newPatientSelect datePicker"
          style={{ paddingLeft: "10px" }}
          name="counseled_for_bmt_date"
          onChange={(date, dateString) => {
            setFormData({
              ...formData,
              counseled_for_bmt_date: dateString,
            });
          }}
          disabled={!formData.counseled_for_bmt_status}
        />
      </div>
      {/* Update code ends */}
    </>
  );
};

import React, { useEffect, useState, useContext, useRef } from "react";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Card from "react-bootstrap/Card";
import { confirmAlert } from "react-confirm-alert";
import "antd/dist/antd.min.css";
import { Modal } from "react-bootstrap";
import { DataGrid } from "@material-ui/data-grid";
import toast from "react-hot-toast";
import { makeStyles } from "@material-ui/core/styles";
import Badge from "react-bootstrap/Badge";

//Global State import
import { useAtom } from "jotai";
import { gbState } from "../../../../components/shared/GlobalState";

//Context import
import AuthContext from "../../../../components/shared/Context";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import ViewReAllocationContentModal from "./ViewReAllocationContentModal";

const ListReAllocationHistoryChild = () => {
  let navigate = useNavigate();
  const { loggedInUserDetails } = useContext(AuthContext);

  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const searchRef = useRef();

  //Error Logging Service
  const [
    {
      errorLogApi,
      dateFormatter: dformatter,
      _calculateDaysLeft,
      _checkCalculateDaysLeft,
    },
  ] = useAtom(gbState);

  const getDataFromServer = () => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    const bodyParameters = {
      key: "value",
    };

    let urlLink = `${process.env.REACT_APP_API_URL}/reallocate/${loggedInUserDetails.tc_id}`;

    axios
      .get(urlLink, config)
      .then((res) => {
        const oldData = res?.data;
        console.log(oldData);
        const updatedData = oldData?.map((data) => {
          //   console.log(data.purchase_inventory.product_id.products);
          return {
            ...data,
            id: data._id,
            product_name: data?.product_id?.products?.product_name,
            product_code: data?.product_id?.products?.item_code,
            product_expiry: data?.state_purchase_id?.p_register?.expiry_date,
            quantity:
              data?.allocated_quantity +
              " " +
              data?.product_id?.products?.storage_unit,
            allocated_to: data?.to_tc_id?.tcenters?.name_tc,
            stock_accepted: data?.stock_accepted,
            date_of_allocation: data?.allocation_recieved_date,
            local_allocation_id: data?.local_allocation_id,
          };
        });
        //console.log(updatedData);
        setData(updatedData);
        setUnfilteredData(updatedData);
      })
      .catch((err) => console.log("error", err));
  };

  useEffect(() => {
    getDataFromServer();
  }, []);

  const useStyles = makeStyles(() => ({
    multiLineCell: {
      display: "flex",
      wordBreak: "keep-all",
      wordWrap: "break-word",
      overflowWrap: "break-word",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      lineHeight: "0.8rem",
      height: "100%",
    },
    customRow: {
      height: "95px", // Increase the row height as needed
    },
  }));

  const calculateDaysLeft = (date) => {
    // Convert the given date string to a Date object
    const givenDate = new Date(date);

    // Calculate the difference between the current date and the given date in milliseconds
    const timeDiff = givenDate.getTime() - new Date().getTime();

    // Convert milliseconds to days and round down to the nearest integer
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    // Update the state with the number of days left
    //setDaysLeft(days);
    return days;
  };
  const dateFormatter = (date) => {
    const date_conv = new Date(date);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date_conv.getMonth()];
    const year = date_conv.getFullYear();
    return `${month} ${year}`;
  };

  const MultiLineCellRenderer = (params) => {
    const classes = useStyles();
    const daysLeft = calculateDaysLeft(params.row.product_expiry);
    return (
      <div className={classes.multiLineCell}>
        <span
          id="content1"
          style={{ display: "flex", alignItems: "center" }}
          className="text-bold"
        >
          {params.row.product_name}
        </span>
        <span id="content2">
          <small className="small-font-size">{params.row.product_code}</small>
        </span>
        <span id="content3">
          <small className="small-font-size">
            Expiry:{" "}
            {daysLeft !== null ? (
              `${dateFormatter(params.row.product_expiry)} (${daysLeft}) days`
            ) : (
              <p>Loading...</p>
            )}
          </small>
        </span>
      </div>
    );
  };

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };

  const revertRecord = (row) => {
    if (row?.stock_accepted)
      return toast.error(
        "The Allocation has already been acepted. Cannot be undone!"
      );

    confirmAlert({
      title: "",
      message: "Please select 'Yes' to confirm or 'No' to abort!",
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmRevert(row),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });

    const confirmRevert = (row) => {
      let userToken = localStorage.getItem("token");
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/reallocate/revert/`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        data: {
          allocation_id: row?._id,
        },
      };
      axios(config)
        .then(function (response) {
          getDataFromServer();
          toast.success("Allocated Successfully.");
        })
        .catch(function (error) {
          toast.error(JSON.stringify(error.response.data.message));
          console.log(error);
        });
    };
  };

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            <Tooltip title="Product Information">
              <a
                onClick={() => {
                  viewRecord(params.row);
                }}
              >
                <i
                  className="mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </a>
            </Tooltip>
            <Tooltip title="Revert drug to TC inventory">
              <div className="" onClick={() => revertRecord(params.row)}>
                <i
                  className="mdi mdi-close-circle"
                  style={{ color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "product_name",
      headerName: "Product",
      width: 220,
      renderCell: MultiLineCellRenderer,
    },
    {
      field: "local_allocation_id",
      headerName: "Alloc ID",
      filterable: false,
      disableColumnMenu: true,
      width: 190,
      align: "left",
    },
    {
      field: "quantity",
      headerName: "Qty",
      filterable: false,
      disableColumnMenu: true,
      width: 120,
      align: "left",
    },
    {
      field: "allocated_to",
      headerName: "Allocated To",
      filterable: false,
      disableColumnMenu: true,
      width: 150,
      align: "left",
    },
    {
      field: "stock_accepted",
      headerName: "Status",
      width: 130,
      renderCell: (params) =>
        isNaN(params.row.stock_accepted) ? (
          <Badge bg="success" style={{ width: "100%", display: "block" }}>
            Accepted
          </Badge>
        ) : (
          <Badge bg="warning" style={{ width: "100%", display: "block" }}>
            Awaiting
          </Badge>
        ),
    },
    {
      field: "date_of_allocation",
      headerName: "Allocation Date",
      filterable: false,
      disableColumnMenu: true,
      width: 160,
      align: "left",
      renderCell: (params) => dformatter(params.row.date_of_allocation),
    },
  ];

  const handleSearch = (e) => {
    console.log("Search Value...", e);

    if (e) {
      setData(
        unfilteredData.filter((item) => {
          return (
            item?.product_code?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.product_name?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.allocated_to?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.local_allocation_id
              ?.toLowerCase()
              .includes(e?.toLowerCase()) ||
            item?.quantity?.toLowerCase().includes(e?.toLowerCase())
          );
        })
      );
    } else {
      getDataFromServer();
      setData(unfilteredData);
    }
  };

  const clearSearch = () => {
    searchRef.current.value = null;
    getDataFromServer();
  };

  return (
    <div className="p-4">
      <div className="patientListSearch">
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">Filter</InputGroup.Text>
          <Form.Control
            placeholder="Product / State / Code / Company ..."
            aria-label="Search"
            aria-describedby="basic-addon1"
            name="searchPatients"
            ref={searchRef}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <InputGroup.Text>
            <i
              className="mdi mdi-delete-outline"
              onClick={clearSearch}
              style={{ cursor: "pointer" }}
            ></i>
          </InputGroup.Text>
        </InputGroup>
      </div>
      <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        displayTitle="Purchases"
        checkboxSelection
        style={{ height: "60vh" }}
      />

      {/* Modal Popup */}
      <Modal show={openModal} centered>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ViewReAllocationContentModal currentRecord={currentRecord} />
        </Modal.Body>
      </Modal>
      {/* {JSON.stringify(data)} */}
    </div>
  );
};

export default ListReAllocationHistoryChild;

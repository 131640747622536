import {React,useState,useEffect} from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import ShowPieChart from "../../../components/Chart/ShowPieChart";
import ShowBarChart from "../../../components/Chart/ShowBarChart";
import ShowLineChart from "../../../components/Chart/ShowLineChart";
const VistTab = ({ currentRecord }) => {
    const [dataGraph, setDataGraph] = useState([]);
    let graphData={};
    for(let i of currentRecord){
      graphData[i.consultation]=graphData[i.consultation] ? graphData[i.consultation]+1 :1
    }
    useEffect(() => {
        setDataGraph([
                  {name: 'CONSULTATION', value:graphData.CONSULTATION},
                  {name: 'HOSPITALIZATION', value:graphData.HOSPITALIZATION}
                ]);
    },[]);

    const [graphDisplay, setGraphDisplay] = useState('pie');
    const showGraphView=(graphType)=>{
      setGraphDisplay(graphType);
    }  
    return (
        <>
            <DropdownButton id="dropdown-basic-button" style={{marginLeft:'600px'}} title={graphDisplay.toUpperCase()+' CHART'} size="sm" variant="secondary">
                <Dropdown.Item  onClick={()=>showGraphView('pie')}>Pie Chart</Dropdown.Item>
                <Dropdown.Item  onClick={()=>showGraphView('bar')}>Bar Graph</Dropdown.Item>
                <Dropdown.Item  onClick={()=>showGraphView('line')}>Line Graph</Dropdown.Item>
            </DropdownButton>
            {(graphDisplay ==='pie') &&
                (<ShowPieChart  data={dataGraph}/>)}
            {(graphDisplay ==='bar') &&
                (<ShowBarChart  data={dataGraph}/>)}
            {(graphDisplay ==='line') &&
                (<ShowLineChart  data={dataGraph}/>)}    
        </>   
  )
};

export default VistTab;

import React, { useEffect, useState, useContext, useRef } from "react";

import { useNavigate } from "react-router-dom";
import axios from "axios";

import "antd/dist/antd.min.css";
import { Modal } from "react-bootstrap";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@mui/material/Tooltip";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { confirmAlert } from "react-confirm-alert";
//Global State import
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";
import toast from "react-hot-toast";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

//Context import
import AuthContext from "../../../components/shared/Context";

const ListStateInventoryChild = () => {
  let navigate = useNavigate();
  const { loggedInUserDetails } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  const searchRef = useRef();

  //Error Logging Service
  const { dateFormatter, errorLogApi } = useAtom(gbState);

  const getDataFromServer = () => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };

    //console.log(config);

    const bodyParameters = {
      key: "value",
    };

    let urlLink = `${process.env.REACT_APP_API_URL}/state/stock/${loggedInUserDetails.state}`;

    axios
      .get(urlLink, config)
      .then((res) => {
        const oldData = res?.data?.current_stock;
        //console.log(oldData);
        const updatedData = oldData.map((data) => {
          //   console.log(data.purchase_inventory.product_id.products);
          return {
            ...data,
            id: data?._id,
            product_name:
              data?.current_stock?.product_id?.products?.product_name,
            category:
              data?.current_stock?.product_id?.products?.category_id?.category,
            item_code: data?.current_stock?.item_code,
            opening_stock:
              data?.current_stock?.opening_stock +
              " " +
              data?.current_stock?.product_id?.products?.storage_unit,
            purchase_qty:
              data?.current_stock?.purchase_qty +
              " " +
              data?.current_stock?.product_id?.products?.storage_unit,
            sale_qty:
              data?.current_stock?.sale_qty +
              " " +
              data?.current_stock?.product_id?.products?.storage_unit,
            closing_stock:
              data?.current_stock?.closing_stock +
              " " +
              data?.current_stock?.product_id?.products?.storage_unit,
            state: data?.current_stock?.state,
            updatedAt: data?.updatedAt,
          };
        });
        //console.log(updatedData);
        setData(updatedData);
        setUnfilteredData(updatedData);
      })
      .catch((err) => {
        errorLogApi(err, "ListStateInventoryChild", urlLink);
      });
  };

  useEffect(() => {
    getDataFromServer();
    // getProducts();
  }, []);

  const useStyles = makeStyles(() => ({
    multiLineCell: {
      display: "flex",
      wordBreak: "keep-all",
      wordWrap: "break-word",
      overflowWrap: "break-word",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      lineHeight: "1.2rem",
      height: "auto",
    },
  }));

  const MultiLineCellRenderer = (params) => {
    const classes = useStyles();
    return (
      <div className={classes.multiLineCell}>
        <span id="content1" className="text-bold">
          {params.row.product_name}
        </span>
        <span id="content2">
          <small className="small-font-size">{params.row.item_code}</small>
        </span>
      </div>
    );
  };

  const columns = [
    {
      field: "product_name",
      headerName: "Product",
      width: 240,
      renderCell: MultiLineCellRenderer,
    },
    {
      field: "category",
      headerName: "Category",
      width: 150,
    },
    {
      field: "opening_stock",
      headerName: "Opening Stock",
      width: 160,
      filterable: false,
      disableColumnMenu: true,
      align: "left",
    },
    {
      field: "purchase_qty",
      headerName: "Purchase Qty",
      width: 160,
      filterable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
    },
    {
      field: "sale_qty",
      headerName: "Issue Qty",
      width: 120,
      filterable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "left",
    },
    {
      field: "closing_stock",
      headerName: "Closing Stock",
      width: 160,
      filterable: false,
      disableColumnMenu: true,
      align: "left",
    },
    {
      field: "state",
      headerName: "State",
      width: 110,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };

  //const unfilterData = data;
  const handleSearch = (e) => {
    //  console.log("Search Value...", e);
    if (e) {
      setData(
        unfilteredData.filter((item) => {
          return (
            item?.item_code?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.product_name?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.purchase_qty?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.category?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.sale_qty?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.closing_stock?.toLowerCase().includes(e?.toLowerCase())
          );
        })
      );
    } else {
      setData(unfilteredData);
      getDataFromServer();
    }
  };

  const clearSearch = () => {
    searchRef.current.value = null;
    getDataFromServer();
  };

  const inventorySettle = () => {
    // alert("inventory Settled");

    confirmAlert({
      title: "",
      message: "Are you sure to 'RESET' Inventory ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmSettleInventory(),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });

    const confirmSettleInventory = () => {
      let urlLink = `${process.env.REACT_APP_API_URL}/state/stocksettle/${loggedInUserDetails.state}`;

      const config = {
        method: "post",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: { invdata: true },
      };

      axios(config)
        .then((res) => {
          // console.log("response", res, "data", res.data[0]);
          getDataFromServer();
        })
        .catch((err) => {
          if (err.response.data.error) toast.error("Already settled");
          errorLogApi(err, "ListStateInventoryChild", urlLink);
        });
    };
  };

  const resetInventory = () => {
    let inputCode = "";
    confirmAlert({
      title: "",
      message: "Are you sure to 'RESET' Inventory ?",
      childrenElement: () => (
        <div>
          {/* Use the key here */}
          <input
            type="text"
            placeholder="Type Code..."
            className="mt-2"
            onChange={(e) => (inputCode = e.target.value)} // Direct assignment
          />
        </div>
      ),
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmResetInventory(inputCode),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });

    const confirmResetInventory = (inputCode) => {
      if (!inputCode) return toast.error("Please enter a valid code!");

      let urlLink = `${process.env.REACT_APP_API_URL}/state/stocksettle/reset`;

      const config = {
        method: "delete",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: { invdata: true, secret_code: inputCode },
      };

      axios(config)
        .then((res) => {
          // console.log("response", res, "data", res.data[0]);
          toast.success("Sucessfully Reset Inventory Done");
          setData([]);
        })
        .catch((err) => {
          if (err.response.data.error) toast.error(err.response.data.error);
          errorLogApi(err, "ListStateInventoryChild", urlLink);
        });
    };
  };

  const generatePdfContent = (data) => {
    // Make sure data is passed as an argument
    const currentDate = new Date().toLocaleDateString();

    const tableBody = [
      [
        "Product",
        "Category",
        "Opening Stock",
        "Allotted",
        "Issued",
        "Closing Stock",
      ],
      ...data.map((d) => [
        // Assuming 'data' is an array of objects
        `${d.product_name} (${d.item_code})`,
        d.category,
        d.opening_stock,
        d.purchase_qty,
        d.sale_qty,
        d.closing_stock,
      ]),
    ];

    const content = [
      {
        text: `State Inventory - ${currentDate}`, // Included currentDate
        style: "title",
      },
      {
        table: {
          headerRows: 1,
          //    widths: ["auto", "auto", "auto", "auto", "auto", "auto"], // 6 widths for 6 columns
          widths: ["*", "*", "*", "*", "*", "*"], // Distribute available width evenly across columns
          body: tableBody,
        },
        style: "table",
      },
    ];

    const docDefinition = {
      content,
      styles: {
        title: {
          fontSize: 16,
          bold: true,
          marginBottom: 10,
        },
        table: {
          margin: [0, 10, 0, 10],
        },
      },
      pageOrientation: "landscape", // Set the page orientation to landscape
    };

    return docDefinition;
  };

  const downloadPdfDocument = () => {
    const docDefinition = generatePdfContent(data);
    const pdfDoc = pdfMake.createPdf(docDefinition);
    const timestamp = new Date().toISOString().replace(/[\-\:\.]/g, "_");
    const filename = `patient_data_${timestamp}.pdf`;
    pdfDoc.download(filename);
  };

  return (
    <div>
      <span className="card-title">
        <h3>Total State Stocks (Current Status)</h3>
      </span>
      <div className="patientUpdateButton">
        <button className="btn btn-danger" onClick={resetInventory}>
          Reset Inventory
        </button>
        &nbsp;&nbsp;
        <button className="btn btn-primary" onClick={inventorySettle}>
          Settle Inventory
        </button>
        &nbsp;&nbsp;
        <button className="btn btn-warning" onClick={downloadPdfDocument}>
          Download PDF
        </button>
      </div>
      <div className="patientListSearch">
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">Filter</InputGroup.Text>
          <Form.Control
            placeholder="Product / State / Code ..."
            aria-label="Search"
            aria-describedby="basic-addon1"
            name="searchPatients"
            ref={searchRef}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <InputGroup.Text>
            <i
              className="mdi mdi-delete-outline"
              onClick={clearSearch}
              style={{ cursor: "pointer" }}
            ></i>
          </InputGroup.Text>
        </InputGroup>
      </div>
      <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        displayTitle="Current Stocks"
        checkboxSelection
        style={{ height: "60vh" }}
      />

      {/* Modal Popup */}
      <Modal show={openModal} centered>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Stocks Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent currentRecord={currentRecord} />
        </Modal.Body>
      </Modal>

      {/* {JSON.stringify(data)} */}
      {/* {JSON.stringify(currentRecord)} */}
    </div>
  );
};

const ModalContent = ({ currentRecord }) => {
  const dateFormatter = (date) => {
    const date_conv = new Date(date);
    const fullyear = date_conv.getFullYear();
    const month = date_conv.getMonth() + 1;
    const day = date_conv.getDate();
    return `${day}/${month}/${fullyear}`;
  };

  return (
    <div className="container-fluid">
      <table class="table table-striped">
        <tbody>
          <tr>
            <th scope="row">Product Name</th>
            <td>{currentRecord.product_name}</td>
          </tr>
          <tr>
            <th scope="row">Measuring Units</th>
            <td>{currentRecord.measuring_unit}</td>
          </tr>
          <tr>
            <th scope="row">Quantity</th>
            <td>{currentRecord.quantity}</td>
          </tr>
          <tr>
            <th scope="row">Expiry</th>
            <td>{dateFormatter(currentRecord.product_expiry)}</td>
          </tr>
          <tr>
            <th scope="row">Company Name</th>
            <td>{currentRecord.company_name}</td>
          </tr>
          <tr>
            <th scope="row">State</th>
            <td>{currentRecord.state}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ListStateInventoryChild;

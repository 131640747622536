import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { DataGrid } from "@material-ui/data-grid";
import Tooltip from "@mui/material/Tooltip";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

//Context import
import AuthContext from "../../../components/shared/Context";
import ResponsiveTable from "./ResponsiveTable";

const PreRegistrationsListChild = () => {
  let navigate = useNavigate();
  const [{ errorLogApi, dateFormatter }] = useAtom(gbState);
  const { loggedInUserDetails, setPatientDiagnosis, setFieldLabelFromArray } =
    useContext(AuthContext);
  const [data, setData] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [unfilteredData, setUnFilteredData] = useState(data);
  const [user, setUser] = useState(jwt_decode(localStorage.getItem("token")));

  const getDataFromServer = () => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    const bodyParameters = {
      key: "value",
    };
    let urlLink = `${process.env.REACT_APP_API_URL}/pre-register/${user?.tc_id}`;
    axios
      .get(urlLink, config)
      .then((res) => {
        //const oldData = res.data.appointments;
        const patients_data = res.data.patients;
        const patients = patients_data.map((data) => {
          return {
            id: data?._id,
            ...data?.contact,
            ...data,
          };
        });
        setData(patients);
        setUnFilteredData(patients);
      })
      .catch((err) => {
        errorLogApi(err, "PreRegistrationList", urlLink);
      });
  };

  useEffect(() => {
    let user = jwt_decode(localStorage.getItem("token"));
    const getDataFromServer = () => {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };
      const bodyParameters = {
        key: "value",
      };
      let urlLink = `${process.env.REACT_APP_API_URL}/pre-register/${user?.tc_id}`;
      axios
        .get(urlLink, config)
        .then((res) => {
          //const oldData = res.data.appointments;
          const patients_data = res.data.patients;
          const patients = patients_data.map((data) => {
            return {
              id: data?._id,
              ...data?.contact,
              ...data,
            };
          });
          setData(patients);
        })
        .catch((err) => {
          console.log(err);
          errorLogApi(err, "PreRegistrationList", urlLink);
        });
    };
    getDataFromServer();
  }, []);

  const handleDelete = (id) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this patient ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (id) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/pre-register/delete/${id}`,
        config
      )
      .then((response) => {
        getDataFromServer();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const badgeColor = {
    pending: "warning",
    completed: "success",
    cancelled: "danger",
    reschedule: "primary",
  };

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      width: 100,
      sortable: false,
      hideable: false,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            <Tooltip title="View Patient">
              <a onClick={() => viewRecord(params.row, "AP")}>
                <i
                  className="mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </a>
            </Tooltip>
            <Tooltip title="Delete Patient">
              <div onClick={() => handleDelete(params.row.id)}>
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{
                    fontSize: "15px",
                    color: "red",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit Patient">
              <div
                onClick={() =>
                  navigate("/pre/register/update", { state: params.row })
                }
              >
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{
                    fontSize: "15px",
                    color: "grey",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Register Patient">
              <div
                onClick={() =>
                  navigate("/add-diagnosis", { state: params.row })
                }
              >
                <i
                  className="mdi mdi-plus-box"
                  style={{
                    fontSize: "15px",
                    color: "blue",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 110,
      renderCell: (params) => params?.row?.createdAt?.slice(0, 10),
    },
    {
      field: "temp_patient_id",
      headerName: "TEMP ID",
      width: 130,
    },

    {
      field: "patient_name",
      headerName: "Patient",
      width: 160,
    },

    {
      field: "primary_mobile",
      headerName: "Mobile",
      width: 150,
      sortable: false,
      hideable: false,
      align: "center",
    },
    {
      field: "whatsapp_number",
      headerName: "Whatsapp",
      width: 150,
      sortable: false,
      hideable: false,
      align: "center",
    },
  ];

  const handleSearchChange = (e) => {
    const value = e.target.value;
    console.log(value);
    setSearchTerm(value);
    setUnFilteredData(data);
    if (value) {
      setData(
        unfilteredData.filter((item) => {
          return (
            item.patient_name?.toLowerCase().includes(value.toLowerCase()) ||
            item.temp_patient_id?.toLowerCase().includes(value.toLowerCase()) ||
            item.contact?.email?.toLowerCase().includes(value.toLowerCase()) ||
            item.contact?.primary_mobile
              ?.toLowerCase()
              .includes(value.toLowerCase())
          );
        })
      );
    } else {
      getDataFromServer();
      setData(unfilteredData);
    }
  };

  return (
    <div>
      <h1 className="allPatients">Pre Registrations</h1>
      <div className="patientUpdateButton">
        <button
          className="btn btn-primary"
          onClick={() => navigate("/pre/register/add")}
        >
          + Pre Register Patient
        </button>
      </div>
      <div className="patientListSearch">
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">Search Patients</InputGroup.Text>
          <Form.Control
            placeholder="name, temp ID, primary mobile..."
            aria-label="Search"
            aria-describedby="basic-addon1"
            name="searchPatients"
            onChange={handleSearchChange}
          />
        </InputGroup>
      </div>

      <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        displayTitle="Appointments"
        checkboxSelection
        style={{ height: "40vh" }}
      />
      {/* {JSON.stringify(data)} */}
      {/* Modal Popup */}
      <Modal show={openModal} size="md" centered>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent currentRecord={currentRecord} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

const ModalContent = ({ currentRecord }) => {
  return (
    <div>
      <ResponsiveTable data={currentRecord} />
    </div>
  );
};

export default PreRegistrationsListChild;

import React from "react";
// import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import { Button, Alert } from "react-bootstrap";
//import "./ProductsList.css";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";

const ChronicComplicationTab = ({ emrRecordId, emrRecord }) => {
  const [toggleBP, setToggleBP] = useState(true);

  const [formData, setFormData] = useState({
    musculoskeletal_complication: null,
    musculoskeletal_complication_others: null,
    chronic_haemophilic_arthropathy: null,
    contractures: null,
    pseudotumor_formation: null,
    fractures: null,
    fractures_others: null,
    clinical_suspicion_for_presence_of_inhibitors: null,
    transfusion_related_complications: null,
    transfusion_related_complication_type: null,
    transfusion_complications_others: null,
    documental_history_of_inhibitors: null,
    documental_history_of_inhibitors_bu_quantity: null,
    treatement_taken_for_inhibitors: null,
  });

  // useEffect(() => {
  //   let chronicRec = localStorage.getItem(`${emrRecordId}-chronic`);
  //   setFormData({ ...formData, ...JSON.parse(chronicRec) });
  //   console.log(JSON.parse(chronicRec));
  // }, []);

  useEffect(() => {
    if (emrRecord) {
      localStorage.setItem(`${emrRecordId}-chronic`, JSON.stringify(emrRecord));
    }
    let chronicRec = localStorage.getItem(`${emrRecordId}-chronic`);
    setFormData({ ...formData, ...JSON.parse(chronicRec) });
  }, [emrRecord]);

  const transRelatedCompOptions = [
    { value: 1, label: "Allo-immunization" },
    { value: 2, label: "Hep B Infection" },
    { value: 3, label: "Hep C Infection" },
    { value: 4, label: "HIV Infection" },
    { value: 5, label: "Any Other" },
  ];

  const ttForInhibitorsOptions = [
    { value: 2, label: "Emicizumab (Roche)" },
    { value: 1, label: "rFVII " },
  ];

  const updateEmr = (e) => {
    e.preventDefault();
    //changeTab(1);

    //  alert("Click to submit");

    let userToken = localStorage.getItem("token");
    const config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/emr/update/${emrRecordId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, chronic_complications: true },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        localStorage.setItem(
          `${emrRecordId}-chronic`,
          JSON.stringify(formData)
        );
        toast.success("Chronic Complications saved Successfully.");
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error.response.data));
      });
  };

  const handleChange = ({ currentTarget: input }) => {
    //alert([input.name]);
    setFormData({
      ...formData,
      [input.name]: input.checked,
    });
  };
  const handleChangeValue = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const dropDownChange = (selected, dropdown) => {
    console.log("this is selected", JSON.stringify(dropdown.name));
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  return (
    <form className="d-flex flex-sm-wrap" onSubmit={(e) => updateEmr(e)}>
      {/* {JSON.stringify(formData)} */}
      <div className="table-responsive w-100" style={{ marginRight: "40px" }}>
        <table className="table table-striped">
          <tbody>
            <tr>
              <td>Musculoskeletal Complication</td>
              <td>
                <input
                  type="checkbox"
                  name="musculoskeletal_complication"
                  onChange={handleChange}
                  checked={formData.musculoskeletal_complication}
                  disabled
                />
              </td>
            </tr>
            <tr>
              <td>Chronic Haemophilic Arthropathy</td>
              <td>
                <input
                  type="checkbox"
                  name="chronic_haemophilic_arthropathy"
                  onChange={handleChange}
                  checked={formData.chronic_haemophilic_arthropathy}
                  disabled
                />
              </td>
            </tr>
            <tr>
              <td>Contractures</td>
              <td>
                <input
                  type="checkbox"
                  name="contractures"
                  onChange={handleChange}
                  checked={formData.contractures}
                  disabled
                />
              </td>
            </tr>
            <tr>
              <td>Pseudotumor Formation</td>
              <td>
                <input
                  type="checkbox"
                  name="pseudotumor_formation"
                  onChange={handleChange}
                  checked={formData.pseudotumor_formation}
                  disabled
                />
              </td>
            </tr>
            <tr>
              <td>Fractures</td>
              <td>
                <input
                  type="checkbox"
                  name="fractures"
                  onChange={handleChange}
                  checked={formData.fractures}
                  disabled
                />
              </td>
            </tr>
            <tr>
              <td>Fractures Others</td>
              <td>
                <input
                  type="text"
                  name="fractures_others"
                  onChange={handleChangeValue}
                  checked={formData.fractures_others}
                  disabled
                />
              </td>
            </tr>
            <tr>
              <td>Clinical Suspicion for Presence of Inhibitors</td>
              <td>
                <input
                  type="checkbox"
                  name="clinical_suspicion_for_presence_of_inhibitors"
                  onChange={handleChange}
                  checked={
                    formData.clinical_suspicion_for_presence_of_inhibitors
                  }
                  disabled
                />
              </td>
            </tr>
            <tr>
              <td>Transfusion Related Complications</td>
              <td>
                <input
                  type="checkbox"
                  name="transfusion_related_complications"
                  onChange={handleChange}
                  checked={formData.transfusion_related_complications}
                  disabled
                />
              </td>
            </tr>
            <tr>
              <td>Transfusion Related Complications</td>
              <td>
                <div className="newPatientItem">
                  <Select
                    name="transfusion_related_complication_type"
                    onChange={
                      //dropDownChange
                      (selected, dropdown) => {
                        //console.log(selected);
                        setFormData({
                          ...formData,
                          transfusion_related_complication_type:
                            JSON.stringify(selected),
                        });
                      }
                    }
                    value={
                      formData.transfusion_related_complication_type &&
                      JSON.parse(formData.transfusion_related_complication_type)
                    }
                    className="newPatientSelect"
                    options={transRelatedCompOptions}
                    isDisabled={true}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>Transfusion Complications</td>
              <td>
                <input
                  type="text"
                  name="transfusion_complications_others"
                  onChange={handleChange}
                  value={formData.transfusion_complications_others}
                  disabled
                />
              </td>
            </tr>
            <tr>
              <td>Documental History of Inhibitors</td>
              <td>
                <input
                  type="checkbox"
                  name="documental_history_of_inhibitors"
                  onChange={handleChange}
                  checked={formData.documental_history_of_inhibitors}
                  disabled
                />
              </td>
            </tr>
            <tr>
              <td>Titer Level of Inhibitors</td>
              <td>
                <input
                  type="text"
                  name="titer_level_of_inhibitors"
                  onChange={handleChangeValue}
                  placeholder=""
                  value={formData.documental_history_of_inhibitors_bu_quantity}
                  disabled
                />
              </td>
            </tr>
            <tr>
              <td>Treatment Taken for Inhibitors</td>
              <td>
                <div className="newPatientItem">
                  <Select
                    name="treatement_taken_for_inhibitors"
                    onChange={
                      //dropDownChange
                      (selected, dropdown) => {
                        //console.log(selected);
                        setFormData({
                          ...formData,
                          treatement_taken_for_inhibitors:
                            JSON.stringify(selected),
                        });
                      }
                    }
                    value={
                      formData.treatement_taken_for_inhibitors &&
                      JSON.parse(formData.treatement_taken_for_inhibitors)
                    }
                    className="newPatientSelect"
                    options={ttForInhibitorsOptions}
                    isDisabled={true}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </form>
  );
};

export default ChronicComplicationTab;

import React, { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { permissionOptions } from "../../../components/Data/Data";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

//Context import
import AuthContext from "../../../components/shared/Context";

const CreateRoleChild = () => {
  const { checkLoggedInStatus } = useContext(AuthContext);
  let navigate = useNavigate();

  //Error Logging Service & Santize Input
  const [{ errorLogApi, sanitizeInput }] = useAtom(gbState);

  //To check if the user is logged in
  useEffect(() => {
    checkLoggedInStatus();
  }, []);

  const [formData, setFormData] = useState({
    role_name: null,
    access_level: null,
  });

  const handleRoleRegistration = (e) => {
    e.preventDefault();

    if (!formData.access_level) {
      toast.error("Select Access Level");
      return;
    }

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/roles/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("Roles Created Successfully.");
        navigate("/roles");
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "CreateRoleChild", "/roles/add");
        // console.log(error);
      });
  };

  const handleChange = ({ currentTarget: input }) => {
    if (sanitizeInput(input.value, input.name)) {
      setFormData({
        ...formData,
        [input.name]: input.value,
      });
    }
  };

  const dropDownChange = (selected, dropdown) => {
    //console.log("this is selected", JSON.stringify(dropdown.name));
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  return (
    <div className="patient-list1">
      <div>
        <h2>Add Roles</h2>
      </div>
      <form onSubmit={(e) => handleRoleRegistration(e)} autoComplete="off">
        <div className="patientUpdateButton">
          <Button variant="primary" size="medium" type="submit">
            Create Role
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="secondary"
            size="medium"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
        <div className="newFormUsers">
          <div className="newPatientItem w-25">
            <label htmlFor="role_name">Role Name</label>
            <input
              type="text"
              name="role_name"
              value={formData.role_name}
              onClick={handleChange}
              onChange={handleChange}
              required
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="access_level">Access Level</label>
            <Select
              name="access_level"
              onChange={dropDownChange}
              className="newPatientSelect"
              options={permissionOptions}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateRoleChild;

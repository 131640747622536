import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import Badge from "react-bootstrap/Badge";
import { Button, Card } from "react-bootstrap";
import "antd/dist/antd.min.css";
import { Modal } from "react-bootstrap";
import { DataGrid } from "@material-ui/data-grid";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import { makeStyles } from "@material-ui/core/styles";

import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

//Context import
import AuthContext from "../../components/shared/Context";

const DiagnosisChild = () => {
  let navigate = useNavigate();
  const { loggedInUserDetails } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  const searchRef = useRef();

  //Error Logging Service
  const [{ errorLogApi, dateFormatter, _checkIfLatestRecord }] =
    useAtom(gbState);

  const getDataFromServer = () => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };

    let urlLink = `${process.env.REACT_APP_API_URL}/diagnosis/`;

    axios
      .get(urlLink, config)
      .then((res) => {
        const olDdiagnosesData = res.data.diagnoses;
        const diagnosesData = olDdiagnosesData.map((d) => ({
          ...d,
          cat_name: d?.category_id?.category,
        }));
        setData(diagnosesData);
        setUnfilteredData(diagnosesData);
      })
      .catch((err) => console.log("error", err));
  };

  useEffect(() => {
    const getDataFromServer = () => {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };

      let urlLink = `${process.env.REACT_APP_API_URL}/diagnosis/`;

      axios
        .get(urlLink, config)
        .then((res) => {
          const olDdiagnosesData = res.data.diagnoses;
          //console.log(olDdiagnosesData);
          const diagnosesData = olDdiagnosesData.map((d) => ({
            ...d,
            cat_name: d?.category_id?.category,
          }));
          setData(diagnosesData);
          setUnfilteredData(diagnosesData);
        })
        .catch((err) => console.log("error", err));
    };

    getDataFromServer();
  }, []);

  const handleDelete = (id) => {
    confirmAlert({
      title: "",
      message:
        "Are you sure to delete this record ? Deleting this record can negatively impact your existing clinical records.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (id) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(`${process.env.REACT_APP_API_URL}/diagnosis/delete/${id}`, config)
      .then((response) => {
        // alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            <Tooltip title="View">
              <a onClick={() => viewRecord(params.row)}>
                <i
                  className="mdi mdi-eye-circle-outline"
                  style={{
                    fontSize: "15px",
                    color: "black",
                    cursor: "pointer",
                  }}
                ></i>
              </a>
            </Tooltip>
            <Tooltip title="Update">
              <a
                onClick={() =>
                  navigate("/diagnosis/update", { state: params.row })
                }
              >
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{
                    fontSize: "15px",
                    color: "grey",
                    cursor: "pointer",
                  }}
                />
              </a>
            </Tooltip>
            <Tooltip title="Delete">
              <div onClick={() => handleDelete(params.row._id)}>
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "id",
      headerName: "ID",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 80,
    },
    {
      field: "cat_name",
      headerName: "Category",
      width: 150,
    },
    {
      field: "label",
      headerName: "Diagnosis",
      width: 220,
    },
    {
      field: "state",
      headerName: "State",
      width: 150,
    },
  ];

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };

  const handleAddDiagnosisCategory = () => {
    navigate("/diagnosis-category");
  };

  const handleSearch = (e) => {
    // console.log("Search Value...", e);

    if (e) {
      setData(
        unfilteredData.filter((item) => {
          return (
            item?.cat_name?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.label?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.id?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.state?.toLowerCase().includes(e?.toLowerCase())
          );
        })
      );
    } else {
      setData(unfilteredData);
      getDataFromServer();
    }
  };

  const clearSearch = () => {
    searchRef.current.value = null;
    getDataFromServer();
  };

  return (
    <div>
      <span className="card-title">
        <h3>Diagnosis</h3>
      </span>
      <div className="patientUpdateButton">
        <Button
          variant="success"
          size="medium"
          onClick={() => getDataFromServer()}
        >
          <i className="mdi mdi-refresh"></i>
        </Button>{" "}
        &nbsp;&nbsp;
        <Button
          variant="primary"
          size="medium"
          onClick={() => navigate("/diagnosis/add")}
        >
          Add Diagnosis
        </Button>
        &nbsp;&nbsp;
        <Button
          variant="primary"
          size="medium"
          onClick={handleAddDiagnosisCategory}
        >
          Category List
        </Button>
      </div>
      <div className="patientListSearch">
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">Filter</InputGroup.Text>
          <Form.Control
            placeholder="ID / Diagnosis / Category"
            aria-label="Search"
            aria-describedby="basic-addon1"
            name="searchPatients"
            ref={searchRef}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <InputGroup.Text>
            <i
              className="mdi mdi-delete-outline"
              onClick={clearSearch}
              style={{ cursor: "pointer" }}
            ></i>
          </InputGroup.Text>
        </InputGroup>
      </div>
      <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        displayTitle="Diagnosis"
        checkboxSelection
        style={{ height: "60vh" }}
      />
      {/* Modal Popup */}
      <Modal show={openModal} centered>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Diagnosis Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent data={currentRecord} />
        </Modal.Body>
      </Modal>
      {/* {JSON.stringify(data)} */}
    </div>
  );
};

const ModalContent = ({ data }) => {
  return (
    <div className="container-fluid legend">
      <Card>
        {/* <Card.Header>
          <i className="fas fa-align-justify" />
          <strong>User Details</strong>
        </Card.Header> */}
        <Card.Body>
          <CardTableRow rowName="State" rowValue={data?.state} />
          <CardTableRow
            rowName="Category"
            rowValue={data?.category_id?.category}
          />
          <CardTableRow rowName="ID" rowValue={data?.id} />
          <CardTableRow rowName="Diagnosis Label" rowValue={data?.label} />
        </Card.Body>
      </Card>
    </div>
  );
};

const CardTableRow = ({ rowName, rowValue }) => {
  return (
    <div className="row">
      <div className="col-md-6 col-xs-6 divTableCell">
        <b> {rowName}: </b>
      </div>
      <div className="col-md-6 col-xs-6 divTableCell">{rowValue}</div>
    </div>
  );
};

export default DiagnosisChild;

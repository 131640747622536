import React from "react";

import MainContentCard from "../../Home/MainContentCard";
import CreateRequestChild from "./CreateRequestChild";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

const CreateRequest = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="CreateRequest" route="/purchase-request-add">
        <CreateRequestChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default CreateRequest;

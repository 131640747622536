import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { forwardRef, useRef, useImperativeHandle } from "react";

const AlertDialog = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [subTitle, setSubTitle] = React.useState("");
  const [desc, setDesc] = React.useState("");

  useImperativeHandle(ref, () => ({
    handleClickOpenInfo(title, des) {
      setSubTitle(title);
      setDesc(des);
      setOpen(true);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{subTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {desc}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
});

export default AlertDialog;

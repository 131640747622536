import React from "react";
import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import SettlementHistoryChild from "./SettlementHistoryChild";

export const SettlementHistory = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes
        page="ListSettlementHistory"
        route="/settlement-history"
      >
        <SettlementHistoryChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

import React, { useEffect, useState, useContext, useRef } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import axios from "axios";
import toast from "react-hot-toast";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

//Context import
import AuthContext from "../../../components/shared/Context";

const SuppliersListChild = () => {
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const { loggedInUserDetails } = useContext(AuthContext);

  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  const searchRef = useRef();
  const getDataFromServer = () => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };

    //console.log(config);

    const bodyParameters = {
      key: "value",
    };

    let urlLink = `${process.env.REACT_APP_API_URL}/suppliers/${loggedInUserDetails.state}`;

    if (loggedInUserDetails.role === "FULL_ACCESS") {
      urlLink = `${process.env.REACT_APP_API_URL}/suppliers/`;
    }

    axios
      .get(urlLink, config)
      .then((res) => {
        const oldData = res.data.suppliers;
        //console.log(oldData);
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data._id,
            supplier_name: data.supplier_name,
            supplier_email: data.email,
            supplier_phone: data.phone,
            supplier_company: data.company_name,
            products: data.products.join(", "),
            comments: data.comments,
            address: data.address,
            state: data.state,
          };
        });
        //console.log(updatedData);
        setData(updatedData);
        setUnfilteredData(updatedData);
      })
      .catch((err) => {
        console.log("error", err?.response?.data?.message);
        if (err?.response?.data?.message.toLowerCase().includes("invalid")) {
          navigate("/login", { replace: true });
        }
      });
  };

  useEffect(() => {
    getDataFromServer();
  }, []);

  const handleRefresh = () => {
    getDataFromServer();
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this Product ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (id) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(`${process.env.REACT_APP_API_URL}/suppliers/delete/${id}`, config)
      .then((response) => {
        getDataFromServer();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            <Tooltip title="View Product">
              <a onClick={() => viewRecord(params.row)}>
                <i
                  className="mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </a>
            </Tooltip>
            <Tooltip title="Edit Product">
              <div
                onClick={() =>
                  navigate("/updatesupplier", { state: params.row })
                }
              >
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete Product">
              <div onClick={() => handleDelete(params.row._id)}>
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "supplier_name",
      headerName: "Name",
      width: 160,
      renderCell: (params) => {
        return (
          <>
            <img
              src={"assets/images/icare/male.jpg"}
              alt="table-user"
              className="me-2 rounded-circle"
              width={25}
            />{" "}
            {params.row.supplier_name}
          </>
        );
      },
    },
    {
      field: "supplier_email",
      headerName: "Email",
      width: 150,
    },
    {
      field: "supplier_phone",
      headerName: "Phone",
      width: 120,
    },
    {
      field: "supplier_company",
      headerName: "Company",
      width: 150,
    },
    {
      field: "products",
      headerName: "Products",
      width: 200,
    },
    {
      field: "state",
      headerName: "State",
      width: 200,
    },
  ];

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };

  const unfilterData = data;
  const handleSearch = (e) => {
    console.log("Search Value...", e);

    if (e) {
      setData(
        unfilteredData.filter((item) => {
          return (
            item?.supplier_name?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.supplier_email?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.supplier_company?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.state?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.products?.toLowerCase().includes(e?.toLowerCase())
          );
        })
      );
    } else {
      getDataFromServer();
      //setData(unfilteredData)
    }
  };

  const clearSearch = () => {
    searchRef.current.value = null;
    getDataFromServer();
  };

  return (
    <>
      <div className="container-fluid">
        <h3>Suppliers</h3>
      </div>
      <div className="patientUpdateButton">
        <button
          className="btn btn-primary"
          onClick={() => navigate("/supplier-add")}
        >
          Add Supplier
        </button>
      </div>
      <div className="patientListSearch">
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">Filter</InputGroup.Text>
          <Form.Control
            placeholder="Supplier / Company ..."
            aria-label="Search"
            aria-describedby="basic-addon1"
            name="searchPatients"
            ref={searchRef}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <InputGroup.Text>
            <i
              className="mdi mdi-delete-outline"
              onClick={clearSearch}
              style={{ cursor: "pointer" }}
            ></i>
          </InputGroup.Text>
        </InputGroup>
      </div>
      <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        displayTitle="Suppliers"
        checkboxSelection
        style={{ height: "60vh" }}
      />

      {/* Modal Popup */}
      <Modal show={openModal} centered>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Product Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent currentRecord={currentRecord} />
        </Modal.Body>
      </Modal>
    </>
  );
};

const ModalContent = ({ currentRecord }) => {
  return (
    <div className="container-fluid">
      <table class="table table-striped">
        <tbody>
          <tr>
            <th scope="row">Supplier Name</th>
            <td>{currentRecord.supplier_name}</td>
          </tr>
          <tr>
            <th scope="row">Email</th>
            <td>{currentRecord.supplier_email}</td>
          </tr>
          <tr>
            <th scope="row">Company</th>
            <td>{currentRecord.supplier_company}</td>
          </tr>
          <tr>
            <th scope="row">Website</th>
            <td>{currentRecord.website}</td>
          </tr>
          <tr>
            <th scope="row">Contact Number</th>
            <td>{currentRecord.phone}</td>
          </tr>
          <tr>
            <th scope="row">Products</th>
            <td>{currentRecord.products}</td>
          </tr>
          <tr>
            <th scope="row">State</th>
            <td>{currentRecord.state}</td>
          </tr>
          <tr>
            <th scope="row" colSpan={2}>
              Comments
            </th>
          </tr>
          <tr>
            <td colSpan={2}>{currentRecord.comments}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SuppliersListChild;

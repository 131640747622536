import React from "react";
import MainContentCard from "../../Home/MainContentCard";
import "./UpdateUserProfile.css";

import UpdateUserProfileChild from "./UpdateUserProfileChild";

const UpdateUserProfile = () => {
  return (
    <MainContentCard>
      <UpdateUserProfileChild />
    </MainContentCard>
  );
};

export default UpdateUserProfile;

import React from "react";
import ShowPieChart from "../../../../components/Chart/ShowPieChart";

const JointsWiseReportChild = () => {
  return (
    <div className="patient-update w-50 h-75">
      <ShowPieChart />
    </div>
  );
};

export default JointsWiseReportChild;

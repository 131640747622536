import React, { useContext, useRef } from "react";
import toast from "react-hot-toast";

import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import axios from "axios";
import Badge from "react-bootstrap/Badge";

import { useNavigate } from "react-router-dom";
import Select from "react-select";
//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../../../components/shared/GlobalState";

//Context import
import AuthContext from "../../../../components/shared/Context";
import PatientInfoBlock from "../../../../components/PatientProfile/PatientInfoBlock";
import VisitInfoBlock from "../../../Visits/View/VisitInfoBlock";

const PVTUSERROLE = "PVT_USER_ACCESS";

const ViewDoseForPatientChild = ({ visit, patientRecord, doseRecord }) => {
  let navigate = useNavigate();
  const { loggedInUserDetails } = useContext(AuthContext);
  const patientDiagnosis = patientRecord?.diagnoses?.diagnosis_name;
  // const patientDiagCategory = patientRecord?.diagnoses?.category_id?.category;
  const [productList, setProductList] = useState();
  const [product, setProduct] = useState();
  const [availableQty, setAvailbleQty] = useState();
  const givenDoseRef = useRef();
  //Error Logging Service
  const [{ errorLogApi, expiryFormatter, dateFormatter }] = useAtom(gbState);
  const [viewSale, setViewSale] = useState(doseRecord);
  const [formData, setFormData] = useState({
    // date_time_of_infusion: null,
    product_name: null,
    product_expiry: null,
    product_code: null,
    product_batch_id: null,
    available_qty: null,
    tc_sale_id: null,
    tc_purchase_id: null,
    product_id: null,
    patient_id: patientRecord?.id,
    visit_id: visit.id,
    drug_infusion_date: null,
    quantity: null,
    dose_quantity: null,
    measuring_unit: null,
    max_available_qty: null,
    dose_given: null,
    patient_id: patientRecord?.id,
    given_by_nurse_or_doctor: null,
    allergic_reactions: null,
    systolic_pressure: null,
    diastolic_pressure: null,
    heart_rate: null,
    temperature: null,
    comments: null,
    state: loggedInUserDetails?.state,
  });

  useEffect(() => {
    const tc_purchase_id =
      viewSale?.s_register?.tc_purchase_id?.p_register?.purchase_id;
    const getDataFromServer = () => {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };
      const bodyParameters = {
        key: "value",
      };
      let urlLink = `${process.env.REACT_APP_API_URL}/tcstocksale/expiry/${tc_purchase_id}`;
      axios
        .get(urlLink, config)
        .then((res) => {
          //console.log(res.data);
          const product_details = res?.data?.product;
          //   console.log("Selected Product: ", product_details);
          setProduct(product_details);
        })
        .catch((err) => {
          errorLogApi(err, "ViewDoseForPatientChild", urlLink);
        });
    };
    if (tc_purchase_id?.length) {
      getDataFromServer();
    }
  }, [viewSale]);

  useEffect(() => {
    setAvailbleQty(
      formData?.quantity
        ? parseInt(formData?.max_available_qty) - parseInt(formData?.quantity)
        : formData?.max_available_qty
    );
  }, [formData.quantity]);

  useEffect(() => {
    const getDataFromServer = () => {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };
      const bodyParameters = {
        key: "value",
      };

      let urlLink = `${process.env.REACT_APP_API_URL}/tcpwstock/`;

      axios
        .get(urlLink, config)
        .then((res) => {
          const oldData = res.data.product_stock;
          // console.log(oldData);
          const updatedData = oldData.map((data) => {
            //   console.log(data.purchase_inventory.product_id.products);
            return {
              //   ...data,
              //   id: data._id,
              value: data?._id,
              batch_id:
                data?.product_stock?.state_purchase_id?.p_register?.batch_id,
              product_name:
                data?.product_stock?.product_id?.products?.product_name,
              product_id: data?.product_stock?.product_id?._id,
              product_code: data?.product_stock?.item_code,
              measuring_unit:
                data?.product_stock?.product_id?.products?.measuring_unit,
              company_name:
                data?.product_stock?.product_id?.products?.company_name,
              product_expiry: data?.product_stock?.expiry_date,
              storage_unit:
                data?.product_stock?.product_id?.products?.storage_unit,
              available_qty: data?.product_stock?.quantity,
              max_available_qty: data?.product_stock?.quantity,
              tc_purchase_id: data?.product_stock?.tc_purchase_id,
              state: data?.product_stock?.state,
              label: (
                <ProductName
                  prod_name={
                    data?.product_stock?.product_id?.products?.product_name
                  }
                  qty={data?.product_stock?.quantity}
                  pexpiry={data?.product_stock?.expiry_date}
                />
              ),
              updatedAt: data?.updatedAt,
            };
          });
          setProductList(updatedData);
        })
        .catch((err) => console.log("error", err));
    };
    getDataFromServer();
  }, []);

  // If the loggedin user is a private TC
  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");
      let product_url = `${process.env.REACT_APP_API_URL}/product/${loggedInUserDetails.state}`;
      const config = {
        method: "GET",
        url: product_url,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data.product));
          const oldData = response?.data?.product;
          const updatedData = oldData.map((data) => {
            return {
              value: data?._id,
              product_id: data?._id,
              category_id: data?.products?.category_id?._id,
              category: data?.products?.category_id?.category,
              product_code: data?.products?.item_code,
              label: (
                <ProductName
                  prod_name={data?.products?.product_name}
                  qty={null}
                  pexpiry={null}
                  loggedInUserDetails={loggedInUserDetails}
                />
              ),
              measuring_unit: data?.products?.measuring_unit,
              storage_unit: data?.products?.storage_unit,
              minimum_alert_qty: data?.products?.minimum_alert_qty,
              company_name: data?.products?.company_name,
              state: data?.products?.state,
            };
          });
          //console.log(updatedData);
          setProductList(updatedData);
        })
        .catch(function (error) {
          errorLogApi(error, "ProductList", "/products");
          console.log(error);
        });
    };
    if (loggedInUserDetails.role === PVTUSERROLE) {
      getDataFromServer();
    }
  }, [loggedInUserDetails]);

  const checkInteger = ({ currentTarget: input }) => {
    const isValidInput = /^\d+$/.test(formData?.quantity);
    if (!isValidInput) {
      toast.error("Choose only Integer value");
      setFormData({
        ...formData,
        quantity: null,
      });
      givenDoseRef.current.value = null;
      return;
    }
  };
  const handleChange = ({ currentTarget: input }) => {
    if (formData?.quantity < 0) {
      toast.error("Choose only Positive value");
      setFormData({
        ...formData,
        quantity: null,
      });
      givenDoseRef.current.value = null;
      return;
    }

    if (parseInt(formData?.quantity) > parseInt(formData?.max_available_qty)) {
      toast.error(`Value should be less than ${formData?.max_available_qty}`);
      setFormData({
        ...formData,
        quantity: null,
      });
      givenDoseRef.current.value = null;
      return;
    }
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  useEffect(() => {
    const selectedProduct = productList?.find(
      (prod) => prod.product_id === viewSale?.s_register?.product_id
    );
    setFormData({
      ...formData,
      product_name: viewSale?.s_register?.product_name,
      product_id: selectedProduct?.product_id,
      product_expiry: product?.p_register?.expiry_date,
      product_code: selectedProduct?.product_code,
      available_qty: selectedProduct?.available_qty,
      max_available_qty: selectedProduct?.max_available_qty,
      storage_unit: selectedProduct?.storage_unit,
      measuring_unit: selectedProduct?.measuring_unit,
      batch_id: product?.p_register?.batch_id,
      tc_purchase_id: selectedProduct?.tc_purchase_id,
      quantity: viewSale?.s_register?.quantity,
      dose_given: viewSale?.s_register?.dose_given,
      given_by_nurse_or_doctor: viewSale?.s_register?.given_by_nurse_or_doctor,
      allergic_reactions: viewSale?.s_register?.allergic_reactions,
      systolic_pressure: viewSale?.s_register?.vitals?.systolic_pressure,
      diastolic_pressure: viewSale?.s_register?.vitals?.diastolic_pressure,
      heart_rate: viewSale?.s_register?.vitals?.heart_rate,
      temperature: viewSale?.s_register?.vitals?.temperature,
      private_product_expiry: viewSale?.s_register?.private_product_expiry,
      comments: viewSale?.s_register?.comments,
    });
    setAvailbleQty(selectedProduct?.available_qty);
  }, [productList, viewSale]);

  //console.log(viewSale);
  return (
    <div>
      <div className="patientUpdateButton">
        &nbsp;&nbsp;
        <button
          className="btn btn-secondary btn-md"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
      {/* {JSON.stringify(viewSale)} */}
      <div className="mb-2">
        <fieldset className="border p-2 w-100">
          <legend className="float-none w-auto p-2 fs-5">
            <span
              class="btn btn-warning btn-sm not-clickable"
              style={{ pointerEvents: "none" }}
            >
              Dose Details
            </span>
          </legend>
          <div
            className="p-20"
            style={{ width: "100%", paddingLeft: "20px", paddingRight: "20px" }}
          >
            <div className="table-responsive overflow-y:hidden">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th scope="row">Date of Visit</th>
                    <td>
                      {visit?.date_of_visit &&
                        dateFormatter(visit?.date_of_visit)}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Patient Name</th>
                    <td>
                      {patientRecord?.patient_name &&
                        patientRecord?.patient_name}
                      {" / "}
                      {patientRecord?.patientId}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Diagnosis</th>
                    <td>
                      <span className="text-bold">
                        {patientDiagnosis && patientDiagnosis}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" style={{ verticalAlign: "center" }}>
                      Product Name
                    </th>
                    <td>
                      {viewSale?.s_register?.product_id?.products?.product_name}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Dose ID</th>
                    <td>
                      {viewSale?.s_register?.tc_sale_id &&
                        viewSale?.s_register?.tc_sale_id}
                    </td>
                  </tr>
                  {loggedInUserDetails?.role !== PVTUSERROLE && (
                    <tr>
                      <th scope="row">Product Expiry</th>
                      <td>
                        {formData?.product_expiry &&
                          expiryFormatter(formData?.product_expiry)}{" "}
                      </td>
                    </tr>
                  )}
                  {loggedInUserDetails?.role === PVTUSERROLE && (
                    <tr>
                      <th scope="row">Product Expiry</th>
                      <td>
                        {" "}
                        {expiryFormatter(formData?.private_product_expiry)}{" "}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th scope="row">Product Code</th>
                    <td>
                      {viewSale?.s_register?.product_id?.products?.item_code &&
                        viewSale?.s_register?.product_id?.products
                          ?.item_code}{" "}
                    </td>
                  </tr>
                  {loggedInUserDetails?.role !== PVTUSERROLE && (
                    <tr>
                      <th scope="row">Product BatchID</th>
                      <td> {formData?.batch_id && formData?.batch_id} </td>
                    </tr>
                  )}
                  <tr>
                    <th scope="row">Dose Given</th>
                    <td>
                      {formData?.quantity}&nbsp;{" "}
                      {viewSale?.s_register?.product_id?.products
                        ?.storage_unit &&
                        viewSale?.s_register?.product_id?.products
                          ?.storage_unit}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Dose quantity</th>
                    <td>
                      {formData?.dose_given}&nbsp;{" "}
                      {viewSale?.s_register?.product_id?.products
                        ?.measuring_unit &&
                        viewSale?.s_register?.product_id?.products
                          ?.measuring_unit}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Attending Nurse/Doctor</th>
                    <td>
                      {formData?.given_by_nurse_or_doctor &&
                        formData?.given_by_nurse_or_doctor}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Blood Pressure</th>
                    <td>
                      <table>
                        <tr>
                          <td>
                            <input
                              type="number"
                              name="systolic_pressure"
                              placeholder="Systolic Pressure"
                              disabled={true}
                              onChange={handleChange}
                              value={formData?.systolic_pressure}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              name="diastolic_pressure"
                              placeholder="Diastolic Pressure"
                              disabled={true}
                              onChange={handleChange}
                              value={formData?.diastolic_pressure}
                            />
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Heart Rate</th>
                    <td>
                      <input
                        type="number"
                        name="heart_rate"
                        placeholder="Heart Rate (BPM)"
                        disabled={true}
                        onChange={handleChange}
                        value={formData?.heart_rate}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Temperature</th>
                    <td>
                      <input
                        type="number"
                        name="temperature"
                        placeholder="Temperature"
                        disabled={true}
                        onChange={handleChange}
                        value={formData?.temperature}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      Allergic Reaction Details (if any)
                    </th>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ height: "100px" }}>
                      <textarea
                        type="text"
                        name="allergic_reactions"
                        className="newPatientSelect w-100 h-100"
                        row={3}
                        placeholder=""
                        disabled={true}
                        onChange={handleChange}
                        value={formData?.allergic_reactions}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={2}>
                      Comments
                    </th>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ height: "170px" }}>
                      <textarea
                        type="text"
                        name="comments"
                        className="newPatientSelect w-100 h-100"
                        row={3}
                        placeholder=""
                        disabled={true}
                        onChange={handleChange}
                        value={formData?.comments}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/* {JSON.stringify(formData)} */}
          {/* {JSON.stringify(loggedInUserDetails)} */}
        </fieldset>
      </div>
      <div className="mb-2">
        <PatientInfoBlock selectedPatientProfile={patientRecord} />
      </div>
      <VisitInfoBlock
        selectedPatientVisit={visit}
        patientDiagnosis={patientDiagnosis}
      />
    </div>
  );
};

const ProductName = ({ prod_name, qty, pexpiry, loggedInUserDetails }) => {
  const [{ errorLogApi, expiryFormatter }] = useAtom(gbState);
  return (
    <div>
      <span className="text-xs">{prod_name}</span>
      {loggedInUserDetails?.role === PVTUSERROLE && (
        <p className="text-black truncate text-light font-12" small>
          {" "}
          <Badge bg="primary">Local Inventory</Badge>
        </p>
      )}
    </div>
  );
};

export default ViewDoseForPatientChild;

import React, { useEffect, useState, useContext, useRef } from "react";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Card from "react-bootstrap/Card";
import { confirmAlert } from "react-confirm-alert";
import "antd/dist/antd.min.css";
import { Modal } from "react-bootstrap";
import { DataGrid } from "@material-ui/data-grid";
import toast from "react-hot-toast";
import { makeStyles } from "@material-ui/core/styles";
import Badge from "react-bootstrap/Badge";
import "antd/dist/antd.min.css";
import { DatePicker } from "antd";
//Global State import
import { useAtom } from "jotai";
import { gbState } from "../../../../components/shared/GlobalState";

//Context import
import AuthContext from "../../../../components/shared/Context";
import ExcelDownloader from "./ExcelDownloader"; // Import the ExcelDownloader component

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const { RangePicker } = DatePicker;

const ListPurchaseTCChild = () => {
  let navigate = useNavigate();
  const { loggedInUserDetails } = useContext(AuthContext);

  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [deliveryInfo, setDeliveryInfo] = useState(false);
  const searchRef = useRef();

  //Error Logging Service
  const [
    { errorLogApi, dateFormatter, _calculateDaysLeft, _checkCalculateDaysLeft },
  ] = useAtom(gbState);

  const getDataFromServer = () => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    const bodyParameters = {
      key: "value",
    };
    let urlLink = `${process.env.REACT_APP_API_URL}/state/sales/tc/${loggedInUserDetails.tc_id}`;

    if (loggedInUserDetails.role === "FULL_ACCESS") {
      urlLink = `${process.env.REACT_APP_API_URL}/state/sales/`;
    }

    if (loggedInUserDetails.role === "ST_ADM_ACCESS") {
      urlLink = `${process.env.REACT_APP_API_URL}/state/sales/${loggedInUserDetails.state}`;
    }

    // if (loggedInUserDetails.role === "ST_ADM_ACCESS") {
    //   urlLink = `${process.env.REACT_APP_API_URL}/suppliers/state/${loggedInUserDetails.state}`;
    // }
    axios
      .get(urlLink, config)
      .then((res) => {
        const oldData = res.data.sales;
        //console.log(oldData);
        const updatedData = oldData.map((data) => {
          //   console.log(data.purchase_inventory.product_id.products);
          return {
            ...data,
            id: data._id,
            stock_type: data?.product_stock?.stock_type, //newly added
            product_code: data?.s_register?.product_id?.products?.item_code,
            product_name: data?.s_register?.product_id?.products?.product_name,
            product_expiry:
              data?.s_register?.purchase_id?.p_register?.expiry_date,
            expiry_status: _checkCalculateDaysLeft(
              data?.s_register?.purchase_id?.p_register?.expiry_date
            ),
            sale_date: dateFormatter(data?.s_register?.sale_date),
            date_for_filter: data?.s_register?.sale_date,
            sale_code: data?.s_register?.sale_id,
            quantity: data?.s_register?.quantity,
            tc_name: data?.s_register?.tc_id?.tcenters?.name_tc,
            state: data?.s_register?.state,
            allotment_recieved_status:
              data?.s_register?.allotment_recieved_status,
            comments: data?.p_register?.comments,
          };
        });
        //console.log(updatedData);
        setData(updatedData);
        setUnfilteredData(updatedData);
      })
      .catch((err) => console.log("error", err));
  };

  useEffect(() => {
    getDataFromServer();
  }, []);

  const handleDelete = (id) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this Product ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (id) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/state/sales/delete/${id}`,
        config
      )
      .then((response) => {
        getDataFromServer();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const revertRecord = (row) => {
    confirmAlert({
      title: "",
      message: `Are you sure to delete ${row.product_name} of quantity ${row.quantity} having allocation id ${row.sale_code} from your inventory ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => revertRecordHandle(row.id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });

    const revertRecordHandle = (id) => {
      let userToken = localStorage.getItem("token");
      const config = {
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/tcpurchase/revert`,
        headers: { Authorization: `Bearer ${userToken}` },
        data: { purchase_id: id },
      };
      axios(config)
        .then((response) => {
          getDataFromServer();
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
        });
    };
  };

  const useStyles = makeStyles(() => ({
    multiLineCell: {
      display: "flex",
      wordBreak: "keep-all",
      wordWrap: "break-word",
      overflowWrap: "break-word",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      lineHeight: "1.2rem",
      height: "100%",
    },
    customRow: {
      height: "75px", // Increase the row height as needed
    },
  }));

  const calculateDaysLeft = (date) => {
    // Convert the given date string to a Date object
    const givenDate = new Date(date);

    // Calculate the difference between the current date and the given date in milliseconds
    const timeDiff = givenDate.getTime() - new Date().getTime();

    // Convert milliseconds to days and round down to the nearest integer
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    // Update the state with the number of days left
    //setDaysLeft(days);
    return days;
  };

  const MultiLineCellRenderer = (params) => {
    const classes = useStyles();
    const daysLeft = calculateDaysLeft(params.row.product_expiry);

    return (
      <div className={classes.multiLineCell}>
        <span id="content1" className="text-bold">
          {params.row.product_name}
        </span>
        <span id="content2">
          <small className="small-font-size">
            {params.row.product_code}&nbsp;{" "}
          </small>
        </span>
      </div>
    );
  };

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            <Tooltip title="Product Information">
              <a
                onClick={() => {
                  setDeliveryInfo(false);
                  viewRecord(params.row);
                }}
              >
                <i
                  className="mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </a>
            </Tooltip>
            <Tooltip title="Delivery Information">
              <div
                onClick={() => {
                  setDeliveryInfo(true);
                  viewRecord(params.row);
                }}
              >
                <i
                  className="mdi mdi-flash-circle"
                  style={{
                    fontSize: "15px",
                    color: `${
                      params.row.allotment_recieved_status ? "blue" : "red"
                    }`,
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </Tooltip>
            {params.row.allotment_recieved_status && (
              <Tooltip title="Allotment Accepted">
                <div>
                  <i
                    className="mdi mdi-checkbox-marked-circle"
                    style={{
                      fontSize: "15px",
                      color: "green",
                      cursor: "pointer",
                    }}
                  ></i>
                </div>
              </Tooltip>
            )}
            {!params.row.allotment_recieved_status && (
              <Tooltip title="Awaiting acceptance">
                <div
                  onClick={() =>
                    navigate("/add-purchase-tc", { state: params.row })
                  }
                >
                  <i
                    className="mdi mdi-help-circle"
                    style={{
                      fontSize: "15px",
                      color: "purple",
                      cursor: "pointer",
                    }}
                  ></i>
                </div>
              </Tooltip>
            )}

            {(loggedInUserDetails.role === "FULL_ACCESS" ||
              loggedInUserDetails.role === "ST_ADM_ACCESS") && (
              <Tooltip title="Delete Allotment">
                <div onClick={() => handleDelete(params.row._id)}>
                  <i
                    className="mdi mdi-trash-can-outline"
                    style={{
                      fontSize: "15px",
                      color: "red",
                      cursor: "pointer",
                    }}
                  ></i>
                </div>
              </Tooltip>
            )}
            <Tooltip title="Revert drug to state">
              <div className="" onClick={() => revertRecord(params.row)}>
                <i
                  className="mdi mdi-close-circle"
                  style={{ color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "allotment_recieved_status",
      headerName: "Issued Status",
      width: 145,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (!params.row.allotment_recieved_status) {
          return (
            <Tooltip title="Confirm/Accept">
              <Badge
                bg="danger"
                style={{ width: "100%", display: "block", cursor: "pointer" }}
                onClick={() =>
                  navigate("/add-purchase-tc", { state: params.row })
                }
              >
                &nbsp;AWAITING&nbsp;
              </Badge>
            </Tooltip>
          );
        } else {
          return (
            <Badge bg="success" style={{ width: "100%", display: "block" }}>
              RECIEVED
            </Badge>
          );
        }
      },
      align: "center",
    },
    {
      field: "product_name",
      headerName: "Product",
      width: 220,
      renderCell: MultiLineCellRenderer,
    },
    {
      field: "sale_date",
      headerName: "Date",
      filterable: false,
      disableColumnMenu: true,
      width: 120,
      align: "center",
    },
    {
      field: "sale_code",
      headerName: "Allocation Code",
      filterable: false,
      disableColumnMenu: true,
      width: 160,
      align: "center",
    },

    {
      field: "quantity",
      headerName: "Qty",
      filterable: false,
      disableColumnMenu: true,
      width: 120,
      align: "center",
    },

    {
      field: "expiry_status",
      headerName: "Expiry",
      width: 120,
      renderCell: (params) =>
        isNaN(params.row.expiry_status) ? (
          <Badge bg="danger">Expired</Badge>
        ) : (
          <Badge bg="success">( {params.row.expiry_status} ) days left</Badge>
        ),
    },
    {
      field: "stock_type",
      headerName: "Stock Type",
      width: 140,
      filterable: false,
      disableColumnMenu: true,
      align: "left",
      renderCell: (params) =>
        params?.row?.stock_type ? (
          <Badge bg="warning" style={{ width: "100%", display: "block" }}>
            LOCAL ALLOCATION
          </Badge>
        ) : (
          <Badge bg="success" style={{ width: "100%", display: "block" }}>
            STATE ALLOCATION
          </Badge>
        ),
    },
    {
      field: "tc_name",
      headerName: "TC Name",
      width: 150,
    },

    {
      field: "state",
      headerName: "State",
      filterable: false,
      disableColumnMenu: true,
      width: 110,
    },
  ];

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };
  //   console.log(data);

  const handleSearch = (e) => {
    //console.log("Search Value...", e);

    if (e) {
      setData(
        unfilteredData.filter((item) => {
          return (
            item?.product_code?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.expiry_status
              ?.toString()
              ?.toLowerCase()
              .includes(e?.toLowerCase()) ||
            item?.product_name?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.company_name?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.sale_code?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.state?.toLowerCase().includes(e?.toLowerCase())
          );
        })
      );
    } else {
      getDataFromServer();
      setData(unfilteredData);
    }
  };

  const clearSearch = () => {
    searchRef.current.value = null;
    getDataFromServer();
  };

  const handleDateChange = (dates, dateStrings) => {
    if (dates) {
      const [start, end] = dates;
      setData(
        unfilteredData.filter((item) => {
          const visitDate = new Date(item.date_for_filter);
          return visitDate >= start && visitDate <= end;
        })
      );
    } else {
      setData(unfilteredData);
    }
  };

  const handleDataRefresh = () => {
    getDataFromServer();
  };

  return (
    <div>
      <span className="">
        <h3>TC Allocations/TC Purchase Register</h3>
      </span>
      <div className="float-end">
        <ExcelDownloader
          data={data}
          filename={`purchase_status_${new Date()
            .toISOString()
            .replace(/[-:.]/g, "")}.xlsx`}
          excludedFields={[
            "_id",
            "id",
            "s_register",
            "__v",
            "patient_record",
            "visit_record",
            "allotment_recieved_status",
          ]}
        />
        &nbsp;&nbsp;
        <button className="btn btn-primary ms-2" onClick={handleDataRefresh}>
          <i className="mdi mdi-autorenew" />
        </button>
      </div>
      <CheckboxComponent
        setData={setData}
        data={data}
        unfilteredData={unfilteredData}
      />

      <div className="patientListSearch">
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">Filter</InputGroup.Text>
          <Form.Control
            placeholder="Product / State / Code / Company ..."
            aria-label="Search"
            aria-describedby="basic-addon1"
            name="searchPatients"
            ref={searchRef}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <InputGroup.Text>
            <i
              className="mdi mdi-delete-outline"
              onClick={clearSearch}
              style={{ cursor: "pointer" }}
            ></i>
          </InputGroup.Text>
        </InputGroup>
      </div>
      <div className="dateFilter mb-3">
        Date Filter : <RangePicker onChange={handleDateChange} />
      </div>
      <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        displayTitle="Purchases"
        checkboxSelection
        style={{ height: "60vh" }}
      />

      {/* Modal Popup */}
      <Modal show={openModal} centered>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent
            currentRecord={currentRecord}
            deliveryInfo={deliveryInfo}
          />
        </Modal.Body>
      </Modal>
      {/* {JSON.stringify(data)} */}
    </div>
  );
};

const ModalContent = ({ currentRecord, deliveryInfo }) => {
  const [{ dateFormatter, _calculateDaysLeft }] = useAtom(gbState);
  const daysLeft = _calculateDaysLeft(currentRecord.product_expiry);
  return (
    <div className="container-fluid">
      {!deliveryInfo && (
        <ModalCardComponent title="Product Details">
          <table class="table">
            <tbody>
              <tr>
                <th scope="row">Date of Sale</th>
                <td>{currentRecord.sale_date}</td>
              </tr>
              <tr>
                <th scope="row">Sale ID</th>
                <td>{currentRecord.s_register.sale_id}</td>
              </tr>
              <tr>
                <th scope="row">Product Name</th>
                <td>{currentRecord.product_name}</td>
              </tr>
              <tr>
                <th scope="row">Product ID</th>
                <td>{currentRecord.product_code}</td>
              </tr>
              <tr>
                <th scope="row">Product Expiry</th>
                <td>
                  {dateFormatter(currentRecord.product_expiry)} &nbsp;&nbsp;
                  <b> {daysLeft}</b>
                </td>
              </tr>
              <tr>
                <th scope="row">Alloted Quantity</th>
                <td>{currentRecord.quantity}</td>
              </tr>
              <tr>
                <th scope="row">TC Name</th>
                <td>{currentRecord.tc_name}</td>
              </tr>
            </tbody>
          </table>
        </ModalCardComponent>
      )}
      {deliveryInfo && (
        <ModalCardComponent title="Delivery Details">
          {/* {JSON.stringify(currentRecord)} */}
          <table class="table">
            <tbody>
              <tr>
                <th scope="row">Delivery Person</th>
                <td>{currentRecord?.s_register?.delivery_person}</td>
              </tr>
              <tr>
                <th scope="row">Delivery Mobile</th>
                <td>{currentRecord?.s_register?.delivery_mobile}</td>
              </tr>
              <tr>
                <th scope="row">Delivery Date</th>
                <td>
                  {
                    currentRecord?.s_register?.expected_date_of_delivery?.split(
                      "T"
                    )[0]
                  }
                </td>
              </tr>
              <tr>
                <th colspan="2" scope="row">
                  Comments
                </th>
              </tr>
              <tr>
                <td colspan="2" scope="row">
                  {currentRecord?.s_register?.comments}
                </td>
              </tr>
            </tbody>
          </table>
        </ModalCardComponent>
      )}
    </div>
  );
};

const ModalCardComponent = ({ title, children }) => {
  return (
    <Card style={{ width: "100%" }}>
      <Card.Header>
        <span className="card-title">
          <b>{title}</b>
        </span>
      </Card.Header>
      <Card.Body style={{ wordBreak: "break-word" }}>{children}</Card.Body>
    </Card>
  );
};

const CheckboxComponent = ({ setData, data, unfilteredData }) => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  useEffect(() => {
    setData(
      unfilteredData.filter((item) => {
        if (selectedOption === "All") return unfilteredData;
        if (selectedOption === "Received")
          return item?.allotment_recieved_status !== null;
        if (selectedOption === "Awaiting")
          return item?.allotment_recieved_status === null;
      })
    );
  }, [selectedOption]);

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <label>
        <input
          type="checkbox"
          value="All"
          checked={selectedOption === "All"}
          onChange={handleOptionChange}
        />
        &nbsp;&nbsp;All
      </label>
      <label>
        <input
          type="checkbox"
          value="Received"
          checked={selectedOption === "Received"}
          onChange={handleOptionChange}
        />
        &nbsp;&nbsp;Received
      </label>
      <label>
        <input
          type="checkbox"
          value="Awaiting"
          checked={selectedOption === "Awaiting"}
          onChange={handleOptionChange}
        />
        &nbsp;&nbsp;Awaiting
      </label>
    </div>
  );
};

export default ListPurchaseTCChild;

import React, { useContext, useRef } from "react";
import { Modal } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

//Context import
import AuthContext from "../../../components/shared/Context";

const TCentersListChild = () => {
  const { loggedInUserDetails } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  let navigate = useNavigate();
  const searchRef = useRef();

  const handleDelete = (id) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this TC ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (id) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(`${process.env.REACT_APP_API_URL}/tc/delete/${id}`, config)
      .then((response) => {
        //alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((err) => alert(err));
  };

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/tc/`;

    if (loggedInUserDetails.role === "ST_ADM_ACCESS") {
      urlLink = `${process.env.REACT_APP_API_URL}/tc/state/${loggedInUserDetails.state}`;
    }

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.treatment_center));
        const oldData = response.data.treatment_center;
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data._id,
            name_tc: data.tcenters.name_tc,
            hdcc_no: data.tcenters.hdcc_no,
            state: data.tcenters.state,
            district: data.tcenters.district,
            tcid: data?.tcenters?.treatment_center_id,
            tc_type: data.tcenters?.tc_type,
          };
        });
        //console.log(updatedData);
        setData(updatedData);
        setUnfilteredData(updatedData);
      })
      .catch(function (error) {
        //console.log(error.message);
      });
  };

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/tc/`;

      if (loggedInUserDetails.role === "ST_ADM_ACCESS") {
        urlLink = `${process.env.REACT_APP_API_URL}/tc/state/${loggedInUserDetails.state}`;
      }
      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      axios(config)
        .then(function (response) {
          //console.log(JSON.stringify(response.data.treatment_center));
          const oldData = response.data.treatment_center;
          const updatedData = oldData.map((data) => {
            return {
              ...data,
              id: data._id,
              name_tc: data.tcenters.name_tc,
              hdcc_no: data.tcenters.hdcc_no,
              state: data.tcenters.state,
              district: data.tcenters.district,
              tcid: data?.tcenters?.treatment_center_id,
              tc_type: data.tcenters?.tc_type,
            };
          });
          //console.log(updatedData);
          setData(updatedData);
          setUnfilteredData(updatedData);
        })
        .catch(function (error) {
          //console.log(error.message);
        });
    };

    getDataFromServer();
  }, []);

  const handleTCEdit = (row) => {
    //alert(JSON.stringify(row));
    navigate("/tcenters/update/", { state: row });
  };

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            <Tooltip title="View TC">
              <a onClick={() => viewRecord(params.row)}>
                <i
                  className="mdi mdi-eye-circle-outline"
                  style={{
                    fontSize: "15px",
                    color: "black",
                    cursor: "pointer",
                  }}
                ></i>
              </a>
            </Tooltip>
            <Tooltip title="Edit TC">
              <a onClick={() => handleTCEdit(params.row)}>
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </a>
            </Tooltip>
            <Tooltip title="Delete TC">
              <div onClick={() => handleDelete(params.row._id)}>
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "tcid",
      headerName: "TC ID",
      width: 150,
    },
    {
      field: "name_tc",
      headerName: "Treatment Center",
      width: 300,
      renderCell: (params) => {
        return <div className="">{params?.row?.name_tc}</div>;
      },
    },
    {
      field: "tc_type",
      headerName: "TC Type",
      width: 140,
      align: "center",
      renderCell: (params) => {
        if (params?.row?.tc_type === "private") {
          return (
            <Badge bg="primary">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {params?.row?.tc_type && params?.row?.tc_type.toUpperCase()}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Badge>
          );
        } else {
          return (
            <Badge bg="success">
              {params?.row?.tc_type && params?.row?.tc_type.toUpperCase()}
            </Badge>
          );
        }
      },
    },
    {
      field: "hdcc_no",
      headerName: "HDCC ID",
      width: 130,
    },
    {
      field: "state",
      headerName: "State",
      width: 130,
    },
    {
      field: "district",
      headerName: "District/City",
      width: 170,
    },
    // {
    //   field: "id",
    //   headerName: "TC ID",
    //   width: 300,
    // },
  ];

  const handleAddTreatmentCenter = () => {
    navigate("/tcenters/add");
  };

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };

  const unfilterData = data;
  const handleSearch = (e) => {
    //console.log("Search Value...", e);

    if (e) {
      setData(
        unfilteredData.filter((item) => {
          return (
            item?.name_tc?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.tcid?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.email?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.hdcc_no?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.district?.toLowerCase().includes(e?.toLowerCase())
          );
        })
      );
    } else {
      setData(unfilteredData);
      getDataFromServer();
    }
  };

  const clearSearch = () => {
    searchRef.current.value = null;
    getDataFromServer();
  };

  return (
    <div className="patient-list-tc">
      <div>
        <h2>Treatment Centers</h2>
      </div>
      <div style={{ textAlign: "right", width: "100%" }}>
        <Button
          variant="primary"
          size="medium"
          onClick={handleAddTreatmentCenter}
          style={{ float: "right" }}
        >
          Add TC
        </Button>
      </div>
      <br />
      <br />
      <div className="patientListSearch">
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">Filter</InputGroup.Text>
          <Form.Control
            placeholder="Name / ID ..."
            aria-label="Search"
            aria-describedby="basic-addon1"
            name="searchPatients"
            ref={searchRef}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <InputGroup.Text>
            <i
              className="mdi mdi-delete-outline"
              onClick={clearSearch}
              style={{ cursor: "pointer" }}
            ></i>
          </InputGroup.Text>
        </InputGroup>
      </div>
      <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        displayTitle="Treatment Centers"
        checkboxSelection
        style={{ height: "60vh" }}
      />

      {/* Modal Popup */}
      <Modal show={openModal} size="md" centered>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent currentRecord={currentRecord.tcenters} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

const ModalContent = ({ currentRecord }) => {
  const [tcenter, setTcenter] = useState({ ...currentRecord });

  return (
    <div className="container-fluid legend">
      <Card>
        <Card.Header>
          <strong>Treatment Center Details</strong>&nbsp;&nbsp;
          {currentRecord?.tc_type === "private" ? (
            <Badge bg="primary">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {currentRecord?.tc_type && currentRecord?.tc_type.toUpperCase()}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Badge>
          ) : (
            <Badge bg="success">
              {currentRecord?.tc_type && currentRecord?.tc_type.toUpperCase()}
            </Badge>
          )}
        </Card.Header>
        <Card.Body>
          <CardTableRow
            rowName="TC ID"
            rowValue={tcenter?.treatment_center_id}
          />
          <CardTableRow rowName="TC Name" rowValue={tcenter?.name_tc} />
          <CardTableRow rowName="Institution" rowValue={tcenter?.institution} />
          <CardTableRow rowName="HDCC No." rowValue={tcenter?.hdcc_no} />
          <CardTableRow rowName="TC Type" rowValue={tcenter?.tc_type} />
          <CardTableRow
            rowName="Primary Email"
            rowValue={tcenter?.primary_email}
          />
          <CardTableRow
            rowName="Secondary Email"
            rowValue={tcenter?.secondary_email}
          />
          <CardTableRow rowName="Website" rowValue={tcenter?.website} />
          <CardTableRow rowName="Phone" rowValue={tcenter?.phone} />
          <CardTableRow rowName="Fax" rowValue={tcenter?.fax} />
          <CardTableRow rowName="State" rowValue={tcenter?.state} />
          <CardTableRow rowName="District" rowValue={tcenter?.district} />
          <CardTableRow rowName="Pincode" rowValue={tcenter?.pincode} />
          <CardTableRow
            rowName="Address Line1"
            rowValue={tcenter?.address_line1}
          />
          <CardTableRow
            rowName="Address Line2"
            rowValue={tcenter?.address_line2}
          />
          <CardTableRow rowName="Landmark" rowValue={tcenter?.landmark} />
        </Card.Body>
      </Card>

      {/* {JSON.stringify(tcenter)} */}
    </div>
  );
};

const CardTableRow = ({ rowName, rowValue }) => {
  return (
    <div className="row">
      <div className="col-md-6 col-xs-6 divTableCell">
        <b> {rowName}: </b>
      </div>
      <div className="col-md-6 col-xs-6 divTableCell">{rowValue}</div>
    </div>
  );
};

export default TCentersListChild;

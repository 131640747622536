import React, { useState } from "react";
import Card from "react-bootstrap/Card";

import Topbar from "../../components/Topbar/Topbar";
import SideBar from "../../components/SideBar/SideBar";
import LeftSideBar from "../../components/SideBar/LeftSideBar";

const MainContentCard = ({ children }) => {
  return (
    <>
      <div className="wrapper">
        <LeftSideBar />
        {/* Left Sidebar End */}
        {/* </div> */}
        <div className="content-page">
          <div className="content">
            <Topbar />
            {/* Main content here  */}
            <Card style={{ width: "100%", marginTop: "10px", padding: "30px" }}>
              <Card.Body>{children}</Card.Body>
            </Card>
            {/* <PatientList /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainContentCard;

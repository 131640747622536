import { atom } from "jotai";
import {
  sanitizeFunction,
  errorLogFunction,
  encryptData,
  decryptData,
  maskAadhaarString,
  dateFormatter,
  convertDate,
  expiryFormatter,
  customEncrypt,
  _calculateAge,
  _checkIfLatestRecord,
  _calculateDaysLeft,
  _checkCalculateDaysLeft,
} from "./GeneralStateFunctions";

/*
To import in any component in header

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

inside function
//Error Logging Service & Santize Input
const [{ errorLogApi, sanitizeInput }] = useAtom(gbState);

*/
export const gbState = atom({
  errorLogApi: errorLogFunction,
  sanitizeInput: sanitizeFunction,
  encryptData: encryptData,
  decryptData: decryptData,
  maskAadhaarString: maskAadhaarString,
  dateFormatter: dateFormatter,
  convertDate: convertDate,
  expiryFormatter: expiryFormatter,
  customEncrypt: customEncrypt,
  _calculateAge: _calculateAge,
  _checkIfLatestRecord: _checkIfLatestRecord,
  _calculateDaysLeft: _calculateDaysLeft,
  _checkCalculateDaysLeft: _checkCalculateDaysLeft,
});

import React from "react";
import { Table, Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";

const ViewDoseInformation = ({ data }) => {
  return (
    <Container>
      <Card>
        <Card.Header>
          <i className="fas fa-align-justify" />
          <strong>Patient Dose Details</strong>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover responsive>
            <tbody>
              <tr key={data?.patient_name}>
                <td>Patient Name</td> <td>{data?.patient_name}</td>
              </tr>
              <tr key={data?.patient_name}>
                <td>Visit Date</td>
                <td>{data?.visit_date?.slice(0, 10)}</td>
              </tr>
              <tr key={data?.patient_name}>
                <td>Product Name</td> <td>{data?.product_name}</td>
              </tr>
              <tr key={data?.patient_name}>
                <td>Quantity</td>
                <td>{data?.quantity}</td>
              </tr>
              <tr key={data?.patient_name}>
                <td>HCP Name</td>
                <td>{data?.hcp_name}</td>
              </tr>
              <tr key={data?.patient_name}>
                <td>TC Name</td>
                <td>{data?.tc_name}</td>
              </tr>
              <tr key={data?.patient_name}>
                <td>State</td>
                <td>{data?.state}</td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ViewDoseInformation;

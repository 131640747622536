import React from "react";
import MainContentCard from "../../Home/MainContentCard";
import { useLocation } from "react-router-dom";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import UpdatePurchaseRequestChild from "./UpdatePurchaseRequestChild";

const UpdatePurchaseRequest = () => {
  const { state } = useLocation();
  let requestedProduct = state;
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes
        page="UpdateRequest"
        route="/purchase-request-update"
      >
        <UpdatePurchaseRequestChild requestedProduct={requestedProduct} />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default UpdatePurchaseRequest;

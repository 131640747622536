import React, { useState } from "react";
import Topbar from "../../components/Topbar/Topbar";
import SideBar from "../../components/SideBar/SideBar";
import LeftSideBar from "../../components/SideBar/LeftSideBar";

const MainContent = ({ children }) => {
  return (
    <>
      <div className="wrapper">
        <LeftSideBar />
        {/* Left Sidebar End */}
        {/* </div> */}
        <div className="content-page ">
          <div className="content ">
            <Topbar />
            {/* Main content here  */}
            <div style={{ width: "100%", marginTop: "10px" }}>{children}</div>

            {/* <PatientList /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainContent;

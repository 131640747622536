import React from "react";
import Select from "react-select";

const WFHSwellingRomTab = ({ dropDownChange, formData }) => {
  const dropDown01 = [
    {
      id: 1,
      name: "0",
      value: 0,
    },
    {
      id: 2,
      name: "1",
      value: 1,
    },
    {
      id: 3,
      name: "2",
      value: 2,
    },
  ];

  const SwellingsAndRoms = {
    swelling_left: [
      "ankle_left_swelling",
      "elbow_left_swelling",
      "knee_left_swelling",
    ],
    swelling_right: [
      "ankle_right_swelling",
      "elbow_right_swelling",
      "knee_right_swelling",
    ],
    rom_left: ["ankle_left_rom", "elbow_left_rom", "knee_left_rom"],
    rom_right: ["ankle_right_rom", "elbow_right_rom", "knee_right_rom"],
  };

  return (
    <div className="table-responsive w-100" style={{ marginRight: "40px" }}>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col" className="text-center">
              Ankle
            </th>
            <th scope="col" className="text-center">
              Elbow
            </th>
            <th scope="col" className="text-center">
              Knee
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colSpan={4}>SWELLING</th>
          </tr>
          <tr>
            <td className="table-row-label">Left</td>

            {SwellingsAndRoms.swelling_left.map((sw_left, index) => (
              <td className="table-row-options">
                <DropDownMenu
                  fieldName={sw_left}
                  dropDownChange={dropDownChange}
                  defvalue={formData[SwellingsAndRoms.swelling_left[index]]}
                  dropDownValues={dropDown01}
                />
              </td>
            ))}
          </tr>
          <tr>
            <td className="table-row-label">Right</td>
            {SwellingsAndRoms.swelling_right.map((sw_right, index) => (
              <td className="table-row-options">
                <DropDownMenu
                  fieldName={sw_right}
                  dropDownChange={dropDownChange}
                  defvalue={formData[SwellingsAndRoms.swelling_right[index]]}
                  dropDownValues={dropDown01}
                />
              </td>
            ))}
          </tr>
          <tr>
            <td colSpan={4}>
              0: None <br />
              1: Present <br />
              2: Present with chronic synovitis
            </td>
          </tr>
          <tr>
            <th colSpan="4">ROM</th>
          </tr>
          <tr>
            <td className="table-row-label">Left</td>
            {SwellingsAndRoms.rom_left.map((rm_left, index) => (
              <td className="table-row-options">
                <DropDownMenu
                  fieldName={rm_left}
                  dropDownChange={dropDownChange}
                  defvalue={formData[SwellingsAndRoms.rom_left[index]]}
                  dropDownValues={dropDown01}
                />
              </td>
            ))}
          </tr>
          <tr>
            <td className="table-row-label">Right</td>
            {SwellingsAndRoms.rom_right.map((rm_right, index) => (
              <td className="table-row-options">
                <DropDownMenu
                  fieldName={rm_right}
                  dropDownChange={dropDownChange}
                  defvalue={formData[SwellingsAndRoms.rom_right[index]]}
                  dropDownValues={dropDown01}
                />
              </td>
            ))}
          </tr>
          <tr>
            <td colSpan="4">
              0: Loss upto 10% Full range of motion <br />
              1: Loss 10-30% ROM <br />
              2: Loss {">"}33% ROM
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default WFHSwellingRomTab;

const DropDownMenu = ({
  fieldName,
  dropDownChange,
  dropDownValues,
  defvalue,
}) => {
  const dropDownOptions = dropDownValues.map((ddval) => ({
    value: ddval.value,
    label: ddval.name,
  }));

  return (
    <div className="newPatientItem">
      <Select
        name={fieldName}
        onChange={dropDownChange}
        value={dropDownOptions.find((v) => v.value === parseInt(defvalue))}
        className="newPatientSelect"
        options={dropDownOptions}
        isDisabled={true}
      />
    </div>
  );
};

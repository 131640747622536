import React, { useState } from "react";
import VideoTile from "./VideoTile";
import { Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import { videoList } from "./VideoList";
const VideoHelpChild = () => {
  const [data, setData] = useState(videoList);

  const unfilterData = data;
  const handleSearch = (e) => {
    // console.log("Search Value...", e);

    if (e) {
      setData(
        unfilterData.filter((item) => {
          return item.title?.toLowerCase().includes(e.toLowerCase());
        })
      );
    } else {
      setData(videoList);
    }
  };

  return (
    <Container>
      <div className="" style={{ paddingBottom: "30px" }}>
        <InputGroup className="">
          <InputGroup.Text id="basic-addon1">Search Video</InputGroup.Text>
          <Form.Control
            placeholder="keyword"
            aria-label="Search"
            aria-describedby="basic-addon1"
            name="searchPatients"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </InputGroup>
      </div>
      <Row
        xs={1}
        sm={2}
        md={3}
        lg={4}
        xl={5}
        className="g-4"
        style={{ marginLeft: "1px" }}
      >
        {data?.map((video) => (
          <VideoTile key={video.id} videoUrl={video.url} title={video.title} />
        ))}
      </Row>
    </Container>
  );
};

export default VideoHelpChild;

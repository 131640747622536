import React from "react";
import Select from "react-select";

const FlexionExtensionTab = ({ formData, dropDownChange }) => {
  const dropDown01 = [
    {
      id: 1,
      name: "<5°",
      value: 0,
    },
    {
      id: 2,
      name: "5°-10°",
      value: 1,
    },
    {
      id: 3,
      name: "11°-20°",
      value: 2,
    },
    {
      id: 4,
      name: ">20°",
      value: 3,
    },
  ];

  const flexions = {
    flexion_loss_left: [
      "flexion_loss_left_elbow",
      "flexion_loss_left_knee",
      "flexion_loss_left_ankle",
    ],
    flexion_loss_right: [
      "flexion_loss_right_elbow",
      "flexion_loss_right_knee",
      "flexion_loss_right_ankle",
    ],
  };

  const extensions = {
    extension_loss_left: [
      "extension_loss_left_elbow",
      "extension_loss_left_knee",
      "extension_loss_left_ankle",
    ],
    extension_loss_right: [
      "extension_loss_right_elbow",
      "extension_loss_right_knee",
      "extension_loss_right_ankle",
    ],
  };

  return (
    <div className="table-responsive w-100" style={{ marginRight: "40px" }}>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col" className="text-center">
              Elbow
            </th>
            <th scope="col" className="text-center">
              Knee
            </th>
            <th scope="col" className="text-center">
              Ankle
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {/* Left row */}
            <td className="table-row-label">Left</td>
            {flexions.flexion_loss_left.map((flexion_loss_left, index) => (
              <td className="table-row-options">
                <DropDownMenu
                  fieldName={flexion_loss_left}
                  dropDownChange={dropDownChange}
                  dropDownValues={dropDown01}
                  defvalue={formData[flexions.flexion_loss_left[index]]}
                  key={`flexion_loss_left${index}`}
                />
              </td>
            ))}
          </tr>
          <tr>
            <td className="table-row-label">Right</td>
            {flexions.flexion_loss_right.map((flexion_loss_right, index) => (
              <td className="table-row-options">
                <DropDownMenu
                  fieldName={flexion_loss_right}
                  dropDownChange={dropDownChange}
                  dropDownValues={dropDown01}
                  defvalue={formData[flexions.flexion_loss_right[index]]}
                  key={`flexion_loss_right${index}`}
                />
              </td>
            ))}
          </tr>
          <tr>
            <td colSpan="4" className="text-center">
              Extension Loss
            </td>
          </tr>
          <tr>
            <td className="table-row-label">Left</td>
            {extensions.extension_loss_left.map(
              (extension_loss_left, index) => (
                <td className="table-row-options">
                  {/* {JSON.stringify(formData[extensions.extension_loss_left[index]])} */}
                  <DropDownMenu
                    fieldName={extension_loss_left}
                    dropDownChange={dropDownChange}
                    dropDownValues={dropDown01}
                    defvalue={formData[extensions.extension_loss_left[index]]}
                    key={`extension_loss_left${index}`}
                  />
                </td>
              )
            )}
          </tr>
          <tr>
            <td className="table-row-label">Right</td>

            {extensions.extension_loss_right.map(
              (extension_loss_right, index) => (
                <td className="table-row-options">
                  <DropDownMenu
                    fieldName={extension_loss_right}
                    dropDownChange={dropDownChange}
                    dropDownValues={dropDown01}
                    defvalue={formData[extensions.extension_loss_right[index]]}
                    key={`extension_loss_right${index}`}
                  />
                </td>
              )
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FlexionExtensionTab;

const DropDownMenu = ({
  fieldName,
  dropDownChange,
  dropDownValues,
  defvalue,
  key,
}) => {
  const dropDownOptions = dropDownValues.map((ddval) => ({
    value: ddval.value,
    label: ddval.name,
  }));

  return (
    <div className="newPatientItem ">
      <Select
        name={fieldName}
        onChange={dropDownChange}
        value={dropDownOptions.find((v) => v.value === parseInt(defvalue))}
        className="newPatientSelect"
        options={dropDownOptions}
      />
    </div>
  );
};

import React from "react";
import { Route } from "react-router-dom";

import PrivateRoutes from "../../components/Auth/PrivateRoutes";

//Import All Components
import * as Pages from "./Pages";

export const AdminUserRoutes = (
  <Route element={<PrivateRoutes roleRequired={["FULL_ACCESS"]} />}>
    <Route path="/errorlogs" name="ErrorLogs" element={<Pages.ErrorLog />} />
    <Route path="/patients" name="Patients" element={<Pages.PatientList />} />
    <Route path="/patient/" name="Patients" element={<Pages.PatientUpdate />} />
    <Route
      path="/visits/"
      name="Visits"
      element={<Pages.PatientVisitsList />}
    />
    <Route
      path="/dashboard-state"
      name="Dashboard State"
      element={<Pages.DashboardState />}
    />
    <Route
      path="/inventory-status"
      name="Inventory Status"
      element={<Pages.InventoryStatus />}
    />
    <Route
      path="/settlement-history"
      name="Settlement History"
      element={<Pages.SettlementHistory />}
    />
    <Route
      path="/pwinventory"
      name="Product Wise Inventory"
      element={<Pages.ProductWiseInventory />}
    />
    <Route
      path="/state-inventory"
      name="Product Wise Inventory"
      element={<Pages.ListStateInventory />}
    />
    <Route
      path="/tc-allotment-status"
      name="TC Allotment Status"
      element={<Pages.TCAllotmentStatus />}
    />
    <Route
      path="/tcallocation"
      name="Allocate to TC"
      element={<Pages.AllocateTC />}
    />
    <Route
      path="/tcwise/inventory/status"
      name="TC Wise Inventory Status"
      element={<Pages.TCWiseInventoryStatus />}
    />
    <Route
      path="/transaction-history-state"
      name="Transaction History"
      element={<Pages.StateTransactionHistory />}
    />
    <Route
      path="/add-purchase"
      name="Inventory Add"
      element={<Pages.AddPurchase />}
    />
    <Route
      path="/list-purchases"
      name="List Purchases"
      element={<Pages.ListPurchases />}
    />
    <Route path="/list-sales" name="List Sales" element={<Pages.ListSales />} />
    <Route
      path="/inventory-status-tc"
      name="Inventory Status"
      element={<Pages.TCInventoryStatus />}
    />
    <Route
      path="/purchase-request-update"
      name="Purhcase Request Update"
      element={<Pages.UpdatePurchaseRequest />}
    />
    <Route
      path="/suppliers"
      name="List Suppliers"
      element={<Pages.Suppliers />}
    />
    {/* Users Management */}
    <Route path="/users" name="Users" element={<Pages.UsersList />} />
    <Route path="/users/add" name="Add User" element={<Pages.CreateUser />} />
    <Route
      path="/users/add-doctor"
      name="Add Doctor"
      element={<Pages.CreateDoctor />}
    />
    <Route
      path="/users/update-user/"
      name="Update User"
      element={<Pages.UpdateUser />}
    />
    {/* Roles Management */}
    <Route path="/roles" name="Roles" element={<Pages.RolesList />} />
    <Route path="/roles/add" name="Roles" element={<Pages.CreateRole />} />
    <Route
      path="/tcenters"
      name="Treatment Centers"
      element={<Pages.TCentersList />}
    />
    <Route
      path="/tcenters/add"
      name="Add Treatment Centers"
      element={<Pages.CreateTC />}
    />
    <Route
      path="/tcenters/update/"
      name="Update Treatment Center"
      element={<Pages.UpdateTC />}
    />

    <Route path="/entrylogs" name="EntryLogs" element={<Pages.EntryLogs />} />
    <Route
      path="/activitylogs"
      name="ActivityLogs"
      element={<Pages.ActivityLog />}
    />
    {/* Diagnosis Management */}
    <Route
      path="/diagnosis"
      name="Diagnosis Management"
      element={<Pages.Diagnosis />}
    />
    <Route
      path="/diagnosis/add"
      name="Add Diagnosis"
      element={<Pages.AddDiagnosisName />}
    />
    <Route
      path="/diagnosis/update"
      name="Update Diagnosis"
      element={<Pages.UpdateDiagnosisName />}
    />
    <Route
      path="/diagnosis-category"
      name="Diagnosis Category"
      element={<Pages.DiagnosisCategory />}
    />
    <Route
      path="/diagnosis-category/add"
      name="Diagnosis Category Add"
      element={<Pages.AddDiagnosisCategory />}
    />
    {/* Notification Route */}
    <Route
      path="/notifications"
      name="Notifications"
      element={<Pages.Notifications />}
    />
  </Route>
);

import React from "react";
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer } from "recharts";

import "./Chart.css";
//import "./ShowPieChart.css";

const ShowPieChart = ({ data }) => {
  const data01 = [
    { name: "Hemophilia A", value: 10 },
    { name: "Hemophilia B", value: 15 },
    { name: "Inhibitors", value: 5 },
    { name: "Thalasseimia", value: 20 },
    ,
  ];

  const data02 = [
    { name: "Group A", value: 2400 },
    { name: "Group B", value: 4567 },
    { name: "Group C", value: 1398 },
    { name: "Group D", value: 9800 },
    { name: "Group E", value: 3908 },
    { name: "Group F", value: 4800 },
  ];

  return (
    <div className="chart">
      <ResponsiveContainer width="100%" aspect={2}>
        <PieChart>
          <Pie
            dataKey="value"
            isAnimationActive={true}
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={150}
            fill="#8884d8"
            label
          />
          {/* <Pie
            dataKey="value"
            data={data02}
            cx={500}
            cy={200}
            innerRadius={40}
            outerRadius={80}
            fill="#82ca9d"
          /> */}
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ShowPieChart;

import "./PatientCreate.css";
import PatientCreateChild from "./PatientCreateChild";
import { useLocation } from "react-router-dom";
//import "bootstrap/dist/css/bootstrap.min.css";
import MainContentCard from "../../Home/MainContentCard";

import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

const PatientCreate = () => {
  const { state } = useLocation();
  let preRegistrationRecord = state;
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="PatientCreateChild" route="/patient-add">
        <PatientCreateChild preRegistrationRecord={preRegistrationRecord} />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default PatientCreate;

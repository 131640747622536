import { useEffect, useState, useContext } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";

import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";

//Context import
import AuthContext from "../../../components/shared/Context";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import GeneralDetailsCard from "../List/GeneralDetailsCard";
import ClinicalDetailsCard from "../List/ClinicalDetailsCard";
import BleedingHistoryCard from "../List/BleedingHistoryCard";
import VaccinationDetailsCard from "../List/VaccinationDetailsCard";
import AddressDetailsCard from "../List/AddressDetailsCard";
import ContactDetailsCard from "../List/ContactDetailsCard";
import EconomicStatusCard from "../List/EconomicStatusCard";
import SchedulingDetailsCard from "../List/SchedulingDetailsCard";
import { toast } from "react-hot-toast";
import jwt_decode from "jwt-decode";
import PatientInfoBlock from "../../../components/PatientProfile/PatientInfoBlock";

const SearchPatientListChild = ({ searchText }) => {
  let navigate = useNavigate();
  const [{ customEncrypt }] = useAtom(gbState);
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [selectedPatientProfile, setSelectedPatientProfile] = useState();

  const {
    loggedInUserDetails,
    setPatientDiagnosis,

    globalSearchText,
    setGlobalSearchText,
    setFieldLabelFromArray,
  } = useContext(AuthContext);

  function dateFormatter(date) {
    const date_conv = new Date(date);
    const fullyear = date_conv.getFullYear();
    const month = date_conv.getMonth() + 1;
    const day = date_conv.getDate();
    return `${day}/${month}/${fullyear}`;
  }

  const useStyles = makeStyles(() => ({
    multiLineCell: {
      display: "flex",
      wordBreak: "keep-all",
      wordWrap: "break-word",
      overflowWrap: "break-word",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      lineHeight: "1.2rem",
      height: "auto",
    },
  }));

  const MultiLineCellRenderer = (params) => {
    const classes = useStyles();
    return (
      <div className={classes.multiLineCell}>
        <Tooltip
          title={`${params.row.patient_death_status ? "Deceased" : "Alive"}`}
        >
          <span
            id="content1"
            style={{ display: "flex", alignItems: "center", lineHeight: "1" }}
          >
            <div
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                backgroundColor: params.row.patient_death_status
                  ? "red"
                  : "#87CEEB",
                marginRight: "5px",
              }}
            ></div>

            <b>{params.row.patient_name.toUpperCase()}</b>
          </span>
        </Tooltip>
        <span id="content2" style={{ paddingLeft: "16px" }}>
          <small className="small-font-size">
            {/* <Badge bg={`secondary`}>{params.row.patientId}</Badge> */}
            {params.row.patientId}
          </small>
        </span>
      </div>
    );
  };

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      width: 140,
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            <Tooltip title="View Patient">
              <a onClick={() => viewRecord(params.row)}>
                <i
                  className="mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </a>
            </Tooltip>
            <Tooltip title="Appointment Center">
              <a
                onClick={() => {
                  if (!params.row.patient_death_status)
                    return navigate(`/appointmentlist/`, { state: params.row });

                  return toast.error(
                    "Sorry Cannot create Appointment for deceased patient"
                  );
                }}
              >
                <i
                  className="mdi mdi-clock-alert-outline"
                  style={{
                    fontSize: "15px",
                    color: "blue",
                    cursor: "pointer",
                  }}
                />
              </a>
            </Tooltip>
            <Tooltip title="Report">
              <a
                onClick={() =>
                  navigate(`/report/`, {
                    state: params.row,
                  })
                }
              >
                <i
                  className="mdi mdi-chart-line-variant"
                  style={{
                    fontSize: "15px",
                    color: "black",
                    cursor: "pointer",
                  }}
                ></i>
              </a>
            </Tooltip>
            <Tooltip title="Edit Patient">
              <a
                onClick={() => navigate(`/patient/`, { state: params.row.id })}
              >
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey" }}
                ></i>
              </a>
            </Tooltip>
            <Tooltip title="Health Record">
              <a
                onClick={() => navigate(`/listhrecord/`, { state: params.row })}
              >
                <i
                  className="mdi mdi-heart-pulse"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                />
              </a>
            </Tooltip>
            <Tooltip title="Create a Visit">
              <div
                onClick={() => {
                  if (!params.row.patient_death_status)
                    return navigate(`/create-visit/`, {
                      state: params.row,
                    });

                  return toast.error(
                    "Sorry Cannot create visit for deceased patient"
                  );
                }}
              >
                <i
                  className="mdi mdi-plus-box"
                  style={{
                    fontSize: "15px",
                    color: "green",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </Tooltip>
            {/* </Link> */}
            <Tooltip title="List Visits">
              <div
                onClick={() =>
                  navigate(`/visits/`, {
                    state: params.row,
                  })
                }
              >
                <i
                  className="mdi mdi-view-list"
                  style={{
                    fontSize: "15px",
                    color: "green",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Record Patient Death">
              <div
                onClick={() => {
                  if (params.row.delete_status) {
                    toast.error(
                      "Deleted Record, please recover record to view"
                    );
                    return;
                  }
                  if (params.row.patient_death_status) {
                    return navigate(`/patient/drecord/view`, {
                      state: params.row,
                    });
                  }
                  return navigate(`/patient/drecord`, {
                    state: params.row,
                  });
                }}
              >
                <i
                  className="mdi mdi-heart-off"
                  style={{
                    fontSize: "15px",
                    color: "red",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "patient_name",
      headerName: "Name",
      width: 230,
      renderCell: MultiLineCellRenderer,
    },
    {
      field: "diagnosis",
      headerName: "Diagnosis",
      width: 150,
    },
    {
      field: "createdAt",
      headerName: "Registered",
      filterable: false,
      disableColumnMenu: true,
      align: "center",
      width: 150,
      renderCell: (params) => {
        return dateFormatter(params.row.createdAt);
      },
    },

    {
      field: "email",
      headerName: "Email",
      width: 150,
      renderCell: (params) => params.row.contact.email,
    },
    {
      field: "center",
      headerName: "Center",
      width: 140,
      renderCell: ({ row }) => {
        return row?.tc_detail?.tcenters?.name_tc;
      },
    },
    { field: "state", headerName: "State", width: 120 },
  ];

  useEffect(() => {
    const getDataFromServer = () => {
      const config = {
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/patient/profile/`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: { search_text: searchText.trim() },
      };

      axios(config)
        .then((res) => {
          //console.log(res.data);
          let data = res.data.patient;
          setSelectedPatientProfile(data);
          if (data) {
            const updatedData = [
              {
                ...data,
                id: data._id,
                diagnosis: data.diagnoses.diagnosis_name,
                state: data.tc_state,
              },
            ];
            //console.log(updatedData);
            setData(updatedData);
          } else {
            setData([]);
            toast.error(`Couldn't find patient`);
            navigate("/global/search");
          }
        })
        .catch((err) => console.log("error", err));
    };

    getDataFromServer();
  }, [searchText]);

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };

  return (
    <div>
      <div className="mb-2">
        {/* {JSON.stringify(selectedPatientProfile)} */}
        {selectedPatientProfile && (
          <PatientInfoBlock selectedPatientProfile={selectedPatientProfile} />
        )}
      </div>
      <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5]}
        displayTitle="Patient List"
        checkboxSelection
        style={{ height: "43vh" }}
      />

      {/* Modal Popup */}
      <Modal show={openModal} size="lg">
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent
            currentRecord={currentRecord}
            setFieldLabelFromArray={setFieldLabelFromArray}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

const ModalContent = ({ currentRecord, setFieldLabelFromArray }) => {
  return (
    <div className="container-fluid legend">
      <GeneralDetailsCard
        currentRecord={currentRecord}
        setFieldLabelFromArray={setFieldLabelFromArray}
      />
      <ClinicalDetailsCard
        currentRecord={currentRecord}
        setFieldLabelFromArray={setFieldLabelFromArray}
      />
      <BleedingHistoryCard
        currentRecord={currentRecord}
        setFieldLabelFromArray={setFieldLabelFromArray}
      />
      <VaccinationDetailsCard
        currentRecord={currentRecord}
        setFieldLabelFromArray={setFieldLabelFromArray}
      />
      <AddressDetailsCard
        currentRecord={currentRecord}
        setFieldLabelFromArray={setFieldLabelFromArray}
      />
      <ContactDetailsCard
        currentRecord={currentRecord}
        setFieldLabelFromArray={setFieldLabelFromArray}
      />
      <EconomicStatusCard
        currentRecord={currentRecord}
        setFieldLabelFromArray={setFieldLabelFromArray}
      />
      <SchedulingDetailsCard currentRecord={currentRecord} />
    </div>
  );
};

export default SearchPatientListChild;

import React, { useState, useEffect } from "react";
import { useContext, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import "./Topbar.css";

//Context import
import AuthContext from "../shared/Context";
import NotificationComponent from "./NotificationComponent";

const Topbar = () => {
  let navigate = useNavigate();
  const searchTextRef = useRef();
  const childRef = useRef(null);
  const [notifications, setNotifications] = useState();
  const {
    handleLogout,
    loggedInUserDetails,
    globalSearchText,
    setGlobalSearchText,
  } = useContext(AuthContext);
  const [tcenter, setTcenter] = useState(null);

  const reloadNotifications = () => {
    setNotifications(null);
    let userToken = localStorage.getItem("token");
    let notification_url = "";

    if (loggedInUserDetails?.tc_id) {
      //TC Admins Notifications
      notification_url = `${process.env.REACT_APP_API_URL}/notifications/${loggedInUserDetails?.tc_id}/5`;
    } else {
      //State Admin Notifications
      notification_url = `${process.env.REACT_APP_API_URL}/notifications/${loggedInUserDetails?._id}/5`;
    }

    const config = {
      method: "GET",
      url: notification_url,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then((response) => {
        // console.log(response.data.notifications);
        setNotifications(response.data.notifications);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");
      const config = {
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/tc/${loggedInUserDetails?.tc_id}`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(response.data.treatment_center.tcenters);
          setTcenter(response.data.treatment_center.tcenters);
        })
        .catch(function (err) {
          //console.log(error.message);
          //errorLogApi(err, "UsersListChild", "/users");
        });
    };

    if (loggedInUserDetails?.tc_id) {
      getDataFromServer();
    } else {
      setTcenter({ name_tc: "Admin mode" });
    }
  }, [loggedInUserDetails]);

  const markAsRead = (id) => {
    let userToken = localStorage.getItem("token");
    let n_url = `${process.env.REACT_APP_API_URL}/notifications/read/${id}`;
    const config = {
      method: "post",
      url: n_url,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { id },
    };

    axios(config)
      .then((response) => {
        // console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => reloadNotifications());
    console.log("message_id: ", id);
  };

  useEffect(() => {
    let userToken = localStorage.getItem("token");
    let notification_url = "";

    if (loggedInUserDetails?.tc_id) {
      //TC Admins Notifications
      notification_url = `${process.env.REACT_APP_API_URL}/notifications/${loggedInUserDetails?.tc_id}/5`;
    } else {
      //State Admin Notifications
      notification_url = `${process.env.REACT_APP_API_URL}/notifications/${loggedInUserDetails?._id}/5`;
    }
    const config = {
      method: "GET",
      url: notification_url,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then((response) => {
        // console.log(response.data.notifications);
        setNotifications(response.data.notifications);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  // console.log(loggedInUserDetails);
  return (
    <div className="navbar-custom">
      {/* Topbar Start */}
      <ul className="list-unstyled topbar-menu float-end mb-0">
        <li className="dropdown notification-list d-lg-none">
          <div
            className="nav-link  arrow-none"
            data-bs-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="false"
            aria-expanded="false"
          >
            <i className="dripicons-search noti-icon" />
          </div>
          <div className="dropdown-menu dropdown-menu-animated dropdown-lg p-0">
            <form className="p-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search ..."
                aria-label="Recipient's username"
              />
            </form>
          </div>
        </li>
        <li className="dropdown notification-list">
          <div
            className="nav-link"
            data-bs-toggle="dropdown"
            // onClick={reloadNotifications}
            role="button"
            // aria-haspopup="false"
            aria-expanded="false"
          >
            <i className="dripicons-bell noti-icon" />
            <span className="noti-icon-badge" />
          </div>
          <div
            // className="dropdown-menu dropdown-menu-end dropdown-menu-animated dropdown-lg"
            className="dropdown-menu dropdown-menu-end dropdown-menu-animated dropdown-lg topbar-dropdown-menu profile-dropdown"
          >
            {/* className="dropdown-item noti-title" */}
            <div className="noti-title">
              <h6 className="m-0">
                <span className="float-end">
                  <button className="text-dark" onClick={reloadNotifications}>
                    <i className="mdi mdi-refresh cursor-pointer"></i>
                  </button>{" "}
                </span>
                Notification
              </h6>
              <hr />
              {/* item*/}
              {notifications ? (
                <NotificationComponent
                  notificationsData={notifications}
                  markAsRead={markAsRead}
                />
              ) : (
                "loading ..."
              )}
            </div>
            <div
              className="dropdown-item text-center text-primary notify-item notify-all"
              onClick={() => navigate("/notifications")}
              style={{ cursor: "pointer" }}
            >
              View All
            </div>
          </div>
        </li>

        <li className="dropdown notification-list">
          <a
            className="nav-link  nav-user arrow-none me-0"
            data-bs-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="false"
            aria-expanded="false"
          >
            <span className="account-user-avatar">
              <img
                src="/assets/images/users/avatar-11.png"
                alt="user-image"
                className="rounded-circle"
              />
            </span>
            <span>
              <span className="account-user-name">
                {loggedInUserDetails &&
                loggedInUserDetails?.user_type === "Doctor"
                  ? loggedInUserDetails && "Dr. " + loggedInUserDetails?.name
                  : loggedInUserDetails?.user_type?.includes("Private") ||
                    loggedInUserDetails?.user_type?.includes("Nodal") ||
                    loggedInUserDetails?.user_type?.includes("State Admin")
                  ? loggedInUserDetails && loggedInUserDetails?.name
                  : loggedInUserDetails && "Mr. " + loggedInUserDetails?.name}
              </span>
              <span
                className="account-position display-6"
                style={{ fontSize: "10px" }}
              >
                {loggedInUserDetails && loggedInUserDetails?.user_type}
              </span>
              {loggedInUserDetails?.state && (
                <span className="account-position" style={{ fontSize: "9px" }}>
                  <b>
                    {" "}
                    {loggedInUserDetails && loggedInUserDetails?.district}
                    {loggedInUserDetails?.district && ", "}
                    {loggedInUserDetails && loggedInUserDetails?.state}
                  </b>
                </span>
              )}
            </span>
          </a>
          <div
            style={{ fontSize: "14px" }}
            className="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown"
          >
            {/* item*/}
            <div className="dropdown-header noti-title">
              <h6 className="text-overflow m-0">Welcome !</h6>
            </div>
            {/* item*/}
            <a
              onClick={() => navigate("/user/profile")}
              className="dropdown-item notify-item"
            >
              <i className="mdi mdi-account-circle me-1" />
              <span>My Account</span>
            </a>
            {/* item*/}
            <a href="javascript:void(0);" className="dropdown-item notify-item">
              <i className="mdi mdi-account-edit me-1" />
              <span>Settings</span>
            </a>
            {/* item*/}
            <div
              onClick={() => navigate("/help-dash")}
              className="dropdown-item notify-item"
              style={{ cursor: "pointer" }}
            >
              <i className="mdi mdi-lifebuoy me-1" />
              <span>Support</span>
            </div>
            {/* item*/}

            {/* item*/}
            <a
              href="javascript:void(0);"
              onClick={handleLogout}
              className="dropdown-item notify-item"
            >
              <i className="mdi mdi-logout me-1" />
              <span>Logout</span>
            </a>
          </div>
        </li>
      </ul>

      {/* Centered Treatment Center Text */}
      {tcenter?.name_tc === "Admin mode" ? (
        <div className="row-top topbar-center-text">
          <div className=" column grey-background treatment-center">
            ADMINISTRATOR MODE
          </div>
        </div>
      ) : (
        <div className="row-top topbar-center-text">
          <div className="column grey-background treatment-center">
            Treatment Center
          </div>
          <div className="column htc-faridkot">{tcenter?.name_tc}</div>
        </div>
      )}

      <button className="button-menu-mobile open-left">
        <i className="mdi mdi-menu" />
      </button>
      <div className="app-search dropdown d-none d-lg-block">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            //alert(searchTextRef.current.value);
            if (searchTextRef.current.value) {
              navigate("/search-patient", {
                state: searchTextRef.current.value,
              });
              setGlobalSearchText(searchTextRef.current.value);
            } else {
              toast.error("Please input Search Term");
            }
          }}
        >
          <div className="input-group">
            <input
              type="text"
              className="form-control dropdown-toggle"
              placeholder="Global Patient Search..."
              id="top-search"
              ref={searchTextRef}
              value={globalSearchText}
              onChange={(e) => setGlobalSearchText(e.target.value)}
            />
            <button
              className="btn bg-transparent"
              style={{ marginLeft: "-40px", zIndex: "100" }}
              onClick={() => {
                setGlobalSearchText("");
              }}
            >
              <i className="mdi mdi-trash-can-outline"></i>
            </button>
            <span className="mdi mdi-magnify search-icon" />
            <button className="input-group-text btn-primary" type="submit">
              Search
            </button>
          </div>
        </form>
      </div>
      {/* end Topbar */}
    </div>
  );
};

export default Topbar;

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import MainContentCard from "../../Home/MainContentCard";
import BackButton from "../../../components/Buttons/BackButton";
import { Button } from "react-bootstrap";
import VisitInfoBlock from "./VisitInfoBlock";
import PatientInfoBlock from "../../../components/PatientProfile/PatientInfoBlock";
import ViewDischargeChild from "./ViewDischargeChild";

import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

const ViewDischarge = () => {
  let navigate = useNavigate();
  const { state } = useLocation();
  const patientDiagnosis = state.patient.diagnoses.diagnosis_name;
  const [formData, setFormData] = useState({
    ...state.visit,
  });

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="ViewDischargeChild" route="/view-discharge">
        <div className="patientUpdateButton">
          <BackButton /> &nbsp;&nbsp;
          <Button variant="secondary" size="md" onClick={() => navigate(-1)}>
            Back
          </Button>
        </div>
        <div className="mb-2">
          <PatientInfoBlock selectedPatientProfile={state.patient} />
        </div>
        <Tabs
          defaultActiveKey="discharge"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="discharge" title="Discharge">
            <ViewDischargeChild
              formData={formData}
              setFormData={setFormData}
              handleChange={handleChange}
              visitData={state.visit}
            />
          </Tab>
          <Tab eventKey="visit" title="Visit Details">
            <VisitInfoBlock
              selectedPatientVisit={state.visit}
              PatientDiagnosis={patientDiagnosis}
            />
          </Tab>
        </Tabs>
        {/* {JSON.stringify(state.visit)} */}
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default ViewDischarge;

import "./TCentersList.css";
import React from "react";
import MainContentCard from "../../Home/MainContentCard";

import TCentersListChild from "./TCentersListChild";

import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

const TCentersList = () => {
  return (
    <MainContentCard>
      {/* <SideBar /> */}
      <ErrorBoundaryRoutes route="/tcenters" page="TCentersListChild">
        <TCentersListChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default TCentersList;

import React from "react";
import Select from "react-select";

const JointPainTab = ({ formData, dropDownChange }) => {
  const dropDown03 = [
    {
      id: 1,
      name: "0",
      value: 0,
    },
    {
      id: 2,
      name: "1",
      value: 1,
    },
    {
      id: 3,
      name: "2",
      value: 2,
    },
  ];

  const jointPains = {
    joint_pain_left: [
      "joint_pain_left_elbow",
      "joint_pain_left_knee",
      "joint_pain_left_ankle",
    ],
    joint_pain_right: [
      "joint_pain_right_elbow",
      "joint_pain_right_knee",
      "joint_pain_right_ankle",
    ],
  };

  return (
    <div className="table-responsive w-100" style={{ marginRight: "40px" }}>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col" className="text-center">
              Elbow
            </th>
            <th scope="col" className="text-center">
              Knee
            </th>
            <th scope="col" className="text-center">
              Ankle
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="table-row-label">Left</td>
            {jointPains.joint_pain_left.map((joint_pain_left, index) => (
              <td className="table-row-options">
                <DropDownMenu
                  fieldName={joint_pain_left}
                  dropDownChange={dropDownChange}
                  dropDownValues={dropDown03}
                  defvalue={formData[jointPains.joint_pain_left[index]]}
                  key={joint_pain_left + index}
                />
              </td>
            ))}
          </tr>
          <tr>
            <td className="table-row-label">Right</td>
            {jointPains.joint_pain_right.map((joint_pain_right, index) => (
              <td className="table-row-options">
                <DropDownMenu
                  fieldName={joint_pain_right}
                  dropDownChange={dropDownChange}
                  dropDownValues={dropDown03}
                  defvalue={formData[jointPains.joint_pain_right[index]]}
                  key={joint_pain_right + index}
                />
              </td>
            ))}
          </tr>

          <tr>
            <td colSpan="4">
              0 - No pain through active range of motion
              <br /> 1 - No pain through active range only pain on gentle
              overpressure or palpation
              <br /> 2 - Pain through active range
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default JointPainTab;

const DropDownMenu = ({
  fieldName,
  dropDownChange,
  dropDownValues,
  defvalue,
  key,
}) => {
  const dropDownOptions = dropDownValues.map((ddval) => ({
    value: ddval.value,
    label: ddval.name,
  }));

  return (
    <div className="newPatientItem ">
      <Select
        name={fieldName}
        onChange={dropDownChange}
        value={dropDownOptions.find((v) => v.value === parseInt(defvalue))}
        className="newPatientSelect"
        options={dropDownOptions}
      />
    </div>
  );
};

import React from "react";
import Select from "react-select";

const StrengthTab = ({ formData, dropDownChange }) => {
  const dropDown03 = [
    {
      id: 1,
      name: "0",
      value: 0,
    },
    {
      id: 2,
      name: "1",
      value: 1,
    },
    {
      id: 3,
      name: "2",
      value: 2,
    },
    {
      id: 4,
      name: "3",
      value: 3,
    },
    {
      id: 5,
      name: "4",
      value: 4,
    },
  ];

  const strengths = {
    strength_left: [
      "strength_left_elbow",
      "strength_left_knee",
      "strength_left_ankle",
    ],
    strength_right: [
      "strength_right_elbow",
      "strength_right_knee",
      "strength_right_ankle",
    ],
  };

  return (
    <div className="table-responsive w-100" style={{ marginRight: "40px" }}>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col" className="text-center">
              Elbow
            </th>
            <th scope="col" className="text-center">
              Knee
            </th>
            <th scope="col" className="text-center">
              Ankle
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="table-row-label">Left</td>
            {strengths.strength_left.map((strength_left, index) => (
              <td className="table-row-options">
                <DropDownMenu
                  fieldName={strength_left}
                  dropDownChange={dropDownChange}
                  dropDownValues={dropDown03}
                  defvalue={formData[strengths.strength_left[index]]}
                  key={strength_left + index}
                />
              </td>
            ))}
          </tr>
          <tr>
            <td className="table-row-label">Right</td>
            {strengths.strength_right.map((strength_right, index) => (
              <td className="table-row-options">
                <DropDownMenu
                  fieldName={strength_right}
                  dropDownChange={dropDownChange}
                  dropDownValues={dropDown03}
                  defvalue={formData[strengths.strength_right[index]]}
                  key={strength_right + index}
                />
              </td>
            ))}
          </tr>

          <tr>
            <td colSpan="4">
              0 - Holds test position against gravity with maximum resistance
              (gr.5)
              <br />
              1 - Holds test position against gravity with moderate resistance
              (but breaks with maximal resistance) (gr.4)
              <br />
              2 - Holds test position with minimal resistance (gr 3+) or holds
              test position against gravity (gr.3)
              <br />
              3 - Able to partially complete ROM against gravity (gr.3/2+) or
              able to move through ROM gravity eliminated (gr 2-) <br />4 -
              Trace (gr.1) or no muscle contraction (gr.0)
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default StrengthTab;

const DropDownMenu = ({
  fieldName,
  dropDownChange,
  dropDownValues,
  defvalue,
  key,
}) => {
  const dropDownOptions = dropDownValues.map((ddval) => ({
    value: ddval.value,
    label: ddval.name,
  }));

  return (
    <div className="newPatientItem ">
      <Select
        name={fieldName}
        onChange={dropDownChange}
        value={dropDownOptions.find((v) => v.value === parseInt(defvalue))}
        className="newPatientSelect"
        options={dropDownOptions}
        isDisabled={true}
      />
    </div>
  );
};

import React from "react";

import MainContentCard from "../../Home/MainContentCard";

import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import ProductWiseInventoryChild from "./ProductWiseInventoryChild";

const ProductWiseInventory = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="ProductWiseInventory" route="/pwinventory">
        <ProductWiseInventoryChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default ProductWiseInventory;

import { useState, useEffect } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import "./NotificationStyleBrowser.css";
import moment from "moment";
import Card from "react-bootstrap/Card";

const NotificationComponent = ({ notificationsData, loggedInUserDetails }) => {
  const [notifications, setNotifications] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  useEffect(() => {
    setNotifications(notificationsData);
  }, [notificationsData]);

  const reloadNotifications = () => {
    setNotifications(null);
    let userToken = localStorage.getItem("token");
    let notification_url = "";

    if (loggedInUserDetails?.tc_id) {
      //TC Admins Notifications
      notification_url = `${process.env.REACT_APP_API_URL}/notifications/${loggedInUserDetails?.tc_id}/5`;
    } else {
      //State Admin Notifications
      notification_url = `${process.env.REACT_APP_API_URL}/notifications/${loggedInUserDetails?._id}/5`;
    }

    const config = {
      method: "GET",
      url: notification_url,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then((response) => {
        // console.log(response.data.notifications);
        setNotifications(response.data.notifications);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const markAsRead = (id) => {
    let userToken = localStorage.getItem("token");
    let n_url = `${process.env.REACT_APP_API_URL}/notifications/read/${id}`;
    const config = {
      method: "post",
      url: n_url,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { id },
    };

    axios(config)
      .then((response) => {
        // console.log(response.data);
        reloadNotifications();
      })
      .catch(function (error) {
        console.log(error);
      });
    // .finally(() => reloadNotifications());
    console.log("message_id: ", id);
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    // Options for the date format
    const dateOptions = {
      weekday: "long", // "Tuesday"
      year: "numeric", // "2024"
      month: "long", // "April"
      day: "numeric", // "30"
    };
    // Options for the time format
    const timeOptions = {
      hour: "2-digit", // "09"
      minute: "2-digit", // "31"
      second: "2-digit", // "16"
      hour12: true, // AM/PM format
    };
    // Combine date and time with custom formatting
    const formattedDate =
      date.toLocaleDateString("en-US", dateOptions) +
      " at " +
      date.toLocaleTimeString("en-US", timeOptions);

    return formattedDate;
  };

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
    // console.log("Clicked on Notification");
  };

  return (
    <div
      className="align-middle d-none d-sm-inline-block"
      style={{ wordBreak: "break-word", maxWidth: "100%", overflow: "hidden" }}
    >
      {notifications?.map((n, index) => (
        <>
          <div
            key={index}
            className={`p-2 rounded-2 mt-1 ${
              n.isRead
                ? "notification-bg-lg-green notification-g-hover"
                : "notification-bg-lg notification-hover"
            } `} // Using CSS classes
            onClick={() => viewRecord(n)}
          >
            <div
              className="float-start text-uppercase"
              style={{ fontSize: ".8rem" }}
            >
              {formatDate(n?.createdAt)}
            </div>
            <br />
            <div className="notification-separator"></div>
            <div className="text-justify">
              {/* Changed to use the text-justify class */}
              {n.message}
              <div className="float-end">
                {!n.isRead && (
                  <i
                    className="mdi mdi-check-circle-outline read-button"
                    onClick={() => markAsRead(n?._id)}
                  ></i>
                )}
              </div>
            </div>
          </div>
        </>
      ))}

      {/* Modal Popup */}
      <Modal show={openModal} size="lg" centered>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent currentRecord={currentRecord} markAsRead={markAsRead} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

const ModalContent = ({ currentRecord, markAsRead }) => {
  return (
    <div className="flex flex-row justify-content-center">
      <NotificationTable data={currentRecord} markAsRead={markAsRead} />
    </div>
  );
};

export default NotificationComponent;

const NotificationTable = ({ data, markAsRead }) => {
  const [tcenter, setTcenter] = useState(null);
  function formatHumanReadableDate(isoDate) {
    const date = new Date(isoDate);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZoneName: "short",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  }

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");
      const config = {
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/tc/${data?.sender_tcid}`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(response.data.treatment_center.tcenters);
          setTcenter(response.data.treatment_center.tcenters);
        })
        .catch(function (err) {
          //console.log(error.message);
          //errorLogApi(err, "UsersListChild", "/users");
        });
    };
    getDataFromServer();
  }, []);

  return (
    <>
      <Card>
        <Card.Header>
          <i className="fas fa-align-justify" />
          <strong>
            {data.notification_type.replaceAll("_", " ").toUpperCase()}
          </strong>
        </Card.Header>
        <Card.Body>
          <div key="ss" className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              <b>Acceptance Date</b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {formatHumanReadableDate(data.updatedAt)}
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              <b>Message</b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">{data.message}</div>
          </div>
        </Card.Body>
      </Card>
      {tcenter && <TreatmentTable tcenter={tcenter} />}
      {data.notification_type === "inventory_allocated" && <QuickMenu />}
      <div className="w-full mt-3 text-end">
        {!data.isRead && (
          <button
            className="btn btn-danger"
            onClick={() => markAsRead(data?._id)}
          >
            <i className="mdi mdi-check-circle-outline read-button"></i>
            &nbsp; Mark As Read
          </button>
        )}
      </div>
    </>
  );
};

const TreatmentTable = ({ tcenter }) => {
  return (
    <Card>
      <Card.Header>
        <i className="fas fa-align-justify" />
        <strong>Treatment Center Details</strong>
      </Card.Header>
      <Card.Body>
        <CardTableRow rowName="TC Name" rowValue={tcenter?.name_tc} />
        <CardTableRow rowName="Institution" rowValue={tcenter?.institution} />
        <CardTableRow rowName="HDCC No." rowValue={tcenter?.hdcc_no} />
      </Card.Body>
    </Card>
  );
};

const CardTableRow = ({ rowName, rowValue }) => {
  return (
    <div className="row">
      <div className="col-md-6 col-xs-6 divTableCell">
        <b> {rowName}: </b>
      </div>
      <div
        className="col-md-6 col-xs-6 divTableCell"
        style={{ wordWrap: "break-word;" }}
      >
        {rowValue}
      </div>
    </div>
  );
};

const QuickMenu = () => {
  return (
    <Card>
      <Card.Header>
        <i className="fas fa-align-justify" />
        <strong>Menu Navigation</strong>
      </Card.Header>
      <Card.Body>
        <div className="p-6 justify-content-center">
          <div className="flex items-center space-x-4">
            <span className="font-medium">
              <i
                className="mdi mdi-check-circle"
                style={{ color: "green", fontSize: "14px" }}
              ></i>
              Quick Menu &gt;{" "}
              <i
                className="mdi mdi-check-circle"
                style={{ color: "green", fontSize: "14px" }}
              ></i>
              TC Inventory &gt;{" "}
              <i
                className="mdi mdi-check-circle"
                style={{ color: "green", fontSize: "14px" }}
              ></i>
              Add Purchase &gt;{" "}
              <i
                className="mdi mdi-check-circle"
                style={{ color: "green", fontSize: "14px" }}
              ></i>
              Issued Status (Click on Awaited)
            </span>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

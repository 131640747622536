import React, { useState, useMemo } from "react";
import * as XLSX from "xlsx";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";

const ExportToExcel = ({ jsonData }) => {
  let navigate = useNavigate();
  const [selectedFields, setSelectedFields] = useState([]);

  // List of fields to exclude from the user's selection options
  const excludedFields = [
    "id",
    "_id",
    "createdAt",
    "updatedAt",
    "__v",
    "treatment_center",
    "createdBy",
    "flag_patient",
    "tc_state",
    "tc_detail",
    "id_card",
    "temp_patient_id",
    "review_status",
  ];

  // Compute fields that are selectable (not in the excluded list)
  const selectableFields = useMemo(() => {
    return Object.keys(jsonData[0] || {}).filter(
      (field) => !excludedFields.includes(field)
    );
  }, [jsonData]);

  // Handle field checkbox changes
  const handleFieldChange = (event) => {
    const field = event.target.name;
    setSelectedFields((prev) =>
      event.target.checked ? [...prev, field] : prev.filter((f) => f !== field)
    );
  };

  const sortFields = (fields) => {
    const priorityFields = [
      "patientId",
      "patient_name",
      "date_of_birth",
      "diagnosis",
      "fathers_name",
      "mothers_name",
      "gender",
      "weight",
    ]; // Fields to appear first

    //Sort teh fields for selection
    const sortedFields = fields.sort((a, b) => {
      const indexA = priorityFields.indexOf(a);
      const indexB = priorityFields.indexOf(b);

      if (indexA > -1 && indexB > -1) {
        return indexA - indexB; // Both are priority fields, sort among themselves
      } else if (indexA > -1) {
        return -1; // Only A is priority, it should come first
      } else if (indexB > -1) {
        return 1; // Only B is priority, it should come first
      }
      return a.localeCompare(b); // Neither are priority, sort alphabetically
    });
    return sortedFields;
  };

  // Handle Select All checkbox
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedFields(selectableFields);
    } else {
      setSelectedFields([]);
    }
  };

  const formatLabel = (fieldName) => {
    // Replace underscores with spaces
    let formattedLabel = fieldName.replace(/_/g, " ");

    // Capitalize the first word
    formattedLabel = formattedLabel.replace(/^\w/, (c) => c.toUpperCase());

    return formattedLabel;
  };

  //log before download

  const logDownload = () => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/patient/excel/download`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...jsonData },
    };
    axios(config)
      .then(function (response) {
        toast.success("Document downloaded successfully!");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // Generate and download the Excel file with timestamp in filename
  const downloadExcel = () => {
    logDownload();
    const timestamp = new Date().toISOString().replace(/[\-T:\.Z]/g, "");
    const filename = `exported_data_${timestamp}.xlsx`;

    const ws = XLSX.utils.json_to_sheet(
      jsonData.map((item) =>
        selectedFields.reduce(
          (obj, field) => ({ ...obj, [field]: item[field] }),
          {}
        )
      )
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "ExportedData");
    XLSX.writeFile(wb, filename);
  };

  // Check if all selectable fields are selected
  const isAllSelected =
    selectableFields.length > 0 &&
    selectableFields.every((field) => selectedFields.includes(field));

  return (
    <div>
      <h3>Select Fields to Export:</h3>

      <div className="d-flex justify-content-end">
        <Row>
          <Col xs="auto">
            <Button variant="secondary" onClick={() => navigate(-1)}>
              Back
            </Button>
          </Col>
        </Row>
        &nbsp;
        <Row>
          <Col xs="auto">
            <Button onClick={downloadExcel} disabled={!selectedFields.length}>
              Download Excel
            </Button>
          </Col>
        </Row>
      </div>
      <div>
        <div>
          <label>
            <input
              type="checkbox"
              checked={isAllSelected}
              onChange={handleSelectAll}
            />
            &nbsp; Select All
          </label>
        </div>
        <hr />
        <div>
          <Row>
            {sortFields(selectableFields).map((field, index) => (
              <Col key={field} xs={12} md={6} lg={6}>
                <Form.Group controlId={`formCheckbox-${field}`}>
                  <Form.Check
                    type="checkbox"
                    label={formatLabel(field)}
                    name={field}
                    onChange={handleFieldChange}
                    checked={selectedFields.includes(field)}
                  />
                </Form.Group>
              </Col>
            ))}
          </Row>
        </div>
      </div>
      {/* <Button onClick={downloadExcel} disabled={!selectedFields.length}>
        Download Excel
      </Button> */}
    </div>
  );
};

export default ExportToExcel;

import React from "react";

import "./UpdateUser.css";
import UpdateUserChild from "./UpdateUserChild";
import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

const UpdateUser = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="UpdateUserChild" route="/users/update-user">
        <UpdateUserChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default UpdateUser;

import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const ViralMarkersTab = ({ emrRecordId, emrRecord, setEmrRecord }) => {
  const [formData, setFormData] = useState({
    hiv_rna: null,
    hiv_ab: null,
    hepatitis_b: null,
    hepatitis_c: null,
    hcv_rna: null,
    hcv_ab: null,
  });

  useEffect(() => {
    setFormData({
      ...emrRecord,
    });
  }, [emrRecord]);

  const vmarkerOptions = [
    { value: "positive", label: "Positive" },
    { value: "negative", label: "Negative" },
  ];

  const handleChange = ({ currentTarget: input }) => {
    //alert([input.name]);
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const updateEmr = () => {
    // e.preventDefault();
    //changeTab(1);

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/emr/update/${emrRecordId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, viral_markers: true },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        localStorage.setItem(
          `${emrRecordId}-viralmarkers`,
          JSON.stringify(formData)
        );
        toast.success("Viral markers sheet updated Successfully.");
        setEmrRecord(response.data.emr);
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error.response.data));
      });
  };

  return (
    <div className="newPatientFormOnly">
      <div className="w-100 mt-2 text-end pe-5">
        <Button
          variant="primary"
          size="large"
          onClick={updateEmr}
          className="btn btn-primary float-right"
        >
          Save
        </Button>
      </div>
      <div className="newPatientItem">
        <label htmlFor="hiv_rna">HIV RNA</label>
        <InputGroup className="mb-3">
          <Form.Control
            aria-label="hiv_rna"
            type="number"
            name="hiv_rna"
            onChange={handleChange}
            value={formData.hiv_rna}
          />
          <InputGroup.Text id="basic-addon2">copies/mL</InputGroup.Text>
        </InputGroup>
      </div>
      <div className="newPatientItem">
        <label htmlFor="hcv_rna">HCV RNA</label>
        <InputGroup className="mb-3">
          <Form.Control
            aria-label="hcv_rna"
            type="number"
            name="hcv_rna"
            onChange={handleChange}
            value={formData.hcv_rna}
          />
          <InputGroup.Text id="basic-addon2">copies/mL</InputGroup.Text>
        </InputGroup>
      </div>
      <div className="newPatientItem w-15">
        <label htmlFor="hiv_ab">HIV AB</label>
        <Select
          name="hiv_ab"
          onChange={
            //dropDownChange
            (selected, dropdown) => {
              //console.log(selected);
              setFormData({
                ...formData,
                hiv_ab: selected.value,
              });
            }
          }
          className="newPatientSelect"
          options={vmarkerOptions}
          value={
            formData.hiv_ab &&
            vmarkerOptions?.find((option) => option.value === formData.hiv_ab)
          }
        />
      </div>
      <div className="newPatientItem w-15">
        <label htmlFor="hepatitis_b">Hepatitis B</label>
        <Select
          name="hepatitis_b"
          onChange={
            //dropDownChange
            (selected, dropdown) => {
              //console.log(selected);
              setFormData({
                ...formData,
                hepatitis_b: selected.value,
              });
            }
          }
          className="newPatientSelect"
          options={vmarkerOptions}
          value={
            formData.hepatitis_b &&
            vmarkerOptions?.find(
              (option) => option.value === formData.hepatitis_b
            )
          }
        />
      </div>
      <div className="newPatientItem w-15">
        <label htmlFor="role">Hepatitis C</label>
        <Select
          name="hepatitis_c"
          onChange={
            //dropDownChange
            (selected, dropdown) => {
              //console.log(selected);
              setFormData({
                ...formData,
                hepatitis_c: selected.value,
              });
            }
          }
          className="newPatientSelect"
          options={vmarkerOptions}
          value={
            formData.hepatitis_c &&
            vmarkerOptions?.find(
              (option) => option.value === formData.hepatitis_c
            )
          }
        />
      </div>
      <div className="newPatientItem w-15">
        <label htmlFor="role">HCV AB</label>
        <Select
          name="hcv_ab"
          onChange={
            //dropDownChange
            (selected, dropdown) => {
              //console.log(selected);
              setFormData({
                ...formData,
                hcv_ab: selected.value,
              });
            }
          }
          className="newPatientSelect"
          options={vmarkerOptions}
          value={
            formData.hcv_ab &&
            vmarkerOptions?.find((option) => option.value === formData.hcv_ab)
          }
        />
      </div>
    </div>
  );
};

export default ViralMarkersTab;

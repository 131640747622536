import React from "react";
import Select from "react-select";

const WFHAxialDeformityTab = ({
  dropDownChange,
  formData,
  handleChangeValue,
  titleText,
  fieldName,
}) => {
  const dropDown01 = [
    {
      id: 1,
      name: "0",
      value: 0,
    },
    {
      id: 2,
      name: "1",
      value: 1,
    },
    {
      id: 3,
      name: "2",
      value: 2,
    },
  ];

  const axialDeformityKneeAndAnkle = {
    ad_knee_left: [
      "ankle_left_ad_knee",
      "elbow_left_ad_knee",
      "knee_left_ad_knee",
    ],
    ad_knee_right: [
      "ankle_right_ad_knee",
      "elbow_right_ad_knee",
      "knee_right_ad_knee",
    ],
    ad_ankle_left: [
      "ankle_left_ad_ankle",
      "elbow_left_ad_ankle",
      "knee_left_ad_ankle",
    ],
    ad_ankle_right: [
      "ankle_right_ad_ankle",
      "elbow_right_ad_ankle",
      "knee_right_ad_ankle",
    ],
  };

  return (
    <div className="table-responsive w-100" style={{ marginRight: "40px" }}>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col" className="text-center">
              Ankle
            </th>
            <th scope="col" className="text-center">
              Elbow
            </th>
            <th scope="col" className="text-center">
              Knee
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colSpan={4}>AXIAL DEFORMITY (KNEE)</th>
          </tr>
          <tr>
            <td className="table-row-label">Left</td>
            {axialDeformityKneeAndAnkle.ad_knee_left.map((ad_left, index) => (
              <td className="table-row-options">
                <DropDownMenu
                  fieldName={ad_left}
                  handleChangeValue={handleChangeValue}
                  dropDownChange={dropDownChange}
                  defvalue={
                    formData[axialDeformityKneeAndAnkle.ad_knee_left[index]]
                  }
                  dropDownValues={dropDown01}
                />
              </td>
            ))}
          </tr>
          <tr>
            <td className="table-row-label">Right</td>
            {axialDeformityKneeAndAnkle.ad_knee_right.map((ad_right, index) => (
              <td className="table-row-options">
                <DropDownMenu
                  fieldName={ad_right}
                  handleChangeValue={handleChangeValue}
                  dropDownChange={dropDownChange}
                  defvalue={
                    formData[axialDeformityKneeAndAnkle.ad_knee_right[index]]
                  }
                  dropDownValues={dropDown01}
                />
              </td>
            ))}
          </tr>
          <tr>
            <td colSpan="4">
              0: 0-7° valgus
              <br />
              1: 8-15° valgus or upto 5°varus
              <br />
              2: {">"}15° valgus or {">"}5° varus
            </td>
          </tr>
          <tr>
            <th colSpan="4">AXIAL DEFORMITY (ANKLE)</th>
          </tr>
          <tr>
            <td className="table-row-label">Left</td>
            {axialDeformityKneeAndAnkle.ad_ankle_left.map((ada_left, index) => (
              <td className="table-row-options">
                <DropDownMenu
                  fieldName={ada_left}
                  handleChangeValue={handleChangeValue}
                  dropDownChange={dropDownChange}
                  defvalue={
                    formData[axialDeformityKneeAndAnkle.ad_ankle_left[index]]
                  }
                  dropDownValues={dropDown01}
                />
              </td>
            ))}
          </tr>
          <tr>
            <td className="table-row-label">Right</td>
            {axialDeformityKneeAndAnkle.ad_ankle_right.map(
              (ada_right, index) => (
                <td className="table-row-options">
                  <DropDownMenu
                    fieldName={ada_right}
                    handleChangeValue={handleChangeValue}
                    dropDownChange={dropDownChange}
                    defvalue={
                      formData[axialDeformityKneeAndAnkle.ad_ankle_right[index]]
                    }
                    dropDownValues={dropDown01}
                  />
                </td>
              )
            )}
          </tr>
          <tr>
            <td colSpan="4">
              0: 0-7° valgus
              <br />
              1: 8-15° valgus or upto 5°varus
              <br />
              2: {">"}15° valgus or {">"}5° varus
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default WFHAxialDeformityTab;

const DropDownMenu = ({
  fieldName,
  dropDownChange,
  dropDownValues,
  defvalue,
}) => {
  const dropDownOptions = dropDownValues.map((ddval) => ({
    value: ddval.value,
    label: ddval.name,
  }));

  return (
    <div className="newPatientItem">
      <Select
        name={fieldName}
        onChange={dropDownChange}
        value={dropDownOptions.find((v) => v.value === parseInt(defvalue))}
        className="newPatientSelect"
        options={dropDownOptions}
        isDisabled={true}
      />
    </div>
  );
};

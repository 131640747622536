import React from "react";
import { NavLink } from "react-router-dom";

const TCInventoryMenu = ({ setExpandedMenu, expandedMenu }) => {
  return (
    <li className="side-nav-item">
      <div
        onClick={() =>
          setExpandedMenu({
            ...expandedMenu,
            inventory: !expandedMenu.inventory,
          })
        }
        style={{ cursor: "pointer" }}
        className="side-nav-link"
      >
        <i className="mdi mdi-list-status" />
        <span> TC Inventory </span>
        {!expandedMenu.inventory && <span className="menu-arrow" />}
        {expandedMenu.inventory && (
          <span className="menu-arrow" style={{ transform: "rotate(90deg)" }} />
        )}
      </div>
      <div
        className={`${expandedMenu.inventory ? "collapse show" : "collapse"}`}
        id="sidebarTCInvPages"
      >
        <ul className="side-nav-second-level">
          <li>
            <NavLink to="/pwtcinventory">Product-wise Inventory</NavLink>
          </li>
          <li>
            <NavLink to="/tc-inventory">Current Inventory</NavLink>
          </li>
          <li>
            <NavLink to="/tc-settlement-history">TC Settlement History</NavLink>
          </li>
          <li>
            <NavLink to="/list-purchase-tc">Add Purchase</NavLink>
          </li>
          <li>
            <NavLink to="/rellocation/list">ReAllocation Register</NavLink>
          </li>
          <li>
            <NavLink to="/dose-history-tc">TC Dosing History</NavLink>
          </li>
        </ul>
      </div>
    </li>
  );
};

export default TCInventoryMenu;

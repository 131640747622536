import React, { useState, useEffect } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import MainContent from "../Home/MainContent";
//import DashboardHemophilia from "./DashboardHemophilia";
//import DashboardThalassemia from "./DashboardThalassemia";
//import DashboardStateInventory from "./DashboardStateInventory";
import HelpPopup from "../ApplicationHelp/HelpPopup";

const DashboardHemophilia = React.lazy(() => import("./DashboardHemophilia"));
const DashboardThalassemia = React.lazy(() => import("./DashboardThalassemia"));
const DashboardStateInventory = React.lazy(() =>
  import("./DashboardStateInventory")
);

const DashboardState = () => {
  const [tabState, setTabState] = useState(true);
  const [value, setValue] = useState(0);
  const [loggedIn, setLoggedIn] = useState(false);
  const [showHelpPopup, setShowHelpPopup] = useState(true);
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ span: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleCloseHelpPopup = () => {
    setShowHelpPopup(false);
  };

  useEffect(() => {
    const preloadComponent = async () => {
      if (value === 0) await import("./DashboardHemophilia");
      if (value === 1) await import("./DashboardThalassemia");
      if (value === 2) await import("./DashboardStateInventory");
    };
    preloadComponent();
  }, [value]);

  return (
    <MainContent>
      <HelpPopup showPopup={showHelpPopup} onClose={handleCloseHelpPopup} />
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{ maxWidth: { xs: 420, sm: 780 }, bgcolor: "background.paper" }}
        >
          <Tabs
            style={{ backgroundColor: "#f5f5f2" }}
            value={value}
            onChange={handleChangeTabs}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Hemophilia" value={0} />
            <Tab label="Thalassemia" value={1} />
            <Tab label="Inventory" value={2} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <React.Suspense fallback={<div>Loading...</div>}>
            <DashboardHemophilia />
          </React.Suspense>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <React.Suspense fallback={<div>Loading...</div>}>
            <DashboardThalassemia />
          </React.Suspense>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <React.Suspense fallback={<div>Loading...</div>}>
            <DashboardStateInventory />
          </React.Suspense>
        </TabPanel>
      </Box>
    </MainContent>
  );
};

export default DashboardState;

import React from "react";
import { useLocation } from "react-router-dom";
import MainContentCard from "../../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../../components/shared/ErrorBoundaryRoutes";
import ViewDoseForPatientChild from "./ViewDoseForPatientChild";

const ViewDoseForPatient = () => {
  const { state } = useLocation();
  let visit = state;
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="ViewDoseForPatient" route="/view-dose">
        <ViewDoseForPatientChild
          visit={state.dose_record.visit_record}
          patientRecord={state.dose_record.patient_record}
          doseRecord={state.dose_record}
        />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default ViewDoseForPatient;

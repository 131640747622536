import React from "react";
import { NavLink } from "react-router-dom";

const StateInventoryMenu = ({ setExpandedMenu, expandedMenu }) => {
  return (
    <li className="side-nav-item">
      <div
        onClick={() =>
          setExpandedMenu({
            ...expandedMenu,
            stateinventory: !expandedMenu.stateinventory,
          })
        }
        style={{ cursor: "pointer" }}
        className="side-nav-link"
      >
        <i className="mdi mdi-list-status" />
        <span>State Inventory </span>
        {!expandedMenu.stateinventory && <span className="menu-arrow" />}
        {expandedMenu.stateinventory && (
          <span className="menu-arrow" style={{ transform: "rotate(90deg)" }} />
        )}
      </div>
      <div
        className={`${
          expandedMenu.stateinventory ? "collapse show" : "collapse"
        }`}
        id="sidebarInvPages"
      >
        <ul className="side-nav-second-level">
          <li>
            <NavLink to="/pwinventory">Product-wise Inventory</NavLink>
          </li>
          <li>
            <NavLink to="/state-inventory">Current Inventory</NavLink>
          </li>
          <li>
            <NavLink to="/tcwise/inventory/status">TC Wise Inventory</NavLink>
          </li>
          <li>
            <NavLink to="/tcwise/inventory/download">
              <span className="badge bg-danger text-light float-end">New</span>
              All TC Inventory
            </NavLink>
          </li>
          <li>
            <NavLink to="/settlement-history">Settlement History</NavLink>
          </li>
          <li>
            <NavLink to="/transaction-history-state">
              Transaction History
            </NavLink>
          </li>
          <li>
            <NavLink to="/list-purchases">Purchase History</NavLink>
          </li>
          <li>
            <NavLink to="/list-sales">Allocation/Issue History</NavLink>
          </li>
          <li>
            <NavLink to="/add-purchase">
              <i className="mdi mdi-import"></i>&nbsp;Add Purchase/Product
            </NavLink>
          </li>
          <li>
            <NavLink to="/pwinventory">Allocate Drug to TC</NavLink>
          </li>
          <li>
            <NavLink to="/dosing-history">Patient Dosing History</NavLink>
          </li>
        </ul>
      </div>
    </li>
  );
};

export default StateInventoryMenu;

import React from "react";
import BleedingChart from "./BleedingChart";

const ClinicalDetails = ({ title, pRecord }) => {
  return (
    <div className="newPatientFormOnly ">
      <fieldset className="border p-2 w-100">
        <legend className="float-none w-auto p-2 fs-5">{title}</legend>
        <div className="newPatientFormOnly visit">
          {/* <BleedingChart data={"test"} /> */}
          {JSON.stringify(pRecord?.site_of_bleeds)}
        </div>
      </fieldset>
    </div>
  );
};

export default ClinicalDetails;

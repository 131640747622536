import React, { useState, Suspense } from "react";
import Chart from "react-apexcharts";

import PatientRegistrationChart from "./PatientRegistrationChart";
import TCDiagnosisCount from "./TCDiagnosisCount";

const DashboardReportComponent = ({ tcData }) => {
  const [donutData, setDonutData] = useState({
    options: {
      labels: ["Mild(5-40%)", "Moderate(1-5%)", "Severe (less than 1%)"],
      legend: {
        position: "bottom",
      },
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: "Hemophilia A",
        data: [33, 52, 123],
      },
      {
        name: "Hemophilia A",
        data: [247, 108, 8],
      },
      {
        name: "Hem A with Inhib",
        data: [239, 82, 207],
      },
      {
        name: "Hem B with Inhib",
        data: [43, 109, 75],
      },
    ],
  });
  const [educationData, setEducationData] = useState({
    options: {
      labels: [
        "illiterate",
        "Upto Primary",
        "Middle School",
        "Sr Secondary",
        "Uni Graduate",
        "PG",
        "Professional",
      ],
      legend: {
        position: "bottom",
      },
    },
    series: [144, 132, 155, 80, 65, 40, 90],
  });
  const [polarData, setPolarData] = useState({
    series: [54, 63, 81, 27, 45, 40, 82],
    options: {
      labels: [
        "Acute Bleed",
        "Infection",
        "Surgery",
        "Seq Rltd Hemophilia",
        "Regular Followup",
        "Counselling",
        "Registration",
      ],
      legend: {
        position: "bottom",
      },
      chart: {
        type: "polarArea",
      },
      stroke: {
        colors: ["#fff"],
      },
      fill: {
        opacity: 0.8,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });
  const [scatterData, setScatterData] = useState({
    series: [
      {
        name: "Hemophilia A",
        data: [
          [16, 15.4],
          [21, 12],
          [25, 13],
          [19, 20],
          [10, 10],
          [13, 33.2],
          [10, 27.4],
          [10, 10],
          [10, 18.2],
          [16, 40],
          [16, 12.8],
          [13, 40.3],
          [13, 10],
          [29, 30],
          [27, 25.3],
          [16, 60],
          [13, 43.7],
          [10, 45.2],
          [16, 16.5],
          [10, 23],
          [24, 37.1],
        ],
      },
      {
        name: "Hemophilia B",
        data: [
          [17, 40],
          [12, 12.8],
          [39, 30],
          [17, 25.3],
          [46, 60],
          [23, 43.7],
          [40, 45.2],
          [16, 16.5],
          [10, 23],
          [34, 37.1],
          [56, 15.4],
          [31, 12],
          [13, 33.2],
          [50, 27.4],
          [13, 10],
          [16, 18.2],
          [28, 40.3],
          [32, 10],
          [31, 13],
          [16, 20],
          [15, 10],
        ],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "scatter",
        zoom: {
          enabled: true,
          type: "xy",
        },
      },
      xaxis: {
        tickAmount: 10,
        // labels: {
        //   formatter: function (val) {
        //     return parseFloat(val).toFixed(1);
        //   },
        // },
      },
      yaxis: {
        tickAmount: 7,
      },
    },
  });

  const [economicStatus, setEconomicStatus] = useState({
    options: {
      chart: {
        type: "area",
        height: 350,
        stacked: true,
        events: {
          selection: function (chart, e) {
            console.log(new Date(e.xaxis.min));
          },
        },
      },
      colors: ["#a83232", "#a87732", "#6fa832", "#4ea832"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      fill: {
        type: "gradient",
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.8,
        },
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
      },
      xaxis: {
        categories: [
          "Hem A",
          "Hem B",
          "Hem A with Inhib",
          "Hem B with Inhib",
          "vWd",
        ],
      },
    },
    series: [
      {
        name: "Red BPL",
        data: [283, 181, 105, 256, 123],
      },
      {
        name: "BPL",
        data: [283, 181, 105, 256, 96],
      },
      {
        name: "2-5 Lakhs",
        data: [283, 181, 105, 256, 23],
      },
      {
        name: "More than 5 Lakhs",
        data: [283, 181, 105, 256, 200],
      },
    ],
  });
  const [jointBleeds, setJointBleeds] = useState({
    options: {
      chart: {
        type: "bar",
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
      },
      xaxis: {
        categories: ["Ankle", "Knee", "Hip", "Wrist", "Elbow", "Shoulder"],
      },
    },
    series: [
      {
        name: "Hemophilia A",
        data: [283, 181, 54, 256, 123, 60],
      },
      {
        name: "Hemophilia B",
        data: [123, 18, 35, 156, 96, 181],
      },
      {
        name: "Hem A With Inhib",
        data: [83, 65, 59, 26, 23, 4],
      },
      {
        name: "Hem B With Inhib",
        data: [201, 81, 77, 56, 200, 30],
      },
    ],
  });
  const [jointScores, setJointScores] = useState({
    options: {
      chart: {
        type: "bar",
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
      },
      xaxis: {
        categories: ["HJHS", "FISH", "WFH", "PETTERSON", "DENVER"],
      },
    },
    series: [
      {
        name: "Hemophilia A",
        data: [133, 176, 9, 124, 6],
      },
      {
        name: "Hemophilia B",
        data: [19, 210, 106, 24, 15],
      },
      {
        name: "Hem A With Inhib",
        data: [193, 89, 299, 165, 165],
      },
      {
        name: "Hem B With Inhib",
        data: [27, 247, 285, 56, 138],
      },
    ],
  });
  const [otherBleeds, setOtherBleeds] = useState({
    options: {
      chart: {
        type: "bar",
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
      },
      xaxis: {
        categories: [
          "Muscles",
          "Gum",
          "Nose",
          "Skin",
          "Haematuria (Renal)",
          "Intracranial",
          "Eye",
          "Iliopsoas",
          "Throat and Neck",
          "Deep Laceration",
          "Gastrointestinal",
        ],
      },
    },
    series: [
      {
        name: "Hemophilia A",
        data: [93, 82, 94, 71, 29, 38, 72, 36, 24, 74, 47],
      },
      {
        name: "Hemophilia B",
        data: [82, 37, 1, 6, 16, 6, 48, 64, 26, 25, 31],
      },
      {
        name: "Hem A With Inhib",
        data: [30, 57, 79, 57, 73, 90, 99, 40, 96, 22, 94],
      },
      {
        name: "Hem B With Inhib",
        data: [80, 49, 100, 22, 50, 66, 94, 18, 56, 45, 83],
      },
    ],
  });
  const [cComplications, setcComplicationss] = useState({
    options: {
      chart: {
        type: "bar",
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
      },
      xaxis: {
        categories: [
          "Musculoskeletal Complication",
          "Chronic Haemophilic Arthropathy",
          "Contractures",
          "Pseudotumor Formation",
          "Pseudotumor Formation",
          "Clinical Suspicion for Presence of Inhibitors",
          "Transfusion Related Complications",
          "Documental History of Inhibitors",
        ],
      },
    },
    series: [
      {
        name: "Hemophilia A",
        data: [93, 82, 94, 71, 29, 38, 72, 36],
      },
      {
        name: "Hemophilia B",
        data: [82, 37, 1, 6, 16, 6, 48, 64],
      },
      {
        name: "Hem A With Inhib",
        data: [30, 57, 79, 57, 73, 90, 99, 40],
      },
      {
        name: "Hem B With Inhib",
        data: [80, 49, 100, 22, 50, 66, 94, 18, 56],
      },
    ],
  });
  const [chartOptions, setChartOptions] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: [
          "Batala",
          "Faridkot",
          "Bhatinda",
          "Chandigarh",
          "Amritsar",
          "Firozpur",
          "Gurdaspur",
          "Barnala",
          "Dasua",
        ],
      },
    },
    options4: {
      chart: {
        id: "basic-bar",
      },

      xaxis: {
        categories: ["HA", "HAwI", "HBwI"],
      },
    },
    series: [
      {
        name: "Hemophilia A",
        data: [30, 40, 45, 50, 49, 60, 70, 21],
      },
      {
        name: "Hemophlia B",
        data: [40, 30, 15, 20, 69, 60, 9, 30],
      },
      {
        name: "Hem A With Inhibitors",
        data: [60, 40, 35, 90, 45, 3, 39, 10],
      },
      {
        name: "Hem B With Inhibitors",
        data: [10, 30, 25, 65, 29, 90, 10, 31],
      },
      {
        name: "vWd",
        data: [4, 3, 8, 12, 6, 3, 9, 20],
      },
      {
        name: "Acquired Hemophilia",
        data: [10, 21, 35, 78, 19, 20, 19, 30],
      },
    ],
    optionsAge: {
      chart: {
        id: "basic-bar",
      },
      legend: {
        position: "bottom",
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        name: "Hem B With Inhibitors",
        categories: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90],
      },
    },
    seriesAge: [
      {
        name: "Hemophilia A",
        data: [33, 41, 35, 65, 45, 33, 15, 10, 6, 3],
      },
      {
        name: "Hemophilia B ",
        data: [45, 33, 31, 56, 53, 25, 13, 8, 9, 1],
      },
      {
        name: "Hem A w Inhibitors",
        data: [25, 13, 43, 15, 63, 15, 23, 4, 6, 2],
      },
      {
        name: "Hem B w Inhibitors",
        data: [23, 11, 25, 15, 5, 13, 10, 2, 3, 1],
      },
    ],
    series3: [
      {
        data: [
          {
            x: new Date("2018-02-12").getTime(),
            y: 76,
          },
          {
            x: new Date("2018-02-12").getTime(),
            y: 76,
          },
        ],
      },
    ],
    xaxis: {
      type: "datetime",
    },
    series4: [
      {
        name: "All Bleeds",
        data: [6.4, 3.8, 5.8],
      },
      {
        name: "Spontaneous Bleeds",
        data: [2.1, 1.6, 2.5],
      },
      {
        name: "Joint Bleeds",
        data: [5.2, 2.7, 3.8],
      },
    ],
  });

  return (
    <>
      {/* <div className="row" style={{ marginTop: "25px" }}>
        <Suspense fallback={<p>Loading...</p>}>
          <PatientRegistrationChart chartOptions={chartOptions} />
        </Suspense>
      </div> */}
      <div className="row">
        <div className="col-xl-12 col-lg-11">
          <div className="card card-h-100">
            <TCDiagnosisCount jsonData={tcData} />
          </div>
        </div>
        <div className="col-xl-6">
          <div className="card card-h-100">
            <div className="card-body">
              <h4 className="header-title mb-3">ABR (Annual Bleed Rate)</h4>
              <div dir="ltra">
                <div
                  id="sessions-overview"
                  className="apex-charts mt-3"
                  data-colors="#0acf97"
                />
                <Chart
                  options={chartOptions.options4}
                  series={chartOptions.series4}
                  type="bar"
                  width="100%"
                  height="380"
                />
              </div>
            </div>{" "}
          </div>
        </div>

        <div className="col-xl-6">
          <div className="card card-h-100">
            <div className="card-body">
              <h4 className="header-title mb-3">Severity Report</h4>
              <div dir="ltra">
                <div
                  id="sessions-overview"
                  className="apex-charts mt-3"
                  data-colors="#0acf97"
                />
                <Chart
                  options={donutData.options}
                  series={donutData.series}
                  type="bar"
                  width="100%"
                  height="380"
                />
              </div>
            </div>{" "}
            {/* end card-body*/}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6">
          <div className="card card-h-100">
            <div className="card-body">
              <h4 className="header-title mb-3">Joint Bleed</h4>
              <div dir="ltra">
                <div
                  id="sessions-overview"
                  className="apex-charts mt-3"
                  data-colors="#0acf97"
                />
                <Chart
                  options={jointBleeds.options}
                  series={jointBleeds.series}
                  type="bar"
                  width="100%"
                  height="380"
                />
              </div>
            </div>{" "}
            {/* end card-body*/}
          </div>
        </div>

        <div className="col-xl-6">
          <div className="card card-h-100">
            <div className="card-body">
              <h4 className="header-title mb-3">Other Bleeds</h4>
              <div dir="ltra">
                <div
                  id="sessions-overview"
                  className="apex-charts mt-3"
                  data-colors="#0acf97"
                />
                <Chart
                  options={otherBleeds.options}
                  series={otherBleeds.series}
                  type="bar"
                  width="100%"
                  height="380"
                />
              </div>
            </div>{" "}
            {/* end card-body*/}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6">
          <div className="card card-h-100">
            <div className="card-body">
              <h4 className="header-title mb-3">Joint Scores</h4>
              <div dir="ltra">
                <div
                  id="sessions-overview"
                  className="apex-charts mt-3"
                  data-colors="#0acf97"
                />
                <Chart
                  options={jointScores.options}
                  series={jointScores.series}
                  type="bar"
                  width="100%"
                  height="380"
                />
              </div>
            </div>{" "}
            {/* end card-body*/}
          </div>
        </div>

        <div className="col-xl-6">
          <div className="card card-h-100">
            <div className="card-body">
              <h4 className="header-title mb-3">Chronic Complications</h4>
              <div dir="ltra">
                <div
                  id="sessions-overview"
                  className="apex-charts mt-3"
                  data-colors="#0acf97"
                />
                <Chart
                  options={cComplications.options}
                  series={cComplications.series}
                  type="bar"
                  width="100%"
                  height="380"
                />
              </div>
            </div>{" "}
            {/* end card-body*/}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6">
          <div className="card card-h-100">
            <div className="card-body">
              <h4 className="header-title mb-3">Weight Wise</h4>
              <div dir="ltra">
                <div
                  id="sessions-overview"
                  className="apex-charts mt-3"
                  data-colors="#0acf97"
                />
                <Chart
                  options={scatterData.options}
                  series={scatterData.series}
                  type="scatter"
                  width="100%"
                  height="380"
                />
              </div>
            </div>{" "}
            {/* end card-body*/}
          </div>
        </div>

        <div className="col-xl-6">
          <div className="card card-h-100">
            <div className="card-body">
              <h4 className="header-title mb-3">Age Distribution</h4>
              <div dir="ltra">
                <div
                  id="sessions-overview"
                  className="apex-charts mt-3"
                  data-colors="#0acf97"
                />
                <Chart
                  options={chartOptions.optionsAge}
                  series={chartOptions.seriesAge}
                  type="bar"
                  width="100%"
                  height="380"
                />
              </div>
            </div>{" "}
            {/* end card-body*/}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6">
          <div className="card card-h-100">
            <div className="card-body">
              <h4 className="header-title mb-3">Education Wise</h4>
              <div dir="ltra">
                <div
                  id="sessions-overview"
                  className="apex-charts mt-3"
                  data-colors="#0acf97"
                />
                <Chart
                  options={educationData.options}
                  series={educationData.series}
                  type="donut"
                  width="100%"
                  height="380"
                />
              </div>
            </div>{" "}
            {/* end card-body*/}
          </div>
        </div>

        <div className="col-xl-6">
          <div className="card card-h-100">
            <div className="card-body">
              <h4 className="header-title mb-3">Visit Reason</h4>
              <div dir="ltra">
                <div
                  id="sessions-overview"
                  className="apex-charts mt-3"
                  data-colors="#0acf97"
                />
                <Chart
                  options={polarData.options}
                  series={polarData.series}
                  type="polarArea"
                  width="100%"
                  height="380"
                />
              </div>
            </div>{" "}
            {/* end card-body*/}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6">
          <div className="card card-h-100">
            <div className="card-body">
              <h4 className="header-title mb-3">Economic Status</h4>
              <div dir="ltra">
                <div
                  id="sessions-overview"
                  className="apex-charts mt-3"
                  data-colors="#0acf97"
                />
                <Chart
                  options={economicStatus.options}
                  series={economicStatus.series}
                  type="area"
                  width="100%"
                  height="380"
                />
              </div>
            </div>{" "}
            {/* end card-body*/}
          </div>
        </div>

        <div className="col-xl-6">
          <div className="card card-h-100">
            <div className="card-body">
              <h4 className="header-title mb-3">Visit Reason</h4>
              <div dir="ltra">
                <div
                  id="sessions-overview"
                  className="apex-charts mt-3"
                  data-colors="#0acf97"
                />
                <Chart
                  options={chartOptions.options}
                  series={chartOptions.series4}
                  type="line"
                  width="100%"
                  height="380"
                />
              </div>
            </div>{" "}
            {/* end card-body*/}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardReportComponent;

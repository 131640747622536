import React, { useEffect, useState, useContext, useRef } from "react";

import { useNavigate } from "react-router-dom";
import axios from "axios";

import "antd/dist/antd.min.css";
import { Modal } from "react-bootstrap";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import Select, { components } from "react-select";
//Global State import
import { useAtom } from "jotai";
import { gbState } from "../../../../components/shared/GlobalState";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

//Context import
import AuthContext from "../../../../components/shared/Context";
import toast from "react-hot-toast";

const TCSettlementHistoryChild = () => {
  let navigate = useNavigate();
  const { loggedInUserDetails } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [settlementOptions, setSettlementOptions] = useState({});
  const [dropDownSettlement, setDropDownSettlement] = useState(null);
  const searchRef = useRef();
  const ddref = useRef();
  //Error Logging Service
  const { dateFormatter, errorLogApi } = useAtom(gbState);

  const getDataFromServer = () => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    const bodyParameters = {
      key: "value",
    };
    let urlLink = `${process.env.REACT_APP_API_URL}/tcs/stocksettle/`;
    axios
      .get(urlLink, config)
      .then((res) => {
        const oldData = res.data.tc_settled_stock;
        //console.log(oldData);
        const updatedData = oldData.map((data) => {
          //   console.log(data.purchase_inventory.product_id.products);
          return {
            ...data,
            id: data._id,
            tc_settlement_id: data.tc_settled_stock.tc_settlement_id,
            tc_settlement_date: data.tc_settled_stock.tc_settlement_date,
            product_name:
              data.tc_settled_stock.product_id.products.product_name,
            item_code: data.tc_settled_stock.item_code,
            opening_stock:
              data.tc_settled_stock.opening_stock +
              " " +
              data.tc_settled_stock.product_id.products.storage_unit,
            purchase_qty:
              data.tc_settled_stock.purchase_qty +
              " " +
              data.tc_settled_stock.product_id.products.storage_unit,
            sale_qty:
              data.tc_settled_stock.sale_qty +
              " " +
              data.tc_settled_stock.product_id.products.storage_unit,
            closing_stock:
              data.tc_settled_stock.closing_stock +
              " " +
              data.tc_settled_stock.product_id.products.storage_unit,
            state: data.tc_settled_stock.state,
            updatedAt: data.updatedAt,
          };
        });
        //console.log(updatedData);
        setData(updatedData);
        setUnfilteredData(updatedData);
        // console.log(res);
      })
      .catch((err) => {
        errorLogApi(err, "ListStateInventoryChild", urlLink);
      });
  };

  useEffect(() => {
    getDataFromServer();
    // getProducts();
  }, []);

  const useStyles = makeStyles(() => ({
    multiLineCell: {
      display: "flex",
      wordBreak: "keep-all",
      wordWrap: "break-word",
      overflowWrap: "break-word",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      lineHeight: "1.2rem",
      height: "auto",
    },
  }));

  useEffect(() => {
    // Create an array of unique settlements based on settlement_id
    const uniqueSettlements = data.filter(
      (settlement, index, self) =>
        index ===
        self.findIndex(
          (s) => s.tc_settlement_id === settlement.tc_settlement_id
        )
    );

    // Map the unique settlements to the desired format
    const settlementOptions = uniqueSettlements.map((settlement) => ({
      value: settlement.tc_settlement_id,
      label: `${settlement.tc_settlement_id}\n${
        settlement?.tc_settlement_date?.split("T")[0]
      }`,
    }));

    setSettlementOptions(settlementOptions);
  }, [unfilteredData]);

  const calculateDaysLeft = (date) => {
    // Convert the given date string to a Date object
    const givenDate = new Date(date);

    // Calculate the difference between the current date and the given date in milliseconds
    const timeDiff = givenDate.getTime() - new Date().getTime();

    // Convert milliseconds to days and round down to the nearest integer
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    // Update the state with the number of days left
    //setDaysLeft(days);
    return days;
  };

  const MultiLineCellRenderer = (params) => {
    const classes = useStyles();
    return (
      <div className={classes.multiLineCell}>
        <span id="content1">{params.row.product_name}</span>
        <span id="content2">
          <small className="small-font-size">{params.row.item_code}</small>
        </span>
      </div>
    );
  };

  const SettlementCellRenderer = (params) => {
    const classes = useStyles();
    return (
      <div className={classes.multiLineCell}>
        <span id="content1">{params.row.tc_settlement_id}</span>
        <span id="content2">
          <small className="small-font-size">
            {params?.row?.tc_settlement_date?.split("T")[0]}
          </small>
        </span>
      </div>
    );
  };

  const columns = [
    {
      field: "tc_settlement_id",
      headerName: "Settlment ID",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 120,
      renderCell: SettlementCellRenderer,
    },
    {
      field: "product_name",
      headerName: "Product",
      width: 180,
      renderCell: MultiLineCellRenderer,
    },
    {
      field: "opening_stock",
      headerName: "Opening Stock",
      width: 160,
      filterable: false,
      disableColumnMenu: true,
      align: "center",
    },
    {
      field: "purchase_qty",
      headerName: "Purchase Qty",
      width: 160,
      filterable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "center",
    },
    {
      field: "sale_qty",
      headerName: "Sale Qty",
      width: 120,
      filterable: false,
      sortable: true,
      disableColumnMenu: true,
      align: "center",
    },
    {
      field: "closing_stock",
      headerName: "Closing Stock",
      width: 160,
      filterable: false,
      disableColumnMenu: true,
      align: "center",
    },
  ];

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };

  const handleSearch = (e) => {
    // console.log("Search Value...", e);
    if (e) {
      setData(
        unfilteredData.filter((item) => {
          return (
            item?.tc_settlement_id?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.item_code?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.product_name?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.purchase_qty?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.sale_qty?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.closing_stock?.toLowerCase().includes(e?.toLowerCase())
          );
        })
      );
    } else {
      getDataFromServer();
      setData(unfilteredData);
    }
  };

  const unfilter_Data = data;
  const handleTextSearch = (e) => {
    console.log("Search Value...", e);

    if (e) {
      setData(
        unfilteredData.filter((item) => {
          return (
            item?.tc_settlement_id?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.item_code?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.product_name?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.purchase_qty?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.sale_qty?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.closing_stock?.toLowerCase().includes(e?.toLowerCase())
          );
        })
      );
    } else {
      getDataFromServer();
      setData(unfilteredData);
    }
  };

  const clearSearch = () => {
    console.log("clear called");
    searchRef.current.value = null;
    ddref.current.value = null;
    setDropDownSettlement(null);
    setData(unfilteredData);
    toast.success("Cleared");
  };

  const inventorySettle = () => {
    // alert("inventory Settled");

    let urlLink = `${process.env.REACT_APP_API_URL}/tc/stocksettle/${loggedInUserDetails.tc_id}`;

    const config = {
      method: "post",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: { invdata: true },
    };

    axios(config)
      .then((res) => {
        console.log("response", res, "data", res.data[0]);
        getDataFromServer();
      })
      .catch((err) => {
        errorLogApi(err, "ListStateInventoryChild", urlLink);
      });
  };

  const containerStyle = {
    display: "flex",
    alignItems: "left",
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "300px",
      marginBottom: "15px",
    }),
  };

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div>{props.label.split("\n")[0]}</div>
        <div style={{ fontSize: "0.8em" }}>{props.label.split("\n")[1]}</div>
      </components.Option>
    );
  };

  const SingleValue = (props) => {
    return (
      <components.SingleValue {...props}>
        <div>{props.data.label.split("\n")[0]}</div>
        <div style={{ fontSize: "0.8em" }}>
          {props.data.label.split("\n")[1]}
        </div>
      </components.SingleValue>
    );
  };

  return (
    <div>
      <span className="card-title">
        <h3>Inventory Settlements</h3>
      </span>
      <div className="" style={containerStyle}>
        <div className="">
          <Select
            components={{ Option, SingleValue }}
            name="settlements"
            onChange={(selected, dropdown) => {
              setDropDownSettlement(selected.value);
              handleSearch(selected.value);
            }}
            className="newPatientSelect"
            placeholder="Select Settlement ID..."
            styles={customStyles}
            value={
              dropDownSettlement
                ? settlementOptions.find((val) => val.id === dropDownSettlement)
                : null
            }
            isSearchable
            ref={ddref}
            options={settlementOptions}
          />
        </div>
        <div className="" style={{ paddingLeft: "10px" }}>
          <button className="btn btn-primary" onClick={clearSearch}>
            Clear
          </button>
        </div>
      </div>
      <div className="patientListSearch" style={containerStyle}>
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">Filter</InputGroup.Text>
          <Form.Control
            placeholder="Product / State / Code / Company ..."
            aria-label="Search"
            aria-describedby="basic-addon1"
            name="searchPatients"
            ref={searchRef}
            onChange={(e) => handleTextSearch(e.target.value)}
          />
          <InputGroup.Text>
            <i
              className="mdi mdi-delete-outline"
              onClick={clearSearch}
              style={{ cursor: "pointer" }}
            ></i>
          </InputGroup.Text>
        </InputGroup>
      </div>
      <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        displayTitle="TC Current Stocks"
        checkboxSelection
        style={{ height: "60vh" }}
      />

      {/* Modal Popup */}
      <Modal show={openModal} centered>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Stocks Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent currentRecord={currentRecord} />
        </Modal.Body>
      </Modal>

      {/* {JSON.stringify(data)} */}
      {/* {JSON.stringify(currentRecord)} */}
    </div>
  );
};

const ModalContent = ({ currentRecord }) => {
  const dateFormatter = (date) => {
    const date_conv = new Date(date);
    const fullyear = date_conv.getFullYear();
    const month = date_conv.getMonth() + 1;
    const day = date_conv.getDate();
    return `${day}/${month}/${fullyear}`;
  };

  return (
    <div className="container-fluid">
      <table class="table table-striped">
        <tbody>
          <tr>
            <th scope="row">Product Name</th>
            <td>{currentRecord.product_name}</td>
          </tr>
          <tr>
            <th scope="row">Measuring Units</th>
            <td>{currentRecord.measuring_unit}</td>
          </tr>
          <tr>
            <th scope="row">Quantity</th>
            <td>{currentRecord.quantity}</td>
          </tr>
          <tr>
            <th scope="row">Expiry</th>
            <td>{dateFormatter(currentRecord.product_expiry)}</td>
          </tr>
          <tr>
            <th scope="row">Company Name</th>
            <td>{currentRecord.company_name}</td>
          </tr>
          {/* <tr>
            <th scope="row">State</th>
            <td>{currentRecord.state}</td>
          </tr> */}
        </tbody>
      </table>
    </div>
  );
};

export default TCSettlementHistoryChild;

import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import "./CardComponent.css";

const CardComponent = ({
  title,
  menus,
  handleOnClick,
  selectedMenu,
  setSelectedMenu,
}) => {
  const [listGroupStyle, setListGroupStyle] = useState({});

  useEffect(() => {
    // Assuming each list item has a fixed height, for example, 40px
    const maxItemHeight = 40; // Adjust this based on your actual item height
    const maxVisibleItems = 8;
    if (menus.length > maxVisibleItems) {
      setListGroupStyle({
        maxHeight: `${maxItemHeight * maxVisibleItems}px`,
        overflowY: "auto",
      });
    } else {
      setListGroupStyle({});
    }
  }, [menus]);

  return (
    <Card style={{ width: "18rem" }} className="diagnosis-card">
      <Card.Header>
        <span className="card-title">{title}</span>
      </Card.Header>
      <ListGroup variant="flush" style={listGroupStyle}>
        {menus.map((menu) => (
          <ListGroup.Item
            key={menu.id}
            className={`click-menu ${
              selectedMenu === menu.id ? "selected-menu" : ""
            }`}
            onClickCapture={() => {
              setSelectedMenu(menu.id);
              handleOnClick(
                menu.id,
                menu.label,
                menu.category,
                menu.category_id,
                menu.diagnosis_id
              );
            }}
          >
            {menu.label}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Card>
  );
};

export default CardComponent;

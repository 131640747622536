import React, { useRef } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
} from "recharts";
import html2canvas from "html2canvas";

const PerPatientDoseChart = ({ data }) => {
  const chartRef = useRef(null);
  const colorPalette = [
    "#8884d8",
    "#82ca9d",
    "#ffc658",
    "#ff7300",
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#a4de6c",
    "#d0ed57",
    "#83a6ed",
    "#8dd1e1",
  ];

  // Aggregate data by patient and product
  const aggregatedData = data.reduce((acc, item) => {
    const patientName = item.patient_name;
    const productName = item.product_name;
    const quantity = parseFloat(item.quantity);

    if (!acc[patientName]) {
      acc[patientName] = { total: 0 };
    }

    if (!acc[patientName][productName]) {
      acc[patientName][productName] = 0;
    }

    acc[patientName][productName] += quantity;
    acc[patientName].total += quantity;
    return acc;
  }, {});

  // Transform the data for the chart
  const chartData = Object.entries(aggregatedData).map(
    ([patientName, products]) => ({
      patient_name: patientName,
      ...products,
    })
  );

  // Get unique product names
  const productNames = Array.from(
    new Set(data.map((item) => item.product_name))
  );

  const handleDownload = () => {
    if (chartRef.current) {
      html2canvas(chartRef.current).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = imgData;
        link.download = "patient_dose_chart.png";
        link.click();
      });
    }
  };

  return (
    <div>
      <div ref={chartRef}>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="patient_name"
              angle={-45}
              textAnchor="end"
              height={70}
            />
            <YAxis />
            <Tooltip />
            <Legend />
            {productNames.map((name, index) => (
              <Bar
                key={name}
                dataKey={name}
                stackId="a"
                fill={colorPalette[index % colorPalette.length]}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
        <div style={{ marginTop: "20px" }}>
          <h3>Patient Details:</h3>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={tableHeaderStyle}>Patient Name</th>
                {productNames.map((name) => (
                  <th key={name} style={tableHeaderStyle}>
                    {name}
                  </th>
                ))}
                <th style={tableHeaderStyle}>Total</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(aggregatedData).map(([patientName, products]) => (
                <tr key={patientName}>
                  <td style={tableCellStyle}>{patientName}</td>
                  {productNames.map((name) => (
                    <td key={name} style={tableCellStyle}>
                      {products[name] ? products[name].toFixed(2) : "0.00"}
                    </td>
                  ))}
                  <td style={tableCellStyle}>{products.total.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button
          onClick={handleDownload}
          style={{
            padding: "10px 20px",
            backgroundColor: "#4CAF50",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Download Chart
        </button>
      </div>
    </div>
  );
};

const tableHeaderStyle = {
  backgroundColor: "#f2f2f2",
  padding: "10px",
  borderBottom: "1px solid #ddd",
  textAlign: "left",
};

const tableCellStyle = {
  padding: "10px",
  borderBottom: "1px solid #ddd",
};

export default PerPatientDoseChart;

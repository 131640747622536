import React from "react";

import MainContentCard from "../Home/MainContentCard";
import ErrorLogChild from "./ErrorLogChild";

const ErrorLog = () => {
  return (
    <MainContentCard>
      <ErrorLogChild />
    </MainContentCard>
  );
};

export default ErrorLog;

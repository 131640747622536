import React, { useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import indianCities from "../../../components/Data/IndianCities";
import Select from "react-select";

//Context import
import AuthContext from "../../../components/shared/Context";

const AddSupplierChild = () => {
  const { checkLoggedInStatus, loggedInUserDetails, selectedDiagnosis } =
    useContext(AuthContext);

  let navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      supplier_name: null,
      email: null,
      phone: null,
      company_name: null,
      address: null,
      products: null,
      comments: null,
      website: null,
      state: null,
      createdby: loggedInUserDetails._id,
    },
    onSubmit: (values) => {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/suppliers/add`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: { ...values },
      };
      axios(config)
        .then((res) => {
          toast.success("Supplier Registered Successfully.");
          formik.setFieldValue("supplier_name", "");
          formik.setFieldValue("email", "");
          formik.setFieldValue("phone", "");
          formik.setFieldValue("company_name", "");
          formik.setFieldValue("address", "");
          formik.setFieldValue("products", "");
          formik.setFieldValue("comments", "");
          navigate("/suppliers");
        })
        .catch(function (error) {
          toast.error(error?.response?.data?.message);
          console.log(error);
        });
    },
    validationSchema: Yup.object({
      supplier_name: Yup.string().required("Required"),
      email: Yup.string().email().required("Required"),
      phone: Yup.string().required("Required"),
      company_name: Yup.string().required("Required"),
    }),
  });

  const statesSet = new Set(
    indianCities.map((city, index) => {
      return city.state;
    })
  );

  const states = [...statesSet];

  const stateOptions = states
    .sort()
    .map((state) => ({ value: state, label: state }));

  //const inputFields = Object.keys(formData);
  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className="col-md-6 mx-auto d-block d-flex signup-form">
        <form onSubmit={formik.handleSubmit} className="mt-3 border p-4 shadow">
          <h5 className="text-secondary">ADD SUPPLIER</h5>
          <hr />
          <br />
          <div className="row">
            <div className="mb-3 col-md-6">
              <label>
                Name<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="supplier_name"
                className="form-control"
                placeholder="Supplier Name"
                style={
                  formik.touched.supplier_name && formik.errors.supplier_name
                    ? { border: "1px solid red" }
                    : null
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.supplier_name}
              />
            </div>
            <div className="mb-3 col-md-6">
              <label>
                Enter Email<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="email"
                className="form-control"
                placeholder="Supplier Email"
                style={
                  formik.touched.email && formik.errors.email
                    ? { border: "1px solid red" }
                    : null
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </div>
            <div className="mb-3 col-md-6">
              <label>
                Enter Website<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="website"
                className="form-control"
                placeholder="Supplier Website"
                style={
                  formik.touched.website && formik.errors.website
                    ? { border: "1px solid red" }
                    : null
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.website}
              />
            </div>
            <div className="mb-3 col-md-6">
              <label>
                Phone<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="phone"
                className="form-control"
                placeholder="Phone"
                style={
                  formik.touched.phone && formik.errors.phone
                    ? { border: "1px solid red" }
                    : null
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.phone}
              />
            </div>
            <div className="mb-3 col-md-6">
              <label>
                Company<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="company_name"
                className="form-control"
                placeholder="Company Name"
                style={
                  formik.touched.company_name && formik.errors.company_name
                    ? { border: "1px solid red" }
                    : null
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.company_name}
              />
            </div>
            <div className="mb-3 col-md-6">
              <label>Address</label>
              <input
                type="text"
                name="address"
                className="form-control"
                placeholder="Address"
                onChange={formik.handleChange}
                value={formik.values.address}
              />
            </div>
            <div className="mb-3 col-md-6">
              <label>Product(s)</label>
              <input
                type="text"
                name="products"
                className="form-control"
                placeholder="Product(s) seperate using commas"
                onChange={formik.handleChange}
                value={formik.values.products}
              />
            </div>
            <div className="mb-3 col-md-6">
              <label>State</label>
              <Select
                name="state"
                onChange={(selected, dropdown) =>
                  formik.handleChange({
                    target: {
                      name: "state",
                      value: selected.label,
                    },
                  })
                }
                className="newPatientSelect"
                value={
                  loggedInUserDetails?.state &&
                  stateOptions.find(
                    (state) => state.label === loggedInUserDetails?.state
                  )
                }
                options={stateOptions}
                isDisabled={loggedInUserDetails?.state ? true : false}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
              />
            </div>
            <div className="mb-3 ">
              <label>Comments</label>
              <textarea
                type="textarea"
                rows="5"
                name="comments"
                className="form-control"
                placeholder="Comments"
                onChange={formik.handleChange}
                value={formik.values.comments}
              />
            </div>
            <div className="text-danger">
              {" "}
              &nbsp;
              {formik.errors.supplier_name && (
                <span>Please check and input the Required fields</span>
              )}
            </div>

            <div className="col-md-12">
              <button className="btn-lg btn-danger float-end" type="submit">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddSupplierChild;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import PatientInfoBlock from "../../../components/PatientProfile/PatientInfoBlock";
import BackButton from "../../../components/Buttons/BackButton";

const ViewPatientDeathRecordChild = ({ pRecord }) => {
  let navigate = useNavigate();
  const [jsonData, setJsonData] = useState({
    death_report_date:
      pRecord?.patient_death_details?.death_report_date.split("T")[0],
    death_reported_by: pRecord?.patient_death_details?.death_reported_by,
    date_of_death: pRecord?.patient_death_details?.date_of_death.split("T")[0],
    place_of_death: pRecord?.patient_death_details?.place_of_death,
    cause_of_death: JSON.parse(pRecord?.patient_death_details?.cause_of_death)
      ?.label,
    other_cause_of_death: pRecord?.patient_death_details?.other_cause_of_death,
    additional_information:
      pRecord?.patient_death_details?.additional_information,
    govt_registration_no: pRecord?.patient_death_details?.govt_registration_no,
  });

  return (
    <div>
      <h1>Decease Information </h1>
      <div className="patientUpdateButton">
        <button
          className="btn btn-danger float-end"
          onClick={() =>
            navigate(`/patient/drecord/`, {
              state: pRecord,
            })
          }
        >
          Edit Record
        </button>
        &nbsp;&nbsp;
        <button
          className="btn btn-secondary float-end"
          onClick={() => navigate("/patients")}
        >
          Back
        </button>
        &nbsp;&nbsp;
      </div>
      <PatientInfoBlock selectedPatientProfile={pRecord} />

      <fieldset className="border p-4 w-100">
        <legend className="float-none w-auto p-2 fs-5">
          Decease Information Update
        </legend>
        {/* {JSON.stringify(pRecord?.patient_death_details)} */}
        {/* {JSON.stringify(Object.entries(jsonData))} */}
        {jsonData && <JSONDisplay data={jsonData} />}
      </fieldset>
    </div>
  );
};

function JSONDisplay({ data }) {
  return (
    <div style={{ width: "100%" }}>
      {Object.entries(data).map(([key, value], index) => (
        <div
          key={key}
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 20px",
            backgroundColor: index % 2 === 0 ? "#F2F2F2" : "white",
          }}
        >
          <span>
            <FormatString inputString={key} />{" "}
          </span>
          <span>{value}</span>
        </div>
      ))}
    </div>
  );
}

function FormatString({ inputString }) {
  const formattedString = inputString
    .replace(/_/g, " ") // Replace underscores with spaces
    .replace(/^\w/, (c) => c.toUpperCase()); // Capitalize the first character

  return <b>{formattedString}</b>;
}

export default ViewPatientDeathRecordChild;

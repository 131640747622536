import React, { useState, useEffect } from "react";
import { RadioGroup, Radio } from "react-radio-group";
import { Button } from "react-bootstrap";
import axios from "axios";
import toast from "react-hot-toast";

const DenverScores = ({ emrRecordId, jsRecord }) => {
  const [totalScore, setTotalScore] = useState(0);

  const [formData, setFormData] = useState({
    effusion_hemarthrosis: null,
    synovial_hypertrophy: null,
    cartilage_loss: null,
    cyst_erosions: null,
  });

  // useEffect(() => {
  //   let denver = localStorage.getItem(`${emrRecordId}-denverscores`);
  //   setFormData({ ...formData, ...JSON.parse(denver) });
  // }, []);

  useEffect(() => {
    if (jsRecord) {
      localStorage.setItem(
        `${emrRecordId}-denverscores`,
        JSON.stringify(jsRecord)
      );
    }
    let denver = localStorage.getItem(`${emrRecordId}-denverscores`);
    setFormData({ ...formData, ...JSON.parse(denver) });
  }, [jsRecord]);

  useEffect(() => {
    setTotalScore(Math.max(...Object.values({ ...formData })));
  }, [formData]);

  const pettersonFormValue = [
    {
      id: 1,
      title: "Effusion/hemarthrosis",
      radiobuttonName: "effusion_hemarthrosis",
      selectedValue: formData.effusion_hemarthrosis,
      options: [
        {
          optionText: "Small",
          optionValue: 1,
        },
        {
          optionText: "Moderate",
          optionValue: 2,
        },
        {
          optionText: "Large",
          optionValue: 3,
        },
      ],
    },
    {
      id: 2,
      title: "Synovial hypertrophy/hemosiderin",
      radiobuttonName: "synovial_hypertrophy",
      selectedValue: formData.synovial_hypertrophy,
      options: [
        {
          optionText: "Small",
          optionValue: 4,
        },
        {
          optionText: "Moderate",
          optionValue: 5,
        },
        {
          optionText: "Large",
          optionValue: 6,
        },
      ],
    },

    {
      id: 4,
      title: "Cartilage loss",
      radiobuttonName: "cartilage_loss",
      selectedValue: formData.cartilage_loss,
      options: [
        {
          optionText: "< 50% cartilage loss",
          optionValue: 9,
        },
        {
          optionText: "≥ 50% cartilage loss",
          optionValue: 10,
        },
      ],
    },
  ];

  const cyst_erosions = {
    id: 5,
    title: "Cysts/erosions",
    radiobuttonName: "cyst_erosions",
    selectedValue: formData.cyst_erosions,
    options: [
      {
        optionText: "1 cyst/partial surface erosion",
        optionValue: 7,
      },
      {
        optionText: "More than one cyst or full surface erosion",
        optionValue: 8,
      },
    ],
  };

  const updateDenverScore = (e) => {
    e.preventDefault();

    //changeTab(1);

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/jointsscore/update/${emrRecordId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, denverscores: true },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        //handleSuccess(JSON.stringify(response.data));
        localStorage.setItem(
          `${emrRecordId}-denverscores`,
          JSON.stringify(formData)
        );
        toast.success("Denver score saved Successfully.");
      })
      .catch(function (error) {
        //handleFailure(JSON.stringify(error.response.data.patient));
        toast.error(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error.response.data));
      });
  };

  const handleChange = ({ target: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  return (
    <form className="" onSubmit={(e) => updateDenverScore(e)}>
      <table class="table table-striped table-responsive">
        {/* <progress id="file" value="32" max="100">
            32%
          </progress> */}
        <thead>
          <tr>
            <td colSpan={4} style={{ borderBottom: "none" }}></td>
          </tr>
          <tr>
            <th colSpan={2}>Total Score: {totalScore}</th>
            <th colSpan={2} style={{ textAlign: "right" }}>
              <Button variant="primary" size="medium" type="submit">
                Save
              </Button>
            </th>
          </tr>
        </thead>

        <tbody>
          {pettersonFormValue.map(
            ({ title, radiobuttonName, options, selectedValue }) => (
              <DenverScoreRow
                handleChange={handleChange}
                rowNameTitle={title}
                radioButtonName={radiobuttonName}
                options={options}
                selectedValue={selectedValue}
              />
            )
          )}

          <tr>
            <td style={{ width: "10px" }}>{cyst_erosions.title} &nbsp;</td>
            <td style={{ fontSize: "12px" }} colSpan="3">
              {/* {formData.cyst_erosions} */}
              {cyst_erosions.options.map((cysterosion) => (
                <RadioGroup name={cyst_erosions.radiobuttonName}>
                  <Radio
                    value={cysterosion.optionValue}
                    className="radioButton"
                    onChange={handleChange}
                    checked={
                      cysterosion.optionValue ===
                      parseInt(cyst_erosions.selectedValue)
                        ? true
                        : false
                    }
                  />
                  {cysterosion.optionText}
                </RadioGroup>
              ))}
            </td>
          </tr>
          <tr>
            <td colSpan={5} style={{ textAlign: "right" }}>
              Final score = highest grade assigned to any finding.
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default DenverScores;

const DenverScoreRow = ({
  radioButtonName,
  handleChange,
  rowNameTitle,
  options,
  selectedValue,
}) => {
  return (
    <tr>
      <td style={{ width: "10px" }}>{rowNameTitle} &nbsp;</td>
      {options.map((option) => (
        <td style={{ fontSize: "12px" }}>
          <RadioGroup name={radioButtonName}>
            <Radio
              value={option.optionValue}
              className="radioButton"
              onChange={handleChange}
              checked={
                option.optionValue === parseInt(selectedValue) ? true : false
              }
            />
            {option.optionText}
          </RadioGroup>
        </td>
      ))}
      {options.length === 2 && <td>&nbsp;</td>}
    </tr>
  );
};

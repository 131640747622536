import React from "react";
import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import TCWiseInventoryStatusChild from "./TCWiseInventoryStatusChild";

const TCWiseInventoryStatus = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes
        page="ListTCWiseInventoryStatus"
        route="/tcwise/inventory/status"
      >
        <TCWiseInventoryStatusChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default TCWiseInventoryStatus;

import React, { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import styles from "./UpdateUser.module.css";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

//Context import
import AuthContext from "../../../components/shared/Context";

const UpdateUserChild = () => {
  const { state } = useLocation();
  const user_id = state.id;
  const { checkLoggedInStatus, states, loggedInUserDetails } =
    useContext(AuthContext);

  let navigate = useNavigate();

  //Error Logging Service
  const [{ errorLogApi, customEncrypt }] = useAtom(gbState);

  //Sanitize input
  const [{ sanitizeInput }] = useAtom(gbState);

  //To check if the user is logged in
  useEffect(() => {
    checkLoggedInStatus();
  }, []);

  const [tcStates, setTcStates] = useState([]);

  const [rolesValue, setRolesValue] = useState([]);
  const [confirmPassword, setConfirmPassword] = useState(null);

  const [formData, setFormData] = useState({
    first_name: null,
    last_name: null,
    mobile: null,
    email: null,
    password: null,
    role: "TC_USER_ACCESS",
    user_type: "Doctor",
    tc_state: loggedInUserDetails.state,
    tc_district: loggedInUserDetails.district,
    tc_id: loggedInUserDetails.tc_id,
    // patient_import_status: loggedInUserDetails?.patient_import_status,
    createdBy: loggedInUserDetails._id,
  });

  const [selectedOption, setSelectedOption] = useState(
    formData?.patient_import_status
  );

  const [selectedAccountOption, setSelectedAccountOption] = useState(false);

  useEffect(() => {
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/tc`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios(config)
      .then(function (response) {
        const fetchedTcData = response.data.treatment_center;
        const tcData = fetchedTcData.map((tc) => {
          return {
            id: tc._id,
            tc_name: tc.tcenters.name_tc,
            state: tc.tcenters.state,
            district: tc.tcenters.district,
          };
        });
        //console.log(JSON.stringify(tcData));
        setTcStates([...tcData]);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  //Get User Profile
  useEffect(() => {
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/user/${user_id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios(config)
      .then(function (response) {
        const userData = response.data.users;
        setFormData({ ...formData, ...userData, password: null });
        // console.log(JSON.stringify(userData));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  //Load Roles on page load
  useEffect(() => {
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/roles`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios(config)
      .then(function (response) {
        const fetchedRolesData = response.data.roles;
        setRolesValue(fetchedRolesData);
        //console.log(JSON.stringify(fetchedRolesData));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handleUpdateUserRegistration = (e) => {
    e.preventDefault();

    if (
      loggedInUserDetails?._id === user_id &&
      formData?.account_status === false
    ) {
      toast.error("Cannot Disable the Loggedin Account!");
      return;
    }

    if (formData.password !== confirmPassword) {
      toast.error("Password not matching!");
      return;
    }

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/user/update/`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, id: customEncrypt(user_id) },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("User Updated Successfully.");

        navigate("/users");
      })
      .catch(function (error) {
        //  toast.error(JSON.stringify(error.response.data.message));
        toast.error(error?.response?.data?.message);
        console.log(error);
      });
  };

  const handleChange = ({ currentTarget: input }) => {
    if (sanitizeInput(input.value, input.name)) {
      setFormData({
        ...formData,
        [input.name]: input.value,
      });
    }
  };

  const handlePassChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleOptionChange = () => {
    setSelectedOption(!selectedOption);
  };

  useEffect(() => {
    setFormData({
      ...formData,
      patient_import_status: selectedOption,
    });
  }, [selectedOption]);

  const handleAccountOptionChange = () => {
    setSelectedAccountOption(!selectedAccountOption);
  };

  useEffect(() => {
    setFormData({
      ...formData,
      account_status: selectedAccountOption,
    });
  }, [selectedAccountOption]);

  return (
    <div className="patient-list1">
      <div>
        <h2>Update User</h2>
      </div>
      {/* {JSON.stringify({ ...formData })} */}
      {/* {JSON.stringify(loggedInUserDetails)} */}
      <form
        onSubmit={(e) => handleUpdateUserRegistration(e)}
        autoComplete="off"
      >
        <div className="patientUpdateButton">
          <Button variant="primary" size="medium" type="submit">
            Save Doctor
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="secondary"
            size="medium"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
        <div className={styles.newFormUsersOnly}>
          <div className="newPatientItem w-25">
            <label htmlFor="first_name">First Name</label>
            <input
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="last_name">Last Name</label>
            <input
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              // required
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="mobile">Mobile</label>
            <input
              type="text"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              autoComplete="new-password"
              disabled
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handlePassChange}
              autoComplete="new-password"
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="password">Confirm Password</label>
            <input
              type="password"
              name="confirm_password"
              onChange={(e) => {
                setConfirmPassword(e?.target?.value);
              }}
              autoComplete="new-password"
            />
          </div>
          <div className="newPatientItem w-25 mt-4">
            <label>
              Patient Import Option &nbsp;&nbsp;
              <input
                type="checkbox"
                name="patient_import_status"
                checked={formData?.patient_import_status}
                onChange={handleOptionChange}
              />
            </label>
          </div>
          <div className="newPatientItem w-25 mt-4">
            <label>
              Account Status &nbsp;&nbsp;
              <input
                type="checkbox"
                name="account_status"
                checked={formData?.account_status}
                onChange={handleAccountOptionChange}
              />
            </label>
          </div>
        </div>
      </form>
      {/* {JSON.stringify(formData)} */}
    </div>
  );
};

export default UpdateUserChild;

import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import axios from "axios";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const CbcProfileTab = ({ emrRecordId, emrRecord }) => {
  const [formData, setFormData] = useState({
    hemoglobin: null,
    wbc: null,
    polys_neutrophilis: null,
    eosinophilis: null,
    basophilis: null,
    lymphocytes: null,
    monocytes: null,
    band_cells: null,
    red_blood_count: null,
    reticulocyte_count: null,
    hematocrit_pcv: null,
    mcv: null,
    mch: null,
    mchc: null,
    platelets: null,
    rdw: null,
  });
  const cbcProfileKeys = Object.keys(formData);

  useEffect(() => {
    if (emrRecord) {
      localStorage.setItem(
        `${emrRecordId}-cbcprofile`,
        JSON.stringify(emrRecord)
      );
    }
    let cbc = localStorage.getItem(`${emrRecordId}-cbcprofile`);
    setFormData({ ...formData, ...JSON.parse(cbc) });
  }, [emrRecord]);

  const updateEmr = (e) => {
    e.preventDefault();

    let userToken = localStorage.getItem("token");
    const config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/emr/update/${emrRecordId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, cbc_profile: true },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        localStorage.setItem(
          `${emrRecordId}-cbcprofile`,
          JSON.stringify(formData)
        );
        toast.success("Cbc Profile sheet updated Successfully.");
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error.response.data));
      });
  };

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const cbcLabels = [
    "Hemoglobin",
    "WBC",
    "Polys (neutrophilis)",
    "Eosinophils",
    "Basophils",
    "Lymphocytes",
    "Monocytes",
    "Band Cells",
    "Red Blood Count",
    "Reticulocyte Count",
    "Hematocrit - PCV",
    "MCV",
    "MCH",
    "MCHC",
    "Platelets",
    "RDW",
  ];

  const measureUnit = [
    "g/dl",
    "cell/mcl",
    "%",
    "%",
    "%",
    "%",
    "%",
    "",
    "mil/ul",
    "%",
    "%",
    "fL",
    "pg/cell",
    "g/dl",
    "cell/ul",
    "%",
  ];

  return (
    <div className="newPatientFormOnly">
      {/* {JSON.stringify(biochemKeys)} */}
      {/* {JSON.stringify(formData)} */}
      {cbcProfileKeys.map((key, index) => (
        <InputField
          handleChange={handleChange}
          fieldname={key}
          formData={formData}
          index={index}
          measureUnit={measureUnit}
          cbcLabels={cbcLabels}
          disabled
        />
      ))}
    </div>
  );
};

export default CbcProfileTab;

export const InputField = ({
  handleChange,
  fieldname,
  formData,
  index,
  measureUnit,
  cbcLabels,
}) => {
  function capitalize(s) {
    return s && s[0].toUpperCase() + s.slice(1);
  }

  return (
    <div className="newPatientItem">
      <label htmlFor={fieldname}>
        {/* {capitalize(fieldname.replaceAll("_", " ")).toUpperCase()} */}
        {cbcLabels[index]}
      </label>

      <InputGroup className="mb-3">
        <Form.Control
          aria-label={fieldname}
          type="number"
          name={fieldname}
          onChange={handleChange}
          value={formData[fieldname]}
          disabled
        />
        {measureUnit[index] && (
          <InputGroup.Text id="basic-addon2">
            {measureUnit[index]}
          </InputGroup.Text>
        )}
      </InputGroup>
    </div>
  );
};

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material/styles";
import "./index.css";
import ErrorBoundary from "./components/shared/ErrorBoundary";
import "react-confirm-alert/src/react-confirm-alert.css";
//import "bootstrap";
//import "bootstrap/dist/css/bootstrap.css";
//import "bootstrap/dist/css/bootstrap.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <App />
        </StyledEngineProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>
);

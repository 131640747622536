import React, { useRef } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import html2canvas from "html2canvas";

const TotalDoseConsumptionChart = ({ data }) => {
  const chartRef = useRef(null);

  const colorPalette = [
    "#8884d8",
    "#82ca9d",
    "#ffc658",
    "#ff7300",
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#a4de6c",
    "#d0ed57",
    "#83a6ed",
    "#8dd1e1",
  ];

  const aggregatedData = data.reduce((acc, item) => {
    const existing = acc.find(
      (entry) => entry.product_name === item.product_name
    );
    if (existing) {
      existing.quantity += parseFloat(item.quantity);
    } else {
      acc.push({
        product_name: item.product_name,
        quantity: parseFloat(item.quantity),
        fill: colorPalette[acc.length % colorPalette.length],
      });
    }
    return acc;
  }, []);

  const handleDownload = () => {
    if (chartRef.current) {
      html2canvas(chartRef.current).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = imgData;
        link.download = "total_dose_consumption_chart.png";
        link.click();
      });
    }
  };

  return (
    <div>
      <div ref={chartRef}>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={aggregatedData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="product_name"
              angle={-45}
              textAnchor="end"
              height={70}
            />
            <YAxis />
            <Tooltip />
            <Bar dataKey="quantity" />
          </BarChart>
        </ResponsiveContainer>
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {aggregatedData.map((product, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                margin: "5px 10px",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: product.fill,
                  marginRight: "5px",
                }}
              ></div>
              <span>
                {product.product_name}: {product.quantity.toFixed(2)}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button
          onClick={handleDownload}
          style={{
            padding: "10px 20px",
            backgroundColor: "#4CAF50",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Download Chart
        </button>
      </div>
    </div>
  );
};

export default TotalDoseConsumptionChart;

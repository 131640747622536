import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  let navigate = useNavigate();

  return (
    <div>
      <Button
        variant="primary"
        size="medium"
        onClick={() => navigate("/patients")}
      >
        Home
      </Button>
      {/* &nbsp;&nbsp;&nbsp;
      <Button variant="secondary" size="medium" onClick={() => navigate(-1)}>
        Back
      </Button> */}
    </div>
  );
};

export default BackButton;

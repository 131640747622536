import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

const PatientRegistrationChart = ({ patientsData }) => {
  const [chartData, setChartData] = useState({ options: {}, series: [] });

  function formatChartData(patientsData) {
    const treatmentCenters = new Set();
    const diagnosesCount = {};

    patientsData.forEach((patient) => {
      const tcName = patient.tc_detail?.tcenters?.name_tc;
      const diagnosisName = patient.diagnoses?.diagnosis_name;

      if (tcName && diagnosisName) {
        // Add treatment center to the set
        treatmentCenters.add(tcName);

        // Initialize diagnosis count object
        if (!diagnosesCount[diagnosisName]) {
          diagnosesCount[diagnosisName] = {};
        }

        // Increment the count for the diagnosis at the treatment center
        diagnosesCount[diagnosisName][tcName] =
          (diagnosesCount[diagnosisName][tcName] || 0) + 1;
      }
    });

    // Prepare the series data
    const series = Object.keys(diagnosesCount).map((diagnosis) => ({
      name: diagnosis,
      data: Array.from(treatmentCenters).map(
        (tc) => diagnosesCount[diagnosis][tc] || 0
      ),
    }));

    return {
      options: {
        chart: { id: "basic-bar" },
        stroke: { curve: "smooth" },
        xaxis: { categories: Array.from(treatmentCenters) },
      },
      series,
    };
  }

  useEffect(() => {
    const formattedData = formatChartData(patientsData);
    setChartData(formattedData);
  }, [patientsData]);

  return (
    <div className="col-xl-9 col-lg-8">
      {/* Card body starts */}
      <div className="card card-h-100">
        <div className="card-body">
          <h4 className="header-title mb-3">
            Patients Registration (Across Centers)--
          </h4>
          <div dir="ltr">
            <div
              id="sessions-overview"
              className="apex-charts mt-3"
              data-colors="#0acf97"
            />
            <Chart
              options={chartData.options}
              series={chartData.series}
              type="line"
              width="100%"
              height="380"
            />
          </div>
        </div>{" "}
        {/* end card-body*/}
        {/* {JSON.stringify(chartData)} */}
      </div>{" "}
      {/* end card*/}
    </div>
  );
};

export default PatientRegistrationChart;

import Chart from "../../../../components/Chart/Chart";

const AgeWiseReportChild = ({ patientData }) => {
  return (
    <div className="patient-update w-50">
      <Chart
        title="Patient Age"
        data={patientData}
        datakey="height"
        grid={true}
      />
    </div>
  );
};

export default AgeWiseReportChild;

export const externalvisitOptions = [
  { value: false, label: "No" },
  { value: true, label: "Yes" },
];

export const transfusionRegimenOptions = [
  { value: 1, label: "Hypo-transfusion (Pre-Hb 7-8)" },
  { value: 2, label: "Hypo-transfusion (Pre-Hb 9-10)" },
  { value: 3, label: "Super-transfusion (Pre-Hb 11-12)" },
  { value: 4, label: "Others" },
];

export const paymentOptions = [
  { value: 1, label: "Free" },
  { value: 2, label: "Subsidized" },
  { value: 3, label: "Paid" },
  { value: 4, label: "Not Applicable" },
  { value: 5, label: "RBSK" },
  { value: 6, label: "Non RBSK" },
];

export const travelModeOptions = [
  { value: 1, label: "Private / Self" },
  { value: 2, label: "Public Transport (Bus)" },
  { value: 3, label: "Public Transport (Taxi)" },
];

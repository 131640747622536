import React from "react";
import ImageSlider from "./ImageSlider";
import image1 from "../../images/Slides/1.jpg";
import image2 from "../../images/Slides/2.jpg";
import image3 from "../../images/Slides/3.jpg";
import image4 from "../../images/Slides/4.jpg";
import image5 from "../../images/Slides/5.jpg";
import image6 from "../../images/Slides/6.jpg";
import image7 from "../../images/Slides/7.jpg";
import image8 from "../../images/Slides/8.jpg";

const QuickStartChild = () => {
  const images = [
    { src: image1, alt: "Image 1" },
    { src: image2, alt: "Image 2" },
    { src: image3, alt: "Image 3" },
    { src: image4, alt: "Image 4" },
    { src: image5, alt: "Image 5" },
    { src: image6, alt: "Image 6" },
    { src: image7, alt: "Image 7" },
    { src: image8, alt: "Image 8" },
  ];

  return (
    <div>
      <ImageSlider images={images} />
    </div>
  );
};

export default QuickStartChild;

import Chart from "../../../../components/Chart/Chart";

const WeightWiseReportChild = ({ patientData }) => {
  return (
    <div className="patient-update w-50">
      <Chart
        title="Patient Age"
        data={patientData}
        datakey="weight"
        grid={true}
      />
    </div>
  );
};

export default WeightWiseReportChild;

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";

import MainContentCard from "../../Home/MainContentCard";
import BackButton from "../../../components/Buttons/BackButton";

import VisitInfoBlock from "./VisitInfoBlock";
import PatientInfoBlock from "../../../components/PatientProfile/PatientInfoBlock";
import CreateDischargeChild from "./CreateDischargeChild";

import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

const CreateDischarge = () => {
  const { state } = useLocation();
  const patientDiagnosis = state.patient.diagnoses.diagnosis_name;
  const [formData, setFormData] = useState({
    ...state.visit,
    discharged: true,
    discharged_date: null,
    discharged_time: null,
    examination_at_time_of_admission: null,
    history_of_past_illness: null,
    procedure_or_surgery_performed: null,
    significant_findings: null,
    treatment_given: null,
    discharged_comments: null,
    condition_at_discharge: null,
    treatment_advice_on_discharge: null,
    followup_instruction: null,
    doctor_who_discharged: null,
    user_who_discharged: null,
  });

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="CreateDischarge" route="/create-discharge">
        <div className="patientUpdateButton">
          <BackButton />
        </div>
        <div className="mb-2">
          <PatientInfoBlock selectedPatientProfile={state?.patient} />
        </div>
        <Tabs
          defaultActiveKey="discharge"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="discharge" title="Discharge">
            <CreateDischargeChild
              formData={formData}
              setFormData={setFormData}
              handleChange={handleChange}
            />
          </Tab>
          <Tab eventKey="visit" title="Visit Details">
            <VisitInfoBlock
              selectedPatientVisit={state?.visit}
              PatientDiagnosis={patientDiagnosis}
            />
          </Tab>
        </Tabs>
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default CreateDischarge;

import React from "react";
import { useLocation } from "react-router-dom";
import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import UpdateDoctorChild from "./UpdateDoctorChild";

const UpdateDoctor = () => {
  const { state } = useLocation();
  const doctorRecord = state;

  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="UpdateDoctorChild" route="/doctor/update">
        <UpdateDoctorChild doctorRecord={doctorRecord} />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default UpdateDoctor;

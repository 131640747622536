import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import axios from "axios";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const EchoProfileTab = ({ emrRecordId, emrRecord }) => {
  const [formData, setFormData] = useState({
    heart_rate: null,
    lvidd: null,
    lvids: null,
    rvdd: null,
    aortic_root: null,
    la_dimension: null,
    ivsd: null,
    ivss: null,
    lvpwd: null,
    lvpws: null,
    edv: null,
    esv: null,
    ejection_fraction: null,
    tr_jet: null,
  });
  const echoProfileKeys = Object.keys(formData);

  useEffect(() => {
    if (emrRecord) {
      localStorage.setItem(
        `${emrRecordId}-echoprofile`,
        JSON.stringify(emrRecord)
      );
    }
    let echo = localStorage.getItem(`${emrRecordId}-echoprofile`);
    setFormData({ ...formData, ...JSON.parse(echo) });
  }, [emrRecord]);

  const updateEmr = (e) => {
    e.preventDefault();
    //changeTab(1);

    let userToken = localStorage.getItem("token");
    const config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/emr/update/${emrRecordId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, echo_profile: true },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        localStorage.setItem(
          `${emrRecordId}-echoprofile`,
          JSON.stringify(formData)
        );
        toast.success("ECHO Profile sheet updated Successfully.");
      })
      .catch(function (error) {
        //handleFailure(JSON.stringify(error.response.data.patient));
        toast.error(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error.response.data));
      });
  };

  const handleChange = ({ currentTarget: input }) => {
    //alert([input.name]);
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const echoLabels = [
    "Heart Rate (HR)",
    "LVIDd",
    "LVIDs",
    "RVDd",
    "Arotic Root",
    "LA Dimension",
    "IVSd",
    "IVSs",
    "LVPWd",
    "LVPWs",
    "EDV",
    "ESV",
    "EF (Ejection Fraction)",
    "TR Jet",
  ];
  const measuringUnit = [
    "Beats/min",
    "cm",
    "cm",
    "cm",
    "cm",
    "cm",
    "cm",
    "cm",
    "cm",
    "cm",
    "ml",
    "ml",
    "%",
    "m/sec",
  ];

  return (
    <div className="newPatientFormOnly">
      {/* {JSON.stringify(biochemKeys)} */}
      {/* {JSON.stringify(formData)} */}
      {echoProfileKeys.map((key, index) => (
        <InputField
          handleChange={handleChange}
          fieldname={key}
          formData={formData}
          index={index}
          measuringUnit={measuringUnit}
          echoLabels={echoLabels}
        />
      ))}
    </div>
  );
};

export default EchoProfileTab;

export const InputField = ({
  handleChange,
  fieldname,
  formData,
  index,
  measuringUnit,
  echoLabels,
}) => {
  function capitalize(s) {
    return s && s[0].toUpperCase() + s.slice(1);
  }

  return (
    <div className="newPatientItem">
      <label htmlFor={fieldname}>
        {/* {capitalize(fieldname.replaceAll("_", " ")).toUpperCase()} */}
        {echoLabels[index]}
      </label>

      <InputGroup className="mb-3">
        <Form.Control
          aria-label={fieldname}
          type="number"
          name={fieldname}
          onChange={handleChange}
          value={formData[fieldname]}
          disabled
        />
        {measuringUnit[index] && (
          <InputGroup.Text id="basic-addon2">
            {measuringUnit[index]}
          </InputGroup.Text>
        )}
      </InputGroup>
    </div>
  );
};

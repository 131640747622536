import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Modal, Button, Card } from "react-bootstrap";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";

const RolesListChild = () => {
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  let navigate = useNavigate();

  const checkRole = [
    "administrator",
    "state admin",
    "tc admin",
    "state it admin",
    "doctor",
    "nurse",
    "data entry operator (deo)",
  ];

  const handleDelete = (row) => {
    if (checkRole.includes(row.role_name.toLowerCase())) {
      toast.error(`${row.role_name} cannot be removed!`);
      return;
    }

    confirmAlert({
      title: "",
      message: "Are you sure to delete this Role ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(row),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (row) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/roles/delete/${row._id}`,
        config
      )
      .then((response) => {
        //alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((err) => console.log(err));
  };

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/roles/`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data.roles));
        const oldData = response.data.roles;
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data._id,
            role_name: data.role_name,
          };
        });
        //   console.log(updatedData);
        setData(updatedData);
      })
      .catch(function (error) {
        //console.log(error.message);
      });
  };

  useEffect(() => {
    getDataFromServer();
  }, []);

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            <a onClick={() => viewRecord(params.row)}>
              <i
                className="mdi mdi-eye-circle-outline"
                style={{
                  fontSize: "15px",
                  color: "black",
                  cursor: "pointer",
                }}
              ></i>
            </a>
            <Tooltip title="Delete Role">
              <div onClick={() => handleDelete(params.row)}>
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "role_name",
      headerName: "Role Name",
      width: 300,
    },
    {
      field: "access_level",
      headerName: "Role ID",
      width: 300,
    },
  ];

  const handleAddRole = () => {
    navigate("/roles/add");
  };

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };

  return (
    <div className="patient-list1">
      <div>
        <h2>Roles</h2>
      </div>
      <div className="patientUpdateButton">
        <Button variant="primary" size="medium" onClick={handleAddRole}>
          Add Roles
        </Button>
      </div>
      <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        displayTitle="Blood Products"
        checkboxSelection
        style={{ height: "60vh" }}
      />
      {/* Modal Popup */}
      <Modal show={openModal} size="md" centered>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent currentRecord={currentRecord} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

const ModalContent = ({ currentRecord }) => {
  return (
    <div className="container-fluid legend">
      <Card>
        <Card.Header>
          <i className="fas fa-align-justify" />
          <strong>Role Details</strong>
        </Card.Header>
        <Card.Body>
          <CardTableRow rowName="Role ID" rowValue={currentRecord?.id} />
          <CardTableRow
            rowName="Role Name"
            rowValue={currentRecord?.role_name}
          />
          <CardTableRow
            rowName="Access Level"
            rowValue={currentRecord?.access_level}
          />
          <CardTableRow
            rowName="Creation date"
            rowValue={currentRecord?.createdAt}
          />
        </Card.Body>
      </Card>
    </div>
  );
};

const CardTableRow = ({ rowName, rowValue }) => {
  return (
    <div className="row">
      <div className="col-md-6 col-xs-6 divTableCell">
        <b> {rowName}: </b>
      </div>
      <div className="col-md-6 col-xs-6 divTableCell">{rowValue}</div>
    </div>
  );
};

export default RolesListChild;

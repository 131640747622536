import "./CreateVisit.css";

import { useLocation } from "react-router-dom";

import MainContentCard from "../../Home/MainContentCard";
import CreateVisitChild from "./CreateVisitChild";

import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

const CreateVisit = () => {
  const { state } = useLocation();
  let patientRecordId = state.id;

  return (
    <MainContentCard>
      {/* {JSON.stringify(state)} */}
      <ErrorBoundaryRoutes page="CreateVisitChild" route="/create-visit">
        <CreateVisitChild
          patientRecordId={patientRecordId}
          patientProfileData={state}
        />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default CreateVisit;

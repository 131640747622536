import React from "react";
import CardContent from "./CardContent";
import QuickStartChild from "./QuickStartChild";

const QuickStart = () => {
  return (
    <CardContent>
      <QuickStartChild />
    </CardContent>
  );
};

export default QuickStart;

import React, { useEffect, useState, useContext } from "react";
import MainContentCard from "../../Home/MainContentCard";
import PurchaseRequestsChild from "./PurchaseRequestsChild";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

const PurchaseRequests = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="PurchaseRequests" route="/purchase-requests">
        <PurchaseRequestsChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default PurchaseRequests;

import React from "react";
// import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
//import "./ProductsList.css";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";

const THistory = ({ emrRecordId, emrRecord }) => {
  const [products, setProducts] = useState([]);

  const [toggleBP, setToggleBP] = useState(true);

  const [formData, setFormData] = useState({
    has_required_treatment: false,
    blood_products_required: false,
    blood_products: "",
    physiotherapy: null,
    product_one: "",
    additional_product: "",
    therapy: null,
    care: null,
    taken_for: false,
    any_nonblood_product_required: false,
    nonblood_product: null,
    nonblood_product_others: null,
  });

  const productOneOptions = products.map((product) => ({
    value: product._id,
    label: product.products.product_name + " (" + "Generic" + ")",
  }));

  const therapyOptions = [
    { value: 1, label: "On Demand Therapy" },
    { value: 2, label: "Prophylactic Therapy" },
  ];

  const careOptions = [
    { value: 1, label: "Home Care" },
    { value: 2, label: "Hospital Care" },
  ];

  const takenForOptions = [
    { value: true, label: "Major Bleed" },
    { value: false, label: "Minor Bleed" },
  ];

  // useEffect(() => {
  //   let thistory = localStorage.getItem(`${emrRecordId}-thistory`);
  //   setFormData({ ...formData, ...JSON.parse(thistory) });
  //   console.log(JSON.parse(thistory));
  // }, []);

  useEffect(() => {
    if (emrRecord) {
      localStorage.setItem(
        `${emrRecordId}-thistory`,
        JSON.stringify(emrRecord)
      );
    }
    let thistory = localStorage.getItem(`${emrRecordId}-thistory`);
    setFormData({ ...formData, ...JSON.parse(thistory) });
  }, [emrRecord]);

  const updateEmr = (e) => {
    e.preventDefault();

    let userToken = localStorage.getItem("token");
    const config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/emr/update/${emrRecordId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, treatment_history: true },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        localStorage.setItem(
          `${emrRecordId}-thistory`,
          JSON.stringify(formData)
        );
        toast.success("Treatment History saved Successfully.");
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error.response.data));
      });
  };

  const handleChange = ({ currentTarget: input }) => {
    //alert([input.name]);
    setFormData({
      ...formData,
      [input.name]: input.checked,
    });
  };
  const handleChangeValue = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const dropDownChange = (selected, dropdown) => {
    console.log("this is selected", JSON.stringify(dropdown.name));
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const getProducts = () => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/product/`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data.product));
        const oldData = response.data.product;
        setProducts(oldData);
      })
      .catch(function (error) {
        console.log(error.message);
      });
  };
  useEffect(() => {
    getProducts();
  }, []);

  return (
    <form className="d-flex flex-sm-wrap" onSubmit={(e) => updateEmr(e)}>
      {/* {JSON.stringify(formData)} */}
      <div className="table-responsive w-100" style={{ marginRight: "40px" }}>
        <table class="table table-striped ">
          <tbody>
            <tr>
              <td>Has Required Treatment ?</td>
              <td>
                <input
                  type="checkbox"
                  name="has_required_treatment"
                  onChange={handleChange}
                  checked={formData.has_required_treatment}
                  disabled
                />
              </td>
            </tr>
            <tr>
              <td>Physiotherapy ?</td>
              <td>
                <input
                  type="checkbox"
                  name="physiotherapy"
                  onChange={handleChange}
                  checked={formData.physiotherapy}
                  disabled
                />
              </td>
            </tr>
            <tr>
              <td>Product Given</td>
              <td>
                <div className="newPatientItems">
                  <Select
                    name="product_one"
                    onChange={
                      //dropDownChange
                      (selected, dropdown) => {
                        //console.log(selected);
                        setFormData({
                          ...formData,
                          product_one: JSON.stringify(selected),
                        });
                      }
                    }
                    value={
                      formData.product_one && JSON.parse(formData.product_one)
                    }
                    className="newPatientSelect"
                    options={productOneOptions}
                    isDisabled={true}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>Product One Others</td>
              <td>
                <input
                  type="text"
                  name="blood_products"
                  onChange={handleChangeValue}
                  // value={formData.blood_products}
                  disabled={toggleBP}
                />
              </td>
            </tr>
            <tr>
              <th colspan="2">Treatment used / given.</th>
            </tr>
            <tr>
              <td>Therapy</td>
              <td>
                <Select
                  name="therapy"
                  onChange={
                    //  dropDownChange
                    (selected, dropdown) => {
                      //console.log(selected);
                      setFormData({
                        ...formData,
                        therapy: JSON.stringify(selected),
                      });
                    }
                  }
                  value={formData.therapy && JSON.parse(formData.therapy)}
                  className="newPatientSelect"
                  options={therapyOptions}
                  isDisabled={true}
                />
              </td>
            </tr>
            <tr>
              <td>Care</td>
              <td>
                <Select
                  name="care"
                  onChange={
                    //dropDownChange
                    (selected, dropdown) => {
                      //console.log(selected);
                      setFormData({
                        ...formData,
                        care: JSON.stringify(selected),
                      });
                    }
                  }
                  value={formData.care && JSON.parse(formData.care)}
                  className="newPatientSelect"
                  options={careOptions}
                  isDisabled={true}
                />
              </td>
            </tr>
            <tr>
              <td>Taken for:</td>
              <td>
                <Select
                  name="taken_for"
                  onChange={
                    //dropDownChange
                    (selected, dropdown) => {
                      //console.log(selected);
                      setFormData({
                        ...formData,
                        taken_for: JSON.stringify(selected),
                      });
                    }
                  }
                  value={formData.taken_for && JSON.parse(formData.taken_for)}
                  className="newPatientSelect"
                  options={takenForOptions}
                  isDisabled={true}
                />
              </td>
            </tr>
            <tr>
              <td>Products Required</td>
              <td>
                <input
                  type="checkbox"
                  name="any_nonblood_product_required"
                  onChange={handleChange}
                  checked={formData.any_nonblood_product_required}
                  disabled
                />
              </td>
            </tr>
            <tr>
              <td>Additional Product Used/Given</td>
              <td>
                <div>
                  <Select
                    name="additional_product"
                    onChange={
                      //dropDownChange
                      (selected, dropdown) => {
                        //console.log(selected);
                        setFormData({
                          ...formData,
                          additional_product: JSON.stringify(selected),
                        });
                      }
                    }
                    value={
                      formData.additional_product &&
                      JSON.parse(formData.additional_product)
                    }
                    className="newPatientSelect"
                    options={productOneOptions}
                    isDisabled={true}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>Product Two Others</td>
              <td>
                <input
                  type="text"
                  name="nonblood_product_others"
                  onChange={handleChangeValue}
                  value={formData.nonblood_product_others}
                  disabled
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </form>
  );
};

export default THistory;

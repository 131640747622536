import { useEffect, useContext, useRef, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import jwt_decode from "jwt-decode";

//Global State using JOTAI
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";
//import { captchaSettings, Captcha } from "reactjs-captcha";

//Context import
import AuthContext from "../../components/shared/Context";

const Login = () => {
  const { setIsLoggedIn, setloggedInUserDetails } = useContext(AuthContext);
  const [userStatus, setUserStatus] = useAtom(gbState);
  const [captchaSvg, setCaptchaSvg] = useState(null);

  //Error Logging Service
  const [{ errorLogApi, encryptData }] = useAtom(gbState);

  useEffect(() => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/user/cpt`,
    };
    axios(config).then((res) => {
      const authTokenHeader = res?.headers["auth-token"];
      setCaptchaSvg({
        svg: res.data.svg,
        hash: res.headers["auth-token"]
          ? res.headers["auth-token"]
          : res.data.atoken,
      });
    });
  }, []);

  const refreshCaptcha = () => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/user/cpt`,
    };
    axios(config).then((res) => {
      setCaptchaSvg({
        svg: res.data.svg,
        hash: res.headers["auth-token"]
          ? res.headers["auth-token"]
          : res.data.atoken,
      });
    });
  };

  let navigate = useNavigate();
  let usernameRef = useRef();
  let passwordRef = useRef();
  let svgcaptchaRef = useRef();
  let userToken = localStorage.getItem("token");

  const tcRoles = [
    "TC_ADM_ACCESS",
    "TC_USER_ACCESS",
    "REPORT_ACCESS",
    "PVT_USER_ACCESS",
  ];
  const adminRoles = ["ST_ADM_ACCESS", "FULL_ACCESS"];

  useEffect(() => {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/user/tokenverify`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    if (userToken) {
      axios(config)
        .then(function (response) {
          setIsLoggedIn(true);
          setloggedInUserDetails(
            JSON.parse(sessionStorage.getItem("user-details"))
          );
          navigate("/patients", { replace: true });
        })
        .catch((error) => {
          localStorage.removeItem("token");
          sessionStorage.removeItem("user-details");
          navigate("/", { replace: true });
          usernameRef.current.focus();
        });
    } else {
      navigate("/", { replace: true });
    }
  }, []);

  const encode = (data) => {
    return btoa(
      unescape(encodeURIComponent(data.split("").reverse().join("")))
    );
  };

  const checkLogin = async (email, password, captchatext) => {
    //console.log(captchaSvg);
    const encrypted_pass = window.btoa(
      `${password}-${process.env.REACT_APP_SECRET_PASS}`
    );
    const enc_pass = encryptData(password);

    // const encryptedData = encode(email);

    const cred = encode(
      JSON.stringify({ email: email, password: encrypted_pass })
    );

    const credentials = {
      // email: email,
      // password: encrypted_pass,
      cred: cred,
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/login`,
      credentials,
      {
        headers: {
          "Content-Type": "application/json",
          "auth-token": encryptData(
            encryptData(captchatext) + "_" + captchaSvg.hash
          ),
        },
      }
    );

    return response;
    //return response.headers["auth-token"];
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(usernameRef.current.value))
      return toast.error("Enter Valid Email to login.");

    if (
      usernameRef.current.value &&
      passwordRef.current.value &&
      svgcaptchaRef.current.value
    ) {
      try {
        const response = await checkLogin(
          usernameRef.current.value,
          passwordRef.current.value,
          svgcaptchaRef.current.value
        );
        localStorage.setItem("token", response?.headers["auth-token"]);
        setloggedInUserDetails(jwt_decode(response?.headers["auth-token"]));

        setInterval(checkToken, 1000 * 60 * 10);

        setIsLoggedIn(true);
        setUserStatus({
          ...userStatus,
          user: jwt_decode(response.headers["auth-token"]),
          handleLogout: () => {
            localStorage.removeItem("token");
            sessionStorage.removeItem("user-details");
            setUserStatus({ ...userStatus, user: null });
            navigate("/login", { replace: true });
          },
        });
        if (response?.headers["auth-token"]) {
          const user = jwt_decode(response.headers["auth-token"]);
          //console.log(user);
          if (tcRoles.includes(user.role)) {
            navigate("/dashboard-tc", { replace: true });
          }
          if (adminRoles.includes(user.role)) {
            navigate("/dashboard-state", { replace: true });
          }
          toast.success("Logged in successfully");
        }
      } catch (error) {
        //toast.error("Unable to login, Bad username or password");
        if (error?.response?.data?.error) {
          return toast.error(error?.response?.data?.error);
        }
        if (error?.response?.data) {
          return toast.error(error?.response?.data);
        }
        //console.log(error?.response?.data);
      }
    }
  };

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwt_decode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        // token has expired, do something here (e.g. log out the user)
        localStorage.removeItem("token");
        sessionStorage.removeItem("user-details");
        setUserStatus({ ...userStatus, user: null });
        navigate("/login", { replace: true });
      }
    }
  };

  const encodeSvg = (svgString) => {
    return svgString
      .replace(
        "<svg",
        ~svgString.indexOf("xmlns")
          ? "<svg"
          : '<svg xmlns="http://www.w3.org/2000/svg"'
      )
      .replace(/"/g, "'")
      .replace(/%/g, "%25")
      .replace(/#/g, "%23")
      .replace(/{/g, "%7B")
      .replace(/}/g, "%7D")
      .replace(/</g, "%3C")
      .replace(/>/g, "%3E")
      .replace(/\s+/g, " ");
  };

  return (
    <div className="auth-fluid ">
      {/* Auth fluid Left content */}
      <div
        className="auth-fluid-right text-center"
        style={{ backgroundColor: "null" }}
      ></div>
      {/* end Auth fluid Left content */}

      {/*Auth fluid Right content */}
      <div className="auth-fluid-form-box">
        <div className=" align-items-center d-flex h-100">
          <div className="card-body" style={{ marginTop: "95px" }}>
            {/* Logo */}
            {/* {JSON.stringify(userStatus)} */}
            <div
              className="d-flex justify-content-center auth-brand text-center text-lg-start align-items-center w-75"
              align="center"
            >
              <NavLink to="/" className="logo-dark mb-4">
                <img
                  src="assets/images/icare/icare-logo_trans_01.png"
                  alt
                  height={100}
                />
              </NavLink>
              <NavLink to="/" className="logo-light mb-4">
                <span>
                  <img
                    src="assets/images/icare/icare-logo_trans_01.png"
                    alt
                    height={100}
                  />
                </span>
              </NavLink>
            </div>
            <div id="login-input">
              <form onSubmit={handleLoginSubmit}>
                <div className="mb-3">
                  <label htmlFor="emailaddress" className="form-label">
                    Email address
                  </label>
                  <input
                    className="form-control"
                    type="email"
                    id="emailaddress"
                    required
                    placeholder="Enter your email"
                    autoComplete="new-password"
                    ref={usernameRef}
                  />
                </div>
                <div className="mb-3">
                  <a
                    onClick={() => {
                      toast.error(
                        "Contact or email your Manager/Administrator for your Login credentials/resetting."
                      );
                    }}
                    className="text-muted float-end"
                  >
                    <small>Forgot your password?</small>
                  </a>
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <input
                    className="form-control"
                    type="password"
                    required
                    id="password"
                    placeholder="Enter your password"
                    // autoComplete="new-password"
                    ref={passwordRef}
                  />
                </div>
                <div className="mb-3">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="checkbox-signin"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="checkbox-signin"
                    >
                      Remember me
                    </label>
                  </div>
                </div>
                <div className="mb-3 d-flex flex-row">
                  <div
                    className=""
                    style={{
                      backgroundColor: "#F6F6F6",
                      width: "90%",
                      textAlign: "center",
                    }}
                  >
                    {captchaSvg && (
                      <img
                        src={`data:image/svg+xml,${encodeSvg(captchaSvg.svg)}`}
                      />
                    )}
                  </div>
                  <div className="">
                    <i
                      className="mdi mdi-refresh"
                      style={{ fontSize: "35px", cursor: "pointer" }}
                      onClick={() => refreshCaptcha()}
                    ></i>
                  </div>
                </div>
                <div className="mb-3">
                  <input
                    className="form-control"
                    name="captchacode"
                    type="text"
                    required
                    placeholder="Enter Captcha"
                    ref={svgcaptchaRef}
                  />
                </div>
                <div className="d-grid mb-0 text-center">
                  <button className="btn btn-danger" type="submit">
                    <i className="mdi mdi-login" /> Log In
                  </button>
                  <br />

                  <span style={{ fontSize: "15px" }}>
                    Comprehensive Blood Disorder* Registry with Analytics
                  </span>
                </div>
              </form>
            </div>
            {/* end form*/}
            {/* Footer*/}
            <footer className="footer footer-alt d-flex justify-content-center">
              <span style={{ fontSize: "12px" }}>
                *Blood Disorder: Hemophilia, Thalassemia, Sickle Cell Disease
                [BUILD:202202410012]
              </span>
            </footer>
          </div>{" "}
          {/* end .card-body */}
        </div>{" "}
        {/* end .align-items-center.d-flex.h-100*/}
      </div>
      {/* end auth-fluid-form-box*/}
      {/* end auth-fluid*/}
    </div>
  );
};

export default Login;

import { useEffect, useState, useContext } from "react";
import axios from "axios";
import Select from "react-select";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

//Context import
import AuthContext from "../../components/shared/Context";

function TableRows({
  rowsData,
  deleteTableRows,
  handleChange,
  dropDownChange,
  editable,
  addTableRows,
}) {
  const [products, setProducts] = useState([]);
  const { loggedInUserDetails } = useContext(AuthContext);
  const productsOptions = products.map((prod) => ({
    value: prod._id,
    label: prod.products.product_name + " (" + prod.products.company_name + ")",
    measuring_unit: prod.products.measuring_unit,
  }));

  const getProducts = () => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/product/${loggedInUserDetails.state}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.product));
        const oldData = response.data.product;
        setProducts(oldData);
      })
      .catch(function (error) {
        //console.log(error.message);
      });
  };
  useEffect(() => {
    getProducts();
  }, []);

  return rowsData.map((data, index) => {
    const { medicine, note, duration, dose_quantity } = data;
    return (
      <tr key={index}>
        <td>
          <Select
            name="medicine"
            onChange={(selected, dropdown) =>
              dropDownChange(selected, dropdown, index)
            }
            className="newPatientSelect w-100"
            isSearchable
            options={productsOptions}
            value={medicine && JSON.parse(medicine)}
            isDisabled={!editable}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
          />
        </td>
        <td>
          <InputGroup className="mb-0">
            <Form.Control
              aria-label={dose_quantity}
              type="number"
              name="dose_quantity"
              onChange={(evnt) => handleChange(index, evnt)}
              value={dose_quantity}
              disabled={!editable || !medicine}
            />
            {medicine && (
              <InputGroup.Text id="basic-addon2">
                {JSON.parse(medicine).measuring_unit}
              </InputGroup.Text>
            )}
          </InputGroup>
        </td>
        <td>
          <input
            type="text"
            value={duration}
            onChange={(evnt) => handleChange(index, evnt)}
            name="duration"
            className="form-control"
            disabled={!editable || !medicine}
          />{" "}
        </td>

        <td>
          <input
            type="text"
            value={note}
            onChange={(evnt) => handleChange(index, evnt)}
            name="note"
            className="form-control"
            disabled={!editable || !medicine}
          />{" "}
        </td>

        <td>
          {index === 0 && editable && (
            <i
              className="mdi mdi-plus-circle-outline"
              style={{
                fontSize: "25px",
                cursor: "pointer",
                color: "blue",
                verticalAlign: "middle",
              }}
              onClick={addTableRows}
            />
          )}
          {index !== 0 && editable && (
            <i
              className="mdi mdi-minus-circle-outline"
              onClick={() => deleteTableRows(index)}
              style={{
                fontSize: "25px",
                color: "red",
                cursor: "pointer",
                verticalAlign: "middle",
              }}
            />
          )}
        </td>
      </tr>
    );
  });
}
export default TableRows;

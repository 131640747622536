import React from "react";
import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import ListStateInventoryChild from "./ListStateInventoryChild";

const ListStateInventory = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="ListStateInventory" route="/state-inventory">
        <ListStateInventoryChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default ListStateInventory;

import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Select from "react-select";
import { DatePicker } from "antd";
import UUID from "uuid-int";
import { confirmAlert } from "react-confirm-alert";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

//Context import
import AuthContext from "../../../components/shared/Context";

const AddPurchaseChild = () => {
  let navigate = useNavigate();
  const { checkLoggedInStatus, loggedInUserDetails, selectedDiagnosis } =
    useContext(AuthContext);

  //Error Logging Service
  const [{ errorLogApi, sanitizeInput }] = useAtom(gbState);

  const [products, setProducts] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  let defaultDate = new Date();
  const [date, setDate] = useState(defaultDate);

  // number  0 <= id <=511
  const id = 21;
  const generator = UUID(id);
  const uuid = generator.uuid();

  const [formData, setFormData] = useState({
    product_id: null,
    item_code: null,
    quantity: null,
    product_sku: null,
    po_number: null,
    shipment_id: null,
    batch_id: uuid,
    po_date: null,
    expiry_date: null,
    supplier_id: null,
    unit_price: null,
  });

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/product/${loggedInUserDetails.state}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        const oldData = response.data.product;
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data._id,
            item_code: data.products.item_code,
            product_name: data.products.product_name,
            measuring_unit: data.products.measuring_unit,
            company_name: data.products.company_name,
            company_email: data.products.company_email,
          };
        });
        //console.log(updatedData);
        setProducts(updatedData);
      })
      .catch(function (error) {
        console.log(error.message);
      });
  };

  const getSuppliersFromServer = () => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/suppliers/${loggedInUserDetails.state}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data.product));
        const oldData = response.data.suppliers;
        //console.log(oldData);
        setSuppliers(oldData);
      })
      .catch(function (error) {
        console.log(error.message);
      });
  };

  useEffect(() => {
    getDataFromServer();
    getSuppliersFromServer();
  }, []);

  const productsOptions = products.map((prod) => ({
    value: prod._id,
    label: prod.products.product_name,
  }));

  const suppliersOptions = suppliers.map((supplier) => ({
    value: supplier._id,
    label: supplier.supplier_name,
  }));

  const addPurchaseInventory = (e) => {
    e.preventDefault();
    //changeTab(1);

    if (!formData.po_date) {
      toast.error("Please select a PO Date!");
      return;
    }

    if (!formData.expiry_date) {
      toast.error("Please select a Product Expiry Date!");
      return;
    }

    if (!formData.product_id) {
      toast.error("Please select a Product to request & quantity!");
      return;
    }

    if (formData.quantity < 0) {
      toast.error("Please enter positive quantity to add in Inventory!");
      return;
    }

    // Show confirmation before add
    confirmAlert({
      title: "",
      message: "Are you sure to add it to Inventory?",
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmAddPurchase(),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });

    const confirmAddPurchase = () => {
      let userToken = localStorage.getItem("token");
      const config = {
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/state/purchases/add`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        data: { ...formData, p_register: true },
      };
      axios(config)
        .then(function (response) {
          toast.success("Request Created Successfully.");
          navigate("/list-purchases");
        })
        .catch(function (error) {
          toast.error(JSON.stringify(error.response.data.message));
          //console.log(JSON.stringify(error.response));
          if (error.response.data.message.toLowerCase().includes("invalid")) {
            navigate("/login", { replace: true });
          }
        });
    };
  };

  const handleChangeValue = ({ currentTarget: input }) => {
    // setFormData({
    //   ...formData,
    //   [input.name]: input.value,
    // });
    if (sanitizeInput(input.value, input.name)) {
      setFormData({
        ...formData,
        [input.name]: input.value,
      });
    }
  };

  const dropDownChange = (selected, dropdown) => {
    //console.log("this is selected", JSON.stringify(dropdown.name));
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className="col-md-12 mx-auto d-block d-flex signup-form">
        <form
          onSubmit={(e) => addPurchaseInventory(e)}
          className="mt-1 border p-4 shadow"
        >
          <div className="row flex flex-row items-justify-center">
            <div className="">
              <h5 className="text-secondary">ADD PURCHASE</h5>
            </div>
            {/* <div className="">
              <button className="btn-lg btn-danger float-end">Save</button>
            </div> */}
          </div>
          <hr />
          <br />
          <div className="row">
            <div className="mb-3 col-md-6">
              <label>
                Purchase Order No.<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="po_number"
                className="form-control"
                placeholder="Purchase Order No."
                onChange={handleChangeValue}
                value={formData.po_number}
                required
              />
            </div>
            <div className="mb-3 col-md-6">
              <label htmlFor="po_date">
                PO Date<span className="text-danger">*</span>
              </label>
              <DatePicker
                className="form-control datePicker"
                style={{ paddingLeft: "10px" }}
                name="po_date"
                onChange={(date, dateString) => {
                  setFormData({
                    ...formData,
                    po_date: dateString,
                  });
                }}
              />
            </div>
            <div className="mb-3 col-md-6">
              <label>
                Enter Product<span className="text-danger">*</span>
              </label>
              <Select
                name="product_id"
                onChange={(selected, dropdown) => {
                  setFormData({
                    ...formData,
                    product_id: selected.value,
                    item_code: products.find((p) => p.id === selected.value)
                      .item_code,
                  });
                }}
                value={productsOptions.find(
                  (prod) => prod.value === formData.product_id
                )}
                className="newPatientSelect"
                isSearchable
                options={productsOptions}
              />
            </div>
            <div className="mb-3 col-md-6">
              <label>
                Item code<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="item_code"
                className="form-control"
                value={formData.item_code}
                disabled
              />
            </div>
            <div className="mb-3 col-md-6">
              <label>
                Product Quantity<span className="text-danger">*</span>
              </label>
              <input
                type="number"
                name="quantity"
                className="form-control"
                placeholder="Product Quantity"
                min="1"
                onChange={handleChangeValue}
                value={formData.quantity}
                required
              />
            </div>
            <div className="mb-3 col-md-6">
              <label>Enter SKU</label>
              <input
                type="text"
                name="product_sku"
                className="form-control"
                placeholder="Product SKU"
                onChange={handleChangeValue}
                value={formData.product_sku}
              />
            </div>

            <div className="mb-3 col-md-6">
              <label>Shipment ID</label>
              <input
                type="text"
                name="shipment_id"
                className="form-control"
                placeholder="Shipment ID"
                onChange={handleChangeValue}
                value={formData.shipment_id}
              />
            </div>
            <div className="mb-3 col-md-6">
              <label>
                Batch ID<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="batch_id"
                className="form-control"
                placeholder="Batch ID"
                onChange={handleChangeValue}
                value={formData.batch_id}
                required
              />
            </div>

            <div className="mb-3 col-md-6">
              <label>
                Product Expiry Date<span className="text-danger">*</span>
              </label>
              <DatePicker
                className="form-control datePicker"
                style={{ paddingLeft: "10px" }}
                name="expiry_date"
                picker="month"
                format="YYYY-MM"
                onChange={(date, dateString) => {
                  setFormData({
                    ...formData,
                    expiry_date: dateString,
                  });
                }}
              />
            </div>
            <div className="mb-3 col-md-6">
              <label>Supplier</label>
              <Select
                name="supplier_id"
                onChange={dropDownChange}
                value={suppliersOptions.find(
                  (prod) => prod.value === formData.supplier_id
                )}
                className="newPatientSelect"
                isSearchable
                options={suppliersOptions}
              />
            </div>
            <div className="mb-3 col-md-6">
              <label>
                Unit Price<span className="text-danger">*</span>
              </label>
              <input
                type="number"
                name="unit_price"
                className="form-control"
                placeholder="Enter Unit Price"
                onChange={handleChangeValue}
                value={formData.unit_price}
                min="1" // Add this attribute to prevent negative numbers
              />
            </div>
            <div className="mb-3">
              <label>Comments</label>
              <textarea
                type="textarea"
                name="comments"
                className="form-control"
                placeholder=""
                rows="5"
                onChange={handleChangeValue}
                value={formData.comments}
              />
            </div>

            <div className="col-md-12">
              <button className="btn-lg btn-danger float-end">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPurchaseChild;

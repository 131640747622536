import React, { useState, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import SidebarLogo from "./SidebarLogo";
import {
  privateRoles,
  tcRoles,
  adminRoles,
  adminMenuRoles,
  stateAdminRole,
} from "../Data/UserRolesData";

//Context import
import AuthContext from "../shared/Context";

import "./LeftSideBar.css";
import PurchaseRequestMenu from "./PurchaseRequestMenu";
import StateInventoryMenu from "./StateInventoryMenu";
import TCInventoryMenu from "./TCInventoryMenu";
import SupplierMenu from "./SupplierMenu";

const LeftSideBar = () => {
  const { showFooterLogo, loggedInUserDetails, expandedMenu, setExpandedMenu } =
    useContext(AuthContext);

  //console.log(loggedInUserDetails);
  return (
    <div className="leftside-menu">
      {/* ========== Left Sidebar Start ========== */}
      <SidebarLogo />
      <div className="h-100" id="leftside-menu-container" data-simplebar>
        {/*- Sidemenu */}
        <ul className="side-nav">
          <li className="side-nav-title side-nav-item">Navigation</li>
          <li className="side-nav-item">
            <div
              onClick={() =>
                setExpandedMenu({
                  ...expandedMenu,
                  dashboard: !expandedMenu.dashboard,
                })
              }
              style={{ cursor: "pointer" }}
              className="side-nav-link"
            >
              <i className="uil-home-alt" />
              <span className="badge bg-success float-end">New</span>
              <span> Dashboards </span>
            </div>
            <div
              className={`${
                expandedMenu.dashboard ? "collapse show" : "collapse"
              }`}
              id="sidebarDashboards"
            >
              <ul className="side-nav-second-level">
                {/* Defined in UserRolesData */}
                {tcRoles.includes(loggedInUserDetails?.role) && (
                  <li>
                    <NavLink to="/dashboard-tc">Treament Center</NavLink>
                  </li>
                )}
                {/* Defined in UserRolesData */}
                {privateRoles.includes(loggedInUserDetails?.role) && (
                  <li>
                    <NavLink to="/dashboard-tc">Treament Center</NavLink>
                  </li>
                )}
                {/* Defined in UserRolesData */}
                {adminMenuRoles.includes(loggedInUserDetails?.role) && (
                  <li>
                    <NavLink to="/dashboard-state">State Analytics</NavLink>
                  </li>
                )}
              </ul>
            </div>
          </li>
          <li className="side-nav-title side-nav-item">Quick Menu</li>
          <li className="side-nav-item " style={{ backgroundColor: "#202020" }}>
            <NavLink to="/help-dash" className="side-nav-link">
              <i className="mdi mdi-information" style={{ color: "yellow" }} />
              <span className="text-darkgray mr-4"> Application Help</span>
              &nbsp;&nbsp;
              <i
                className="mdi mdi-help-circle"
                style={{ color: "lightblue" }}
              />
            </NavLink>{" "}
          </li>
          <li className="side-nav-item">
            <NavLink to="/upcoming/appointments" className="side-nav-link">
              <i className="mdi mdi-calendar-clock" /> Upcoming Appointments
            </NavLink>
          </li>
          <li className="side-nav-item">
            <NavLink to="/pre/register/list" className="side-nav-link">
              <i className="mdi mdi-clipboard-account" /> Pre Registrations
            </NavLink>
          </li>
          <li className="side-nav-item">
            <div
              onClick={() =>
                setExpandedMenu({
                  ...expandedMenu,
                  patients: !expandedMenu.patients,
                })
              }
              style={{ cursor: "pointer" }}
              className="side-nav-link"
            >
              <i className="uil-user" />
              <span> Patients </span>
              {!expandedMenu.patients && <span className="menu-arrow" />}
              {expandedMenu.patients && (
                <span
                  className="menu-arrow"
                  style={{ transform: "rotate(90deg)" }}
                />
              )}
            </div>
            <div
              className={`${
                expandedMenu.patients ? "collapse show" : "collapse"
              }`}
            >
              <ul className="side-nav-second-level">
                <li>
                  <NavLink to="/patients">
                    <i className="uil-document-layout-left" /> List Patients
                  </NavLink>
                </li>
                {tcRoles.includes(loggedInUserDetails?.role) && (
                  <li>
                    <NavLink to="/add-diagnosis">
                      <i className="uil-user-plus" /> Add Patients
                    </NavLink>
                  </li>
                )}
                {privateRoles.includes(loggedInUserDetails?.role) && (
                  <li>
                    <NavLink to="/add-diagnosis">
                      <i className="uil-user-plus" /> Add Patients
                    </NavLink>
                  </li>
                )}
                {tcRoles.includes(loggedInUserDetails?.role) && (
                  <li>
                    <NavLink to="/global/search">
                      <i className="uil-search" /> Search Patients
                    </NavLink>
                  </li>
                )}
                {privateRoles.includes(loggedInUserDetails?.role) && (
                  <li>
                    <NavLink to="/global/search">
                      <i className="uil-search" /> Search Patients
                    </NavLink>
                  </li>
                )}
                {loggedInUserDetails?.patient_import_status && (
                  <li>
                    <NavLink to="/import-patients">
                      <i className="mdi mdi-file-import" /> Import Patients
                    </NavLink>
                  </li>
                )}
                {/* <li>
                  <NavLink to="/upcoming/appointments">
                    <i className="mdi mdi-calendar-clock" /> Upcoming
                    Appointments
                  </NavLink>
                </li> */}
                {/* <li>
                  <NavLink to="/testimonials">
                    <i className="mdi mdi-chat-outline" /> Testimonials
                  </NavLink>
                </li> */}
              </ul>
            </div>
          </li>
          <li className="side-nav-item">
            <div
              onClick={() =>
                setExpandedMenu({
                  ...expandedMenu,
                  reports: !expandedMenu.reports,
                })
              }
              style={{ cursor: "pointer" }}
              className="side-nav-link"
            >
              <i className="mdi mdi-google-analytics" />
              <span> Reports </span>
              {!expandedMenu.reports && <span className="menu-arrow" />}
              {expandedMenu.reports && (
                <span
                  className="menu-arrow"
                  style={{ transform: "rotate(90deg)" }}
                />
              )}
            </div>
            <div
              className={`${
                expandedMenu.reports ? "collapse show" : "collapse"
              }`}
              id="sidebarEmail"
            >
              <ul className="side-nav-second-level">
                <li>
                  <NavLink to="/reports/generic">Generic Reports</NavLink>
                </li>
                <li>
                  <NavLink to="/reports/severitywise">Severity-wise</NavLink>
                </li>
                <li>
                  <NavLink to="/reports/jointswise">Joints-wise</NavLink>
                </li>
                <li>
                  <NavLink to="/reports/bleedwise">Bleed-wise</NavLink>
                </li>
              </ul>
            </div>
          </li>
          <li className="side-nav-title side-nav-item">Drug Inventory</li>
          {/* Suppliers Menu for TCs*/}
          {tcRoles.includes(loggedInUserDetails?.role) && (
            <SupplierMenu
              setExpandedMenu={setExpandedMenu}
              expandedMenu={expandedMenu}
            />
          )}
          {/* Suppliers Menu for Admins*/}
          {adminRoles.includes(loggedInUserDetails?.role) && (
            <SupplierMenu
              setExpandedMenu={setExpandedMenu}
              expandedMenu={expandedMenu}
            />
          )}
          <li className="side-nav-item">
            <NavLink to="/products" className="side-nav-link">
              <i className="uil-capsule" />
              <span> Product Master </span>
            </NavLink>
          </li>
          {/* Purchase Request Menu for TCs */}
          {tcRoles.includes(loggedInUserDetails?.role) && (
            <PurchaseRequestMenu
              setExpandedMenu={setExpandedMenu}
              expandedMenu={expandedMenu}
            />
          )}
          {adminMenuRoles.includes(loggedInUserDetails?.role) && (
            <PurchaseRequestMenu
              setExpandedMenu={setExpandedMenu}
              expandedMenu={expandedMenu}
              tcRoles={tcRoles && tcRoles}
              loggedInUserDetails={loggedInUserDetails && loggedInUserDetails}
            />
          )}
          {/* StateInventory for  States and Admin only */}
          {adminRoles.includes(loggedInUserDetails?.role) && (
            <StateInventoryMenu
              setExpandedMenu={setExpandedMenu}
              expandedMenu={expandedMenu}
            />
          )}
          {stateAdminRole.includes(loggedInUserDetails?.role) && (
            <StateInventoryMenu
              setExpandedMenu={setExpandedMenu}
              expandedMenu={expandedMenu}
            />
          )}
          {/* TC Inventory  for TC's Only */}
          {tcRoles.includes(loggedInUserDetails?.role) && (
            <TCInventoryMenu
              setExpandedMenu={setExpandedMenu}
              expandedMenu={expandedMenu}
            />
          )}

          <li className="side-nav-item">
            <NavLink to="/recommndeddrugs" className="side-nav-link">
              <i className="mdi mdi-pharmacy" />
              <span className="badge bg-danger text-light float-end">New</span>
              <span> Recommended Drugs </span>
            </NavLink>
          </li>

          <li className="side-nav-title side-nav-item">KnowledgeBase</li>
          <li className="side-nav-item">
            <NavLink to="/listkb" className="side-nav-link">
              <i className="mdi mdi-newspaper" />
              <span className="badge bg-primary text-light float-end">New</span>
              <span> List Papers </span>
            </NavLink>
          </li>
          {adminMenuRoles.includes(loggedInUserDetails?.role) && (
            <li className="side-nav-title side-nav-item">Administrator</li>
          )}
          {adminMenuRoles.includes(loggedInUserDetails?.role) && (
            <li className="side-nav-item">
              <NavLink to="/users" className="side-nav-link">
                <i className="uil-user" />
                <span className="badge bg-secondary text-light float-end">
                  New
                </span>
                <span> Users </span>
              </NavLink>
            </li>
          )}
          {!adminMenuRoles.includes(loggedInUserDetails?.role) && (
            <li className="side-nav-item">
              <NavLink to="/doctors" className="side-nav-link">
                <i className="uil-user" />
                <span className="badge bg-secondary text-light float-end">
                  New
                </span>
                <span> Doctors </span>
              </NavLink>
            </li>
          )}
          {adminMenuRoles.includes(loggedInUserDetails?.role) && (
            <li className="side-nav-item">
              <NavLink to="/roles" className="side-nav-link">
                <i className="mdi mdi-shield-account" />
                <span> Roles </span>
              </NavLink>
            </li>
          )}
          {adminMenuRoles.includes(loggedInUserDetails?.role) && (
            <li className="side-nav-item">
              <NavLink to="/tcenters" className="side-nav-link">
                <i className="mdi mdi-city-variant-outline" />
                <span> Treatment Centers </span>
              </NavLink>
            </li>
          )}
          {adminMenuRoles.includes(loggedInUserDetails?.role) && (
            <li className="side-nav-item">
              <NavLink to="/diagnosis" className="side-nav-link">
                <i className="mdi mdi-medical-bag" />
                <span> Diagnosis Menu </span>
              </NavLink>
            </li>
          )}
          {adminMenuRoles.includes(loggedInUserDetails?.role) && (
            <li className="side-nav-item">
              <NavLink to="/entrylogs" className="side-nav-link">
                <i className="mdi mdi-pencil-circle" />
                <span> Entry Logs </span>
              </NavLink>
            </li>
          )}
          {adminMenuRoles.includes(loggedInUserDetails?.role) && (
            <li className="side-nav-item">
              <NavLink to="/errorlogs" className="side-nav-link">
                <i className="mdi mdi-bug-outline" />
                <span> Error Logs </span>
              </NavLink>
            </li>
          )}
          {adminMenuRoles.includes(loggedInUserDetails?.role) && (
            <li className="side-nav-item">
              <NavLink to="/activitylogs" className="side-nav-link">
                <i className="mdi mdi-clock-alert" />
                <span> Activity Logs </span>
              </NavLink>
            </li>
          )}
          {/* disable before final rollout */}
          {/* <li className="side-nav-item">
            <NavLink to="/errorlogs" className="side-nav-link">
              <i className="mdi mdi-bug-outline" />
              <span> Error Logs </span>
            </NavLink>
          </li> */}
          {/* --end-- */}
        </ul>
        {/* End Sidebar */}
        <span className="collapse sidebar-collapse display-logo">
          <div
            className="help-box text-white d-flex justify-content-center"
            style={{ marginTop: "50px", backgroundColor: "white" }}
          >
            <img
              src="assets/images/brands/Roche_Logo_500px_Blue_RGB.png"
              alt
              height={30}
            />
          </div>
        </span>
        <div className="clearfix" />
      </div>
      {/* Sidebar -left */}
    </div>
  );
};

export default LeftSideBar;

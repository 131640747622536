import React, { useContext, useRef } from "react";
import { Modal } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import Badge from "react-bootstrap/Badge";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

//Context import
import AuthContext from "../../../components/shared/Context";

const DoctorsListChild = () => {
  const { checkLoggedInStatus, loggedInUserDetails } = useContext(AuthContext);
  //Error Logging Service
  const [{ errorLogApi, customEncrypt }] = useAtom(gbState);
  //Sample Call: errorLogApi(err, "UsersListChild", "/users");

  //To check if the user is logged in
  checkLoggedInStatus();
  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  let navigate = useNavigate();
  const searchRef = useRef();
  const checkRole = [""];

  const handleDelete = (row) => {
    toast.error(`${row.user_type} cannot be removed!`);
  };

  function isRecordNew(createdDate) {
    const today = new Date();
    createdDate = new Date(createdDate);

    return (
      createdDate.getFullYear() === today.getFullYear() &&
      createdDate.getMonth() === today.getMonth() &&
      createdDate.getDate() === today.getDate()
    );
  }

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");

    let urlLink = `${process.env.REACT_APP_API_URL}/user/doc/${loggedInUserDetails?.tc_id}`;

    if (
      loggedInUserDetails.role === "ST_ADM_ACCESS" ||
      loggedInUserDetails.role === "TC_ADM_ACCESS"
    ) {
      urlLink = `${process.env.REACT_APP_API_URL}/user/state/${customEncrypt(
        loggedInUserDetails.state
      )}`;
    }

    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data.users));
        const oldData = response.data.doctors;
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data._id,
            name: `${data.first_name} ${
              data.last_name !== null ? data.last_name : ""
            }`,
            email: data.email,
            role: data.role,
            status: data.user_type,
            state: data.tc_state,
            district: data.tc_district,
          };
        });
        //console.log(updatedData);
        if (loggedInUserDetails.role === "TC_ADM_ACCESS") {
          const filteredData = updatedData.filter(
            (data) => data.tc_id === loggedInUserDetails.tc_id
          );
          setData(filteredData);
          setUnfilteredData(filteredData);
        } else {
          setData(updatedData);
          setUnfilteredData(updatedData);
        }
      })
      .catch(function (err) {
        // console.log(error.message);
        errorLogApi(err, "UsersListChild", "/users");
        //console.log("error api call ", err.response.data.message);
        if (err.response.data.message) {
          if (err.response.data.message.toLowerCase().includes("invalid")) {
            navigate("/login");
          }
        }
      });
  };

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");

      let urlLink = `${process.env.REACT_APP_API_URL}/user/doc/${loggedInUserDetails?.tc_id}`;

      if (
        loggedInUserDetails.role === "ST_ADM_ACCESS" ||
        loggedInUserDetails.role === "TC_ADM_ACCESS"
      ) {
        urlLink = `${process.env.REACT_APP_API_URL}/user/state/${customEncrypt(
          loggedInUserDetails.state
        )}`;
      }

      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          // console.log(response.data.doctors);
          const oldData = response.data.doctors;
          const updatedData = oldData.map((data) => {
            return {
              ...data,
              id: data._id,
              name: `${data.first_name} ${
                data.last_name !== null ? data.last_name : ""
              }`,
              email: data.email,
              role: data.role,
              status: data.user_type,
              state: data.tc_state,
              district: data.tc_district,
              tc_detail: data.tc_id,
            };
          });
          //console.log(updatedData);
          if (loggedInUserDetails.role === "TC_ADM_ACCESS") {
            const filteredData = updatedData.filter(
              (data) => data.tc_id === loggedInUserDetails.tc_id
            );
            setData(filteredData);
            setUnfilteredData(filteredData);
          } else {
            setData(updatedData);
            setUnfilteredData(updatedData);
          }
        })
        .catch(function (err) {
          // console.log(error.message);
          errorLogApi(err, "UsersListChild", "/users");
          // console.log("error api call ", err.response.data.message);
          if (err.response.data.message) {
            if (err.response.data.message.toLowerCase().includes("invalid")) {
              navigate("/login");
            }
          }
        });
    };
    getDataFromServer();
  }, []);

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            <Tooltip title="View User">
              <a onClick={() => viewRecord(params.row)}>
                <i
                  className="mdi mdi-eye-circle-outline"
                  style={{
                    fontSize: "15px",
                    color: "black",
                    cursor: "pointer",
                  }}
                ></i>
              </a>
            </Tooltip>
            <Tooltip title="Edit User">
              <a
                onClick={() =>
                  navigate("/doctor/update/", { state: params.row })
                }
              >
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                />
              </a>
            </Tooltip>
            <Tooltip title="Delete User">
              <div onClick={() => handleDelete(params.row)}>
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Full Name",
      width: 200,
      renderCell: (params) => {
        return (
          <Tooltip
            title={
              params?.row?.account_status
                ? "Active Account"
                : "Disabled Account"
            }
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <span
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  backgroundColor: params?.row?.account_status
                    ? "green"
                    : "red",
                  marginRight: "5px",
                }}
              />
              <span className="text-bold"> {params?.row?.name} </span>&nbsp;{" "}
              {isRecordNew(params?.row?.createdAt) && (
                <Badge bg="primary" style={{ fontSize: "8px" }}>
                  New
                </Badge>
              )}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 210,
    },

    {
      field: "status",
      headerName: "Role",
      width: 190,
    },
    {
      field: "tc_detail",
      headerName: "TC Details",
      width: 200,
      renderCell: (params) =>
        params?.row?.tc_detail ? (
          <TCDetail tcid={params?.row?.tc_detail} />
        ) : (
          ""
        ),
    },

    {
      field: "district",
      headerName: "District",
      width: 140,
    },
    {
      field: "state",
      headerName: "State",
      width: 140,
    },
  ];

  const handleAddUser = () => {
    navigate("/users/add");
  };

  const handleAddDoctor = () => {
    navigate("/users/add-doctor");
  };

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };

  // const unfilterData = data;
  const handleSearch = (e) => {
    // console.log("Search Value...", e);

    if (e) {
      setData(
        unfilteredData.filter((item) => {
          return (
            item?.name?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.email?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.status?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.state?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.district?.toLowerCase().includes(e?.toLowerCase())
          );
        })
      );
    } else {
      setData(unfilteredData);
      getDataFromServer();
    }
  };

  const clearSearch = () => {
    searchRef.current.value = null;
    getDataFromServer();
  };

  const userRoles = ["TC_USER_ACCESS", "PVT_USER_ACCESS"];

  return (
    <div className="patient-lis1">
      <div>
        <h2>Doctors</h2>
      </div>
      <div className="patientUpdateButton">
        {userRoles.includes(loggedInUserDetails.role) && (
          <Button variant="primary" size="medium" onClick={handleAddDoctor}>
            Add Doctor
          </Button>
        )}
      </div>
      <div className="patientListSearch">
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">Filter</InputGroup.Text>
          <Form.Control
            placeholder="Doctor ..."
            aria-label="Search"
            aria-describedby="basic-addon1"
            name="searchPatients"
            ref={searchRef}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <InputGroup.Text>
            <i
              className="mdi mdi-delete-outline"
              onClick={clearSearch}
              style={{ cursor: "pointer" }}
            ></i>
          </InputGroup.Text>
        </InputGroup>
      </div>
      <DataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        displayTitle="Blood Products"
        checkboxSelection
        style={{ height: "60vh" }}
      />

      {/* {JSON.stringify(data)} */}

      {/* Modal Popup */}
      <Modal show={openModal} size="lg" centered>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent currentRecord={currentRecord} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

const TCDetail = ({ tcid }) => {
  const [tcenter, setTcenter] = useState(null);
  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");
      const config = {
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/tc/${tcid}`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          //console.log(response.data.treatment_center.tcenters);
          setTcenter(response.data.treatment_center.tcenters);
        })
        .catch(function (err) {
          //console.log(error.message);
          //errorLogApi(err, "UsersListChild", "/users");
        });
    };

    if (tcid) {
      getDataFromServer();
    } else {
      setTcenter({ name_tc: null });
    }
  }, []);

  return (
    <>
      {tcenter?.name_tc ? (
        <div>
          <div className="text-bold">{tcenter?.name_tc}</div>
          <div style={{ fontSize: "11px", marginTop: "-35px" }}>
            <Badge
              bg={`${tcenter?.tc_type === "private" ? "primary" : "success"}`}
            >
              {tcenter?.tc_type && tcenter?.tc_type.toUpperCase()}
            </Badge>
          </div>
        </div>
      ) : (
        "Loading..."
      )}
    </>
  );
};

const ModalContent = ({ currentRecord }) => {
  const [tcenter, setTcenter] = useState();
  const [{ errorLogApi }] = useAtom(gbState);

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");
      const config = {
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/tc/${currentRecord?.tc_id}`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          setTcenter(response.data.treatment_center.tcenters);
        })
        .catch(function (err) {
          //console.log(error.message);
          errorLogApi(err, "UsersListChild", "/users");
        });
    };
    if (currentRecord?.tc_id) {
      getDataFromServer();
    }
  }, []);

  function dateFormatter(date) {
    const date_conv = new Date(date);
    const fullyear = date_conv.getFullYear();
    const month = date_conv.getMonth() + 1;
    const day = date_conv.getDate();
    return `${day}/${month}/${fullyear}`;
  }

  return (
    <div className="container-fluid legend">
      <Card>
        <Card.Header>
          <i className="fas fa-align-justify" />
          <strong>User Details</strong>
        </Card.Header>
        <Card.Body>
          <CardTableRow
            rowName="Name"
            rowValue={
              currentRecord?.first_name + " " + currentRecord?.last_name
            }
          />
          <CardTableRow rowName="Email" rowValue={currentRecord?.email} />
          <CardTableRow rowName="Phone" rowValue={currentRecord?.mobile} />
          <CardTableRow
            rowName="Account Type"
            rowValue={currentRecord?.user_type}
          />
          <CardTableRow
            rowName="Account Status"
            rowValue={
              currentRecord?.account_status ? (
                <Badge bg="success">Enabled</Badge>
              ) : (
                <Badge bg="danger">Disabled</Badge>
              )
            }
          />
          <CardTableRow rowName="State" rowValue={currentRecord?.tc_state} />
          <CardTableRow
            rowName="District"
            rowValue={currentRecord?.tc_district}
          />

          <CardTableRow
            rowName="Created AT"
            rowValue={
              currentRecord?.createdAt &&
              dateFormatter(currentRecord?.createdAt)
            }
          />
        </Card.Body>
      </Card>
      {currentRecord.tc_id && (
        <Card>
          <Card.Header>
            <i className="fas fa-align-justify" />
            <strong>Treatment Center Details</strong>
          </Card.Header>
          <Card.Body>
            <CardTableRow rowName="TC Name" rowValue={tcenter?.name_tc} />
            <CardTableRow
              rowName="Institution"
              rowValue={tcenter?.institution}
            />
            <CardTableRow rowName="HDCC No." rowValue={tcenter?.hdcc_no} />
            <CardTableRow
              rowName="Primary Email"
              rowValue={tcenter?.primary_email}
            />
            <CardTableRow
              rowName="Secondary Email"
              rowValue={tcenter?.secondary_email}
            />
            <CardTableRow rowName="Website" rowValue={tcenter?.website} />
            <CardTableRow rowName="Phone" rowValue={tcenter?.phone} />
            <CardTableRow rowName="Fax" rowValue={tcenter?.fax} />
            <CardTableRow rowName="State" rowValue={tcenter?.state} />
            <CardTableRow rowName="District" rowValue={tcenter?.district} />
            <CardTableRow rowName="Pincode" rowValue={tcenter?.pincode} />
            <CardTableRow
              rowName="Address Line1"
              rowValue={tcenter?.address_line1}
            />
            <CardTableRow
              rowName="Address Line2"
              rowValue={tcenter?.address_line2}
            />
            <CardTableRow rowName="Landmark" rowValue={tcenter?.landmark} />
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

const CardTableRow = ({ rowName, rowValue }) => {
  return (
    <div className="row">
      <div className="col-md-6 col-xs-6 divTableCell">
        <b> {rowName}: </b>
      </div>
      <div
        className="col-md-6 col-xs-6 divTableCell"
        style={{ wordWrap: "break-word;" }}
      >
        {rowValue}
      </div>
    </div>
  );
};

export default DoctorsListChild;

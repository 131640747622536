import { useState, useEffect } from "react";

import "./NotificationStyle.css";

const NotificationComponent = ({ notificationsData, markAsRead }) => {
  const [notifications, setNotifications] = useState(notificationsData);

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    // Options for the date format
    const dateOptions = {
      weekday: "long", // "Tuesday"
      year: "numeric", // "2024"
      month: "long", // "April"
      day: "numeric", // "30"
    };
    // Options for the time format
    const timeOptions = {
      hour: "2-digit", // "09"
      minute: "2-digit", // "31"
      second: "2-digit", // "16"
      hour12: true, // AM/PM format
    };
    // Combine date and time with custom formatting
    const formattedDate =
      date.toLocaleDateString("en-US", dateOptions) +
      " at " +
      date.toLocaleTimeString("en-US", timeOptions);

    return formattedDate;
  };

  return (
    <div
      className="align-middle d-none d-sm-inline-block"
      style={{ wordBreak: "break-word", maxWidth: "100%", overflow: "hidden" }}
    >
      {notifications?.map((n, index) => (
        <>
          <div
            key={index}
            className={`p-2 rounded-2 mt-1 ${
              n.isRead
                ? "notification-bg-green notification-g-hover"
                : "notification-bg notification-hover"
            }`} // Using CSS classes
          >
            <div
              className="float-start text-uppercase"
              style={{ fontSize: ".6rem" }}
            >
              {formatDate(n?.createdAt)}
            </div>
            <br />
            <div className="notification-separator"></div>
            <div className="text-justify">
              {/* Changed to use the text-justify class */}
              {n.message}
              <div className="float-end">
                {!n.isRead && (
                  <i
                    className="mdi mdi-check-circle-outline read-button"
                    onClick={() => markAsRead(n?._id)}
                  ></i>
                )}
              </div>
            </div>
          </div>
        </>
      ))}
    </div>
  );
};

export default NotificationComponent;

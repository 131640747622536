import { DataGrid } from "@material-ui/data-grid";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext, useRef } from "react";
// import { Modal } from "react-bootstrap";
import { Row, Col, Button, Modal } from "react-bootstrap";

import Tooltip from "@mui/material/Tooltip";
import { confirmAlert } from "react-confirm-alert";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker, Space } from "antd";
import Select from "react-select";
//import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import Badge from "react-bootstrap/Badge";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import "./Modal.css";
//Context import
import AuthContext from "../../../components/shared/Context";

import GeneralDetailsCard from "./GeneralDetailsCard";
import ClinicalDetailsCard from "./ClinicalDetailsCard";
import BleedingHistoryCard from "./BleedingHistoryCard";
import VaccinationDetailsCard from "./VaccinationDetailsCard";
import AddressDetailsCard from "./AddressDetailsCard";
import ContactDetailsCard from "./ContactDetailsCard";
import EconomicStatusCard from "./EconomicStatusCard";
import SchedulingDetailsCard from "./SchedulingDetailsCard";
import { toast } from "react-hot-toast";
import jwt_decode from "jwt-decode";

import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import ExportToExcel from "./ExportToExcel";

const { RangePicker } = DatePicker;

const PatientListChild = () => {
  let navigate = useNavigate();
  //Error Logging Service
  const [{ errorLogApi, customEncrypt, decryptData, maskAadhaarString }] =
    useAtom(gbState);
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [unfilteredDiagnosisData, setUnfilteredDiagnosisData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [diagnosisCategory, setDiagnosisCategory] = useState();
  const [filterDiagnosis, setFilterDiagnosis] = useState();
  const [selectedIds, setSelectedIds] = useState([]);

  const searchRef = useRef();
  const { loggedInUserDetails, setPatientDiagnosis, setFieldLabelFromArray } =
    useContext(AuthContext);

  const [result, setResult] = useState(null);
  const [durationResult, setDurationResult] = useState([]);
  //date range selector code
  const [dates, setDates] = useState(null);
  const [dateValue, setDateValue] = useState(null);
  const [dateInterval, setDateInterval] = useState({});
  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], "days") > 365;
    const tooEarly = dates[1] && dates[1].diff(current, "days") > 365;
    return !!tooEarly || !!tooLate;
  };
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const searchRecordsBetweenDates = (field) => {
    if (dateInterval) {
      //alert("started");
      const data_res = data.filter((d) => {
        var time = new Date(d[field]).getTime();
        return dateInterval.sd <= time && time <= dateInterval.ed;
      });
      setDurationResult(data_res);
      setData(data_res);
    }
  };

  useEffect(() => {
    if (dateValue) {
      setDateInterval({
        sd: new Date(dateValue[0]).getTime(),
        ed: new Date(dateValue[1]).getTime(),
      });
    }
  }, [dateValue]);

  const handleDelete = (id) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this patient ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (id) => {
    let userToken = localStorage?.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/patient/delete/p/${id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then((response) => {
        // alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((err) => {
        //console.log(err);
        errorLogApi(err, "PatientListChild", "/patient/delete");
      });
  };

  function dateFormatter(date) {
    const date_conv = new Date(date);
    const fullyear = date_conv.getFullYear();
    const month = date_conv.getMonth() + 1;
    const day = date_conv.getDate();
    return `${day}/${month}/${fullyear}`;
  }

  const useStyles = makeStyles(() => ({
    multiLineCell: {
      display: "flex",
      wordBreak: "keep-all",
      wordWrap: "break-word",
      overflowWrap: "break-word",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      lineHeight: "1.2rem",
      height: "auto",
    },
  }));

  const MultiLineCellRenderer = (params) => {
    const classes = useStyles();
    return (
      <div className={classes.multiLineCell}>
        <Tooltip
          title={`${params?.row?.patient_death_status ? "Deceased" : "Alive"}`}
        >
          <span
            id="content1"
            style={{ display: "flex", alignItems: "center", lineHeight: "1" }}
          >
            <div
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                backgroundColor: params?.row?.patient_death_status
                  ? "red"
                  : "#87CEEB",
                marginRight: "5px",
              }}
            ></div>

            <b>{params?.row?.patient_name.toUpperCase()}</b>
          </span>
        </Tooltip>
        <span
          id="content2"
          style={
            params?.row?.id_card?.card_number
              ? { paddingLeft: "0px" }
              : { paddingLeft: "0px" }
          }
        >
          <small className="small-font-size">
            {/* <Badge bg={`secondary`}>{params.row.patientId}</Badge> */}
            {/* mdi-close-circle */}
            {params?.row?.id_card?.card_number ? (
              <Tooltip
                title={maskAadhaarString(
                  decryptData(params?.row?.id_card?.card_number)
                )}
              >
                <i
                  className="mdi mdi-check-circle"
                  style={{
                    fontSize: "12px",
                    color: "green",
                  }}
                ></i>
              </Tooltip>
            ) : (
              <Tooltip title={"Adhaar Card Not Available"}>
                <i
                  className="mdi mdi-close-circle"
                  style={{
                    fontSize: "12px",
                    color: "red",
                  }}
                ></i>
              </Tooltip>
            )}{" "}
            {params?.row?.patientId}
          </small>
        </span>
      </div>
    );
  };

  const convertDate = (isoDateString) => {
    // const isoDateString = "2023-02-07T05:19:05";
    const date = new Date(isoDateString);

    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  const DeleteStatusMultiCellRender = (params) => {
    const classes = useStyles();

    let content;

    if (params?.row?.delete_status) {
      content = (
        <div className={classes.multiLineCell}>
          <Badge bg="danger">&nbsp;&nbsp;DELETED&nbsp;&nbsp;</Badge>
          <span id="content2" style={{ paddingLeft: "0px" }}>
            <small className="small-font-size">
              {convertDate(params?.row?.delete_status)}
            </small>
          </span>
        </div>
      );
    } else {
      content = (
        <div className={classes.multiLineCell}>
          <Badge bg="success">&nbsp;&nbsp;CREATED&nbsp;&nbsp;</Badge>
          <span id="content2" style={{ paddingLeft: "0px" }}>
            <small className="small-font-size">
              {params?.row?.createdAt && convertDate(params?.row?.createdAt)}
            </small>
          </span>
        </div>
      );
    }

    return content;
  };

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      filterable: false,
      disableColumnMenu: true,
      width: 150,
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            <Tooltip title="View Patient">
              <a onClick={() => viewRecord(params?.row)}>
                <i
                  className="mdi mdi-eye-circle-outline"
                  style={{
                    fontSize: "15px",
                    color: "black",
                    cursor: "pointer",
                  }}
                ></i>
              </a>
            </Tooltip>
            <Tooltip title="Appointment Center">
              <a
                onClick={() => {
                  if (!params?.row?.patient_death_status)
                    return navigate(`/appointmentlist/`, {
                      state: params?.row,
                    });

                  return toast.error(
                    "Sorry Cannot create Appointment for deceased patient"
                  );
                }}
              >
                <i
                  className="mdi mdi-clock-alert-outline"
                  style={{
                    fontSize: "15px",
                    color: "blue",
                    cursor: "pointer",
                  }}
                />
              </a>
            </Tooltip>
            <Tooltip title="Report">
              <a
                onClick={() =>
                  navigate(`/report/`, {
                    state: params?.row,
                  })
                }
              >
                <i
                  className="mdi mdi-chart-line-variant"
                  style={{
                    fontSize: "15px",
                    color: "black",
                    cursor: "pointer",
                  }}
                ></i>
              </a>
            </Tooltip>

            <Tooltip title="Edit Patient">
              <a
                onClick={() =>
                  navigate(`/patient/`, { state: params?.row?.id })
                }
              >
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                />
              </a>
            </Tooltip>

            <Tooltip title="Health Record">
              <a
                onClick={() =>
                  navigate(`/listhrecord/`, { state: params?.row })
                }
              >
                <i
                  className="mdi mdi-heart-pulse"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                />
              </a>
            </Tooltip>
            <Tooltip title="Delete Patient">
              <div
                onClick={() => {
                  if (params?.row?.delete_status) {
                    toast.error("Already Deleted Record");
                  } else {
                    handleDelete(params?.row._id);
                  }
                }}
              >
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Create a Visit">
              <div
                onClick={() => {
                  if (!params?.row?.patient_death_status)
                    return navigate(`/create-visit/`, {
                      state: params?.row,
                    });

                  return toast.error(
                    "Sorry Cannot create visit for deceased patient"
                  );
                }}
              >
                <i
                  className="mdi mdi-plus-box"
                  style={{
                    fontSize: "15px",
                    color: "green",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </Tooltip>
            {/* </Link> */}
            <Tooltip title="List Visits">
              <div
                onClick={() => {
                  if (params?.row?.delete_status) {
                    toast.error(
                      "Deleted Record, please recover record to view"
                    );
                    return;
                  }
                  return navigate(`/visits/`, {
                    state: params?.row,
                  });
                }}
              >
                <i
                  className="mdi mdi-view-list"
                  style={{
                    fontSize: "16px",
                    color: "green",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </Tooltip>
            {/* </Link> */}
            <Tooltip title="Record Patient Death">
              <div
                onClick={() => {
                  if (params?.row?.delete_status) {
                    toast.error(
                      "Deleted Record, please recover record to view"
                    );
                    return;
                  }
                  if (params?.row?.patient_death_status) {
                    return navigate(`/patient/drecord/view`, {
                      state: params?.row,
                    });
                  }
                  return navigate(`/patient/drecord`, {
                    state: params?.row,
                  });
                }}
              >
                <i
                  className="mdi mdi-heart-off"
                  style={{
                    fontSize: "15px",
                    color: "red",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    loggedInUserDetails?.role === "FULL_ACCESS"
      ? {
          field: "delete_status",
          headerName: "Status",
          width: 130,
          renderCell: DeleteStatusMultiCellRender,
        }
      : "",
    {
      field: "patient_name",
      headerName: "Patient Name",
      width: 230,
      renderCell: MultiLineCellRenderer,
    },
    {
      field: "diagnosis",
      headerName: "Diagnosis",
      width: 200,
    },
    // { field: "patientId", headerName: "iCareID", width: 190 },

    {
      field: "createdAt",
      headerName: "Registered",
      filterable: false,
      disableColumnMenu: true,
      align: "center",
      width: 130,
      renderCell: (params) => {
        return convertDate(params?.row?.createdAt);
      },
    },
    {
      field: "last_visit",
      headerName: "Last Visited",
      filterable: false,
      disableColumnMenu: true,
      align: "center",
      width: 160,
      renderCell: (params) => {
        return <CheckLastVisit patient_id={params?.row?._id} />;
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      renderCell: (params) => params?.row?.contact?.email,
    },
    {
      field: "bloodgroup",
      headerName: "Blood Group",
      filterable: false,
      disableColumnMenu: true,
      width: 140,
      align: "center",
      renderCell: (params) => setFieldLabelFromArray(params?.row?.bloodgroup),
    },
    { field: "state", headerName: "State", width: 120 },
    {
      field: "center",
      headerName: "Center",
      width: 200,
      renderCell: ({ row }) => {
        return row?.tc_detail?.tcenters?.name_tc;
      },
    },
  ];
  const unfilterData = data;
  const handleSearch = (e) => {
    e = e.trim();
    if (e) {
      setData(
        unfilteredData.filter((item) => {
          const lowerCaseSearchTerm = e.toLowerCase();

          // Check and compare if the property exists
          const isPatientNameMatch = item.patient_name
            ?.toLowerCase()
            .includes(lowerCaseSearchTerm);
          const isPatientIdMatch = item.patientId
            ?.toLowerCase()
            .includes(lowerCaseSearchTerm);
          const isDiagnosisMatch = item.diagnosis
            ?.toLowerCase()
            .includes(lowerCaseSearchTerm);
          const isEmailMatch = item.contact?.email
            ?.toLowerCase()
            .includes(lowerCaseSearchTerm);
          const isTcMatch = item?.tc_detail?.tcenters?.name_tc
            ?.toLowerCase()
            .includes(lowerCaseSearchTerm);
          const isCreatedAtMatch = item.createdAt
            ?.toLowerCase()
            .includes(lowerCaseSearchTerm);

          return (
            isPatientNameMatch ||
            isPatientIdMatch ||
            isDiagnosisMatch ||
            isEmailMatch ||
            isTcMatch ||
            isCreatedAtMatch
          );
        })
      );
    } else {
      //  getDataFromServer(); // Fetch data again from the server
      setData(unfilteredData); // Reset the data to unfiltered state
    }
  };

  const getDataFromServer = () => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage?.getItem("token")}` },
    };

    const bodyParameters = {
      key: "value",
    };

    let urlLink = `${process.env.REACT_APP_API_URL}/patient/${customEncrypt(
      loggedInUserDetails?.tc_id
    )}`;
    if (loggedInUserDetails?.role === "FULL_ACCESS") {
      urlLink = `${process.env.REACT_APP_API_URL}/patient/`;
    }

    if (loggedInUserDetails?.role === "ST_ADM_ACCESS") {
      urlLink = `${process.env.REACT_APP_API_URL}/patient/state/${loggedInUserDetails?.state}`;
    }

    axios
      .get(urlLink, config)
      .then((res) => {
        const oldData = res?.data?.patients;
        const updatedData = oldData?.map((data) => {
          return {
            ...data,
            id: data?._id,
            diagnosis: data?.diagnoses?.diagnosis_name,
            state: data?.tc_state,
            residential_address: data?.residential_address.address,
          };
        });
        //console.log(updatedData);
        setData(updatedData);
        setUnfilteredData(updatedData);
        setUnfilteredDiagnosisData(updatedData);
      })
      .catch((err) => {
        errorLogApi(err, "PatientListChild", urlLink);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 3000); // 3 seconds delay
      });
  };

  useEffect(() => {
    let user = jwt_decode(localStorage?.getItem("token"));

    const getDataFromServer = () => {
      const config = {
        headers: { Authorization: `Bearer ${localStorage?.getItem("token")}` },
      };

      const bodyParameters = {
        key: "value",
      };

      let urlLink = `${process.env.REACT_APP_API_URL}/patient/${customEncrypt(
        user?.tc_id
      )}`;
      if (loggedInUserDetails?.role === "FULL_ACCESS") {
        urlLink = `${process.env.REACT_APP_API_URL}/patient/`;
      }

      if (loggedInUserDetails?.role === "ST_ADM_ACCESS") {
        urlLink = `${process.env.REACT_APP_API_URL}/patient/state/${
          user && user?.state
        }`;
      }

      axios
        .get(urlLink, config)
        .then(
          (res) => {
            const oldData = res?.data?.patients;
            const updatedData = oldData.map((data) => {
              return {
                ...data,
                id: data?._id,
                diagnosis: data?.diagnoses?.diagnosis_name,
                state: data?.tc_state,
                residential_address: data?.residential_address.address,
              };
            });
            //console.log(updatedData);
            setData(updatedData);
            setUnfilteredData(updatedData);
            setUnfilteredDiagnosisData(updatedData);
          },
          (error) => {
            if (error?.response?.data?.message) {
              if (
                error?.response?.data?.message.toLowerCase().includes("invalid")
              ) {
                navigate("/login");
              }
            }
            //toast.error("DATA FETCHING ERROR ! Contact Admin");
            errorLogApi(error, "PatientListChild", urlLink);
          }
        )
        .catch((err) => {
          //console.log("error api call ", err.response.data.message);
          if (err) {
            errorLogApi(err, "PatientListChild", urlLink);
          }

          if (err?.response?.data?.message) {
            if (
              err?.response?.data?.message?.toLowerCase().includes("invalid")
            ) {
              navigate("/login");
            }
          }
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 3000); // 3 seconds delay
        });
    };

    getDataFromServer();
  }, []);

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };

  const clearSearch = () => {
    if (!searchRef.current.value) return;
    searchRef.current.value = null;
    getDataFromServer();
  };

  const handleSelectionChange = (selectionModel) => {
    // `selectionModel` will be an array of IDs of selected rows
    setSelectedIds(selectionModel);
  };

  const deleteMultipleRecords = () => {
    let inputCode = "";
    if (!selectedIds?.length) return toast.error("Please select Record(s)");

    confirmAlert({
      title: "",
      message: "Are you sure to delete patient(s) ?",
      childrenElement: () => (
        <div>
          {/* Use the key here */}
          <input
            type="text"
            placeholder="Type Code..."
            className="mt-2"
            onChange={(e) => (inputCode = e.target.value)} // Direct assignment
          />
        </div>
      ),
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecordInBulk(inputCode),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecordInBulk = (inputCode) => {
    if (!inputCode) return toast.error("Please enter a valid code!");

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/patient/delete/bulk`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ids: selectedIds, secret_code: inputCode },
    };
    if (selectedIds.length > 0) {
      axios(config)
        .then(function (response) {
          getDataFromServer();
          toast.success("Patient Deleted Successfully.");
        })
        .catch(function (error) {
          errorLogApi(error, "PatientDeleteMany", "/delete/bulk");
          toast.error(JSON.stringify(error.response.data.message));
        });
    }
  };

  // Bulk update Diagnosis Data
  const bulkUpdatePatientDiagnosis = () => {
    if (!selectedIds?.length) return toast.error("Please select Record(s)");

    confirmAlert({
      title: "",
      message: "Are you sure to Update patient(s) ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => bUpdatePatientDiagnosis(),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });

    const bUpdatePatientDiagnosis = () => {
      let userToken = localStorage.getItem("token");
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/patient/updateDiagnosis`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        data: { ids: selectedIds },
      };
      if (selectedIds.length > 0) {
        axios(config)
          .then(function (response) {
            getDataFromServer();
            toast.success(response?.data?.message);
          })
          .catch(function (error) {
            errorLogApi(error, "PatientUpdateMany", "/patient/updateDiagnosis");
            toast.error(JSON.stringify(error.response.data.message));
          });
      }
    };
  };

  // if (loading) {
  //   return <LoadingComponent />;
  // }

  const generateTCLevelReport = (data) => {
    navigate("/tclevelreport", { state: data });
  };

  const useRoles = ["ST_ADM_ACCESS", "FULL_ACCESS"];

  const handleExportToExcel = () => {
    if (selectedIds?.length === 0) {
      return toast.error("Please select at least one record.");
    }
    const selectedPatientData = data.filter((item) =>
      selectedIds.includes(item._id)
    );
    navigate("/export/toexcel", { state: { data: selectedPatientData } });
  };

  // Diagnosis category
  useEffect(() => {
    // console.log("Diagnosis category called");
    const getDataFromServer = () => {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };

      let urlLink = `${process.env.REACT_APP_API_URL}/dcat/state/${loggedInUserDetails?.state}`;

      axios
        .get(urlLink, config)
        .then((res) => {
          const responseData = res?.data?.categories;
          const diagnosesData = responseData.map((c) => ({ ...c, id: c._id }));
          setDiagnosisCategory(
            diagnosesData.map((d) => ({ value: d._id, label: d.category }))
          );
        })
        .catch((err) => console.log("error", err));
    };
    getDataFromServer();
  }, []);

  //Filter function
  useEffect(() => {
    if (filterDiagnosis?.value) {
      setUnfilteredData(
        unfilteredDiagnosisData?.filter((item) => {
          const categoryId = item?.diagnoses?.category_id;
          const isDiagnosisMatch = categoryId?._id === filterDiagnosis?.value;
          return isDiagnosisMatch;
        })
      );
    }
  }, [filterDiagnosis]);

  useEffect(() => {
    setData(unfilteredData);
    clearSearch();
  }, [unfilteredData]);

  const clearFilter = () => {
    getDataFromServer();
    setFilterDiagnosis(null);
    clearSearch();
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {" "}
        <h1 className="allPatients">All Patients</h1>
      </div>

      <div className="d-flex justify-content-end">
        <Row>
          <Col xs="auto">
            {loggedInUserDetails?.role === "TC_USER_ACCESS" && (
              <Button
                style={{ marginRight: "1px" }}
                onClick={() => generateTCLevelReport(data)}
              >
                Generate Report
              </Button>
            )}
          </Col>
          <Col xs="auto">
            {loggedInUserDetails?.role === "TC_USER_ACCESS" && (
              <Button variant="success" onClick={handleExportToExcel}>
                Export to Excel
              </Button>
            )}
            {loggedInUserDetails?.role === "ST_ADM_ACCESS" && (
              <Button variant="success" onClick={handleExportToExcel}>
                Export to Excel
              </Button>
            )}
          </Col>
        </Row>
      </div>
      <CheckboxComponent
        setData={setData}
        data={data}
        unfilteredData={unfilteredData}
      />
      <div className="mb-10 mt-2">
        <Space direction="vertical" size={12}>
          <RangePicker
            value={dates || dateValue}
            style={{ height: "36px" }}
            disabledDate={disabledDate}
            onCalendarChange={(val) => setDates(val)}
            onChange={(val) => setDateValue(val)}
            onOpenChange={onOpenChange}
            format="YYYY-MM-DD"
          />
        </Space>{" "}
        &nbsp;&nbsp;
        <Button
          variant="info"
          onClick={() => {
            //  setColLabel("Patients Created");
            searchRecordsBetweenDates("createdAt");
          }}
          disabled={dateInterval.sd ? false : true}
        >
          Created
        </Button>
        &nbsp;&nbsp;
        <Button
          variant="info"
          onClick={() => {
            // setColLabel("Patients Updated");
            searchRecordsBetweenDates("updatedAt");
          }}
          disabled={dateInterval.sd ? false : true}
        >
          Updated
        </Button>
        &nbsp;&nbsp;
        <Button
          onClick={() => {
            // setResult([]);
            setDurationResult([]);
            setDateValue([]);
            //getDataFromServer();
            // setColLabel("Total Patients");
            setData(unfilteredData);
          }}
        >
          Reset
        </Button>
      </div>

      <div className="mt-2">
        <Row className="align-items-center mb-2">
          <Col xs="auto">
            <Select
              name="diagnosis_cat"
              onChange={(selected, dropdown) => setFilterDiagnosis(selected)}
              placeholder="Filter Diagnosis"
              options={diagnosisCategory}
              value={filterDiagnosis}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
            />
          </Col>
          <Col xs="auto">
            <Button variant="danger" size="md" onClick={clearFilter}>
              Clear Filter
            </Button>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col>
            <InputGroup className="">
              <InputGroup.Text id="basic-addon1">
                Search Patients
              </InputGroup.Text>
              <Form.Control
                placeholder="Name, iCareID, Email..."
                aria-label="Search"
                aria-describedby="basic-addon1"
                name="searchPatients"
                ref={searchRef}
                onChange={(e) => handleSearch(e?.target?.value)}
              />
              <InputGroup.Text>
                <i
                  className="mdi mdi-delete-outline"
                  onClick={clearSearch}
                  style={{ cursor: "pointer" }}
                ></i>
              </InputGroup.Text>
            </InputGroup>
          </Col>
        </Row>
      </div>
      <div
        className="mt-2"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {useRoles?.includes(loggedInUserDetails?.role) && (
          <div className="mb-3">
            <Button
              variant="danger"
              size="small"
              onClick={deleteMultipleRecords}
            >
              Delete Multiple Records
            </Button>
          </div>
        )}
        {useRoles?.includes(loggedInUserDetails?.role) && (
          <div className="mb-3">
            <Button
              variant="info"
              size="small"
              onClick={bulkUpdatePatientDiagnosis}
            >
              Fix Diagnosis in Multiple Records
            </Button>
          </div>
        )}
      </div>

      {/* {JSON.stringify(data)} */}
      {loading ? (
        <LoadingComponent />
      ) : (
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Patient List"
          checkboxSelection
          style={{ height: "60vh" }}
          onSelectionModelChange={handleSelectionChange}
        />
      )}

      {/* {JSON.stringify(data)} */}

      {/* Modal Popup */}
      <Modal show={openModal} size="lg">
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent
            currentRecord={currentRecord}
            setFieldLabelFromArray={setFieldLabelFromArray}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

const ModalContent = ({ currentRecord, setFieldLabelFromArray }) => {
  return (
    <div className="container-fluid legend">
      <GeneralDetailsCard
        currentRecord={currentRecord}
        setFieldLabelFromArray={setFieldLabelFromArray}
      />
      <ClinicalDetailsCard
        currentRecord={currentRecord}
        setFieldLabelFromArray={setFieldLabelFromArray}
      />
      {currentRecord?.bleeding_history && (
        <BleedingHistoryCard
          currentRecord={currentRecord}
          setFieldLabelFromArray={setFieldLabelFromArray}
        />
      )}
      {currentRecord?.vaccinations && (
        <VaccinationDetailsCard
          currentRecord={currentRecord}
          setFieldLabelFromArray={setFieldLabelFromArray}
        />
      )}
      <AddressDetailsCard
        currentRecord={currentRecord}
        setFieldLabelFromArray={setFieldLabelFromArray}
      />
      {currentRecord?.contact && (
        <ContactDetailsCard
          currentRecord={currentRecord}
          setFieldLabelFromArray={setFieldLabelFromArray}
        />
      )}
      {currentRecord?.economic_status && (
        <EconomicStatusCard
          currentRecord={currentRecord}
          setFieldLabelFromArray={setFieldLabelFromArray}
        />
      )}
      {currentRecord?.scheduling && (
        <SchedulingDetailsCard currentRecord={currentRecord} />
      )}
    </div>
  );
};

const CheckboxComponent = ({ setData, data, unfilteredData }) => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (e) => {
    setSelectedOption(e?.target?.value);
  };

  useEffect(() => {
    if (selectedOption === "All") {
      setData(unfilteredData);
    } else if (selectedOption === "Alive") {
      setData(
        unfilteredData.filter((item) => item?.patient_death_status === false)
      );
    } else if (selectedOption === "Deceased") {
      setData(
        unfilteredData.filter((item) => item?.patient_death_status === true)
      );
    } else if (selectedOption === "Duplicates") {
      // Grouping items by card number
      const groupedByCardNumber = unfilteredData.reduce((acc, item) => {
        let cardNumber = item?.id_card?.card_number;
        if (!acc[cardNumber]) {
          acc[cardNumber] = [];
        }
        acc[cardNumber].push(item);
        return acc;
      }, {});

      // Filtering groups with more than one item and flattening them
      const duplicates = Object.values(groupedByCardNumber)
        .filter((group) => group.length > 1)
        .flat();

      setData(duplicates);
    }
  }, [selectedOption, unfilteredData]);

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <label>
        <input
          type="checkbox"
          value="All"
          checked={selectedOption === "All"}
          onChange={handleOptionChange}
        />
        &nbsp;&nbsp;All
      </label>
      <label>
        <input
          type="checkbox"
          value="Alive"
          checked={selectedOption === "Alive"}
          onChange={handleOptionChange}
        />
        &nbsp;&nbsp;Alive
      </label>
      <label>
        <input
          type="checkbox"
          value="Deceased"
          checked={selectedOption === "Deceased"}
          onChange={handleOptionChange}
        />
        &nbsp;&nbsp;Deceased
      </label>
      <label>
        <input
          type="checkbox"
          value="Duplicates"
          checked={selectedOption === "Duplicates"}
          onChange={handleOptionChange}
        />
        &nbsp;&nbsp;Duplicates
      </label>
    </div>
  );
};

export default PatientListChild;

const CheckLastVisit = ({ patient_id }) => {
  const [visit, setVisit] = useState();
  const [loading, setLoading] = useState(false);
  //Error Logging Service
  const [{ errorLogApi, convertDate }] = useAtom(gbState);

  useEffect(() => {
    const getDataFromServer = () => {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };
      const bodyParameters = {
        key: "value",
      };
      let urlLink = `${process.env.REACT_APP_API_URL}/visits/visit/last/${patient_id}`;
      setLoading(true);
      axios
        .get(urlLink, config)
        .then((res) => {
          //console.log(res.data);
          const visitData = res.data.visit;
          setVisit(visitData);
        })
        .catch((err) => {
          errorLogApi(err, "AppointmentListChild", urlLink);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    getDataFromServer();
  }, []);

  if (loading) return "loading...";

  return visit?.date_of_visit ? convertDate(visit?.date_of_visit) : "N/A";
};

import React from "react";
import Card from "react-bootstrap/Card";

const ClinicalDetailsCard = ({ currentRecord }) => {
  return (
    <Card>
      <Card.Header>
        <i className="fas fa-align-justify" />
        <strong>Patient Clinical & Diagnostics Details</strong>
      </Card.Header>
      <Card.Body>
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            {" "}
            <b> {"diagnoses".replaceAll("_", " ").toUpperCase()}</b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {currentRecord?.diagnoses?.diagnosis_name}
          </div>
        </div>
        {currentRecord?.diagnoses?.severity_of_illness && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b> {"severity_of_illness".replaceAll("_", " ").toUpperCase()}</b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {JSON.parse(currentRecord?.diagnoses?.severity_of_illness)?.label}
            </div>
          </div>
        )}
        {currentRecord?.diagnoses?.diagnosis_others && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b> {"diagnosis_others".replaceAll("_", " ").toUpperCase()}</b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {currentRecord?.diagnoses?.diagnosis_others}
            </div>
          </div>
        )}
        {currentRecord?.clinical_details?.transfusion_regimen && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b>
                {" "}
                {"transfusion_regiment".replaceAll("_", " ").toUpperCase()}
              </b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {
                JSON.parse(currentRecord?.clinical_details?.transfusion_regimen)
                  .label
              }
            </div>
          </div>
        )}

        {currentRecord?.clinical_details?.special_transfusion_needs && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b>
                {" "}
                {"special_transfusion_needs".replaceAll("_", " ").toUpperCase()}
              </b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {
                JSON.parse(
                  currentRecord?.clinical_details?.special_transfusion_needs
                ).label
              }
            </div>
          </div>
        )}

        {currentRecord?.clinical_details?.special_transfusion_note && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b>
                {" "}
                {"special_transfusion_note".replaceAll("_", " ").toUpperCase()}
              </b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {currentRecord?.clinical_details?.special_transfusion_note}
            </div>
          </div>
        )}

        {currentRecord?.clinical_details?.on_chelation && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b> {"on_chelation".replaceAll("_", " ").toUpperCase()}</b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {JSON.parse(currentRecord?.clinical_details?.on_chelation)?.label}
            </div>
          </div>
        )}

        {currentRecord?.clinical_details?.splenectomy && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b> {"splenectomy".replaceAll("_", " ").toUpperCase()}</b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {JSON.parse(currentRecord?.clinical_details?.splenectomy)?.label}
            </div>
          </div>
        )}

        {currentRecord?.clinical_details?.atypical_antibodies && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b> {"atypical_antibodies".replaceAll("_", " ").toUpperCase()}</b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {currentRecord?.clinical_details?.atypical_antibodies}
            </div>
          </div>
        )}

        {currentRecord?.clinical_details?.date_of_diagnosis && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b> {"date_of_diagnosis".replaceAll("_", " ").toUpperCase()}</b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {currentRecord?.clinical_details?.date_of_diagnosis}
            </div>
          </div>
        )}

        {currentRecord?.clinical_details?.date_of_first_transfusion && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b>
                {" "}
                {"date_of_first_transfusion".replaceAll("_", " ").toUpperCase()}
              </b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {currentRecord?.clinical_details?.date_of_first_transfusion}
            </div>
          </div>
        )}

        {currentRecord?.clinical_details?.number_of_transfusions && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b>
                {" "}
                {"number_of_transfusions".replaceAll("_", " ").toUpperCase()}
              </b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {currentRecord?.clinical_details?.number_of_transfusions}
            </div>
          </div>
        )}

        {currentRecord?.clinical_details?.approx_blood_vol_transfused_ml && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b>
                {" "}
                {"approx_blood_vol_transfused_ml"
                  .replaceAll("_", " ")
                  .toUpperCase()}
              </b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {currentRecord?.clinical_details?.approx_blood_vol_transfused_ml}
            </div>
          </div>
        )}

        {currentRecord?.clinical_details?.tanner_scale_genital_breasts && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b>
                {" "}
                {"tanner_scale_genital_breasts"
                  .replaceAll("_", " ")
                  .toUpperCase()}
              </b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {
                JSON.parse(
                  currentRecord?.clinical_details?.tanner_scale_genital_breasts
                )?.label
              }
            </div>
          </div>
        )}

        {currentRecord?.clinical_details?.tanner_scale_pubic_hair && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b>
                {" "}
                {"tanner_scale_pubic_hair".replaceAll("_", " ").toUpperCase()}
              </b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {
                JSON.parse(
                  currentRecord?.clinical_details?.tanner_scale_pubic_hair
                )?.label
              }
            </div>
          </div>
        )}

        {currentRecord?.clinical_details?.tanner_scale_last_assesment_date && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b>
                {" "}
                {"tanner_scale_last_assesment_date"
                  .replaceAll("_", " ")
                  .toUpperCase()}
              </b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {
                currentRecord?.clinical_details
                  ?.tanner_scale_last_assesment_date
              }
            </div>
          </div>
        )}

        {currentRecord?.clinical_details?.genetic_mutations && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              {" "}
              <b> {"genetic_mutations".replaceAll("_", " ").toUpperCase()}</b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {currentRecord?.clinical_details?.genetic_mutations}
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default ClinicalDetailsCard;

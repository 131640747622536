import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import toast from "react-hot-toast";
import axios from "axios";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

export const SiteOfBleedTab = ({ emrRecordId, emrRecord, setEmrRecord }) => {
  const [formData, setFormData] = useState({
    joints: null,
    ankle_left: null,
    ankle_right: null,
    knee_left: null,
    knee_right: null,
    hip_left: null,
    hip_right: null,
    wrist_left: null,
    wrist_right: null,
    elbow_left: null,
    elbow_right: null,
    shoulder_left: null,
    shoulder_right: null,
    muscles: null,
    gum: null,
    nose: null,
    skin: null,
    haematuria_renal: null,
    intracranial: null,
    eye: null,
    iliopsoas: null,
    throat_neck: null,
    deep_laceration: null,
    gastrointestinal: null,
    record_date_sob: null,
  });

  //Error Logging Service
  const [{ errorLogApi }] = useAtom(gbState);

  useEffect(() => {
    if (emrRecord) {
      localStorage.setItem(`${emrRecordId}-sob`, JSON.stringify(emrRecord));
    }
    let sob = localStorage.getItem(`${emrRecordId}-sob`);
    setFormData({ ...formData, ...JSON.parse(sob) });
  }, [emrRecord]);

  const updateEmr = (e) => {
    e.preventDefault();
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/emr/update/${emrRecordId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, site_of_bleeds: true },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.emr));
        localStorage.setItem(`${emrRecordId}-sob`, JSON.stringify(formData));
        toast.success("Site Of Bleeds Saved Successfully.");
        setEmrRecord(response.data.emr);
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data.message));
        errorLogApi(error, "SiteOfBleed", "/patient/emr/");
        //console.log(error);
      });
  };

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.checked,
    });
  };

  return (
    <form
      className="d-flex flex-sm-wrap"
      style={{ marginRight: "40px" }}
      onSubmit={(e) => updateEmr(e)}
    >
      <div
        className="flex-wrap-reverse flex-wrap w-100"
        style={{ textAlign: "right" }}
      >
        <Button variant="primary" size="large" type="submit">
          Save
        </Button>
      </div>
      {/* {JSON.stringify(emrRecord)} */}
      {/* {JSON.stringify(formData)} */}
      <div className="table-responsive w-100">
        <table className="table table-striped ">
          <thead>
            <tr>
              <th scope="col">Joint Bleed Location</th>
              <th scope="col">Left</th>
              <th scope="col">Right</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Ankle</td>
              <td>
                <input
                  type="checkbox"
                  name="ankle_left"
                  onChange={handleChange}
                  checked={formData.ankle_left}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  name="ankle_right"
                  onChange={handleChange}
                  checked={formData.ankle_right}
                />
              </td>
            </tr>
            <tr>
              <td>Knee</td>
              <td>
                <input
                  type="checkbox"
                  name="knee_left"
                  onChange={handleChange}
                  checked={formData.knee_left}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  name="knee_right"
                  onChange={handleChange}
                  checked={formData.knee_right}
                />
              </td>
            </tr>
            <tr>
              <td>Hip</td>
              <td>
                <input
                  type="checkbox"
                  name="hip_left"
                  onChange={handleChange}
                  checked={formData.hip_left}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  name="hip_right"
                  onChange={handleChange}
                  checked={formData.hip_right}
                />
              </td>
            </tr>
            <tr>
              <td>Wrist</td>
              <td>
                <input
                  type="checkbox"
                  name="wrist_left"
                  onChange={handleChange}
                  checked={formData.wrist_left}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  name="wrist_right"
                  onChange={handleChange}
                  checked={formData.wrist_right}
                />
              </td>
            </tr>
            <tr>
              <td>Elbow</td>
              <td>
                <input
                  type="checkbox"
                  name="elbow_left"
                  onChange={handleChange}
                  checked={formData.elbow_left}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  name="elbow_right"
                  onChange={handleChange}
                  checked={formData.elbow_right}
                />
              </td>
            </tr>
            <tr>
              <td>Shoulder</td>
              <td>
                <input
                  type="checkbox"
                  name="shoulder_left"
                  onChange={handleChange}
                  checked={formData.shoulder_left}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  name="shoulder_right"
                  onChange={handleChange}
                  checked={formData.shoulder_right}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="table-responsive w-100">
        <table class="table table-striped">
          <thead>
            <tr>
              <th colSpan="2" className="text-center">
                Other Bleeds
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Muscles</td>
              <td className="text-left">
                <input
                  type="checkbox"
                  name="muscles"
                  onChange={handleChange}
                  checked={formData.muscles}
                />
              </td>
            </tr>
            <tr>
              <td>Gum</td>
              <td className="text-left">
                <input
                  type="checkbox"
                  name="gum"
                  onChange={handleChange}
                  checked={formData.gum}
                />
              </td>
            </tr>
            <tr>
              <td>Nose</td>
              <td className="text-left">
                <input
                  type="checkbox"
                  name="nose"
                  onChange={handleChange}
                  checked={formData.nose}
                />
              </td>
            </tr>
            <tr>
              <td>Skin</td>
              <td className="text-left">
                <input
                  type="checkbox"
                  name="skin"
                  onChange={handleChange}
                  checked={formData.skin}
                />
              </td>
            </tr>
            <tr>
              <td>Haematuria (Renal)</td>
              <td className="text-left">
                <input
                  type="checkbox"
                  name="haematuria_renal"
                  onChange={handleChange}
                  checked={formData.haematuria_renal}
                />
              </td>
            </tr>
            <tr>
              <td>Intracranial</td>
              <td className="text-left">
                <input
                  type="checkbox"
                  name="intracranial"
                  onChange={handleChange}
                  checked={formData.intracranial}
                />
              </td>
            </tr>
            <tr>
              <td>Eye</td>
              <td className="text-left">
                <input
                  type="checkbox"
                  name="eye"
                  onChange={handleChange}
                  checked={formData.eye}
                />
              </td>
            </tr>
            <tr>
              <td>iliopsoas</td>
              <td className="text-left">
                <input
                  type="checkbox"
                  name="iliopsoas"
                  onChange={handleChange}
                  checked={formData.iliopsoas}
                />
              </td>
            </tr>
            <tr>
              <td>Throat and Neck</td>
              <td className="text-left">
                <input
                  type="checkbox"
                  name="throat_neck"
                  onChange={handleChange}
                  checked={formData.throat_neck}
                />
              </td>
            </tr>
            <tr>
              <td>Deep Laceration</td>
              <td className="text-left">
                <input
                  type="checkbox"
                  name="deep_laceration"
                  onChange={handleChange}
                  checked={formData.deep_laceration}
                />
              </td>
            </tr>
            <tr>
              <td>Gastrointestinal</td>
              <td className="text-left">
                <input
                  type="checkbox"
                  name="gastrointestinal"
                  onChange={handleChange}
                  checked={formData.gastrointestinal}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </form>
  );
};

export default SiteOfBleedTab;

import React, { useEffect, useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import Badge from "react-bootstrap/Badge";
import { Button, Card } from "react-bootstrap";
import "antd/dist/antd.min.css";
import { Modal } from "react-bootstrap";
import { DataGrid } from "@material-ui/data-grid";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import { makeStyles } from "@material-ui/core/styles";

import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

//Context import
import AuthContext from "../../components/shared/Context";

const DiagnosisCategoryChild = () => {
  let navigate = useNavigate();
  const { loggedInUserDetails } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  //Error Logging Service
  const [{ errorLogApi, dateFormatter, _checkIfLatestRecord }] =
    useAtom(gbState);

  const getDataFromServer = () => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };

    let urlLink = `${process.env.REACT_APP_API_URL}/dcat/`;

    axios
      .get(urlLink, config)
      .then((res) => {
        const responseData = res?.data?.categories;
        //  console.log(responseData);
        const diagnosesData = responseData.map((c) => ({ ...c, id: c._id }));
        setData(diagnosesData);
      })
      .catch((err) => console.log("error", err));
  };

  useEffect(() => {
    // const getDataFromServer = () => {
    //   const config = {
    //     headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    //   };

    //   let urlLink = `${process.env.REACT_APP_API_URL}/dcat/`;

    //   axios
    //     .get(urlLink, config)
    //     .then((res) => {
    //       const diagnosesCategoryData = res.data.category;
    //       //console.log(oldData);
    //       setData(diagnosesCategoryData);
    //     })
    //     .catch((err) => console.log("error", err));
    // };

    getDataFromServer();
  }, []);

  const handleDelete = (id) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete this record ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (id) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(`${process.env.REACT_APP_API_URL}/dcat/delete/${id}`, config)
      .then((response) => {
        // alert(JSON.stringify(response));
        //toast.success(response?.data?.message);
        getDataFromServer();
      })
      .catch((error) => {
        // console.log(error);
        toast.error(error?.response?.data?.message);
      });
  };

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            <Tooltip title="View">
              <span onClick={() => viewRecord(params?.row)}>
                <i
                  className="mdi mdi-eye-circle-outline"
                  style={{
                    fontSize: "15px",
                    color: "black",
                    cursor: "pointer",
                  }}
                ></i>
              </span>
            </Tooltip>
            <Tooltip title="Update">
              <a
                onClick={() =>
                  navigate("/diagnosis-category/update", { state: params.row })
                }
              >
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{
                    fontSize: "15px",
                    color: "grey",
                    cursor: "pointer",
                  }}
                />
              </a>
            </Tooltip>
            <Tooltip title="Delete">
              <div onClick={() => handleDelete(params?.row?.id)}>
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "category",
      headerName: "Category",
      width: 180,
    },
    {
      field: "state",
      headerName: "State",
      width: 150,
    },
  ];

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };

  const handleDiagnosisList = () => {
    navigate("/diagnosis");
  };

  return (
    <div>
      <span className="card-title">
        <h3>Diagnosis Category</h3>
      </span>
      <div className="patientUpdateButton">
        <Button
          variant="primary"
          size="medium"
          onClick={() => navigate("/diagnosis-category/add")}
          disabled={loggedInUserDetails?.role === "FULL_ACCESS" ? false : true}
        >
          Add Category
        </Button>
        &nbsp;&nbsp;
        <Button variant="secondary" size="medium" onClick={handleDiagnosisList}>
          Back
        </Button>
      </div>
      {/* {JSON.stringify(data)} */}
      {data && (
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Diagnosis Category"
          checkboxSelection
          style={{ height: "60vh" }}
        />
      )}

      {/* Modal Popup */}
      <Modal show={openModal} centered>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Category Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent data={currentRecord} />
        </Modal.Body>
      </Modal>
      {/* {JSON.stringify(data)} */}
    </div>
  );
};

const ModalContent = ({ data }) => {
  return (
    <div className="container-fluid legend">
      <Card>
        {/* <Card.Header>
            <i className="fas fa-align-justify" />
            <strong>User Details</strong>
          </Card.Header> */}
        <Card.Body>
          <CardTableRow rowName="State" rowValue={data?.state} />
          <CardTableRow rowName="Category" rowValue={data?.category} />
        </Card.Body>
      </Card>
    </div>
  );
};

const CardTableRow = ({ rowName, rowValue }) => {
  return (
    <div className="row">
      <div className="col-md-6 col-xs-6 divTableCell">
        <b> {rowName}: </b>
      </div>
      <div className="col-md-6 col-xs-6 divTableCell">{rowValue}</div>
    </div>
  );
};

export default DiagnosisCategoryChild;

import React, { useEffect, useState, useContext, useRef } from "react";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import "antd/dist/antd.min.css";
import { Modal } from "react-bootstrap";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import { Box, withStyles } from "@material-ui/core";
import { Row, Col, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import Select from "react-select";
import { DatePicker } from "antd";

//Global State import
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

//Context import
import AuthContext from "../../../components/shared/Context";
import ExcelDownloader from "./ExcelDownloader";
import TotalDoseConsumptionChart from "../TCInventory/List/TotalDoseConsumptionChart";
import PerPatientDatewiseChart from "../TCInventory/List/PerPatientDatewiseChart";
import PerPatientDoseChart from "../TCInventory/List/PerPatientDoseChart";
import PatientVisitFrequencyChart from "../TCInventory/List/PatientVisitFrequencyChart";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const { RangePicker } = DatePicker;

const ListPatientDosingHistoryChild = () => {
  let navigate = useNavigate();
  const { loggedInUserDetails } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [selectedReport, setSelectedReport] = useState();
  const [openReportModal, setOpenReportModal] = useState(false);

  const [deliveryInfo, setDeliveryInfo] = useState(false);
  const searchRef = useRef();
  //Error Logging Service
  const [{ errorLogApi, dateFormatter, expiryFormatter }] = useAtom(gbState);

  const reportOptions = [
    { value: 1, label: "Total Dose Consumption" },
    { value: 2, label: "Per Patient Dose Chart" },
    { value: 3, label: "Per Patient Datewise Chart" },
    { value: 4, label: "Patient Visit Frequency Chart" },
  ];

  const getDataFromServer = () => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    let urlLink = `${process.env.REACT_APP_API_URL}/tcstocksale/history/ad`;

    axios
      .get(urlLink, config)
      .then((res) => {
        const oldData = res.data.dose_history;
        //console.log(oldData);
        const updatedData = oldData.map((data) => {
          //   console.log(data.purchase_inventory.product_id.products);
          return {
            ...data,
            id: data?._id,
            dose_id: data?.s_register?.tc_sale_id,
            date: data?.s_register?.date_time_of_infusion,
            patient_name: data?.s_register?.patient_id?.patient_name,
            patient_id: data?.s_register?.patient_id?.patientId,
            patient_record: data?.s_register?.patient_id,
            visit_date: data?.s_register?.visit_id?.date_of_visit,
            visit_record: {
              ...data?.s_register?.visit_id,
              id: data?.s_register?.visit_id?._id,
            },
            quantity:
              data?.s_register?.quantity +
              " " +
              data?.s_register?.product_id?.products?.storage_unit,
            product_name: data?.s_register?.product_id?.products?.product_name,
            product_code: data?.s_register?.product_id?.products?.item_code,
            tc_name: data?.s_register?.tc_id?.tcenters?.name_tc,
            hcp_name: data?.s_register?.given_by_nurse_or_doctor,
            state: data?.s_register?.tc_id?.tcenters?.state,
          };
        });
        //console.log(updatedData);
        setData(updatedData);
        setUnfilteredData(updatedData);
      })
      .catch((err) => console.log("error", err));
  };

  useEffect(() => {
    getDataFromServer();
  }, []);

  const useStyles = makeStyles(() => ({
    multiLineCell: {
      display: "flex",
      wordBreak: "keep-all",
      wordWrap: "break-word",
      overflowWrap: "break-word",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      lineHeight: "1.2rem",
      height: "100%",
    },
    customRow: {
      height: "75px", // Increase the row height as needed
    },
  }));

  const MultiLineCellRenderer = (params) => {
    const classes = useStyles();
    return (
      <div className={classes.multiLineCell}>
        <span id="content1">{params?.row?.product_name}</span>
        <span id="content2">
          <small className="small-font-size">{params?.row?.product_code}</small>
        </span>
      </div>
    );
  };

  const PatientLineCellRenderer = (params) => {
    const classes = useStyles();
    return (
      <div className={classes.multiLineCell}>
        <span id="content1">
          <b>{params?.row?.patient_name?.toUpperCase()}</b>
        </span>
        <span id="content2">
          <small className="small-font-size">
            <b>Patient ID:</b> {params?.row?.patient_id}
          </small>
        </span>
        <span id="content2">
          <small className="small-font-size">
            <b>Dose ID&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</b> {params?.row?.dose_id}
          </small>
        </span>
      </div>
    );
  };

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="editiConContainer">
            {/* <Tooltip title="Product Information">
                <a
                  onClick={() => {
                    setDeliveryInfo(false);
                    viewRecord(params.row);
                  }}
                >
                  <i
                    className="mdi mdi-eye-circle-outline"
                    style={{ fontSize: "15px", color: "black" }}
                  ></i>
                </a>
              </Tooltip> */}
            <Tooltip title="Dose Information">
              <div
              // onClick={() =>
              //   navigate("/view-dose", {
              //     state: {
              //       visit: params.row.visit_record,
              //       patient: params.row.patient_record,
              //     },
              //   })
              // }
              >
                <i
                  className="mdi mdi-checkbox-marked-circle"
                  style={{
                    fontSize: "15px",
                    color: `${
                      params.row.allotment_recieved_status ? "blue" : "green"
                    }`,
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },

    {
      field: "patient_name",
      headerName: "Patient",
      width: 220,
      renderCell: PatientLineCellRenderer,
    },
    {
      field: "visit_date",
      headerName: "Dose Date",
      filterable: false,
      disableColumnMenu: true,
      width: 140,
      align: "center",
      renderCell: (params) => dateFormatter(params.row.visit_date),
    },
    // {
    //   field: "dose_id",
    //   headerName: "Dose ID",
    //   filterable: false,
    //   disableColumnMenu: true,
    //   width: 160,
    //   align: "center",
    // },
    {
      field: "product_name",
      headerName: "Product",
      width: 200,
      renderCell: MultiLineCellRenderer,
    },

    // {
    //   field: "patient_id",
    //   headerName: "Patient ID",
    //   width: 150,
    // },
    {
      field: "quantity",
      headerName: "Dose Qty",
      filterable: false,
      disableColumnMenu: true,
      width: 140,
      align: "center",
      renderCell: (params) => <div>{params?.row?.quantity}</div>,
    },
    {
      field: "hcp_name",
      headerName: "HCP",
      width: 130,
      filterable: false,
      disableColumnMenu: true,
      // align: "center",
      renderCell: (params) => <div>{params?.row?.hcp_name}</div>,
    },
    {
      field: "tc_name",
      headerName: "TC Name",
      width: 160,
      align: "center",
      renderCell: (params) => <div>{params?.row?.tc_name}</div>,
    },
    {
      field: "state",
      headerName: "State",
      filterable: false,
      disableColumnMenu: true,
      width: 110,
      renderCell: (params) => <div>{params?.row?.state}</div>,
    },
  ];

  const viewRecord = (rec) => {
    setCurrentRecord(rec);
    setOpenModal(true);
  };
  //   console.log(data);

  // let unfilterData = data;
  const handleSearch = (e) => {
    //console.log("Search Value...", e);

    if (e) {
      setData(
        unfilteredData.filter((item) => {
          return (
            item?.dose_id?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.patient_name?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.product_code?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.product_name?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.patient_id?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.sale_code?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.tc_name?.toLowerCase().includes(e?.toLowerCase()) ||
            item?.state?.toLowerCase().includes(e?.toLowerCase())
          );
        })
      );
    } else {
      setData(unfilteredData);
      getDataFromServer();
    }
  };

  const clearSearch = () => {
    searchRef.current.value = null;
    getDataFromServer();
  };

  const handleDateChange = (dates, dateStrings) => {
    if (dates) {
      const [start, end] = dates;
      setData(
        unfilteredData.filter((item) => {
          const visitDate = new Date(item.visit_date);
          return visitDate >= start && visitDate <= end;
        })
      );
    } else {
      setData(unfilteredData);
    }
  };

  const StyledDataGrid = withStyles({
    root: {
      "& .MuiDataGrid-renderingZone": {
        maxHeight: "none !important",
      },
      "& .MuiDataGrid-cell": {
        lineHeight: "unset !important",
        maxHeight: "none !important",
        whiteSpace: "normal",
      },
      "& .MuiDataGrid-row": {
        maxHeight: "none !important",
      },
    },
  })(DataGrid);

  return (
    <div>
      <span className="">
        <h3>List All TC Dosing History</h3>
      </span>

      <div className="mb-3 align-items-right">
        <Row className="align-items-center justify-content-end">
          <Col xs="auto" className="ml-auto">
            <Select
              name="reports"
              onChange={(selected, dropdown) => {
                if (selected) {
                  setSelectedReport(selected.value);
                } else {
                  setSelectedReport(null);
                }
              }}
              options={reportOptions}
              placeholder="Select Report Type"
              className="w-100"
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: "200px", // Adjust this width as needed
                }),
              }}
              isClearable
              isSearchable
              value={
                reportOptions.find(
                  (option) => option.value === selectedReport
                ) || null
              }
            />
          </Col>
          <Col xs="auto">
            <Button
              onClick={() => {
                if (selectedReport) {
                  setOpenReportModal(true);
                } else {
                  toast.error("Select Report Type");
                }
              }}
              variant="primary"
            >
              View Report
            </Button>
          </Col>
          <Col xs="auto">
            <ExcelDownloader
              data={data}
              excludedFields={[
                "id",
                "_id",
                "product_stock",
                "__v",
                "s_register",
              ]}
              filename={`Dosing_history_${new Date()
                .toISOString()
                .replace(/[-:.]/g, "")}.xlsx`}
            />
          </Col>
        </Row>
      </div>
      <div className="dateFilter mb-3">
        Date Filter : <RangePicker onChange={handleDateChange} />
      </div>
      <div className="patientListSearch">
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">Filter</InputGroup.Text>
          <Form.Control
            placeholder="Product / TC Name / Code / Company ..."
            aria-label="Search"
            aria-describedby="basic-addon1"
            name="searchPatients"
            ref={searchRef}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <InputGroup.Text>
            <i
              className="mdi mdi-delete-outline"
              onClick={clearSearch}
              style={{ cursor: "pointer" }}
            ></i>
          </InputGroup.Text>
        </InputGroup>
      </div>
      <StyledDataGrid
        rows={data}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        displayTitle="Purchases"
        checkboxSelection
        style={{ height: "80vh" }}
      />

      {/* Modal Popup */}
      <Modal show={openModal} centered>
        <Modal.Header closeButton onClick={() => setOpenModal(false)}>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalContent currentRecord={currentRecord} />
        </Modal.Body>
        {/* <Modal.Footer>
              <Button variant="secondary" onClick={() => setOpenModal(false)}>
                Close
              </Button>
            </Modal.Footer> */}
      </Modal>

      <Modal
        show={openReportModal}
        size="lg"
        centered
        onHide={() => setOpenReportModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div
            className="report-container p-5"
            style={{ maxHeight: "70vh", overflowY: "auto" }}
          >
            {selectedReport === 1 && <TotalDoseConsumptionChart data={data} />}
            {selectedReport === 3 && <PerPatientDatewiseChart data={data} />}
            {selectedReport === 2 && <PerPatientDoseChart data={data} />}
            {selectedReport === 4 && <PatientVisitFrequencyChart data={data} />}
          </div>
        </Modal.Body>
      </Modal>
      {/* {JSON.stringify(currentRecord)} */}
    </div>
  );
};

const ModalContent = ({ currentRecord }) => {
  return <div className="">{JSON.stringify(currentRecord)}</div>;
};

export default ListPatientDosingHistoryChild;

import { useLocation } from "react-router-dom";

import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import PreRegistrationUpdateChild from "./PreRegistrationUpdateChild";

const PreRegistrationUpdate = () => {
  const { state } = useLocation();
  let patientRecord = state;

  return (
    <MainContentCard>
      <ErrorBoundaryRoutes
        page="PreRegistrationsList"
        route="/pre/register/update"
      >
        <PreRegistrationUpdateChild patientRecord={patientRecord} />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default PreRegistrationUpdate;

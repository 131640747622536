import React from "react";

import Topbar from "../../../../components/Topbar/Topbar";
import SideBar from "../../../../components/SideBar/SideBar";
import MainContent from "../../../Home/MainContent";
import MainContentCard from "../../../Home/MainContentCard";

import "./GenericReports.css";
import GenericReportsChild from "./GenericReportsChild";

const GenericReports = () => {
  return (
    <MainContentCard>
      <GenericReportsChild />
    </MainContentCard>
  );
};

export default GenericReports;

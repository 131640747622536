import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import PreRegistrationsListChild from "./PreRegistrationsListChild";

const PreRegistrationsList = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes
        page="PreRegistrationsList"
        route="/pre/register/list"
      >
        <PreRegistrationsListChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default PreRegistrationsList;

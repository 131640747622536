import React, { useEffect, useState, useContext, useRef } from "react";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import Select from "react-select";
import { DatePicker } from "antd";
import moment from "moment";
import toast from "react-hot-toast";

//Global State import
import { useAtom } from "jotai";
import { gbState } from "../../../../components/shared/GlobalState";

//Context import
import AuthContext from "../../../../components/shared/Context";

const AddPurchaseTCChild = ({ recieveProduct }) => {
  let navigate = useNavigate();
  const recieved_quantityRef = useRef();
  const [formData, setFormData] = useState({
    state_sale_id: recieveProduct?._id,
    sale_id: recieveProduct?.s_register?.sale_id,
    product_id: recieveProduct?.s_register?.product_id._id,
    item_code: recieveProduct?.s_register?.purchase_id?.p_register?.item_code,
    purchase_id: recieveProduct?.s_register?.purchase_id?._id,
    sale_recieved_date: moment(),
    quantity: recieveProduct?.s_register?.quantity,
    recieved_quantity: recieveProduct?.s_register?.quantity,
    reciever_comments: null,
    expiry_date:
      recieveProduct?.s_register?.purchase_id?.p_register?.expiry_date,
    tc_id: recieveProduct?.s_register?.tc_id._id,
  });

  //Error Logging Service
  const [{ errorLogApi, dateFormatter }] = useAtom(gbState);

  const handleFormSubmit = () => {
    confirmAlert({
      title: "",
      message: "Are you sure to proceed?",
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmhandleFormSubmit(),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const confirmhandleFormSubmit = () => {
    console.log(formData);
    let userToken = localStorage.getItem("token");
    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/tcpurchase/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        toast.success("Sale Registered Successfully.");
        navigate("/list-purchase-tc");
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message);
        console.log(error.message);
      });
  };

  const handleChange = ({ currentTarget: input }) => {
    if (formData.recieved_quantity < 0) {
      toast.error("Choose only Positive value");
      setFormData({
        ...formData,
        recieved_quantity: null,
      });
      recieved_quantityRef.current.value = null;
      return;
    }
    if (
      formData.recieved_quantity >
      parseInt(recieveProduct?.s_register?.quantity)
    ) {
      toast.error(
        `Value should be less than ${recieveProduct?.s_register?.quantity}`
      );
      setFormData({
        ...formData,
        recieved_quantity: null,
      });
      recieved_quantityRef.current.value = null;
      return;
    }

    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  return (
    <div>
      <h3>Add to TC Inventory</h3>
      <div className="patientUpdateButton">
        <button className="btn btn-primary" onClick={handleFormSubmit}>
          {" "}
          Add to Inventory
        </button>{" "}
        &nbsp;&nbsp;
        <button className="btn btn-secondary" onClick={() => navigate(-1)}>
          Back
        </button>
      </div>
      <Card style={{ width: "100%", marginTop: "10px", padding: "20px" }}>
        <Card.Body className="container table-responsive">
          <table class="table table-striped">
            <tbody>
              <tr>
                <th scope="row">Allocation ID</th>
                <td>{formData.sale_id}</td>
              </tr>
              <tr>
                <th scope="row">Product Name</th>
                <td>
                  {
                    recieveProduct?.s_register?.product_id?.products
                      ?.product_name
                  }{" "}
                </td>
              </tr>
              <tr>
                <th scope="row">Allocation Date</th>
                <td>{dateFormatter(recieveProduct?.s_register?.sale_date)}</td>
              </tr>
              <tr>
                <th scope="row">Treatment Center</th>
                <td>{recieveProduct?.s_register?.tc_id?.tcenters?.name_tc}</td>
              </tr>
              <tr>
                <th scope="row">Allocated Quantity</th>
                <td>
                  {formData.quantity}{" "}
                  {
                    recieveProduct?.s_register?.product_id?.products
                      ?.storage_unit
                  }
                </td>
              </tr>
              <tr>
                <th scope="row">Recieved Quantity</th>
                <td>
                  {" "}
                  <input
                    type="number"
                    name="recieved_quantity"
                    placeholder="#Number"
                    onChange={handleChange}
                    onKeyUp={handleChange}
                    ref={recieved_quantityRef}
                    disabled
                    value={
                      formData?.recieved_quantity && formData?.recieved_quantity
                    }
                  />{" "}
                  {
                    recieveProduct?.s_register?.product_id?.products
                      ?.storage_unit
                  }
                </td>
              </tr>
              <tr>
                <th scope="row" colSpan={2}>
                  Comments
                </th>
              </tr>
              <tr>
                <td colSpan={2} height={`100px`}>
                  <textarea
                    type="text"
                    name="reciever_comments"
                    className="newPatientSelect w-100 h-100"
                    row={3}
                    placeholder="Please write recieved!"
                    onChange={handleChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          {/* {JSON.stringify(recieveProduct)} */}
          {/* {JSON.stringify(formData)} */}
        </Card.Body>
      </Card>
    </div>
  );
};

export default AddPurchaseTCChild;

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import SiteOfBleedTab from "./SiteOfBleedTab";

//import "bootstrap/dist/css/bootstrap.min.css";

import JointScores from "./JointScores/JointScores";
import THistory from "./THistory";
import ChronicComplicationTab from "./ChronicComplicationTab";
import InvestigationTab from "./InvestigationTab";
import TreatmentAdviceTab from "./TreatmentAdviceTab";
import MriScoreTab from "./MriScoreTab";
import XrayScores from "./XrayScores";
import UltraSoundScores from "./UltraSoundScores";
import UploadReports from "./UploadReports";
import CbcProfileTab from "./Thalassemia/CbcProfileTab";
import EchoProfileTab from "./Thalassemia/EchoProfileTab";
import BiochemTab from "./Thalassemia/BiochemTab";
import HbElectrophoresis from "./Thalassemia/HbElectrophoresis";
import EndocrineProfileTab from "./Thalassemia/EndocrineProfileTab";

const PrimaryEMRMenu = ({
  emrRecordId,
  handleChangeTabs,
  value,
  TabPanel,
  setValue,
  tabState,
  emrRecord,
}) => {
  return (
    <Box sx={{ width: "100%", marginLeft: "20px" }}>
      <Box
        sx={{
          maxWidth: { xs: 300, sm: "90%" },
          bgcolor: "background.paper",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChangeTabs}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Primary EMR Scrollable Menu"
        >
          {!tabState && (
            <Tab label="Bio Chemistry" style={{ padding: "15px" }} value={10} />
          )}
          {!tabState && (
            <Tab label="CBC Profile" style={{ padding: "15px" }} value={11} />
          )}
          {!tabState && (
            <Tab label="ECHO Profile" style={{ padding: "15px" }} value={12} />
          )}
          {!tabState && (
            <Tab
              label="Hb Electrophoresis"
              style={{ padding: "15px" }}
              value={13}
            />
          )}
          {!tabState && (
            <Tab
              label="Endocrine Profile"
              style={{ padding: "15px" }}
              value={14}
            />
          )}

          {tabState && (
            <Tab label="Site of Bleeds" style={{ padding: "15px" }} value={0} />
          )}
          {tabState && (
            <Tab
              label="Joint Bleed Scores"
              style={{ padding: "15px" }}
              value={1}
            />
          )}

          <Tab
            label="Treatment History"
            style={{ padding: "15px" }}
            value={2}
          />
          <Tab
            label="Chronic Complications"
            style={{ padding: "15px" }}
            value={3}
          />
          {tabState && (
            <Tab label="Investigations" style={{ padding: "15px" }} value={4} />
          )}

          {/* <Tab label="Treatment Advice" style={{ padding: "15px" }} value={5} /> */}
          <Tab label="MRI Scores" style={{ padding: "15px" }} value={6} />
          <Tab label="Xray Scores" style={{ padding: "15px" }} value={7} />
          <Tab
            label="Ultrasound Scores"
            style={{ padding: "15px" }}
            value={8}
          />
          <Tab label="Upload Reports" style={{ padding: "15px" }} value={9} />
        </Tabs>
      </Box>

      <div className="m-20 p-20">
        <TabPanel value={value} index={0}>
          <SiteOfBleedTab
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.site_of_bleeds}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <JointScores emrRecordId={emrRecordId} />
        </TabPanel>
        <TabPanel
          value={value}
          index={2}
          // onClick={() => alert("this will reload")}
        >
          <THistory
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.treatment_history}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ChronicComplicationTab
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.chronic_complications}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <InvestigationTab
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.investigation_sheet}
          />
        </TabPanel>
        {/* <TabPanel value={value} index={5}>
          <TreatmentAdviceTab
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.treatment_advice}
          />
        </TabPanel> */}
        <TabPanel value={value} index={6}>
          <MriScoreTab
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.mriscan_score}
          />
        </TabPanel>
        <TabPanel value={value} index={7}>
          <XrayScores
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.xray_score}
          />
        </TabPanel>
        <TabPanel value={value} index={8}>
          <UltraSoundScores
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.ultrasound}
          />
        </TabPanel>
        <TabPanel value={value} index={9}>
          <UploadReports emrRecordId={emrRecordId} />
        </TabPanel>
        <TabPanel value={value} index={10}>
          <BiochemTab
            emrRecordId={emrRecordId}
            changeTab={setValue}
            emrRecord={emrRecord && emrRecord.biochemistry}
          />
        </TabPanel>
        <TabPanel value={value} index={11}>
          <CbcProfileTab
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.cbc_profile}
          />
        </TabPanel>
        <TabPanel value={value} index={12}>
          <EchoProfileTab
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.echo_profile}
          />
        </TabPanel>
        <TabPanel value={value} index={13}>
          <HbElectrophoresis
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.hb_electrophoresis}
          />
        </TabPanel>
        <TabPanel value={value} index={14}>
          <EndocrineProfileTab
            emrRecordId={emrRecordId}
            emrRecord={emrRecord && emrRecord.endocrine_profile}
          />
        </TabPanel>
      </div>
    </Box>
  );
};

export default PrimaryEMRMenu;

import { useEffect, useState, useContext } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import axios from "axios";
import Chart from "../../../../components/Chart/Chart";
import ShowPieChart from "../../../../components/Chart/ShowPieChart";
//Context import
import AuthContext from "../../../../components/shared/Context";

const SeverityWiseReportChild = () => {
  const { checkLoggedInStatus, loggedInUserDetails, selectedDiagnosis } =
    useContext(AuthContext);

  const [patientData, setPatientData] = useState([]);
  const [patientChartDataWeight, setPatientChartDataWeight] = useState([]);
  const [patientChartDataHeight, setPatientChartDataHeight] = useState([]);
  const [patientChartDataAge, setPatientChartDataAge] = useState([]);

  function _calculateAge(birthday) {
    // birthday is a date
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  useEffect(() => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/patient/${loggedInUserDetails.tc_id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {},
    };
    axios(config)
      .then(function (response) {
        setPatientData(response.data.patients);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setPatientChartDataWeight(
      patientData.map((patient, index) => ({
        name: index + 1,
        diagnosis: parseInt(patient.diagnoses.diagnosis_code),
      }))
    );
    setPatientChartDataHeight(
      patientData.map((patient, index) => ({
        name: index + 1,
        height: patient.height,
      }))
    );
    setPatientChartDataAge(
      patientData.map((patient, index) => ({
        name: index + 1,
        age: _calculateAge(new Date(patient.date_of_birth)),
      }))
    );
  }, [patientData]);

  const data01 = [
    { name: "Mild", value: 10 },
    { name: "Moderate", value: 15 },
    { name: "Severe", value: 5 },
    ,
  ];
  return (
    <div className="patient-update w-50">
      {/* {JSON.stringify(loggedInUserDetails)} */}
      {/* {JSON.stringify(patientChartDataWeight)} */}
      {/* 
      <Chart
        title="Patient Diagnosis"
        data={patientChartDataWeight}
        datakey="diagnosis"
        grid={true}
      /> */}
      {/* <Chart
        title="Patient Weights"
        data={patientChartDataWeight}
        datakey="weight"
        grid={true}
      /> */}
      {/* <Chart
        title="Patient Height"
        data={patientChartDataHeight}
        datakey="height"
        grid={true}
      /> */}
      <ShowPieChart data={data01} />
    </div>
  );
};

export default SeverityWiseReportChild;

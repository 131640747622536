import React, { useState, useEffect } from "react";
import { RadioGroup, Radio } from "react-radio-group";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import axios from "axios";
import "./FishScores.css";

const PetterssonScore = ({ emrRecordId, jsRecord }) => {
  const [totalScore, setTotalScore] = useState(0);

  const [formData, setFormData] = useState({
    osteoporosis: null,
    enlarged_epiphysis: null,
    irregular_subchondral_surface: null,
    narrowing_of_joint_space: null,
    subchondral_cyst_formation: null,
    erosion_of_joint_margins: null,
    gross_incorguance: null,
    joint_deformity: null,
  });

  // useEffect(() => {
  //   let petterson = localStorage.getItem(`${emrRecordId}-petterssonscores`);
  //   setFormData({ ...formData, ...JSON.parse(petterson) });
  // }, []);

  useEffect(() => {
    if (jsRecord) {
      localStorage.setItem(
        `${emrRecordId}-petterssonscores`,
        JSON.stringify(jsRecord)
      );
    }
    let petterson = localStorage.getItem(`${emrRecordId}-petterssonscores`);
    setFormData({ ...formData, ...JSON.parse(petterson) });
  }, [jsRecord]);

  useEffect(() => {
    setTotalScore(
      Object.values({ ...formData })
        .filter((value) => value != null)
        .reduce((prev, value) => (prev = prev + parseInt(value)), 0)
    );
  }, [formData]);

  const pettersonFormValue = [
    {
      id: 1,
      title: "Osteoporosis",
      radiobuttonName: "osteoporosis",
      selectedValue: formData.osteoporosis,
      options: [
        {
          optionText: "Absent",
          optionValue: 0,
        },
        {
          optionText: "Present",
          optionValue: 1,
        },
      ],
    },
    {
      id: 2,
      title: "Enlarged epiphysis",
      radiobuttonName: "enlarged_epiphysis",
      selectedValue: formData.enlarged_epiphysis,
      options: [
        {
          optionText: "Absent",
          optionValue: 0,
        },
        {
          optionText: "Present",
          optionValue: 1,
        },
      ],
    },
    {
      id: 3,
      title: "Irregular subchondral surface",
      radiobuttonName: "irregular_subchondral_surface",
      selectedValue: formData.irregular_subchondral_surface,
      options: [
        {
          optionText: "Absent",
          optionValue: 0,
        },
        {
          optionText: "Slight",
          optionValue: 1,
        },
        {
          optionText: "Pronounced",
          optionValue: 2,
        },
      ],
    },
    {
      id: 4,
      title: "Narrowing of joint space",
      radiobuttonName: "narrowing_of_joint_space",
      selectedValue: formData.narrowing_of_joint_space,
      options: [
        {
          optionText: "Absent",
          optionValue: 0,
        },
        {
          optionText: "< 50%",
          optionValue: 1,
        },
        {
          optionText: "> 50%",
          optionValue: 2,
        },
      ],
    },
    {
      id: 5,
      title: "Subchondral cyst formation",
      radiobuttonName: "subchondral_cyst_formation",
      selectedValue: formData.subchondral_cyst_formation,
      options: [
        {
          optionText: "Absent",
          optionValue: 0,
        },
        {
          optionText: "One cyst",
          optionValue: 1,
        },
        {
          optionText: "More than one cyst",
          optionValue: 2,
        },
      ],
    },
    {
      id: 6,
      title: "Erosions at joint margins",
      radiobuttonName: "erosion_of_joint_margins",
      selectedValue: formData.erosion_of_joint_margins,
      options: [
        {
          optionText: "Absent",
          optionValue: 0,
        },
        {
          optionText: "Present",
          optionValue: 1,
        },
      ],
    },
    {
      id: 7,
      title:
        "Gross incongruence of articulating bone ends (angulation/displacement)",
      radiobuttonName: "gross_incorguance",
      selectedValue: formData.gross_incorguance,
      options: [
        {
          optionText: "Absent",
          optionValue: 0,
        },
        {
          optionText: "Slight",
          optionValue: 1,
        },
        {
          optionText: "Pronounced",
          optionValue: 2,
        },
      ],
    },
    {
      id: 8,
      title: "Joint deformity",
      radiobuttonName: "joint_deformity",
      selectedValue: formData.joint_deformity,
      options: [
        {
          optionText: "Absent",
          optionValue: 0,
        },
        {
          optionText: "Slight",
          optionValue: 1,
        },
        {
          optionText: "Pronounced",
          optionValue: 2,
        },
      ],
    },
  ];

  const updatePetterssonScore = (e) => {
    e.preventDefault();
    //changeTab(1);

    let userToken = localStorage.getItem("token");
    const config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/jointsscore/update/${emrRecordId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, petterssonscore: true },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        localStorage.setItem(
          `${emrRecordId}-petterssonscores`,
          JSON.stringify(formData)
        );
        toast.success("Pettersson saved Successfully.");
      })
      .catch(function (error) {
        //handleFailure(JSON.stringify(error.response.data.patient));
        toast.error(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error.response.data));
      });
  };

  const handleChange = ({ target: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  return (
    <form className="" onSubmit={(e) => updatePetterssonScore(e)}>
      <table class="table table-striped table-responsive">
        {/* <progress id="file" value="32" max="100">
          32%
        </progress> */}
        <thead>
          <tr>
            <td colSpan={4} style={{ borderBottom: "none" }}></td>
          </tr>
          <tr>
            <th colSpan={4}>Total Score: {totalScore}</th>
            {/* <th colSpan={2} style={{ textAlign: "right" }}>
              <Button variant="primary" size="medium" type="submit">
                Save
              </Button>
            </th> */}
          </tr>
          {/* <tr>
          <th style={{ width: "10%" }}></th>
          <th style={{ textAlign: "center" }}>0</th>
          <th style={{ textAlign: "center" }}>1</th>
          <th style={{ textAlign: "center" }}>2</th>
        </tr> */}
        </thead>

        <tbody>
          {/* <tr>
          <td colSpan={4}>{JSON.stringify(formData)}</td>
        </tr> */}

          {pettersonFormValue.map(
            ({ title, radiobuttonName, options, selectedValue }) => (
              <PetterssonScoreRow
                handleChange={handleChange}
                rowNameTitle={title}
                radioButtonName={radiobuttonName}
                options={options}
                selectedValue={selectedValue}
              />
            )
          )}

          <tr>
            <td colSpan={5} style={{ textAlign: "right" }}>
              1 - No Difficulty <br />4 - Maximum Difficulty
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default PetterssonScore;

const PetterssonScoreRow = ({
  radioButtonName,
  handleChange,
  rowNameTitle,
  selectedValue,
  options,
}) => {
  return (
    <tr>
      <td style={{ width: "10%" }}>{rowNameTitle} &nbsp;</td>
      {options.map((option) => (
        <td style={{ fontSize: "12px" }}>
          <RadioGroup name={radioButtonName}>
            <Radio
              value={option.optionValue}
              className="radioButton"
              onClick={handleChange}
              checked={
                option.optionValue === parseInt(selectedValue) ? true : false
              }
              disabled
            />
            {option.optionText}
          </RadioGroup>
        </td>
      ))}
      {options.length === 2 && <td>&nbsp;</td>}
    </tr>
  );
};

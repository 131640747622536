import {
  visitReasons,
  visitTypes,
  visitReasonsThalassemia,
  vReasons,
} from "../../../components/Data/ChartData";
import { useState, useRef, useEffect } from "react";
import Select from "react-select";
import { BsQuestionCircleFill } from "react-icons/bs";
import { DatePicker } from "antd";
import moment from "moment";

import {
  transfusionRegimenOptions,
  paymentOptions,
  travelModeOptions,
} from "../Create/VisitDataOptions";
import AlertDialog from "../../../components/InfoDialog/AlertDialog";
import PatientInfoBlock from "../../../components/PatientProfile/PatientInfoBlock";

export const VisitTab = ({
  handleChange,
  dropDownChange,
  loggedInUserDetails,
  doctors,
  formData,
  setFormData,
  patientHomeTC,
  selectedPatientProfile,
  visitTypeInput,
  doctorInput,
  visitDateInput,
  visitReasonInput,
  visitWeightInput,
  visitHeightInput,
  visitTypeInput1,
}) => {
  const [visitReasonsOptions, setVisitReasonsOptions] = useState(null);
  const [vtOptions, setVtOptions] = useState(null);
  const childRef = useRef();

  async function dateConverter() {
    const date_conv = new Date();
    const fullyear = date_conv.getFullYear();
    const month = date_conv.getMonth() + 1;
    const day = date_conv.getDate();
    return `${day}/${month}/${fullyear}`;
  }

  const options = [
    { value: "1", label: "Self" },
    { value: "2", label: "Govt Funded" },
    { value: "3", label: "Privately Funded" },
  ];

  const consultationOptions = [
    { value: true, label: "Consultation" },
    { value: false, label: "Hospitalization" },
  ];

  const doctorOptions = doctors?.map((doctor) => {
    if (doctor?.doctor_drop_down_status) {
      return {
        value: doctor?._id,
        label: doctor?.last_name
          ? "Dr. " + doctor?.first_name + " " + doctor?.last_name
          : "Dr. " + doctor?.first_name,
      };
    }
  });

  function isValidOption(option) {
    return (
      option != null &&
      typeof option === "object" &&
      option.hasOwnProperty("value") &&
      option.hasOwnProperty("label")
    );
  }

  const hasValidOptions = doctorOptions?.some(isValidOption) ?? false;

  //Changing Visit options

  useEffect(() => {
    var visitOptions;
    //console.log(selectedPatientProfile);
    if (
      selectedPatientProfile?.diagnoses?.category_id?.category
        ?.toLowerCase()
        ?.includes("hemophilia")
    ) {
      visitOptions = vReasons?.hemophilia?.map((visit) => ({
        value: visit.id,
        label: visit.reason,
        subreasons: visit.subreasons,
      }));
      setVisitReasonsOptions(visitOptions);
    } else if (
      selectedPatientProfile?.diagnoses?.category_id?.category
        ?.toLowerCase()
        ?.includes("thalassemia")
    ) {
      visitOptions = vReasons?.thalassemia?.map((visit) => ({
        value: visit.id,
        label: visit.reason,
        subreasons: visit.subreasons,
      }));
      setVisitReasonsOptions(visitOptions);
    }
  }, []);

  const externalvisitOptions = [
    { value: false, label: "No" },
    { value: true, label: "Yes" },
  ];

  const tranfusionRegimenHelp =
    "Transfusion Regimen : Hypo transfusion is usually associated with only 1 bag transfusion. Hyper transfusion and Super transfusion target a post-transfusion Hb of 13-14gm/dl. The system uses 13.5 gm/dl for calculations of blood requirement.";
  const specialTransfusionNote =
    "Write the instruction for blood bank in case of special transfusion need.";
  const style = {
    paper: {
      height: 250,
      width: 200,
      margin: 20,
    },
    card: {
      header: {
        container: {
          display: "flex" /* establish flex container */,
          justifyContent: "space-between",
          backgroundColor: "lightblue",
        },
        padding: 1,
        height: 26,
      },
    },
  };

  return (
    <div className="newPatientFormOnly1">
      <AlertDialog ref={childRef} title="" />
      <PatientInfoBlock selectedPatientProfile={selectedPatientProfile} />
      <div className="newPatientFormOnly ">
        <fieldset className="border p-2 w-100">
          <legend className="float-none w-auto p-2 fs-5">
            Visit Information
          </legend>
          <div className="newPatientFormOnly visit">
            <div className="newPatientItem">
              <label htmlFor="date_of_visit">Date of Visit</label>
              <DatePicker
                format="YYYY-MM-DD"
                className="newPatientSelect datePicker"
                name="date_of_visit"
                onChange={(date, dateString) => {
                  setFormData({
                    ...formData,
                    date_of_visit: date,
                  });
                }}
                value={moment.utc(formData?.date_of_visit, "YYYY-MM-DD")}
                disabledDate={(current) => {
                  return (
                    // moment().add(-1, "days") >= current
                    moment().add(1, "month") <= current
                  );
                }}
                ref={visitDateInput}
              />
            </div>
            {/* Patient Name */}
            <div className="newPatientItem">
              <label>Patient for: </label>
              <Select
                name="consultation"
                onChange={dropDownChange}
                className="newPatientSelect"
                options={consultationOptions}
                value={
                  formData?.consultation
                    ? { value: true, label: "Consultation" }
                    : { value: true, label: "Consultation" }
                }
                ref={visitTypeInput}
              />
            </div>
            <div className="newPatientItem">
              <label htmlFor="ward_clinic_opd_number">Ward/OPD Number</label>
              <input
                type="text"
                name="ward_clinic_opd_number"
                placeholder="#Number"
                value={formData?.ward_clinic_opd_number}
                onChange={handleChange}
              />
            </div>
            <div className="newPatientItem">
              <label htmlFor="doctor_id">Doctor</label>
              <Select
                name="doctor_id"
                onChange={dropDownChange}
                className="newPatientSelect"
                options={doctorOptions}
                value={
                  formData?.doctor_id &&
                  doctorOptions.find((d) => d.value === formData?.doctor_id)
                }
                ref={doctorInput}
                isDisabled={!hasValidOptions}
              />
            </div>
            <div className="newPatientItem">
              <label>Visit / Consultation Reason</label>
              <Select
                name="visit_reason"
                onChange={
                  //dropDownChange
                  (selected, dropdown) => {
                    //console.log(selected);
                    setFormData({
                      ...formData,
                      visit_reason: JSON.stringify(selected),
                      visit_type: null,
                    });
                    setVtOptions(selected?.subreasons);
                  }
                }
                className="newPatientSelect"
                options={visitReasonsOptions}
                ref={visitReasonInput}
                value={
                  formData?.visit_reason && JSON.parse(formData?.visit_reason)
                }
              />
            </div>
            <div className="newPatientItem">
              <label>Visit Type</label>
              <Select
                name="visit_type"
                onChange={(selected, dropdown) => {
                  setFormData({
                    ...formData,
                    visit_type: JSON.stringify(selected),
                  });
                }}
                className="newPatientSelect"
                options={vtOptions}
                ref={visitTypeInput1}
                isDisabled={!vtOptions?.length}
                value={formData?.visit_type && JSON.parse(formData?.visit_type)}
              />
            </div>

            {selectedPatientProfile?.diagnoses?.category_id?.category
              ?.toLowerCase()
              ?.includes("thalassemia") && (
              <div className="newPatientItem">
                <label htmlFor="transfusion_regimen">
                  Transfusion Regimen{" "}
                  <BsQuestionCircleFill
                    color="green"
                    className="helper-icon"
                    onClick={() => {
                      childRef.current.handleClickOpenInfo(
                        "Transfusion Regimen",
                        tranfusionRegimenHelp
                      );
                    }}
                  />
                </label>
                <Select
                  name="transfusion_regimen"
                  onChange={
                    //dropDownChange
                    (selected, dropdown) => {
                      //console.log(selected);
                      setFormData({
                        ...formData,
                        transfusion_regimen: JSON.stringify(selected),
                      });
                    }
                  }
                  className="newPatientSelect"
                  options={transfusionRegimenOptions}
                  value={
                    formData?.transfusion_regimen &&
                    JSON.parse(formData?.transfusion_regimen)
                  }
                />
              </div>
            )}

            <div className="newPatientItem">
              <label htmlFor="travel_distance">Travel Distance</label>
              <input
                type="Number"
                name="travel_distance"
                placeholder="#in Kms"
                onChange={handleChange}
                value={formData?.travel_distance}
              />
            </div>
            <div className="newPatientItem">
              <label>Travel Mode</label>
              <Select
                name="travel_mode"
                onChange={
                  //dropDownChange
                  (selected, dropdown) => {
                    //console.log(selected);
                    setFormData({
                      ...formData,
                      travel_mode: JSON.stringify(selected),
                    });
                  }
                }
                className="newPatientSelect"
                value={
                  formData?.travel_mode && JSON.parse(formData?.travel_mode)
                }
                options={travelModeOptions}
              />
            </div>

            <div className="newPatientItem">
              <label>Payment Status </label>
              <Select
                name="payment_status"
                onChange={
                  //dropDownChange
                  (selected, dropdown) => {
                    //console.log(selected);
                    setFormData({
                      ...formData,
                      payment_status: JSON.stringify(selected),
                    });
                  }
                }
                className="newPatientSelect"
                value={
                  formData?.payment_status &&
                  JSON.parse(formData?.payment_status)
                }
                options={paymentOptions}
              />
            </div>
          </div>
        </fieldset>
      </div>
      <div className="newPatientFormOnly">
        <fieldset className="border p-2 w-100">
          <legend className="float-none w-auto p-2 fs-5">
            General Examination
          </legend>

          <div className="newPatientFormOnly visit">
            <div className="newPatientItem">
              <label htmlFor="weight">Weight (Current)</label>
              <input
                type="number"
                name="weight"
                placeholder="Number in Kgs"
                value={formData?.weight}
                onChange={handleChange}
                ref={visitWeightInput}
              />
            </div>
            <div className="newPatientItem">
              <label htmlFor="height">Height (Current)</label>
              <input
                type="number"
                name="height"
                placeholder="in Centimeters"
                value={formData?.height}
                onChange={handleChange}
                ref={visitHeightInput}
              />
            </div>

            {selectedPatientProfile?.diagnoses?.category_id?.category
              ?.toLowerCase()
              ?.includes("thalassemia") && (
              <>
                <div className="newPatientItem">
                  <label htmlFor="pre_transfusion_hb">Pre Transfusion Hb</label>
                  <input
                    type="number"
                    name="pre_transfusion_hb"
                    placeholder="in g/dl"
                    onChange={handleChange}
                    value={formData?.pre_transfusion_hb}
                  />
                </div>
                <div className="newPatientItem">
                  <label htmlFor="liver">Liver</label>
                  <input
                    type="number"
                    name="liver"
                    placeholder="in Centimeters"
                    onChange={handleChange}
                    value={formData?.liver}
                  />
                </div>
                <div className="newPatientItem">
                  <label htmlFor="spleen">Spleen</label>
                  <input
                    type="number"
                    name="spleen"
                    placeholder="in Centimeters"
                    onChange={handleChange}
                    value={formData?.spleen}
                  />
                </div>
                <div className="newPatientItem">
                  <label htmlFor="post_transfusion_hb">
                    Post Transfusion Hb
                  </label>
                  <input
                    type="number"
                    name="post_transfusion_hb"
                    placeholder="in g/dl"
                    onChange={handleChange}
                    value={formData?.post_transfusion_hb}
                  />
                </div>
              </>
            )}
          </div>
        </fieldset>
      </div>
      <div className="newPatientFormOnly">
        <fieldset className="border p-2 w-100">
          <legend className="float-none w-auto p-2 fs-5">
            Financial Expense/Impact
          </legend>
          <div className="newPatientFormOnly visit">
            <div className="newPatientItem">
              <label htmlFor="stay_cost">Stay cost</label>
              <input
                type="Number"
                name="stay_cost"
                placeholder="Cost in INR"
                value={formData?.stay_cost}
                onChange={handleChange}
              />
            </div>
            <div className="newPatientItem">
              <label htmlFor="food_cost">Food cost</label>
              <input
                type="Number"
                name="food_cost"
                placeholder="Cost in INR"
                value={formData?.food_cost}
                onChange={handleChange}
              />
            </div>
            <div className="newPatientItem">
              <label htmlFor="wages_lost_by_parents">
                Wages lost by parents
              </label>
              <input
                type="Number"
                name="wages_lost_by_parents"
                placeholder="Cost in INR"
                value={formData?.wages_lost_by_parents}
                onChange={handleChange}
              />
            </div>
            <div className="newPatientItem">
              <label htmlFor="school_absentism">School absentism</label>
              <input
                type="Number"
                name="school_absentism"
                placeholder="Cost in INR"
                value={formData?.school_absentism}
                onChange={handleChange}
              />
            </div>
            <div className="newPatientItem">
              <label htmlFor="travel_cost">Travel cost</label>
              <input
                type="Number"
                name="travel_cost"
                placeholder="Cost in INR"
                value={formData?.travel_cost}
                onChange={handleChange}
              />
            </div>
            <div className="newPatientItem">
              <label htmlFor="other_direct_indirect_cost">
                Other direct/indirect cost
              </label>
              <input
                type="Number"
                name="other_direct_indirect_cost"
                placeholder="Cost in INR"
                value={formData?.other_direct_indirect_cost}
                onChange={handleChange}
              />
            </div>
            <div className="newPatientItem">
              <label htmlFor="hcp_indirect_cost">HCP indirect cost</label>
              <input
                type="Number"
                name="hcp_indirect_cost"
                placeholder="Cost in INR"
                value={formData?.hcp_indirect_cost}
                onChange={handleChange}
              />
            </div>
          </div>
        </fieldset>
      </div>
      <div className="newPatientFormOnly">
        <fieldset className="border p-2 w-100">
          <legend className="float-none w-auto p-2 fs-5">Visit Remark</legend>
          <div className="newPatientItem form-outline mb-4 w-100">
            {/* <label htmlFor="reason_others">Reason Others</label> */}
            <textarea
              className="form-control"
              name="reason_others"
              placeholder="other reasons..."
              rows={4}
              onChange={handleChange}
              value={formData?.reason_others}
            />
          </div>
        </fieldset>
      </div>
      {/* Loggin User: {JSON.stringify(jwt_decode(localStorage.getItem("token")))} */}
      {/* {selectedPatientProfile.diagnoses.diagnosis_name} */}
    </div>
  );
};

export default VisitTab;

import React from "react";
import MainContentCard from "../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../components/shared/ErrorBoundaryRoutes";
import DiagnosisCategoryChild from "./DiagnosisCategoryChild";

const DiagnosisCategory = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="DiagnosisCategory" route="/diagnosis-category">
        <DiagnosisCategoryChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default DiagnosisCategory;

import React, { useState } from "react";
import { Carousel, Button } from "react-bootstrap";

const ImageSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="image-slider">
      <Carousel
        activeIndex={currentIndex}
        interval={null}
        prevIcon={<span className="carousel-control-prev-icon" />}
        nextIcon={<span className="carousel-control-next-icon" />}
      >
        {images.map((image, index) => (
          <Carousel.Item key={index}>
            <img className="d-block w-100" src={image.src} alt={image.alt} />
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="controls">
        <Button
          variant="outline-light"
          onClick={handlePrev}
          className="carousel-control-prev"
        >
          Previous
        </Button>
        <Button
          variant="outline-light"
          onClick={handleNext}
          className="carousel-control-next"
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default ImageSlider;

import React, { useContext } from "react";
// import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import { Button, Alert } from "react-bootstrap";
import { BsXCircle } from "react-icons/bs";
import "./UploadReports.css";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";

//Context import
import AuthContext from "../../../../components/shared/Context";

const UploadReports = ({ emrRecordId, changeTab }) => {
  const { dateFormatter, loggedInUserDetails } = useContext(AuthContext);
  const [value, setValue] = React.useState([1, 3]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [formData, setFormData] = useState({
    emr_id: emrRecordId,
    rtype: null,
    rcomments: null,
    createdBy: loggedInUserDetails._id,
  });

  const reportTypeOptions = [
    { value: "xray", label: "Xray" },
    { value: "mriscan", label: "MRI Scan" },
    { value: "ultrasound", label: "Ultrasound" },
    { value: "prescription", label: "Prescription" },
    { value: "blood_test_report", label: "Blood Test Report" },
    { value: "ct_scan", label: "CT Scan" },
    {
      value: "pulmonary_function_test_report",
      label: "Puliminary Function Test Report",
    },
    { value: "malaria_test_report", label: "Malaria Test Report" },
    { value: "hiv_test_report", label: "HIV Test Report" },
    { value: "hbv_test_report", label: "Hbv Test Report" },
    { value: "hcv_test_report", label: "Hcv Test Report" },
    { value: "dengue_reports", label: "Dengue Reports" },
    {
      value: "hospital_discharge_summary",
      label: "Hospital Discharge Summary",
    },
    { value: "thyroid_function_test", label: "Thyroid Function Test" },
    { value: "biopsy_results", label: "Biopsy results" },
    {
      value: "neuropsychology_evaluations",
      label: "Neuropsychology Evaluations",
    },
    {
      value: "Emergency_room_clinical_note",
      label: "Emergency Room clinical Notes",
    },
  ];

  useEffect(() => {
    loadPreviousReports();
  }, []);

  const loadPreviousReports = () => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/upload/${emrRecordId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data[0].reports.emr_id));
        //toast.success("Report Uploaded Successfully.");
        setUploadedFiles(response.data);
      })
      .catch(function (error) {
        //toast.error(JSON.stringify(error.response.data));
        setUploadedFiles([]);
      });
  };

  const handleDelete = (id) => {
    //  setData(data.filter((item) => item.id !== id));
    //alert(id);
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(`${process.env.REACT_APP_API_URL}/upload/delete/${id}`, config)
      .then((response) => {
        // alert(JSON.stringify(response));
        loadPreviousReports();
      })
      .catch((err) => console.log(err));
  };

  const updateEmr = (e) => {
    e.preventDefault();
    //changeTab(1);

    if (!fileData) {
      toast.error("Please select a file");
      return;
    }

    //  alert("Click to submit");

    //New data being created before submission
    const data = new FormData();
    data.append("file", fileData);
    data.append("rtype", formData.rtype);
    data.append("rcomments", formData.rcomments);
    data.append("createdBy", formData.createdBy);

    let userToken = localStorage.getItem("token");
    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/upload/${emrRecordId}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userToken}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        loadPreviousReports();
        //handleSuccess(JSON.stringify(response.data));
        toast.success("Report Uploaded Successfully.");
      })
      .catch(function (error) {
        //handleFailure(JSON.stringify(error.response.data.patient));
        toast.error(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error.response));
      });
  };

  const handleChange = ({ currentTarget: input }) => {
    //alert([input.name]);
    setFormData({
      ...formData,
      [input.name]: input.checked,
    });
  };
  const handleChangeValue = ({ target: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
      createdBy: loggedInUserDetails._id,
    });
  };

  const dropDownChange = (selected, dropdown) => {
    console.log("this is selected", JSON.stringify(dropdown.name));
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const handleFileUpload = (e) => {
    let uploadedfile = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(uploadedfile);

    reader.onload = (e) => {
      // console.log(e.target.result);
      setFileData(uploadedfile);
    };

    // console.log("File ", file);
  };

  return (
    <>
      {uploadedFiles.length > 0 && (
        <UploadReportsTable
          uploadedFiles={uploadedFiles}
          handleDelete={handleDelete}
          dateFormatter={dateFormatter}
        />
      )}
    </>
  );
};

export default UploadReports;

const UploadReportsTable = ({ uploadedFiles, handleDelete, dateFormatter }) => {
  return (
    <div className="table-responsive" style={{ marginRight: "40px" }}>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col" colspan="2">
              Uploaded Reports
            </th>
            <th scope="col" colspan="2">
              &nbsp;
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>

          {uploadedFiles.map((file) => {
            return (
              <tr key={file._id}>
                <td>{dateFormatter(file.createdAt)}</td>
                <td>{JSON.parse(file.reports.rtype).label}</td>
                <td className="comment-section">
                  {file.reports.rcomments && file.reports.rcomments}
                </td>
                <td style={{ textAlign: "right" }}>
                  <a
                    href={
                      process.env.REACT_APP_REPORTS_DOWNLOAD_URL +
                      file.reports.rname
                    }
                    target="_blank"
                    title="Upload"
                  >
                    Download Report
                  </a>
                  &nbsp;
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

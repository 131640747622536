import React from "react";
import CardContent from "./CardContent";
import VideoHelpChild from "./VideoHelpChild";

const VideoHelp = () => {
  return (
    <CardContent>
      <VideoHelpChild />
    </CardContent>
  );
};

export default VideoHelp;

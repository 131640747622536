import React from "react";

import MainContentCard from "../../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../../components/shared/ErrorBoundaryRoutes";
import ProductWiseTCInventoryChild from "./ProductWiseTCInventoryChild";

const ProductWiseTCInventory = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="ProductWiseTCInventory" route="/pwtcinventory">
        <ProductWiseTCInventoryChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default ProductWiseTCInventory;

import React from "react";
import Card from "react-bootstrap/Card";

const CardContent = ({ children }) => {
  return (
    <Card style={{ width: "100%", marginTop: "10px", padding: "30px" }}>
      <Card.Body>{children}</Card.Body>
    </Card>
  );
};

export default CardContent;

import React, { useEffect, useState, useContext, useRef } from "react";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { DatePicker } from "antd";
import moment from "moment";
import toast from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";

//Global State import
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

//Context import
import AuthContext from "../../../components/shared/Context";

const AllocateTCChild = ({ allocateProduct }) => {
  let navigate = useNavigate();
  const { loggedInUserDetails } = useContext(AuthContext);
  const [tcenters, setTcenters] = useState();
  const [availableQty, setAvailbleQty] = useState(
    allocateProduct?.product_stock?.quantity
  );
  const quantityRef = useRef();
  const [formData, setFormData] = useState({
    purchase_id: allocateProduct?.product_stock?.purchase_id,
    product_id: allocateProduct?.product_stock?.product_id._id,
    sale_date: moment(),
    quantity: null,
    tc_id: null,
    delivery_person: null,
    delivery_mobile: null,
    expected_date_of_delivery: null,
    comments: null,
  });

  //Error Logging Service
  const [{ errorLogApi, expiryFormatter }] = useAtom(gbState);

  useEffect(() => {
    setAvailbleQty(
      formData?.quantity
        ? parseInt(allocateProduct?.product_stock?.quantity) -
            parseInt(formData?.quantity)
        : allocateProduct?.product_stock?.quantity
    );
  }, [formData.quantity]);

  useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    const bodyParameters = {
      key: "value",
    };
    let urlLink = `${process.env.REACT_APP_API_URL}/tc/state/${loggedInUserDetails.state}`;
    axios
      .get(urlLink, config)
      .then((res) => {
        const oldData = res.data.treatment_center;
        const filteredTC = oldData?.filter(
          (tc) => tc?.tcenters?.tc_type === "government"
        );
        // console.log(oldData);
        const updatedData = filteredTC.map((data) => {
          //   console.log(data.purchase_inventory.product_id.products);
          return {
            value: data._id,
            // label: data?.tcenters?.name_tc,
            label: (
              <ProductDisplayComponent
                tc_name={data?.tcenters?.name_tc}
                tc_id={data?.tcenters?.treatment_center_id}
              />
            ),
            dataLabel: data?.tcenters?.name_tc,
          };
        });
        //console.log(updatedData);
        setTcenters(updatedData);
      })
      .catch((err) => console.log("error", err));
  }, []);

  const handleFormSubmit = () => {
    if (
      parseInt(formData.quantity) >
      parseInt(allocateProduct?.product_stock?.quantity)
    ) {
      toast.error(
        `Quantity cannot exceed ${allocateProduct?.product_stock?.quantity}`
      );
      return;
    }

    confirmAlert({
      title: "",
      message: "Are you sure to Allocate this quanity to TC ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => productAllocationTC(),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });

    const productAllocationTC = () => {
      let userToken = localStorage.getItem("token");
      const config = {
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/state/sales/add`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        data: { ...formData },
      };
      axios(config)
        .then(function (response) {
          toast.success("Sale Registered Successfully.");
          navigate("/pwinventory");
        })
        .catch(function (error) {
          toast.error(error?.response?.data?.message);
          console.log(error);
        });
    };
  };

  const handleChange = ({ currentTarget: input }) => {
    if (formData.quantity < 0) {
      toast.error("Choose only Positive value");
      setFormData({
        ...formData,
        quantity: null,
      });
      quantityRef.current.value = null;
      return;
    }
    if (
      formData.quantity > parseInt(allocateProduct?.product_stock?.quantity)
    ) {
      toast.error(
        `Value should be less than ${allocateProduct?.product_stock?.quantity}`
      );
      setFormData({
        ...formData,
        quantity: null,
      });
      quantityRef.current.value = null;
      return;
    }

    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  return (
    <div>
      <h3>Allocate to TC</h3>
      <div className="patientUpdateButton">
        <button className="btn btn-primary" onClick={handleFormSubmit}>
          {" "}
          Allocate
        </button>{" "}
        &nbsp;&nbsp;
        <button className="btn btn-secondary" onClick={() => navigate(-1)}>
          Back
        </button>
      </div>
      <Card style={{ width: "100%", marginTop: "10px", padding: "20px" }}>
        <Card.Body className="container table-responsive">
          <table class="table table-striped">
            <tbody>
              <tr>
                <th scope="row">Product Name</th>
                <td>{allocateProduct.product_name}</td>
              </tr>
              <tr>
                <th scope="row">Product Code</th>
                <td>{allocateProduct.item_code}</td>
              </tr>
              <tr>
                <th scope="row">Expiry Date</th>
                <td>{expiryFormatter(allocateProduct?.product_expiry)}</td>
              </tr>
              <tr>
                <th scope="row">Available Quantity</th>
                <td>
                  {/* {allocateProduct?.product_stock?.quantity} */}
                  {availableQty}{" "}
                  {
                    allocateProduct?.product_stock?.product_id?.products
                      ?.storage_unit
                  }
                </td>
              </tr>
              <tr>
                <th scope="row">State</th>
                <td>{allocateProduct?.state}</td>
              </tr>
              <tr>
                <th scope="row">Treatment Center</th>
                <td>
                  <Select
                    name="tc_id"
                    onChange={(selected, dropdown) => {
                      setFormData({
                        ...formData,
                        tc_id: selected.value,
                      });
                    }}
                    filterOption={(option, input) => {
                      return option.data.dataLabel
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    className="newPatientSelect"
                    options={tcenters}
                    isDisabled={false}
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">Allocation date</th>
                <td>
                  <DatePicker
                    className="newPatientSelect w-100"
                    style={{ paddingLeft: "10px" }}
                    name="sale_date"
                    onChange={(date, dateString) => {
                      setFormData({
                        ...formData,
                        sale_date: date,
                      });
                    }}
                    showToday={true}
                    format="DD-MM-YYYY" // Explicitly set the format here
                    disabledDate={(current) => {
                      // Can not select days after today
                      return current && current > moment().endOf("day");
                    }}
                    value={
                      formData.sale_date
                        ? moment(formData.sale_date, "DD-MM-YYYY")
                        : null
                    }
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">Allocation Quantity</th>
                <td>
                  <input
                    type="number"
                    name="quantity"
                    placeholder="#Number"
                    onChange={handleChange}
                    onKeyUp={handleChange}
                    ref={quantityRef}
                    value={formData?.quantity && formData?.quantity}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">Delivery Person</th>
                <td>
                  <input
                    type="text"
                    name="delivery_person"
                    placeholder=""
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">Delivery mobile</th>
                <td>
                  <input
                    type="text"
                    name="delivery_mobile"
                    placeholder=""
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">Expected Delivery Date</th>
                <td>
                  <DatePicker
                    className="newPatientSelect w-100"
                    style={{ paddingLeft: "10px" }}
                    name="expected_date_of_delivery"
                    onChange={(date, dateString) => {
                      setFormData({
                        ...formData,
                        expected_date_of_delivery: dateString,
                      });
                    }}
                    showToday={true}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">Comments</th>
                <td>
                  <textarea
                    type="text"
                    name="comments"
                    className="newPatientSelect w-100 h-auto"
                    row={3}
                    placeholder=""
                    onChange={handleChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          {/* {JSON.stringify(formData)}
          {JSON.stringify(allocateProduct)} */}
        </Card.Body>
      </Card>
    </div>
  );
};

const ProductDisplayComponent = ({ tc_name, tc_id }) => {
  return (
    <div>
      <div style={{ fontWeight: "bold" }}>{tc_name}</div>
      <div style={{ fontSize: "smaller" }}>{tc_id}</div>
    </div>
  );
};

export default AllocateTCChild;

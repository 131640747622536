import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { DatePicker, Space } from "antd";
import { Button } from "react-bootstrap";

import MainContentCard from "../../Home/MainContentCard";
import PatientDataProcessor from "./PatientDataProcessor";

const { RangePicker } = DatePicker;
const PatientTCLevelReport = () => {
  const { state } = useLocation();
  const [data, setData] = useState(state);
  const [unfilteredData, setUnfilteredData] = useState(data);
  const [colLabel, setColLabel] = useState("Total Patients");
  const [result, setResult] = useState(null);
  const [durationResult, setDurationResult] = useState([]);
  //date range selector code
  const [dates, setDates] = useState(null);
  const [dateValue, setDateValue] = useState(null);
  const [dateInterval, setDateInterval] = useState({});
  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], "days") > 365;
    const tooEarly = dates[1] && dates[1].diff(current, "days") > 365;
    return !!tooEarly || !!tooLate;
  };
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const searchRecordsBetweenDates = (field) => {
    if (dateInterval) {
      //alert("started");
      const data_res = data.filter((d) => {
        var time = new Date(d[field]).getTime();
        return dateInterval.sd <= time && time <= dateInterval.ed;
      });
      setDurationResult(data_res);
      setData(data_res);
    }
  };

  useEffect(() => {
    if (dateValue) {
      setDateInterval({
        sd: new Date(dateValue[0]).getTime(),
        ed: new Date(dateValue[1]).getTime(),
      });
    }
  }, [dateValue]);

  return (
    <MainContentCard>
      <div className="mb-10">
        <Space direction="vertical" size={12}>
          <RangePicker
            value={dates || dateValue}
            style={{ height: "36px" }}
            disabledDate={disabledDate}
            onCalendarChange={(val) => setDates(val)}
            onChange={(val) => setDateValue(val)}
            onOpenChange={onOpenChange}
            format="YYYY-MM-DD"
          />
        </Space>{" "}
        &nbsp;&nbsp;
        <Button
          variant="info"
          onClick={() => {
            setColLabel("Patients Created");
            searchRecordsBetweenDates("createdAt");
          }}
          disabled={dateInterval.sd ? false : true}
        >
          Created
        </Button>
        &nbsp;&nbsp;
        <Button
          variant="info"
          onClick={() => {
            setColLabel("Patients Updated");
            searchRecordsBetweenDates("updatedAt");
          }}
          disabled={dateInterval.sd ? false : true}
        >
          Updated
        </Button>
        &nbsp;&nbsp;
        <Button
          onClick={() => {
            // setResult([]);
            setDurationResult([]);
            setDateValue([]);
            //getDataFromServer();
            setColLabel("Total Patients");
            setData(unfilteredData);
          }}
        >
          Reset
        </Button>
      </div>
      <div className="mt-4">
        <PatientDataProcessor jsonData={data} />
      </div>
    </MainContentCard>
  );
};

export default PatientTCLevelReport;

import { useEffect, useRef } from "react";
import Select from "react-select";
import { DatePicker } from "antd";
import moment from "moment";

import { BsQuestionCircleFill } from "react-icons/bs";
import AlertDialog from "../../../components/InfoDialog/AlertDialog";
import ContactTab from "./ContactTab";

import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import { Category } from "@material-ui/icons";

export const GeneralTab = ({
  patientId,
  handleChange,
  dropDownChange,
  bloodGroupData,
  patientFamilyAnnualIncome,
  employmentType,
  educationQualification,
  diagnosisData,
  diagnosisTypeData,
  tcid,
  formData,
  setFormData,
  selectedDiagnosis,
  adhaarValidator,
  patientNameInput,
  aadhaarNumberInput,
  genderInput,
  bloodGroupInput,
  dobInput,
  weightInput,
  motherNameInput,
  belongsToInput,
  localRegField,
  setLocalRegField,
  tc_state,

  primaryMobileInput,
  setStateValue,
  states,
  city,
  stateValue,
  setCity,
  indianCities,
  loggedInUserDetails,
}) => {
  const childRef = useRef();

  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Others", label: "Others" },
  ];

  const minorOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const bloodgroupOptions = bloodGroupData.map((data) => ({
    value: data.bloodGroupType,
    label: data.bloodGroupType,
  }));

  const patientAnnualIncomeOptions = patientFamilyAnnualIncome.map((data) => ({
    value: data.incomeType,
    label: data.incomeType,
  }));

  const emplymentTypeOptions = employmentType.map((data) => ({
    value: data.eType,
    label: data.eType,
  }));

  const educationQualOptions = educationQualification.map((data) => ({
    value: data.eqType,
    label: data.eqType,
  }));

  const sourceFundOptions = [
    { value: "ESI", label: "ESI" },
    { value: "CGHS", label: "CGHS" },
    { value: "RAIL", label: "Railways" },
    { value: "DEFN", label: "Defence" },
    { value: "STGOV", label: "State Govt." },
    { value: "OOP", label: "Out of Pocket" },
    { value: "OTHERS", label: "Others" },
  ];

  // const diagnosisOptions = diagnosisData.map((data) => ({
  //   value: data.disease,
  //   label: data.disease,
  // }));

  const maritalOptions = [
    { value: "SINGLE", label: "Single" },
    { value: "MARRIED", label: "Married" },
    { value: "DIVORCED", label: "Divorced" },
    { value: "WIDOWED", label: "Widowed" },
    { value: "UNKOWN", label: "Unknown" },
    { value: "OTHER", label: "Other" },
  ];

  const consanguinityOptions = [
    { value: "100", label: "Unknown" },
    { value: "101", label: "Yes" },
    { value: "102", label: "No" },
  ];

  const hlaStatusOptions = [
    { value: 1, label: "Pending" },
    { value: 2, label: "Done" },
    { value: 3, label: "Unknown" },
  ];

  const languagesKnownOptions = [
    { value: 1, label: "English" },
    { value: 2, label: "Hindi" },
    { value: 3, label: "Punjabi" },
    { value: 4, label: "Bengali" },
    { value: 5, label: "Maithili" },
    { value: 6, label: "Sanskrit" },
    { value: 7, label: "Tamil" },
    { value: 8, label: "Urdu" },
    { value: 9, label: "Assamese" },
    { value: 10, label: "Dogri" },
    { value: 11, label: "Kannada" },
    { value: 12, label: "Gujarati" },
    { value: 13, label: "Bodo" },
    { value: 14, label: "Manipur" },
    { value: 15, label: "Oriya" },
    { value: 16, label: "Marathi" },
    { value: 17, label: "Santali" },
    { value: 18, label: "Telugu" },
    { value: 19, label: "Sindhi" },
    { value: 20, label: "Malayalam" },
    { value: 21, label: "Kashmiri" },
  ];

  const aadharOwnerOptions = [
    { value: 1, label: "Patient" },
    { value: 2, label: "Father" },
    { value: 3, label: "Mother" },
  ];

  let diagnosisTypeOptions = {};
  if (selectedDiagnosis?.label?.toLowerCase().includes("hemophilia")) {
    diagnosisTypeOptions = diagnosisTypeData.hemophilia.map((data) => ({
      value: data.id,
      label: data.severity_of_illness,
    }));
  }

  if (selectedDiagnosis?.label?.toLowerCase().includes("vwd")) {
    diagnosisTypeOptions = diagnosisTypeData.vwd.map((data) => ({
      value: data.id,
      label: data.severity_of_illness,
    }));
  }

  function _calculateAge(birthday) {
    return moment().diff(new Date(birthday), "years");
  }

  useEffect(() => {
    if (parseInt(_calculateAge(new Date(formData.date_of_birth)) <= 18)) {
      setFormData({ ...formData, minor_patient: true });
    } else {
      setFormData({ ...formData, minor_patient: false });
    }
  }, [formData.date_of_birth]);

  const consanguinityHelp =
    "Consanguinity : Is the marriage between parents Consanguineous? Change/Modify only if you are sure.";

  return (
    <div className="newPatientFormOnly">
      <AlertDialog ref={childRef} title="Special Tranfusion note" />
      <div className="newPatientItem">
        <label htmlFor="patientId">Registration ID</label>
        <input type="text" name="patientId" value={patientId} disabled />
      </div>
      {/* {JSON.stringify(formData)} */}
      <div className="newPatientItem">
        <label htmlFor="patient_name">
          Local Registration ID &nbsp;&nbsp;
          <input
            type="checkbox"
            onChange={() => {
              setLocalRegField(!localRegField);
            }}
          />
        </label>
        <input
          type="text"
          name="local_registration_id"
          onChange={handleChange}
          disabled={localRegField}
        />
      </div>
      {/* Patient Name */}
      <div className="newPatientItem">
        <label htmlFor="patient_name">
          Patient Name <span className="text-danger">*</span>
        </label>
        <input
          type="text"
          name="patient_name"
          placeholder="Patient Name"
          value={formData.patient_name}
          onChange={handleChange}
          onClick={handleChange}
          className={`${formData.patient_name && "bg-success bg-opacity-25"}`}
          // required
          ref={patientNameInput}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="date_of_birth">
          Date of Birth <span className="text-danger">*</span>
        </label>
        <DatePicker
          // className="newPatientSelect datePicker"
          className={`${
            formData.patient_name && "bg-success bg-opacity-25"
          } newPatientSelect datePicker`}
          style={{ paddingLeft: "10px" }}
          name="date_of_birth"
          onChange={(date, dateString) => {
            setFormData({
              ...formData,
              date_of_birth: dateString,
            });
          }}
          disabledDate={(current) => {
            let customDate = moment().format("YYYY-MM-DD");
            return current && current > moment(customDate, "YYYY-MM-DD");
          }}
          showToday={true}
          ref={dobInput}
          value={
            formData?.date_of_birth &&
            moment(formData?.date_of_birth, "YYYY-MM-DD")
          }
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="age_current">Current Age</label>
        <input
          type="text"
          name="age_current"
          value={
            formData.date_of_birth &&
            _calculateAge(new Date(formData.date_of_birth)) + " yrs"
          }
          disabled
        />
      </div>
      <div className="newPatientItem">
        <label>Minor</label>
        <Select
          name="minor_patient"
          onChange={dropDownChange}
          className="newPatientSelect"
          value={
            formData.date_of_birth &&
            minorOptions.find(
              (minor) =>
                minor.value ===
                parseInt(_calculateAge(new Date(formData.date_of_birth))) < 18
            )
          }
          options={minorOptions}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="weight">Weight (in Kgs)</label>
        <input
          type="number"
          name="weight"
          step="1"
          placeholder="Weight in Kgs"
          onChange={handleChange}
          min="0"
          ref={weightInput}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="height">Height (in cm)</label>
        <input
          type="number"
          name="height"
          step="0.01"
          placeholder="in Centimeters"
          min="0"
          onChange={handleChange}
        />
      </div>
      <div className="newPatientItem">
        <label>
          Gender <span className="text-danger">*</span>
        </label>
        <Select
          name="gender"
          onChange={
            //dropDownChange
            (selected, dropdown) => {
              //console.log(selected);
              setFormData({
                ...formData,
                gender: JSON.stringify(selected),
              });
            }
          }
          className={`newPatientSelect`}
          styles={{
            control: (base, state) => ({
              ...base,
              borderColor: `${formData?.gender ? "green" : "lightgray"}`,
            }),
          }}
          options={genderOptions}
          ref={genderInput}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
          value={formData?.gender && JSON.parse(formData?.gender)}
        />
      </div>
      <div className="newPatientItem">
        <label>Marital Status</label>
        <Select
          name="marital_status"
          onChange={
            //dropDownChange
            (selected, dropdown) => {
              //console.log(selected);
              setFormData({
                ...formData,
                marital_status: JSON.stringify(selected),
              });
            }
          }
          className="newPatientSelect"
          options={maritalOptions}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="patient_name">Spouse Name</label>
        <input
          type="text"
          name="spouse_name"
          placeholder="Spouse Name"
          value={formData.spouse_name}
          onChange={handleChange}
          onClick={handleChange}
          className={`${formData.spouse_name && "bg-success bg-opacity-25"}`}
        />
      </div>
      <div className="newPatientItem">
        <label>Blood Group</label>
        <Select
          name="bloodgroup"
          onChange={
            //dropDownChange
            (selected, dropdown) => {
              //console.log(selected);
              setFormData({
                ...formData,
                bloodgroup: JSON.stringify(selected),
              });
            }
          }
          className="newPatientSelect"
          options={bloodgroupOptions}
          ref={bloodGroupInput}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="mothers_name">Mother's Name</label>
        <input
          type="text"
          name="mothers_name"
          placeholder="Mother's Name"
          className={`${formData.mothers_name && "bg-success bg-opacity-25"}`}
          onChange={handleChange}
          onClick={handleChange}
          ref={motherNameInput}
          value={formData?.mothers_name && formData?.mothers_name}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="fathers_name">Father's Name</label>
        <input
          type="text"
          name="fathers_name"
          placeholder="Father's Name"
          value={formData.fathers_name}
          onChange={handleChange}
          onClick={handleChange}
        />
      </div>

      <div className="newPatientItem">
        {/* <label htmlFor="card_number">{selectedCard} Number</label> */}
        <label htmlFor="card_number">
          Aadhaar Card Number <span className="text-danger">*</span>
        </label>
        <input
          type="text"
          name="card_number"
          placeholder="XXXXXXXXXXXX"
          onChange={handleChange}
          onBlur={adhaarValidator}
          // required
          ref={aadhaarNumberInput}
        />
      </div>
      {/* New field added 16/01/23 */}
      <div className="newPatientItem">
        <label>
          Aadhaar Belongs to <span className="text-danger">*</span>
        </label>
        <Select
          name="belongs_to"
          onChange={
            //dropDownChange
            (selected, dropdown) => {
              //console.log(selected);
              setFormData({
                ...formData,
                belongs_to: JSON.stringify(selected),
              });
            }
          }
          className="newPatientSelect"
          options={aadharOwnerOptions}
          ref={belongsToInput}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
        />
      </div>
      <div className="newPatientItem">
        <label>Patient/Family Ann. Income</label>

        <Select
          name="patient_annual_income"
          onChange={
            //dropDownChange
            (selected, dropdown) => {
              //console.log(selected);
              setFormData({
                ...formData,
                patient_annual_income: JSON.stringify(selected),
              });
            }
          }
          className="newPatientSelect"
          options={patientAnnualIncomeOptions}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
        />
      </div>
      <div className="newPatientItem">
        <label>Employment Type</label>

        <Select
          name="employment_type"
          onChange={
            //dropDownChange
            (selected, dropdown) => {
              //console.log(selected);
              setFormData({
                ...formData,
                employment_type: JSON.stringify(selected),
              });
            }
          }
          className="newPatientSelect"
          options={emplymentTypeOptions}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
        />
      </div>
      <div className="newPatientItem">
        <label>Educational Qualification</label>
        <Select
          name="education"
          onChange={
            //dropDownChange
            (selected, dropdown) => {
              //console.log(selected);
              setFormData({
                ...formData,
                education: JSON.stringify(selected),
              });
            }
          }
          className="newPatientSelect"
          options={educationQualOptions}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
        />
      </div>
      <div className="newPatientItem">
        <label>Source of Funds</label>
        <Select
          name="source_of_funds"
          onChange={
            //dropDownChange
            (selected, dropdown) => {
              //console.log(selected);
              setFormData({
                ...formData,
                source_of_funds: JSON.stringify(selected),
              });
            }
          }
          className="newPatientSelect"
          options={sourceFundOptions}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
        />
      </div>
      <div className="newPatientItem">
        <label>Diagnosis</label>
        <input
          type="text"
          name="diagnosis_name"
          value={formData.diagnosis_name}
          disabled
        />
      </div>
      {(selectedDiagnosis?.category?.toLowerCase().includes("hemophilia") ||
        selectedDiagnosis?.category.toLowerCase().includes("haemophilia")) && (
        <div className="newPatientItem">
          <label>Severity of illness</label>
          <Select
            name="severity_of_illness"
            onChange={
              //dropDownChange
              (selected, dropdown) => {
                //console.log(selected);
                setFormData({
                  ...formData,
                  severity_of_illness: JSON.stringify(selected),
                });
              }
            }
            className="newPatientSelect"
            options={diagnosisTypeOptions}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
          />
        </div>
      )}
      <div className="newPatientItem">
        <label htmlFor="diagnosis_others">Other Diagnosis</label>
        <input
          type="text"
          name="diagnosis_others"
          placeholder="Other than selection"
          onChange={handleChange}
        />
      </div>
      <div className="newPatientItem">
        <label>Languages Known</label>
        <Select
          name="languages_known"
          isMulti
          onChange={(selected, dropdown) => {
            // console.log(selected);
            if (selected.length) {
              setFormData({
                ...formData,
                languages_known: JSON.stringify(selected),
              });
            }
          }}
          className="basic-multi-select"
          options={languagesKnownOptions}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
        />
      </div>
      {selectedDiagnosis?.category?.toLowerCase().includes("thalassemia") && (
        <div className="newPatientItem">
          <label>HLA Status</label>
          <Select
            name="hla_status"
            onChange={(selected, dropdown) => {
              //console.log(selected);
              setFormData({
                ...formData,
                hla_status: JSON.stringify(selected),
              });
            }}
            className="newPatientSelect"
            options={hlaStatusOptions}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
          />
        </div>
      )}

      <div className="newPatientItem">
        <label>
          Consanguinity &nbsp;&nbsp;
          <BsQuestionCircleFill
            color="green"
            className="helper-icon"
            onClick={() => {
              childRef.current.handleClickOpenInfo(
                "Consanguinity",
                consanguinityHelp
              );
            }}
          />
        </label>
        <Select
          name="consanguinity"
          onChange={dropDownChange}
          className="newPatientSelect"
          value={
            consanguinityOptions.find(
              (val) => val.value === formData.consanguinity
            ) || { value: 100, label: "Unknown" }
          }
          options={consanguinityOptions}
        />
      </div>
      {/* <div className="newPatientItem">
        <label htmlFor="treatment_center">Treatment Center</label>
        <input
          type="text"
          name="treatment_center"
          value={tcid}
          onChange={handleChange}
          disabled
        />
      </div> */}
      {/* <fieldset className="border p-2 w-100">
        <legend className="float-none w-auto p-0 fs-5">Contact</legend>
      </fieldset> */}
      <br />
      <ErrorBoundaryRoutes page="ContactTab" route="/patient-add">
        <ContactTab
          primaryMobileInput={primaryMobileInput}
          handleChange={handleChange}
          dropDownChange={dropDownChange}
          setStateValue={setStateValue}
          states={states}
          city={city}
          stateValue={stateValue}
          setCity={setCity}
          indianCities={indianCities}
          setFormData={setFormData}
          formData={formData}
          tc_state={tc_state}
        />
      </ErrorBoundaryRoutes>
    </div>
  );
};

export default GeneralTab;

import React, { useState, useEffect, useContext } from "react";
import { read, utils } from "xlsx";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { Card, Table, Button, Modal, Form } from "react-bootstrap";
import axios from "axios";
import toast from "react-hot-toast";
import moment from "moment";
import { bloodGroupData } from "../../../components/Data/ChartData";

//Global State import
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

//Context import
import AuthContext from "../../../components/shared/Context";

const PatientImportsChild = () => {
  let navigate = useNavigate();
  const { loggedInUserDetails } = useContext(AuthContext);
  const [fileData, setFileData] = useState([]);
  const [showMapping, setShowMapping] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [mapping, setMapping] = useState({});
  const [currentRecordIndex, setCurrentRecordIndex] = useState(0);
  const [constantValues, setConstantValues] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState(null);
  const [diagnosesData, setDiagnosesData] = useState([]);

  //Error Logging Service
  const [{ errorLogApi, sanitizeInput, encryptData, user }] = useAtom(gbState);

  const fieldOptions = [
    { value: "local_registration_id", label: "Local Registration ID" },
    { value: "patient_name", label: "Name" },
    { value: "gender", label: "Gender" },
    { value: "date_of_birth", label: "Date of Birth mm/dd/yyyy" },
    { value: "bloodgroup", label: "Blood Group" },
    { value: "fathers_name", label: "Father's Name" },
    { value: "mothers_name", label: "Mother's Name" },
    { value: "category", label: "Category" },
    { value: "sub_category_name", label: "Sub Category Name" },
    { value: "primary_mobile", label: "Mobile Number" },
    { value: "card_type", label: "Card Type" },
    { value: "card_number", label: "Card Number" },
    { value: "belongs_to", label: "Belongs To" },
    { value: "address", label: "Address" },
    { value: "landmark", label: "Landmark" },
    { value: "pincode", label: "Pincode" },
  ];

  const excelDateToJSDate = (excelDate) => {
    return moment("1899-12-31").add(excelDate, "days").format("MM/DD/YYYY");
  };

  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Others", label: "Others" },
  ];

  const aadharOwnerOptions = [
    { value: 1, label: "Patient" },
    { value: 2, label: "Father" },
    { value: 3, label: "Mother" },
  ];

  const bloodgroupOptions = bloodGroupData.map((data) => ({
    value: data.bloodGroupType,
    label: data.bloodGroupType,
  }));

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = utils.sheet_to_json(worksheet, { header: 1 });

      // Convert date fields immediately after reading
      const formattedData = jsonData.slice(1).map((row) => {
        const newRow = { ...row };
        newRow["Date of Birth mm/dd/yyyy"] = excelDateToJSDate(
          row["Date of Birth mm/dd/yyyy"]
        );
        return newRow;
      });

      setHeaders(jsonData[0]);
      //setFileData(jsonData.slice(1));
      setFileData(formattedData);
      setShowMapping(true);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleMappingChange = (selectedOption, header) => {
    setMapping((prevMapping) => ({
      ...prevMapping,
      [header]: selectedOption ? selectedOption.value : null,
    }));
  };

  const handleConstantValueChange = (e, field) => {
    setConstantValues({
      ...constantValues,
      [field]: e.target.value,
    });
  };

  const handleNextRecord = () => {
    setFormData(null);
    setCurrentRecordIndex((prevIndex) =>
      Math.min(prevIndex + 1, fileData.length - 1)
    );
  };

  const handlePreviousRecord = () => {
    setFormData(null);
    setCurrentRecordIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const normalizeSpaces = (input) => {
    return input.replace(/\s+/g, " ").trim();
  };

  const saveCurrentRecord = () => {
    const record = fileData[currentRecordIndex];
    const patientData = headers.reduce((obj, header) => {
      const field = mapping[header];
      const columnIndex = headers.indexOf(header);
      obj[field] = record[columnIndex] || constantValues[field] || "";
      return obj;
    }, {});

    setFormData({
      ...patientData,
      date_of_birth: excelDateToJSDate(patientData?.date_of_birth),
      // sub_category_name: setdiagnosis(patientData?.sub_category_name),
      category_id: setdiagnosis(patientData?.sub_category_name)?.category_id
        ?._id,
      diagnosis_id: setdiagnosis(patientData?.sub_category_name)?._id,
      diagnosis_code: setdiagnosis(patientData?.sub_category_name)?.id,
      diagnosis_name: setdiagnosis(patientData?.sub_category_name)?.label,
      gender: JSON.stringify(
        genderOptions.find((g) => g.label === patientData.gender)
      ),
      belongs_to: JSON.stringify(
        aadharOwnerOptions.find((c) => c.label === patientData.belongs_to)
      ),
      bloodgroup: JSON.stringify(
        bloodgroupOptions.find((b) => b.label.includes(patientData.bloodgroup))
      ),
      patientId: generateRandomID(patientData),
      card_number: encryptData(patientData?.card_number),
      card_type: "AADHARCARD",
      treatment_center: loggedInUserDetails?.tc_id,
      tc_state: loggedInUserDetails.state,
      createdBy: loggedInUserDetails?._id,
      patient_name: normalizeSpaces(patientData.patient_name),
    });
  };

  function makeid(length) {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const generateRandomID = (patientData) => {
    const id_4_digit = Math.floor(1000 + Math.random() * 9000);
    const pre_id = `${loggedInUserDetails.state.substring(0, 2).toUpperCase()}`;

    const getSubIdPrefix = (category) => {
      const lowerCaseCategory = category?.toLowerCase();
      if (
        lowerCaseCategory.includes("hemophilia") ||
        lowerCaseCategory.includes("haemophilia")
      ) {
        return "HID";
      } else if (lowerCaseCategory.includes("thalassemia")) {
        return "TID";
      } else if (lowerCaseCategory.includes("sickle")) {
        return "SKL";
      }
      return "GEN"; // Generic prefix for other categories
    };

    const sub_id = `${pre_id}-${getSubIdPrefix(patientData?.category)}-`;
    const id_main = `${sub_id}${id_4_digit}${makeid(2)}`;
    return id_main;
  };

  const savePatients = () => {
    let userToken = localStorage.getItem("token");

    if (!formData?.treatment_center) {
      toast.error("Please add Treatment Center for Patient");
      return;
    }

    if (!formData?.patient_name) {
      toast.error("Please add Name Patient");
      return;
    }

    if (!formData?.gender) {
      toast.error("Please Select Gender");
      return;
    }

    if (!formData?.diagnosis_id) {
      toast.error("Please Select Diagnosis");
      return;
    }

    if (!formData?.diagnosis_name) {
      toast.error("Please Select Diagnosis name");
      return;
    }

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/patient/v2/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        toast.success("Patient Registered Successfully.");
        setFormData(null);
      })
      .catch(function (error) {
        console.log(error);
        setFormData(null);
        errorLogApi(error, "PatientCreateChild", "/patient-add");
        toast.error(error.response.data.message);
        if (error?.response?.data?.message?.toLowerCase().includes("invalid")) {
          navigate("/login", { replace: true });
        }
      });
  };

  const getMappedValue = (record, header) => {
    const mappedField = mapping[header];
    if (mappedField) {
      const columnIndex = headers.indexOf(header);
      return record[columnIndex] || constantValues[mappedField] || "";
    }
    return constantValues[header] || "";
  };

  // Getting Diagnosis from server
  useEffect(() => {
    const getDataFromServer = () => {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };

      let urlLink = `${process.env.REACT_APP_API_URL}/diagnosis/`;

      axios
        .get(urlLink, config)
        .then((res) => {
          const olDdiagnosesData = res.data.diagnoses;
          // console.log(olDdiagnosesData);
          const res_diagnosesData = olDdiagnosesData.map((d) => ({
            ...d,
            cat_name: d?.category_id?.category,
          }));
          setDiagnosesData(res_diagnosesData);
        })
        .catch((err) => console.log("error", err));
    };

    getDataFromServer();
  }, []);

  //Diagnosis Function

  const setdiagnosis = (diagnosis) => {
    //console.log(diagnosis);
    if (!diagnosis) return toast.error("This data is incomplete!");

    const diag = diagnosesData?.find((d) => d.label.includes(diagnosis));
    console.log(diag);
    return diag;
  };

  return (
    <div>
      <Card>
        <Card.Body>
          <Card.Title>Excel File Upload</Card.Title>
          <input type="file" onChange={handleFileUpload} />
        </Card.Body>
      </Card>

      {showMapping && (
        <Modal show={showMapping} onHide={() => setShowMapping(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Map Excel Headers</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {headers.map((header, index) => (
              <Form.Group key={index} controlId={`mapping-${header}`}>
                <Form.Label>{header}</Form.Label>
                <Select
                  onChange={(selectedOption) =>
                    handleMappingChange(selectedOption, header)
                  }
                  value={
                    fieldOptions.find(
                      (option) => option.value === mapping[header]
                    ) || null
                  }
                  options={fieldOptions.filter(
                    (option) =>
                      !Object.values(mapping).includes(option.value) ||
                      option.value === mapping[header]
                  )}
                />
              </Form.Group>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowMapping(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {fileData.length > 0 && (
        <Card>
          <Card.Body>
            <Card.Title>Record Navigation</Card.Title>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {headers.map((header, index) => (
                  <tr key={index}>
                    <td>{header}</td>
                    <td>
                      {getMappedValue(fileData[currentRecordIndex], header)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Button
              onClick={handlePreviousRecord}
              disabled={currentRecordIndex === 0}
            >
              Previous
            </Button>
            &nbsp;&nbsp;
            <Button
              onClick={handleNextRecord}
              disabled={currentRecordIndex === fileData.length - 1}
            >
              Next
            </Button>
            &nbsp;&nbsp;
            <Button onClick={saveCurrentRecord} disabled={isSaving}>
              {isSaving ? "Verify..." : "Set This Record"}
            </Button>
            &nbsp;&nbsp;
            <Button onClick={savePatients} disabled={!formData}>
              Save This Record
            </Button>
          </Card.Body>
        </Card>
      )}

      {JSON.stringify(formData?.length)}
    </div>
  );
};

export default PatientImportsChild;

import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import "./AddDiagnosis.css";
import diagnosisIcon from "../../../images/diagnosis.jpg";
import CardComponent from "../../../components/Card/CardComponent";
import { Button } from "@material-ui/core";
import Card from "react-bootstrap/Card";
import toast from "react-hot-toast";
import MainContent from "../../Home/MainContent";

//Context import
import AuthContext from "../../../components/shared/Context";

const diagnoses = {
  thalassemia: [
    { id: 1001, label: "Alpha Thalassemia" },
    { id: 1002, label: "E-Beta Thalassemia" },
    { id: 1003, label: "HbE Thalassemia" },
    { id: 1004, label: "HbD Thalassemia" },
    { id: 1016, label: "Sickle Beta Thalassemia" },
    { id: 1005, label: "Thalassemia Intermedia" },
    { id: 1006, label: "Thalassemia Major" },
    { id: 1007, label: "Thalassemia Minor (Carrier)" },
  ],
  hemophilia: [
    { id: 1008, label: "Hemophilia A" },
    { id: 1009, label: "Hemophilia B" },
    { id: 1010, label: "Hemophilia A with Inhibitors" },
    { id: 1014, label: "Hemophilia B with Inhibitors" },
    { id: 1013, label: "Von Willebrand Disease (vWd)" },
    { id: 1017, label: "Factor VII Difficiency" },
    { id: 1015, label: "Hemophilia C" },
    { id: 1011, label: "Acquired Hemophilia" },
  ],
  sickel_cell: [{ id: 1012, label: "Sickle Cell" }],
};

const AddDiagnosis = () => {
  const { state } = useLocation();
  let patientRecord = state;
  const { setSelectedDiagnosis, selectedDiagnosis } = useContext(AuthContext);
  let navigate = useNavigate();
  const [diagnosesData, setDiagnosesData] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [btnStatus, setBtnStatus] = useState(false);
  const [hemophiliaOptions, sethemophiliaOptions] = useState(null);
  const [thalassemiaOptions, setThalassemiaOptions] = useState(null);
  const [sicklecellOptions, setSicklecellOptions] = useState(null);

  // Getting Diagnosis from server
  useEffect(() => {
    const getDataFromServer = () => {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };

      let urlLink = `${process.env.REACT_APP_API_URL}/diagnosis/`;

      axios
        .get(urlLink, config)
        .then((res) => {
          const olDdiagnosesData = res.data.diagnoses;
          // console.log(olDdiagnosesData);
          const res_diagnosesData = olDdiagnosesData.map((d) => ({
            ...d,
            cat_name: d?.category_id?.category,
          }));
          setDiagnosesData(res_diagnosesData);
        })
        .catch((err) => console.log("error", err));
    };

    getDataFromServer();
  }, []);

  useEffect(() => {
    // Filter hemophilia or haemophilia
    const hemData = diagnosesData?.reduce((accumulator, d) => {
      if (
        d?.category_id?.category.toLowerCase().includes("hemophilia") ||
        d?.category_id?.category.toLowerCase().includes("haemophilia")
      ) {
        accumulator.push({
          id: d?.id,
          label: d?.label,
          category_id: d?.category_id?._id,
          category: d?.category_id?.category,
          diagnosis_id: d?._id,
        });
      }
      return accumulator;
    }, []);
    //setting hemophilia data
    sethemophiliaOptions(hemData);

    // Filter Thalassemia
    const thalData = diagnosesData?.reduce((accumulator, d) => {
      if (d?.category_id?.category.toLowerCase().includes("thalassemia")) {
        accumulator.push({
          id: d?.id,
          label: d?.label,
          category_id: d?.category_id?._id,
          category: d?.category_id?.category,
          diagnosis_id: d?._id,
        });
      }
      return accumulator;
    }, []);
    setThalassemiaOptions(thalData);

    //Filter Sickle Cell
    const sickleData = diagnosesData?.reduce((accumulator, d) => {
      if (d?.category_id?.category.toLowerCase().includes("sickle")) {
        accumulator.push({
          id: d?.id,
          label: d?.label,
          category_id: d?.category_id?._id,
          category: d?.category_id?.category,
          diagnosis_id: d?._id,
        });
      }
      return accumulator;
    }, []);
    setSicklecellOptions(sickleData);
  }, [diagnosesData]);

  useEffect(() => {
    // console.log(selectedDiagnosis);
    if (selectedDiagnosis?.category?.toLowerCase().includes("sickle")) {
      toast.success("This functionality will be rolled out in future");
      setSelectedMenu(null);
      setSelectedDiagnosis({});
      setBtnStatus(true);
      return;
    }
  }, [selectedDiagnosis]);

  const handleOnClick = (id, label, category, category_id, diagnosis_id) => {
    if (selectedDiagnosis?.category?.toLowerCase().includes("sickle")) {
      toast.success("This functionality will be rolled out in future");
      setSelectedMenu(null);
      setSelectedDiagnosis({});
      setBtnStatus(true);
      return;
    }
    setBtnStatus(false);
    setSelectedDiagnosis({ id, label, category, category_id, diagnosis_id });
    // navigate("/patient-add");
  };

  const handleAddPatients = (id, label) => {
    if (selectedMenu) {
      navigate("/patient-add", { state: patientRecord });
    } else {
      toast.error(`Please select a diagnosis`);
    }
  };

  return (
    <MainContent>
      {/* <SideBar /> */}

      <Card
        style={{
          width: "100%",
          marginTop: "10px",
          padding: "1px",
          height: "100%",
        }}
      >
        {/* {JSON.stringify(selectedMenu)} */}
        <Card.Body>
          <div className="diagnosis-list">
            <div className="diagnosis-child-icon">
              <img src={diagnosisIcon} width="120px" />
            </div>
            <div className="diagnosis-child">
              {patientRecord && <PreRegisterComponent data={patientRecord} />}
              {hemophiliaOptions ? (
                <CardComponent
                  title="Hemophilia / Bleeding Disorders"
                  menus={hemophiliaOptions}
                  handleOnClick={handleOnClick}
                  selectedMenu={selectedMenu}
                  setSelectedMenu={setSelectedMenu}
                  btnStatus={btnStatus}
                />
              ) : (
                "loading..."
              )}
              {thalassemiaOptions ? (
                <CardComponent
                  title="Thalassemia"
                  menus={thalassemiaOptions}
                  handleOnClick={handleOnClick}
                  selectedMenu={selectedMenu}
                  setSelectedMenu={setSelectedMenu}
                  btnStatus={btnStatus}
                />
              ) : (
                "loading..."
              )}
              {sicklecellOptions ? (
                <CardComponent
                  title="Sickle Cell * Work in Progress.."
                  menus={sicklecellOptions}
                  handleOnClick={handleOnClick}
                  selectedMenu={selectedMenu}
                  setSelectedMenu={setSelectedMenu}
                  btnStatus={btnStatus}
                />
              ) : (
                "loading..."
              )}
              <div className="skip-diagnosis flex sm:flex-col md:flex-row">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  className="m-2"
                  disabled={btnStatus}
                  onClick={handleAddPatients}
                >
                  Start Patient Registration
                </Button>{" "}
              </div>
            </div>
            {/* {JSON.stringify(diagnosesData)} */}
          </div>
        </Card.Body>
      </Card>
      {/* {JSON.stringify(thalassemiaOptions)} */}
    </MainContent>
  );
};

const PreRegisterComponent = ({ data }) => {
  const patientfields = [
    "temp_patient_id",
    "patient_name",
    "gender",
    "primary_mobile",
    "whatsapp_number",
    "email",
  ];

  // Utility function to check if a value is an object
  const isObject = (key) => {
    if (key === "gender") {
      return JSON.parse(data[key])?.label;
    } else {
      return data[key];
    }
  };

  return (
    <div className="container mt-2">
      <Card>
        <Card.Header>
          <i className="fas fa-align-justify" />
          <strong>Patient General Details</strong>
        </Card.Header>
        <Card.Body>
          {patientfields.map((pfields) => {
            return (
              <div key={pfields} className="row">
                <div className="col-md-6 col-xs-6 divTableCell ">
                  <b>{pfields.replaceAll("_", " ").toUpperCase()}</b>
                </div>
                <div className="col-md-6 col-xs-6 divTableCell ">
                  {isObject(pfields)}
                </div>
              </div>
            );
          })}
        </Card.Body>
      </Card>
    </div>
  );
};

export default AddDiagnosis;

import React from "react";
import Select from "react-select";

const CrepitusInstabilityTab = ({ dropDownChange, formData }) => {
  const dropDown02 = [
    {
      id: 1,
      name: "0",
      value: 0,
    },
    {
      id: 2,
      name: "1",
      value: 1,
    },
    {
      id: 3,
      name: "2",
      value: 2,
    },
  ];

  const dropDown01 = [
    {
      id: 1,
      name: "None",
      value: 0,
    },
    {
      id: 2,
      name: "Present",
      value: 1,
    },
  ];

  const CrepitusAndInstabilites = {
    crepitus_left: [
      "ankle_left_crepitus",
      "elbow_left_crepitus",
      "knee_left_crepitus",
    ],
    crepitus_right: [
      "ankle_right_crepitus",
      "elbow_right_crepitus",
      "knee_right_crepitus",
    ],
    instabilities_left: [
      "ankle_left_instability",
      "elbow_left_instability",
      "knee_left_instability",
    ],
    instabilities_right: [
      "ankle_right_instability",
      "elbow_right_instability",
      "knee_right_instability",
    ],
  };

  return (
    <div className="table-responsive w-100" style={{ marginRight: "40px" }}>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col" className="text-center">
              Ankle
            </th>
            <th scope="col" className="text-center">
              Elbow
            </th>
            <th scope="col" className="text-center">
              Knee
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colSpan={4}>CREPITUS</th>
          </tr>
          <tr>
            <td className="table-row-label">Left</td>
            {CrepitusAndInstabilites.crepitus_left.map((cp_left, index) => (
              <td className="table-row-options">
                <DropDownMenu
                  fieldName={cp_left}
                  dropDownChange={dropDownChange}
                  defvalue={
                    formData[CrepitusAndInstabilites.crepitus_left[index]]
                  }
                  dropDownValues={dropDown01}
                />
              </td>
            ))}
          </tr>
          <tr>
            <td className="table-row-label">Right</td>

            {CrepitusAndInstabilites.crepitus_right.map((cp_right, index) => (
              <td className="table-row-options">
                <DropDownMenu
                  fieldName={cp_right}
                  dropDownChange={dropDownChange}
                  defvalue={
                    formData[CrepitusAndInstabilites.crepitus_right[index]]
                  }
                  dropDownValues={dropDown01}
                />
              </td>
            ))}
          </tr>
          <tr>
            <th colSpan="4">INSTABILITY</th>
          </tr>
          <tr>
            <td className="table-row-label">Left</td>

            {CrepitusAndInstabilites.instabilities_left.map(
              (ins_left, index) => (
                <td className="table-row-options">
                  <DropDownMenu
                    fieldName={ins_left}
                    dropDownChange={dropDownChange}
                    defvalue={
                      formData[
                        CrepitusAndInstabilites.instabilities_left[index]
                      ]
                    }
                    dropDownValues={dropDown02}
                  />
                </td>
              )
            )}
          </tr>
          <tr>
            <td className="table-row-label">Right</td>
            {CrepitusAndInstabilites.instabilities_right.map(
              (ins_right, index) => (
                <td className="table-row-options">
                  <DropDownMenu
                    fieldName={ins_right}
                    dropDownChange={dropDownChange}
                    defvalue={
                      formData[
                        CrepitusAndInstabilites.instabilities_right[index]
                      ]
                    }
                    dropDownValues={dropDown02}
                  />
                </td>
              )
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CrepitusInstabilityTab;

const DropDownMenu = ({
  fieldName,
  dropDownChange,
  dropDownValues,
  defvalue,
}) => {
  const dropDownOptions = dropDownValues.map((ddval) => ({
    value: ddval.value,
    label: ddval.name,
  }));

  return (
    <div className="newPatientItem">
      <Select
        name={fieldName}
        onChange={dropDownChange}
        value={dropDownOptions.find((v) => v.value === parseInt(defvalue))}
        className="newPatientSelect"
        options={dropDownOptions}
        isDisabled={true}
      />
    </div>
  );
};

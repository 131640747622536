import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import axios from "axios";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const BiochemTab = ({ emrRecordId, emrRecord }) => {
  const [formData, setFormData] = useState({
    random_blood_glucose: null,
    calcium: null,
    phosphorus: null,
    ionized_calcium: null,
    bun_urea: null,
    creatinine: null,
    sodium: null,
    potassium: null,
    chloride: null,
    stb_total_bilirubin: null,
    sdb_direct_bilirubin: null,
    sib_indirect_bilirubin: null,
    sgot: null,
    sgpt: null,
    total_protein: null,
    alb_albumin: null,
    alp_alkaline_phosphates: null,
  });

  const biochemKeys = Object.keys(formData);

  useEffect(() => {
    if (emrRecord) {
      localStorage.setItem(
        `${emrRecordId}-biochemistry`,
        JSON.stringify(emrRecord)
      );
    }

    let biochem = localStorage.getItem(`${emrRecordId}-biochemistry`);
    setFormData({ ...formData, ...JSON.parse(biochem) });
  }, [emrRecord]);

  const updateEmr = (e) => {
    e.preventDefault();
    //changeTab(1);

    let userToken = localStorage.getItem("token");
    const config = {
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/emr/update/${emrRecordId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, biochemistry: true },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        localStorage.setItem(
          `${emrRecordId}-biochemistry`,
          JSON.stringify(formData)
        );
        toast.success("Biochemistry sheet updated Successfully.");
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error.response.data));
      });
  };

  const handleChange = ({ currentTarget: input }) => {
    //alert([input.name]);
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const biochemLabels = [
    "Random Blood Glucose",
    "Calcium",
    "Phosphorus",
    "Ionized Calcium",
    "BUN (Urea)",
    "Creatinine",
    "Sodium",
    "Potassium",
    "Chloride",
    "STB (Total Bilirubin)",
    "SDB (Direct Bilirubin)",
    "SIB (Indirect Bilirubin)",
    "SGOT",
    "SGPT",
    "Total Protein",
    "ALB (Albumin)",
    "ALP (Alkaline Phosphate)",
  ];
  const measuringUnit = [
    "mg/dl",
    "mg/dl",
    "mg/dl",
    "mg/dl",
    "mg/dl",
    "mg/dl",
    "mmol/L",
    "mmol/L",
    "mmol/L",
    "mg/dl",
    "mg/dl",
    "mg/dl",
    "U/L",
    "U/L",
    "",
    "g/dL",
    "IU/L",
  ];

  return (
    <div className="newPatientFormOnly">
      {/* {JSON.stringify(emrRecord)} */}
      {/* {JSON.stringify(biochemKeys)} */}
      {/* {JSON.stringify(formData)} */}
      {biochemKeys.map((key, idx) => (
        <InputField
          handleChange={handleChange}
          fieldname={key}
          formData={formData}
          index={idx}
          measuringUnit={measuringUnit}
          biochemLabels={biochemLabels}
        />
      ))}
    </div>
  );
};

export default BiochemTab;

export const InputField = ({
  handleChange,
  fieldname,
  formData,
  index,
  measuringUnit,
  biochemLabels,
}) => {
  function capitalize(s) {
    return s && s[0].toUpperCase() + s.slice(1);
  }

  return (
    <div className="newPatientItem">
      <label htmlFor={fieldname}>
        {/* {capitalize(fieldname.replaceAll("_", " ").toUpperCase())}{" "} */}
        {biochemLabels[index]}
      </label>
      <InputGroup className="mb-3">
        <Form.Control
          aria-label={fieldname}
          type="number"
          name={fieldname}
          onChange={handleChange}
          value={formData[fieldname]}
          disabled
        />
        {measuringUnit[index] && (
          <InputGroup.Text id="basic-addon2">
            {measuringUnit[index]}
          </InputGroup.Text>
        )}
      </InputGroup>
    </div>
  );
};

import React from "react";
const VisitInfoBlock = ({ selectedPatientVisit, patientDiagnosis }) => {
  return (
    <div>
      {/* {JSON.stringify(PatientVisit)} */}
      <fieldset className="border p-2 w-100">
        <legend className="float-none w-auto p-2 fs-5">
          Visit Information
        </legend>
        <div className="newPatientFormOnly">
          <div
            style={{ width: "100%", paddingLeft: "20px", paddingRight: "20px" }}
          >
            <div className="table-responsive overflow-y:hidden">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th scope="row">Date of Visit</th>
                    <td>{selectedPatientVisit?.date_of_visit.slice(0, 10)}</td>
                    <th scope="row">Patient for</th>
                    <td>
                      {selectedPatientVisit?.consultation
                        ? "Consultation"
                        : "Hospitalization"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Ward/OPD Number</th>
                    <td>{selectedPatientVisit?.ward_clinic_opd_number}</td>
                    <th scope="row">Doctor</th>
                    <td>
                      Dr{" "}
                      {selectedPatientVisit?.doctor_id?.last_name
                        ? selectedPatientVisit?.doctor_id?.first_name +
                          " " +
                          selectedPatientVisit?.doctor_id?.last_name
                        : selectedPatientVisit?.doctor_id?.first_name}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Visit / Consultation Reason</th>
                    <td>
                      {JSON.parse(selectedPatientVisit?.visit_reason)?.label}
                    </td>
                    <th scope="row">Travel Distance</th>
                    <td>{selectedPatientVisit?.travel_distance}</td>
                  </tr>
                  <tr>
                    <th scope="row">Travel Mode</th>
                    <td>
                      {JSON.parse(selectedPatientVisit?.travel_mode)?.label}
                    </td>
                    <th scope="row">Payment Status</th>
                    <td>
                      {JSON.parse(selectedPatientVisit?.payment_status)?.label}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset className="border p-2 w-100">
        <legend className="float-none w-auto p-2 fs-5">
          General Examination
        </legend>
        <div className="newPatientFormOnly">
          <div
            style={{ width: "100%", paddingLeft: "20px", paddingRight: "20px" }}
          >
            <div className="table-responsive overflow-y:hidden">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th scope="row">Weight (Current)</th>
                    <td>{selectedPatientVisit?.weight}</td>
                    <th scope="row">Height (Current)</th>
                    <td>{selectedPatientVisit?.height}</td>
                  </tr>
                  <tr>
                    <th scope="row">Pre Transfusion Hb</th>
                    <td>{selectedPatientVisit?.pre_transfusion_hb}</td>
                    <th scope="row">Doctor</th>
                    <td>{selectedPatientVisit?.weight}</td>
                  </tr>
                  {patientDiagnosis?.toLowerCase().includes("thalassemia") && (
                    <>
                      <tr>
                        <th scope="row">Liver</th>
                        <td>{selectedPatientVisit?.liver}</td>
                        <th scope="row">Spleen</th>
                        <td>{selectedPatientVisit?.spleen}</td>
                      </tr>
                      <tr>
                        <th scope="row">Post Transfusion Hb</th>
                        <td>{selectedPatientVisit?.post_transfusion_hb}</td>
                        <th scope="row">{}</th>
                        <td>{}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset className="border p-2 w-100">
        <legend className="float-none w-auto p-2 fs-5">
          Financial Expense/Impact
        </legend>
        <div className="newPatientFormOnly">
          <div
            style={{ width: "100%", paddingLeft: "20px", paddingRight: "20px" }}
          >
            <div className="table-responsive overflow-y:hidden">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th scope="row">Stay cost</th>
                    <td>{selectedPatientVisit?.stay_cost}</td>
                    <th scope="row">Food cost</th>
                    <td>{selectedPatientVisit?.food_cost}</td>
                  </tr>
                  <tr>
                    <th scope="row">Wages Lost by parents</th>
                    <td>{selectedPatientVisit?.wages_lost_by_parents}</td>
                    <th scope="row">School absentism</th>
                    <td>{selectedPatientVisit?.school_absentism}</td>
                  </tr>
                  <tr>
                    <th scope="row">Travel cost</th>
                    <td>{selectedPatientVisit?.travel_cost}</td>
                    <th scope="row">Other direct/indirect cost</th>
                    <td>{selectedPatientVisit?.other_direct_indirect_cost}</td>
                  </tr>
                  <tr>
                    <th scope="row">HCP indirect cost</th>
                    <td>{selectedPatientVisit?.hcp_indirect_cost}</td>
                    <th scope="row">{""}</th>
                    <td>{""}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset className="border p-2 w-100">
        <legend className="float-none w-auto p-2 fs-5">Visit Remark</legend>
        <div className="newPatientFormOnly">
          <div
            style={{ width: "100%", paddingLeft: "20px", paddingRight: "20px" }}
          >
            <div className="table-responsive overflow-y:hidden">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th scope="row">Reason</th>
                  </tr>
                  <tr>
                    {" "}
                    <td scope="row">{selectedPatientVisit?.reason_others}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default VisitInfoBlock;

import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import axios from "axios";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const DexaTab = ({ emrRecordId, emrRecord, setEmrRecord }) => {
  const [formData, setFormData] = useState({
    dual_neck_femer_t_score: null,
    dual_neck_femer_z_score: null,
    dual_neck_femer_bmc: null,
    dual_neck_femer_bmd: null,
    ls_spine_t_score: null,
    ls_spine_z_score: null,
    ls_spine_bmc: null,
    ls_spine_bmd: null,
  });

  useEffect(() => {
    setFormData({ ...formData, ...emrRecord });
  }, [emrRecord]);

  const dexaKeys = Object.keys(formData);

  const handleChange = ({ currentTarget: input }) => {
    //alert([input.name]);
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const dexaLabels = [
    "T Score (Dual Neck Femer)",
    "Z Score (Dual Neck Femer)",
    "BMC (Dual Neck Femer)",
    "BMD (Dual Neck Femer)",
    "T Score (LS Spine)",
    "Z Score (LS Spine)",
    "BMC (LS Spine)",
    "BMD (LS Spine)",
  ];

  const measuringUnit = ["cms", "", "gm", "gm/cm2", "", "", "gm", "gm/cm2"];
  const updateEmr = () => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/emr/update/${emrRecordId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, dexa: true },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        localStorage.setItem(`${emrRecordId}-dexa`, JSON.stringify(formData));
        toast.success("Dexa sheet updated Successfully.");
        setEmrRecord(response.data.emr);
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error.response.data));
      });
  };
  return (
    <div className="newPatientFormOnly">
      {/* {JSON.stringify(biochemKeys)} */}
      {/* {JSON.stringify(formData)} */}
      <div className="w-100 mt-2 text-end pe-5">
        <Button
          variant="primary"
          size="large"
          onClick={updateEmr}
          className="btn btn-primary float-right"
        >
          Save
        </Button>
      </div>
      {dexaKeys.map((key, index) => (
        <InputField
          handleChange={handleChange}
          fieldname={key}
          formData={formData}
          index={index}
          measuringUnit={measuringUnit}
          endocrineLabels={dexaLabels}
        />
      ))}
    </div>
  );
};

export default DexaTab;

export const InputField = ({
  handleChange,
  fieldname,
  formData,
  index,
  measuringUnit,
  endocrineLabels,
}) => {
  function capitalize(s) {
    return s && s[0].toUpperCase() + s.slice(1);
  }

  return (
    <div className="newPatientItem">
      <label htmlFor={fieldname}>{endocrineLabels[index]}</label>
      <InputGroup className="mb-3">
        <Form.Control
          aria-label={fieldname}
          type="number"
          name={fieldname}
          onChange={handleChange}
          value={formData[fieldname]}
        />
        {measuringUnit[index] && (
          <InputGroup.Text id="basic-addon2">
            {measuringUnit[index]}
          </InputGroup.Text>
        )}
      </InputGroup>
    </div>
  );
};

import React from "react";
import { useLocation } from "react-router-dom";

import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import AppointmentListChild from "./AppointmentListChild";

const AppointmentList = () => {
  const { state } = useLocation();
  let patientRecordId = state.id;
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="AppointmentList" route="/appointments">
        <AppointmentListChild
          patientRecordId={patientRecordId}
          patientProfileData={state}
        />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default AppointmentList;

import React from "react";
import MainContentCard from "../Home/MainContentCard";

import ErrorBoundaryRoutes from "../../components/shared/ErrorBoundaryRoutes";
import GlobalSearchChild from "./GlobalSearchChild";

const GlobalSearch = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="GlobalSearchChild" route="/global/search">
        <GlobalSearchChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default GlobalSearch;

import "../Emr/UpdateEmr.css";
import React from "react";
import PrimaryEMRMenu from "./Emr/PrimaryEMRMenu";
import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Tabs, Tab } from "react-bootstrap";

import { useLocation } from "react-router-dom";

//Context import
import AuthContext from "../../../components/shared/Context";

import TreatmentAdviceTab from "./Emr/TreatmentAdviceTab";

const PatientEMRView = (patientEmr) => {
  return (
    <div className="patient-create">
      {/* {JSON.stringify(patientEmr)} */}
      <FormElement
        emrRecordId={patientEmr.selectedPatientEmr._id}
        emrRecord={patientEmr}
      />
    </div>
  );
};

const FormElement = ({ emrRecordId, emrRecord }) => {
  const [emrRecordLocal, setEmrRecordLocal] = useState();
  // console.log(emrRecordId);
  const { state } = useLocation();
  const { setPatientDiagnosis } = useContext(AuthContext);

  const selectedPatientProfile = { ...state };
  const [tabState, setTabState] = useState(true);
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (emrRecord) {
      setEmrRecordLocal(emrRecord.selectedPatientEmr);
    }
  }, [emrRecord]);

  useEffect(() => {
    try {
      // console.log(selectedPatientProfile.diagnoses.diagnosis_name);
      if (emrRecord.PatientDiagnosis.toLowerCase().includes("thalassemia")) {
        setTabState(false);
        setValue(10);
        setPatientDiagnosis(emrRecord.PatientDiagnosis);
      } else {
        setPatientDiagnosis(emrRecord.PatientDiagnosis);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ span: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <div className="mt-3">
      {/* <div className="submitButton"></div> */}
      {/* {JSON.stringify(formData)} */}
      {/* {JSON.stringify(selectedPatientProfile)} */}
      {/* {JSON.stringify(emrRecord.biochemistry)} */}
      <Tabs
        defaultActiveKey="medical"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="medical" title="Medical Details">
          <fieldset className="border p-2 w-100">
            <legend className="float-none w-auto p-2 fs-5">
              Medical Details
            </legend>
            <PrimaryEMRMenu
              emrRecordId={emrRecordId}
              handleChangeTabs={handleChangeTabs}
              value={value}
              TabPanel={TabPanel}
              setValue={setValue}
              tabState={tabState}
              emrRecord={emrRecord.selectedPatientEmr}
            />
          </fieldset>
        </Tab>
        <Tab eventKey="treatment" title="Treatment Advice">
          {emrRecordLocal && (
            <fieldset className="border p-2 w-100">
              <legend className="float-none w-auto p-2 fs-5">
                Treatment Advice/Details
              </legend>
              <TreatmentAdviceTab
                emrRecordId={emrRecordId}
                emrRecord={emrRecord}
                emrRecordLocal={emrRecordLocal}
                setEmrRecordLocal={setEmrRecordLocal}
              />
              <br />
            </fieldset>
          )}
        </Tab>
      </Tabs>
      <div></div>
    </div>
  );
};
export default PatientEMRView;

import React from "react";
import { useLocation } from "react-router-dom";

import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

import MainContentCard from "../../Home/MainContentCard";
import SearchPatientListChild from "./SearchPatientListChild";

const SearchPatientList = () => {
  const { state } = useLocation();
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes
        page="SearchPatientListChild"
        route="/search-patient"
      >
        <SearchPatientListChild searchText={state} />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default SearchPatientList;

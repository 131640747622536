import React from "react";
import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import ListSalesChild from "./ListSalesChild";

const ListSales = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="ListSales" route="/list-sales">
        <ListSalesChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default ListSales;

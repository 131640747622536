import React from "react";
const VisitInfoBlock = (PatientVisit) => {
  return (
    <div>
      {/* {JSON.stringify(PatientVisit)} */}
      <fieldset className="border p-2 w-100">
        <legend className="float-none w-auto p-2 fs-5">
          Visit Information
        </legend>
        <div className="newPatientFormOnly">
          <div
            style={{ width: "100%", paddingLeft: "20px", paddingRight: "20px" }}
          >
            <div className="table-responsive overflow-y:hidden">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th scope="row">Date of Visit</th>
                    <td>
                      {PatientVisit?.selectedPatientVisit?.date_of_visit.slice(
                        0,
                        10
                      )}
                    </td>
                    <th scope="row">Patient for</th>
                    <td>
                      {PatientVisit?.selectedPatientVisit?.consultation
                        ? "Consultation"
                        : "Hospitalization"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Ward/OPD Number</th>
                    <td>
                      {
                        PatientVisit?.selectedPatientVisit
                          ?.ward_clinic_opd_number
                      }
                    </td>
                    <th scope="row">Doctor</th>
                    <td>
                      Dr{" "}
                      {
                        PatientVisit?.selectedPatientVisit?.doctor_id
                          ?.first_name
                      }
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Visit / Consultation Reason</th>
                    <td>
                      {
                        JSON.parse(
                          PatientVisit?.selectedPatientVisit?.visit_reason
                        )?.label
                      }
                    </td>
                    <th scope="row">Travel Distance</th>
                    <td>
                      {PatientVisit?.selectedPatientVisit?.travel_distance}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Travel Mode</th>
                    <td>
                      {
                        JSON.parse(
                          PatientVisit?.selectedPatientVisit?.travel_mode
                        )?.label
                      }
                    </td>
                    <th scope="row">Payment Status</th>
                    <td>
                      {
                        JSON.parse(
                          PatientVisit?.selectedPatientVisit?.payment_status
                        )?.label
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset className="border p-2 w-100">
        <legend className="float-none w-auto p-2 fs-5">
          General Examination
        </legend>
        <div className="newPatientFormOnly">
          <div
            style={{ width: "100%", paddingLeft: "20px", paddingRight: "20px" }}
          >
            <div className="table-responsive overflow-y:hidden">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th scope="row">Weight (Current)</th>
                    <td>{PatientVisit?.selectedPatientVisit?.weight}</td>
                    <th scope="row">Height (Current)</th>
                    <td>{PatientVisit?.selectedPatientVisit?.height}</td>
                  </tr>
                  <tr>
                    <th scope="row">Pre Transfusion Hb</th>
                    <td>
                      {PatientVisit?.selectedPatientVisit?.pre_transfusion_hb}
                    </td>
                    <th scope="row">Doctor</th>
                    <td>{PatientVisit?.selectedPatientVisit?.weight}</td>
                  </tr>
                  {PatientVisit?.PatientDiagnosis.toLowerCase().includes(
                    "thalassemia"
                  ) && (
                    <>
                      <tr>
                        <th scope="row">Liver</th>
                        <td>{PatientVisit?.selectedPatientVisit?.liver}</td>
                        <th scope="row">Spleen</th>
                        <td>{PatientVisit?.selectedPatientVisit?.spleen}</td>
                      </tr>
                      <tr>
                        <th scope="row">Post Transfusion Hb</th>
                        <td>
                          {
                            PatientVisit?.selectedPatientVisit
                              ?.post_transfusion_hb
                          }
                        </td>
                        <th scope="row">{}</th>
                        <td>{}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset className="border p-2 w-100">
        <legend className="float-none w-auto p-2 fs-5">Visit Remark</legend>
        <div className="newPatientFormOnly">
          <div
            style={{ width: "100%", paddingLeft: "20px", paddingRight: "20px" }}
          >
            <div className="table-responsive overflow-y:hidden">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th scope="row">Reason</th>
                  </tr>
                  <tr>
                    {" "}
                    <td scope="row">
                      {PatientVisit.selectedPatientVisit.reason_others}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default VisitInfoBlock;

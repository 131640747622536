import React from "react";
import Card from "react-bootstrap/Card";

const SchedulingDetailsCard = ({ currentRecord }) => {
  return (
    <Card>
      <Card.Header>
        <i className="fas fa-align-justify" />
        <strong>Scheduling Details</strong>
      </Card.Header>
      <Card.Body>
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            <b>{"patient_revisit_days".replaceAll("_", " ").toUpperCase()}</b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {currentRecord?.scheduling?.patient_revisit_days}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            <b>
              {"next_scheduled_appointment_date"
                .replaceAll("_", " ")
                .toUpperCase()}
            </b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {currentRecord?.scheduling?.next_scheduled_appointment_date}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default SchedulingDetailsCard;

import "./App.css";
import { Toaster } from "react-hot-toast";
import moment from "moment";
import "moment-timezone";

//Context import
import { AuthProvider } from "./components/shared/Context";

import ProtectedRoutes from "./components/ProtectedRoutes/ProtectedRoutes";
import OptimizedRoutes from "./components/ProtectedRoutes/OptimizedRoutes";

function App() {
  return (
    <AuthProvider>
      <Toaster
        position="top-right"
        toastOptions={{
          style: {
            fontSize: "1 rem",
          },
        }}
      ></Toaster>
      {/* <ProtectedRoutes /> */}
      <OptimizedRoutes />
    </AuthProvider>
  );
}

export default App;

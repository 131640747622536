import { useState, useContext, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import toast from "react-hot-toast";
import VisitTab from "./VisitTab";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import "bootstrap/dist/css/bootstrap.min.css";
import BackButton from "../../../components/Buttons/BackButton";

//Context import
import AuthContext from "../../../components/shared/Context";

const UpdateVisitModal = ({ visit, patient, setVisit, setOpenVisitModal }) => {
  return (
    <div className="patient-create">
      {/* {JSON.stringify(patient)}
      <br />
      <br />
      {JSON.stringify(visit)} */}
      <FormElement
        patientRecordId={patient?._id}
        patientProfileData={patient}
        visit={visit}
        setVisit={setVisit}
        setOpenVisitModal={setOpenVisitModal}
      />
    </div>
  );
};

const FormElement = ({
  patientRecordId,
  patientProfileData,
  visit,
  setVisit,
  setOpenVisitModal,
}) => {
  const { loggedInUserDetails, selectedPatientProfile } =
    useContext(AuthContext);
  moment.tz.setDefault("Asia/Kolkata");
  //Error Logging Service
  const [{ errorLogApi }] = useAtom(gbState);

  // const [patientProfileStatus, setPatientProfileStatus] = useState(false);
  // const [patientProfile, setPatientProfile] = useState({});
  const [latestWHRecord, setLatestWHRecord] = useState();

  const visitTypeInput = useRef();
  const doctorInput = useRef();
  const visitDateInput = useRef();
  const visitReasonInput = useRef();
  const visitWeightInput = useRef();
  const visitHeightInput = useRef();
  const visitTypeInput1 = useRef();

  let navigate = useNavigate();

  //const [patientId, setPatientId] = useState("");
  const [doctors, setDoctors] = useState([]);
  let defaultDate = new Date();
  const [date, setDate] = useState(defaultDate);
  const [patientHomeTC, setPatientHomeTC] = useState("");
  defaultDate.setDate(defaultDate.getDate());

  const today = new Date();

  const [formData, setFormData] = useState({
    ...visit,
    patientId: patientRecordId,
    date_of_visit: visit?.date_of_visit,
    hospitalization: visit?.hospitalization,
    consultation: visit?.consultation,
    ward_clinic_opd_number: visit?.ward_clinic_opd_number,
    doctor_id: visit?.doctor_id?._id,
    visit_reason: visit?.visit_reason,
    visit_type: visit?.visit_type,
    reason_others: visit?.reason_others,
    weight: patientProfileData?.weight,
    height: patientProfileData?.height,
    liver: visit?.liver,
    spleen: visit?.spleen,
    stay_cost: visit?.stay_cost,
    food_cost: visit?.food_cost,
    wages_lost_by_parents: visit?.wages_lost_by_parents,
    school_absentism: visit?.school_absentism,
    travel_cost: visit?.travel_cost,
    other_direct_indirect_cost: visit?.other_direct_indirect_cost,
    hcp_indirect_cost: visit?.hcp_indirect_cost,
    hemoglobin_initial_hb: visit?.hemoglobin_initial_hb,
    hemoglobin_final_hb: visit?.hemoglobin_final_hb,
    pre_transfusion_hb: visit?.pre_transfusion_hb,
    post_transfusion_hb: visit?.post_transfusion_hb,
    transfusion_regimen: visit?.transfusion_regimen,
    external_visit: visit?.external_visit,
    travel_mode: visit?.travel_mode,
    payment_status: visit?.payment_status,
    transportation_support: visit?.transportation_support,
    travel_distance: visit?.travel_distance,
    treatment_center_id: loggedInUserDetails.tc_id,
    tc_state: loggedInUserDetails.state,
    createdBy: loggedInUserDetails._id,
  });

  useEffect(() => {
    const config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/user/doc/${loggedInUserDetails.tc_id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data.doctors));
        const doctorsData = response.data.doctors;
        setDoctors(doctorsData);
        // console.log(doctorsData);
      })
      .catch(function (error) {
        //   console.log(error.message);
      });
  }, []);

  useEffect(() => {
    const getLatestWeightHeight = () => {
      const userToken = localStorage.getItem("token");
      // console.log(patientRecordId);

      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/visits/latest-weight/${patientRecordId}`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
        .then((response) => {
          if (response?.data?.latestRecord) {
            setLatestWHRecord(response?.data?.latestRecord);
          }
        })
        .catch((error) => {
          errorLogApi(error, "GetLatestWeight", "visits/latest-weight");
        });
    };

    getLatestWeightHeight();
  }, []);

  useEffect(() => {
    setFormData({
      ...formData,
      weight: latestWHRecord?.weight,
      height: latestWHRecord?.height,
    });
  }, [latestWHRecord]);

  const handleVisitRegistration = (e) => {
    e.preventDefault();
    //alert(JSON.stringify({ ...formData }));

    if (
      loggedInUserDetails.role === "ST_ADM_ACCESS" ||
      loggedInUserDetails.role === "FULL_ACCESS"
    ) {
      toast.error(
        "State Admin cannot create the Visit record, Kindly login as Doctor/Nurse/Deo"
      );
      return;
    }

    if (formData.date_of_visit === null) {
      visitDateInput.current.focus();
      toast.error("Please select visit Date");
      return;
    }

    if (formData.consultation === null) {
      visitTypeInput.current.focus();
      toast.error("Please select visit type");
      return;
    }

    if (formData.doctor_id === "") {
      doctorInput.current.focus();
      toast.error("Please select Doctor");
      return;
    }

    //visit_reason
    if (formData.visit_reason === "") {
      visitReasonInput.current.focus();
      toast.error("Please select Visit Reason");
      return;
    }

    //weight
    if (formData.weight === null) {
      visitWeightInput.current.focus();
      toast.error("Please Enter Weight");
      return;
    }
    //height
    if (formData.height === null) {
      visitHeightInput.current.focus();
      toast.error("Please enter Height");
      return;
    }

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/visits/update/${visit?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        toast.success("Visit Update Successfully.");
        setVisit(response?.data?.visit);
        setOpenVisitModal(false);
        // console.log(response?.data?.visit);
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data.message));
        errorLogApi(error, "CreateVisitChild", "/create-visit/");
        //console.log(error);
      });
  };

  const checkNonNegative = [
    "stay_cost",
    "food_cost",
    "wages_lost_by_parents",
    "school_absentism",
    "travel_cost",
    "other_direct_indirect_cost",
    "hcp_indirect_cost",
    "weight",
    "height",
    "travel_distance",
  ];

  useEffect(() => {
    // Iterate over each field in checkNonNegative
    checkNonNegative.forEach((field) => {
      // Check if the field's value is less than 0
      if (formData[field] < 0) {
        // Update the specific field to null
        setFormData((prevFormData) => ({
          ...prevFormData,
          [field]: null,
        }));
      }
    });
  }, [formData]); // Dependency array includes formData to trigger the effect when formData changes

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Check if the field is in the checkNonNegative array and the value is non-negative
    if (
      checkNonNegative.includes(name) &&
      (value === "" || parseInt(value, 10) >= 0)
    ) {
      setFormData({
        ...formData,
        [name]: value,
        patientId: patientRecordId,
      });
    } else if (!checkNonNegative.includes(name)) {
      // Update the state normally for fields not in the checkNonNegative array
      setFormData({
        ...formData,
        [name]: value,
        patientId: patientRecordId,
      });
    }
    // If the value is negative, you can choose to not update or handle it differently
  };

  const handleChange01 = ({ currentTarget: input }) => {
    //setPatientId(patientRecordId);
    //console.log("HandleChange");
    if (checkNonNegative.includes(input.name) && input.value < 0) {
      toast.error("Please enter a non negative number!");
      return;
    }

    setFormData({
      ...formData,
      [input.name]: input.value,
      patientId: patientRecordId,
    });
  };

  const dropDownChange = (selected, dropdown) => {
    // console.log("this is selected", JSON.stringify(dropdown.name));
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
      patientId: patientRecordId,
    });
  };

  return (
    <form
      className="newPatientForm1"
      onSubmit={(e) => handleVisitRegistration(e)}
    >
      {/* <h2>Visit</h2> */}
      <div className="submitButton">
        <div className="patientUpdateButton">
          <Button variant="success" size="medium" type="submit">
            Update Visit
          </Button>
        </div>
      </div>
      {/* <hr /> */}

      {patientProfileData ? (
        <VisitTab
          visitTypeInput={visitTypeInput}
          visitDateInput={visitDateInput}
          visitReasonInput={visitReasonInput}
          visitWeightInput={visitWeightInput}
          visitHeightInput={visitHeightInput}
          visitTypeInput1={visitTypeInput1}
          doctorInput={doctorInput}
          handleChange={handleChange}
          dropDownChange={dropDownChange}
          loggedInUserDetails={loggedInUserDetails}
          formData={formData}
          setFormData={setFormData}
          doctors={doctors}
          patientHomeTC={patientHomeTC}
          selectedPatientProfile={patientProfileData}
        />
      ) : (
        <div className="newPatientForm"> Loading ...</div>
      )}
    </form>
  );
};
export default UpdateVisitModal;

import React from "react";
import { useLocation } from "react-router-dom";
import MainContentCard from "../../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../../components/shared/ErrorBoundaryRoutes";
import CreateDoseForPatientChild from "./CreateDoseForPatientChild";

const CreateDoseForPatient = () => {
  const { state } = useLocation();
  let visit = state;
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="CreateDoseForPatient" route="/create-dose">
        <CreateDoseForPatientChild
          visit={state.visit}
          patientRecord={state.patient}
        />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default CreateDoseForPatient;

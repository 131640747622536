import React from "react";
import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import AddProductChild from "./AddProductChild";

const AddProduct = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="AddProduct" route="/addproduct">
        <AddProductChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default AddProduct;

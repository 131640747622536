import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import axios from "axios";

import Form from "react-bootstrap/Form";

const ChronicComplicationThalassemiaTab = ({
  emrRecordId,
  emrRecord,
  setEmrRecord,
}) => {
  const [formData, setFormData] = useState({
    cardiac: null,
    endocrine: null,
    hepatic: null,
    pituatary: null,
    edntals: null,
    thyroid: null,
    garods: null,
    parathyroid: null,
    pancreas: null,
  });

  useEffect(() => {
    setFormData({ ...formData, ...emrRecord });
  }, [emrRecord]);

  const chronicComplicationKeys = Object.keys(formData);

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.checked,
    });
  };

  const chronicComplicationLabels = [
    "Caridiac",
    "Endocrine",
    "Hepatic",
    "Pituatary",
    "Edntals",
    "Thyroid",
    "Garods",
    "Parathyroid",
    "Pancreas",
  ];

  const updateEmr = () => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/emr/update/${emrRecordId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, chronic_complication_thalassemia: true },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        localStorage.setItem(
          `${emrRecordId}-chroniccomplicationthalassemia`,
          JSON.stringify(formData)
        );
        toast.success("Chronic Complication sheet updated Successfully.");
        setEmrRecord(response.data.emr);
      })
      .catch(function (error) {
        // console.log(error);
        toast.error(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error.response.data));
      });
  };

  return (
    <div className="newPatientFormOnly">
      {/* {JSON.stringify(biochemKeys)} */}
      {/* {JSON.stringify(formData)} */}
      <div className="w-100 mt-2 text-end pe-5">
        <Button
          variant="primary"
          size="large"
          onClick={updateEmr}
          className="btn btn-primary float-right"
        >
          Save
        </Button>
      </div>
      {chronicComplicationKeys.map((key, index) => (
        <InputField
          handleChange={handleChange}
          fieldname={key}
          formData={formData}
          index={index}
          endocrineLabels={chronicComplicationLabels}
        />
      ))}
    </div>
  );
};

export default ChronicComplicationThalassemiaTab;

const InputField = ({
  handleChange,
  fieldname,
  formData,
  index,
  endocrineLabels,
}) => {
  function capitalize(s) {
    return s && s[0].toUpperCase() + s.slice(1);
  }

  return (
    <div className="newPatientItem">
      <Form.Check
        type="checkbox"
        id={fieldname}
        label={capitalize(endocrineLabels[index])}
        name={fieldname}
        onChange={handleChange}
        checked={!!formData[fieldname]}
      />
    </div>
  );
};

import React, { useRef } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import indianCities from "../../../components/Data/IndianCities";
import Select from "react-select";
import { useLocation } from "react-router-dom";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

//Context import
import AuthContext from "../../../components/shared/Context";

const UpdateTCChild = () => {
  const { checkLoggedInStatus, loggedInUserDetails } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  const districtRef = useRef();
  const stateInputRef = useRef();

  //Error Logging Service & Santize Input
  const [{ errorLogApi, sanitizeInput }] = useAtom(gbState);

  //To check if the user is logged in
  useEffect(() => {
    checkLoggedInStatus();
  }, []);

  const [stateValue, setStateValue] = useState(null);
  const [city, setCity] = useState(null);
  const [formData, setFormData] = useState({
    ...state.tcenters,
  });

  //Created a set for indian cities
  const statesSet = new Set(
    indianCities.map((city, index) => {
      return city.state;
    })
  );

  //Converted Set to Array of States
  const states = [...statesSet];

  const handleTCRegistration = (e) => {
    e.preventDefault();
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/tc/update/${state._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, tcenters: true },
    };
    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        toast.success("TC Updated Successfully.");
        navigate("/tcenters");
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data.message));
        console.log(error);
      });
  };
  const stateOptions = states.map((state) => ({
    value: state,
    label: state,
  }));

  useEffect(() => {
    setStateValue(stateOptions.find((s) => s.label === formData.state).value);
  }, [formData]);

  const districtOptions = indianCities
    .filter((state) => {
      return state.state === stateValue;
    })
    .map((state) => ({ value: state.city, label: state.city }));

  const handleChange = ({ currentTarget: input }) => {
    const field_values = [
      "password",
      "email",
      "primary_email",
      "secondary_email",
    ];
    // Check if the input needs to be sanitized
    if (!field_values.includes(input.name)) {
      // Sanitize input and update state if valid
      if (sanitizeInput(input.value, input.name)) {
        setFormData({
          ...formData,
          [input.name]: input.value,
        });
      }
    } else {
      // Directly update state for fields that don't need sanitization
      setFormData({
        ...formData,
        [input.name]: input.value,
      });
    }
  };

  const dropDownChange = (selected, dropdown) => {
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const tcTypeOptions = [
    { value: "government", label: "Government" },
    { value: "private", label: "Private" },
  ];

  return (
    <div className="patient-list-tc">
      <div>
        <h4>Edit Treatment Center</h4>
      </div>
      <form onSubmit={(e) => handleTCRegistration(e)}>
        <div className="patientUpdateButton">
          <Button variant="primary" size="medium" type="submit">
            Update TC
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="secondary"
            size="medium"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </div>
        <div className="newTCFormUsers">
          {/* {JSON.stringify(state._id)} */}
          <div className="newPatientItem w-25">
            <label htmlFor="first_name">Name of Treatment Center</label>
            <input
              type="text"
              name="name_tc"
              value={formData?.name_tc}
              onChange={handleChange}
              required
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="institution">Institution</label>
            <input
              type="text"
              name="institution"
              value={formData?.institution}
              onChange={handleChange}
              required
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="hdcc_no">HDCC No</label>
            <input
              type="text"
              name="hdcc_no"
              value={formData?.hdcc_no}
              onChange={handleChange}
              required
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="tc_type">TC Type</label>
            <Select
              name="tc_type"
              onChange={(selected, dropdown) => {
                setFormData({
                  ...formData,
                  tc_type: selected.label.toLowerCase(),
                });
              }}
              className="newPatientSelect"
              options={tcTypeOptions}
              value={
                formData?.tc_type &&
                tcTypeOptions.find((t) => t.value === formData?.tc_type)
              }
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="primary_email">Email (Primary)</label>
            <input
              type="email"
              name="primary_email"
              value={formData?.primary_email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="secondary_email">Email (Secondary)</label>
            <input
              type="email"
              name="secondary_email"
              value={formData?.secondary_email}
              onChange={handleChange}
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="phone">Phone </label>
            <input
              type="text"
              name="phone"
              value={formData?.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="fax">Fax </label>
            <input
              type="text"
              name="fax"
              value={formData.fax}
              onChange={handleChange}
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="website">Website </label>
            <input
              type="text"
              name="website"
              placeholder="https://"
              value={formData.website}
              onChange={handleChange}
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="country">Country </label>
            <input type="text" name="country" value="India" disabled />
          </div>
          {!(loggedInUserDetails.role === "ST_ADM_ACCESS") && (
            <div className="newPatientItem w-25">
              <label>State</label>
              <Select
                name="state"
                onChange={(selected, dropdown) => {
                  setStateValue(selected.value);
                  setCity(null);
                  dropDownChange(selected, dropdown);
                }}
                className="newPatientSelect"
                options={stateOptions}
                ref={stateInputRef}
                value={
                  formData.state &&
                  stateOptions.find((rec) => {
                    return rec.value === formData.state;
                  })
                }
              />
            </div>
          )}
          {loggedInUserDetails.role === "ST_ADM_ACCESS" && (
            <div className="newPatientItem w-25">
              <label htmlFor="hdcc_no">State</label>
              <input type="text" name="state" value={formData.state} disabled />
            </div>
          )}
          <div className="newPatientItem w-25">
            <label>District/City</label>
            <Select
              name="district"
              onChange={dropDownChange}
              ref={districtRef}
              className="newPatientSelect"
              options={districtOptions}
              isClearable={true}
              value={
                formData.district &&
                districtOptions.find((d) => {
                  return d.value === formData.district;
                })
              }
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="pincode">Pincode </label>
            <input
              type="text"
              name="pincode"
              value={formData.pincode}
              onChange={handleChange}
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="address_line1">Address line 1 </label>
            <input
              type="text"
              name="address_line1"
              value={formData.address_line1}
              onChange={handleChange}
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="address_line2">Address line 2 </label>
            <input
              type="text"
              name="address_line2"
              value={formData.address_line2}
              onChange={handleChange}
            />
          </div>
          <div className="newPatientItem w-25">
            <label htmlFor="landmark">Landmark </label>
            <input
              type="text"
              name="landmark"
              value={formData.landmark}
              onChange={handleChange}
            />
          </div>
        </div>
        {/* {JSON.stringify(state.tcenters)} */}
      </form>
    </div>
  );
};

export default UpdateTCChild;

import React from "react";
import MainContentCard from "../../Home/MainContentCard";
import AddKnowledgebaseChild from "./AddKnowledgebaseChild";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";

const AddKnowledgebase = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes page="CreatePatientHealthRecord" route="/addkb">
        <AddKnowledgebaseChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default AddKnowledgebase;

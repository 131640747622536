import { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import toast from "react-hot-toast";
import { Tabs, Tab, Alert } from "react-bootstrap";
import Select from "react-select";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import moment from "moment";

import { bloodGroupData } from "../../../components/Data/ChartData";

//Global State import
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

//Context import
import AuthContext from "../../../components/shared/Context";

import "bootstrap/dist/css/bootstrap.min.css";

const PreRegistrationUpdateChild = ({ patientRecord }) => {
  let navigate = useNavigate();
  const { checkLoggedInStatus, loggedInUserDetails, selectedDiagnosis } =
    useContext(AuthContext);

  //Error Logging Service
  const [{ errorLogApi, sanitizeInput, encryptData, user }] = useAtom(gbState);
  const [tabState, setTabState] = useState(0);
  const tabSequence = ["general"];
  const [formData, setFormData] = useState({
    ...patientRecord,
  });

  const handleSelect = (key) => {
    if (key === "general") {
      setTabState(0);
    }
  };

  const handleChange = ({ currentTarget: input }) => {
    //console.log(input.name, input.value);
    if (sanitizeInput(input.value, input.name)) {
      setFormData({
        ...formData,
        [input.name]: input.value,
      });
    }
    if (input.name === "email") {
      setFormData({
        ...formData,
        [input.name]: input.value,
      });
    }
  };

  const handlePreRegistration = () => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/pre-register/edit`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        toast.success("Patient Registered Successfully.");
        navigate("/pre/register/list");
      })
      .catch(function (error) {
        errorLogApi(error, "PreRegistrationChild", "/patient-add");
        toast.error(JSON.stringify(error.response.data.message));
        if (error.response.data.message.toLowerCase().includes("invalid")) {
          navigate("/login", { replace: true });
        }
      });
  };

  return (
    <div className="mb-25">
      <h1 className="allPatients">Pre Register Patient</h1>
      <div className="patientUpdateButton mb-3">
        <button
          className="btn btn-primary"
          onClick={() => {
            handlePreRegistration();
          }}
        >
          <i className="mdi mdi-content-save" /> Update
        </button>{" "}
        &nbsp;
        <button className="btn btn-secondary" onClick={() => navigate(-1)}>
          Back
        </button>
      </div>
      <Tabs
        defaultActiveKey={tabSequence[tabState]}
        activeKey={tabSequence[tabState]}
        id="uncontrolled-tab-example"
        className="mb-3"
        onSelect={handleSelect}
      >
        <Tab eventKey="general" title="General">
          <GeneralInformationTab
            handleChange={handleChange}
            formData={formData}
            setFormData={setFormData}
          />
        </Tab>
      </Tabs>
      {/* {JSON.stringify(formData)} */}
    </div>
  );
};
const GeneralInformationTab = ({ handleChange, formData, setFormData }) => {
  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Others", label: "Others" },
  ];

  const bloodgroupOptions = bloodGroupData.map((data) => ({
    value: data.bloodGroupType,
    label: data.bloodGroupType,
  }));

  return (
    <div className="newPatientFormOnly">
      <div className="newPatientItem">
        <label htmlFor="patient_name">Patient Name</label>
        <input
          type="text"
          name="patient_name"
          placeholder="Patient Name"
          value={formData.patient_name && formData.patient_name}
          onChange={handleChange}
          onClick={handleChange}
          // required
          // ref={patientNameInput}
        />
      </div>

      <div className="newPatientItem">
        <label htmlFor="date_of_birth">Date of Birth</label>
        <DatePicker
          className="newPatientSelect datePicker"
          style={{ paddingLeft: "10px" }}
          name="date_of_birth"
          onChange={(date, dateString) => {
            setFormData({
              ...formData,
              date_of_birth: dateString,
            });
          }}
          disabledDate={(current) => {
            let customDate = moment().format("YYYY-MM-DD");
            return current && current > moment(customDate, "YYYY-MM-DD");
          }}
          showToday={true}
          value={
            formData?.date_of_birth &&
            moment(formData?.date_of_birth, "YYYY-MM-DD")
          }
          //ref={dobInput}
        />
      </div>
      <div className="newPatientItem">
        <label>Gender</label>
        <Select
          name="gender"
          onChange={(selected, dropdown) => {
            setFormData({
              ...formData,
              gender: JSON.stringify(selected),
            });
          }}
          className="newPatientSelect"
          options={genderOptions}
          value={formData?.gender && JSON.parse(formData?.gender)}
          //ref={genderInput}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
        />
      </div>

      <div className="newPatientItem">
        <label htmlFor="mothers_name">Mother's Name</label>
        <input
          type="text"
          name="mothers_name"
          onChange={handleChange}
          value={formData?.mothers_name && formData?.mothers_name}
          // ref={primaryMobileInput}
        />
      </div>

      <div className="newPatientItem">
        <label htmlFor="primary_mobile">Mobile Primary</label>
        <input
          type="text"
          name="primary_mobile"
          placeholder="+91 1234567890"
          onChange={handleChange}
          value={formData?.primary_mobile && formData?.primary_mobile}
          // ref={primaryMobileInput}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="secondary_mobile">Mobile Secondary</label>
        <input
          type="text"
          name="secondary_mobile"
          placeholder="+91 1234567890"
          onChange={handleChange}
          value={formData?.secondary_mobile && formData?.secondary_mobile}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="whatsapp_number">WhatsApp</label>
        <input
          type="text"
          name="whatsapp_number"
          placeholder="+91 1234567890"
          onChange={handleChange}
          value={formData?.whatsapp_number && formData?.whatsapp_number}
        />
      </div>
      <div className="newPatientItem">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          name="email"
          placeholder="patient@email.com"
          onChange={handleChange}
          value={formData?.email && formData?.email}
        />
      </div>
    </div>
  );
};
export default PreRegistrationUpdateChild;

import React, { useRef } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import html2canvas from "html2canvas";

const PerPatientDatewiseChart = ({ data }) => {
  const chartRef = useRef(null);
  const colorPalette = [
    "#8884d8",
    "#82ca9d",
    "#ffc658",
    "#ff7300",
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#a4de6c",
    "#d0ed57",
    "#83a6ed",
    "#8dd1e1",
  ];

  // Function to format date (assuming input is in ISO format)
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };

  // Aggregate data by date and product
  const aggregatedData = data.reduce((acc, item) => {
    const date = formatDate(item.visit_date);
    if (!acc[date]) {
      acc[date] = {};
    }
    if (!acc[date][item.product_name]) {
      acc[date][item.product_name] = 0;
    }
    acc[date][item.product_name] += parseFloat(item.quantity);
    return acc;
  }, {});

  // Convert aggregated data to array format for Recharts and sort by date
  const chartData = Object.keys(aggregatedData)
    .sort((a, b) => new Date(a) - new Date(b))
    .map((date) => ({
      date,
      ...aggregatedData[date],
    }));

  // Get unique product names
  const products = Array.from(new Set(data.map((item) => item.product_name)));

  const handleDownload = () => {
    if (chartRef.current) {
      html2canvas(chartRef.current).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = imgData;
        link.download = "PatientDateWise.png";
        link.click();
      });
    }
  };

  return (
    <div>
      <div ref={chartRef}>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              angle={-45}
              textAnchor="end"
              height={70}
              tickFormatter={(tick) => tick}
            />
            <YAxis />
            <Tooltip labelFormatter={(label) => `Date: ${label}`} />
            <Legend />
            {products.map((product, index) => (
              <Line
                key={product}
                type="monotone"
                dataKey={product}
                stroke={colorPalette[index % colorPalette.length]}
                activeDot={{ r: 8 }}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {products.map((product, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                margin: "5px 10px",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: colorPalette[index % colorPalette.length],
                  marginRight: "5px",
                }}
              ></div>
              <span>{product}</span>
            </div>
          ))}
        </div>
      </div>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button
          onClick={handleDownload}
          style={{
            padding: "10px 20px",
            backgroundColor: "#4CAF50",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Download Chart
        </button>
      </div>
    </div>
  );
};

export default PerPatientDatewiseChart;

import React from "react";
import MainContentCard from "../../Home/MainContentCard";
import ErrorBoundaryRoutes from "../../../components/shared/ErrorBoundaryRoutes";
import ListRecommendedDrugsChild from "./ListRecommendedDrugsChild";

const ListRecommendedDrugs = () => {
  return (
    <MainContentCard>
      <ErrorBoundaryRoutes
        page="CreatePatientHealthRecord"
        route="/addhealthrecord"
      >
        <ListRecommendedDrugsChild />
      </ErrorBoundaryRoutes>
    </MainContentCard>
  );
};

export default ListRecommendedDrugs;

import {React,useState,useEffect} from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import ShowPieChart from "../../../components/Chart/ShowPieChart";
import ShowBarChart from "../../../components/Chart/ShowBarChart";
import ShowLineChart from "../../../components/Chart/ShowLineChart";
const ReasonTab = ({ currentRecord }) => {
    const [dataGraph, setDataGraph] = useState([]);
    let graphData={};
    for(let i of currentRecord){
      graphData[i.reason]=graphData[i.reason] ? graphData[i.reason]+1 :1
    }
     const keys = Object.keys(graphData)
    useEffect(() => {
       let dataPoints=[];
        for (var i = 0; i < keys.length; i++) {
            dataPoints.push({
                name: keys[i],
                value: graphData[keys[i]]
            });
        }
        setDataGraph(dataPoints);
    },[]);

    const [graphDisplay, setGraphDisplay] = useState('pie');
    const showGraphView=(graphType)=>{
      setGraphDisplay(graphType);
    }  
    return (
        <>
            <DropdownButton id="dropdown-basic-button" style={{marginLeft:'600px'}} title={graphDisplay.toUpperCase()+' CHART'} size="sm" variant="secondary">
                <Dropdown.Item  onClick={()=>showGraphView('pie')}>Pie Chart</Dropdown.Item>
                <Dropdown.Item  onClick={()=>showGraphView('bar')}>Bar Graph</Dropdown.Item>
                <Dropdown.Item  onClick={()=>showGraphView('line')}>Line Graph</Dropdown.Item>
            </DropdownButton>
            {(graphDisplay ==='pie') &&
                (<ShowPieChart  data={dataGraph}/>)}
            {(graphDisplay ==='bar') &&
                (<ShowBarChart  data={dataGraph}/>)}
            {(graphDisplay ==='line') &&
                (<ShowLineChart  data={dataGraph}/>)}    
        </>   
  )
};

export default ReasonTab;

import React from "react";
import { useEffect, useState, useContext } from "react";
import { Button, Alert } from "react-bootstrap";
//import "./ProductsList.css";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

//Context import
import AuthContext from "../../../components/shared/Context";

const InvestigationTab = ({ emrRecordId, emrRecord, setEmrRecord }) => {
  const { patientDiagnosis } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    factor_viii: null,
    factor_ix: null,
    hemophilia_severity: null,
    bleeding_time_in_minutes: null,
    clotting_time_in_minutes: null,
    prothombin_time_in_seconds: null,
    inr: null,
    aptt_pttk_sec: null,
    mixing_studies_result: null,
    inhibitors: null,
    haemoglobin: null,
    mcv: null,
    platelet_count: null,
    platelet_morphology: null,
    ferritin: null,
    bilirubin: null,
    alt: null,
    ast: null,
    alp: null,
    protein: null,
    albumin: null,
    hepatitis_b_screen: null,
    hepatitis_c_screen: null,
    hiv_screen: null,
    hepatitis_a_vaccination: null,
    hepatitis_c_vaccination: null,
    anti_hbs_titre: null,
    others: null,
  });

  const screenOptions = [
    { value: 1, label: "Positive" },
    { value: 0, label: "Negative" },
  ];

  useEffect(() => {
    if (emrRecord) {
      localStorage.setItem(
        `${emrRecordId}-investigation`,
        JSON.stringify(emrRecord)
      );
    }
    let invSheet = localStorage.getItem(`${emrRecordId}-investigation`);
    setFormData({ ...formData, ...JSON.parse(invSheet) });
  }, [emrRecord]);

  const updateEmr = (e) => {
    e.preventDefault();

    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/emr/update/${emrRecordId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData, investigation_sheet: true },
    };

    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        localStorage.setItem(
          `${emrRecordId}-investigation`,
          JSON.stringify(formData)
        );
        toast.success("Investigation sheet updated Successfully.");
        setEmrRecord(response.data.emr);
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error.response.data));
      });
  };

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.checked,
    });
  };
  const handleChangeValue = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const dropDownChange = (selected, dropdown) => {
    console.log("this is selected", JSON.stringify(dropdown.name));
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  return (
    <form className="d-flex flex-sm-wrap" onSubmit={(e) => updateEmr(e)}>
      <div className="table-responsive w-100" style={{ marginRight: "40px" }}>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col" colspan="2">
                Investigations
              </th>
              <th scope="col" colspan="2" style={{ textAlign: "right" }}>
                <Button variant="primary" size="large" type="submit">
                  Save
                </Button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {patientDiagnosis?.toLowerCase().includes("hemophilia a") ? (
                <>
                  <td>Factor VIII (%)</td>
                  <td>
                    <InputGroup className="mb-0 w-50">
                      <Form.Control
                        aria-label="factor_viii"
                        type="number"
                        name="factor_viii"
                        onChange={handleChangeValue}
                        value={formData.factor_viii}
                      />
                      <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                    </InputGroup>
                  </td>
                </>
              ) : (
                <>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </>
              )}

              {patientDiagnosis?.toLowerCase().includes("hemophilia b") ? (
                <>
                  <td>Factor IX (%)</td>
                  <td>
                    <InputGroup className="mb-0 w-50">
                      <Form.Control
                        aria-label="factor_ix"
                        type="number"
                        name="factor_ix"
                        onChange={handleChangeValue}
                        value={formData.factor_ix}
                      />
                      <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                    </InputGroup>
                  </td>
                </>
              ) : (
                <>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </>
              )}
            </tr>
            <tr>
              {patientDiagnosis?.toLowerCase().includes("hemophilia") ? (
                <>
                  <td>Severity of Hemophilia</td>
                  <td>
                    <InputGroup className="mb-0 w-50">
                      <Form.Control
                        aria-label="hemophilia_severity"
                        type="number"
                        name="hemophilia_severity"
                        onChange={handleChangeValue}
                        value={formData.hemophilia_severity}
                      />
                      <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                    </InputGroup>
                  </td>
                </>
              ) : (
                <>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </>
              )}
              <td>Bleeding Time(in mins)</td>
              <td>
                <InputGroup className="mb-0 w-50">
                  <Form.Control
                    aria-label="bleeding_time_in_minutes"
                    type="number"
                    name="bleeding_time_in_minutes"
                    onChange={handleChangeValue}
                    value={formData.bleeding_time_in_minutes}
                  />
                  <InputGroup.Text id="basic-addon2">mins</InputGroup.Text>
                </InputGroup>
              </td>
            </tr>
            <tr>
              <td>Clotting Time(in mins)</td>
              <td>
                <InputGroup className="mb-0 w-50">
                  <Form.Control
                    aria-label="clotting_time_in_minutes"
                    type="number"
                    name="clotting_time_in_minutes"
                    onChange={handleChangeValue}
                    value={formData.clotting_time_in_minutes}
                  />
                  <InputGroup.Text id="basic-addon2">mins</InputGroup.Text>
                </InputGroup>
              </td>
              <td>Prothombin Time (secs)</td>
              <td>
                <InputGroup className="mb-0 w-50">
                  <Form.Control
                    aria-label="prothombin_time_in_seconds"
                    type="number"
                    name="prothombin_time_in_seconds"
                    onChange={handleChangeValue}
                    value={formData.prothombin_time_in_seconds}
                  />
                  <InputGroup.Text id="basic-addon2">secs</InputGroup.Text>
                </InputGroup>
              </td>
            </tr>
            <tr>
              <td>INR</td>
              <td>
                <InputGroup className="mb-0 w-50">
                  <Form.Control
                    aria-label="inr"
                    type="number"
                    name="inr"
                    onChange={handleChangeValue}
                    value={formData.inr}
                  />
                  {/* <InputGroup.Text id="basic-addon2">MNPT</InputGroup.Text> */}
                </InputGroup>
              </td>
              <td>aPTT / PTTK (sec)</td>
              <td>
                <InputGroup className="mb-0 w-50">
                  <Form.Control
                    aria-label="aptt_pttk_sec"
                    type="number"
                    name="aptt_pttk_sec"
                    onChange={handleChangeValue}
                    value={formData.aptt_pttk_sec}
                  />
                  <InputGroup.Text id="basic-addon2">secs</InputGroup.Text>
                </InputGroup>
              </td>
            </tr>
            <tr>
              <td>Inhibitors</td>
              <td>
                <InputGroup className="mb-0 w-50">
                  <Form.Control
                    aria-label="inhibitors"
                    type="number"
                    name="inhibitors"
                    onChange={handleChangeValue}
                    value={formData.inhibitors}
                  />
                  <InputGroup.Text id="basic-addon2">BU</InputGroup.Text>
                </InputGroup>
              </td>
              <td>Haemoglobin</td>
              <td>
                <InputGroup className="mb-0 w-50">
                  <Form.Control
                    aria-label="haemoglobin"
                    type="number"
                    name="haemoglobin"
                    onChange={handleChangeValue}
                    value={formData.haemoglobin}
                  />
                  <InputGroup.Text id="basic-addon2">g/dl</InputGroup.Text>
                </InputGroup>
              </td>
            </tr>
            <tr>
              <td>MCV</td>
              <td>
                <InputGroup className="mb-0 w-50">
                  <Form.Control
                    aria-label="mcv"
                    type="number"
                    name="mcv"
                    onChange={handleChangeValue}
                    value={formData.mcv}
                  />
                  <InputGroup.Text id="basic-addon2">fL</InputGroup.Text>
                </InputGroup>
              </td>
              <td>Platelet Count</td>
              <td>
                <InputGroup className="mb-0 w-50">
                  <Form.Control
                    aria-label="platelet_count"
                    type="number"
                    name="platelet_count"
                    onChange={handleChangeValue}
                    value={formData.platelet_count}
                  />
                  <InputGroup.Text id="basic-addon2">cu/mm</InputGroup.Text>
                </InputGroup>
              </td>
            </tr>
            <tr>
              <td>Platelet Morphology</td>
              <td>
                <InputGroup className="mb-0 w-50">
                  <Form.Control
                    aria-label="platelet_morphology"
                    type="number"
                    name="platelet_morphology"
                    onChange={handleChangeValue}
                    value={formData.platelet_morphology}
                  />
                  {/* <InputGroup.Text id="basic-addon2"> ng/ mL</InputGroup.Text> */}
                </InputGroup>
              </td>
              <td>Ferritin</td>
              <td>
                <InputGroup className="mb-0 w-50">
                  <Form.Control
                    aria-label="ferritin"
                    type="number"
                    name="ferritin"
                    onChange={handleChangeValue}
                    value={formData.ferritin}
                  />
                  <InputGroup.Text id="basic-addon2"> ng/mL</InputGroup.Text>
                </InputGroup>
              </td>
            </tr>
            <tr>
              <td>Bilirubin</td>
              <td>
                <InputGroup className="mb-0 w-50">
                  <Form.Control
                    aria-label="bilirubin"
                    type="number"
                    name="bilirubin"
                    onChange={handleChangeValue}
                    value={formData.bilirubin}
                  />
                  <InputGroup.Text id="basic-addon2"> mg/dL</InputGroup.Text>
                </InputGroup>
              </td>
              <td>ALT</td>
              <td>
                <InputGroup className="mb-0 w-50">
                  <Form.Control
                    aria-label="alt"
                    type="number"
                    name="alt"
                    onChange={handleChangeValue}
                    value={formData.alt}
                  />
                  <InputGroup.Text id="basic-addon2">IU/L</InputGroup.Text>
                </InputGroup>
              </td>
            </tr>
            <tr>
              <td>AST</td>
              <td>
                <InputGroup className="mb-0 w-50">
                  <Form.Control
                    aria-label="ast"
                    type="number"
                    name="ast"
                    onChange={handleChangeValue}
                    value={formData.ast}
                  />
                  <InputGroup.Text id="basic-addon2">IU/L</InputGroup.Text>
                </InputGroup>
              </td>
              <td>ALP</td>
              <td>
                <InputGroup className="mb-0 w-50">
                  <Form.Control
                    aria-label="alp"
                    type="number"
                    name="alp"
                    onChange={handleChangeValue}
                    value={formData.alp}
                  />
                  <InputGroup.Text id="basic-addon2">IU/L</InputGroup.Text>
                </InputGroup>
              </td>
            </tr>
            <tr>
              <td>Protein</td>
              <td>
                <InputGroup className="mb-0 w-50">
                  <Form.Control
                    aria-label="protein"
                    type="number"
                    name="protein"
                    onChange={handleChangeValue}
                    value={formData.protein}
                  />
                  <InputGroup.Text id="basic-addon2">g/dL</InputGroup.Text>
                </InputGroup>
              </td>
              <td>Albumin</td>
              <td>
                <InputGroup className="mb-0 w-50">
                  <Form.Control
                    aria-label="albumin"
                    type="number"
                    name="albumin"
                    onChange={handleChangeValue}
                    value={formData.albumin}
                  />
                  <InputGroup.Text id="basic-addon2">g/dL</InputGroup.Text>
                </InputGroup>
              </td>
            </tr>
            <tr>
              <td>Hepatitis B Screen</td>
              <td>
                <Select
                  name="hepatitis_b_screen"
                  onChange={dropDownChange}
                  value={screenOptions.find(
                    (hepb) =>
                      hepb.value === parseInt(formData.hepatitis_b_screen)
                  )}
                  className="newPatientSelect w-50"
                  options={screenOptions}
                />
              </td>
              <td>Hepatitis C Screen</td>
              <td>
                <Select
                  name="hepatitis_c_screen"
                  onChange={dropDownChange}
                  value={screenOptions.find(
                    (hepb) =>
                      hepb.value === parseInt(formData.hepatitis_c_screen)
                  )}
                  className="newPatientSelect w-50"
                  options={screenOptions}
                />
              </td>
            </tr>
            <tr>
              <td>HIV Screen</td>
              <td>
                <Select
                  name="hiv_screen"
                  onChange={dropDownChange}
                  value={screenOptions.find(
                    (hepb) => hepb.value === parseInt(formData.hiv_screen)
                  )}
                  className="newPatientSelect w-50"
                  options={screenOptions}
                />
              </td>
              <td>Hepatitis A Vacciniation</td>
              <td>
                <input
                  type="checkbox"
                  name="hepatitis_a_vaccination"
                  onChange={handleChange}
                  checked={formData.hepatitis_a_vaccination}
                />
              </td>
            </tr>
            <tr>
              <td>Hepatitis C Vacciniation</td>
              <td>
                <input
                  type="checkbox"
                  name="hepatitis_c_vaccination"
                  onChange={handleChange}
                  checked={formData.hepatitis_c_vaccination}
                />
              </td>
              <td>Anti HBS Titre</td>
              <td>
                <InputGroup className="mb-0 w-50">
                  <Form.Control
                    aria-label="anti_hbs_titre"
                    type="number"
                    name="anti_hbs_titre"
                    onChange={handleChangeValue}
                    value={formData.anti_hbs_titre}
                  />
                  <InputGroup.Text id="basic-addon2">mIU/mL</InputGroup.Text>
                </InputGroup>
              </td>
            </tr>
            <tr>
              <td>Missing Studies Res.</td>
              <td>
                <InputGroup className="mb-0 w-50">
                  <Form.Control
                    aria-label="mixing_studies_result"
                    type="number"
                    name="mixing_studies_result"
                    onChange={handleChangeValue}
                    value={formData.mixing_studies_result}
                  />
                  {/* <InputGroup.Text id="basic-addon2">mIU/mL</InputGroup.Text> */}
                </InputGroup>
              </td>
              <td> &nbsp;</td>
              <td> &nbsp;</td>
            </tr>
          </tbody>
        </table>
      </div>
    </form>
  );
};

export default InvestigationTab;

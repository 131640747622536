import React from "react";
import Card from "react-bootstrap/Card";

const EconomicStatusCard = ({ currentRecord, setFieldLabelFromArray }) => {
  return (
    <Card>
      <Card.Header>
        <i className="fas fa-align-justify" />
        <strong>Patient Economic Status</strong>
      </Card.Header>
      <Card.Body>
        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            <b>
              {"patient/family_annual_income"
                .replaceAll("_", " ")
                .toUpperCase()}
            </b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {setFieldLabelFromArray(
              currentRecord?.economic_status?.patient_annual_income
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-xs-6 divTableCell">
            <b>{"employment_type".replaceAll("_", " ").toUpperCase()}</b>
          </div>
          <div className="col-md-6 col-xs-6 divTableCell">
            {setFieldLabelFromArray(
              currentRecord?.economic_status?.employment_type
            )}
          </div>
        </div>
        {currentRecord?.economic_status?.source_of_funds && (
          <div className="row">
            <div className="col-md-6 col-xs-6 divTableCell">
              <b>{"source_of_funds".replaceAll("_", " ").toUpperCase()}</b>
            </div>
            <div className="col-md-6 col-xs-6 divTableCell">
              {
                JSON.parse(currentRecord?.economic_status?.source_of_funds)
                  ?.label
              }
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default EconomicStatusCard;
